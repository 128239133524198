import type {
    ConnectedServiceSettings,
    Customer,
    CustomerProvider,
    PaymentAccount,
    PaymentAccountProviders,
    Transaction,
    TransactionProvider,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import { ConnectedServiceConfigData, ConnectedServiceRequestError, TransactionType } from '@nexdynamic/squeegee-common';
import type { AxiosError } from 'axios';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import { AccountsLedgerService } from '../ChartOfAccounts/AccountsLedgerService';
import { DateTimePicker } from '../Components/DateTimePicker/DateTimePicker';
import { CustomerMatchDialog } from '../ConnectedServices/Customers/CustomerMatchDialog';
import { PaymentsMatchDialog } from '../ConnectedServices/Payments/PaymentsMatchDialog';
import { CustomerService } from '../Customers/CustomerService';
import { Data } from '../Data/Data';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { Prompt } from '../Dialogs/Prompt';
import { LoaderEvent } from '../Events/LoaderEvent';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { Api } from '../Server/Api';
import { MandateApi } from '../Server/MandateApi';
import { PaymentsApi } from '../Server/PaymentsApi';
import { t } from '../t';
import { ConnectedServiceConnect } from './ConnectedServiceConnect';
import type { IConnectedServiceInfo } from './IConnectedServiceInfo';
import { PaymentAccountMatchDialog } from './PaymentAccounts/PaymentAccountMatchDialog';

export class ConnectedServicesService {
    public static async disconnect(provider: string) {
        const providerLabel = ApplicationState.localise(`providers.${provider.split('.')[0]}-name` as TranslationKey);
        if (
            await new Prompt('prompts.stripe-disconnect-title', 'prompts.stripe-disconnect-text', {
                okLabel: 'general.disconnect',
                localisationParams: { service: providerLabel },
            }).show()
        ) {
            new LoaderEvent(true);
            try {
                if (!(await Api.delete(Api.apiEndpoint, '/api/oauth/' + provider))) {
                    new NotifyUserMessage('notifications.stripe-disconnect-error', { service: provider });
                }
            } finally {
                new NotifyUserMessage('notifications.stripe-disconnect-success', { service: provider });
                new LoaderEvent(false);
            }
        }
    }

    public static async getAvailableConnectedServices(): Promise<Array<IConnectedServiceInfo>> {
        const response = await Api.get<Array<IConnectedServiceInfo>>(Api.apiEndpoint, '/api/oauth');
        return response?.data || [];
    }

    public static async getConnectedServiceData(id: string): Promise<IConnectedServiceInfo | undefined> {
        const response = await Api.get<IConnectedServiceInfo>(Api.apiEndpoint, `/api/oauth/${id}`);
        return response?.data;
    }

    public static async importCustomers(provider: CustomerProvider) {
        try {
            const providerLabel = ApplicationState.localise(`providers.${provider.split('.')[0]}-name` as TranslationKey);
            new LoaderEvent(
                true,
                false,
                ApplicationState.localise('notifications.provider-downloading-customers', { provider: providerLabel })
            );
            const thirdPartyCustomers = await Api.get<Array<Customer>>(
                Api.apiEndpoint,
                `/api/oauth/data/${provider}/customers?limit=4000`,
                false,
                300000,
                false
            );
            new LoaderEvent(false);
            if (!thirdPartyCustomers || !thirdPartyCustomers.data) {
                new NotifyUserMessage('notifications.provider-customer-download-error', { provider });
                Logger.error(`Error during download to ${provider} customers`, thirdPartyCustomers && thirdPartyCustomers.data);
            } else {
                const dialog = new CustomerMatchDialog(
                    ApplicationState.localise('matcher.import-provider-customers', { provider }),
                    thirdPartyCustomers.data,
                    provider,
                    async (starting_after?: string, limit?: number) => {
                        const resp = await Api.get<Array<Customer>>(
                            Api.apiEndpoint,
                            `/api/oauth/data/${provider}/customers?starting_after=${starting_after}&limit=${limit}`,
                            false,
                            300000
                        );
                        return resp?.data;
                    }
                );
                await dialog.show(DialogAnimation.SLIDE_UP);
            }
        } catch (error) {
            new LoaderEvent(false);
            if (
                ConnectedServiceRequestError.isConnectedServiceRequestError(error) &&
                (error as ConnectedServiceRequestError).providerErrorCode === 'needs-customer-to-reauthenticate'
            ) {
                const csrError = error.response.data as ConnectedServiceRequestError;
                const message = ('connected-service-request-error.' + csrError.providerErrorCode) as TranslationKey;
                const providerData = await ConnectedServicesService.getProvider(provider);
                if (!providerData) return;
                const providerDialog = new ConnectedServiceConnect(providerData, message);
                if (providerDialog.cancelled) return;
            } else new NotifyUserMessage('notifications.provider-customer-import-error', { provider });
            Logger.error(`Unable to import customers from ${provider}`, error);
        }
    }

    public static async importPaymentAccounts(provider: PaymentAccountProviders) {
        try {
            const providerLabel = ApplicationState.localise(`providers.${provider.split('.')[0]}-name` as TranslationKey);

            new LoaderEvent(
                true,
                false,
                ApplicationState.localise('notifications.provider-downloading-customers', { provider: providerLabel })
            );
            const paymentAccountsResponse = await Api.get<Array<PaymentAccount>>(
                Api.apiEndpoint,
                `/api/oauth/data/${provider}/paymentaccounts`,
                false,
                300000,
                false
            );
            new LoaderEvent(false);

            if (!paymentAccountsResponse || !paymentAccountsResponse.data) {
                new NotifyUserMessage('notifications.provider-customer-download-error', { provider: providerLabel });
                Logger.error(`Error during download to ${provider} paymentaccounts`);
            } else {
                const dialog = new PaymentAccountMatchDialog(
                    ApplicationState.localise('matcher.import-provider-payment-accounts', { provider: providerLabel }),
                    paymentAccountsResponse.data,
                    provider
                );
                await dialog.show(DialogAnimation.SLIDE_UP);
            }
        } catch (error) {
            new LoaderEvent(false);
            if (
                ConnectedServiceRequestError.isConnectedServiceRequestError(error) &&
                (error as ConnectedServiceRequestError).providerErrorCode === 'needs-customer-to-reauthenticate'
            ) {
                const csrError = error.response.data as ConnectedServiceRequestError;
                const message = ('connected-service-request-error.' + csrError.providerErrorCode) as TranslationKey;
                const providerData = await ConnectedServicesService.getProvider(provider);
                if (!providerData) return;
                const providerDialog = new ConnectedServiceConnect(providerData, message);
                if (providerDialog.cancelled) return;
            } else new NotifyUserMessage('notifications.provider-customer-import-error', { provider });
            Logger.error(`Unable to import customers from ${provider}`, error);
        }
    }

    public static async getCustomer(provider: string, id: string): Promise<Customer | undefined> {
        try {
            new LoaderEvent(true);

            const response = await Api.get<Customer>(Api.apiEndpoint, `/api/oauth/data/${provider}/customers/${id}`);

            new LoaderEvent(false);
            return response?.data;
        } catch (err) {
            const error = err as AxiosError;

            new LoaderEvent(false);

            const message = error?.response?.data
                ? error.response.data.messages?.length
                    ? error.response.data.messages.join(', ')
                    : error.response.data.rawResponse || error.response.data
                : error.message || 'An error occurred';

            new NotifyUserMessage(('Error getting connected entity: ' + message) as TranslationKey);
            Logger.error(`Unable to getting connected entity from ${provider}`, error);
            return message;
        }
    }

    public static async updateConnectedEntity(provider: string, id: string) {
        try {
            new LoaderEvent(true);

            await Api.put<Array<Customer>>(Api.apiEndpoint, `/api/oauth/data/${provider}/${id}`, {}, false, false);

            new LoaderEvent(false);
            return true;
        } catch (err) {
            const error = err as AxiosError;

            new LoaderEvent(false);

            const message = error?.response?.data
                ? error.response.data.messages?.length
                    ? error.response.data.messages.join(', ')
                    : error.response.data.rawResponse || error.response.data
                : error.message || 'An error occurred';

            new NotifyUserMessage(('Error updating connected entity: ' + message) as TranslationKey);
            Logger.error(`Unable to update connected entity from ${provider}`, error);
            return message;
        }
    }

    public static async deleteConnectedEntity(provider: string, id: string) {
        try {
            new LoaderEvent(true);

            await Api.delete<boolean>(Api.apiEndpoint, `/api/oauth/data/${provider}/${id}`, false, false);
            new LoaderEvent(false);
            return true;
        } catch (err) {
            const error = err as AxiosError;

            new LoaderEvent(false);

            const message = error?.response?.data
                ? error.response.data.messages?.length
                    ? error.response.data.messages.join(', ')
                    : error.response.data.rawResponse || error.response.data
                : error.message || 'An error occurred';

            new NotifyUserMessage(('Error deleting connected entity: ' + message) as TranslationKey);
            Logger.error(`Unable to delete connected entity from ${provider}`, error);
            return message;
        }
    }

    public static async createConnectedEntity(provider: string, resource: string, id: string, bulk?: boolean) {
        try {
            if (!bulk) new LoaderEvent(true);
            await Api.post<Array<Customer>>(Api.apiEndpoint, `/api/oauth/data/${provider}/${resource}/${id}`, {}, false, false);
            if (!bulk) new LoaderEvent(false);
            return true;
        } catch (err) {
            const error = err as AxiosError;
            const message = error?.response?.data
                ? error.response.data.messages?.length
                    ? error.response.data.messages.join(', ')
                    : error.response.data.rawResponse || error.response.data
                : error.message || 'An error occurred';
            if (!bulk) {
                new LoaderEvent(false);

                new NotifyUserMessage(('Error creating connected entity: ' + message) as TranslationKey);
            }
            Logger.error(`Unable to create connected entity from ${provider}`, error);
            return message;
        }
    }

    public static async syncResourceEntities(provider: string, resource: string, ids: Array<string>) {
        try {
            new LoaderEvent(true);
            await Api.post<Array<Customer>>(Api.apiEndpoint, `/api/oauth/data/${provider}/${resource}`, { ids }, false, false);
            new LoaderEvent(false);
            return true;
        } catch (err) {
            const error = err as AxiosError;
            const message = error?.response?.data
                ? error.response.data.messages?.length
                    ? error.response.data.messages.join(', ')
                    : error.response.data.rawResponse || error.response.data
                : error.message || 'An error occurred';

            new LoaderEvent(false);
            new NotifyUserMessage(('Error creating connected entity: ' + message) as TranslationKey);
            Logger.error(`Unable to create connected entity from ${provider}`, error);
            return message;
        }
    }

    public static async importPayments(provider: string, paymentAccount?: PaymentAccount) {
        const settings = this.getProviderSettings(provider);

        if (!paymentAccount) {
            const accounts = await AccountsLedgerService.getRealAccounts();
            const hasRealAccounts = !!accounts.length;
            if (!hasRealAccounts) {
                await this.importPaymentAccounts(provider as PaymentAccountProviders);
            }
            paymentAccount = await AccountsLedgerService.selectAccount();
        }
        if (!paymentAccount) return;

        const accountId = paymentAccount?.externalIds?.[provider as PaymentAccountProviders];
        const currency = paymentAccount?.currency || ApplicationState.currencyCode().toLowerCase();
        const datePicker = new DateTimePicker(false, settings.lastSyncFromDate, 'import.payments-from', false);
        datePicker.init();
        await datePicker.open();
        if (datePicker.canceled) return;
        const from = datePicker.currentDate.format('YYYY-MM-DD');
        settings.lastSyncFromDate = from;

        const datePickerTo = new DateTimePicker(
            false,
            settings.lastSyncToDate || moment().format('YYYY-MM-DD'),
            'import.payments-to',
            false
        );

        datePickerTo.init();
        await datePickerTo.open();
        if (datePickerTo.canceled) return;
        const to = datePickerTo.currentDate.format('YYYY-MM-DD');
        settings.lastSyncToDate = to;
        const providerName = ApplicationState.localise(`providers.${provider.split('.')[0]}-name` as TranslationKey);
        new LoaderEvent(true, false, ApplicationState.localise('notifications.provider-downloading-payments', { provider: providerName }));
        try {
            await this.saveProviderConfig(provider, settings);

            //await Api.post(Api.apiEndpoint, `/api/oauth/generateMap/${provider}`);

            const payments = await Api.get<Array<Transaction>>(
                Api.apiEndpoint,
                `/api/oauth/data/${provider}/payments?from=${from}${to ? '&to=' + to : ''}${accountId ? '&accountId=' + accountId : ''}${
                    currency ? '&currency=' + currency : ''
                }`,
                false,
                300000,
                false
            );

            new LoaderEvent(false);
            if (!payments || !payments.data) {
                new NotifyUserMessage('notifications.provider-payment-download-error', { provider });
                Logger.error(`Error during download ${provider} payments`, payments && payments.data);
            } else {
                const providerLabel = ApplicationState.localise(`providers.${provider.split('.')[0]}-name` as TranslationKey);
                const dialog = new PaymentsMatchDialog(
                    ApplicationState.localise('matcher.import-provider-payments', { provider: providerLabel }),
                    payments.data,
                    provider as TransactionProvider,
                    paymentAccount,
                    datePicker.currentDate.subtract(1, 'day'),
                    datePickerTo.currentDate
                );
                dialog.show();
            }
        } catch (error) {
            new LoaderEvent(false);
            if (
                ConnectedServiceRequestError.isConnectedServiceRequestError(error.response.data) &&
                error.response.data.providerErrorCode === 'needs-customer-to-reauthenticate'
            ) {
                const csrError = error.response.data as ConnectedServiceRequestError;
                const message = ('connected-service-request-error.' + csrError.providerErrorCode) as TranslationKey;
                const providerData = await ConnectedServicesService.getProvider(provider);
                if (!providerData) return;
                const providerDialog = new ConnectedServiceConnect(providerData, message);
                await providerDialog.show();
                if (providerDialog.cancelled) return;
            } else new NotifyUserMessage('notifications.provider-payment-import-error', { provider });
            Logger.error(`Unable to import payments from ${provider}`, error);
        }
    }

    public static async getProviders() {
        const response = await Api.get<Array<IConnectedServiceInfo>>(Api.apiEndpoint, '/api/oauth');
        return response?.data || [];
    }

    public static async getProvider(id: string) {
        const response = await Api.get<IConnectedServiceInfo>(Api.apiEndpoint, `/api/oauth/${id}/connect`);
        return response?.data;
    }

    public static async refreshPendingAutomaticPaymentsAndCustomers() {
        new LoaderEvent(true, true, 'refresh.payments-and-customers-started');
        try {
            const pendingTransactions = Data.all<Transaction>(
                'transactions',
                t =>
                    t.transactionType === TransactionType.AutomaticPayment &&
                    (t.transactionSubType === 'auto.go-cardless' ||
                        t.transactionSubType === 'auto.stripe' ||
                        t.transactionSubType === 'payment.stripe') &&
                    t.status === 'pending'
            );

            for (const payment of pendingTransactions) {
                await PaymentsApi.checkAndUpdatePaymentStatus(payment);
                new LoaderEvent(
                    true,
                    true,
                    t('refresh.pending-payment-statuses-x-of-y', {
                        x: pendingTransactions.indexOf(payment) + 1,
                        y: pendingTransactions.length,
                    })
                );
            }

            const pendingCustomers = Data.all<Customer>('customers', c => c.paymentProviderMetaData?.gocardless?.status === 'pending');
            for (const customer of pendingCustomers) {
                const originalMandateStatus = customer.paymentProviderMetaData?.gocardless?.status;
                const checkedAutomaticPaymentStatus = await MandateApi.checkCustomerMandate(customer._id).catch(() => undefined);

                if (checkedAutomaticPaymentStatus && originalMandateStatus !== checkedAutomaticPaymentStatus) {
                    if (customer.paymentProviderMetaData?.gocardless) {
                        customer.paymentProviderMetaData.gocardless.status = checkedAutomaticPaymentStatus as any;
                        CustomerService.addOrUpdateCustomer(customer);
                        new NotifyUserMessage('prompts.payment-status-changed');
                    }
                }
                new LoaderEvent(
                    true,
                    true,
                    t('refresh.pending-customer-mandates-x-of-y', {
                        x: pendingCustomers.indexOf(customer) + 1,
                        y: pendingCustomers.length,
                    })
                );
            }
            new NotifyUserMessage('refresh.payment-and-customers-finished');
        } catch (error) {
            Logger.error('Failed to update pending payment and customer statuses.', error);
            new NotifyUserMessage('failed.to-update-pending-status');
        } finally {
            new LoaderEvent(false);
        }
    }

    static getProviderSettings(provider: keyof ConnectedServiceSettings) {
        const settings = ApplicationState.getSetting<ConnectedServiceSettings>('global.connected-services', {});
        const providerSettings = settings[provider] || new ConnectedServiceConfigData();
        return providerSettings;
    }

    static async saveProviderConfig(provider: keyof ConnectedServiceSettings, providerConfig: ConnectedServiceConfigData) {
        const settings = ApplicationState.getSetting<ConnectedServiceSettings>('global.connected-services', {});

        settings[provider] = providerConfig;
        await ApplicationState.setSetting('global.connected-services', settings);
    }
}
