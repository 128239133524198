import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../ApplicationState';
import { GlobalFlags } from '../../GlobalFlags';
import { DialogAnimation } from '../DialogAnimation';
import type { ISlide } from './ISlide';
import { SlideShowDialog } from './SlideShowDialog';

export class SlideGenerator {
    public static async showLaunchSlides() {
        const dialog = new SlideShowDialog(SlideGenerator.launchSlides, { allowSkip: false });
        await dialog.show(GlobalFlags.isHttp ? DialogAnimation.SLIDE_UP : DialogAnimation.SLIDE);
    }
    public static readonly launchSlides: Array<ISlide> = [
        {
            title: 'Welcome to Squeegee!' as TranslationKey,
            subtitle: `Welcome to the Squeegee work scheduling and management app.` as TranslationKey,
            imageUrl: `${!GlobalFlags.isAndroidMobileDevice && !GlobalFlags.isAppleMobileDevice ? '/' : ''}images/launch-slides/white-blue-shaded-logo.svg`,
            action: {
                text: 'SIGN IN',
                execute: async () => undefined,
            },
            class: 'slide-blue',
        },
        {
            title: 'Your Data Anywhere' as TranslationKey,
            subtitle: `Access Squeegee from your phone, tablet, laptop or PC at home or on the go. 
            
            This is all as part of your subscription and in real-time or offline on multiple devices!` as TranslationKey,
            imageUrl: `${!GlobalFlags.isAndroidMobileDevice && !GlobalFlags.isAppleMobileDevice ? '/' : ''}images/launch-slides/use-anywhere.svg`,
            action: {
                text: 'SIGN IN',
                execute: async () => undefined,
            },
            class: 'slide-yellow',
        },
        {
            title: 'Schedule Your Work' as TranslationKey,
            subtitle: `Schedule repeating jobs and one off appointments with multiple services and multiple addresses per customer.
            
            Assign work to individual workers or teams and re-assign as you go to optimise your work flow.` as TranslationKey,
            imageUrl: `${!GlobalFlags.isAndroidMobileDevice && !GlobalFlags.isAppleMobileDevice ? '/' : ''}images/launch-slides/scheduling.svg`,
            action: {
                text: 'SIGN IN',
                execute: async () => undefined,
            },
            class: 'slide-green',
        },
        {
            title: 'Invoice Customers' as TranslationKey,
            subtitle: `Invoice your customers as the work is done, no admin paperwork to catch up on later.
            
            Send invoices out via Email and SMS both automatically and on request.` as TranslationKey,
            imageUrl: `${!GlobalFlags.isAndroidMobileDevice && !GlobalFlags.isAppleMobileDevice ? '/' : ''}images/launch-slides/invoicing.svg`,
            action: {
                text: 'SIGN IN',
                execute: async () => undefined,
            },
            class: 'slide-orange',
        },
        {
            title: 'Process Payments' as TranslationKey,
            subtitle: `Take payments with Stripe and GoCardless directly from the app or an invoice.
            
            Record cash, cheque and bank transfers as they happen to cut down on duplicate information and errors` as TranslationKey,
            imageUrl: `${!GlobalFlags.isAndroidMobileDevice && !GlobalFlags.isAppleMobileDevice ? '/' : ''}images/launch-slides/payments.svg`,
            action: {
                text: 'SIGN IN',
                execute: async () => undefined,
            },
            class: 'slide-purple',
        },
        {
            title: 'Analyse Your Business' as TranslationKey,
            subtitle: `View financial information and projections to monitor the month to month health of your business.
            
            Download and analyse report data to take a deep dive into the details of your financials, job completion rates, customer retention and more.` as TranslationKey,
            imageUrl: `${!GlobalFlags.isAndroidMobileDevice && !GlobalFlags.isAppleMobileDevice ? '/' : ''}images/launch-slides/analyse-your-business.svg`,
            action: {
                text: 'SIGN IN',
                execute: async () => undefined,
            },
            class: 'slide-red',
        },
    ];

    private static readonly onboardingVersion = 1;
    public static async showOnboardingSlides(forceShow = false) {
        const versionShown = ApplicationState.account.onBoardingVersionShown;
        if (!forceShow && versionShown && versionShown >= SlideGenerator.onboardingVersion) return;

        const dialog = new SlideShowDialog(SlideGenerator.onboardingSlides, { allowSkip: true });
        await dialog.show(GlobalFlags.isHttp ? DialogAnimation.SLIDE_UP : DialogAnimation.SLIDE);

        if (versionShown !== SlideGenerator.onboardingVersion) {
            ApplicationState.account.onBoardingVersionShown = SlideGenerator.onboardingVersion;
            ApplicationState.save();
        }
    }
    public static readonly onboardingSlides: Array<ISlide> = [
        {
            title: 'onboarding.slide-two.title',
            subtitle: 'onboarding.slide-two.subtitle',
            imageUrl: `${!GlobalFlags.isAndroidMobileDevice && !GlobalFlags.isAppleMobileDevice ? '/' : ''}images/onboarding/schedule.svg`,
            class: 'slide-blue',
        },
        {
            title: 'onboarding.slide-three.title',
            subtitle: 'onboarding.slide-three.subtitle',
            imageUrl: `${!GlobalFlags.isAndroidMobileDevice && !GlobalFlags.isAppleMobileDevice ? '/' : ''}images/onboarding/payments.svg`,
            class: 'slide-green',
        },
        {
            title: 'onboarding.slide-one.title',
            subtitle: 'onboarding.slide-one.subtitle',
            imageUrl: `${!GlobalFlags.isAndroidMobileDevice && !GlobalFlags.isAppleMobileDevice ? '/' : ''}images/onboarding/customers.svg`,
            action: {
                text: 'Get Started',
                execute: async () => void (await ApplicationState.navigateTo('customers')),
            },
            class: 'slide-red',
        },
    ];
}

(window as any).SlideGenerator = SlideGenerator;
