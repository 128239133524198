import { ApplicationState } from '../ApplicationState';

export class MetresToDistanceValueConverter {
    toView(metres: number, breakTwoLine = true) {
        return MetresToDistanceValueConverter.getDistanceInMetresAsText(metres, breakTwoLine);
    }

    public static getDistanceInMetresAsText(distanceInMetres: number, breakTwoLine = true): string {
        return breakTwoLine
            ? MetresToDistanceValueConverter.getDistanceInDistanceUnits(distanceInMetres).toString() +
                  ' ' +
                  ApplicationState.distanceUnitsAbbreviated
            : MetresToDistanceValueConverter.getDistanceInDistanceUnits(distanceInMetres).toString() + ' ' + ApplicationState.distanceUnits;
    }

    private static getDistanceInDistanceUnits(distanceInMeters: number): number {
        if (distanceInMeters === 0) return 0;
        const unitDivisor = ApplicationState.distanceUnits === 'miles' ? 1609.34 : 1000;

        const distanceInDistanceUnits = parseFloat((distanceInMeters / unitDivisor).toFixed(2));
        if (isNaN(distanceInDistanceUnits)) return 0;
        return distanceInDistanceUnits;
    }
}
