import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { ContactMethod } from '@nexdynamic/squeegee-common';
import { bindable } from 'aurelia-framework';
import { Select } from '../../Dialogs/Select';
import { TextDialog } from '../../Dialogs/TextDialog';

export class ContactMethods {
    @bindable contactMethods: Array<ContactMethod> = [];

    protected async addOrEditContactMethod(contactMethod?: ContactMethod) {
        const typeDialog = new Select<{ text: TranslationKey; value: 'email' | 'telephone' | 'mail' }>(
            'contact.method-type-title',
            [
                { text: 'notifications.type-Email', value: 'email' },
                { text: 'global.telephone-title', value: 'telephone' },
                { text: 'global.mail-title', value: 'mail' },
            ],
            'text',
            'value',
            (contactMethod && contactMethod.value) || ''
        );

        const valueDialog = new TextDialog(<TranslationKey>'', '', (contactMethod && contactMethod.value) || '', '');

        typeDialog.nextDialog = valueDialog;
        typeDialog.beforeNextDialog = async () => {
            const type = await typeDialog.getResult();
            if (!type) return;
            valueDialog._validate = x => (!x ? <TranslationKey>('Please enter the ' + type.text) : true);
            valueDialog.title = <TranslationKey>('Enter the ' + type.text);
        };

        const result = await typeDialog.show();

        if (typeDialog.cancelled || valueDialog.cancelled) return;

        await valueDialog.getResult();

        const type = await typeDialog.getResult();
        if (!type) return;

        const value = result.value;
        if (contactMethod) {
            contactMethod.type = type.value;
            contactMethod.value = value;
        } else {
            this.contactMethods.push(new ContactMethod(type.value, value));
        }
    }

    protected async removeContactMethod(contactMethod: ContactMethod) {
        this.contactMethods.splice(this.contactMethods.indexOf(contactMethod), 1);
    }
}
