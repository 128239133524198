import type { StoredObjectResourceTypes, TranslationKey } from '@nexdynamic/squeegee-common';
import { Note, sortByCreatedDateAsc, sortByCreatedDateDesc } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { DateTimePicker } from '../../Components/DateTimePicker/DateTimePicker';
import { Data } from '../../Data/Data';
import { SqueegeeLocalStorage } from '../../Data/SqueegeeLocalStorage';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import { TextAreaDialog } from '../../Dialogs/TextAreaDialog';
import type { IMenuBarAction } from '../../Menus/IMenuBarAction';
import { UIActivity } from './UIActivity';

export class CustomerNotesAndActivityDialog extends CustomDialog<void> {
    protected activities: Array<UIActivity>;

    protected filteredActivities: Array<UIActivity>;

    protected moreActions: Array<IMenuBarAction>;
    private type: StoredObjectResourceTypes | null;
    private ascending = SqueegeeLocalStorage.getItem('customerNotesDialogAscending') === 'true';

    protected searchTerm: string;

    constructor(public customerId: string) {
        super('customerNotesDialog', '../Customers/Components/CustomerNotesAndActivitiesDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'customer-notes-and-activities-dialog',
            isSecondaryView: true,
        });
    }

    public async init() {
        this.activities = UIActivity.generateCustomerActivies(this.customerId);
        this.refreshFilter();
    }

    public async setActions() {
        this.moreActions = [
            {
                tooltip: 'All Activities' as TranslationKey,
                icon: 'list',
                handler: () => this.refreshFilter(null),
                roles: [],
                actionType: 'action-list',
            },
            {
                tooltip: 'Notes' as TranslationKey,
                icon: 'description',
                handler: () => this.refreshFilter('notes'),
                roles: [],
                actionType: 'action-today',
            },
            {
                tooltip: 'Finances' as TranslationKey,
                icon: 'receipt',
                handler: () => this.refreshFilter('transactions'),
                roles: [],
                actionType: 'action-invoice',
            },
            {
                tooltip: 'Done/Skipped Jobs' as TranslationKey,
                icon: 'work',
                handler: () => this.refreshFilter('joboccurrences'),
                roles: [],
                actionType: 'action-view-job-history',
            },
            {
                tooltip: 'Messages' as TranslationKey,
                icon: 'message',
                handler: () => this.refreshFilter('notifications'),
                roles: [],
                actionType: 'action-notify',
            },
            {
                tooltip: `Sort ${this.ascending ? 'descending' : 'ascending'}` as TranslationKey,
                icon: 'sort',
                handler: async () => {
                    this.ascending = !this.ascending;
                    SqueegeeLocalStorage.setItem('customerNotesDialogAscending', this.ascending.toString());
                    this.refreshFilter(null);
                },
                roles: [],
                actionType: 'action-sort',
            },
        ];
    }

    protected createOrEditNote = async (existingNoteId?: string) => {
        let note = existingNoteId ? Data.get<Note>(existingNoteId) : new Note('', this.customerId);
        if (!note) return;

        const customerNotesDialog = new TextAreaDialog(
            'Add a Note' as TranslationKey,
            note.content,
            'Add a note to this customer',
            undefined,
            v => (!v?.trim() ? ('Please enter a note' as TranslationKey) : true)
        );

        const value = await customerNotesDialog.show();
        if (customerNotesDialog.cancelled) return;

        const datePicker = new DateTimePicker(false, note.createdDate, 'Select note date' as TranslationKey, false);
        datePicker.okLabel = 'general.ok';
        datePicker.cancelLabel = 'general.cancel';
        datePicker.init();
        await datePicker.open();
        if (datePicker.canceled || !datePicker.selectedDate) return;

        note = new Note(value, note.relatedId);
        const createdDate = moment();
        note.createdDate =
            datePicker.selectedDate === createdDate.format('YYYY-MM-DD')
                ? createdDate.toISOString()
                : moment(datePicker.selectedDate).toISOString();

        await Data.put(note);

        const activity = UIActivity.fromNote(note);
        if (existingNoteId)
            this.activities.splice(
                this.activities.findIndex(x => x.id === existingNoteId),
                1
            );
        this.activities.push(activity);

        this.refreshFilter();
    };


    protected refreshFilter = async (type?: StoredObjectResourceTypes | null) => {
        if (type !== undefined) this.type = type;
        const searchTerm = this.searchTerm?.trim().toLowerCase() || '';
        if (this.type) {
            if (searchTerm) {
                this.filteredActivities = this.activities.filter(
                    a => a.type === this.type && (a.content + ' ' + a.title + ' ' + a.date).toLowerCase().includes(searchTerm)
                );
            } else {
                this.filteredActivities = this.activities.filter(a => a.type === this.type);
            }
        } else {
            if (searchTerm) {
                this.filteredActivities = this.activities.filter(a =>
                    (a.content + ' ' + a.title + ' ' + a.date).toLowerCase().includes(searchTerm)
                );
            } else {
                this.filteredActivities = this.activities.slice();
            }
        }

        this.filteredActivities.sort(this.ascending ? sortByCreatedDateAsc : sortByCreatedDateDesc);

        this.setActions();
    };
}
