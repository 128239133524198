import type { Customer } from '@nexdynamic/squeegee-common';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';

export class CustomerSavedMessage extends NotifyUserMessage {
    constructor(public customer: Customer) {
        super('notifications.customer-saved');
    }
}

export class CustomerDeletedMessage extends NotifyUserMessage {
    constructor(public customerId: string) {
        super('notifications.customer-deleted');
    }
}
