import type { Customer } from '@nexdynamic/squeegee-common';
import { Invoice, InvoiceItem, Transaction, TransactionType } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { InvoiceService } from '../Invoices/InvoiceService';
import { Api } from '../Server/Api';
import { Data } from './Data';
import { SqueegeeParser } from './SqueegeeParser';

export class CustomImporters {
    public static async 'Import Invoices'(csv?: string) {
        if (!csv) {
            const invoiceRowSample = new InvoiceRow();
            return Object.keys(invoiceRowSample)
                .map(x => `"${x}": ${(<any>invoiceRowSample)[x]}`)
                .join('\n');
        }

        const parsedRows = SqueegeeParser.parseCSV<InvoiceRow>(csv);
        for (const row of parsedRows.data) {
            const typr = new Typr(row);
            const customerExternalId = typr.string('external customer id');
            const customer = Data.firstOrDefault<Customer>('customers', c => c._externalId === customerExternalId);
            const customerId = customer ? customer._id : '';

            const invoice = new Invoice(typr.boolean('tax rate', false), typr.number('tax rate') || 0, typr.boolean('taxable', false));
            invoice.customerId = customerId;
            invoice.notes = typr.string('notes');
            invoice.date = typr.date('date') || moment().toString();
            invoice.billTo = (customer && `${customer.name} ${customer.address && customer.address.addressDescription}`.trim()) || '';
            const amount = typr.number('amount') || 0;
            const type = customer ? 'customers' : '';
            const refId = customerId;
            const invoiceItem = new InvoiceItem(1, invoice.notes, amount, type, refId);
            invoice.items = invoice.items || [];
            invoice.items.push(invoiceItem);
            Invoice.updateTotals(invoice);

            const invoiceNumber = typr.number('invoice number');
            if (invoiceNumber !== undefined) invoice.invoiceNumber = invoiceNumber;
            const invoiceTransaction = new Transaction(
                TransactionType.Invoice,
                'invoice.imported',
                amount,
                customer && customer._id,
                undefined,
                'Imported invoice record',
                invoice.date,
                undefined,
                invoice
            );

            const outstanding = typr.number('outstanding') || 0;
            await Data.put(invoiceTransaction, true, 'lazy');

            if (outstanding !== amount) {
                const paymentAmount = -(amount - outstanding);
                const paymentDate = typr.date('paid date') || invoice.date;
                const paymentTransaction = new Transaction(
                    TransactionType.Payment,
                    'payment.imported',
                    paymentAmount,
                    customer && customer._id,
                    undefined,
                    'Imported payment record',
                    paymentDate
                );
                await Data.put(paymentTransaction, true, 'lazy');
                customer && (await InvoiceService.allocateBalanceToInvoices(customer._id));
            }
        }

        const invoiceNumbers = Data.all<Transaction>('transactions', x => !!x.invoice)
            .map(x => (x.invoice && x.invoice.invoiceNumber) || 0)
            .sort();
        const maxInvoiceNumber = invoiceNumbers && invoiceNumbers.length && invoiceNumbers[invoiceNumbers.length - 1];
        await Api.setInvoiceNumber(maxInvoiceNumber);
    }
}

export class Typr<TParsedType> {
    public constructor(private parsedObject: TParsedType = <any>{}) {}

    public string(property: keyof TParsedType) {
        return (<any>this.parsedObject[property] || '').toString();
    }

    public number(property: keyof TParsedType) {
        const value = Number(this.parsedObject[property]);
        return isNaN(value) ? undefined : value;
    }

    public boolean(property: keyof TParsedType, trueByDefault: boolean) {
        const value = (<any>this.parsedObject[property] || '').toString().trim().toLowerCase();
        const result =
            ['true', 't', 'y', 'yes', 'on', '1'].indexOf(value) > -1
                ? true
                : ['false', 'f', 'n', 'no', 'off', '0'].indexOf(value) > -1
                ? false
                : undefined;

        return result === undefined ? trueByDefault : result;
    }

    public date(property: keyof TParsedType, inputFormat?: string) {
        const value = moment((<any>this.parsedObject[property] || '').toString(), inputFormat);
        return value.isValid() ? value.format() : undefined;
    }
}

export class InvoiceRow {
    public readonly 'external id': string = 'The ID for this invoice in the external system';
    public readonly 'external customer id': string = 'The ID for the customer that this invoice relates to in the external system';
    public readonly 'invoice number': string = 'Sequential numeric invoice number';
    public readonly 'date': string = 'The date this invoice was generated';
    public readonly 'notes': string = 'The description notes to add to this invoice';
    public readonly 'taxable': string = 'Is this invoice taxable';
    public readonly 'amount': string = 'How much is the invoice for';
    public readonly 'tax rate': string = 'The tax rate percentage as a decimal';
    public readonly 'paid date': string = 'When was this invoice paid as an ISO date time';
    public readonly 'outstanding': string = 'How much of this invoice is outstanding';
}
