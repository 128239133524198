import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom, inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import './breadcrumb.scss';

export type Crumb = {
    text: TranslationKey;
    href: string;
};
@inject(Router)
export class BreadcrumbCustomElement {
    @bindable crumbs?: Array<Crumb>;

    constructor(private router: Router) {}

    @computedFrom('crumbs')
    get breadcrumbs() {
        // Don't include the last crumb
        if (this.crumbs) return this.crumbs.slice(0, this.crumbs.length - 1);
    }

    @computedFrom('crumbs')
    get current() {
        if (this.crumbs) return this.crumbs[this.crumbs?.length - 1];
    }

    protected navigateToCrumb(crumb: Crumb) {
        this.router.navigate(crumb.href);
    }
}
