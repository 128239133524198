import type { Moment } from 'moment';
import moment from 'moment';

export const parseSoapyDate = (dateString: string, includeTime: boolean): Moment | undefined => {
    if (!dateString) return;
    const date = moment(dateString, includeTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD');
    if (!date.isValid()) return;

    return date;
};
