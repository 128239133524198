import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { uuid } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { Logger } from '../Logger';
import { CustomDialogBase } from './CustomDialogBase';
import { DialogAnimation } from './DialogAnimation';
import type { DialogSettingsViewport } from './DialogSettings';

export class Prompt extends CustomDialogBase<boolean> {
    public static async continue(message: TranslationKey) {
        const dialog = new Prompt('general.confirm', message, { okLabel: 'general.continue', cancelLabel: 'general.cancel' });
        return await dialog.show();
    }
    static info(message: TranslationKey, title = 'Info', okLabel = 'OK' as TranslationKey) {
        new Prompt(title as TranslationKey, message as TranslationKey, { cancelLabel: '', okLabel }).show();
    }
    protected showLoader = false;
    protected cssClass = 'prompt-dialog';
    protected htmlContent: string;
    public updateHtmlContent(textLocalisationKey: TranslationKey) {
        this.htmlContent = ApplicationState.localise(textLocalisationKey);
    }

    public constructor(titleLocalisationKey: TranslationKey, textLocalisationKey: TranslationKey, settings?: DialogSettingsViewport) {
        super(
            'promptDialog' + uuid(),
            './Prompt.html',
            ApplicationState.localise(titleLocalisationKey, (settings && settings.localisationParams) || undefined),
            settings
        );
        this.htmlContent = ApplicationState.localise(textLocalisationKey, (settings && settings.localisationParams) || undefined);
    }

    public load(loaderPromise?: Promise<any>) {
        if (loaderPromise) {
            this.showLoader = true;
            loaderPromise
                .then(() => {
                    this.showLoader = false;
                })
                .catch(error => {
                    Logger.error(`Error during load in Prompt`, { prompt: this, error });
                    super.cancel();
                });
        }
        return super.show();
    }

    public static async saveOrDiscard(saveDelegate: () => Promise<void>) {
        const prompt = new Prompt('prompts.cancel-edit.title', 'prompts.cancel-edit.text', {
            okLabel: 'general.save',
            cancelLabel: 'general.discard',
        });
        const save = await prompt.show(DialogAnimation.GROW);
        if (save) await saveDelegate();
    }

    /**
     *Prompt the user if they want to discard current changes
     * @returns boolean true if discard was accepted
     */
    public static async discard() {
        const prompt = new Prompt('prompts.discard-changes', 'prompts.discard-changes-description', {
            okLabel: 'general.discard',
            cancelLabel: 'general.cancel',
        });
        await prompt.show(DialogAnimation.GROW);

        return prompt.cancelled ? false : true;
    }

    public get result() {
        return this._result;
    }
}
