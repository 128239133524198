import { Location } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { SqueegeeLocalStorage } from '../Data/SqueegeeLocalStorage';
import type { IDayPilotOptions } from '../DayPilot/Components/IDayPilotOptions';
import { Utilities } from '../Utilities';
import { AddressLookupDialog } from './AddressLookupDialog';
import { CustomDialog } from './CustomDialog';

export class DayPilotSettingsDialog extends CustomDialog<IDayPilotOptions> {
    protected failedValidation = false;
    protected time: string;
    constructor(protected viewOptions: IDayPilotOptions, protected showStartpoint: boolean = true) {
        super('dayPilotSettingsDialog', './DayPilotSettingsDialog.html', '', {
            cssClass: 'color-schedule  schedule-details-dialog details-dialog no-nav-shadow',
            okLabel: '',
            cancelLabel: '',
            isSecondaryView: true,
            destructive: true,
        });
        if (!viewOptions.startLocation || !viewOptions.startLocation.addressDescription) {
            viewOptions.startLocation = Utilities.copyObject(ApplicationState.account.businessAddress) || new Location();
        }
        if (!viewOptions.endLocation) viewOptions.endLocation = new Location();
    }

    timeChanged() {
        this.time;
    }

    protected selectViewOptions() {
        this.ok(this.viewOptions);
    }

    public async getResult() {
        return this.viewOptions;
    }

    protected resetToDefaults() {
        SqueegeeLocalStorage.removeItem('dayPilotDepartureTimeMinutes');
        SqueegeeLocalStorage.removeItem('dayPilotDepartureTimeHours');
        SqueegeeLocalStorage.removeItem('dayPilotStartLocation');
        SqueegeeLocalStorage.removeItem('dayPilotEndLocation');

        this.viewOptions.startLocation = Utilities.copyObject(ApplicationState.account.businessAddress) || new Location();
        this.viewOptions.endLocation = new Location();
    }

    public async pickStartLocation() {
        const dialog = new AddressLookupDialog(this.viewOptions.startLocation as Location);
        const location = await dialog.show();
        if (dialog.cancelled) return;

        this.viewOptions.startLocation = Utilities.copyObject(location);
    }

    public async clearStartLocation() {
        SqueegeeLocalStorage.removeItem('dayPilotStartLocation');
        this.viewOptions.startLocation = Utilities.copyObject(ApplicationState.account.businessAddress) || new Location();
    }

    public async pickEndLocation() {
        const dialog = new AddressLookupDialog(this.viewOptions.endLocation as Location);
        const location = await dialog.show();
        if (dialog.cancelled) return;

        this.viewOptions.endLocation = Utilities.copyObject(location);
    }

    public async clearEndLocation() {
        SqueegeeLocalStorage.removeItem('dayPilotEndLocation');
        this.viewOptions.endLocation = new Location();
    }
}
