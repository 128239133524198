import type { AccountUser, Customer, Job, JobInitialFields, Team } from '@nexdynamic/squeegee-common';
import { FrequencyType, JobOccurrence } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import { CustomerDeletedMessage } from '../../Customers/CustomerMessage';
import { Data } from '../../Data/Data';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import { Prompt } from '../../Dialogs/Prompt';
import type { Subscription } from '../../Events/SqueegeeEventAggregator';
import { JobService } from '../../Jobs/JobService';
import { JobActions } from '../../Jobs/actions/JobActions';
import { ScheduleDetailsDialog } from '../../Schedule/Components/ScheduleDetailsDialog';
import { ScheduleItem } from '../../Schedule/Components/ScheduleItem';
import { AssignmentService } from '../../Users/Assignees/AssignmentService';
import { Utilities } from '../../Utilities';

export class NewJobDialog extends CustomDialog<{ job: Job; initialFields?: JobInitialFields }> {
    private static _cssClasses = 'job-new-dialog details-dialog has-header-content';
    protected menuTitle: string;
    protected failedValidation = false;
    protected stateFlags = ApplicationState.stateFlags;
    public job: Job;
    private originalJob: string;
    public assignees: Array<AccountUser | Team> = [];

    constructor(
        private customer: Customer,
        job: Job,
        private saveJobToDb = true,
        private dateRequired = false,
        protected initialFields?: JobInitialFields,
        protected quoteConversion = false,
        protected applyRange = false
    ) {
        super('new-job-' + job._id, '../Jobs/Components/NewJobDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: NewJobDialog._cssClasses,
            isSecondaryView: true,
            destructive: true,
        });

        this.menuTitle = `New ${job.isQuote ? 'Quote' : 'Job'}${customer.name ? ' for ' + customer.name : ''}`;

        this.originalJob = JSON.stringify(job);
        this.job = Utilities.copyObject(job);
    }

    private _customerRemovedSub: Subscription;

    public async abortCancel() {
        const job = JSON.stringify(this.job);
        const dirty = this.originalJob !== job;
        if (dirty) {
            return !(await Prompt.discard());
        }
        return false;
    }

    public async init() {
        this._customerRemovedSub = CustomerDeletedMessage.subscribe((message: CustomerDeletedMessage) => {
            if (message.customerId === this.customer._id) return super.cancel();
        });
    }

    public dispose() {
        this._customerRemovedSub && this._customerRemovedSub.dispose();
        super.dispose();
    }

    protected saveJob = async (job: Job, initialFields?: JobInitialFields) => {
        this.failedValidation = false;

        if (this.saveJobToDb) {
            const { error, success } = await JobActions.save(
                this.customer,
                job,
                this.assignees.map(x => x._id),
                initialFields,
                this.dateRequired
            );
            this.failedValidation = Boolean(error);
            if (this.failedValidation) return;

            if (success) {
                this.ok({ job, initialFields });
            }

            if (job.frequencyType === FrequencyType.NoneRecurring && job.date) {
                const occurrence =
                    Data.get<JobOccurrence>(job._id + job.date) || new JobOccurrence(job, job.date || moment().toISOString().substr(0, 10));

                AssignmentService.assign(
                    occurrence,
                    this.assignees.map(x => x._id)
                );

                const occurrenceCustomer = Data.get<Customer>(job.customerId);
                if (!occurrenceCustomer) return;
                const scheduleItem = new ScheduleItem(job, occurrenceCustomer, occurrence);
                new ScheduleDetailsDialog(scheduleItem).show();
            }
        } else {
            if (JobService.validateJobAndNotifyUI(job, this.dateRequired).length) {
                this.failedValidation = true;
            } else {
                this.ok({ job: this.job, initialFields: initialFields });
            }
        }
    };
}
