import moment from 'moment';
import { ApplicationEnvironment } from '../ApplicationEnvironment';
import { ApplicationState } from '../ApplicationState';

export class DateValueConverter {
    toView(value: string | number, format = 'ddd, MMM Do', useYesterdayTodayAndTomorrow = true) {
        return DateValueConverter.getDisplayDate(value, format, useYesterdayTodayAndTomorrow);
    }

    public static getDisplayDate(value: string | number | moment.Moment, format = 'ddd, MMM Do', useYesterdayTodayAndTomorrow = true) {
        if (typeof value === 'number' || (typeof value === 'string' && /^[0-9]*?$/.test(value))) {
            const valueAsString = value.toString();
            if (valueAsString.length === 10) value = moment.unix(Number(value));
            else if (valueAsString.length === 13) value = moment(Number(value));
            else return valueAsString;
        }
        if (useYesterdayTodayAndTomorrow) {
            const today = moment();
            const yesterday = today.clone().subtract(1, 'day');
            const tomorrow = today.clone().add(1, 'day');

            if (yesterday.isSame(value, 'day')) {
                return ApplicationState.localise('dates.yesterday');
            } else if (today.isSame(value, 'day')) {
                return ApplicationState.localise('dates.today');
            } else if (tomorrow.isSame(value, 'day')) {
                return ApplicationState.localise('dates.tomorrow');
            }
        }

        return `${moment(value).format(format)}${ApplicationEnvironment.i18nDebugEnabled ? ' 🗺️' : ''}`;
    }
}
