import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { uuid } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import './AureliaReactComponentDialog.scss';
import type {
    AureliaReactComponentDialogComponent,
    AureliaReactComponentDialogComponentStandardProps,
} from './AureliaReactComponentDialogComponent';
import type { AureliaReactComponentDialogOptions } from './AureliaReactComponentDialogOptions';
import { aureliaReactComponentDialogRenderer } from './AureliaReactComponentDialogRenderer';
import { CustomDialog } from './CustomDialog';
export class AureliaReactComponentDialog<T, ComponentProps extends object = never> extends CustomDialog<T> {
    componentElementId = uuid();
    component: AureliaReactComponentDialogComponent<AureliaReactComponentDialogComponentStandardProps<T> & ComponentProps>;
    componentProps?: ComponentProps;
    unmount: () => void;
    saveButton: {
        save?: () => void;
        saveText?: TranslationKey;
    } = {};
    constructor(props: AureliaReactComponentDialogOptions<ComponentProps>) {
        super(
            uuid(),
            './AureliaReactComponentDialog.html',
            ApplicationState.localise(props.dialogTitle),
            props.isSecondaryView !== false
                ? {
                      cssClass: 'aurelia-react-component-dialog' + (props.cssClass ? ' ' + props.cssClass : ''),
                      isSecondaryView: props.isSecondaryView,
                      okLabel: '',
                      cancelLabel: '',
                  }
                : {
                      cssClass: 'aurelia-react-component-dialog' + (props.cssClass ? ' ' + props.cssClass : ''),
                      coverViewport: props.coverViewport,
                      okLabel: '',
                      cancelLabel: '',
                  }
        );
        this.component = props.component;
        this.componentProps = props.componentProps;
    }

    public async init(): Promise<void> {
        const element = document.getElementById(this.componentElementId);
        if (!element) {
            this.cancel(true);
            return;
        }
        const props: AureliaReactComponentDialogComponentStandardProps<T> = {
            okCallback: this.delegateOk,
            cancelCallback: this.delegateCancel,
            saveButton: this.saveButton,
            ...this.componentProps,
        };
        this.unmount = aureliaReactComponentDialogRenderer(element, this.component, props).unmount;
    }

    public delegateOk = async (result: T) => {
        await this.ok(result);

        this.unmount();
    };

    public delegateCancel = async () => {
        await this.cancel();
        this.unmount();
    };

    public static show<T, ComponentProps extends object = never>(options: AureliaReactComponentDialogOptions<ComponentProps>) {
        const dialog = new AureliaReactComponentDialog<T, ComponentProps>(options);
        return { show: dialog.show(), dialog };
    }
}
