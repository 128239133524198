import type { IListItem, StoredObjectResourceTypes, TranslationKey} from '@nexdynamic/squeegee-common';
import { AlphanumericCharColourDictionary, uuid } from '@nexdynamic/squeegee-common';
import type { Subscription } from 'aurelia-event-aggregator';
import { bindable } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import { ViewResizeEvent } from '../../Events/ViewResizeEvent';
import { FabWithActions } from '../../FabWithActions';
import { Utilities } from '../../Utilities';
import type { IFabAction } from '../Fabs/IFabAction';
import './StandardList.scss';

export abstract class StandardList<TItemType extends { _id: string }> {
    protected list: Array<IListItem<TItemType>> = [];
    protected listFiltered: Array<IListItem<TItemType>> = [];
    protected constructor(protected threeLine = false, protected autoHeight = false) {}
    public readonly id: string = uuid();

    @bindable public searchText = '';

    public searchTextChanged(): void {
        this.filter();
    }

    protected abstract listTitle: TranslationKey;
    protected abstract loadItems: () => Array<IListItem<TItemType>> | Promise<Array<IListItem<TItemType>>>;
    protected abstract selectItem: (item: TItemType) => void | Promise<void>;
    protected abstract fabActions?: IFabAction[];
    protected abstract listenToResourceTypes?: Array<StoredObjectResourceTypes>;
    protected allowDragAndDrop = false;

    protected onDragStart?(itemsInDrag: Array<TItemType>): void;

    protected onDragDrop?(oldIndex: number | Array<number>, newIndex: number | Array<number>): void;

    protected sortFunction(a: TItemType, b: TItemType) {
        let orderA = this.orderData.indexOf(a._id);
        let orderB = this.orderData.indexOf(b._id);

        orderA = orderA > -1 ? orderA : 0;
        orderB = orderB > -1 ? orderB : 0;
        if (orderA < orderB) return -1;
        else if (orderA > orderB) return 1;
        else return 0;
    }

    private static _colours = new AlphanumericCharColourDictionary();

    protected async internalSelectItem(item: IListItem<TItemType>) {
        const indexOfItem = this.listFiltered.indexOf(item);

        if (this.selectedIndex === indexOfItem) this.selectedIndex = undefined;
        else this.selectedIndex = indexOfItem;

        if (this.selectedIndexes.indexOf(indexOfItem) === -1) {
            this.selectedIndexes.push(indexOfItem);
            await this.selectItem(item.original);
        } else {
            this.selectedIndexes.splice(this.selectedIndexes.indexOf(indexOfItem));
        }

        if (ApplicationState.signaler) ApplicationState.signaler.signal(this.id + '-select-state-changed');
    }

    protected orderData: Array<string> = [];

    protected async refreshList() {
        this._itemsAreDirty = false;
        this.list = (await this.loadItems()).sort((a, b) => this.sortFunction(a.original, b.original));
        this.filter();

        for (const itm of this.list) this.orderData.indexOf(itm.original._id) === -1 && this.orderData.push(itm.original._id);
        this._createFab();
    }

    protected selectedIndex?: number;
    protected selectedIndexes: number[] = [];

    protected isSelected(itmIndex: number) {
        return itmIndex === this.selectedIndex || this.selectedIndexes.indexOf(itmIndex) > -1;
    }

    protected filter() {
        this.listFiltered = [];
        const searchTextLowered = (this.searchText && this.searchText.toLowerCase()) || '';
        this.listFiltered = this.list.filter(
            listItem =>
                !searchTextLowered ||
                (listItem.title && listItem.title.toLowerCase().indexOf(searchTextLowered) > -1) ||
                (listItem.description && listItem.description.toLowerCase().indexOf(searchTextLowered) > -1) ||
                (listItem.indicators &&
                    listItem.indicators.length &&
                    listItem.indicators
                        .map(i => i.content)
                        .join(' ')
                        .toLowerCase()
                        .indexOf(searchTextLowered) > -1) ||
                (listItem.additionalSearchableText && listItem.additionalSearchableText.toLowerCase().indexOf(searchTextLowered) > -1)
        );
    }

    protected avatarText(item: IListItem<TItemType>) {
        return item.avatarText || (item.title && item.title.length ? Utilities.getAvatarTextFromName(item.title) : '');
    }

    protected avatarColor(item: IListItem<TItemType>) {
        return (
            item.avatarColour || (item.title && item.title.length ? StandardList._colours[item.title.substring(0, 1).toUpperCase()] : '')
        );
    }

    protected listenToResourcesSub: Subscription;
    public attached() {
        new ViewResizeEvent();
        this._addResourceChangeListener();
        this.refreshList();
    }

    private _addResourceChangeListener() {
        const listenToTypes = this.listenToResourceTypes;
        if (!listenToTypes) return;

        this.listenToResourcesSub = DataRefreshedEvent.subscribe((event: DataRefreshedEvent) => {
            if (!event.updatedObjects) return;
            if (!event.hasAnyType(...listenToTypes)) return;

            // TODO: What if order data is changed? Do we need anything special?

            // WTF? To ignore change but let make sure we get a refresh later.
            if (this._dragInProgress) this._itemsAreDirty = true;
            else this.refreshList();
        });
    }
    private _createFab() {
        if (this.fabActions) FabWithActions.register(this.fabActions);
    }

    public detached() {
        FabWithActions.unregister();
        this.listenToResourcesSub && this.listenToResourcesSub.dispose();
    }

    private _dragInProgress: boolean;
    private _itemsAreDirty: boolean;

    protected _dragDropped = (oldIndex: number | Array<number>, newIndex: number | Array<number>) => {
        this._dragInProgress = false;
        if (oldIndex === newIndex) return;

        let mappedOrder: Array<{ oldIndex: number; newIndex: number }>;

        const oldIndexes = Array.isArray(oldIndex) ? oldIndex : [oldIndex];
        const newIndexes = Array.isArray(newIndex) ? newIndex : [newIndex];

        if (oldIndexes.length === 1 && this.selectedIndexes.length > 1) {
            let count = 0;
            mappedOrder = this.selectedIndexes.map(x => {
                return { oldIndex: x, newIndex: newIndexes[0] + count++ };
            });
        } else {
            mappedOrder = oldIndexes.map((x, idx) => {
                return { oldIndex: x, newIndex: newIndexes[idx] };
            });
        }

        mappedOrder.sort((a, b) => {
            if (newIndexes[0] > oldIndexes[0]) return b.oldIndex - a.oldIndex;
            else return a.oldIndex - b.oldIndex;
        });

        for (let index = 0; index < mappedOrder.length; index++) {
            const itemInDrag = this.listFiltered[mappedOrder[index].oldIndex];
            const from = this.orderData.indexOf(itemInDrag.original._id);
            const to = this.orderData.indexOf(this.listFiltered[mappedOrder[index].newIndex].original._id);
            if (from > -1) this.orderData.splice(from, 1);
            this.orderData.splice(to, 0, itemInDrag.original._id);
        }

        if (this.onDragDrop) this.onDragDrop(oldIndex, newIndex);

        if (this._itemsAreDirty) this.refreshList();
    };

    protected _dragStarted = (event: any) => {
        this._dragInProgress = true;
        const selectedItems = this.selectedIndexes.map(i => this.listFiltered[i].original);
        const itemInDrag = this.listFiltered[event.oldIndex];
        if (!itemInDrag || selectedItems.length) return;
        if (this.onDragStart) this.onDragStart(selectedItems);
    };
}
