// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paper {
  overflow: hidden;
  height: 100%;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/Components/paper/paper.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,YAAA;EACA,sBAAA;EACA,kBAAA;EACA,wCAAA;AACJ","sourcesContent":[".paper {\n    overflow: hidden;\n    height: 100%;\n    background-color: #fff;\n    border-radius: 6px;\n    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
