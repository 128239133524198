import type { Expense } from '@nexdynamic/squeegee-common';
import { getExpenseTaxConfig, to2dp, TransactionType } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import { Logger } from '../Logger';
import type { ValidationErrorMessage } from '../Notifications/ValidationErrorMessage';
import { Utilities } from '../Utilities';

export class ExpenseService {
    public static async getExpenses() {
        return Data.all<Expense>('transactions', e => e.transactionType === TransactionType.Expense);
    }

    public static async getExpense(id: string) {
        return Data.get<Expense>(id);
    }

    public static validateExpenseAndNotifyUI(expense: Expense): Array<ValidationErrorMessage> {
        const ExpenseNameErrors = Utilities.validateAndNotifyUI(
            !!expense.description && !!expense.description.length,
            'validation.expense-description-required'
        );
        const ExpenseAmountErrors = Utilities.validateAndNotifyUI(
            !isNaN(expense.amount) && !!expense.amount && expense.amount !== 0,
            'validation.expense-value-cannot-be-zero'
        );
        const ExpenseCategoryError = Utilities.validateAndNotifyUI(
            Boolean(expense.category?.description?.trim()),
            'validation.expense-category-required'
        );
        return ExpenseNameErrors.concat(ExpenseAmountErrors).concat(ExpenseCategoryError);
    }
    public static updateTotals(expense: Expense) {
        const { taxEnabled, taxRate } = getExpenseTaxConfig(expense, ApplicationState.account);
        if (taxEnabled) {
            expense.subtotal = to2dp(expense.amount / (1 + taxRate));
            expense.totalTax = to2dp(expense.amount - expense.subtotal);
        } else {
            expense.subtotal = to2dp(expense.amount);
        }
    }

    public static async updateExpense(Expense: Expense) {
        const errors = ExpenseService.validateExpenseAndNotifyUI(Expense);
        if (errors.length) throw errors;
        if (Expense.description !== undefined) Expense.description = Utilities.toTitleCase(Expense.description);

        await Data.put(Expense);
    }

    public static async addExpense(Expense: Expense) {
        const errors = ExpenseService.validateExpenseAndNotifyUI(Expense);
        if (errors.length) throw errors;

        await Data.put(Expense);
    }

    public static async removeExpense(expense: Expense) {
        try {
            await Data.delete(expense);
        } catch (error) {
            Logger.error(`Error during removeExpense in expense service`, { expense, error });
            throw error;
        }
    }
}
