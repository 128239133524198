import type { Franchisee } from '@nexdynamic/squeegee-common';
import { ListItem } from '../Components/StandardList/ListItem';

export class FranchiseeStandardListItem extends ListItem<Franchisee> {
    public constructor(public readonly franchise: Franchisee) {
        super(franchise);
    }

    getTitle = this.original.name;
    getDescription = this.original.accountEmail;

    getAvatarImage = '';
    avatarText = this.original.name;
}
