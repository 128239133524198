import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { wait } from '@nexdynamic/squeegee-common';
import { uuid } from '@nexdynamic/squeegee-portal-common';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { Logger } from '../../Logger';
import type { SquareTransactionInfo } from './SquareTransactionInfo';

export const enableFakeSquarePOS = () => {
    if (window.squarePOS) return;
    window.squarePOS = {
        isFake: true,
        initTransaction: async (options, callback: (transactionInfo: SquareTransactionInfo) => void) => {
            Logger.info('Fake Square POS transaction initiated', options);

            let count = 4;
            while (count--) {
                new LoaderEvent(true, false, (count + 1).toString() as TranslationKey);
                await wait(750);
            }

            new LoaderEvent(false);

            callback({
                clientTransactionId: `fake:${uuid()}`,
                metadata: { fake: 'true' },
            });
        },
    };
};
