import { FeedbackPromptResult } from '@nexdynamic/squeegee-common';
import type { Subscription } from 'aurelia-event-aggregator';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import { CustomerService } from '../../Customers/CustomerService';
import { Logger } from '../../Logger';
import { ScheduleActionEvent } from '../../Schedule/Actions/ScheduleActionEvent';
import { Api } from '../../Server/Api';
import { openSystemBrowser } from '../../Utilities';
import { FeedbackService } from './FeedbackService';

const data = require('../../../package.json');

export class FeedbackListItemCustomElement {
    protected questionStage: 'enjoying' | 'rate' | 'ask-feedback' | 'providing-feedback' | 'thanks' | 'dismissed' = 'enjoying';
    protected feedbackList: HTMLUListElement;
    private promptResult: FeedbackPromptResult = new FeedbackPromptResult(
        new Date().getTime().toString(),
        'declined-feedback',
        `${data.version}`
    );
    protected version = data.version;
    protected readyToShowElement = false;
    private _scheduleActionSubScription: Subscription;
    private _account = ApplicationState.account;
    private _applicationState = ApplicationState.instance;

    public Api = Api;

    public attached() {
        this.checkIfReadyToShowElement();
        this._scheduleActionSubScription = ScheduleActionEvent.subscribe((event: ScheduleActionEvent) => {
            if (event.actionType === 'action-done') {
                if (this.readyToShowElement) {
                    this.toggleListItem();
                }
            }
        });
    }

    detached() {
        this._scheduleActionSubScription && this._scheduleActionSubScription.dispose();
    }

    protected async checkIfReadyToShowElement() {
        this.readyToShowElement = false;
        // must be online and not already rated.
        if (!this.Api.isConnected || (this._account.feedbackData && this._account.feedbackData.hasRated)) {
            this.readyToShowElement = false;
        } else {
            const hasFeedbackHistory =
                this._account.feedbackData &&
                    this._account.feedbackData.feedbackPromptHistory &&
                    this._account.feedbackData.feedbackPromptHistory.length
                    ? true
                    : false;
            let askForFeedback = false;
            if (!hasFeedbackHistory) {
                askForFeedback = moment().diff(moment(this._applicationState.createdDate), 'weeks') >= 3;
            } else {
                let mostRecentPromptTimestamp: string | undefined;
                let i = this._account.feedbackData.feedbackPromptHistory.length;
                while (i--) {
                    const promptData = this._account.feedbackData.feedbackPromptHistory[i];
                    if (mostRecentPromptTimestamp === undefined || promptData.timestamp > mostRecentPromptTimestamp)
                        mostRecentPromptTimestamp = promptData.timestamp;
                }

                askForFeedback =
                    !!mostRecentPromptTimestamp &&
                    moment().diff(moment(Number(mostRecentPromptTimestamp)), 'weeks') >=
                    8 * this._account.feedbackData.feedbackPromptHistory.length;
            }
            // enough time passed then check customers
            if (askForFeedback) {
                const customers = CustomerService.getCustomers();
                if (customers.length > 10) {
                    this.readyToShowElement = true;
                }
            }
        }
    }

    protected askForFeedback() {
        this.questionStage = 'ask-feedback';
    }

    protected askToRate() {
        this.questionStage = 'rate';
        this.promptResult.promptResult = 'declined-rating';
    }

    protected dismiss() {
        this.toggleListItem(false, 0);
        this.readyToShowElement = false;
        this.questionStage = 'dismissed';
        FeedbackService.saveFeedbackOrRatingResult(this.promptResult);
    }

    protected rate() {
        // take them to rate
        this.questionStage = 'thanks';
        this.promptResult.promptResult = 'gave-rating';
        FeedbackService.saveFeedbackOrRatingResult(this.promptResult);
        this.toggleListItem(false);
        this.readyToShowElement = false;

        openSystemBrowser('https://www.trustpilot.com/review/squeeg.ee')
    }

    protected feedbackUrl = FeedbackService.feedbackUrl;
    protected async feedback() {
        try {
            this.promptResult.promptResult = 'gave-feedback';
            await FeedbackService.sendFeedback();
            await FeedbackService.saveFeedbackOrRatingResult(this.promptResult);
            this.questionStage = 'thanks';
            this.toggleListItem(false);
            this.readyToShowElement = false;
        } catch (error) {
            Logger.error('Error during post the feedback to us', { error });
        }
    }

    private toggleListItem(show = true, delay = 2000) {
        setTimeout(() => {
            if (show) this.feedbackList.classList.add('expand-item');
            else this.feedbackList.classList.remove('expand-item');
        }, delay);
    }
}
