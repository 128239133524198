import type { AuditEvent } from '@nexdynamic/squeegee-common';
import { GlobalFlags } from '../../GlobalFlags';
import { Api } from '../../Server/Api';
import { generateTrpc } from './generateTrpc';

export const V2 = () => {
    let endpoint: string;
    if (GlobalFlags.isHttp) {
        endpoint = window.location.origin;
    } else {
        endpoint = Api.currentHostAndScheme;
    }
    const trpc = generateTrpc(endpoint);
    const v2 = {
        ping: (input: { message: string }) => trpc.ping.query(input),
        signout: () => trpc.signout.mutate(),
        signin: (input: { email: string, password: string }) => trpc.signin.mutate(input),
        submitAuditEvent: (input: AuditEvent) => trpc.submitAuditEvent.mutate(input),
    }

    Object.assign(window, { v2 });

    return v2;
};

(window as any).GenerateV2 = V2;
