import type { Customer } from '@nexdynamic/squeegee-common';
import { Data } from '../../Data/Data';
import { updateTagText } from './updateTagText';

export const updateCustomerTags = async (
    initialCustomer: Customer,
    standardSelected: string[],
    importantSelected: string[],
    ignoreImportant: string[],
    ignoreStandard: string[]
) => {
    const customer = Data.get<Customer>(initialCustomer._id);
    if (!customer) return;

    customer.notes = await updateTagText(customer.notes || '', standardSelected, importantSelected, ignoreImportant, ignoreStandard);

    Data.put(customer);
};
