import { bindable } from 'aurelia-framework';

export class Financial {
    @bindable() financial = 0.0;
    @bindable() label = '';
    @bindable() isInteger = false;
    @bindable() commas = false;
    protected isVisible: boolean;
    protected wholeNumber = '0';
    protected decimalPart: string = this.isInteger ? '' : '00';
    protected negative = false;

    financialChanged() {
        if (this.financial === undefined || this.financial === null) {
            this.isVisible = false;
            return;
        }
        const negative = this.financial.toString().startsWith('-');
        const parts = parseFloat(this.financial.toString()).toFixed(2).split('.');
        this.wholeNumber = negative ? parts[0].substring(1, parts[0].length) : parts[0];
        if (this.commas) {
            this.wholeNumber = Number(this.wholeNumber).toLocaleString('en');
        }
        this.decimalPart = this.isInteger ? '' : parts[1];
        this.negative = negative;
        this.isVisible = true;
    }
}
