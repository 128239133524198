import { SnackbarProvider } from '@nexdynamic/nex-ui-react';
import ReactDOM from 'react-dom/client';
import { SqueegeeThemeProvider } from '../ReactUI/SqueegeeThemeProvider';
import type {
    AureliaReactComponentDialogComponent,
    AureliaReactComponentDialogComponentStandardProps,
} from './AureliaReactComponentDialogComponent';

export const aureliaReactComponentDialogRenderer = <T, TOptions extends AureliaReactComponentDialogComponentStandardProps<T>>(
    element: HTMLElement,
    Component: AureliaReactComponentDialogComponent<TOptions>,
    componentProps: TOptions
) => {
    const root = ReactDOM.createRoot(element);

    root.render(
        <SqueegeeThemeProvider>
            <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} maxSnack={3}>
                <Component {...componentProps} />
            </SnackbarProvider>
        </SqueegeeThemeProvider>
    );

    return {
        unmount: () => {
            root.unmount();
        },
    };
};
