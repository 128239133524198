import type { Customer } from '@nexdynamic/squeegee-common';
import { searchCustomers } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../ApplicationState';
import { CustomDialog } from '../../Dialogs/CustomDialog';

export interface ISelectableCustomer {
    isSelected: boolean;
    customer: Customer;
}

export class SelectCustomersDialog extends CustomDialog<Array<Customer>> {
    public searchTerm = '';
    public selectableCustomers: Array<ISelectableCustomer> = [];
    public filteredCustomers: Array<ISelectableCustomer> = [];

    constructor(public preselectedCustomers: Array<Customer> = [], public allCustomers: Array<Customer> = []) {
        super('selectCustomersDialog', '../Customers/Components/SelectCustomersDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'customers-select-dialog select-list-dialog',
            coverViewport: true,
            noObfuscator: true,
            smallerOnDesktop: true,
        });
        this.selectableCustomers = this.allCustomers.map(customer => {
            return <ISelectableCustomer>{
                isSelected: this.preselectedCustomers.some(currentCustomer => currentCustomer._id === customer._id),
                customer: customer,
            };
        });
        this.filter();
    }

    public filter() {
        const mobilePrefixes = ApplicationState.account.smsMobilePhonePrefixes?.split(',').map(x => x.trim());

        const foundCustomers = searchCustomers({
            customers: this.selectableCustomers.map(x => x.customer),
            searchText: this.searchTerm,
            searchJobs: true,
            mobilePrefixes
        })
            .map(x => x._id)
            .reduce<{ [id: string]: true }>((dictionary, id) => {
                dictionary[id] = true;
                return dictionary;
            }, {});

        this.filteredCustomers = this.selectableCustomers.filter(selectableItem => foundCustomers[selectableItem.customer._id]);
    }

    protected delegateOk = () => {
        this.ok(this.getSelectedCustomers());
    };

    private getSelectedCustomers(): Array<Customer> {
        const selectedCustomers = <Array<Customer>>(
            this.selectableCustomers
                .filter(selectableOccurrence => selectableOccurrence.isSelected === true)
                .map(selectableOccurrence => selectableOccurrence.customer)
        );
        //Also include any prev selected customers that do not appear in the current customers
        for (let i = 0; i < this.preselectedCustomers.length; i++) {
            if (!this.allCustomers.find(customer => customer._id === this.preselectedCustomers[i]._id)) {
                selectedCustomers.push(this.preselectedCustomers[i]);
            }
        }
        return selectedCustomers;
    }

    protected selectItem(item: ISelectableCustomer, event: Event) {
        item.isSelected = !item.isSelected;
        if (event) event.stopPropagation();
    }

    public async getResult() {
        return this.getSelectedCustomers();
    }
}
