import type { LedgerAccountType, PaymentAccount } from '@nexdynamic/squeegee-common';
import { bindable } from 'aurelia-typed-observable-plugin';
import { ApplicationState } from '../../ApplicationState';
import type { AccountLedgerTypeGroup } from '../../ChartOfAccounts/AccountsLedgerService';
import { AccountsLedgerService } from '../../ChartOfAccounts/AccountsLedgerService';
import { Data } from '../../Data/Data';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import type { Subscription } from '../../Events/SqueegeeEventAggregator';
export class PaymentAccountListCustomElement {
    protected paymentAccounts?: Readonly<Array<PaymentAccount>>;

    protected balances: Partial<Record<string, number>> = {};

    protected builtInAccounts: Array<PaymentAccount> = [];

    protected groupedByType: AccountLedgerTypeGroup;

    private _dataChangedEvent: Subscription;

    protected isUltimateAndAbove: boolean = ApplicationState.hasUltimateOrAbove

    @bindable() protected accountType?: LedgerAccountType;
    @bindable() protected accountSubType?: LedgerAccountType;

    @bindable protected groupBy: undefined | 'accountType';

    async loadData() {
        this.paymentAccounts = Data.all('paymentaccounts');
        this.groupedByType = AccountsLedgerService.getAccountLedgersGroupedByType(true);
        this.loadBalances();
    }

    async loadBalances() {
        if (!this.paymentAccounts) return;
        const resp = await AccountsLedgerService.getAccountLedgerBalances(this.paymentAccounts.slice());
        this.balances = resp.balances;
    }

    attached() {
        this._dataChangedEvent = DataRefreshedEvent.subscribe<DataRefreshedEvent>(event => {
            if (event.hasAnyType('paymentaccounts')) return this.loadData();
            if (event.hasAnyType('transactions')) return this.loadBalances();
        });
        this.loadData();
    }
    detached() {
        this._dataChangedEvent && this._dataChangedEvent.dispose();
    }
}
