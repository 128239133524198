import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { Localisation } from '@nexdynamic/squeegee-common';
import { ApplicationState } from './ApplicationState';

export class LanguagesAndCountries {
    public static get languages(): { [code: string]: string } {
        return Localisation.languageCodes.reduce<{ [key: string]: string }>((map, key) => {
            map[key] = ApplicationState.localise(<TranslationKey>`languages.${key}`);
            return map;
        }, {});
    }

    public static get languagesArray() {
        const languages = LanguagesAndCountries.languages;
        return Object.keys(languages).map(value => {
            return { value, text: <TranslationKey>`languages.${value}` };
        });
    }

    public static get countries(): { [code: string]: string } {
        return Localisation.countryCodes.reduce<{ [key: string]: string }>((map, key) => {
            map[key] = ApplicationState.localise(<TranslationKey>`countries.${key}`);
            return map;
        }, {});
    }

    public static get countriesArray() {
        const countries = LanguagesAndCountries.countries;
        return Object.keys(countries).map(value => {
            return { value, text: <TranslationKey>`countries.${value}` };
        });
    }
}
