import createPalette from '@mui/material/styles/createPalette';
import type { IDirectoryEntryDetails } from '@nexdynamic/squeegee-portal-common';
import { ApplicationState } from '../ApplicationState';
import { Logger } from '../Logger';
import { Api } from '../Server/Api';

export const getDirectoryEntryDetails = async () => {
    try {
        const response = await Api.get<IDirectoryEntryDetails>(Api.apiEndpoint, `/api/directory/secure/${ApplicationState.dataEmail}`);
        if (response?.data?.config?.themeOptions) {
            if (!response.data.config.themeOptions.palette) {
                response.data.config.themeOptions.palette = createPalette({});
            }
            if (!response.data.config.themeOptions.palette.text) {
                response.data.config.themeOptions.palette.text = {};
            }
        }
        return response?.data;
    } catch (error) {
        Logger.error('Error during getDirectoryEntry', { error });
    }
};
