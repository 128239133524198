import type {
    ConnectedServiceSettings,
    Expense,
    ICustomerPaymentProvidersData,
    Tag,
    TranslationKey
} from '@nexdynamic/squeegee-common';
import {
    TagType, TransactionType
} from '@nexdynamic/squeegee-common';
import { bindable, inject } from 'aurelia-framework';
import type { Router } from 'aurelia-router';
import { BindingSignaler } from 'aurelia-templating-resources';
import { ApplicationState } from '../ApplicationState';
import type { IFabAction } from '../Components/Fabs/IFabAction';
import { ConnectedServicesService } from '../ConnectedServices/ConnectedServicesService';
import { Data } from '../Data/Data';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { FabWithActions } from '../FabWithActions';
import type { Filter } from '../Filters/Filter';
import type { IExpensesFilterItemDictionary } from '../Filters/Filters';
import { Filters } from '../Filters/Filters';
import { FiltersDialog } from '../Filters/FiltersDialog';
import { Logger } from '../Logger';
import { TransactionsBase } from '../Transactions/Components/TransactionsBase';
import { TransactionService } from '../Transactions/TransactionService';
import { Utilities } from '../Utilities';
import { ExpenseFormDialog } from './Components/ExpenseFormDialog';

@inject(BindingSignaler, FabWithActions)
export class Expenses extends TransactionsBase {
    protected router: Router;
    protected hideCancelledTransactions = ApplicationState.getSetting('global.hide-cancelled-transactions', false);
    public activate(_params: any, routeConfig: any) {
        this.router = routeConfig.navModel.router;
    }

    public currentFilter: Filter<IExpensesFilterItemDictionary> = Filters.expensesFilter([]);

    @bindable public searchText = '';
    public searchTextChanged(): void {
        this.filter();
    }

    private constructor(protected signaler: BindingSignaler, protected fabWithActions: FabWithActions) {
        super(signaler);
    }
    public async attached() {
        await super.attached();
        FabWithActions.register(this.getExpenseActions());
    }
    public detached() {
        super.detached();
        FabWithActions.unregister();
    }

    private filter() {
        this.updateTransactionByDayArray(this.currentFilter.filterOptions, this.searchText);
    }

    protected async openFilterMenu() {
        try {
            const dialog = new FiltersDialog<IExpensesFilterItemDictionary>(
                this.currentFilter,
                Filters.expensesFilter,
                this.getCategories()
            );
            await dialog.show(DialogAnimation.SLIDE_UP);
            if (!dialog.cancelled) {
                this.currentFilter = await dialog.getResult();
                this.filter();
            }
        } catch (error) {
            Logger.error('failed to open the filter menu', error);
        }
    }

    public getCategories() {
        const categories = Data.all<Tag>('tags', { type: TagType.EXPENSE_CATEGORY }).slice();
        return categories;
    }

    public loadData() {
        try {
            this._transactionsByDay = TransactionService.getTransIncVoidsByDay(TransactionType.Expense);

            this.updateTransactionByDayArray(this.currentFilter.filterOptions, this.searchText);
            this.currentFilter = Filters.expensesFilter(this.getCategories());
            this.filter();
        } catch (error) {
            Logger.error(`Error during loadData in expense list`, error);
        }
    }

    private _delegateAddExpense = () => this.addExpense();

    public async addExpense(expense?: Expense) {
        try {
            const dialog = new ExpenseFormDialog(expense);
            await dialog.show(DialogAnimation.SLIDE_UP);
        } catch (error) {
            Logger.error('Error in addExpense()', error);
        }
    }

    private getExpenseActions(): Array<IFabAction> {
        const fabActions: Array<IFabAction> = [
            {
                tooltip: 'actions.add-expense',
                actionType: 'action-new-expense',
                handler: this._delegateAddExpense,
                roles: ['Owner', 'Admin', 'Creator'],
            },
        ];
        const settings = ApplicationState.getSetting<ConnectedServiceSettings>('global.connected-services');

        if (settings) {
            for (const provider in settings) {
                const setting = settings[provider as keyof ICustomerPaymentProvidersData];
                if (!setting?.provides?.length) continue;
                if (setting.provides.indexOf('payments') > -1)
                    fabActions.push({
                        tooltip: `Sync with ${Utilities.toTitleCase(provider)}` as TranslationKey,
                        actionType: 'action-download',
                        handler: () => {
                            ConnectedServicesService.importPayments(provider as keyof ICustomerPaymentProvidersData);
                        },
                        roles: ['Owner', 'Admin'],
                    });
            }
        }

        return fabActions;
    }

    protected sortExpenseDesc = (expenseA: Expense, expenseB: Expense) => {
        if (expenseA.description !== undefined && expenseB.description !== undefined) {
            return expenseA.description > expenseB.description ? 1 : expenseA.description < expenseB.description ? -1 : 0;
        } else {
            return 0;
        }
    };

    protected sortExpenseAmount = (expenseA: Expense, expenseB: Expense) => {
        return expenseA.amount < expenseB.amount ? -1 : expenseA.amount > expenseB.amount ? 1 : 0;
    };
}
