import { bindable, inject } from 'aurelia-framework';
import type * as SortableModule from 'sortablejs';
import { DragSortingEvent } from '../../Events/DragSortingEvent';
import { GlobalFlags } from '../../GlobalFlags';
import type { ScheduleItem } from '../../Schedule/Components/ScheduleItem';

const Sortable = require('sortablejs/modular/sortable.complete.esm');
@inject(Element)
export class SortableCustomAttribute {
    @bindable() enabled?: boolean;
    @bindable() onDragSortComplete?: (
        oldIndex?: number | Array<number>,
        newIndex?: number | Array<number>,
        fromId?: string | null,
        toId?: string | null
    ) => any;
    @bindable() sortModeStarted: (event: any) => any;
    @bindable() sortMove?: (fromId?: string | null, toId?: string | null) => any;

    @bindable() isInSortMode: boolean;
    @bindable() selectedItems: Array<ScheduleItem>;

    private _sortable: SortableModule;

    public constructor(protected _element: HTMLElement) { }

    public async attached() {
        if (this.enabled) this.initSortMode();
    }

    private initSortMode() {
        this.isInSortMode = true;
        const options = <any>{
            group: { name: 'list', pull: true },
            delay: GlobalFlags.isMobile ? 100 : 0,
            animation: 150,
            handle: '.sort-handle',
            multiDrag: true,
            scrollSensitivity: 88,
            bubbleScroll: true,
            touchStartThreshold: 10,
            ghostClass: 'highlight-selected',

            scroll: document.getElementById('main-scroll-container'),

            onStart: (event: any) => {
                new DragSortingEvent(false);
                if (this.sortModeStarted) this.sortModeStarted(event);
                if (window.TapticEngine) window.TapticEngine.gestureSelectionChanged();
            },

            onMove: (event: any) => {
                if (window.TapticEngine) window.TapticEngine.gestureSelectionChanged();

                const fromId = event.dragged.getAttribute('data-id');
                const toId = event.related.getAttribute('data-id');
                if (this.sortMove) this.sortMove(fromId, toId);
            },

            onEnd: (event: any) => {
                new DragSortingEvent(true);
                if (this.onDragSortComplete) {
                    const oldIndex =
                        (event.oldIndicies && event.oldIndicies.length && event.oldIndicies.map((x: any) => x.index)) || event.oldIndex;
                    const newIndex =
                        (event.newIndicies && event.newIndicies.length && event.newIndicies.map((x: any) => x.index)) || event.newIndex;
                    const fromId = event.from.getAttribute('data-id');
                    const toId = event.to.getAttribute('data-id');
                    this.onDragSortComplete(oldIndex, newIndex, fromId, toId);
                }
                if (window.TapticEngine) window.TapticEngine.gestureSelectionChanged();
                const target: HTMLElement = event.to;
                if (target && target.ondrop) target.ondrop(event);
            },
        };

        if (this._sortable) this._sortable.destroy();

        this._sortable = new Sortable.default(this._element, options);
    }

    public async detached() {
        new DragSortingEvent(true);
        this._sortable && this._sortable.destroy && this._sortable.destroy();
    }
}
