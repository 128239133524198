import type { Subscription } from 'aurelia-event-aggregator';
import { bindable, inject } from 'aurelia-framework';
import Hammer from 'hammerjs';
import { ApplicationState } from '../ApplicationState';
import { DragSortingEvent } from '../Events/DragSortingEvent';

declare const TapticEngine: any;

const enum DraggingDirection {
    LEFT = 0,
    RIGHT = 1,
}

@inject(Element)
export class ListDragContainerCustomAttribute {
    /** Disable draging */
    @bindable() disable: boolean;

    /**
     * The class of the HTMLElement to attach the hammerjs events to
     * @type {string}
     * @memberOf SquirrlDragCustomAttribute
     */
    @bindable() targetClass: string;

    /**
     * Fired upon the completion of a pan left event or a swipe left event
     * @memberOf SquirrlDragCustomAttribute
     */
    @bindable() onleft: (model?: any) => Promise<void>;

    /** remove the element on completion of the left drag */
    @bindable() removeOnLeft = true;

    /**
     * Fired upon the completion of a pan right event or a swipe right event
     * @memberOf SquirrlDragCustomAttribute
     */
    @bindable() onright: (model?: any) => Promise<boolean>;

    /** remove the element on completion of the right drag */
    @bindable() removeOnRight = true;

    /**
     * Disables the ability to drag or swipe right
     * @type {boolean}
     * @memberOf SquirrlDragCustomAttribute
     */
    @bindable() disableOnRight: boolean;

    /**
     * Disables the ability to drag or swipe left
     * @type {boolean}
     * @memberOf SquirrlDragCustomAttribute
     */
    @bindable() disableOnleft: boolean;

    /**
     * Allowance of drag before the events are fired
     * @type {number}
     * @memberOf SquirrlDragCustomAttribute
     */
    private static _triggerSensitivity = 4;

    /**
     * Sets how fast a swipe must be for the events to auto fire
     * @type {number}
     * @memberOf SquirrlDragCustomAttribute
     */
    private static _velocitySensitivity = 3;

    /**
     * @private
     * @type {Subscription}
     * @memberOf SquirrlDragCustomAttribute
     */
    private _dragSortingSubscription: Subscription;

    /**
     * Hammerjs event mannager,
     * used to keep a reference to the attached event listener
     * @private
     * @type {HammerManager}
     * @memberOf SquirrlDragCustomAttribute
     */
    private _hammertime?: HammerManager;

    private target: HTMLElement | undefined | null;
    private _currentDirection: DraggingDirection;
    private alreadySwiping: boolean;

    constructor(private element: HTMLElement) {}

    /**
     * Called when the attached element has been loaded
     * @private
     * @memberOf SquirrlDragCustomAttribute
     */
    public attached() {
        if (ApplicationState.getSetting('global.disable-swiping', false)) return;

        navigator.vibrate =
            navigator.vibrate || (<any>navigator).webkitVibrate || (<any>navigator).mozVibrate || (<any>navigator).msVibrate;
        this._hammertime = new Hammer(this.element, {
            recognizers: [[Hammer.Pan, { direction: Hammer.DIRECTION_HORIZONTAL, threshold: 15 }]],
        });
        this.setEventListener();
    }

    /**
     * Adds a hammerjs event listener to the target element
     * @private
     * @memberOf SquirrlDragCustomAttribute
     */
    private setEventListener(): void {
        if (!this._hammertime) return;
        this._hammertime.on('panstart panmove panend pancancel', this.panHandler);

        this._dragSortingSubscription = DragSortingEvent.subscribe<DragSortingEvent>(event => {
            if (event.ended) {
                if (this._hammertime) return;

                this._hammertime = new Hammer(this.element, {
                    recognizers: [[Hammer.Pan, { direction: Hammer.DIRECTION_HORIZONTAL, threshold: 15 }]],
                });
                this._hammertime.on('panstart panmove panend pancancel', this.panHandler);
                return;
            }

            if (!this._hammertime) return;

            this._hammertime.off('panstart panmove panend pancancel');
            this._hammertime.stop(true);
            this._hammertime.destroy();
            delete this._hammertime;
        });
    }
    private _triggered = false;
    private panHandler = (event: HammerInput) => {
        event.preventDefault();
        if (event.pointerType === 'mouse') return;

        if (this.disable === true) return;

        this.target = this.getTarget(event);
        if (!this.target) return;

        this.target.style.transitionDuration = '0.0s';
        if (event.deltaX) {
            const direction = event.deltaX < 0 ? DraggingDirection.LEFT : DraggingDirection.RIGHT;
            if (event.type === 'panmove') {
                this.moveTarget(event.deltaX, this.target);
                if (this._currentDirection === undefined || this._currentDirection !== direction) this.updateCssClasses(direction);
                this._currentDirection = direction;
                const canTrigger = this.canTrigger(event, this.target);

                if (canTrigger === true && !this._triggered) {
                    this._triggered = true;
                    if (this.target) this.target.style.opacity = '0.6';
                    if (window.TapticEngine) window.TapticEngine.gestureSelectionChanged();
                    else if (navigator.vibrate) {
                        navigator.vibrate(100);
                    }
                } else if (canTrigger === false && this._triggered) {
                    if (window.TapticEngine) window.TapticEngine.gestureSelectionChanged();
                    if (this.target) this.target.style.opacity = '1';
                    this._triggered = false;
                }
                return;
            }
        }

        if (event.type === 'panstart') return this.resetElement(this.target);

        if (event.type === 'panend') this.onEventFinal(event, this.target);
        this.target.style.opacity = '1';
        const target = this.target;
        setTimeout(() => {
            this.resetElement(target, true);
        }, 250);
    };

    private canTrigger(event: HammerInput, target: HTMLElement) {
        const triggerWidthLimit = target.clientWidth / ListDragContainerCustomAttribute._triggerSensitivity;

        return Math.abs(event.deltaX) > triggerWidthLimit;
    }

    private getTarget(event: HammerInput): HTMLElement | null {
        let element: HTMLElement | null = event.target;

        while ((element = element.parentElement) && !element.classList.contains(this.targetClass));

        const draggingDisabled = element && (element.classList.contains('item-selectable') || element.classList.contains('item-disabled'));
        if (draggingDisabled) return null;
        return element;
    }
    /**
     * Moves the target element if the direction of movement is not disabled
     * @private
     * @param {number} percentage
     * @returns {boolean}
     *
     * @memberOf SquirrlDragCustomAttribute
     */
    private moveTarget(amount: number, target: HTMLElement) {
        const directionRight = amount > 0;

        if ((directionRight && this.disableOnRight) || (!directionRight && this.disableOnleft)) {
            target.style.transform = 'translateX(0)';
        } else {
            target.style.transform = 'translateX(' + amount + 'px)';
        }
    }

    /**
     * Takes action on the hammerjs pan events
     * @private
     * @param {HammerInput} event
     * @param {number} percentage
     * @returns {void}
     * @memberOf SquirrlDragCustomAttribute
     */
    private async onEventFinal(event: HammerInput, target: HTMLElement) {
        // If a quick swipe has occurred fire the events

        if (
            event.velocityX >= ListDragContainerCustomAttribute._velocitySensitivity ||
            event.velocityX <= -ListDragContainerCustomAttribute._velocitySensitivity
        ) {
            if (this.alreadySwiping) return;
            this.alreadySwiping = true;
            try {
                if (event.velocityX >= ListDragContainerCustomAttribute._velocitySensitivity) {
                    if (this.target) this.target.style.opacity = '0.6';
                    if (window.TapticEngine) window.TapticEngine.gestureSelectionChanged();
                    else if (navigator.vibrate) {
                        navigator.vibrate(100);
                    }
                    if (!(await this._onRight(target))) this.resetElement(target);
                    target.remove();
                } else if (event.velocityX <= -ListDragContainerCustomAttribute._velocitySensitivity) {
                    if (this.target) this.target.style.opacity = '0.6';
                    if (window.TapticEngine) window.TapticEngine.gestureSelectionChanged();
                    else if (navigator.vibrate) {
                        navigator.vibrate(100);
                    }
                    if (await this._onLeft(target)) return this.resetElement(target, !this.removeOnLeft);
                    else this.resetElement(target);
                }
            } finally {
                this.alreadySwiping = false;
            }
        } else {
            const canTrigger = this.canTrigger(event, target);
            // If a drag has reached the trigger level fire the events
            if (canTrigger && (this._currentDirection === DraggingDirection.LEFT || this._currentDirection === DraggingDirection.RIGHT)) {
                if (this.alreadySwiping) return;
                this.alreadySwiping = true;
                try {
                    if (this._currentDirection === DraggingDirection.LEFT) {
                        if (await this._onLeft(target)) return this.resetElement(target, !this.removeOnLeft);
                        else this.resetElement(target);
                    } else {
                        if (!(await this._onRight(target))) this.resetElement(target);
                        target.remove();
                    }
                } finally {
                    this.alreadySwiping = false; //note this sometimes doesn't get hit see bug #180598505 added in resetElement to ensure
                }
            } else {
                // await this.resetElement(target);
            }
            if (window.TapticEngine) window.TapticEngine.gestureSelectionEnd();
        }

        // return await this.resetElement(target, true);
    }
    /**
     * Updates the css classes and any other target classes
     * @private
     * @param {number} value
     * @memberOf SquirrlDragCustomAttribute
     */
    private updateCssClasses(direction: DraggingDirection): void {
        const classList = this.element.classList;
        if (direction === DraggingDirection.RIGHT && !this.disableOnRight) {
            classList.remove('dragging-left');
            classList.add('dragging-right');
        } else if (direction === DraggingDirection.LEFT && !this.disableOnleft) {
            classList.remove('dragging-right');
            classList.add('dragging-left');
        } else {
            classList.remove('dragging-right');
            classList.remove('dragging-left');
        }
    }
    /**
     * Destroys the event listener when element has been detached
     * Called by aurelia
     * @private
     * @memberOf SquirrlDragCustomAttribute
     */
    public detached(): void {
        if (this.target) this.resetElement(this.target);
        if (this._dragSortingSubscription) this._dragSortingSubscription.dispose();

        if (!this._hammertime) return;

        this._hammertime.off('pan panstart panmove panend pancancel', this.panHandler);
        this._hammertime.remove('pan');
        this._hammertime.stop(true);
        this._hammertime.destroy();

        if (this._dragSortingSubscription) this._dragSortingSubscription.dispose();
    }
    /**
     * Resets the target element back to its original position
     * @private
     * @memberOf SquirrlDragCustomAttribute
     */
    private async resetElement(target: HTMLElement, animate = true) {
        this.alreadySwiping = false; //added to fix swiping bug #180598505
        animate ? (target.style.transitionDuration = '0.2s') : (target.style.transitionDuration = '0');
        target.style.transform = 'translateX(0)';
        this.target = undefined;
        if (window.TapticEngine) window.TapticEngine.gestureSelectionStart();
        await new Promise<void>(resolve => {
            setTimeout(() => resolve(), animate ? 200 : 0);
        });
    }

    /**
     * Fires when a left event has complete
     * calls the bindable onleft promise which when resolved resets the element
     * @private
     * @memberOf SquirrlDragCustomAttribute
     */
    private async _onLeft(target: HTMLElement) {
        if (this.disableOnleft === true) return;
        if (this.onleft) {
            await this.onleft((<any>target).model);
            return false;
        }
        return false;
    }
    /**
     * Fires when a right event has complete
     * calls the bindable onright promise which when resolved resets the element if destroyed param has been set to true
     * @private
     * @memberOf SquirrlDragCustomAttribute
     */
    private async _onRight(target: HTMLElement) {
        if (this.disableOnRight === true) return false;
        if (this.onright) {
            await this.onright((<any>target).model);
            return false;
        }
        return false;
    }
}
