import type { Account, AccountUser, LngLat, TranslationKey } from '@nexdynamic/squeegee-common';
import type { Subscription } from 'aurelia-event-aggregator';
import { bindable, bindingMode, computedFrom, inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import type * as leaflet from 'leaflet';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import { DateTimePicker } from '../../Components/DateTimePicker/DateTimePicker';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { NavigationDialog } from '../../Dialogs/Navigation/NavigationDialog';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { MenuBarActionsEvent } from '../../Events/MenuBarActionsEvent';
import { DateFilterOption } from '../../Jobs/Filters/DateFilterOption';
import type { ScheduleByGroup } from '../../Schedule/Components/ScheduleByGroup';
import { ScheduleDetailsDialog } from '../../Schedule/Components/ScheduleDetailsDialog';
import type { ScheduleItem } from '../../Schedule/Components/ScheduleItem';
import { ScheduleService } from '../../Schedule/ScheduleService';
import { Api } from '../../Server/Api';
import { AssignmentService } from '../../Users/Assignees/AssignmentService';
import { UserService } from '../../Users/UserService';
import type { Workload } from '../../Users/Workload';
import type { RouteScheduleItem } from '../Components/RouteScheduleItem';
import { DayPilot } from '../DayPilot';
import { RouteOptimisationService } from '../RouteOptimisationService';

@inject(Router, DayPilot)
export class Overview {
    protected scheduleDataDay: ScheduleByGroup = {};
    protected routeItems: Array<RouteScheduleItem> = [];
    protected multiUserEnabled = ApplicationState.multiUserEnabled;

    protected loading = true;
    protected driveDistanceMetres: number | undefined;
    protected driveDurationSeconds: number | undefined;

    protected travelDataLastUpdated: TranslationKey | undefined;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) selectedItem: RouteScheduleItem | undefined;
    protected account: Account = ApplicationState.account;
    isToday = true;

    private constructor(public router: Router, public dayPilot: DayPilot) {}

    protected async selectedItemChanged(routeItem?: RouteScheduleItem) {
        if (!routeItem?.scheduleItem) return;
        const dialog = new ScheduleDetailsDialog(routeItem.scheduleItem);
        await dialog.show(DialogAnimation.SLIDE);
        this.selectedItem = undefined;
    }

    protected async selectedWorkLoadChanged() {
        if (!this.selectedWorkLoad) {
            this.selectedWorkLoadIndex = 0;
            return;
        }
        const userName = this.selectedWorkLoad.userOrTeam ? this.selectedWorkLoad.userOrTeam.name : undefined;
        const index = this.workloads.findIndex(x => x.userOrTeam !== undefined && x.userOrTeam.name === userName);
        if (index > -1) this.selectedWorkLoadIndex = index;
        await this.refreshScheduleItemsList(false);
    }

    private _dataRefreshedSub: Subscription;
    private _positionUpdatedSub: Subscription;
    private _applicationStateUpdatedSub: Subscription;

    protected isRouteOptimised = true; // Updated by checkRouteOptimised

    // TODO: Maybe dont need this element refrences anymore?
    protected listContainer: HTMLUListElement;
    protected mapRef: leaflet.Map;

    public async attached() {
        new LoaderEvent(true);
        setTimeout(async () => {
            await this.refreshScheduleItemsList();
            this.updateWorkloads();

            this._dataRefreshedSub = DataRefreshedEvent.subscribe((event: DataRefreshedEvent) => {
                if (event.hasAnyType('customers', 'joboccurrences', 'occurrencesbyday')) this.refreshScheduleItemsList();
                if (event.hasAnyType('accountuser')) this.updateWorkloads();
            });

            // Creates actions in the menu bar
            new MenuBarActionsEvent([
                {
                    tooltip: 'menubar.select-date',
                    actionType: 'action-calculate-route',
                    handler: this._delegateSelectDate,
                    roles: ['Owner', 'Admin'],
                },
            ]);
            new LoaderEvent(false);
        }, 20);
    }

    private _delegateSelectDate = async () => {
        const datePickerDialog = new DateTimePicker(false, this.dayPilot.currentDate.format('YYYY-MM-DD'));
        datePickerDialog.init();
        await datePickerDialog.open();
        if (datePickerDialog.canceled) return;

        this.dayPilot.currentDate = moment(datePickerDialog.selectedDate);
        this.isToday = this.dayPilot.currentDate.isSame(moment(), 'day');

        new LoaderEvent(true);
        await this.refreshScheduleItemsList();
        new LoaderEvent(false);
    };

    public detached() {
        this._dataRefreshedSub && this._dataRefreshedSub && this._dataRefreshedSub.dispose();
        this._positionUpdatedSub && this._positionUpdatedSub.dispose();
        this._applicationStateUpdatedSub && this._applicationStateUpdatedSub.dispose();
        new MenuBarActionsEvent();
    }

    @computedFrom('account.businessAddress')
    protected get hasVerifiedBusinessAddress() {
        return this.account.businessAddress && this.account.businessAddress.lngLat && this.account.businessAddress.lngLat.length === 2
            ? true
            : false;
    }

    protected setBusinessAddress() {
        ApplicationState.setBusinessAddress();
    }

    protected async refreshScheduleItemsList(updateWorkLoads = true) {
        const day = this.dayPilot.currentDate.format('YYYY-MM-DD');
        this.loading = true;
        new LoaderEvent(true);
        this.scheduleDataDay = await this.getOccurrencesForDay(day);
        this.routeItems = RouteOptimisationService.getAllRouteItems(this.scheduleDataDay);
        // Filter for selected user
        if (updateWorkLoads) this.updateWorkloads();
        const workload = this.selectedWorkLoad;

        if (workload && workload.userOrTeam && workload.userOrTeam.name !== 'general.all-title') {
            let userEmailsAndTeamId: Array<string> = [];
            if (this.selectedWorkLoadIndex > 0) {
                if (workload && workload.userOrTeam) {
                    const isTeam = workload.userOrTeam.resourceType === 'team';
                    if (isTeam) {
                        userEmailsAndTeamId.push(workload.userOrTeam._id);
                    } else {
                        const email = (<AccountUser>workload.userOrTeam).email;
                        if (email) userEmailsAndTeamId = await ScheduleService.getUserAndTeamIds(email);
                    }
                }

                if (!userEmailsAndTeamId || !userEmailsAndTeamId.length) {
                    this.routeItems = this.routeItems.filter(
                        x => x.scheduleItem && AssignmentService.isUnassigned(x.scheduleItem?.occurrence)
                    );
                } else {
                    this.routeItems = this.routeItems.filter(
                        x => x.scheduleItem && AssignmentService.isAssigned(x.scheduleItem?.occurrence, userEmailsAndTeamId, false)
                    );
                }
            }
        }

        this.loading = false;
        new LoaderEvent(false);
    }

    private async getOccurrencesForDay(day: string) {
        return ScheduleService.getScheduledJobsByDay(this.dayPilot.currentDate, new DateFilterOption('', day), [], 'status');
    }

    public navigate() {
        if (this.selectedItem) {
            this.openNavigationDialog(this.selectedItem);
        }
    }

    public openNavigationDialog(location: RouteScheduleItem) {
        if (location) {
            const dialog = new NavigationDialog(location);
            dialog.showIfNeeded();
        }
    }

    // TODO: Review twhether this is needed...
    protected fitMapToLocationToggle = true;
    protected noLocationSelectedChanged(newValue: boolean) {
        if (newValue) this.fitMapToLocationToggle = !this.fitMapToLocationToggle;
    }

    @computedFrom('routeItems.length')
    protected get hasUnverifiedRouteItems() {
        return this.routeItems.some(ri => !ri.isVerified);
    }

    protected get showNoBusinessAddressMessage() {
        return this.travelDataLastUpdated && !this.hasVerifiedBusinessAddress;
    }

    protected get showRoutingErrorMessage() {
        return (
            this.travelDataLastUpdated === undefined &&
            !this.hasUnverifiedRouteItems &&
            Api.isConnected &&
            this.hasVerifiedBusinessAddress &&
            !this.loading
        );
    }

    protected get showUnverifiedAddressesMessage() {
        return this.hasValidRouteItems && this.hasUnverifiedRouteItems;
    }

    protected get showOfflineMessage() {
        return (
            (this.travelDataLastUpdated === undefined || !this.hasVerifiedBusinessAddress) &&
            !this.hasUnverifiedRouteItems &&
            !Api.isConnected
        );
    }

    protected get hasValidRouteItems() {
        return this.travelDataLastUpdated !== undefined && this.routeItems.length;
    }

    protected workloads: Array<Workload>;
    protected userLocations: Array<LngLat | undefined>;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) protected selectedWorkLoad: Workload | undefined;
    protected selectedWorkLoadIndex = 0;
    protected async selectUser(index: number) {
        this.selectedWorkLoadIndex = index;
        this.selectedWorkLoad = this.workloads[index];
    }

    private updateWorkloads() {
        const day = this.dayPilot.currentDate.format('YYYY-MM-DD');
        const workloads = UserService.getWorkloadsForUsersAndTeams(
            this.routeItems.filter(ri => ri.scheduleItem).map(ri => ri.scheduleItem) as Array<ScheduleItem>,
            day
        );
        this.workloads = workloads;
        if (!this.selectedWorkLoad) this.selectedWorkLoad = workloads[0];
        else this.selectedWorkLoad = workloads.find(workload => workload.userOrTeam?.name === this.selectedWorkLoad?.userOrTeam?.name);
    }
}
