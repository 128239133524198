import type { Customer } from '@nexdynamic/squeegee-common';
import { Api } from '../../Server/Api';

export const getProviderSourceLink = (customer: Customer, provider: string): string => {
    const dev = !Api.isProduction;
    switch (provider) {
        case 'gocardless': {
            const mandateId = customer.paymentProviderMetaData?.gocardless?.sourceOrMandateId;
            return `https://manage${dev ? '-sandbox' : ''}.gocardless.com/mandates/${mandateId}`;
        }
        case 'stripe': {
            const customerId = customer.externalIds?.stripe || '';
            return `https://dashboard.stripe.com/${dev ? 'test/' : ''}customers/${customerId}`;
        }
        case 'xero':
            return customer.paymentProviderMetaData?.dna?.sourceOrMandateId || '';
        case 'dna':
            return customer.paymentProviderMetaData?.dna?.sourceOrMandateId || '';
        default:
            return '';
    }
};
