import { Prompt } from '../../Dialogs/Prompt';
import { openSystemBrowser } from '../../Utilities';

export const getProviderExternalLinkSource = async (link: string) => {
    const prompt = new Prompt('prompts.confirm-title', 'prompts.external-link-source', {
        okLabel: 'general.yes',
        cancelLabel: 'general.no',
    });

    await prompt.show();
    if (prompt.cancelled) return;

    if (link) openSystemBrowser(link);
};
