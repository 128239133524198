import type { Customer, ICustomerPaymentProvidersData } from '@nexdynamic/squeegee-common';

export const getPaymentMethodDescription = (customer: Customer, provider: keyof ICustomerPaymentProvidersData) => {
    if (!customer.paymentProviderMetaData) return '';
    if (!customer.paymentProviderMetaData[provider]) return '';
    if (provider === 'stripe') {
        return (customer.paymentProviderMetaData['stripe']?.cardLast4 || '').padStart(19, '**** ');
    }
    return customer.paymentProviderMetaData?.[provider]?.sourceOrMandateId || '';
};
