import { bindable } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { AdvertisementAssetCache } from '../../ReactUI/Marketplace/services/AdvertisementService';

export class MarketplaceStatusCustomElement {
    @bindable() assetId: string;

    protected correctUserRoles = ApplicationState.isInAnyRole(['Admin', 'Owner']);

    protected isAlreadyListed = false;
    attached() {
        this.isAlreadyListed = AdvertisementAssetCache.assetIsListed(this.assetId);
    }
}
