import type { Customer, JobOccurrence, LngLat, Quote, TranslationKey } from '@nexdynamic/squeegee-common';
import { Location, copyObject } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import { AddressLookupDialog } from '../Dialogs/AddressLookupDialog';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { PinAddressDialog } from '../Dialogs/PinAddress/PinAddressDialog';
import { Prompt } from '../Dialogs/Prompt';
import { LoaderEvent } from '../Events/LoaderEvent';
import { JobOccurrenceService } from '../Jobs/JobOccurrenceService';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import type { ScheduleItem } from '../Schedule/Components/ScheduleItem';
import { LocationApi } from '../Server/LocationApi';
import { Utilities } from '../Utilities';

export class LocationUtilities {
    public static async pinJobAtMyLocation(scheduleItem: ScheduleItem): Promise<boolean> {
        const position = ApplicationState.position;
        if (!position) {
            new NotifyUserMessage('Current location is not available' as TranslationKey);
            return false;
        }

        const lngLat: LngLat = [position.coords.longitude, position.coords.latitude];
        const jobLocation = scheduleItem.job.location || new Location();
        const isCreatorOrAbove = ApplicationState.isInAnyRole(['Admin', 'Creator', 'Owner', 'JobEditor']);
        const dialog = new PinAddressDialog(jobLocation, lngLat, !isCreatorOrAbove);
        const newLocation = await dialog.show();
        if (dialog.cancelled) return false;

        const job = scheduleItem.customer.jobs[scheduleItem.job._id];
        job.location = newLocation;
        if (isCreatorOrAbove && job.location?.addressId && job.location.addressId === scheduleItem.customer.address.addressId) {
            if (await Prompt.continue('The customer has the same address, would you like to update this also?' as TranslationKey)) {
                scheduleItem.customer.address = copyObject(newLocation)
            }
        }
        scheduleItem.occurrence.location = copyObject(newLocation)
        await Data.put(scheduleItem.customer);
        return true
    }

    public static sortPostcodes(postcodeA: string, postcodeB: string): number {
        const postcodeRegExp = /(^[A-Za-z]{1,2})([0-9]{1,2})([\s]?)([0-9]{1})([A-Za-z]{1,2})/;
        const postcodeAGroups: Array<string> | null = postcodeRegExp.exec(postcodeA);
        const postcodeBGroups: Array<string> | null = postcodeRegExp.exec(postcodeB);
        if (postcodeAGroups && postcodeAGroups.length === 6) {
            if (postcodeBGroups && postcodeBGroups.length === 6) {
                if (postcodeAGroups[5] && postcodeBGroups[5]) {
                    if (postcodeAGroups[5] < postcodeBGroups[5]) {
                        return -1;
                    } else if (postcodeAGroups[5] > postcodeBGroups[5]) {
                        return 1;
                    } else {
                        return 0;
                    }
                } else {
                    return 0;
                }
            } else {
                return -1;
            }
        } else {
            return 1;
        }
    }

    public static getArea(points: Array<LngLat>) {
        let area = 0;
        let i = 0;
        let j = 0;

        for (i = 0, j = points.length - 1; i < points.length; j = i, i++) {
            const point1 = points[i];
            const point2 = points[j];
            area += point1[0] * point2[1];
            area -= point1[1] * point2[0];
        }
        area /= 2;

        return area;
    }
    public static getCentroid(points: Array<LngLat>): LngLat {
        const lngs = points.map(point => point[0]).sort((x, y) => x - y);
        const lats = points.map(point => point[1]).sort((x, y) => x - y);
        const minLng = lngs[0];
        const maxLng = lngs[lngs.length - 1];
        const minLat = lats[0];
        const maxLat = lats[lats.length - 1];

        const lng = minLng + (maxLng - minLng) / 2;
        const lat = minLat + (maxLat - minLat) / 2;

        return [lng, lat];
    }

    public static getImageDataURL(url: string): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const img = new Image();
            img.setAttribute('crossOrigin', 'anonymous');
            let data = '';

            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx && ctx.drawImage(img, 0, 0);

                try {
                    data = canvas.toDataURL();
                    resolve(data);
                } catch (e) {
                    reject(e);
                }
            };

            try {
                img.src = url;
            } catch (e) {
                reject(e);
            }
        });
    }

    public static getDistance(lngLat: LngLat, fromLngLat?: LngLat): number | void {
        let lat: number;
        let lng: number;
        if (fromLngLat) {
            lng = fromLngLat[0];
            lat = fromLngLat[1];
        } else {
            const position = ApplicationState.position;
            if (position && position.coords) {
                lng = position.coords.longitude;
                lat = position.coords.latitude;
            } else if (ApplicationState.account.businessAddress && ApplicationState.account.businessAddress.lngLat) {
                lng = ApplicationState.account.businessAddress.lngLat[0];
                lat = ApplicationState.account.businessAddress.lngLat[1];
            } else return 0;
        }
        const units = <any>ApplicationState.distanceUnits;

        return LocationUtilities.getDistanceBetweenPoints([lng, lat], [lngLat[0], lngLat[1]], units);
    }

    public static async updateLocationIfUnverified(location: Location): Promise<false | Location> {
        if (!location.isVerified) {
            const dialog = new AddressLookupDialog(location);
            const updatedLocation = await dialog.show(DialogAnimation.SLIDE_UP);
            if (!dialog.cancelled) {
                Object.assign(location, Utilities.copyObject(updatedLocation));
                return location;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    public static locationsAreTheSame(locationA?: Location, locationB?: Location) {
        return (
            locationA &&
            locationB &&
            locationA.addressDescription === locationB.addressDescription &&
            JSON.stringify(locationA.lngLat) === JSON.stringify(locationB.lngLat)
        );
    }

    public static async updateCustomerAndJobLocationsOnVerification(
        customer: Customer,
        jobOrCustomerId: string,
        originalLocation: Location,
        newLocation: Location
    ): Promise<void> {
        if (!customer) return;

        let dirty = false;
        if (
            customer._id !== jobOrCustomerId &&
            customer.address &&
            LocationUtilities.locationsAreTheSame(originalLocation, customer.address)
        ) {
            new LoaderEvent(false);
            const prompt = new Prompt(
                'Address Changed' as TranslationKey,
                `Also update customer billing address? <br/> from: ${originalLocation.addressDescription} <br/> to: ${newLocation.addressDescription}` as TranslationKey,
                { okLabel: 'general.yes', cancelLabel: 'general.no' }
            );
            await prompt.show();
            if (!prompt.cancelled) {
                customer.address = Utilities.copyObject(newLocation);
                dirty = true;
            }
        } else if (customer._id === jobOrCustomerId) {
            customer.address = Utilities.copyObject(newLocation);
            dirty = true;
        }

        let updateOtherJobs: boolean | undefined;
        for (const jobId in customer.jobs || {}) {
            const job = customer.jobs[jobId];
            if (jobId === jobOrCustomerId) {
                job.location = Utilities.copyObject(newLocation);
                await LocationUtilities.updateJobOccurrenceLocations(job._id, newLocation);
                dirty = true;
            } else if (job.location && LocationUtilities.locationsAreTheSame(originalLocation, job.location)) {
                if (updateOtherJobs === undefined) {
                    new LoaderEvent(false);
                    updateOtherJobs = await new Prompt(
                        ApplicationState.localise('jobs.update-jobs'),
                        ApplicationState.localise('jobs.update-previous-jobs-address'),
                        {
                            okLabel: 'general.yes',
                            cancelLabel: 'general.no',
                        }
                    ).show();
                }
                if (updateOtherJobs) {
                    job.location = Utilities.copyObject(newLocation);
                    await LocationUtilities.updateJobOccurrenceLocations(job._id, newLocation);
                    dirty = true;
                }
            }
        }

        for (const quote of Data.all<Quote>('quotes', { customerId: customer._id })) {
            let quoteDirty = false;
            if (quote.address && LocationUtilities.locationsAreTheSame(originalLocation, quote.address)) {
                if (updateOtherJobs === undefined) {
                    new LoaderEvent(false);
                    updateOtherJobs = await new Prompt(
                        ApplicationState.localise('jobs.update-jobs'),
                        ApplicationState.localise('jobs.update-previous-jobs-address'),
                        {
                            okLabel: 'general.yes',
                            cancelLabel: 'general.no',
                        }
                    ).show();
                }
                if (updateOtherJobs) {
                    quote.address = Utilities.copyObject(newLocation);
                    quoteDirty = true;
                }
            }
            for (const quoteJob of quote.items) {
                if (!quoteJob.address || !LocationUtilities.locationsAreTheSame(originalLocation, quoteJob.address)) continue;
                quoteJob.address = Utilities.copyObject(newLocation);
                quoteDirty = true;
            }
            if (quoteDirty) await Data.put(quote, true, 'lazy');
        }

        if (dirty) await Data.put(customer, true, 'lazy');
    }

    public static async updateJobOccurrenceLocations(jobId: string, newLocation: Location) {
        const customerOccurrences = JobOccurrenceService.getOccurrencesByJobId(jobId).filter(x => !x.status);

        const updatedOccurrences: Array<JobOccurrence> = [];

        for (let i = 0; i < customerOccurrences.length; i++) {
            const jobOccurrence = customerOccurrences[i];
            if (LocationUtilities.locationsAreTheSame(newLocation, jobOccurrence.location)) continue;
            jobOccurrence.location = Utilities.copyObject(newLocation);
            updatedOccurrences.push(jobOccurrence);
        }

        if (updatedOccurrences.length) await Data.put(updatedOccurrences);
    }

    public static convertMetresIntoMiles(metres: number) {
        const factor = 0.000621371;
        return metres * factor;
    }

    public static getDistanceBetweenPoints(lngLatA: LngLat, lngLatB: LngLat, units: 'miles' | 'kilometres'): number {
        const dLat = LocationUtilities.toRad(lngLatB[1] - lngLatA[1]);
        const dLon = LocationUtilities.toRad(lngLatB[0] - lngLatA[0]);
        const lat1 = LocationUtilities.toRad(lngLatA[1]);
        const lat2 = LocationUtilities.toRad(lngLatB[1]);

        const a = Math.pow(Math.sin(dLat / 2), 2) + Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        let R;
        switch (units) {
            case 'miles':
                R = 3960;
                break;
            case 'kilometres':
                R = 6373;
                break;
            default:
                throw new Error('unknown option given to "units"');
        }

        const distance = R * c;
        return distance;
    }

    private static toRad(degree: number) {
        return (degree * Math.PI) / 180;
    }

    private static _sortPropertyName(aPropertyName: string, bPropertyName: string): number {
        // need to sort on prefix
        const aNumeric = parseInt(aPropertyName, 10);
        const bNumeric = parseInt(bPropertyName, 10);
        if (!isNaN(aNumeric) && !isNaN(bNumeric)) {
            // both have numeric parts - first if they are different return based on number
            if (aNumeric !== bNumeric) return aNumeric - bNumeric;
            // otherwise compare them on the nonNumeric part of prefix
            const aNumbericSuffix = aPropertyName.slice(aNumeric.toString().length);
            const bNumbericSuffix = bPropertyName.slice(bNumeric.toString().length);
            if (aNumbericSuffix && bNumbericSuffix) {
                if (aNumbericSuffix < bNumbericSuffix) return -1;
                if (aNumbericSuffix > bNumbericSuffix) return 1;
                return 0;
            } else if (bNumbericSuffix) {
                return -1;
            } else {
                return 1;
            }
        } else if (isNaN(bNumeric)) {
            return -1;
        } else if (isNaN(aNumeric)) {
            return 1;
        } else {
            if (aPropertyName < bPropertyName) return -1;
            if (aPropertyName > bPropertyName) return 1;
            return 0;
        }
    }

    private static _sortFirstLineOfAddress(aFirstLine: string, bFirstLine: string): number {
        // property name detected by first space on first line of address
        const propertyNameRegExp = /\s/;
        const aPropertyNameResults = aFirstLine.split(propertyNameRegExp);
        const bPropertyNameResults = bFirstLine.split(propertyNameRegExp);
        if (aPropertyNameResults && aPropertyNameResults.length && bPropertyNameResults && bPropertyNameResults.length) {
            //propertyName can be detected, street can be deducted, sort on street first
            const aStreet = aFirstLine.slice(aPropertyNameResults[0].length).trim();
            const bStreet = bFirstLine.slice(bPropertyNameResults[0].length).trim();
            if (aStreet < bStreet) return -1;
            if (aStreet > bStreet) return 1;
            return LocationUtilities._sortPropertyName(aPropertyNameResults[0].trim(), bPropertyNameResults[0].trim());
        } else if (bPropertyNameResults && bPropertyNameResults.length) {
            // let no property name 'a' get ahead in the sort
            return -1;
        } else if (aPropertyNameResults && aPropertyNameResults.length) {
            // let no property name 'b' get ahead in the sort
            return 1;
        } else {
            // directly compare firstline addresses without a clear property name
            if (aFirstLine < bFirstLine) return -1;
            if (aFirstLine > bFirstLine) return 1;
            return 0;
        }
    }

    public static sortAddressDescription(aDescription: string, bDescription: string): number {
        // first line detected by delimited by first comma
        const addressFirstLineRegExp = /,/;
        const aAddressFirstLineResults = aDescription.split(addressFirstLineRegExp);
        const bAddressFirstLineResults = bDescription.split(addressFirstLineRegExp);
        // if there's a first line then sort by that because unverified addresses will be complicated format variations
        // and verified addresses will already be sorted by distance
        if (aAddressFirstLineResults && aAddressFirstLineResults.length && bAddressFirstLineResults && bAddressFirstLineResults.length) {
            return LocationUtilities._sortFirstLineOfAddress(aAddressFirstLineResults[0].trim(), bAddressFirstLineResults[0].trim());
        } else if (bAddressFirstLineResults && bAddressFirstLineResults.length) {
            // put one liner 'a' in front of list
            return -1;
        } else if (aAddressFirstLineResults && aAddressFirstLineResults.length) {
            // put one liner 'b' in front of list
            return 1;
        } else {
            // a and b have one line addresses, return by direct compare
            if (aDescription < bDescription) return -1;
            if (aDescription > bDescription) return 1;
            return 0;
        }
    }

    public static async pinAddress(existingLocation: Location) {
        let currentLocation: Location;
        const currentPosition = ApplicationState.position;
        let currentLatLng: LngLat = [-2, 52];

        if (currentPosition) currentLatLng = [currentPosition.coords.longitude, currentPosition.coords.latitude];
        else if (ApplicationState.account.businessAddress.lngLat) {
            currentLatLng = [ApplicationState.account.businessAddress.lngLat[0], ApplicationState.account.businessAddress.lngLat[1]];
        }

        if (!existingLocation.isVerified && existingLocation.addressId) {
            if (existingLocation.addressSource === 'getAddress') {
                const updatedLocation = await LocationApi.getDetailsForGetAddressId(existingLocation.addressId);
                if (updatedLocation) {
                    existingLocation.isVerified = updatedLocation.isVerified;
                    existingLocation.lngLat = updatedLocation.lngLat;
                    existingLocation.addressDescription = updatedLocation.addressDescription;
                }
            } else if (existingLocation.addressSource === 'addressService') {
                const lngLat = await LocationApi.getLngLatForLoqateAddress(existingLocation.addressId);
                if (lngLat) {
                    existingLocation.isVerified = true;
                    existingLocation.lngLat = lngLat;
                }
            }
        }

        if (existingLocation.isVerified) currentLocation = Utilities.copyObject(existingLocation);
        else {
            currentLocation = new Location(currentLatLng, 'locationPickerManual', existingLocation.addressDescription);
        }
        const dialog = new PinAddressDialog(currentLocation, currentLatLng);
        const newLocation: Location = await dialog.show(DialogAnimation.SLIDE_UP);
        if (!dialog.cancelled) {
            existingLocation.addressDescription = newLocation.addressDescription;
            if (newLocation.isVerified && newLocation.lngLat) {
                existingLocation.isVerified = true;
                existingLocation.lngLat = [newLocation.lngLat[0], newLocation.lngLat[1]];
            } else {
                existingLocation.isVerified = false;
                existingLocation.lngLat = null as any as LngLat;
            }

            if (newLocation.lngLat?.[0] !== currentLocation.lngLat?.[0] || newLocation.lngLat?.[1] !== currentLocation.lngLat?.[1]) {
                existingLocation.lngLatSource = 'locationPickerManual';
            }

            return existingLocation;
        }
    }

    public static nearestLngLat({ source, lngLats }: { source: LngLat; lngLats: Array<LngLat> }) {
        let nearestLngLat: LngLat | undefined;
        let closestDistance = Number.MAX_VALUE;
        for (const lngLatToCheck of lngLats) {
            const distance = LocationUtilities.getDistance(source, lngLatToCheck) || Number.MAX_VALUE;
            if (distance < closestDistance) {
                closestDistance = distance;
                nearestLngLat = lngLatToCheck;
            }
        }
        return nearestLngLat;
    }
}
