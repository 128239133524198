import type { JobGroup, TranslationKey } from '@nexdynamic/squeegee-common';
import { FrequencyType, TagType } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import { DateTimePicker } from '../../Components/DateTimePicker/DateTimePicker';
import { Data } from '../../Data/Data';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import type { ScheduleItem } from '../../Schedule/Components/ScheduleItem';

export class ConfirmDayCompletedDialog extends CustomDialog<{ confirmedDate: string; adjustFutureSchedule: boolean }> {
    public dateFormat = 'll';
    protected todaysDate: string = moment().format('YYYY-MM-DD');
    protected actions: Array<{ icon: string; label: TranslationKey; subLabel: string; dateISO?: string }> = [];

    // Store the value to return to the caller.
    protected adjustSchedule: boolean = ApplicationState.account.schedulingBehavior === 'update-schedule';

    // Control based on whether there are any scheduled items that are recurreling
    protected itemsWithRoundSchedulesCount: number;
    protected itemsWithOwnRecurringScheduleCount: number;

    constructor(protected plannedDate: string, protected scheduleItems: Array<ScheduleItem>) {
        super('confirmDayCompletedDialog', '../Jobs/Components/ConfirmDayCompletedDialog.html', 'dialogs.day-completed-confirm-title', {
            cssClass: 'schedule-action-dialog',
            okLabel: '',
            cancelLabel: '',
            showCloseX: true,
        });
        this.setActions();
        if (ApplicationState.isInAnyRole(['Owner', 'Admin', 'Planner'])) {
            const rounds = Data.all<JobGroup>('tags', x => x.type === TagType.ROUND).reduce<{ [id: string]: JobGroup }>((d, next) => {
                d[next._id] = next;
                return d;
            }, {});
            this.itemsWithRoundSchedulesCount = scheduleItems.filter(
                item => item.job.rounds && item.job.rounds.find(round => rounds[round._id] && rounds[round._id].frequencyData)
            ).length;

            this.itemsWithOwnRecurringScheduleCount = scheduleItems.filter(item => {
                if (item.job.frequencyType === FrequencyType.NoneRecurring) return false;

                if (!item.job.rounds || !item.job.rounds.length) return true;

                for (const round of item.job.rounds) {
                    if (rounds[round._id] && rounds[round._id].frequencyData) {
                        return false;
                    }
                }

                return true;
            }).length;
            this.adjustSchedule = ApplicationState.account.schedulingBehavior === 'update-schedule';
        }
    }

    protected async selectDate(date?: string) {
        if (date) this.ok({ confirmedDate: date, adjustFutureSchedule: this.adjustSchedule });
        else {
            const datePickerDialog = new DateTimePicker(false, this.todaysDate, 'dates.select-completed-date', false);
            datePickerDialog.init();
            await datePickerDialog.open();
            if (!datePickerDialog.canceled) {
                this.ok({ confirmedDate: datePickerDialog.selectedDate, adjustFutureSchedule: this.adjustSchedule });
            }
        }
    }

    protected setActions() {
        if (this.todaysDate >= this.plannedDate)
            this.addAction(
                'schedule',
                'dates.planned-date',
                moment(this.plannedDate, 'YYYY-MM-DD').format(this.dateFormat),
                this.plannedDate
            );
        this.addAction('today', 'dates.today', moment(this.todaysDate, 'YYYY-MM-DD').format(this.dateFormat), this.todaysDate);
        this.addAction('date_range', 'dates.select-date', ApplicationState.localise('dates.any-date-up-to-today'));
    }

    private addAction(icon: string, label: TranslationKey, subLabel: string, dateISO?: string) {
        this.actions.push({ icon, label, subLabel, dateISO });
    }
}
