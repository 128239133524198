import type { IJob } from '@nexdynamic/squeegee-common';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';

export class JobSavedMessage extends NotifyUserMessage {
    constructor(public job: IJob, public jobId: string) {
        super('general.job-saved');
    }
}

export class JobDeletedMessage extends NotifyUserMessage {
    constructor(public jobId: string) {
        super('general.job-deleted');
    }
}
