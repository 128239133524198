import { bindable } from 'aurelia-framework';
import { UIActivity } from './UIActivity';

export class CustomerNotesAndActivitiesCustomElement {
    @bindable()
    protected activities: Array<UIActivity>;

    @bindable()
    protected editNote: (noteId: string) => void;
    protected delete = async (activity: UIActivity) => {
        if (await UIActivity.delete(activity)) {
            this.activities.splice(this.activities.indexOf(activity), 1);
        }
    };

    protected edit = async (activity: UIActivity) => {
        this.editNote(activity.id);
    };
}
