import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { FabWithActions } from '../FabWithActions';
import type { ScheduleItem } from '../Schedule/Components/ScheduleItem';
import { CustomDialog } from './CustomDialog';
import './ViewDialog.scss';

export class ViewDialog extends CustomDialog<Array<ScheduleItem>> {
    constructor(title: TranslationKey) {
        super('View-View', './ViewDialog.html', title, {
            okLabel: '',
            cancelLabel: '',
            destructive: true,
            disableScroll: false,
            cssClass: 'view-dialog',
            noObfuscator: true,
        });
    }

    protected selectedItemsData: { selectedItems: Array<ScheduleItem> } = { selectedItems: [] };

    async init() {
        setTimeout(() => FabWithActions.unregister());
    }

    delegateSave = async () => {
        this.ok(this.selectedItemsData.selectedItems);
    };
}
