import type { SoapyRow } from './SoapyRow';

export const parseSoapyJobStatus = (status: string): SoapyRow['jobStatus'] => {
    switch (status) {
        case 'Done':
            return 'Done';
        case 'Due':
            return 'Due';
        case 'Scheduled':
            return 'Scheduled';
        default:
            return '';
    }
};
