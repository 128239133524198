import moment from 'moment';
import { DateFilterOption } from './DateFilterOption';

export class DateFilter {
    public options: Array<DateFilterOption> = [];
    constructor() {
        const d = DateFilter;
        this.options.push(d.DUE, d.TODAY, d.TOMORROW, d.THIS_WEEK, d.NEXT_WEEK, d.NEXT_30_DAYS);
    }
    private static readonly NOW = moment();
    public static readonly OVERDUE = new DateFilterOption(
        'overdue',
        undefined,
        DateFilter.NOW.clone().subtract(1, 'day').format('YYYY-MM-DD')
    );
    public static readonly DUE = new DateFilterOption('due', undefined, DateFilter.NOW.format('YYYY-MM-DD'));
    public static readonly TODAY = new DateFilterOption('today', DateFilter.NOW.format('YYYY-MM-DD'));
    public static readonly TOMORROW = new DateFilterOption('tomorrow', DateFilter.NOW.clone().add(1, 'd').format('YYYY-MM-DD'));
    public static readonly THIS_WEEK = new DateFilterOption(
        'this-week',
        DateFilter.NOW.clone().startOf('w').format('YYYY-MM-DD'),
        DateFilter.NOW.clone().endOf('week').format('YYYY-MM-DD')
    );
    public static readonly NEXT_WEEK = new DateFilterOption(
        'next-week',
        DateFilter.NOW.clone().add(7, 'd').startOf('w').format('YYYY-MM-DD'),
        DateFilter.NOW.clone().add(7, 'd').endOf('week').format('YYYY-MM-DD')
    );
    public static readonly NEXT_30_DAYS = new DateFilterOption(
        'next-30-days',
        DateFilter.NOW.format('YYYY-MM-DD'),
        DateFilter.NOW.clone().add(30, 'd').format('YYYY-MM-DD')
    );
}
