import type { StoredEvent } from '@nexdynamic/squeegee-common';
import { Data } from '../Data/Data';


export const migrateStoredEvents = async (oldId: string, newId: string) => {
    const events = Data.all<StoredEvent>(
        'storedevents',
        { occurrenceId: oldId }
    ).slice();

    const newEvents = events.map(event => {
        event.occurrenceId = newId;
        return event;
    });

    if (newEvents?.length) await Data.put(newEvents);
};
