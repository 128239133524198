import type { Customer} from '@nexdynamic/squeegee-common';
import { Tag, TagType, Transaction, TransactionType } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { Logger } from '../Logger';
import { TagService } from '../Tags/TagService';
import { Data } from './Data';
import { ImportValidator } from './ImportValidator';
import { SmartRoundParser } from './SmartRoundParser';
import type { SmartRoundRecord } from './SmartRoundRecord';

export class SmartRoundImporter {
    private static requiredColumns: Array<keyof SmartRoundRecord> = [
        'SmartRound ID',
        'First Name',
        'Last Name',
        'Company',
        'Email',
        'Mobile',
        'Telephone',
        'Address',
        'Balance',
        'Brief',
        'Price',
        'Next Due',
        'Last Done',
        'Every',
        'Frequency',
    ];

    public static async import(csv: string, deleteExistingData = false): Promise<{ error: string; success: boolean }> {
        const output = { error: '', success: false };
        try {
            const parsedCSV = SmartRoundParser.parseCSV(csv, ApplicationState.account.language === 'en-US' ? 'MM-DD-YY' : undefined);
            if (parsedCSV && parsedCSV.data && parsedCSV.data.length) {
                const validator = ImportValidator.validateCSV(Object.keys(parsedCSV.data[0]), SmartRoundImporter.requiredColumns);
                if (validator.isValid) {
                    await SmartRoundImporter.addRecords(parsedCSV.data, deleteExistingData);
                    output.success = true;
                } else {
                    output.error = 'The Selected CSV is missing the following columns: ' + validator.errors.join(', \n');
                }
            } else {
                output.error = 'Unable to parse the selected CSV.';
            }
        } catch (error) {
            Logger.error(`Error during import SmartRound CSV`, error);
            throw new Error(error);
        }
        return output;
    }

    private static async addRecords(records: Array<SmartRoundRecord>, deleteExistingData: boolean) {
        const rounds: { [id: string]: Tag } = {};
        const services: { [id: string]: Tag } = {};

        if (!deleteExistingData) {
            await SmartRoundImporter.mergeWithExistingRounds(rounds);
            await SmartRoundImporter.mergeWithExistingServices(services);
        } else {
            const defaultService = new Tag('Default Service', TagType.SERVICE);
            services[defaultService.description] = defaultService;
        }

        const customerReferences: { [ref: string]: Customer } = {};
        const customers: Array<Customer> = [];
        let transactions: Array<Transaction> = [];

        for (let i = 0; i < records.length; i++) {
            const record = records[i];

            try {
                const customer = SmartRoundParser.convertRecord(record, customerReferences, services);
                const newTransactions = SmartRoundImporter.getCustomerBalance(record, customer);

                if (!customer) throw new Error('Could not convert customer record');
                customers.push(customer);
                if (newTransactions.length) transactions = transactions.concat(newTransactions);
            } catch (error) {
                throw new Error(error);
            }
        }
        if (customers.length) {
            try {
                const validator = ImportValidator.validateOutput(customers, transactions, rounds, services);
                if (validator.isValid) {
                    if (deleteExistingData) await Data.deleteAllData(false);
                    await SmartRoundImporter.addToDatabase(customers, transactions, rounds, services);
                    Data.initSync();
                } else {
                    throw new Error('Smart Round Data failed validation ' + '\n' + validator.errors.join('\n'));
                }
            } catch (error) {
                throw new Error('Could not import smart round CSV' + error);
            }
        } else {
            throw new Error('Could not import smart round CSV');
        }
    }
    private static async addToDatabase(
        customers: Array<Customer>,
        transactions: Array<Transaction>,
        rounds: { [id: string]: Tag },
        services: { [id: string]: Tag }
    ) {
        const roundsArray = Object.keys(rounds).map(key => rounds[key]);
        const servicesArray = Object.keys(services).map(key => services[key]);
        let all: Array<any> = [];
        all = all.concat(customers, transactions, roundsArray, servicesArray);
        await Data.put(all);
    }

    private static async mergeWithExistingRounds(rounds: { [id: string]: Tag }) {
        const existingRounds = TagService.getTagsByType(TagType.ROUND);

        if (existingRounds && existingRounds.length) {
            for (let i = 0; i < existingRounds.length; i++) {
                const existingRound = existingRounds[i];
                rounds[existingRound._id] = existingRound;
            }
        }

        return rounds;
    }

    private static getCustomerBalance(record: SmartRoundRecord, customer: Customer): Array<Transaction> {
        const value = SmartRoundParser.getValue(record, ['Balance']);
        if (!isNaN(Number(value))) {
            const balance = -(value || 0);
            if (balance !== 0)
                return [
                    new Transaction(
                        TransactionType.Adjustment,
                        'adjustment.initial-balance-imported',
                        balance,
                        customer._id,
                        undefined,
                        'Balance import'
                    ),
                ];
        } else Logger.info('nan:' + value);
        return [];
    }

    private static async mergeWithExistingServices(services: { [id: string]: Tag }) {
        const existingService = TagService.getTagsByType(TagType.SERVICE);

        if (existingService && existingService.length) {
            for (let i = 0; i < existingService.length; i++) {
                const existingRound = existingService[i];
                services[existingRound.description] = existingRound;
            }
        }

        if (!services['Default Service']) {
            const defaultService = new Tag('Default Service', TagType.SERVICE);
            services[defaultService.description] = defaultService;
        }

        return services;
    }
}
