import { bindable } from 'aurelia-framework';

export class ProgressBarCustomElement {
    protected bar: HTMLElement & { MaterialProgress?: { setProgress: (value: number) => void; setBuffer: (value: number) => void } };

    @bindable progress: number;

    @bindable color: 'error' | 'success' | 'primary' = 'primary';

    progressChanged(newValue: number | undefined) {
        if (this.bar && this.bar.MaterialProgress) {
            this.bar.MaterialProgress.setProgress(newValue || 0);
        }
    }

    attached() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        this.bar.addEventListener('mdl-componentupgraded', function () {
            this.MaterialProgress.setProgress(self.progress || 0);
        });
    }
}
