import type { AvailableFrequency, FrequencyData, Service } from '@nexdynamic/squeegee-common';
import { Frequency, uuid } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { TextDialog } from '../../Dialogs/TextDialog';
import { AdvancedServicesService } from '../../ServicesAdvanced/AdvancedServicesService';
import { CustomDialog } from '../CustomDialog';
import { FrequencyPickerDialog } from './FrequencyPickerDialog';

export class AvailableFrequenciesPickerDialog extends CustomDialog<AvailableFrequency[]> {
    protected availableFrequencies: AvailableFrequency[];
    protected menuTitle = 'Pick Available Frequencies';

    constructor(public service: Service) {
        super('availableFrequenciesPickerDialog', './FrequencyPicker/AdvancedFrequenciesPickerDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'frequency-picker-dialog has-header-content',
            isSecondaryView: true,
        });
        this.availableFrequencies = service.availableFrequencies || [];
    }

    protected getAvailableFrequencyDescription(availableFrequency: AvailableFrequency): string {
        return `(Click to Edit) - ${this.getFrequencyFromAvailableFrequency(availableFrequency).summary}`;
    }

    protected getFrequencyFromAvailableFrequency = (availableFrequency: AvailableFrequency): Frequency => {
        return new Frequency(
            availableFrequency.frequency.firstScheduledDate ? moment(availableFrequency.frequency.firstScheduledDate) : moment(),
            availableFrequency.frequency.interval || 1,
            availableFrequency.frequency.type || 0,
            availableFrequency.frequency.weekOfMonth,
            availableFrequency.frequency.dayOfMonth,
            availableFrequency.frequency.dayOfWeek,
            availableFrequency.frequency.range && availableFrequency.frequency.range
        );
    };

    protected removeFrequency = async (availableFrequency: AvailableFrequency) => {
        this.availableFrequencies = [...this.availableFrequencies.filter(e => e.id !== availableFrequency.id)];
    };

    protected addFrequency = async () => {
        const id = uuid();
        const newFrequency: AvailableFrequency = {
            id,
            frequency: {
                type: 0,
            },
            description: '',
            label: '',
            priceAffector: {
                id: id + '-effect',
                type: 'percentage',
                value: 100,
            },
        };
        this.editFrequency(newFrequency);
    };

    protected editFrequency = async (availableFrequency: AvailableFrequency) => {
        const oldId = availableFrequency.id;
        const newId = uuid();

        // label
        const labelDialog = new TextDialog(
            'dialogs.available-frequency-label',
            'dialogs.available-frequency-label-input',
            availableFrequency.label || '',
            'dialogs.available-frequency-label-input',
            value => {
                if (value && value.trim()) return true;
                else return 'validation.label-required';
            }
        );
        const newLabel = await labelDialog.show();
        if (labelDialog.cancelled) return;
        else if (newLabel && newLabel.trim()) availableFrequency.label = newLabel.trim();

        // description
        const descriptionDialog = new TextDialog(
            'dialogs.available-frequency-description',
            'dialogs.available-frequency-description-input',
            availableFrequency.description || '',
            'dialogs.available-frequency-description-input'
        );
        const newDescription = await descriptionDialog.show();

        if (descriptionDialog.cancelled) return;
        else if (!descriptionDialog.cancelled) availableFrequency.description = newDescription.trim();

        // frequency
        const frequencyData: FrequencyData = {
            ...(availableFrequency.frequency || {}),
            firstScheduledDate: availableFrequency.frequency.firstScheduledDate || moment().format('YYYY-MM-DD'),
            interval: availableFrequency.frequency.interval || 1,
            type: availableFrequency.frequency.type || 0,
        };
        const frequencyDialog = new FrequencyPickerDialog({
            frequencyData,
            mode: 'frequency',
            defaultShowDateRequiredToggle: true,
            defaultDateRequired: false,
        });
        const pickedFrequency: Partial<FrequencyData> = await frequencyDialog.show();
        if (!pickedFrequency) return;
        else {
            delete pickedFrequency.firstScheduledDate;
            availableFrequency.frequency = pickedFrequency;
        }

        const priceModifier = await AdvancedServicesService.getPriceModifierValues(availableFrequency.priceAffector, newId);
        if (!priceModifier) return;

        availableFrequency.priceAffector = priceModifier;

        // replace id and spot in list
        availableFrequency.id = newId;
        this.availableFrequencies = [...this.availableFrequencies.filter(e => e.id !== oldId && e.id !== newId), availableFrequency];
    };

    protected confirmFrequencies = () => {
        this.ok(this.availableFrequencies);
    };
}
