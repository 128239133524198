import type { Customer } from '@nexdynamic/squeegee-common';
import { FrequencyType, Job, Location, Tag, TagType } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { parse } from 'papaparse';
import { CustomerService } from '../Customers/CustomerService';
import type { SmartRoundRecord } from './SmartRoundRecord';

export class SmartRoundParser {
    private static DEFAULT_NAME = 'Unknown';
    private static INPUT_DATE_FORMAT = 'DD-MM-yy';
    private static OUTPUT_DATE_FORMAT = 'YYYY-MM-DD';

    public static parseCSV(csv: string, dateFormat?: string) {
        if (dateFormat) SmartRoundParser.INPUT_DATE_FORMAT = dateFormat;
        else SmartRoundParser.INPUT_DATE_FORMAT = 'DD-MM-yy';
        return parse(csv, { header: true, skipEmptyLines: true });
    }

    public static convertRecord(record: SmartRoundRecord, customerReferences: { [id: string]: Customer }, services: { [id: string]: Tag }) {
        const customerRef = SmartRoundParser.getValue(record, ['Address']);
        let customer: Customer = customerReferences[customerRef];

        if (customer) {
            const job = SmartRoundParser.getJob(record, customer, services);
            if (job) customer.jobs[job._id] = job;
        } else {
            customer = CustomerService.create();

            customer.name = SmartRoundParser.getName(record);
            customer.address.addressDescription = SmartRoundParser.getAddressDescription(record);
            customer.telephoneNumber = SmartRoundParser.getValue(record, ['Telephone']);
            customer.telephoneNumberOther = SmartRoundParser.getValue(record, ['Mobile']);
            customer.email = SmartRoundParser.getValue(record, ['Email']);
            const job = SmartRoundParser.getJob(record, customer, services);
            if (job) customer.jobs[job._id] = job;
        }

        if (customerRef) customerReferences[customerRef] = customer;

        return customer;
    }

    public static getValue(record: SmartRoundRecord, keys: Array<keyof SmartRoundRecord>) {
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            (<any>key) = key.trim();
            if (record[key] && record[key].trim()) return record[key].trim();
        }

        return '';
    }

    private static getJob(record: SmartRoundRecord, customer: Customer, services: { [id: string]: Tag }) {
        const jobAddressDescription = SmartRoundParser.getAddressDescription(record);
        const job = new Job(
            customer._id,
            undefined,
            undefined,
            undefined,
            customer.address.addressDescription === jobAddressDescription
                ? customer.address
                : new Location(undefined, undefined, jobAddressDescription)
        );


        const price = record['Price'].trim();
        if (isNaN(Number(price))) {
            throw new Error(price + ' is not a valid price');
        }
        // TODO: Make sure this sets the day of week on the job to the same as the job date.
        job.date = SmartRoundParser.getDate(record, job);
        job.description = SmartRoundParser.getValue(record, ['SmartRound ID']);
        job.price = Number(price);
        job.services = SmartRoundParser.getService(record, services);
        // if (job.frequencyType !== FrequencyType.NoneRecurring) {
        SmartRoundParser.setFrequencyType(
            SmartRoundParser.getValue(record, ['Every']),
            SmartRoundParser.getValue(record, ['Frequency']),
            SmartRoundParser.getValue(record, ['Next Due']),
            job
        );
        // }
        return job;
    }

    private static getService(record: SmartRoundRecord, services: { [id: string]: Tag }) {
        const newTagDescription = SmartRoundParser.getValue(record, ['Brief']);

        if (!newTagDescription) return [services['Default Service']];

        if (services[newTagDescription] === undefined) {
            const newService = new Tag(newTagDescription, TagType.SERVICE);
            services[newService.description] = newService;
            return [newService];
        } else {
            return [services[newTagDescription]];
        }
    }

    private static setFrequencyType(intervalStr: string, frequency: string, nextDue: string, job: Job) {
        //const frequencyValues = frequency.match(/^(\d)?\s+([a-z]+)/i);

        //if (frequencyValues) {
        if (frequency) {
            const nextDueDate = moment(nextDue);

            const interval = Number(intervalStr);

            if (!nextDueDate.isValid()) {
                job.frequencyInterval = interval;
                switch (frequency) {
                    case 'Weeks':
                        job.frequencyType = FrequencyType.Weeks;
                        break;
                    case 'Days':
                        job.frequencyType = FrequencyType.Days;
                        break;
                    case 'Months':
                        job.frequencyType = FrequencyType.DayOfMonth;
                        break;
                }
            } else {
                job.frequencyInterval = interval;
                if (frequency === 'Weeks') {
                    job.frequencyType = FrequencyType.Weeks;
                    job.frequencyDayOfWeek = nextDueDate.isoWeekday();
                }
                if (frequency === 'Months') {
                    job.frequencyWeekOfMonth = nextDueDate.isoWeek();
                    job.frequencyDayOfWeek = nextDueDate.isoWeekday();
                    if (job.frequencyWeekOfMonth) {
                        job.frequencyType = FrequencyType.MonthlyDayOfWeek;
                    } else {
                        job.frequencyType = FrequencyType.DayOfMonth;
                    }
                }
            }
        } else {
            job.frequencyInterval = 0;
            job.frequencyType = FrequencyType.NoneRecurring;
        }
    }

    private static getDate(record: SmartRoundRecord, job: Job) {
        const dateString = SmartRoundParser.getValue(record, ['Next Due', 'Last Done']);

        if (!dateString || dateString.match(/Never Done/i)) {
            job.frequencyInterval = 0;
            job.frequencyType = FrequencyType.NoneRecurring;
            return moment().format(SmartRoundParser.OUTPUT_DATE_FORMAT);
        }

        if (dateString.match(/Yesterday/i)) {
            return moment().subtract(1, 'day').format(SmartRoundParser.OUTPUT_DATE_FORMAT);
        } else if (dateString.match(/Today/i)) {
            return moment().format(SmartRoundParser.OUTPUT_DATE_FORMAT);
        } else if (dateString.match(/Tomorrow/i)) {
            return moment().add(1, 'day').format(SmartRoundParser.OUTPUT_DATE_FORMAT);
        }
        const date = moment(dateString, SmartRoundParser.INPUT_DATE_FORMAT);
        if (moment.isMoment(date)) {
            return date.format(SmartRoundParser.OUTPUT_DATE_FORMAT);
        } else {
            throw new Error(dateString + ' Is not a valid date format');
        }
    }

    private static getAddressDescription(record: SmartRoundRecord) {
        const addressLine1 = SmartRoundParser.getValue(record, ['Address']);
        /* */
        return `${addressLine1 ? addressLine1 + ' ' : ''}`.trim();
        /* */
    }

    private static getName(record: SmartRoundRecord) {
        const name = SmartRoundParser.getValue(record, ['First Name']);
        const lastName = SmartRoundParser.getValue(record, ['Last Name']);
        return `${name ? name + ' ' : ''}${lastName ? lastName + ' ' : ''}` || SmartRoundParser.DEFAULT_NAME;
    }
}
