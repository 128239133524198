import { type Notification } from "@nexdynamic/squeegee-common";
import { fetchNotifications } from "../../Notifications/functions/fetchNotifications";
import { Api } from "../../Server/Api";


type GetInvoiceNotificationsResult = {
    success: true;
    notifications: Array<Notification>;
} | {
    success: false;
};

export const getInvoiceNotifications = async (invoiceId: string): Promise<GetInvoiceNotificationsResult> => {
    try {
        // Data.all<Notification>('notifications', { relatedItemId: invoiceId }).slice().sort(sortByCreatedDateDesc);
        const notifications = [];
        if (Api.isConnected) {
            // Also check with the api for the statuses if we are online
            const result = await fetchNotifications({
                take: 25,
                skip: 0,
                relatedItemId: invoiceId
            });
            notifications.push(...result);
        }
        return {
            success: true,
            notifications
        };
    } catch (error) {
        return {
            success: false,
        };
    }
}