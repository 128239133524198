import type { AccountUser, Customer, Job, JobInitialFields, Team } from '@nexdynamic/squeegee-common';
import { Location } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../ApplicationState';
import { CustomerDeletedMessage, CustomerSavedMessage } from '../../Customers/CustomerMessage';
import { CustomerService } from '../../Customers/CustomerService';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import { Prompt } from '../../Dialogs/Prompt';
import { LoaderEvent } from '../../Events/LoaderEvent';
import type { Subscription } from '../../Events/SqueegeeEventAggregator';
import { JobActions } from '../../Jobs/actions/JobActions';
import { LocationUtilities } from '../../Location/LocationUtilities';
import { Logger } from '../../Logger';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { AssignmentService } from '../../Users/Assignees/AssignmentService';
import { Utilities } from '../../Utilities';
import { JobService } from '../JobService';

export class EditJobDialog extends CustomDialog<boolean> {
    private static _cssClasses = 'job-edit-dialog details-dialog has-header-content';
    protected menuTitle: string;
    protected failedValidation = false;
    protected formIsDirty: boolean;
    protected stateFlags = ApplicationState.stateFlags;

    private originalJob: string;
    private _originalAddress: Location;

    protected job: Job;

    protected assignees: Array<AccountUser | Team>;

    constructor(private customer: Customer, job: Job) {
        super('edit-job-' + job._id, '../Jobs/Components/EditJobDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: EditJobDialog._cssClasses,
            isSecondaryView: true,
            destructive: true,
        });

        this.job = Utilities.copyObject(job);
        for (const s of this.job.services) {
            if (s.price === undefined) s.price = 0;
            if (s.quantity === undefined) s.quantity = 1;
        }
        this.originalJob = JSON.stringify(this.job);
        this.menuTitle = 'Edit Job';
        this._originalAddress = (job.location && Utilities.copyObject(job.location)) || new Location();
    }

    private _customerChangedSub: Subscription;
    private _customerRemovedSub: Subscription;

    public async abortCancel() {
        const job = JSON.stringify(this.job);
        const dirty = this.originalJob !== job;
        if (dirty) {
            return !(await Prompt.discard());
        }
        return false;
    }
    public async init() {
        this.assignees = AssignmentService.getAssignees(this.job);
        this._customerChangedSub = CustomerSavedMessage.subscribe(() => this._reload());
        this._customerRemovedSub = CustomerDeletedMessage.subscribe((message: CustomerDeletedMessage) => {
            if (message.customerId === this.customer._id) return super.cancel();
        });
    }

    public dispose() {
        this._customerChangedSub && this._customerChangedSub.dispose();
        this._customerRemovedSub && this._customerRemovedSub.dispose();
        super.dispose();
    }

    protected async save() {
        if (JobService.validateJobAndNotifyUI(this.job).length) {
            this.failedValidation = true;
        } else {
            new LoaderEvent(true);
            try {
                if (!LocationUtilities.locationsAreTheSame(this._originalAddress, this.job.location)) {
                    if (this.job.location)
                        await LocationUtilities.updateCustomerAndJobLocationsOnVerification(
                            this.customer,
                            this.job._id,
                            this._originalAddress,
                            this.job.location
                        );
                }
                this.updateJobStartDatesOnActivation();

                await JobService.addOrUpdateJob(
                    this.customer._id,
                    this.job,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    this.assignees.map(x => x._id)
                );

                new LoaderEvent(false);
                this.ok();
            } catch (error) {
                new LoaderEvent(false);
                new NotifyUserMessage('notifications.job-save-error');
                Logger.error(`Error during save in the edit job dialog`, { job: this.job, error });
            }
        }
    }

    protected updateJobStartDatesOnActivation() {
        const originalJob = <Customer>JSON.parse(this.originalJob);
        if (originalJob.state === 'inactive' && this.job.state === 'active') {
            JobService.setJobDateToNextAvailable(this.job);
        }
    }

    private async _reload() {
        const customer = CustomerService.getCustomer(this.customer._id);
        if (!customer) return;

        this.customer = customer;
        this.menuTitle = `Edit Job For ${this.customer.name}`;

        if (this.customer.jobs && this.customer.jobs[this.job._id]) {
            this.job = this.customer.jobs[this.job._id];
        } else {
            super.cancel();
        }
    }

    protected saveJob = async (job: Job, fields?: JobInitialFields) => {
        this.failedValidation = false;
        const { error, success } = await JobActions.save(
            this.customer,
            job,
            this.assignees.map(x => x._id),
            fields
        );

        if (success) this.ok(true);
        this.failedValidation = Boolean(error);
    };
}
