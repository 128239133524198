import type moment from 'moment';


export const updateNextDateByDays = (nextDate: moment.Moment, days: number[], amount: number) => {
    let nextDay = nextDate.isoWeekday();
    let index = days.indexOf(nextDay);
    let count = 8;
    while (index === -1 && count--) {
        if (nextDay < 7) {
            nextDay++;
        } else {
            nextDate.add(1, 'week');
            nextDay = 0;
        }
        index = days.indexOf(nextDay);
    }
    let daysToAdd = amount;
    if (daysToAdd > 0) {
        while (daysToAdd--) {
            index++;
            if (index >= days.length) {
                index = 0;
                nextDate.add(1, 'week');
            }
        }
    } else if (daysToAdd < 0) {
        while (daysToAdd++) {
            index--;
            if (index < 0) {
                index = days.length - 1;
                nextDate.subtract(1, 'week');
            }
        }
    }
    nextDate.isoWeekday(days[index]);
    return nextDate;
};
