import { bindable, inject } from 'aurelia-framework';
import PerfectScrollBar from 'perfect-scrollbar';
import type { Subscription } from '../Events/SqueegeeEventAggregator';
import { ViewResizeEvent } from '../Events/ViewResizeEvent';
import { GlobalFlags } from '../GlobalFlags';
import { ScheduleActionEvent } from '../Schedule/Actions/ScheduleActionEvent';

@inject(Element)
export class ScrollbarCustomAttribute {
    @bindable disabled = false;

    private viewResizeSub: Subscription;
    private initialised = false;
    private ps: PerfectScrollBar;
    private _scheduleActionSub: Subscription;
    constructor(private element: HTMLElement) { }

    disabledChanged(newValue: boolean) {
        if (GlobalFlags.isMobile) return;
        if (newValue && this.element) this.ps.destroy();
        else if (this.initialised) this.ps = new PerfectScrollBar(this.element);
    }

    attached() {
        if (!GlobalFlags.isMobile) {
            window.addEventListener('resize', this.update);
            window.addEventListener('orientationchange', this.update);
            this._scheduleActionSub = ScheduleActionEvent.subscribe(() => this.update());
            this.ps = new PerfectScrollBar(this.element);
            this.viewResizeSub = ViewResizeEvent.subscribe(this.update);
            this.initialised = true;
        }
    }

    detached() {
        if (!GlobalFlags.isMobile) {
            window.removeEventListener('resize', this.update);
            window.removeEventListener('orientationchange', this.update);
            this._scheduleActionSub && this._scheduleActionSub.dispose();
            this.viewResizeSub && this.viewResizeSub.dispose();
            this.ps && this.ps.destroy();
        }
    }

    private update = () => {
        this.ps && this.ps.update();
    };
}
