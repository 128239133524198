// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#custom-dialog .send-message-to-customer--small {
  max-width: 360px !important;
  max-height: 260px !important;
}
#custom-dialog .send-message-to-customer--small .dialog-content {
  border-radius: 4px;
}
#custom-dialog .send-message .tox-tinymce {
  height: 90% !important;
  flex: 1 !important;
}`, "",{"version":3,"sources":["webpack://./src/Dialogs/SendMessageToCustomer.scss"],"names":[],"mappings":"AACI;EACI,2BAAA;EACA,4BAAA;AAAR;AACQ;EACI,kBAAA;AACZ;AAIQ;EACI,sBAAA;EACA,kBAAA;AAFZ","sourcesContent":["#custom-dialog {\n    .send-message-to-customer--small {\n        max-width: 360px !important;\n        max-height: 260px !important;\n        .dialog-content {\n            border-radius: 4px;\n        }\n    }\n\n    .send-message {\n        .tox-tinymce {\n            height: 90% !important;\n            flex: 1 !important;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
