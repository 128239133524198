import type { Subscription } from 'aurelia-event-aggregator';
import { EventAggregator } from 'aurelia-event-aggregator';
import type { SqueegeeEvent } from './SqueegeeEvent';
export type { Subscription } from 'aurelia-event-aggregator';

export class SqueegeeEventAggregator {
    private static instance = <any>new EventAggregator();
    /**
     * Publishes a message.
     * @param event The event or channel to publish to.
     * @param data The data to publish on the channel.
     */
    public static doPublish(event: SqueegeeEvent) {
        return SqueegeeEventAggregator.instance.publish(event);
    }

    /**
     * Subscribes to a message channel or message type.
     * @param event The event channel or event data type.
     * @param callback The callback to be invoked when when the specified message is published.
     */
    public static doSubscribe<TEventType extends typeof SqueegeeEvent | SqueegeeEvent, TEventInstanceType extends SqueegeeEvent>(
        event: TEventType,
        callback: (message: TEventInstanceType) => void
    ): Subscription {
        return SqueegeeEventAggregator.instance.subscribe(event, callback);
    }

    /**
     * Subscribes to a message channel or message type, then disposes the subscription automatically after the first message is received.
     * @param event The event channel or event data type.
     * @param callback The callback to be invoked when when the specified message is published.
     */
    public static doSubscribeOnce<TEventType extends typeof SqueegeeEvent | SqueegeeEvent, TEventInstanceType extends SqueegeeEvent>(
        event: TEventType,
        callback: (message: TEventInstanceType) => void
    ): Subscription {
        return SqueegeeEventAggregator.instance.subscribeOnce(event, callback);
    }
}
