import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { GlobalFlags } from '../GlobalFlags';
import { Utilities } from '../Utilities';
import { CustomDialog } from './CustomDialog';

export class FileUploadDialog extends CustomDialog<Array<File>> {
    protected fileInput: HTMLInputElement;
    protected uploadButton: HTMLButtonElement;
    protected accept = GlobalFlags.isAndroidMobileDevice ? 'image/*,video/*' : '';

    constructor(
        private _allowedFileTypes: Array<string>,
        public uploadTitle: TranslationKey = 'uploads.select-a-file',
        public maxSize?: number | undefined,
        protected selectMultiple = false
    ) {
        super('fileUploadDialog', './FileUploadDialog.html', '', { cssClass: 'file-input-dialog', okLabel: 'general.upload' });

        if (this.uploadTitle === 'uploads.select-a-file' && this.selectMultiple) {
            this.uploadTitle = 'uploads.select-files';
        }
    }


    fileNames: string = ApplicationState.localise(this.uploadTitle);
    onFilesChangedHandler?: (event: Event) => void;

    async init() {
        this.onFilesChangedHandler = (event) => {
            const input = event.target as HTMLInputElement;
            const files = input.files;
            if (files && files.length) {
                const names: Array<TranslationKey> = [];
                for (let i = 0; i < files.length; i++) {
                    const item = files.item(i);
                    if (item && item.name) names.push(item.name as TranslationKey);
                }

                return Utilities.localiseList(names);
            } else {
                this.fileNames = ApplicationState.localise(this.uploadTitle);
            }
        }
        this.fileInput.addEventListener('change', this.onFilesChangedHandler)
    }

    public dispose(): void {
        if (this.onFilesChangedHandler) {
            this.fileInput.removeEventListener('change', this.onFilesChangedHandler);
        }
    }

    protected async openFileChooser() {
        this.fileInput.click();
    }

    public async getResult(): Promise<Array<File> | undefined> {
        const fileInput = <HTMLInputElement>document.getElementById('fileInput');
        if (!fileInput || !fileInput.files || !fileInput.files.length) return;

        const files: Array<File> = [];
        for (let i = 0; i < fileInput.files.length; i++) {
            if (fileInput.files[i] && fileInput.files[i].size) {
                files.push(fileInput.files[i]);
            }
        }
        return files;
    }

    public async onValidate(): Promise<true | TranslationKey> {
        if (!this.fileInput.files || this.fileInput.files.length === 0) return 'validation.file-required';
        const item = this.fileInput.files.item(0);
        if (this.maxSize !== undefined && item && item.size > this.maxSize) {
            const fileSize = Utilities.formatBytes(this.maxSize);
            return ApplicationState.localise('validation.file-max-size', { size: fileSize });
        }

        const error = ApplicationState.localise('validation.file-allowed-types', {
            allowed: Utilities.localiseList((this._allowedFileTypes || []) as Array<TranslationKey>),
        });
        for (let i = 0; i < this.fileInput.files.length; i++) {
            const file = this.fileInput.files.item(i);

            if (!file) return error;

            if (
                this._allowedFileTypes &&
                this._allowedFileTypes.length &&
                this._allowedFileTypes.every(allowed => !file.name.toLowerCase().endsWith(allowed))
            )
                return error;
        }

        return true;
    }
}
