import { bindable, bindingMode, computedFrom } from 'aurelia-framework';

export class FileIconCustomElement {
    private static ICONS_PATH = 'images/file-icons';
    @bindable mimeType: string;

    @bindable({ defaultBindingMode: bindingMode.oneTime }) size: string;

    @computedFrom('mimeType')
    get iconSrc() {
        if (this.mimeType) {
            const icon = this.mimeType.split('/').reverse()[0];
            return FileIconCustomElement.ICONS_PATH + '/' + icon + '.svg';
        } else {
            return FileIconCustomElement.ICONS_PATH + '/' + 'default' + '.svg';
        }
    }
}
