import { safeParse } from '../../Utilities';
import type { SoapyAddress } from './SoapyAddress';
import type { SoapyRow } from './SoapyRow';
import type { SoapyRowRaw } from './SoapyRowRaw';
import type { SoapyScheduleConstraints } from './SoapyScheduleConstraints';
import { parseSoapyDate } from './parseSoapyDate';
import { parseSoapyDueWeek } from './parseSoapyDueWeek';
import { parseSoapyFrequency } from './parseSoapyFrequency';
import { parseSoapyJobStatus } from './parseSoapyJobStatus';

export const parseSoapyRowFramRaw = (row: SoapyRowRaw): SoapyRow => {
    const soapyRow: SoapyRow = {
        customerId: row.customerId,
        customerShortId: row.customerShortId,
        customerStatus: row.customerStatus as any,
        customerType: row.customerType as any,
        customerBalance: parseFloat(row.customerBalance) || 0,
        customerMonthlyValue: parseFloat(row.customerMonthlyValue) || 0,
        customerAnnualValue: parseFloat(row.customerAnnualValue) || 0,
        customerName: row.customerName,
        customerBusinessName: row.customerBusinessName,
        customerEmail: row.customerEmail,
        customerPhoneNumber: row.customerPhoneNumber,
        customerPostcode: row.customerPostcode,
        customerAddress: safeParse<SoapyAddress, undefined>(row.customerAddress, undefined),
        customerNotes: row.customerNotes,
        jobId: row.jobId,
        jobShortId: row.jobShortId,
        jobRoundId: row.jobRoundId,
        jobStatus: parseSoapyJobStatus(row.jobStatus),
        jobTitle: row.jobTitle,
        jobServices: row.jobServices,
        jobCost: parseFloat(row.jobCost) || 0,
        jobNotes: row.jobNotes,
        jobFrequency: parseSoapyFrequency(row.jobFrequency),
        jobScheduleConstraints: safeParse<SoapyScheduleConstraints, undefined>(row.jobScheduleConstraints, undefined),
        jobLastDone: parseSoapyDate(row.jobLastDone, true),
        jobDueWeek: parseSoapyDueWeek(row.jobDueWeek),
        jobScheduledDate: parseSoapyDate(row.jobScheduledDate, false),
        jobEstimatedTime: row.jobEstimatedTime,
        jobDuration: row.jobDuration,
        jobCreatedAt: parseSoapyDate(row.jobCreatedAt, true),
    };
    return soapyRow;
};
