import { ApplicationState } from '../ApplicationState';
import { CustomDialog } from '../Dialogs/CustomDialog';
import { LoaderEvent } from '../Events/LoaderEvent';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { RethinkDbAuthClient } from '../Server/RethinkDbAuthClient';

export class ForgotPasswordDialog extends CustomDialog<void> {
    protected forgotPasswordForm: HTMLFormElement;
    protected showForm = false;
    protected resetSubmitted = false;
    protected supportEmail = ApplicationState.localise('general.support-email');

    constructor(public email: string) {
        super('forgotPasswordDialog', '../Launch/ForgotPasswordDialog.html', '', {
            cancelLabel: '',
            okLabel: '',
            isSecondaryView: true,
            cssClass: 'forgot-password-dialog',
        });
        this.title = ApplicationState.localise('forgot-password.title');
    }

    public async init() {
        setTimeout(() => {
            this.setValidity('forgot-email', true);
            this.showForm = true;
        });
    }

    public validateForm() {
        if (this.forgotPasswordForm.checkValidity()) return true;
        this.setValidity('forgot-email', !!this.email);
        this.forgotPasswordForm.classList.toggle('shake');
        setTimeout(() => this.forgotPasswordForm.classList.toggle('shake'), 500);
        return false;
    }

    private setValidity(fieldId: string, valid: boolean) {
        const container = <HTMLDivElement>(<HTMLInputElement>document.getElementById(fieldId)).parentElement;
        if (valid) container.classList.remove('is-invalid');
        else container.classList.add('is-invalid');
    }

    public async forgotPassword() {
        if (this.validateForm() && this.email) {
            new LoaderEvent(true);
            try {
                const result = await RethinkDbAuthClient.requestPasswordResetEmail(this.email);
                if (result.success == false) {
                    new NotifyUserMessage(result.error);
                } else {
                    new NotifyUserMessage('notifications.reset-email-send-success');
                }
            } catch (error) {
                new NotifyUserMessage('notifications.reset-email-send-error');
            } finally {
                new LoaderEvent(false);
            }
        } else {
            new LoaderEvent(false);
            new NotifyUserMessage('validation.valid-email-required');
        }
    }
}
