import { inject } from 'aurelia-framework';
import type { RouterConfiguration } from 'aurelia-router';
import { Router } from 'aurelia-router';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import { SqueegeeLocalStorage } from '../Data/SqueegeeLocalStorage';
import { GlobalFlags } from '../GlobalFlags';
import { routes } from './Routes';

export type OrderType = 'optimal' | 'planned' | 'time' | 'distance';

@inject(Router)
export class DayPilot {
    private constructor(private router: Router) {
        if (ApplicationState.account.dayPilotDayIsSticky) {
            const stickyDate = SqueegeeLocalStorage.getItem('day-pilot-sticky-date');
            if (stickyDate) {
                const currentDate = moment(stickyDate);
                if (currentDate.isValid()) this.currentDate = currentDate;
            }
        } else {
            SqueegeeLocalStorage.removeItem('day-pilot-sticky-date');
        }

        if (!this.currentDate) this.currentDate = moment();
    }

    public async configureRouter(config: RouterConfiguration, router: Router) {
        if (GlobalFlags.isHttp) {
            config.options.pushState = true;
            config.options.root = '/';
        }
        await router.configure(config.map(routes));
        this.router = router;
        this.router.refreshNavigation();
    }

    public currentDate: moment.Moment;
}
