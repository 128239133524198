import { wait } from '@nexdynamic/squeegee-common';

export class TaskQueue<T extends (...args: any[]) => Promise<any>> {
    private queue: T[] = [];
    private processing = false;
    private interval: number;
    private paused = false;

    constructor(intervalMs: number) {
        this.interval = intervalMs;
    }

    async enqueue(task: T) {
        this.queue.push(task);
        this.processQueue();
    }

    get length() {
        return this.queue.length;
    }

    private async processQueue(): Promise<void> {
        if (!this.processing && this.queue.length > 0) {
            this.processing = true;
            while (this.queue.length > 0) {
                if (this.paused) {
                    break;
                }
                const task = this.queue.shift();
                if (task) {
                    await task();
                }
                await wait(this.interval);
            }

            this.processing = false;
        }
    }

    pause() {
        this.paused = true;
    }

    resume() {
        this.paused = false;
        this.processQueue();
    }
}

// Usage Example
//const queue = new TaskQueue(1000); // Process one task every 1000 milliseconds

// Sample tasks (e.g., API requests)
// const fetchData = async () => {
//     console.log("Fetching data...");
//     // Replace this with actual fetch call
//     // await fetch("/api/data");
//     return new Promise(resolve => setTimeout(resolve, 200)); // Simulate network request delay
// };

// Enqueue tasks
// queue.enqueue(fetchData);
// queue.enqueue(fetchData);
// queue.enqueue(fetchData);
