import { TransactionType } from '@nexdynamic/squeegee-common';
import { bindable } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import type { Filter } from '../../Filters/Filter';
import type { ITransactionsFilterItemDictionary } from '../../Filters/Filters';
import { Filters } from '../../Filters/Filters';
import { FiltersDialog } from '../../Filters/FiltersDialog';
import { Logger } from '../../Logger';
import { TransactionsBase } from '../../Transactions/Components/TransactionsBase';
import { TransactionService } from '../../Transactions/TransactionService';

export class InvoiceListCustomElement extends TransactionsBase {
    @bindable invertTotals: boolean;
    @bindable showFilterMenu = false;
    @bindable showSearchBar = false;
    protected currentFilter: Filter<ITransactionsFilterItemDictionary>;
    private static transactionTypes: Array<TransactionType> = [TransactionType.Invoice];
    protected hideCancelledTransactions = ApplicationState.getSetting('global.hide-cancelled-transactions', false)

    @bindable protected searchText = '';
    protected searchTextChanged(): void {
        this.filter();
    }

    async loadData() {
        this.currentFilter =
            this.currentFilter || Filters.transactionsFilter({ transactionType: InvoiceListCustomElement.transactionTypes });
        this._transactionsByDay = TransactionService.getTransIncVoidsByDay(TransactionType.Invoice);

        await this.updateTransactionByDayArray(this.currentFilter.filterOptions, this.searchText);
    }

    private filter() {
        this.updateTransactionByDayArray(this.currentFilter.filterOptions, this.searchText);
    }

    protected async openFilterMenu() {
        try {
            const dialog = new FiltersDialog<ITransactionsFilterItemDictionary>(this.currentFilter, Filters.transactionsFilter, {
                transactionType: TransactionType.Invoice,
            });
            await dialog.show(DialogAnimation.SLIDE_UP);
            if (!dialog.cancelled) {
                this.currentFilter = await dialog.getResult();
                this.filter();
            }
        } catch (error) {
            Logger.error('Error in openFilterMenu() on InvoiceListCustomElement', error);
        }
    }
}
