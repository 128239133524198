import type { IIntegrationTab, ISession } from '@nexdynamic/squeegee-common';
import { EventAggregator } from 'aurelia-event-aggregator';
import { computedFrom, inject } from 'aurelia-framework';
import type { NavigationInstruction, NavigationResult } from 'aurelia-router';
import { ApplicationState } from '../ApplicationState';
import { GlobalFlags } from '../GlobalFlags';
import { Api } from '../Server/Api';
import { RethinkDbAuthClient } from '../Server/RethinkDbAuthClient';
import { SqueegeeClientSocketApi } from '../Server/SqueegeeClientSocketApi';
import { isDevMode } from '../isDevMode';
import type { IntegrationFrameMessage } from './IntegrationFrameMessage';
import { handleIFrameApiRequest } from './handleIFrameApiRequest';

@inject(EventAggregator)
export class Integrations {
    constructor(private eventAggregator: EventAggregator) {}

    protected error?: string;
    protected isDevMode = isDevMode();
    public activeTab: IIntegrationTab;
    protected activeTabUrl: string;
    public router = ApplicationState.router;
    public devMode = ApplicationState.stateFlags.devMode;
    private SqueegeeClientSocketApi = SqueegeeClientSocketApi;

    protected iframe: HTMLIFrameElement;
    private _routeChangedEventSubscription: any;

    @computedFrom('SqueegeeClientSocketApi.isSocketConnected')
    public get online() {
        return this.SqueegeeClientSocketApi.isSocketConnected;
    }
    public session = RethinkDbAuthClient.session as ISession;
    private get queryString() {
        return `?session-key=${this.session.key}&session-value=${this.session.value}&data-email=${encodeURIComponent(
            ApplicationState.dataEmail
        )}`;
    }
    private get host() {
        if (/^https?:\/\//.test(this.activeTab.url)) return '';

        if (GlobalFlags.isMobileApp) return Api.apiEndpoint;

        return `${document.location.protocol}//${document.location.host}`;
    }

    private setActiveTab(tab?: IIntegrationTab) {
        if (!tab) return;

        this.activeTab = tab;
        this.activeTabUrl = `${this.host}${this.activeTab.url}${this.queryString}`;
    }
    public attached() {
        window.addEventListener('message', this.onMessage);
        this.eventAggregator.subscribe('router:navigation:complete', this.activateIframe);
    }

    public activateIframe = async (r: { instruction: NavigationInstruction; result: NavigationResult }) => {
        const dynamicTabs = r?.instruction?.config?.settings?.dynamicTabs;
        this.setActiveTab(dynamicTabs?.[0]);
    };

    public detached() {
        window.removeEventListener('message', this.onMessage);
        this._routeChangedEventSubscription?.dispose();
    }

    private onMessage = async (e: MessageEvent<IntegrationFrameMessage>) => {
        if (!e.data?.type) return;
        handleIFrameApiRequest(e.data.type, e.data);
    };
}
