import { bindable, computedFrom } from 'aurelia-framework';
import * as c3 from 'c3';
import { ApplicationState } from '../../ApplicationState';
import type { ChartData } from './ChartData';

export class Chart {
    private _chart?: c3.ChartAPI;
    @bindable selectedColumnIndex?: number;
    @bindable showLegend: boolean;
    @bindable showChart = true;
    @bindable showY = false;
    @bindable showX = false;
    @bindable chartData: ChartData;
    @bindable chartDataFormatter?: (data: any) => ChartData;
    @bindable onColumnSelected?: (index: number) => Promise<{ proceed: boolean }>;
    @bindable maxY: number | undefined;
    @bindable restrictedViewableDays: number | undefined;
    protected viewOptions = ApplicationState.viewOptions;
    public columnIcons: Array<string> | undefined;
    public columnSummaries: Array<string> | undefined;
    public columnHighs: Array<string | undefined> | undefined;
    public columnLows: Array<string | undefined> | undefined;
    public labels: Array<{ text: string; isActive: boolean }>;

    protected chartRef: Element;

    @computedFrom('viewOptions.showTemperatures')
    protected get showTemperatures() {
        return this.viewOptions.showTemperatures;
    }

    public attached() {
        window.addEventListener('orientationchange', this.resizeChart);
        this.previousWidth = this.chartRef ? this.chartRef.clientWidth : 0;
        this.pollLayoutChange();
    }

    private resizeTimeout: number;
    private resizeChart = () => {
        this.resizeTimeout = setTimeout(() => {
            requestAnimationFrame(() => {
                if (this._chart) this._chart.resize();
            });
        }, 150) as any;
    };

    private previousWidth = 0;
    private layoutPoller: number;
    private pollLayoutChange = () => {
        this.layoutPoller = setTimeout(() => {
            if (this.chartRef) {
                if (this.previousWidth !== this.chartRef.clientWidth) this.resizeChart();
                this.previousWidth = this.chartRef.clientWidth;
                this.pollLayoutChange();
            }
        }, 1500) as any;
    };

    public selectedColumnIndexChanged(column: number) {
        if (column !== undefined) if (this.labels) this.setActiveLabel(column);
    }

    public maxYChanged() {
        if (!this.maxY || !this._chart) return;

        this._chart.axis.max({ y: this.maxY });
    }

    public chartDataChanged() {
        const chartData = this.chartDataFormatter && !this.chartData.isChartData ? this.chartDataFormatter(this.chartData) : this.chartData;
        chartData.onselected = data => {
            this.selectedColumnIndex = data.index;
            if (this.onColumnSelected) this.onColumnSelected(data.index);
        };
        this.columnIcons = chartData.columnIcons;
        this.columnSummaries = chartData.columnSummaries;
        this.columnHighs =
            chartData.columnHighs &&
            chartData.columnHighs.map(
                x =>
                    (x &&
                        (ApplicationState.temperatureUnits === 'fahrenheit'
                            ? x.toFixed(0) + '°F'
                            : ApplicationState.fahrenheitToCelsius(x) + '°C')) ||
                    ''
            );
        this.columnLows =
            chartData.columnLows &&
            chartData.columnLows.map(
                x =>
                    (x &&
                        (ApplicationState.temperatureUnits === 'fahrenheit'
                            ? x.toFixed(0) + '°F'
                            : ApplicationState.fahrenheitToCelsius(x) + '°C')) ||
                    ''
            );
        if (chartData) {
            chartData.selection = {
                enabled: true,
                grouped: true,
                multiple: false,
            };
            if (!this._chart) {
                this._chart = c3.generate({
                    legend: { show: this.showLegend !== undefined ? this.showLegend : chartData.groups.length > 1 },
                    data: chartData,
                    axis: {
                        y: { show: this.showY, max: this.maxY },
                        x: { show: this.showX, type: 'category' },
                    },
                    tooltip: {
                        show: false,
                    },
                    bar: {
                        width: { ratio: 0.8 },
                    },
                });
            } else {
                this._chart.load(chartData);
            }

            if (!this.showX) this.loadLabels(chartData);
        } else if (this._chart) {
            this._chart.unload();
        }
    }

    public async selectColumn(index: number) {
        if (!this._chart || index === this.selectedColumnIndex) return;

        let proceedWithSelect = true;

        if (this.onColumnSelected) {
            proceedWithSelect = (await this.onColumnSelected(index)).proceed;
        }

        if (proceedWithSelect) {
            this._chart.unselect(undefined, [index]);
            this._chart.select(undefined, [index], true);
        }
    }

    private loadLabels(chartData: ChartData) {
        this.labels = chartData.chartLabels.map((label, index) => {
            return {
                text: label,
                isActive: index === this.selectedColumnIndex,
                isHighlighted: chartData.highlightColumn !== undefined ? index === chartData.highlightColumn : false,
            };
        });
    }

    private setActiveLabel(index: number) {
        for (let i = 0; i < this.labels.length; i++) {
            const label = this.labels[i];
            if (i === index) label.isActive = true;
            else label.isActive = false;
        }
    }

    public detached() {
        if (this._chart) this._chart.destroy();
        if (this.layoutPoller) clearTimeout(this.layoutPoller);
        if (this.resizeTimeout) clearTimeout(this.resizeTimeout);
    }
}
