import tinymce from 'tinymce';
import 'tinymce/icons/default';
// Yep I know -_-
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/code';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/fullpage';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/image';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/print';
import 'tinymce/plugins/save';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/wordcount';
import 'tinymce/skins/ui/oxide-dark/skin.min.css';
import 'tinymce/skins/ui/oxide-dark/skin.mobile.min.css';
import 'tinymce/themes/silver';
import { ApplicationState } from '../ApplicationState';
import { Logger } from '../Logger';
import type { CannedResponse } from '../ReactUI/canned-responses/CannedResponse';
import CannedResponsesDialog from '../ReactUI/canned-responses/CannedResponsesDialog';
import { t } from '../t';
import { AureliaReactComponentDialog } from './AureliaReactComponentDialog';
import { Prompt } from './Prompt';

export class TinyMcePlugin {
    constructor(public textAreaId: string, tools: Array<ITinyMceTool> | undefined, fullpage: boolean, readonly = false) {
        if (!tools) {
            tools = [
                'undo',
                'redo',
                'styleselect',
                // 'save',
                'table',
                'bold',
                'italic',
                'underline',
                'alignleft',
                'aligncenter',
                'alignright',
                'alignjustify',
                'bullist',
                'numlist',
                'outdent',
                'indent',
                'link',
                'image',
                // 'preview',
                'forecolor',
                'backcolor',
                // 'emoticons',
                // 'charmap',
                'code',
                'hr',
                // 'paste',
                // 'pagebreak',
                // 'searchreplace',
                // 'visualblocks',
                // 'insertdatetime',
            ];
            if (fullpage) tools.push('fullpage');
        }

        const selector = `#${textAreaId}`;

        const cannedResponseTools = ApplicationState.hasUltimateOrAbove ? 'insertCannedResponsesButton' : '';

        const toolbar = [cannedResponseTools].concat(tools).join(' ');

        const menubar = false;
        const pluginNames = [
            'advlist',
            'autolink',
            'link',
            'image',
            'lists',
            // 'charmap',
            'print',
            // 'preview',
            'hr',
            'anchor',
            // 'pagebreak',
            // 'searchreplace',
            // 'visualblocks',
            // 'visualchars',
            'code',
            'fullscreen',
            // 'insertdatetime',
            'media',
            'nonbreaking',
            // 'save',
            'table',
            'directionality',
            // 'emoticons',
            // 'template',
            // 'paste',
            'wordcount',
        ];

        if (fullpage) pluginNames.push('fullpage');

        const plugins = pluginNames.join(' ');

        const valid_elements = '+*[*]';
        const setup = (editor: any) => {
            editor.on('change', () => editor.save());

            if (!ApplicationState.hasUltimateOrAbove) return;

            editor.ui.registry.addButton('insertCannedResponsesButton', {
                text: t('settings.canned-responses-button-text'),
                icon: 'comment-add',
                tooltip: t('settings.canned-responses-insert-tooltip'),
                onAction: () => {
                    const { dialog, show } = AureliaReactComponentDialog.show({
                        component: CannedResponsesDialog,
                        componentProps: {
                            cannedResponses: ApplicationState.getSetting<Array<CannedResponse>>(
                                'global.canned-responses',
                                new Array<CannedResponse>()
                            ),
                        },
                        dialogTitle: 'settings.canned-responses-title',
                        isSecondaryView: true,
                    });

                    show.then((result: string) => {
                        if (dialog.cancelled) return;

                        const prompt = new Prompt(
                            'settings.canned-responses-insert-type',
                            'settings.canned-responses-insert-type-description',
                            {
                                okLabel: 'settings.canned-responses-insert-type-replace',
                                altLabel: 'settings.canned-responses-insert-type-insert',
                            }
                        );

                        prompt.show().then(() => {
                            if (prompt.cancelled) return;

                            prompt.result.then(replace => {
                                const content = (result || '').split('\n').join('<br />');
                                const setAndFocus = () => {
                                    editor.setContent(content);
                                    editor.focus();
                                };
                                replace ? setAndFocus() : editor.insertContent(content);
                            });
                        });
                    });
                },
            });
        };

        const settings = {
            skin: false,
            selector,
            toolbar,
            menubar,
            plugins,
            valid_elements,
            setup,
            convert_urls: false,
            remove_script_host: false,
            readonly,
            verify_html: false,
            force_br_newlines: true,
            force_p_newlines: false,
        };
        Logger.info('Html editor settings:', settings);

        requestAnimationFrame(() => tinymce.init(settings));
    }
}

export type ITinyMceTool =
    | 'insertfile'
    | 'undo'
    | 'redo'
    | 'styleselect'
    | 'save'
    | 'table'
    | 'bold'
    | 'italic'
    | 'underline'
    | 'alignleft'
    | 'aligncenter'
    | 'alignright'
    | 'alignjustify'
    | 'bullist'
    | 'numlist'
    | 'outdent'
    | 'indent'
    | 'link'
    | 'image'
    | 'print'
    | 'preview'
    | 'media'
    | 'fullpage'
    | 'forecolor'
    | 'backcolor'
    | 'emoticons'
    | 'charmap'
    | 'code'
    | 'hr'
    | 'paste'
    | 'pagebreak'
    | 'searchreplace'
    | 'spellchecker'
    | 'template'
    | 'visualblocks'
    | 'insertdatetime';
