import { bindable, computedFrom } from 'aurelia-framework';
import { Utilities } from '../../Utilities';

export class StorageLimitCustomElement {
    @bindable usedBytes: number;
    @bindable allowanceBytes: number;

    @computedFrom('used', 'allowanceBytes')
    get progressUsed() {
        return ((this.usedBytes / this.allowanceBytes) * 100).toFixed(0);
    }

    @computedFrom('usedBytes', 'allowanceBytes')
    get localiseParams() {
        return {
            used: Utilities.formatBytes(this.usedBytes, 2),
            allowance: Utilities.formatBytes(this.allowanceBytes, 2),
        };
    }
}
