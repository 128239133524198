import moment from 'moment';
import { ApplicationState } from '../ApplicationState';

export class DateFromNowValueConverter {
    toView(value: string) {
        const date = moment(value);
        return DateFromNowValueConverter.getFromNow(date);
    }

    public static getFromNow(date: moment.Moment) {
        try {
            const yesterday = moment().subtract(1, 'day');
            const today = moment().startOf('day');
            const tomorrow = moment().add(1, 'day');

            if (yesterday.isSame(date, 'day')) {
                return ApplicationState.localise('dates.yesterday');
            } else if (today.isSame(date, 'day')) {
                return ApplicationState.localise('dates.today');
            } else if (tomorrow.isSame(date, 'day')) {
                return ApplicationState.localise('dates.tomorrow');
            }

            const before = date.isBefore(today);

            const years = Math.abs(date.diff(today, 'years'));
            if (years > 0) {
                const remainingWeeks = Math.abs(date.diff(today, 'w')) - years * 52;
                return `${!before ? 'in ' : ''}${years} year${years > 1 ? 's' : ''}${(remainingWeeks && `, ${remainingWeeks} week${remainingWeeks > 1 ? 's' : ''}`) || ''
                    }${before ? ' ago' : ''}`;
            }

            const weeks = Math.abs(date.diff(today, 'weeks'));
            if (weeks > 0) {

                const remainingDays = Math.abs(date.diff(today, 'd')) - weeks * 7;
                return `${!before ? 'in ' : ''}${weeks} week${weeks > 1 ? 's' : ''}${(remainingDays && `, ${remainingDays} day${remainingDays > 1 ? 's' : ''}`) || ''
                    }${before ? ' ago' : ''}`;
            }

            const days = Math.ceil(Math.abs(date.diff(today, 'h') / 24));
            if (days > 0) {
                return `${!before ? 'in ' : ''}${days} day${days > 1 ? 's' : ''}${before ? ' ago' : ''}`;
            }

            return date.fromNow();
        } catch (error) {
            return date.format('ll');
        }
    }
}
