import type { Franchisee, IFranchiseeReportInfo, StoredObjectResourceTypes, TranslationKey } from '@nexdynamic/squeegee-common';
import { sortByCreatedDateDesc } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import type { IFabAction } from '../Components/Fabs/IFabAction';
import type { ListItem } from '../Components/StandardList/ListItem';
import { StandardList } from '../Components/StandardList/StandardList';
import { Data } from '../Data/Data';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { Api } from '../Server/Api';
import { FranchiseeDialog } from './FranchiseeDialog';
import { FranchiseeStandardListItem } from './FranchiseeStandardListItem';
import type { IReportInfo } from './IReportInfo';

export class Franchisees extends StandardList<Franchisee> {
    static async getReports(franchisee: Franchisee, isoReportsSinceDate?: string): Promise<Array<IReportInfo>> {
        const reports: Array<IReportInfo> = [];
        const reportResponse = await Api.get<IFranchiseeReportInfo>(
            null,
            `/api/reports/franchisee-owner-all/${franchisee._id}?isoReportsSinceDate=${isoReportsSinceDate || ''}`
        );
        const availableReports = (reportResponse && reportResponse.data) || {};
        for (const isoReportDate in availableReports) {
            const locale = ApplicationState.account.language?.slice(0, 5) || 'en-GB';
            const isWeekly = franchisee?.settings?.reportInterval === 'week';
            const from = moment(isoReportDate)
                .locale(locale)
                .startOf(isWeekly ? 'week' : 'month');
            const to = moment(isoReportDate).locale(locale);
            const dateInfo = isWeekly ? `Week ${from.week()} (${from.format('L')}-${to.format('L')})` : `${from.format('MMMM YYYY')}`;

            const reportsForDate = availableReports[isoReportDate].sort(sortByCreatedDateDesc);

            const hasFinalisedVersion = reportsForDate.length > 0;

            if (!hasFinalisedVersion) {
                reports.push({
                    isoReportDate,
                    state: 'draft',
                    description: `${dateInfo} (draft)`,
                });
            }

            for (const reportInfoDetail of reportsForDate) {
                const createdDate = moment(reportInfoDetail.createdDate).format('ll');
                const archived = reportsForDate.indexOf(reportInfoDetail) !== 0;
                const state = archived ? 'archived' : 'finalised';
                reports.push({
                    id: reportInfoDetail._id,
                    isoReportDate,
                    state,
                    description: `${dateInfo} finalised ${createdDate} ${state === 'archived' ? '(archived)' : ''}`,
                });
            }
        }

        return reports;
    }

    protected listTitle: TranslationKey = 'route-config.franchisees-title';

    listenToResourceTypes = ['franchisee' as StoredObjectResourceTypes];

    protected loadItems = () => {
        const franchisees = Data.all<Franchisee>('franchisee')
            .slice()
            .sort((x, y) => (x.name > y.name ? 1 : x.name < y.name ? -1 : 0));
        const list: Array<ListItem<Franchisee>> = [];

        for (const franchisee of franchisees) list.push(new FranchiseeStandardListItem(franchisee));

        return list;
    };

    protected selectItem = async (franchisee: Franchisee) => {
        try {
            const dialog = new FranchiseeDialog(franchisee);
            franchisee = await dialog.show();
            await this.refreshList();
        } catch (error) {
            Logger.error('Error in viewFranchisee() on Franchisees', { franchisee, error });
            new NotifyUserMessage('users.save-error');
        }
    };

    protected fabActions: Array<IFabAction> = [];
}
