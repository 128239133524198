import type {
    AutomaticPaymentTransactionSubType,
    Customer,
    InvoiceTransaction,
    PaymentTransaction,
    PaymentTransactionSubType,
    StoredObject,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import { PaymentDetails, Transaction, TransactionType, sortByDateDesc, to2dp } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import { Prompt } from '../Dialogs/Prompt';
import { Select } from '../Dialogs/Select';
import { SelectMultiple } from '../Dialogs/SelectMultiple';
import { InvoiceService } from '../Invoices/InvoiceService';
import { Logger } from '../Logger';
import { Utilities } from '../Utilities';

export class ChargeCustomerService {
    public static async manualAllocation(transaction: Transaction) {
        const customerId = transaction.customerId;
        if (!customerId) return;

        const payments = Data.all<Transaction>('transactions', { transactionType: TransactionType.Payment });
        const automaticPayments = Data.all<Transaction>('transactions', { transactionType: TransactionType.AutomaticPayment });

        const invoiceIdToPaymentId = {} as Record<string, string>;
        for (const payment of payments.concat(automaticPayments)) {
            if (payment.voidedId || !payment.paymentDetails?.invoiceIds?.length) continue;

            for (const invoiceId of payment.paymentDetails.invoiceIds) {
                invoiceIdToPaymentId[invoiceId] = payment._id;
            }
        }

        const unallocatedInvoices = Data.all<Transaction>(
            'transactions',
            t => t.amount > 0 && t.amount <= Math.abs(transaction.amount) && !t.voidedId && !invoiceIdToPaymentId[t._id],
            { transactionType: TransactionType.Invoice, customerId }
        )
            .slice()
            .sort(sortByDateDesc);

        if (unallocatedInvoices.every(t => t.amount > Math.abs(transaction.amount)))
            return new Prompt('prompts.error-title', 'payments.all-unallocated-invoices-greater', { cancelLabel: '' }).show();

        const options = unallocatedInvoices.map(t => ({
            value: t._id,
            text: `${ApplicationState.currencySymbol()}${t.amount.toFixed(2)} ${moment(t.date).format(
                'DDD, Do MMM YYYY'
            )} ${Utilities.localiseList((t.invoice?.items || []).map(i => i.description as TranslationKey) || [])}`,
        }));

        let selectedInvoices = [] as Array<{ text: string; value: string }>;
        let createTip = false;
        let leftOver = 0;
        do {
            const select = new SelectMultiple('payments.select-invoices-for-allocation-title', options, 'text', 'value');
            selectedInvoices = await select.show();
            Logger.info('invoices', selectedInvoices);
            if (select.cancelled) return;
            const invoices = selectedInvoices.map(i => unallocatedInvoices.find(x => x._id === i.value)) as Array<Transaction>;
            let total = invoices.reduce((x, t) => x + t.amount, 0);
            total = to2dp(total);
            const paymentAmount = Math.abs(transaction.amount);
            if (total > paymentAmount) {
                const error = new Prompt('payments.incorrect-allocation-total-title', 'payments.incorrect-allocation-total-description', {
                    localisationParams: {
                        amount: `${ApplicationState.currencySymbol()}${Math.abs(transaction.amount).toFixed(2)}`,
                        total: `${ApplicationState.currencySymbol()}${total.toFixed(2)}`,
                    },
                    okLabel: 'payments.allocation-select-invoices-button',
                });
                await error.show();
                if (error.cancelled) return;
                selectedInvoices.splice(0, selectedInvoices.length);
            } else if (total < paymentAmount) {
                leftOver = paymentAmount - total;
                const selOption = new Select(
                    `${leftOver.toFixed(2)} Overpayment` as TranslationKey,
                    [
                        {
                            text: 'Leave as an Overpayment',
                            value: 'overpayment',
                        },
                        {
                            text: `Allocate as a Tip`,
                            value: 'tip',
                        },
                    ],
                    'text',
                    'value',
                    undefined,
                    { allowCancel: true, isSecondaryView: true }
                );

                const result = await selOption.show();
                if (!result) return;
                if (result.value === 'tip') createTip = true;
            }
        } while (!selectedInvoices.length);

        const updates = [] as Array<StoredObject>;
        if (createTip) {
            const tipTransaction = new Transaction(TransactionType.Invoice, 'invoice.tip', leftOver, customerId);
            tipTransaction._id = transaction._id + '_tip';
            updates.push(tipTransaction);

            if (!transaction.paymentDetails) transaction.paymentDetails = {};
            if (!transaction.paymentDetails.invoiceIds) transaction.paymentDetails.invoiceIds = [];
            transaction.paymentDetails.invoiceIds.push(tipTransaction._id);
        }

        await ChargeCustomerService.allocateToInvoices(
            transaction,
            selectedInvoices.map(i => i.value),
            updates
        );
        if (!updates.length) return;

        updates.push(transaction);
        await Data.put(updates);
        await InvoiceService.allocateBalanceToInvoices(customerId);
        return true;
    }
    static async completeLocalPayment(
        paymentTransaction: PaymentTransaction | InvoiceTransaction,
        allocateToInvoiceIds: Array<string> = [],
        createInvoice = false,
        tipAmount?: number
    ) {
        const dataToUpdate: Array<StoredObject> = [];

        if (createInvoice) {
            const invoice = await InvoiceService.quickCreateInvoice({
                customerId: paymentTransaction.customerId,
                date: paymentTransaction.date,
                invoiceTotal: Math.abs(paymentTransaction.amount),
                description: 'Quick invoice for payment ',
                paymentId: paymentTransaction._id,
            });
            dataToUpdate.push(invoice);
        }

        if (paymentTransaction.transactionSubType && paymentTransaction.transactionSubType.startsWith('payment.tip.')) {
            ChargeCustomerService.createTipAdjustmentFromPayment(paymentTransaction, dataToUpdate);
        } else {
            if (allocateToInvoiceIds && allocateToInvoiceIds.length)
                await ChargeCustomerService.allocateToInvoices(paymentTransaction, allocateToInvoiceIds, dataToUpdate);
        }

        if (tipAmount) {
            const tipTransaction = new Transaction(TransactionType.Invoice, 'invoice.tip', tipAmount, paymentTransaction.customerId);
            tipTransaction._id = paymentTransaction._id + '_tip';
            dataToUpdate.push(tipTransaction);

            paymentTransaction.paymentDetails?.invoiceIds?.push(tipTransaction._id);
        }

        ChargeCustomerService.updateCustomerWithLastPaymentMethod(paymentTransaction, dataToUpdate);

        dataToUpdate.push(paymentTransaction);
        if (dataToUpdate.length) await Data.put(dataToUpdate, true, 'immediate');

        await InvoiceService.allocateBalanceToInvoices(paymentTransaction.customerId);
    }

    public static createTipAdjustmentFromPayment(paymentTransaction: Transaction, dataToUpdate: Array<StoredObject>) {
        const tipAdjustment = new Transaction(
            TransactionType.Adjustment,
            'adjustment.tip',
            -paymentTransaction.amount,
            paymentTransaction.customerId,
            undefined,
            'Tip Adjustment',
            paymentTransaction.date
        );
        tipAdjustment._id = paymentTransaction._id + '_balance';

        dataToUpdate.push(tipAdjustment);
        return dataToUpdate;
    }

    private static updateCustomerWithLastPaymentMethod(paymentTransaction: Transaction, dataToUpdate: Array<StoredObject>) {
        if (!paymentTransaction.customerId) throw 'No customer id on payment transaction';

        const customer = Data.get<Customer>(paymentTransaction.customerId);

        if (
            customer &&
            !!paymentTransaction.transactionSubType &&
            paymentTransaction.transactionSubType !== 'payment.refund' &&
            paymentTransaction.transactionSubType !== 'payment.imported' &&
            paymentTransaction.transactionSubType !== 'auto.other' &&
            paymentTransaction.transactionSubType !== 'invoice.credit-note' &&
            paymentTransaction.transactionSubType !== 'invoice.write-off' &&
            customer.lastPaymentMethod !== paymentTransaction.transactionSubType
        ) {
            customer.lastPaymentMethod = paymentTransaction.transactionSubType as
                | PaymentTransactionSubType
                | AutomaticPaymentTransactionSubType;
            dataToUpdate.push(customer);
        }
    }

    public static async allocateToInvoices(
        paymentTransaction: Transaction,
        allocateToInvoiceIds: Array<string>,
        dataToUpdate: Array<StoredObject>
    ): Promise<void> {
        const invoicesToAllocatePaymentAgainst = allocateToInvoiceIds
            .map(x => Data.get<Transaction>(x))
            .filter(x => x) as Array<Transaction>;

        const total = Math.abs(invoicesToAllocatePaymentAgainst.map(i => (i as Transaction).amount || 0).reduce((x, y) => x + y));
        if (Math.abs(paymentTransaction.amount) >= total) {
            dataToUpdate.push(paymentTransaction);
            for (const invoice of invoicesToAllocatePaymentAgainst) {
                const updates = await ChargeCustomerService.attachPaymentToInvoice(invoice, paymentTransaction);
                dataToUpdate.push(...updates);
            }
        }
    }

    private static async attachPaymentToInvoice(invoice: Transaction, paymentTransaction: Transaction) {
        if (!paymentTransaction.paymentDetails) {
            paymentTransaction.paymentDetails = new PaymentDetails();
        }
        if (!paymentTransaction.paymentDetails.invoiceIds) paymentTransaction.paymentDetails.invoiceIds = [];

        if (!invoice) throw 'Invoice not found when allocating payment to invoices';
        if (!invoice.invoice) throw 'No invoice found to allocate against on transactions when allocating payment to invoices';
        invoice.invoice.paymentReference = paymentTransaction._id;
        if (!paymentTransaction.paymentDetails.invoiceIds.some(x => x === invoice._id))
            paymentTransaction.paymentDetails.invoiceIds.push(invoice._id);

        return await InvoiceService.setInvoicePaymentStatus(invoice, true);
    }
}
