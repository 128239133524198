import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { computedFrom } from 'aurelia-framework';
import { ApplicationState } from '../ApplicationState';
import { BGLoaderEvent } from '../Events/BGLoaderEvent';
import type { Subscription } from '../Events/SqueegeeEventAggregator';
import { Api } from '../Server/Api';

export class BGLoaderCustomElement {
    private bgLoaderEventSubscription: Subscription;
    protected bgLoader?: BGLoaderEvent;
    private static _hiding: any;

    private _api = Api;

    @computedFrom('_api.isConnected', '_api.itemsAwaitingSync')
    protected get statusClass() {
        if (!this._api.isConnected) return 'offline';
        if (this._api.itemsAwaitingSync > 0) return 'syncing';
        return 'online';
    }

    private _loopTimer: any;
    protected showActualPercentage: boolean;
    public attached() {
        this.bgLoaderEventSubscription = BGLoaderEvent.subscribe((loaderEvent: BGLoaderEvent) => {
            loaderEvent.message = ApplicationState.localise((loaderEvent.message as TranslationKey) || 'empty.string');

            if (loaderEvent.show) {
                clearTimeout(BGLoaderCustomElement._hiding);
                this.bgLoader = loaderEvent;
                if (loaderEvent.progressPercent === 'unknown') {
                    this.showActualPercentage = false;
                    loaderEvent.progressPercent = 0;
                    setInterval(() => {
                        (loaderEvent.progressPercent as number) += 10;
                        if ((loaderEvent.progressPercent as number) > 100) loaderEvent.progressPercent = 0;
                    }, 250);
                } else this.showActualPercentage = true;
            } else {
                clearInterval(this._loopTimer);
                loaderEvent.show = true;
                this.bgLoader = loaderEvent;
                BGLoaderCustomElement._hiding = setTimeout(() => {
                    if (this.bgLoader) this.bgLoader.show = false;
                }, 350);
            }
        });
    }

    public detached() {
        this.bgLoaderEventSubscription && this.bgLoaderEventSubscription.dispose();
        clearInterval(this._loopTimer);
    }
}
