// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#custom-dialog .AureliaReactComponentDialog {
  background-color: #f2f2f2;
}
#custom-dialog .AureliaReactComponentDialog .squeegee-dialog-content {
  padding: 0 !important;
}

#main-scroll-container:has(#portal-directory-root) {
  scrollbar-gutter: stable;
}`, "",{"version":3,"sources":["webpack://./src/Dialogs/AureliaReactComponentDialog.scss"],"names":[],"mappings":"AACI;EACI,yBAAA;AAAR;AACQ;EACI,qBAAA;AACZ;;AAIA;EACI,wBAAA;AADJ","sourcesContent":["#custom-dialog {\n    .AureliaReactComponentDialog {\n        background-color: #f2f2f2;\n        .squeegee-dialog-content {\n            padding: 0 !important;\n        }\n    }\n}\n\n#main-scroll-container:has(#portal-directory-root) {\n    scrollbar-gutter: stable;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
