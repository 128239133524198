import type { PaymentTransactionSubTypeBase, StoredObject } from '@nexdynamic/squeegee-common';
import {
    Customer,
    FrequencyType,
    InvoiceTransaction,
    Job,
    JobGroup,
    JobOccurrence,
    JobOccurrenceStatus,
    Location,
    PaymentTransaction,
    Service,
    TransactionType,
    copyObject,
    uuid,
} from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { InvoiceService } from '../Invoices/InvoiceService';
import { Data } from './Data';

type GreenCleenCustomer = {
    id: string;
    active: boolean;
    ref: string;
    title2: string;
    firstname: string;
    initial: string;
    surname2: string;
    number: string;
    letter: string;
    address1: string;
    address2: string;
    commercail: boolean;
    address3: string;
    address4: string;
    address5: string;
    postcode: string;
    mapcode: string;
    phone: string;
    mobile: string;
    fax: string;
    email: string;
    remarks: string;
    invoices: Array<GreenCleenFinancialRecord>;
    payments: Array<GreenCleenFinancialRecord>;
    jobs: Array<GreenCleenJob>;
    cleans: Array<GreenCleenClean>;
};

type GreenCleenClean = {
    service: string;
    date: string;
    price: number;
    quantity: number;
    cleaned: boolean;
    notes: string;
    interval: 'Monthly';
};

type GreenCleenFinancialRecord = {
    date: string;
    description: string;
    amount: number;
};

type GreenCleenJob = {
    id: string;
    type: string;
    quantity: number;
    price: number;
    interval: number;
    round: string;
    nextCleanDate?: string;
};

export const importFromGreenCleen = async (importData: Array<GreenCleenCustomer>) => {
    const data = {} as Record<string, StoredObject>;

    const oneMonthAgo = moment().subtract(1, 'month').format('YYYY-MM-DD');

    for (const c of importData) {
        let name = '';
        if (c.title2.trim()) name += c.title2.trim();
        if (c.firstname.trim()) name += ' ' + c.firstname.trim();
        if (c.initial.trim()) name += ' ' + c.initial.trim();
        if (c.surname2.trim()) name += ' ' + c.surname2.trim();

        let addressDescription = '';
        if (c.number.trim()) addressDescription += c.number.trim();
        if (c.letter.trim()) addressDescription += c.letter.trim();
        if (c.address1.trim()) addressDescription += ',' + c.address1.trim();
        if (c.address2.trim()) addressDescription += ',' + c.address2.trim();
        if (c.address3.trim()) addressDescription += ',' + c.address3.trim();
        if (c.address4.trim()) addressDescription += ',' + c.address4.trim();
        if (c.address5.trim()) addressDescription += ',' + c.address5.trim();
        if (c.postcode.trim()) addressDescription += ',' + c.postcode.trim();

        const address = new Location(undefined, undefined, addressDescription, 'import');
        const customer = new Customer(name, address, c.mobile, c.email, c.phone);
        customer.state = c.active ? 'active' : 'inactive';
        customer._externalId = c.id;
        customer.notes = '';
        if (c.commercail) customer.notes += `#!commercial\n`;

        const mapcode = c.mapcode?.replace(/\n/g, '').trim();
        if (mapcode) customer.notes += `Mapcode: ${mapcode}\n`;

        const fax = c.fax?.replace(/\n/g, '').trim();
        if (fax) customer.notes += `Fax: ${fax}\n`;

        const ref = c.ref?.replace(/\n/g, '').trim();
        if (ref) customer.notes += `Ref: ${ref}\n`;

        const remarks = c.remarks?.replace(/\n/g, '').trim();
        if (remarks) customer.notes += `${customer.notes ? '\n' : ''}Remarks\n-------\n${remarks}\n\n`;

        customer.notes = customer.notes.trim();

        data[customer._id] = customer;

        for (const i of c.invoices) {
            const invoice = InvoiceService.createInvoiceForCustomer(customer);
            invoice.date = i.date;
            invoice.items = [
                {
                    refID: uuid(),
                    description: i.description,
                    unitPrice: i.amount,
                    quantity: 1,
                    resourceType: '',
                },
            ];
            InvoiceService.updateTotals(invoice);
            const invoiceTransaction = new InvoiceTransaction('invoice.imported', customer._id, invoice, 'complete');
            invoiceTransaction.date = i.date;
            invoiceTransaction.amount = i.amount;
            invoiceTransaction._id = uuid();
            data[invoiceTransaction._id] = invoiceTransaction;
        }

        for (const p of c.payments) {
            const type: PaymentTransactionSubTypeBase = 'payment.imported';
            const paymentTransaction = new PaymentTransaction(
                TransactionType.Payment,
                type,
                p.amount,
                p.description,
                {},
                customer._id,
                'completed',
                p.date
            );
            data[paymentTransaction._id] = paymentTransaction;
        }

        const services = Array<Service>();
        const rounds = Array<JobGroup>();
        const generateColourFromDescription = (description: string) => {
            let hash = 0;
            for (let i = 0; i < description.length; i++) {
                hash = description.charCodeAt(i) + ((hash << 5) - hash);
            }
            const c = (hash & 0x00ffffff).toString(16).toUpperCase();
            return '#' + '00000'.substring(0, 6 - c.length) + c;
        };
        for (const j of c.jobs) {
            let service = services.find(s => s.description === j.type);
            if (!service) services.push((service = new Service(j.type)));

            let round = rounds.find(r => r.description === j.round);
            if (!round) rounds.push((round = new JobGroup(j.round, generateColourFromDescription(j.round))));

            const job = new Job(customer._id);
            job._externalId = j.id;
            job._id = uuid();
            job.price = j.price;

            job.services = [copyObject(service)];
            job.services[0].quantity = j.quantity;
            job.services[0].price = j.price;

            job.rounds = [copyObject(round)];

            job.date = j.nextCleanDate;
            job.frequencyType = FrequencyType.DayOfMonth;
            job.frequencyInterval = j.interval;
            job.location = copyObject(address);

            customer.jobs[job._id] = job;
        }

        if (Object.values(customer.jobs).every(j => !j.date || j.date < oneMonthAgo)) customer.state = 'inactive';

        for (const cl of c.cleans) {
            const job = Object.values(customer.jobs).find(j => j.services.some(s => s.description === cl.service));
            if (!job) continue;

            const jobOccurrence = new JobOccurrence(job, cl.date, JobOccurrenceStatus.Done, cl.date);
            jobOccurrence._id = uuid();
            jobOccurrence.price = cl.price;
            jobOccurrence.services = copyObject(job.services);
            jobOccurrence.paymentStatus = 'unbillable';
            delete jobOccurrence.services[0].quantity;
            delete jobOccurrence.services[0].price;

            data[jobOccurrence._id] = jobOccurrence;
        }
    }

    Data.put(Object.values(data));
};

(window as any).importFromGreenCleen = importFromGreenCleen;
