// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.launcher .mdc-text-field--filled {
  background-color: #fff;
}
.launcher .command-options-loading {
  position: absolute;
  bottom: 0;
  margin: 4px 15px;
  border-bottom: 4px solid #da384d;
}
.launcher .command-label {
  width: 100%;
  border-radius: 6px;
}
.launcher mdc-line-ripple {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/Launcher/Launcher.scss"],"names":[],"mappings":"AACI;EACI,sBAAA;AAAR;AAGI;EACI,kBAAA;EACA,SAAA;EACA,gBAAA;EACA,gCAAA;AADR;AAII;EACI,WAAA;EACA,kBAAA;AAFR;AAKI;EACI,aAAA;AAHR","sourcesContent":[".launcher {\n    .mdc-text-field--filled {\n        background-color: #fff;\n    }\n\n    .command-options-loading {\n        position: absolute;\n        bottom: 0;\n        margin: 4px 15px;\n        border-bottom: 4px solid #da384d;\n    }\n\n    .command-label {\n        width: 100%;\n        border-radius: 6px;\n    }\n\n    mdc-line-ripple {\n        display: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
