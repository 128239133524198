import type { AccountUser, LngLat } from '@nexdynamic/squeegee-common';
import { JobOccurrenceStatus } from '@nexdynamic/squeegee-common';
import type { Subscription } from 'aurelia-event-aggregator';
import { bindable, bindingMode, computedFrom, inject } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { ViewResizeEvent } from '../../Events/ViewResizeEvent';
import { DateFilter } from '../../Jobs/Filters/DateFilter';
import { DateFilterOption } from '../../Jobs/Filters/DateFilterOption';
import { Logger } from '../../Logger';
import { ScheduleActionEvent } from '../../Schedule/Actions/ScheduleActionEvent';
import type { ScheduleByGroup } from '../../Schedule/Components/ScheduleByGroup';
import type { ScheduleItem } from '../../Schedule/Components/ScheduleItem';
import { DayOrderDataService } from '../../Schedule/DayOrderDataService';
import { ScheduleService } from '../../Schedule/ScheduleService';
import { RethinkDbAuthClient } from '../../Server/RethinkDbAuthClient';
import { UserService } from '../../Users/UserService';
import type { Workload } from '../../Users/Workload';
import type { DayOrderData } from '../Components/DayOrderData';
import { DayPilot } from '../DayPilot';

@inject(DayPilot)
export class Completed {
    protected currencySymbol = ApplicationState.currencySymbol();
    protected dailyTotalJobs = 0;
    protected dailyTotalPrice = '0.00';
    protected dateFilter: DateFilter = new DateFilter();
    protected scheduleDataDay: ScheduleByGroup = {};
    protected scheduleItems: Array<ScheduleItem> = [];
    protected selectableMode: boolean;
    protected listContainer: HTMLDivElement;
    protected showWorkloadUserSelector = ApplicationState.multiUserEnabled && ApplicationState.isInAnyRole(['Admin', 'Planner', 'Owner']);
    protected currentUser = RethinkDbAuthClient.session && RethinkDbAuthClient.session.email;
    protected viewingUserId = RethinkDbAuthClient.session && RethinkDbAuthClient.session.email;

    protected loadedListItems: { [key: string]: number } = {};

    private _dataRefreshedSub: Subscription;
    private _scheduleChangedSub: Subscription;
    private _applicationStateUpdatedSub: Subscription;
    private _dayOrderData: DayOrderData | undefined;

    public loading = true;

    private constructor(public dayPilot: DayPilot) {}

    public async attached() {
        new ViewResizeEvent();
        new LoaderEvent(true);
        setTimeout(async () => {
            await this.getOccurrenceOrderByDay();
            await this.refreshScheduleItemsList(true);
            await this.updateWorkloads();

            this._scheduleChangedSub = ScheduleActionEvent.subscribe<ScheduleActionEvent>(() => this.refreshScheduleItemsList());
            this._dataRefreshedSub = DataRefreshedEvent.subscribe(
                async (event: DataRefreshedEvent) => event.hasAnyType('customers', 'joboccurrences') && this.refreshScheduleItemsList()
            );
            new LoaderEvent(false);
        }, 100);
    }

    public detached() {
        this._dataRefreshedSub && this._dataRefreshedSub.dispose();
        this._scheduleChangedSub && this._scheduleChangedSub.dispose();
        this._applicationStateUpdatedSub && this._applicationStateUpdatedSub.dispose();
        clearTimeout(this._refreshTimer);
        clearTimeout(this._countTimer);
    }

    private _itemCount = 0;
    private _countTimer: any;
    @computedFrom('searchText')
    public get itemCount() {
        clearTimeout(this._countTimer);
        this._countTimer = setTimeout(() => {
            Logger.info('List Item Count called');
            this._itemCount = this.listContainer.querySelectorAll('li').length;
        }, 100);
        return this._itemCount;
    }

    protected async executePrimary(target: ScheduleItem): Promise<ScheduleItem | null> {
        if (!target.scheduleItemActions.primaryAction) return Promise.reject(null);
        return target.scheduleItemActions.primaryAction.handler();
    }

    protected async executeSecondary(target: ScheduleItem): Promise<ScheduleItem | null> {
        if (!target.scheduleItemActions.secondaryAction) return Promise.reject(null);
        return target.scheduleItemActions.secondaryAction.handler();
    }

    private _refreshTimer: any;
    private async refreshScheduleItemsList(firstTime = false) {
        if (!firstTime) {
            this.loading = true;
            new LoaderEvent(true);
        }
        clearTimeout(this._refreshTimer);
        this._refreshTimer = await setTimeout(async () => {
            Logger.info('Refresh Schedule Items List Called');
            const day = this.dayPilot.currentDate.format('YYYY-MM-DD');

            this._dayOrderData = await DayOrderDataService.getDayOrderData(day, this.viewingUserId);

            this.scheduleDataDay = await this.getOccurrencesForDay(day);
            await this.updateWithDriveData();
            this._refreshTimer = null;
            this.loading = false;
        }, 100);
        if (!firstTime) {
            new LoaderEvent(false);
            this.loading = false;
        }
    }

    private async getOccurrencesForDay(day: string) {
        if (this.viewingUserId && this.viewingUserId !== this.currentUser) {
            return await ScheduleService.getScheduledJobsForUsersAndTeamsByDay(
                ScheduleService.getUserAndTeamIds(this.viewingUserId),
                this.dayPilot.currentDate,
                new DateFilterOption('', day),
                [JobOccurrenceStatus.Done],
                'status',
                undefined,
                true
            );
        } else {
            return await ScheduleService.getScheduledJobsForCurrentUserByDay(
                this.dayPilot.currentDate,
                new DateFilterOption('', day),
                [JobOccurrenceStatus.Done],
                'status'
            );
        }
    }

    private async updateWithDriveData() {
        await this.refreshDayData();
    }

    private async refreshDayData() {
        this.updateScheduleItems();
        this.updateDailyTotals();
    }

    private updateScheduleItems() {
        const scheduleItems: Array<ScheduleItem> = [];
        if (this.scheduleDataDay) {
            const groupKeys = Object.keys(this.scheduleDataDay);
            for (let i = 0; i < groupKeys.length; i++) {
                const group = this.scheduleDataDay[groupKeys[i]];
                const scheduleItemKeys = Object.keys(group);
                for (let i = 0; i < scheduleItemKeys.length; i++) {
                    const scheduleItem = group[scheduleItemKeys[i]];
                    if (this.occurrenceOrderData) {
                        const driveDictionaryData = this.occurrenceOrderData[scheduleItem.job._id];
                        if (driveDictionaryData && driveDictionaryData.occurrenceDayOrder) {
                            scheduleItem.driveOptimisationRank = driveDictionaryData.occurrenceDayOrder;
                        }
                    }
                    scheduleItems.push(group[scheduleItemKeys[i]]);
                }
            }
            this.scheduleItems = scheduleItems;
            this.sortScheduleItemsDescending();
        }
    }

    private sortScheduleItemsDescending() {
        this.scheduleItems.sort((itemA, itemB) => {
            const itemARank = !isNaN(Number(itemA.driveOptimisationRank)) ? Number(itemA.driveOptimisationRank) : 999;
            const itemBRank = !isNaN(Number(itemB.driveOptimisationRank)) ? Number(itemB.driveOptimisationRank) : 999;
            return itemBRank - itemARank;
        });
    }

    private updateDailyTotals() {
        let totalPrice = 0;
        this.dailyTotalJobs = this.scheduleItems.length;
        for (let i = 0; i < this.scheduleItems.length; i++) {
            totalPrice += this.scheduleItems[i].job.price || 0;
        }
        this.dailyTotalPrice = totalPrice.toFixed(2).toString();
    }

    private async getOccurrenceOrderByDay() {
        const day = this.dayPilot.currentDate.format('YYYY-MM-DD');
        const occurrenceOrderByDay = await DayOrderDataService.getDayOrderData(day);
        if (occurrenceOrderByDay) {
            this._dayOrderData = occurrenceOrderByDay;
        }
    }

    private get occurrenceOrderData() {
        return this._dayOrderData && this._dayOrderData.occurrenceOrderData;
    }

    protected workloads: Array<Workload>;
    protected userLocations: Array<LngLat | undefined>;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) protected selectedWorkLoad: Workload | undefined;
    protected selectedWorkLoadIndex = 0;
    protected selectUser(index: number) {
        this.selectedWorkLoadIndex = index;
        this.selectedWorkLoad = this.workloads[index];
        this.viewingUserId = (this.workloads[index].userOrTeam as AccountUser).email;
    }

    protected async selectedWorkLoadChanged() {
        if (!this.selectedWorkLoad) {
            this.selectedWorkLoadIndex = 0;
            return;
        }
        const userName = this.selectedWorkLoad.userOrTeam ? this.selectedWorkLoad.userOrTeam.name : undefined;
        const index = this.workloads.findIndex(x => x.userOrTeam !== undefined && x.userOrTeam.name === userName);
        if (index > -1) this.selectedWorkLoadIndex = index;
        await this.refreshScheduleItemsList(false);
    }

    private updateWorkloads() {
        const workloads = UserService.getWorksloadsForUsers(this.scheduleItems, false, false);
        this.workloads = workloads;
        if (!this.selectedWorkLoad) {
            this.selectedWorkLoad = workloads.find(w =>
                w.userOrTeam?.resourceType === 'accountuser' ? (w.userOrTeam as AccountUser).email === this.viewingUserId : false
            );
            this.selectUser(this.selectedWorkLoad ? this.workloads.indexOf(this.selectedWorkLoad) : 0);
        } else this.selectedWorkLoad = workloads.find(workload => workload.userOrTeam?.name === this.selectedWorkLoad?.userOrTeam?.name);
    }
}
