import { customerIsMatch, regexFromString } from '@nexdynamic/squeegee-common';
import type { ICustomerMatch } from '../ConnectedServices/Customers/ICustomerMatch';

export class GoCardlessCustomersFilterValueConverter {
    toView(array: Array<ICustomerMatch>, searchTextOriginal?: string) {
        if (!searchTextOriginal) return array;

        const searchTerm = searchTextOriginal.toLowerCase();
        return array.filter(matchItem => {
            if (!searchTerm) return true;
            if (!matchItem.thirdPartyCustomer) return false;
            const searchTermRegex = regexFromString({ original: searchTerm.replace(/  +/g, ' '), flags: ['g', 'i'], commaSeparated: true });
            if (matchItem.squeegeeCustomer && customerIsMatch({ customer: matchItem.squeegeeCustomer, searchTerm, regex: searchTermRegex }))
                return true;
            if (this.isMatch(matchItem.thirdPartyCustomer.address, searchTerm)) return true;
            if (this.isMatch(matchItem.thirdPartyCustomer.email, searchTerm)) return true;
            if (this.isMatch(matchItem.thirdPartyCustomer.telephoneNumber, searchTerm)) return true;
            if (this.isMatch(matchItem.thirdPartyCustomer.name, searchTerm)) return true;
            if (this.isMatch(matchItem.thirdPartyCustomer.notes, searchTerm)) return true;
            return false;
        });
    }

    private isMatch(propertyValue: any, searchText: string) {
        const value = (propertyValue || '').toString().toLowerCase();
        return value.indexOf(searchText) > -1;
    }
}
