import type {
    AccountUser,
    Alert,
    Contact,
    Customer,
    Device,
    ExpenseCategory,
    Franchisee,
    ISqueegeeClient,
    Job,
    JobOccurrence,
    JobOrderData,
    Quote,
    Service,
    Setting,
    Signature,
    Tag,
    Team,
    Transaction,
} from '@nexdynamic/squeegee-common';
import * as common from '@nexdynamic/squeegee-common';
import { TagType, TransactionType } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { AlertDetailsDialog } from '../Alerts/AlertDetailsDialog';
import { ApplicationEnvironment } from '../ApplicationEnvironment';
import { ApplicationState } from '../ApplicationState';
import { ConnectedServicesService } from '../ConnectedServices/ConnectedServicesService';
import { CustomerFormDialog } from '../Customers/Components/CustomerFormDialog';
import { SelectCustomerDialog } from '../Customers/Components/SelectCustomerDialog';
import { SelectCustomersDialog } from '../Customers/Components/SelectCustomersDialog';
import { setCustomerContext } from '../Customers/Components/setCustomerContext';
import type { DayOrderData } from '../DayPilot/Components/DayOrderData';
import type { CustomDialogBase } from '../Dialogs/CustomDialogBase';
import { MultipleInputDialog } from '../Dialogs/MultipleInputDialog';
import { Prompt } from '../Dialogs/Prompt';
import { Select } from '../Dialogs/Select';
import { SelectMultiple } from '../Dialogs/SelectMultiple';
import { SendMessageToCustomer } from '../Dialogs/SendMessageToCustomer';
import { TextDialog } from '../Dialogs/TextDialog';
import { ApplicationStateUpdatedEvent } from '../Events/ApplicationStateUpdatedEvent';
import { BGLoaderEvent } from '../Events/BGLoaderEvent';
import { DataRefreshedEvent } from '../Events/DataRefreshedEvent';
import { InvalidateMapSizesEvent } from '../Events/InvalidateMapSizesEvent';
import { LoaderEvent } from '../Events/LoaderEvent';
import { MenuBarActionsEvent } from '../Events/MenuBarActionsEvent';
import { Initialisation } from '../Initialisation';
import { InvoiceService } from '../Invoices/InvoiceService';
import { EditJobDialog } from '../Jobs/Components/EditJobDialog';
import { NewJobDialog } from '../Jobs/Components/NewJobDialog';
import { JobOccurrenceService } from '../Jobs/JobOccurrenceService';
import { JobService } from '../Jobs/JobService';
import { Logger } from '../Logger';
import { ActionableEvent } from '../Notifications/ActionableEvent';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { SendNotificationService } from '../Notifications/SendNotificationService';
import * as AdvertisementService from '../ReactUI/Marketplace/services/AdvertisementService';
import { ScheduleDetailsRatingsDialog } from '../ReactUI/dialogs/ScheduleDetails/ScheduleDetailsRatingsDialog';
import * as CampaignService from '../ReactUI/send/features/campaigns/CampaignService';
import { RoundDataDialog } from '../Rounds/RoundDataDialog';
import { RoundService } from '../Rounds/RoundService';
import { ScheduleDetailsDialog } from '../Schedule/Components/ScheduleDetailsDialog';
import { ScheduleService } from '../Schedule/ScheduleService';
import { Api } from '../Server/Api';
import { RethinkDbAuthClient } from '../Server/RethinkDbAuthClient';
import { SqueegeeClientSocketApi } from '../Server/SqueegeeClientSocketApi';
import { ServiceDataDialog } from '../Services/ServiceDataDialog';
import { ThemeService } from '../Theme/ThemeService';
import { TimerService } from '../Tracking/TimerService';
import { TrackingService } from '../Tracking/TrackingService';
import { AssignmentService } from '../Users/Assignees/AssignmentService';
import * as MoreUtilities from '../Utilities';
import { Utilities } from '../Utilities';
import { Data } from './Data';
import { SqueegeeLocalStorage } from './SqueegeeLocalStorage';
const packageJson = require('../../package.json');
const version = `${packageJson.version || '?'}`;

(<any>window).moment = moment;
const versionParts = /((?:\d+?\.){2}\d+?)([^\d].+?)?$/.exec(version);
const betaVersion = (versionParts && versionParts[2] && `squeegee® ${versionParts[2].replace('-', '')} v${versionParts[1]}`) || undefined;

export class SqClientApi {
    public static getSqApi() {
        const api = {
            context: {},
            d: SqClientApi,
            common,
            betaVersion,
            version,
            showDialog: (dialog: CustomDialogBase<any>) => {
                Logger.error(`A dialog (${dialog.description}) was generated, but no dialog UI was present! "${dialog.title}"`);
                setTimeout(() => dialog.cancel(), 1000);
            },
            moment,
            ApplicationState,
            Data,
            SqueegeeLocalStorage,
            Utilities,
            MoreUtilities,
            GuiApi: {
                setCustomerContext,
                AssignmentService,
                ApplicationEnvironment,
                NotifyUserMessage,
                RethinkDbAuthClient,
                Api,
                SqueegeeClientSocketApi,
                Logger,
                Utilities,
                ScheduleService,
                JobService,
                JobOccurrenceService,
                InvoiceService,
                Initialisation,
                RoundService,
                SendNotificationService,
                ConnectedServicesService,
                ThemeService,
                TrackingService,
                CampaignService,
                TimerService,
                AdvertisementService,
                Dialogs: {
                    CustomerFormDialog,
                    EditJobDialog,
                    NewJobDialog,
                    ScheduleDetailsDialog,
                    RoundDataDialog,
                    ServiceDataDialog,
                    SelectCustomersDialog,
                    SelectCustomerDialog,
                    Select,
                    SelectMultiple,
                    AlertDetailsDialog,
                    Prompt,
                    SendMessageToCustomer,
                    ScheduleDetailsRatingsDialog,
                    TextDialog,
                    MultipleInputDialog,
                },
                Events: {
                    ApplicationStateUpdatedEvent,
                    ActionableEvent,
                    DataRefreshedEvent,
                    LoaderEvent,
                    BGLoaderEvent,
                    InvalidateMapSizesEvent,
                    MenuBarActionsEvent,
                },
            },
        } as ISqueegeeClient;

        return api;
    }

    public static get DataApi() {
        return Data;
    }
    public static get Customers() {
        return Data.all<Customer>('customers').slice();
    }
    public static get Settings() {
        return Data.all<Setting<any>>('settings').slice();
    }
    public static get Jobs() {
        return Data.all<Customer>('customers')
            .map(c => Object.keys(c.jobs || {}).map(id => c.jobs[id]))
            .reduce((arr, jobs) => {
                for (const job of jobs) arr.push(job);
                return arr;
            }, [] as Array<Job>);
    }
    public static get JobOccurrences() {
        return Data.all<JobOccurrence>('joboccurrences').slice();
    }
    public static get Alerts() {
        return Data.all<Alert>('alerts').slice();
    }
    public static get AccountUsers() {
        return Data.all<AccountUser>('accountuser').slice();
    }
    public static get Transactions() {
        return Data.all<Transaction>('transactions').slice();
    }
    public static get Notifications() {
        return Data.all<Transaction>('notifications').slice();
    }
    public static get Tags() {
        return Data.all<Tag>('tags').slice();
    }
    public static get Services() {
        return Data.all<Service>('tags', { type: TagType.SERVICE }).slice();
    }
    public static get Rounds() {
        return Data.all<Tag>('tags', { type: TagType.ROUND }).slice();
    }
    public static get ExpenseCategories() {
        return Data.all<ExpenseCategory>('tags', { type: TagType.EXPENSE_CATEGORY }).slice();
    }
    public static get DiscountTags() {
        return Data.all<Tag>('tags', { type: TagType.DISCOUNT }).slice();
    }
    public static get UntypedTags() {
        return Data.all<Tag>('tags', { type: TagType.NONE }).slice();
    }
    public static get Teams() {
        return Data.all<Team>('team').slice();
    }
    public static get JobOrderDatas() {
        return Data.all<JobOrderData>('joborderdata').slice();
    }
    public static get DayOrderDatas() {
        return Data.all<DayOrderData>('occurrencesbyday').slice();
    }
    public static get Reports() {
        return Data.all<JobOrderData>('report').slice();
    }
    public static get Signatures() {
        return Data.all<Signature>('signatures').slice();
    }
    public static get Contacts() {
        return Data.all<Contact>('contact').slice();
    }
    public static get Franchisees() {
        return Data.all<Franchisee>('franchisee').slice();
    }
    public static get Invoices() {
        return Data.all<Transaction>('transactions', { transactionType: TransactionType.Invoice }).slice();
    }
    public static get AllPayments() {
        return Data.all<Transaction>('transactions', { transactionType: TransactionType.Payment }).concat(
            Data.all<Transaction>('transactions', { transactionType: TransactionType.AutomaticPayment })
        );
    }
    public static get ManualPayments() {
        return Data.all<Transaction>('transactions', { transactionType: TransactionType.Payment }).slice();
    }
    public static get AutomaticPayments() {
        return Data.all<Transaction>('transactions', { transactionType: TransactionType.AutomaticPayment }).slice();
    }
    public static get Expenses() {
        return Data.all<Transaction>('transactions', { transactionType: TransactionType.Expense }).slice();
    }
    public static get Adjustments() {
        return Data.all<Transaction>('transactions', { transactionType: TransactionType.Adjustment }).slice();
    }

    public static get Devices() {
        return Data.all<Device>('devices').slice();
    }

    public static get Quotes() {
        return Data.all<Quote>('quotes').slice();
    }
}
