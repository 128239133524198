import { Location, uuid } from '@nexdynamic/squeegee-common';
import { computedFrom } from 'aurelia-binding';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import { SecondsToTimeValueConverter } from '../../Converters/SecondsToTimeValueConverter';
import { LocationUtilities } from '../../Location/LocationUtilities';
import { ScheduleItem } from '../../Schedule/Components/ScheduleItem';
import { DayOrderDataService } from '../../Schedule/DayOrderDataService';
import { Utilities } from '../../Utilities';
import type { OrderType } from '../DayPilot';
import type { IOccurrenceOrderDataItem } from './IOccurrenceOrderData';

export class RouteScheduleItem extends Location {
    private _id: string = uuid();
    public scheduleItem?: ScheduleItem;
    public driveTime = '';
    public driveDistance = '';
    public driveDurationFromPrevious = '';

    public constructor(
        public routeOrderData: IOccurrenceOrderDataItem,
        location: Location,
        id: string,
        public icon?: string,
        public subDescription?: string,
        public rank?: number,
        rankField?: OrderType
    ) {
        super(
            location.lngLat,
            location.lngLatSource,
            location.addressDescription,
            location.addressSource,
            location.addressId,
            location.image,
            location.popupText,
            location.isVerified
        );
        this._id = id;
        this.rank = this.getRankFieldValue(rankField);
    }

    private getRankFieldValue(rankField?: OrderType) {
        if (this._id === DayOrderDataService.HOME_LEG_KEY) return 9999;
        switch (rankField) {
            case 'optimal':
                return this.routeOrderData.occurrenceDayOrder;
            case 'planned':
                return this.routeOrderData.occurrenceManualDayOrder;
            case 'time':
                if (!this.scheduleItem || !this.scheduleItem.occurrence.time) return -999;
                return moment(this.scheduleItem.date + ' ' + this.scheduleItem.occurrence.time).unix();
            case 'distance':
                return this.distance;
        }
    }

    public refreshRouteItem(lastTime = moment(), rankField?: OrderType | undefined): moment.Moment {
        const driveDistanceFromPrevious: number = this.routeOrderData.driveDistanceMetresFromPrevLocation || 0;
        const driveDurationSecondsFromPrevLocation: number = this.routeOrderData.driveDurationSecondsFromPrevLocation || 0;
        if (rankField) {
            delete this._avatar;
            delete this._avatarColour;
            this.rank = this.getRankFieldValue(rankField); // Only update rank if given a rank field, i.e. optimising route
        }
        if (this.routeOrderData.lngLat) {
            this.driveDistance =
                ApplicationState.distanceUnits === 'miles'
                    ? LocationUtilities.convertMetresIntoMiles(driveDistanceFromPrevious).toFixed(1) +
                      ApplicationState.distanceUnitsAbbreviated
                    : (driveDistanceFromPrevious / 1000).toFixed(1) + 'km';
            this.driveTime = lastTime.add(driveDurationSecondsFromPrevLocation, 's').format('h:mm a');
            this.driveDurationFromPrevious = SecondsToTimeValueConverter.getFormattedDriveDuration(
                driveDurationSecondsFromPrevLocation,
                true
            );
        }

        const durationInSeconds = this.scheduleItem ? this.scheduleItem.durationInSeconds : 0;
        return lastTime.add(durationInSeconds, 's');
    }

    public static fromScheduleItem(
        routeOrderData: IOccurrenceOrderDataItem,
        scheduleItem: ScheduleItem,
        id: string,
        rank?: number,
        rankField?: OrderType | undefined
    ) {
        if (!scheduleItem.occurrence.location) throw 'Location needed for route item';
        const routeItem = new RouteScheduleItem(
            routeOrderData,
            scheduleItem.occurrence.location,
            id,
            undefined,
            undefined,
            rank,
            rankField
        );
        routeItem.scheduleItem = scheduleItem;
        return routeItem;
    }

    public get id() {
        return this._id;
    }

    public clearDriveInformation() {
        this.routeOrderData.driveDistanceMetresFromPrevLocation = undefined;
        this.routeOrderData.driveDurationSecondsFromPrevLocation = undefined;
        this.routeOrderData.includedInOptimisation = false;
        this.rank = undefined;
    }

    public _distance?: number;
    @computedFrom('_distance')
    public get distance() {
        if (this._distance === undefined) {
            this.refreshDistance();
        }

        return this._distance;
    }

    public refreshDistance() {
        const oldDistance = this._distance || 0;
        if (this.lngLat) {
            const distance = LocationUtilities.getDistance(this.lngLat);
            if (distance !== undefined) {
                this._distance = distance;
            } else {
                this._distance = oldDistance;
            }
        } else {
            this._distance = oldDistance;
        }
    }

    public get jobId() {
        if (!this.scheduleItem) return;
        return this.scheduleItem.job._id;
    }

    public get isLocationDirty() {
        if (!this.scheduleItem) return false;
        const scheduleItemLngLat = this.scheduleItem.occurrence.location ? this.scheduleItem.occurrence.location.lngLat : undefined;
        return !Utilities.arraysAreEqual(scheduleItemLngLat, this.routeOrderData.lngLat);
    }

    public get isPartOfOptimisedRoute() {
        return !!this.routeOrderData.includedInOptimisation;
    }

    public refreshLocation() {
        if (!this.scheduleItem) return;
        this.routeOrderData.lngLat = this.scheduleItem.occurrence.location?.lngLat;
    }

    @computedFrom('driveDurationSecondsFromPrevLocation')
    protected get durationText() {
        if (this.routeOrderData.driveDurationSecondsFromPrevLocation !== undefined)
            return SecondsToTimeValueConverter.getFormattedDriveDuration(this.routeOrderData.driveDurationSecondsFromPrevLocation, true);
    }

    private _avatar?: string;
    @computedFrom('_avatar', 'rank')
    public get avatar() {
        if (this._avatar === undefined) {
            if (this.rank && this.rank > 0 && this.rank < 999) {
                this._avatar = this.rank.toString();
            } else {
                this._avatar = '';
            }
            delete this._avatarColour;
        }

        return this._avatar;
    }

    private _avatarColour?: string;
    @computedFrom('_avatar', '_avatarColour', 'occurrence.location.isVerified')
    public get avatarColour() {
        if (this.scheduleItem && this.scheduleItem.avatarColour) return this.scheduleItem?.avatarColour; //this wraps sched item so why not return?

        if (this._avatarColour === undefined && this.scheduleItem) {
            if (this.scheduleItem.job.rounds && this.scheduleItem.job.rounds.length > 0 && this.scheduleItem.job.rounds[0].color) {
                this._avatarColour = this.scheduleItem.job.rounds[0].color;
            } else {
                const roundName = this.scheduleItem.roundName;
                this._avatarColour = roundName
                    ? ScheduleItem._colours[roundName.substring(0, 1).toUpperCase()]
                    : this.avatar !== undefined
                    ? ScheduleItem._colours[this.avatar.substring(0, 1).toUpperCase()]
                    : '#fff';
            }
     
        }

        
    }

    private _statusColour?: string;
    @computedFrom('_avatar', '_avatarColour')
    public get statusColour() {
        if (this._statusColour === undefined) {
            this._statusColour = (this.scheduleItem && this.scheduleItem.statusColour) || '#CCCCCC';
        }
        return this._statusColour;
    }
}
