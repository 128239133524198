import type {
    ConnectedServiceConfigData,
    ConnectedServiceSettings,
    Customer,
    CustomerProvider,
    Expense,
    PaymentAccount,
    PaymentTransaction,
    TMatcher,
    Transaction,
    TransactionProvider,
    TransferTransaction,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import { TransactionType, wait } from '@nexdynamic/squeegee-common';

import { bindable } from 'aurelia-framework';
import moment from 'moment';
import { AccountsLedgerService } from '../../ChartOfAccounts/AccountsLedgerService';
import { CustomerManualSyncService } from '../../ConnectedServices/Customers/CustomerManualSyncService';
import { MatcherService } from '../../ConnectedServices/MatcherService';
import { CustomerDialog, CustomerDialogTab } from '../../Customers/Components/CustomerDialog';
import { CustomerService } from '../../Customers/CustomerService';
import { Data } from '../../Data/Data';
import { CustomDialogBase } from '../../Dialogs/CustomDialogBase';
import { Prompt } from '../../Dialogs/Prompt';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { ExpenseSummaryDialog } from '../../Expenses/Components/ExpenseSummaryDialog';
import { Logger } from '../../Logger';
import { TransactionDialog } from '../../Transactions/Components/TransactionDialog';
import { TransactionService } from '../../Transactions/TransactionService';
import { TransactionUtils } from '../../Transactions/TransactionsUtils';
import { animate } from '../../Utilities';
import { isDevMode } from '../../isDevMode';
import type { IMatcherEngineDataSources } from './MatcherEngine';

import type { IPossibleCustomerMatch, IPossibleInvoiceMatch, IPossiblePaymentOrExpenseMatch } from './MatchPayment';
import { MatchPayment } from './MatchPayment';
import { MatchPaymentService } from './MatchPaymentService';

export class PaymentsMatchDialog extends CustomDialogBase<Array<MatchPayment>> {
    protected matchedItems: Array<MatchPayment> = [];
    protected filteredItems: Array<MatchPayment> = [];

    protected displayedItems: Array<MatchPayment> = [];
    protected customers: Array<Customer>;
    protected invoices: Array<Transaction>;

    protected showUnmatched = true;
    protected showMatched = false;
    protected showIgnored = false;

    @bindable protected searchText = '';
    protected searchTextChanged = (search: string) => {
        this.filter(search);
    };

    protected totalMatched = 0;
    protected totalUnmatched = 0;
    protected totalIgnored = 0;

    protected likeThisOne?: MatchPayment;
    protected connectedServiceSettings: ConnectedServiceSettings;
    protected connectedServiceData: ConnectedServiceConfigData;

    protected providerName: string;

    protected providerImageUrl: string;

    protected isDevMode = isDevMode();

    protected targetDataSources: IMatcherEngineDataSources;

    protected hasMore: boolean;
    protected loadingMore: boolean;

    protected matchAlreadyMatched = false;
    startBalance?: number;
    endBalance?: number;

    unmatchedTotal?: number;
    matchedTotal?: number;
    ignoredTotal?: number;

    totalIn?: number;
    totalOut?: number;

    constructor(
        public menuTitle: TranslationKey,
        protected providerPayments: Array<Transaction>,
        public provider: TransactionProvider,
        public paymentAccount: PaymentAccount,
        public startDate?: moment.Moment,
        public endDate?: moment.Moment
    ) {
        super('transactionsMatchDialog', '../ConnectedServices/Payments/PaymentsMatchDialog.html', '', {
            isSecondaryView: true,
            okLabel: '',
            cancelLabel: '',
            cssClass: 'payment-match-dialog details-dialog no-nav-shadow',
        });
        this.providerName = this.provider.split('.')[0];

        this.providerImageUrl = `./images/provider-${this.providerName}.png`;
    }

    public async init() {
        try {
            new LoaderEvent(true);
            this.working = true;
            const bal = AccountsLedgerService.getAccountLedgerBalance(
                this.paymentAccount,
                this.startDate?.format('YYYY-MM-DD'),
                this.endDate?.format('YYYY-MM-DD')
            );
            this.startBalance = bal.balance;
            this.endBalance = bal.balance2;

            await wait(1);
            this.targetDataSources = {
                customers: await CustomerService.getCustomers(),
                invoices: await TransactionService.getUnmatchedInvoices(),
                payments: await MatchPaymentService.getPaymentsUnallocated(this.paymentAccount._id, this.provider),
            };

            const matchedItems = await MatchPaymentService.loadMatchesForProviderPayments({
                providerPayments: this.providerPayments,
                provider: this.provider,
                paymentAccountId: this.paymentAccount._id,
                from: this.startDate?.toDate(),
                to: this.endDate?.toDate(),
            });

            this.matchedItems = matchedItems;
            await this.filter();
            this.working = false;
            new LoaderEvent(false);
        } catch (error) {
            Logger.error(`Error during initialise the customer match dialog`, error);
        }
    }

    protected toggleExpand(item: MatchPayment) {
        item.expanded = !item.expanded;
    }

    protected toggleMatched() {
        this.showMatched = !this.showMatched;
        this.filter();
    }

    protected toggleUnmatched() {
        this.showUnmatched = !this.showUnmatched;
        this.filter();
    }

    protected toggleIgnored() {
        this.showIgnored = !this.showIgnored;
        this.filter();
    }

    protected quickFilter = '';

    switchQuickFilter(quickFilter: 'in' | 'out' | '') {
        this.quickFilter = quickFilter;
        this.filter();
    }

    protected loadedIndex = 0;

    private updateCounts() {
        let igC = 0;
        let mtC = 0;
        let umC = 0;
        let umT = 0;
        let mtT = 0;
        let igT = 0;
        let inT = 0;
        let outT = 0;

        //const searchTextAsAPossibleDate = moment(this.searchText, 'l').isValid() && moment(this.searchText, 'l').format('YYYY-MM-DD');
        //const searchTextAsAPossibleNumber = Number(this.searchText);

        for (let i = 0; i < this.matchedItems.length; i++) {
            const itm = this.matchedItems[i];
            if (itm.providerPayment.amount > 0) inT += itm.providerPayment.amount;
            else outT += itm.providerPayment.amount;
            switch (itm.status) {
                case 'ignored':
                    igC++;
                    igT += itm.providerPayment.amount;
                    continue;
                case 'matched':
                    mtC++;
                    mtT += itm.providerPayment.amount;
                    continue;
                case 'unmatched':
                    umC++;
                    umT += itm.providerPayment.amount;
                    continue;
            }
        }

        this.totalIgnored = igC;
        this.totalMatched = mtC;
        this.totalUnmatched = umC;
        this.totalIn = inT;
        this.totalOut = outT;
        this.unmatchedTotal = umT;
        this.matchedTotal = mtT;
        this.ignoredTotal = igT;
        const bal = AccountsLedgerService.getAccountLedgerBalance(
            this.paymentAccount,
            this.startDate?.format('YYYY-MM-DD'),
            this.endDate?.format('YYYY-MM-DD')
        );
        this.startBalance = bal.balance;
        this.endBalance = bal.balance2;
    }

    replaceUnderscoresWithSpaces(str: string) {
        return str.replace(/_/g, ' ');
    }

    private async filter(search?: string) {
        if (search !== undefined) {
            this.searchText = search;
        }

        const filtered: Array<MatchPayment> = [];
        let igC = 0;
        let mtC = 0;
        let umC = 0;
        let umT = 0;
        let mtT = 0;
        let igT = 0;
        let inT = 0;
        let outT = 0;

        //const searchTextAsAPossibleDate = moment(this.searchText, 'l').isValid() && moment(this.searchText, 'l').format('YYYY-MM-DD');
        //const searchTextAsAPossibleNumber = Number(this.searchText);

        for (let i = 0; i < this.matchedItems.length; i++) {
            const itm = this.matchedItems[i];
            if (this.filterMethod(itm)) filtered.push(itm);
            if (itm.providerPayment.amount > 0) inT += itm.providerPayment.amount;
            else outT += itm.providerPayment.amount;
            switch (itm.status) {
                case 'ignored':
                    igC++;
                    igT += itm.providerPayment.amount;
                    continue;
                case 'matched':
                    mtC++;
                    mtT += itm.providerPayment.amount;
                    continue;
                case 'unmatched':
                    umC++;
                    umT += itm.providerPayment.amount;
                    continue;
            }
        }

        this.totalIgnored = igC;
        this.totalMatched = mtC;
        this.totalUnmatched = umC;
        this.totalIn = inT;
        this.totalOut = outT;
        this.unmatchedTotal = umT;
        this.matchedTotal = mtT;
        this.ignoredTotal = igT;
        if (this.likeThisOne && filtered.length === 0) {
            this.likeThisOne = undefined;
            this.filter();
            return;
        }
        this.filteredItems = filtered;
        this.displayedItems = [];

        await this.loadMore();
    }

    protected filterMethod(x: MatchPayment) {
        if (
            ((this.showMatched && x.status === 'matched') ||
                (this.showUnmatched && x.status === 'unmatched') ||
                (this.showIgnored && x.status === 'ignored')) &&
            (!this.likeThisOne || (this.likeThisOne.isSimiliar(x) && x.status === 'unmatched')) &&
            (!this.quickFilter ||
                (this.quickFilter === 'in' && x.providerPayment.amount < 0) ||
                (this.quickFilter === 'out' && x.providerPayment.amount > 0)) &&
            (!this.searchText || x.providerPayment.description?.toLowerCase().includes(this.searchText.toLocaleLowerCase()))
        ) {
            return true;
        }
        return false;
    }

    protected loadMoreFromScroll() {
        this.loadMore();
    }

    protected refresh() {
        this.init();
    }

    async autoMatch() {
        this.working = true;

        const dialog = new Prompt(
            'Include previously matched?' as TranslationKey,
            'Include previously matched transactions? You may need to do this to rematch these transactions if they are coming from your provider as Unmatched.' as TranslationKey,
            { allowCancel: true, okLabel: 'answer.yes', cancelLabel: 'answer.no' }
        );
        const matchAlreadyMatched = await dialog.show();

        const payments = matchAlreadyMatched ? MatchPaymentService.getPayments(this.paymentAccount._id) : this.targetDataSources.payments;

        await MatchPaymentService.autoMatch(
            this.matchedItems.filter(x => x.status === 'unmatched'),
            payments
        );

        //this.init();
        this.working = false;
    }

    async loadMore() {
        if (this.loadingMore) return;

        this.loadingMore = true;
        const newBatch = this.filteredItems.slice(this.displayedItems.length, this.displayedItems.length + 5);
        await MatchPaymentService.refreshSuggestions({
            items: newBatch,
            targetData: this.targetDataSources,
        });
        this.displayedItems.push(...newBatch);

        this.hasMore = this.displayedItems.length < this.filteredItems.length;
        this.loadingMore = false;
    }

    clearLikeThisOne() {
        this.likeThisOne = undefined;
        this.filter();
    }

    onTypeChanged = (matchedItem: MatchPayment) => {
        this.refreshItem(matchedItem);
    };

    onCustomerSelect = async (matchedItem: MatchPayment, event: any) => {
        if (event?.detail?.value === 'find') {
            const cust = await CustomerService.selectOrCreateCustomer({
                ref: matchedItem.providerPayment.customerId || '',
                name: matchedItem.providerPayment.description || '',
            });
            if (cust) {
                const sel = { matchesOn: ['selected'], customer: cust, matchPercent: 1 };

                matchedItem.possibleCustomerMatches.unshift(sel);
                await animate();
                event.srcElement.selectedIndex = 1;
            }
        } else {
            matchedItem.customer = event.detail?.value?.customer;
        }
        const d1 = moment(matchedItem.providerPayment.date);
        const dateDiff = (date2: string) => {
            const d2 = moment(date2);
            return Math.abs(d1.diff(d2, 'days'));
        };

        if (matchedItem.customer) {
            matchedItem.possiblePaymentMatches = TransactionService.getUnmatchedTransactions(this.provider)
                .filter(
                    x =>
                        !x.voidedId &&
                        (x.accountId === this.paymentAccount._id || !x.accountId) &&
                        x.customerId === matchedItem.customer?._id &&
                        Math.abs(x.amount) === Math.abs(matchedItem.providerPayment.amount)
                )
                .map(x => {
                    return {
                        transaction: x,
                        matchPercent: 1,
                        matchesOn: [],
                        customer: matchedItem.customer,
                    };
                })
                .sort((a, b) =>
                    dateDiff(a.transaction.date) > dateDiff(b.transaction.date) ? 1 : a.transaction.date === b.transaction.date ? 0 : -1
                );

            matchedItem.possibleInvoiceMatches = TransactionService.getUnmatchedInvoices()
                .filter(
                    x =>
                        x.customerId === matchedItem.customer?._id &&
                        !x.invoice?.paymentReference &&
                        Math.abs(x.amount) === Math.abs(matchedItem.providerPayment.amount)
                )
                .map(x => {
                    return {
                        invoice: x,
                        matchPercent: 1,
                        matchesOn: [],
                        customer: matchedItem.customer,
                    };
                })
                .sort((a, b) => (dateDiff(a.invoice.date) > dateDiff(b.invoice.date) ? 1 : a.invoice.date === b.invoice.date ? 0 : -1));
        }
        matchedItem.match = undefined;
        matchedItem.createNew = true;
    };

    onPaymentSelect = (matchedItem: MatchPayment, event: any) => {
        // there are unreconciled payments for this customer and you have not selected create or one of the payments then hide create

        if (event.detail.value === 'create') {
            matchedItem.match = undefined;
            matchedItem.createNew = true;
        } else {
            matchedItem.createNew = false;
            matchedItem.match = event.detail.value;
        }
    };

    onInvoiceSelect = (matchedItem: MatchPayment, event: any) => {
        // there are unreconciled payments for this customer and you have not selected create or one of the payments then hide create
        if (['manual', 'auto', 'tip'].includes(event.detail.value)) {
            matchedItem.allocateInvoice = event.detail.value;
        } else {
            matchedItem.invoice = event.detail.value;
        }
    };

    async matchCustomer(matchedItem: MatchPayment) {
        if (!matchedItem.providerPayment.customerId) return;
        const customer = Data.get<Customer>(matchedItem.providerPayment.customerId);
        if (customer) {
            matchedItem.customer = customer;
        }
        if (!customer) {
            matchedItem.customer = await CustomerManualSyncService.importCustomer(
                this.provider as any as CustomerProvider,
                matchedItem.providerPayment.customerId
            );
        }
        matchedItem.loaded = false;
        this.refreshItem(matchedItem);
    }

    private async createExpenseFromMatchedItem(
        matchedItem: MatchPayment,
        template?: Partial<Expense>,
        dontConfirm?: boolean,
        matcher?: TMatcher
    ) {
        const exp = await MatchPaymentService.createExpenseFromMatchedItem({
            matchedItem,
            template,
            paymentAccount: this.paymentAccount,
            dontConfirm,
            matcher,
        });
        if (!exp) return;
        if (!dontConfirm) {
            this.likeThisOne = matchedItem;
            this.filter();
        }
    }

    public view(matchedItem: MatchPayment) {
        if (!matchedItem.match) return;
        if (matchedItem.match.transactionType === TransactionType.Expense) {
            const dialog = new ExpenseSummaryDialog(matchedItem.match._id);
            return dialog.show();
        }
        const summaryDialog = new TransactionDialog(matchedItem.match);
        summaryDialog.show();
    }

    public viewCustomer(id: string) {
        const cust = Data.get<Customer>(id);
        if (!cust) return;
        const customerDialog = new CustomerDialog(cust, CustomerDialogTab.BILLING);
        customerDialog.show();
    }

    private async createPaymentFromMatchedItem(
        matchedItem: MatchPayment,
        template?: Partial<Transaction>,
        dontConfirm?: boolean,
        matcher?: TMatcher
    ) {
        const itm = await MatchPaymentService.createPayment({
            matchedItem,
            template,
            dontConfirm,
            paymentAccount: this.paymentAccount,
            matcher,
        });
        if (!itm) return;

        if (!dontConfirm) {
            this.findOtherMatchesLikeThis(matchedItem);
        }
    }

    findOtherMatchesLikeThis(matchItem: MatchPayment) {
        this.likeThisOne = matchItem;
        this.filter();
    }

    public async assign(matchedItem: MatchPayment) {
        if (matchedItem.providerPayment.amount < 0 && !matchedItem.customer) {
            return await this.createExpenseFromMatchedItem(matchedItem, this.likeThisOne?.match);
        }
        await this.createPaymentFromMatchedItem(matchedItem, this.likeThisOne?.match);
    }

    public async importAsExpense(matchedItem: MatchPayment) {
        return await this.createExpenseFromMatchedItem(matchedItem, this.likeThisOne?.match);
    }

    protected working: boolean;

    public async importLikeThisOne(likeThisOne: MatchPayment) {
        try {
            if (!this.likeThisOne) return;
            if (!this.likeThisOne.match) return;
            if (this.likeThisOne.match?.transactionType === TransactionType.Expense) {
                await this.createExpenseFromMatchedItem(likeThisOne, this.likeThisOne?.match, true);
                return;
            }
            if (this.likeThisOne.match?.transactionSubType === 'transfer.in') {
                await this.transferIn(likeThisOne, this.likeThisOne.match, true);
                return;
            }
            if (this.likeThisOne.match?.transactionSubType === 'transfer.out') {
                await this.transferOut(likeThisOne, this.likeThisOne.match, true);
                return;
            }
            if (this.likeThisOne.match?.transactionType === TransactionType.Payment) {
                await this.createPaymentFromMatchedItem(likeThisOne, this.likeThisOne?.match, true);
            }
        } finally {
            this.recheckMatchedItem(likeThisOne);
        }
    }

    public async repeat(likeThisOne: MatchPayment, matcher: TMatcher) {
        try {
            if (matcher.type === 'Ignore') {
                await this.ignore(likeThisOne);
                return;
            }

            const template = Data.get<Transaction>(matcher.entityId);
            if (!template) return;

            if (template.transactionType === TransactionType.Expense) {
                await this.createExpenseFromMatchedItem(likeThisOne, template, true, matcher);
                return;
            }
            if (template.transactionSubType === 'transfer.in') {
                await this.transferIn(likeThisOne, template, true, matcher);
                return;
            }
            if (template.transactionSubType === 'transfer.out') {
                await this.transferOut(likeThisOne, template, true, matcher);
                return;
            }
            if (template.transactionType === TransactionType.Payment) {
                await this.createPaymentFromMatchedItem(likeThisOne, template, true, matcher);
            }
        } finally {
            this.recheckMatchedItem(likeThisOne);
        }
    }

    public async removeMatcher(matchItem: MatchPayment, matcher: TMatcher) {
        await MatcherService.removeMatcher(this.provider, matcher.matcher);
        this.refreshItem(matchItem);
    }

    public async importAllLikeThisOne() {
        if (!this.likeThisOne) return;
        if (!this.likeThisOne.match) return;

        const desc = TransactionUtils.getSubTypeTitle(this.likeThisOne.match?.transactionType, this.likeThisOne.match?.transactionSubType);
        const dialog = new Prompt('repeat.for-all-import', 'import.each-transactions-as-new-description', {
            localisationParams: { count: this.filteredItems.length.toString(), desc: desc },
        });
        await dialog.show();
        if (dialog.cancelled) return;

        this.working = true;
        for (const likeThisOne of this.filteredItems) {
            await this.importLikeThisOne(likeThisOne);
        }
        this.working = false;
        this.likeThisOne = undefined;
        this.init();
    }

    public async importAsRefund(matchedItem: MatchPayment) {
        await this.createPaymentFromMatchedItem(matchedItem, this.likeThisOne?.match, true);
        this.recheckMatchedItem(matchedItem);
    }

    public async importAsPayment(matchedItem: MatchPayment) {
        await this.createPaymentFromMatchedItem(matchedItem, this.likeThisOne?.match, true);
        this.recheckMatchedItem(matchedItem);
    }

    public async transferOut(matchedItem: MatchPayment, template?: Partial<TransferTransaction>, dontConfirm = false, matcher?: TMatcher) {
        const paymentAccount = this.paymentAccount;
        if (!paymentAccount) return;
        const resp = await MatchPaymentService.transferOut({ matchedItem, paymentAccount, template, matcher, dontConfirm });
        if (!dontConfirm && resp) this.findOtherMatchesLikeThis(matchedItem);
    }

    public async transferIn(matchedItem: MatchPayment, template?: Partial<TransferTransaction>, dontConfirm = false, matcher?: TMatcher) {
        const paymentAccount = this.paymentAccount;
        if (!paymentAccount) return;
        const resp = await MatchPaymentService.transferIn({ matchedItem, paymentAccount, template, matcher, dontConfirm });
        if (!dontConfirm && resp) this.findOtherMatchesLikeThis(resp);
    }

    public async match(matchedItem: MatchPayment, match?: Transaction) {
        match = match || matchedItem.match;
        if (!match) return;

        matchedItem.match = match;
        await MatchPaymentService.match(matchedItem, this.paymentAccount._id, true);
        this.recheckMatchedItem(matchedItem);
    }

    public reject(matchedItem: MatchPayment) {
        const pmt = new MatchPayment(matchedItem.providerPayment, matchedItem.provider, true);
        this.matchedItems.splice(this.matchedItems.indexOf(matchedItem), 1, pmt);
        this.filteredItems.splice(this.filteredItems.indexOf(matchedItem), 1), pmt;
        this.displayedItems.splice(this.displayedItems.indexOf(matchedItem), 1), pmt;
        this.filter();
    }

    public async ignore(matchedItem: MatchPayment) {
        await MatchPaymentService.ignore(matchedItem);
        this.recheckMatchedItem(matchedItem);
    }

    private recheckMatchedItem(matchedItem: MatchPayment) {
        if (this.likeThisOne) return; // We don't want to remove the item if we are in the "like this one" mode

        if (!this.filterMethod(matchedItem)) {
            this.filteredItems.splice(this.filteredItems.indexOf(matchedItem), 1);
            this.displayedItems.splice(this.displayedItems.indexOf(matchedItem), 1);
        }
        this.updateCounts();
    }

    private refreshItem(matchedItem: MatchPayment, dontAutoMatch = false) {
        if (matchedItem.type === 'Ignore') return;
        matchedItem.loaded = false;

        MatchPaymentService.refreshSuggestions({
            items: [matchedItem],
            targetData: this.targetDataSources,
            dontAutoMatch,
        });
    }

    public removeAlreadyAssigned(newAssignment: Transaction) {
        for (let i = 0; i < this.matchedItems.length; i++) {
            const item = this.matchedItems[i];
            if (item.match && item.match._id === newAssignment._id) {
                item.match = undefined;
                item.status = 'unmatched';
                item.loaded = false;
            }
        }
    }

    public async detatch(matchedItem: MatchPayment) {
        await MatchPaymentService.unmatch(matchedItem);
        this.recheckMatchedItem(matchedItem);
        this.refreshItem(matchedItem, true);
    }
    public async detatchInvoice(matchedItem: MatchPayment) {
        await MatchPaymentService.unmatch(matchedItem);
        this.refreshItem(matchedItem, true);
    }

    acceptCustomer(matchedItem: MatchPayment, possibleMatch: IPossibleCustomerMatch) {
        matchedItem.customer = possibleMatch.customer;
        this.refreshItem(matchedItem, true);
    }
    acceptInvoice(matchedItem: MatchPayment, possibleMatch: IPossibleInvoiceMatch) {
        if (!possibleMatch.invoice) return;
        matchedItem.invoice = possibleMatch.invoice;
        matchedItem.customer = possibleMatch.customer;
        this.refreshItem(matchedItem, true);
    }
    acceptPayment(matchedItem: MatchPayment, possibleMatch: IPossiblePaymentOrExpenseMatch) {
        if (!possibleMatch.transaction) return;

        if (possibleMatch.transaction.transactionType === TransactionType.Expense) {
            const exp = possibleMatch.transaction as Expense;
            exp.paymentAccountId = this.paymentAccount?._id;
        } else if (possibleMatch.transaction.transactionType === TransactionType.Payment) {
            const pmt = possibleMatch.transaction as PaymentTransaction;
            pmt.paymentDetails.paymentAccountId = this.paymentAccount?._id;
        }

        matchedItem.match = possibleMatch.transaction;

        this.match(matchedItem);
    }
}
