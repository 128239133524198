import type { IFranchiseeCustomerSummary, TranslationKey } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { LoaderEvent } from '../Events/LoaderEvent';
import { Logger } from '../Logger';
import { Api } from '../Server/Api';
import { Utilities } from '../Utilities';

export class Customers {
    protected customers: Array<IFranchiseeCustomerSummary>;
    protected searchText: string;
    searchTextChangedTimeout: NodeJS.Timeout;
    searching: boolean;
    protected noResultsMessage: TranslationKey | undefined = 'franchise.enter-search-text';
    protected updateNoResultsMessage() {
        if (this.customers?.length) this.noResultsMessage = undefined;
        else if (!this.searchText?.length) this.noResultsMessage = 'franchise.enter-search-text';
        else if (this.searchText.length < 4) this.noResultsMessage = 'franchise.enter-search-text-at-least-4';
        else if (!this.searching) this.noResultsMessage = 'franchise.enter-search-no-matches';
        else this.noResultsMessage = undefined;
    }
    protected searchCustomers = (searchText: string) => {
        clearTimeout(this.searchTextChangedTimeout);
        this.searchTextChangedTimeout = setTimeout(async () => {
            try {
                if (!(await ApplicationState.onlineRequiredCheck('Franchisee Customer Search' as TranslationKey))) return;
                new LoaderEvent(true, true, ApplicationState.localise('franchise.searching-customers'));
                this.searching = true;
                if (searchText?.length < 4) {
                    this.customers = [];
                    this.updateNoResultsMessage();
                    return;
                }

                const customerSearch = await Api.get<Array<IFranchiseeCustomerSummary>>(
                    null,
                    `/api/franchise/franchise-customers?q=${encodeURIComponent(searchText)}`
                );
                this.customers = (customerSearch && customerSearch.data) || [];
            } catch (error) {
                Logger.error('Franchisee customer search failed', error);
            } finally {
                this.searching = false;
                this.updateNoResultsMessage();
                new LoaderEvent(false);
            }
        }, 500);
    };

    protected goToCustomer(customer: IFranchiseeCustomerSummary) {
        Utilities.goToRootUrl({
            applicationRouteWithLeadingSlash: `/customers/${customer.id}`,
            queryStringParams: { dataEmail: customer.franchiseeEmail },
        });
    }
}
