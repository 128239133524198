import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { copyObject } from '@nexdynamic/squeegee-common';
import { CustomDialog } from './CustomDialog';
import type { DialogSettingsSecondaryView, DialogSettingsViewport } from './DialogSettings';

export class SelectMultiple<
    TModel extends { [key: string]: any; description?: string; isSelected?: boolean; displayText?: string }
> extends CustomDialog<Array<TModel & { isSelected: boolean; displayText?: string }>> {
    public disableLocalisation = false;
    public showOrder: boolean;
    public selectedOptions: Array<TModel & { isSelected: boolean; displayText?: string }>;

    constructor(
        protected selectTitle: TranslationKey,
        protected options: Array<TModel>,
        protected textKey: keyof TModel,
        protected uniqueValueKey: keyof TModel,
        //The value of the currently selected items used for a reference in the template to mark the currently selected option
        selectedOptions: Array<TModel> = [],
        settings?: DialogSettingsSecondaryView | DialogSettingsViewport,
        displayTextFunction?: (option: TModel) => string,
        protected emptyMsg: TranslationKey = 'general.no-select-options'
    ) {
        super(
            'selectMultiple',
            './SelectMultiple.html',
            selectTitle || '',
            settings || { cssClass: 'select-dialog', okLabel: '', cancelLabel: '', isSecondaryView: true }
        );
        this.selectedOptions = copyObject(selectedOptions) as Array<TModel & { isSelected: true; displayText?: string }>;
        for (const option of options) {
            option.isSelected = typeof option.value === 'object'
                ? this.selectedOptions.some(o => o.value[this.uniqueValueKey] === option.value[this.uniqueValueKey])
                : false;
            if (displayTextFunction) option.displayText = displayTextFunction(option);
            else option.displayText = option[textKey];
        }
        for (const option of selectedOptions) {
            option.isSelected = true;
            if (displayTextFunction) option.displayText = displayTextFunction(option);
            else option.displayText = option[textKey];
        }


    }

    public contextMenuBarAction = () => this.ok();

    public contextMenuBarText: TranslationKey = 'general.done';

    public clearSelected() {
        for (const option of this.options) {
            option.isSelected = false;
        }
        this.selectedOptions = [];
    }

    protected select(option: TModel & { isSelected: boolean; displayText?: string }) {
        if (option.isSelected) this.unselect(option);
        else {
            option.isSelected = true;
            this.selectedOptions.push(option);
        }
    }

    protected unselect(unselectedOption: TModel & { isSelected: boolean; displayText?: string }) {
        unselectedOption.isSelected = false;
        const indexOfOption = this.selectedOptions.findIndex(
            option => option[this.uniqueValueKey] === unselectedOption[this.uniqueValueKey]
        );
        if (indexOfOption === -1) return;
        this.selectedOptions.splice(indexOfOption, 1);
        this.selectedOptions.forEach(x => (x.isSelected = false));
        requestAnimationFrame(() => this.selectedOptions.forEach(x => (x.isSelected = true)));
    }

    public async getResult() {
        for (const option of this.options) {
            option.isSelected = false;
        }
        return this.selectedOptions;
    }

    protected selectAll() {
        for (const option of this.options as Array<TModel & { isSelected: boolean; displayText?: string }>) {
            if (!option.isSelected) this.selectedOptions.push(option);
            option.isSelected = true;
        }
    }
}
