import type { IAccountingPeriod } from '@nexdynamic/squeegee-common';
import { bindable } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { AccountingPeriodService } from '../../ChartOfAccounts/AccountingPeriodService';
import { Logger } from '../../Logger';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';

export class EditAccountingPeriodCustomElement {
    protected menuTitle = ApplicationState.localise('period.add-period');
    @bindable accountingPeriod: IAccountingPeriod;
    @bindable isEditMode = false;
    onPeriodEndDateChange = (event: Event) => {
        const target = event.target as HTMLInputElement;
        this.accountingPeriod.periodEndDate = target.value;
        // update description with new date
        this.accountingPeriod.description = ApplicationState.localise('period.default-description', {
            date: new Date(this.accountingPeriod.periodEndDate).toLocaleDateString(),
        });
    };

    onSaveButtonClick = async () => {
        try {
            if (this.isEditMode) {
                AccountingPeriodService.updatePeriod(this.accountingPeriod);
                return;
            }
            await AccountingPeriodService.addPeriod(this.accountingPeriod);
        } catch (error) {
            new NotifyUserMessage('notifications.error-during-save');
            return Logger.error(`Error during save of account period`, { data: this.accountingPeriod, error });
        }
    };
}
