import type { Transaction } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom } from 'aurelia-framework';

export class InvoiceSummaryCustomElement {
    @bindable transaction: Transaction;


    @computedFrom('transaction.status')
    protected get hasProcessed() {
        return this.transaction?.status === 'complete';
    }

    @computedFrom('transaction.transactionSubType')
    protected get isInvoice() {

        return this.transaction?.transactionSubType !== 'invoice.tip' && (this.transaction.amount || 0) >= 0;

    }
}
