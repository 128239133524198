import type { Customer, Transaction } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom } from 'aurelia-framework';
import { Data } from '../../Data/Data';
import { InvoiceTransactionSummaryDialog } from '../../Invoices/InvoiceTransactionSummaryDialog';
import { Logger } from '../../Logger';

export class InvoiceCustomElement {
    @bindable invoice?: Transaction;
    customer?: Customer;

    protected async view() {
        try {
            if (this.invoice) {
                new InvoiceTransactionSummaryDialog(this.invoice).show();
            }
        } catch (error) {
            Logger.error('Error in viewing invoice', error);
        }
    }

    attached() {
        if (this.invoice && this.invoice.customerId) {
            this.customer = Data.get(this.invoice.customerId);
        }
    }

    @computedFrom('invoice', 'customer.name')
    get title() {
        if (this.customer) {
            return this.customer.name;
        }
        return this.invoice?.description;
    }

    @computedFrom('invoice', 'customer.address')
    get description() {
        if (this.customer) {
            return this.customer.address.addressDescription;
        }
        return `${this.invoice?.invoice?.billTo || ''} [DELETED]`;
    }
}
