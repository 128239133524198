import type {
    AutomaticPaymentTransactionSubTypeBase,
    Customer,
    ICustomerPaymentProvidersData,
    ISession,
    Quote,
    QuotedJob,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import {
    AlphanumericCharColourDictionary,
    FrequencyType,
    Job,
    JobOccurrence,
    Location,
    PaymentTransaction,
    Transaction,
    TransactionType,
    generateJobOccurrenceId,
    uuid,
} from '@nexdynamic/squeegee-common';

import type { UtmParams } from '@nexdynamic/squeegee-portal-common';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import { AutomaticPaymentsDialog } from '../../AutomaticPayments/Dialogs/AutomaticPaymentsDialog';
import { DateTimePicker } from '../../Components/DateTimePicker/DateTimePicker';
import type { IFabAction } from '../../Components/Fabs/IFabAction';
import { ConnectedServicesService } from '../../ConnectedServices/ConnectedServicesService';
import { ClientArchiveService } from '../../Customers/ClientArchiveService';
import { Data } from '../../Data/Data';
import { SqueegeeImporter } from '../../Data/SqueegeeImporter';
import { AureliaReactComponentDialog } from '../../Dialogs/AureliaReactComponentDialog';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { Prompt } from '../../Dialogs/Prompt';
import { prompt } from '../../Dialogs/ReactDialogProvider';
import { Select } from '../../Dialogs/Select';
import { SendMessageToCustomer } from '../../Dialogs/SendMessageToCustomer';
import { TextDialog } from '../../Dialogs/TextDialog';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import { InvalidateMapSizesEvent } from '../../Events/InvalidateMapSizesEvent';
import { LoaderEvent } from '../../Events/LoaderEvent';
import type { Subscription } from '../../Events/SqueegeeEventAggregator';
import { CreateOrEditInvoiceTransactionDialog } from '../../Invoices/CreateOrEditInvoiceTransactionDialog';
import { InvoiceService } from '../../Invoices/InvoiceService';
import { EditJobDialog } from '../../Jobs/Components/EditJobDialog';
import { NewJobDialog } from '../../Jobs/Components/NewJobDialog';
import { JobOccurrenceService } from '../../Jobs/JobOccurrenceService';
import { JobService } from '../../Jobs/JobService';
import type { JobSummary } from '../../Jobs/JobSummary';
import { LocationUtilities } from '../../Location/LocationUtilities';
import { Logger } from '../../Logger';
import type { IMenuBarAction } from '../../Menus/IMenuBarAction';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { QuoteFormDialog } from '../../Quotes/Dialogs/QuoteFormDialog';
import { LegacyQuote } from '../../Quotes/LegacyQuote';
import type { UtmParamsDialogOptions } from '../../ReactUI/dialogs/CustomerDialog/UtmParamsDialog';
import UtmParamsDialog from '../../ReactUI/dialogs/CustomerDialog/UtmParamsDialog';
import type { TransferJobsProps } from '../../ReactUI/transfer/TransferJobs';
import { TransferJobs } from '../../ReactUI/transfer/TransferJobs';
import { ScheduleActionEvent } from '../../Schedule/Actions/ScheduleActionEvent';
import { ScheduleDetailsDialog } from '../../Schedule/Components/ScheduleDetailsDialog';
import { ScheduleItem } from '../../Schedule/Components/ScheduleItem';
import { ScheduleService } from '../../Schedule/ScheduleService';
import { Api } from '../../Server/Api';
import { MandateApi } from '../../Server/MandateApi';
import { MessageTemplates } from '../../Settings/MessageTemplates';
import { TransactionService } from '../../Transactions/TransactionService';
import { AssignmentService } from '../../Users/Assignees/AssignmentService';
import { Utilities, openSystemBrowser } from '../../Utilities';
import { isDevMode } from '../../isDevMode';
import { CustomerService } from '../CustomerService';
import { checkCustomerPaymentStatuses } from '../methods/checkCustomerPaymentStatuses';
import { CustomerCreditDialog } from './CustomerCreditDialog';
import './CustomerDialog.scss';
import { CustomerFormDialog } from './CustomerFormDialog';
import { CustomerNotesAndActivityDialog } from './CustomerNotesAndActivitiesDialog';
import { CustomerRefundDialog } from './CustomerRefundDialog';
import { JobSummaryDialog } from './JobSummaryDialog';
import { getPaymentMethodDescription } from './getPaymentMethodDescription';
import { getProviderExternalLinkSource } from './getProviderExternalLinkSource';
import { getProviderSourceLink } from './getProviderSourceLink';
import { setCustomerContext } from './setCustomerContext';
export enum CustomerDialogTab {
    CUSTOMERS = 0,
    BILLING = 2,
    MESSAGES = 3,
}

export class CustomerDialog extends CustomDialog<void> {
    private static _cssClasses = 'dialog-has-tabs customer-details-dialog details-dialog no-nav-shadow customer-dialog';

    protected selectedTab: CustomerDialogTab;
    protected jobSummaries: Array<JobSummary> = [];
    protected balance: number;
    protected customerJobReadView: HTMLElement;
    protected customerJobList: HTMLElement;
    protected moreActions: Array<IMenuBarAction>;
    protected locationVerified = false;

    protected stateFlags = ApplicationState.stateFlags;

    protected paymentProviderSetup = ApplicationState.hasAutomaticPaymentMethod();
    protected getProviderSourceLink = (provider: string) => getProviderSourceLink(this.model.customer, provider);

    protected model = {
        customer: <Customer>{},
        menuTitle: <string>'',
    };
    protected canViewFinancesAndMessages = ApplicationState.isInAnyRole(['Admin', 'Owner', 'Creator']);
    protected canViewJobList = ApplicationState.isInAnyRole(['JobEditor', 'Admin', 'Owner', 'Creator']);

    private _dataChangedSub: Subscription;
    protected quotes: Array<Quote>;

    private static _colours = new AlphanumericCharColourDictionary();
    protected extractingJobsAndQuotes: boolean;
    protected hasContactMethods: boolean;
    protected isOwnerOrAdmin = ApplicationState.isInAnyRole(['Admin', 'Owner']);

    constructor(customer: Customer, public tab: CustomerDialogTab = 0) {
        super(customer._id, '../Customers/Components/CustomerDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: CustomerDialog._cssClasses,
            isSecondaryView: true,
        });

        this.model.customer = customer;
        this.model.menuTitle = customer.name;
        this.moreActions = this.getCustomerMoreActions();
        this.setHasContactMethods();

        if (isDevMode()) setCustomerContext(customer);
    }

    private _delegateNewJob = () => this.newJob();
    private _delegateNewQuote = () => this.newQuote('quote');
    private _delegateNewAppointment = () => this.newQuote('appointment');
    private _delegateEdit = () => this.edit();

    private _delegateCollectPayment = () => this.collectPayment();

    private _delegateRefund = () => this.refund();
    private _delegateAllocatePayments = () => this.allocatePayments();
    private _delegateDeleteCustomer = () => this.deleteCustomer();

    private _delegateMessageCustomer = () => this.messageCustomer();
    private _delegateClearPortalLogin = () => this.clearPortalLogin();
    private _delegateViewPortalForCustomer = () => this.viewPortalForCustomer();

    private _delegateRequestPayment = () =>
        this.messageCustomer({
            sms:
                (ApplicationState.messagingTemplates && ApplicationState.messagingTemplates.smsPaymentRequest) ||
                MessageTemplates.instance.smsPaymentRequest.default,
            email:
                (ApplicationState.messagingTemplates && ApplicationState.messagingTemplates.emailPaymentRequest) ||
                MessageTemplates.instance.emailPaymentRequest.default,
            emailIsHtml: true,
        });

    private _delegateChargeCard = () => this.chargeCard();

    private createSampleDirectDebit = async () => {
        const amountDialog = new TextDialog('payments.amount', 'payments.amount', '25', '', undefined, false, 'number');
        const amount = Number(await amountDialog.show());
        if (amountDialog.cancelled || !amount) return;

        let paymentReferenceId = uuid().slice(0, 8);
        const paymentReferenceIdDialog = new TextDialog(
            'label.reference-text',
            'Enter a payment reference ID, this can be used with the test cancelled/failed payments webhooks.' as TranslationKey,
            paymentReferenceId,
            ''
        );
        paymentReferenceId = await paymentReferenceIdDialog.show();
        if (paymentReferenceIdDialog.cancelled) return;

        const type = TransactionType.AutomaticPayment;
        const subtype: AutomaticPaymentTransactionSubTypeBase = 'auto.go-cardless';
        const transaction = new PaymentTransaction(
            type,
            subtype,
            amount,
            'gocardless (generated)',
            {},
            this.model.customer._id,
            'pending',
            moment().format('YYYY-MM-DD')
        );
        transaction.externalIds = { gocardless: paymentReferenceId };
        transaction.paymentDetails.paymentProvider = 'gocardless';
        transaction.paymentDetails.paymentId = paymentReferenceId;
        Data.put(transaction);
    };

    private setHasContactMethods() {
        this.hasContactMethods =
            !!this.model.customer.email || !!this.model.customer.telephoneNumber || !!this.model.customer.telephoneNumberOther;
    }

    private async chargeCard() {
        const amountAsDecimal = this.balance > 0 ? this.balance : 0;
        const dialog = new TextDialog('payments.amount', 'payments.amount', amountAsDecimal.toFixed(2), '', undefined, false, 'number');
        const amount = await dialog.show();
        const amountAsInteger = Number(Number(amount).toFixed(2)) * 100;
        openSystemBrowser(`${Api.currentHostAndScheme}/go/p/${this.model.customer._id}?amount=${amountAsInteger}`);
    }

    private _delegateInvoiceCustomer = () => this.invoiceCustomer();

    protected _delegateAutomaticPayments = () => this.automaticPayments();

    protected customerNotesAndActivity = async () => {
        const customerNotesDialog = new CustomerNotesAndActivityDialog(this.model.customer._id);
        await customerNotesDialog.show();
    };

    protected openCustomerUtmParams = async () => {
        const customerUtmParamsDialog = new AureliaReactComponentDialog<UtmParams, UtmParamsDialogOptions>({
            component: UtmParamsDialog,
            componentProps: {
                utmParams: this.model.customer.utm,
            },
            dialogTitle: 'customer-dialog.utm-params-title',
            isSecondaryView: true,
        });

        const utmParams = await customerUtmParamsDialog.show();

        if (customerUtmParamsDialog.cancelled) return;

        if (utmParams) {
            this.model.customer.utm = utmParams;
            Data.put(this.model.customer);
        }
    };

    get utmParams() {
        return this.model.customer.utm;
    }
    get customerHasDeletedJobs() {
        return !!this.model.customer.deletedJobs && Object.keys(this.model.customer.deletedJobs).length > 0;
    }
    public async init() {
        this._extractJobsAndQuotes();
        this.getBalanceDebounce();

        this._dataChangedSub = DataRefreshedEvent.subscribe((e: DataRefreshedEvent) => {
            if (e.updatedObjects[this.model.customer._id] !== undefined) {
                if (e.updatedObjects[this.model.customer._id] === null) return super.cancel();
                return this._reload();
            }

            if (e.filterByType<QuotedJob>('quotes').find(quote => quote.customerId === this.model.customer._id)) this.loadQuotes();

            if (e.hasCustomerTypesUpdated(this.model.customer._id, 'joboccurrences')) return this._reload();

            if (e.hasCustomerTypesUpdated(this.model.customer._id, 'transactions')) this.getBalanceDebounce();
        });

        this.checkAndUpdateMandateStatus();

        this._updateAutomaticPaymentMethodDescription();

        setTimeout(() => this.switchTab(this.tab), 0);

        Api.isConnected && checkCustomerPaymentStatuses(this.model.customer._id);
    }

    protected automaticPaymentMethodDescription: string;

    protected async checkAndUpdateMandateStatus() {
        try {
            if (!this.model.customer.paymentProviderMetaData?.gocardless) return;
            if (
                this.model.customer &&
                this.model.customer.automaticPaymentMethod === 'gocardless' &&
                (this.model.customer.paymentProviderMetaData?.gocardless?.status === 'invited' ||
                    this.model.customer.paymentProviderMetaData?.gocardless?.status === 'pending')
            ) {
                const originalMandateStatus = this.model.customer.paymentProviderMetaData?.gocardless?.status;
                const checkedAutomaticPaymentStatus = await MandateApi.checkCustomerMandate(this.model.customer._id);

                if (checkedAutomaticPaymentStatus && originalMandateStatus !== checkedAutomaticPaymentStatus) {
                    this.model.customer.paymentProviderMetaData.gocardless.status = checkedAutomaticPaymentStatus;
                    CustomerService.addOrUpdateCustomer(this.model.customer);
                    new NotifyUserMessage('prompts.payment-status-changed');
                }
            }
        } catch (error) {
            Logger.info('Unable to check the GoCardless status for this customer', { customer: this.model.customer, error });
        }
    }

    public dispose() {
        this._dataChangedSub && this._dataChangedSub.dispose();
        super.dispose();
    }

    protected async executePrimary(target: ScheduleItem): Promise<ScheduleItem | null> {
        if (!target.scheduleItemActions.primaryAction) return Promise.reject(null);
        return target.scheduleItemActions.primaryAction.handler();
    }
    protected async executeSecondary(target: ScheduleItem): Promise<ScheduleItem | null> {
        if (!target.scheduleItemActions.secondaryAction) return Promise.reject(null);
        return target.scheduleItemActions.secondaryAction.handler();
    }

    private async deleteCustomer() {
        const balance = TransactionService.getCustomerBalance(this.model.customer._id, moment().format('YYYY-MM-DD'));

        let outstandingBalance = false;

        let prompt: Prompt;

        if (balance.amount !== 0) {
            outstandingBalance = true;
            const balanceAsString = `${ApplicationState.currencySymbol()}${balance.amount < 0 ? 0 - balance.amount : balance.amount}`;
            if (balance.amount < 0) {
                if (this.model.customer.name) {
                    prompt = new Prompt('prompts.confirm-title', 'prompts.delete-customer-named-credit', {
                        okLabel: 'general.delete',
                        localisationParams: { name: this.model.customer.name, balance: balanceAsString },
                    });
                } else {
                    prompt = new Prompt('prompts.confirm-title', 'prompts.delete-customer-unnamed-credit', {
                        okLabel: 'general.delete',
                        localisationParams: { balance: balanceAsString },
                    });
                }
            } else {
                if (this.model.customer.name) {
                    prompt = new Prompt('prompts.confirm-title', 'prompts.delete-customer-named-debt', {
                        okLabel: 'general.delete',
                        localisationParams: { name: this.model.customer.name, balance: balanceAsString },
                    });
                } else {
                    prompt = new Prompt('prompts.confirm-title', 'prompts.delete-customer-unnamed-debt', {
                        okLabel: 'general.delete',
                        localisationParams: { balance: balanceAsString },
                    });
                }
            }
        } else {
            if (this.model.customer.name) {
                prompt = new Prompt('prompts.confirm-title', 'prompts.delete-customer-named', {
                    okLabel: 'general.delete',
                    localisationParams: { name: this.model.customer.name },
                });
            } else {
                prompt = new Prompt('prompts.confirm-title', 'prompts.delete-customer-unnamed', { okLabel: 'general.delete' });
            }
        }
        await prompt.show();
        if (!prompt.cancelled) {
            if (outstandingBalance) {
                const finalBalancingTransaction = new Transaction(
                    TransactionType.Adjustment,
                    'adjustment.closing-write-off',
                    balance.amount,
                    this.model.customer._id,
                    undefined,
                    'Customer deleted settlement balance'
                );
                try {
                    await Data.put(finalBalancingTransaction);
                    try {
                        await CustomerService.removeCustomer(this.model.customer);
                        this.cancel(true);
                    } catch (error) {
                        Logger.error('Error during removeCustomer in customer dialog deleteCustomer()', error);
                        await new Prompt('prompts.error-title', 'prompts.delete-customer-failed-after-balance', {
                            allowCancel: false,
                        }).show();
                    }
                } catch (error) {
                    Logger.error('Error during add the final balance transaction in delete customer in customer dialog', error);
                    await new Prompt('prompts.delete-customer-error-title', 'prompts.delete-customer-error-text', {
                        allowCancel: false,
                    }).show();
                }
            } else {
                await CustomerService.removeCustomer(this.model.customer);
                this.cancel(true);
            }
        }
    }

    protected async viewQuote(quote: Job) {
        new JobSummaryDialog(this.model.customer, quote).show(DialogAnimation.SLIDE_UP);
    }

    protected async repeat(job: Job) {
        const blockOutDates: { [iso: string]: { allowPick: boolean } } = {};
        const existingOccurrences = Object.values(JobOccurrenceService.getAllExistingOccurrencesForJob(job._id)).reduce<JobOccurrence[]>(
            (acc, cur) => {
                acc.push(...cur.map(e => Utilities.copyObject<JobOccurrence>(e)));
                return acc;
            },
            []
        );

        for (const occurrence of existingOccurrences) {
            const date = occurrence.isoCompletedDate || occurrence.isoPlannedDate || occurrence.isoDueDate;
            blockOutDates[date] = { allowPick: false };
        }
        const datePickerDialog = new DateTimePicker(false, moment().format('YYYY-MM-DD'), undefined, undefined, blockOutDates);
        datePickerDialog.init();
        datePickerDialog.title = 'Select next date' as TranslationKey;
        await datePickerDialog.open();
        if (!datePickerDialog.canceled) {
            if (Data.get(generateJobOccurrenceId(job, datePickerDialog.selectedDate))) {
                await new Prompt('prompts.error-title', 'dialogs.repeat-job-already-on-day', { cancelLabel: '' });
                return;
            }

            new LoaderEvent(true);
            const jobOccurrence = new JobOccurrence(job, datePickerDialog.selectedDate);
            job.date = datePickerDialog.selectedDate;
            await JobService.addOrUpdateJob(job.customerId, job);

            await Data.put(jobOccurrence);
            this._reload();
            new LoaderEvent(false);

            // open schedule details dialog so that changes can be made as neccesary
            const occurrenceCustomer = Data.get<Customer>(job.customerId);
            if (!occurrenceCustomer) return;

            const scheduleItem = new ScheduleItem(job, occurrenceCustomer, jobOccurrence);
            new ScheduleDetailsDialog(scheduleItem).show();
        }
    }

    private async messageCustomer(messages?: { sms: string; email: string; emailIsHtml: boolean }) {
        const notifyDialog = new SendMessageToCustomer(this.model.customer, messages);
        return await notifyDialog.show();
    }

    private async clearPortalLogin() {
        delete this.model.customer.__auth;
        Data.put(this.model.customer);
    }

    private async viewPortalForCustomer() {
        const sessionResponse = await Api.get<{ success: boolean; error?: string; session?: ISession }>(
            Api.apiEndpoint,
            `/api/directory/session/${this.model.customer._id}`
        );
        const session = sessionResponse?.data?.session;
        const success = sessionResponse?.data?.success;

        if (!session || !success) {
            new NotifyUserMessage('actions.failed-to-view-portal');
            return;
        }

        // const portalUrl = ApplicationState.getSetting<string | undefined>('global.portal-url');
        // if (!portalUrl) return new NotifyUserMessage('actions.failed-to-view-portal');

        const query = `?id=${ApplicationState.account.uuid}&endpoint=${Api.currentHostAndScheme}&layout=Layout%201&sessionKey=${session.key}&sessionValue=${session.value}&viewing=true`;

        if (window.location.hostname === 'localhost' && isDevMode()) {
            openSystemBrowser(`http://localhost:3006${query}`, undefined, undefined, '_portal-customer');
        } else {
            const portalUrl = `/go/sample-portal`;
            openSystemBrowser(portalUrl + query, undefined, undefined, '_portal-customer');
        }
    }

    private async invoiceCustomer() {
        let addUninvoiced = false;
        const p = prompt('prompts.auto-allocate-all-done-jobs', 'prompts.auto-allocate-all-done-jobs-text', {
            okLabel: 'general.yes',
            cancelLabel: 'general.no',
        });

        addUninvoiced = await p.show();

        const dialog = CreateOrEditInvoiceTransactionDialog.createDialog(
            this.model.customer,
            undefined,
            undefined,
            addUninvoiced ? 'all' : 'none'
        );
        if (!dialog) return;

        await dialog.show(DialogAnimation.SLIDE_UP);
    }

    public switchTab(tab: CustomerDialogTab) {
        this.tab = tab;
        this.setFabActions();
        new InvalidateMapSizesEvent();
    }

    public async newJob(jobDate?: string) {
        const jobLocation = Utilities.copyObject(this.model.customer.address) || new Location();

        const frequency = ApplicationState.defaultJobFrequency;
        const type = (frequency && frequency.type) || FrequencyType.NoneRecurring;
        const interval = (frequency && frequency.interval) || 0;

        const newJob = new Job(
            this.model.customer._id,
            undefined,
            undefined,
            jobDate || moment().format('YYYY-MM-DD'),
            jobLocation,
            undefined,
            undefined,
            undefined,
            undefined,
            interval,
            type,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            ApplicationState.account.defaultJobDuration
        );

        const dialog = new NewJobDialog(this.model.customer, newJob, undefined, undefined, undefined, false);
        await dialog.show(DialogAnimation.SLIDE_UP);
        this._reload();
    }

    public async newQuote(type: 'quote' | 'appointment') {
        if (type === 'quote') {
            const dialog = new QuoteFormDialog(this.model.customer);
            await dialog.show(DialogAnimation.SLIDE_UP);
        } else {
            const dialog = new LegacyQuote(this.model.customer, type);
            await dialog.show(DialogAnimation.SLIDE_UP);
        }
        this._reload();
    }

    public async editJob(job: Job) {
        const dialog = new EditJobDialog(this.model.customer, job);
        const updated = await dialog.show(DialogAnimation.SLIDE);
        if (!updated) return;

        this._reload();
    }

    public async resetJobSchedule(job: Job) {
        const prompt = new Prompt('prompts.confirm-title', 'prompts.reset-job-confirm-text', {
            okLabel: 'general.yes',
            cancelLabel: 'general.no',
        });
        const result = await prompt.show();
        if (result) {
            try {
                const date = moment().format().substring(0, 10);
                await JobService.hardResetSchedule(job, date, false);
                new ScheduleActionEvent('action-replan');
                this._reload();
            } catch (error) {
                new NotifyUserMessage('notifications.job-delete-failed');
                Logger.error('Error during reset job shedule from customer dialog', error);
            }
        }
    }

    public async pauseJobSchedule(job: Job) {
        const prompt = new Prompt('prompts.confirm-title', 'job.schedule-pause-job-confirm-text', {
            okLabel: 'general.yes',
            cancelLabel: 'general.no',
        });
        const result = await prompt.show();
        if (result) {
            try {
                const customer = Data.get<Customer>(job.customerId);
                if (!customer) return;

                delete job.date;
                customer.jobs[job._id] = job;
                await Data.put(customer);
                new ScheduleActionEvent('action-replan');
                this._reload();
            } catch (error) {
                new NotifyUserMessage('job.schedule-pause-failed');
                Logger.error('Error during pause job shedule from customer dialog', error);
            }
        }
    }

    public async resumeJobSchedule(job: Job) {
        const prompt = new Prompt('prompts.confirm-title', 'job.schedule-resume-job-confirm-text', {
            okLabel: 'general.yes',
            cancelLabel: 'general.no',
        });
        const result = await prompt.show();
        if (result) {
            try {
                const customer = Data.get<Customer>(job.customerId);
                if (!customer) return;

                const picker = new DateTimePicker(false, moment().format('YYYY-MM-DD'), 'job.schedule-resume-date');
                picker.init();
                await picker.open();
                if (picker.canceled) return;

                job.date = picker.selectedDate;

                customer.jobs[job._id] = job;
                await Data.put(customer);
                new ScheduleActionEvent('action-replan');
                this._reload();
            } catch (error) {
                new NotifyUserMessage('job.schedule-resume-failed');
                Logger.error('Error during resume job shedule from customer dialog', error);
            }
        }
    }

    public async deactivateJobSchedule(job: Job) {
        const prompt = new Prompt('prompts.confirm-title', 'job.schedule-deactivate-job-confirm-text', {
            okLabel: 'general.yes',
            cancelLabel: 'general.no',
        });
        const result = await prompt.show();
        if (result) {
            try {
                const customer = Data.get<Customer>(job.customerId);
                if (!customer) return;

                job.state = 'inactive';
                customer.jobs[job._id] = job;
                await Data.put(customer);

                this._reload();
            } catch (error) {
                new NotifyUserMessage('job.schedule-deactivate-failed');
                Logger.error('Error during deactivate job shedule from customer dialog', error);
            }
        }
    }

    public async activateJobSchedule(job: Job) {
        const prompt = new Prompt('prompts.confirm-title', 'job.schedule-activate-job-confirm-text', {
            okLabel: 'general.yes',
            cancelLabel: 'general.no',
        });
        const result = await prompt.show();
        if (result) {
            try {
                const customer = Data.get<Customer>(job.customerId);
                if (!customer) return;

                job.state = 'active';
                customer.jobs[job._id] = job;
                await Data.put(customer);

                this._reload();
            } catch (error) {
                new NotifyUserMessage('job.schedule-activate-failed');
                Logger.error('Error during activate job shedule from customer dialog', error);
            }
        }
    }

    public async deleteJob(job: Job) {
        const prompt = new Prompt('prompts.confirm-title', 'prompts.delete-job-confirm-text', {
            okLabel: 'general.yes',
            cancelLabel: 'general.no',
        });
        const result = await prompt.show();
        if (result) {
            try {
                await JobService.removeJob(this.model.customer._id, job);
                this._reload();
            } catch (error) {
                new NotifyUserMessage('notifications.job-delete-failed');
                Logger.error(`Error during delete job for customer in customer dialog`, { job, customer: this.model.customer, error });
            }
        }
    }

    public async edit() {
        const dialog = new CustomerFormDialog(Utilities.copyObject<Customer>(this.model.customer));
        dialog.isEdit = true;
        await dialog.show(DialogAnimation.SLIDE);
        this._reload();
    }

    public async allocatePayments() {
        try {
            await InvoiceService.allocateBalanceToInvoices(this.model.customer._id);
            this._reload();
        } catch (error) {
            Logger.error(`Error during allocate payment for customer ${this.model.customer._id} in customer dialog.`, error);
        }
    }

    public async collectPayment() {
        try {
            const dialog = CustomerCreditDialog.createForCustomer(this.model.customer);
            await dialog.show(DialogAnimation.SLIDE_UP);
            this._reload();
        } catch (error) {
            Logger.error(`Error during collect payment for customer ${this.model.customer._id} in customer dialog.`, error);
        }
    }

    public async refund() {
        try {
            const dialog = CustomerRefundDialog.createForCustomer(this.model.customer);
            await dialog.show(DialogAnimation.SLIDE_UP);
            this._reload();
        } catch (error) {
            Logger.error(`Error during collect payment for customer ${this.model.customer._id} in customer dialog.`, error);
        }
    }

    public async viewScheduleItem(scheduleItem: ScheduleItem) {
        try {
            const dialog = new ScheduleDetailsDialog(scheduleItem);
            await dialog.show(DialogAnimation.SLIDE);
            this._reload();
        } catch (error) {
            Logger.error(
                `Error during view schedule item for job ${scheduleItem.job} for customer ${scheduleItem.customer._id} in customer dialog`,
                error
            );
        }
    }

    public getRoundColor(round: any) {
        if (round.color) return round.color;
        return CustomerDialog._colours[round.description.substring(0, 1).toUpperCase()];
    }

    private _providers?: Array<string>;

    public get providers() {
        if (!this._providers) {
            const providers = [] as Array<string>;
            if (!this.model.customer) return providers;
            if (this.hasPaymentMethodActive('stripe')) providers.push('stripe');
            if (this.hasPaymentMethodActive('gocardless')) providers.push('gocardless');
            if (this.model.customer.externalIds?.xero) providers.push('xero');
            this._providers = providers;
        }
        return this._providers;
    }

    protected getPaymentMethodDescription = (provider: keyof ICustomerPaymentProvidersData) =>
        getPaymentMethodDescription(this.model.customer, provider);

    protected getProviderExternalLinkSource = (provider: keyof ICustomerPaymentProvidersData) =>
        getProviderExternalLinkSource(getProviderSourceLink(this.model.customer, provider));

    private hasPaymentMethodActive(provider: 'stripe' | 'gocardless'): boolean {
        if (!this.model.customer.externalIds?.[provider]) return false;
        if (this.model.customer.paymentProviderMetaData?.[provider]?.status === 'active') return true;
        if (this.model.customer.paymentProviderMetaData?.[provider]?.status === 'pending') return true;
        return false;
    }

    public async skipScheduleItem(scheduleItem: ScheduleItem) {
        await ScheduleService.bulkSkipScheduleItems([scheduleItem], () => {
            return;
        });
        this._reload();
    }

    public async deleteScheduleItem(scheduleItem: ScheduleItem) {
        if (
            await new Prompt(
                'Delete Appointment' as TranslationKey,
                'Are you sure you wish to delete this appointment?' as TranslationKey,
                {
                    okLabel: 'Delete' as TranslationKey,
                    cancelLabel: 'Cancel' as TranslationKey,
                }
            ).show()
        ) {
            new LoaderEvent(true);
            if (
                scheduleItem.job.frequencyType === FrequencyType.NoneRecurring &&
                scheduleItem.job.date &&
                scheduleItem.job.date === (scheduleItem.occurrence.isoPlannedDate || scheduleItem.occurrence.isoDueDate)
            ) {
                const latestOccurrence = JobOccurrenceService.getLatestNotDoneOccurrenceByJobId(
                    scheduleItem.job._id,
                    scheduleItem.occurrence._id
                );
                scheduleItem.job.date = latestOccurrence?.isoPlannedDate || latestOccurrence?.isoDueDate;
                await JobService.addOrUpdateJob(scheduleItem.customer._id, scheduleItem.job);
            }
            await Data.delete(scheduleItem.occurrence);
            await AssignmentService.clear(scheduleItem.occurrence);
            this._reload();
            new LoaderEvent(false);
        }
    }

    _balanceRefreshTimer: any;
    private getBalanceDebounce() {
        clearTimeout(this._balanceRefreshTimer);
        this._balanceRefreshTimer = setTimeout(() => {
            const balance = TransactionService.getCustomerBalance(this.model.customer._id, moment().format('YYYY-MM-DD'));
            this.balance = balance.amount;
            this.setFabActions();
        }, 200);
    }

    protected currentHostAndScheme = Api.currentHostAndScheme;

    private async _extractJobsAndQuotes() {
        if (!this.jobSummaries) this.extractingJobsAndQuotes = true;
        try {
            const jobSummaries = await JobService.getJobSummariesForCustomer(this.model.customer, undefined, undefined, true);
            const activeRepeating = jobSummaries.filter(
                x => x.job.state !== 'inactive' && x.job.frequencyType !== FrequencyType.NoneRecurring
            );
            const activeNonRepeating = jobSummaries.filter(
                x => x.job.state !== 'inactive' && x.job.frequencyType === FrequencyType.NoneRecurring
            );
            const inactiveRepeating = jobSummaries.filter(
                x => x.job.state === 'inactive' && x.job.frequencyType !== FrequencyType.NoneRecurring
            );
            const inactiveNonRepeating = jobSummaries.filter(
                x => x.job.state === 'inactive' && x.job.frequencyType === FrequencyType.NoneRecurring
            );
            this.jobSummaries = [...activeRepeating, ...activeNonRepeating, ...inactiveRepeating, ...inactiveNonRepeating];
            this.loadQuotes();
        } catch (error) {
            Logger.error('Error during extracting jobs and quotes for customer', error);
        }
        this.extractingJobsAndQuotes = false;
    }

    private loadQuotes() {
        this.quotes = Data.all<Quote>('quotes', { customerId: this.model.customer._id }).slice();
    }

    private _reloadTimer: any;
    private async _reload() {
        clearTimeout(this._reloadTimer);
        this._reloadTimer = setTimeout(() => {
            const customer = CustomerService.getCustomer(this.model.customer._id);
            if (customer) {
                this.model = { customer, menuTitle: customer.name };
                this.getBalanceDebounce();
                this._extractJobsAndQuotes();
                this._updateAutomaticPaymentMethodDescription();
                this.moreActions = this.getCustomerMoreActions();
            }
            this.setHasContactMethods();
        }, 200);
    }

    private _updateAutomaticPaymentMethodDescription() {
        if (this.model.customer.automaticPaymentMethod) {
            const customPaymentMethods = ApplicationState.getSetting<Record<string, string>>('global.custom-payment-methods', {});
            if (this.model.customer.automaticPaymentMethod === 'stripe' || this.model.customer.automaticPaymentMethod === 'gocardless') {
                this.automaticPaymentMethodDescription = this.model.customer.automaticPaymentMethod;
            } else {
                this.automaticPaymentMethodDescription = customPaymentMethods[this.model.customer.automaticPaymentMethod];
            }
        } else {
            this.automaticPaymentMethodDescription = 'No automatic payment method selected, tap here to configure.';
        }
    }

    private setFabActions() {
        if (this.tab === CustomerDialogTab.MESSAGES) {
            this.fabActions = undefined;
        } else {
            this.fabActions = this.getCustomerActions();
        }
    }

    private getCustomerActions(): Array<IFabAction> {
        const fabActions: Array<IFabAction> = [
            {
                tooltip: 'actions.add-job',
                actionType: 'action-new-job',
                handler: this._delegateNewJob,
                roles: ['Owner', 'Admin', 'Creator', 'JobEditor'],
            },
            {
                tooltip: 'actions.create-quote',
                actionType: 'action-new-quote',
                handler: this._delegateNewQuote,
                roles: ['Owner', 'Admin', 'Creator'],
            },
            {
                tooltip: 'actions.create-appointment',
                actionType: 'action-new-appointment',
                handler: this._delegateNewAppointment,
                roles: ['Owner', 'Admin', 'Creator'],
            },
            {
                tooltip: 'actions.record-payment',
                actionType: 'action-credit',
                handler: this._delegateCollectPayment,
                roles: ['Owner', 'Admin', 'Creator'],
            },
            {
                tooltip: 'actions.record-refund',
                actionType: 'action-credit',
                handler: this._delegateRefund,
                roles: ['Owner', 'Admin', 'Creator'],
            },
            {
                tooltip: 'actions.send-message',
                actionType: 'action-notify',
                handler: this._delegateMessageCustomer,
                roles: ['Owner', 'Admin', 'Creator'],
            },
            {
                tooltip: 'actions.create-invoice',
                actionType: 'action-invoice',
                handler: this._delegateInvoiceCustomer,
                roles: ['Owner', 'Admin', 'Creator'],
            },
        ];

        fabActions.push({
            tooltip: 'actions.request-payment',
            actionType: 'action-request-payment',
            handler: this._delegateRequestPayment,
            roles: ['Owner', 'Admin', 'Creator'],
        });

        return fabActions.filter(x => ApplicationState.isInAnyRole(x.roles));
    }

    private getCustomerMoreActions(): Array<IMenuBarAction> {
        const moreActions: Array<IMenuBarAction> = [
            {
                tooltip: 'customer.menu-edit-customer',
                actionType: 'action-edit',
                handler: this._delegateEdit,
                roles: ['Owner', 'Admin', 'Creator', 'Canvasser'],
            },
            {
                tooltip: 'refresh.invoice-statuses',
                actionType: 'action-reset-to-job-defaults',
                handler: this._delegateAllocatePayments,
                roles: ['Owner', 'Admin', 'Creator'],
            },
            {
                tooltip: 'customer.menu-unarchive-transactions',
                actionType: 'action-reset-to-job-defaults',
                handler: () => ClientArchiveService.unarchiveCustomerTransactions(this.model.customer._id),
                roles: ['Owner', 'Admin', 'Creator'],
            },
            {
                tooltip: 'customer.menu-unarchive-messages',
                actionType: 'action-reset-to-job-defaults',
                handler: () => ClientArchiveService.unarchiveCustomerNotifications(this.model.customer._id),
                roles: ['Owner', 'Admin', 'Creator'],
            },
            {
                tooltip: 'customer.menu-unarchive-appointments',
                actionType: 'action-reset-to-job-defaults',
                handler: () => ClientArchiveService.unarchiveCustomerJobOccurrences(this.model.customer._id),
                roles: ['Owner', 'Admin', 'Creator'],
            },
        ];

        if (ApplicationState.hasAdvancedOrAbove) {
            moreActions.push({
                tooltip: 'actions.reset-portal-login',
                actionType: 'action-reset-to-job-defaults',
                handler: this._delegateClearPortalLogin,
                roles: ['Owner', 'Admin', 'Creator'],
            });
            moreActions.push({
                tooltip: 'actions.charge-card',
                actionType: 'action-invoice',
                handler: this._delegateChargeCard,
                roles: ['Owner', 'Admin', 'Creator'],
            });
        }

        if (ApplicationState.hasAdvancedOrAbove) {
            moreActions.push({
                tooltip: 'actions.view-portal-as-customer',
                actionType: 'action-reset-to-job-defaults',
                handler: this._delegateViewPortalForCustomer,
                roles: ['Owner', 'Admin', 'Creator'],
            });
        }

        if (ApplicationState.hasUltimateOrAbove) {
            moreActions.push({
                tooltip: 'import.jobs',
                actionType: 'action-edit',
                handler: async () => {
                    SqueegeeImporter.importJobsOnlySqueegeeCSV(this.model.customer);
                },
                roles: ['Owner', 'Admin', 'Creator'],
            });
        }

        if (ApplicationState.stateFlags.devMode) {
            if (this.model.customer.externalIds?.xero) {
                moreActions.push({
                    tooltip: 'Update with Xero' as TranslationKey,
                    actionType: 'action-edit',
                    handler: async () => {
                        await ConnectedServicesService.updateConnectedEntity('xero', this.model.customer._id);
                    },
                    roles: ['Owner', 'Admin'],
                });
                moreActions.push({
                    tooltip: 'Delete in Xero' as TranslationKey,
                    actionType: 'action-edit',
                    handler: async () => {
                        await ConnectedServicesService.deleteConnectedEntity('xero', this.model.customer._id);
                    },
                    roles: ['Owner', 'Admin'],
                });
            } else {
                moreActions.push({
                    tooltip: 'Create in Xero' as TranslationKey,
                    actionType: 'action-edit',
                    handler: async () => {
                        await ConnectedServicesService.createConnectedEntity('xero', 'customers', this.model.customer._id);
                    },
                    roles: ['Owner', 'Admin'],
                });
            }
        }

        if (isDevMode()) {
            moreActions.push({
                tooltip: 'customer.generate-fake-direct-debit',
                actionType: 'action-invoice',
                handler: this.createSampleDirectDebit,
                roles: ['Owner', 'Admin', 'Creator'],
            });
        }
        if (this.customerHasDeletedJobs) {
            moreActions.push({
                tooltip: 'customer.menu-restore-deleted-job',
                actionType: 'action-reset-to-job-defaults',
                handler: () => this.restoreDeletedJob(),
                roles: ['Owner', 'Admin', 'Creator'],
            });
        }
        // WTF: must be the last action in the list
        moreActions.push({
            tooltip: 'customer.menu-delete-customer',
            actionType: 'action-delete',
            handler: this._delegateDeleteCustomer,
            roles: ['Owner', 'Admin', 'Creator'],
        });

        return moreActions;
    }

    public async verifyAddress() {
        if (this.model.customer && this.model.customer.address) {
            const oldAddress = Utilities.copyObject(this.model.customer.address) || new Location();
            const locationUpdated = await LocationUtilities.updateLocationIfUnverified(this.model.customer.address);
            if (locationUpdated) {
                await LocationUtilities.updateCustomerAndJobLocationsOnVerification(
                    this.model.customer,
                    this.model.customer._id,
                    oldAddress,
                    this.model.customer.address
                );
            }
        }
    }

    private async automaticPayments() {
        const dialog = new AutomaticPaymentsDialog(this.model.customer._id);
        await dialog.show(DialogAnimation.SLIDE_UP);
    }

    protected canSellJob = ApplicationState.features.marketplaceSeller && ApplicationState.isInAnyRole(['Admin', 'Owner']);

    sellJob(job: Job) {
        ApplicationState.navigateToRouteFragment(`marketplace/sell-jobs?jobIds=${job.customerId + ':' + job._id}`);
        this.cancel();
    }

    protected canTransfer =
        ApplicationState.stateFlags.devMode || (ApplicationState.features.transferData && ApplicationState.isInAnyRole(['Owner', 'Admin']));
    protected async transferSingle(job: Job) {
        const jobIds = `${job.customerId + ':' + job._id}`;

        const { dialog, show } = AureliaReactComponentDialog.show<boolean, TransferJobsProps>({
            dialogTitle: 'Transfer Jobs' as TranslationKey,
            component: TransferJobs,
            componentProps: {
                jobIds: [jobIds],
            },
            isSecondaryView: true,
        });

        const result = await show;

        if (dialog.cancelled) return;

        if (result) {
            this.ok();
            new NotifyUserMessage('jobs.transfer-complete');
        }
    }

    public async restoreCustomer() {
        const confirmPrompt = new Prompt('general.confirm', 'deleted-customer.confirm-restore');
        await confirmPrompt.show();
        if (confirmPrompt.cancelled) return this.cancel();

        this.model.customer._deleted = false;
        Data.put(this.model.customer);
        this.ok();
        new CustomerDialog(this.model.customer).show();
        new NotifyUserMessage('deleted-customer.restore-complete');
    }

    private async restoreDeletedJob() {
        const options: Array<{ text: string; value: string; dateDeleted: string }> = [];
        if (!this.model.customer.deletedJobs) return;
        Object.entries(this.model.customer.deletedJobs).forEach(([jobId, job]) => {
            const jobSummary = JobService.getJobSummaryForJobAndCustomer(job, this.model.customer);
            console.log(jobSummary);
            const dateDeleted = job.updatedDate; // assume updated date is the date deleted
            const jobSummaryText = `${jobSummary.servicesDescription} ${
                jobSummary.frequency
                    ? ApplicationState.localise(
                          jobSummary.frequency.localisationKeyAndParams.key,
                          jobSummary.frequency?.localisationKeyAndParams.params
                      )
                    : ''
            } (${ApplicationState.localise('job.deleted-date')}: ${new Date(dateDeleted).toDateString()})`;
            options.push({
                text: jobSummaryText,
                value: jobId,
                dateDeleted: job.updatedDate,
            });
        });
        // sort jobs by date deleted most recent first
        options.sort((a, b) => b.dateDeleted.localeCompare(a.dateDeleted));
        const dialog = new Select('dialogs.select-job-to-restore', options, 'text', 'value', undefined);
        const result = await dialog.show(DialogAnimation.SLIDE);

        if (!dialog.cancelled) {
            if (result.value) {
                const selectedJobToRestoreId = result.value;
                const job = this.model.customer.deletedJobs[selectedJobToRestoreId];
                delete this.model.customer.deletedJobs[selectedJobToRestoreId];
                this.model.customer.jobs[job._id] = job;
                await Data.put(this.model.customer);
                new NotifyUserMessage('dialogs.job-restored');
                await this._reload();
            }
        }
    }
}
