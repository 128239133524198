import type { Customer, InvoiceItem, JobOccurrence, Transaction, TranslationKey } from '@nexdynamic/squeegee-common';
import { Invoice, JobOccurrenceStatus, sortByDateAsc } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import { CustomerService } from '../Customers/CustomerService';
import { Data } from '../Data/Data';
import { CustomDialog } from '../Dialogs/CustomDialog';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { Prompt } from '../Dialogs/Prompt';
import { LoaderEvent } from '../Events/LoaderEvent';
import { SelectScheduleItemsDialog } from '../Jobs/Components/SelectScheduleItemsDialog';
import { DateFilterOption } from '../Jobs/Filters/DateFilterOption';
// import { SelectScheduleItemsDialog } from '../Jobs/Components/SelectScheduleItemsDialog';
import { JobOccurrenceService } from '../Jobs/JobOccurrenceService';
import { JobService } from '../Jobs/JobService';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { ScheduleItem } from '../Schedule/Components/ScheduleItem';
// import { ScheduleItem } from '../Schedule/Components/ScheduleItem';
import { InvoiceLineItemDialog } from './InvoiceLineItemDialog';
import { InvoiceService } from './InvoiceService';

export class CreateOrEditInvoiceTransactionDialog extends CustomDialog<Transaction> {
    protected currencySymbol = ApplicationState.currencySymbol();
    protected hasAdvancedOrAbove = ApplicationState.hasAdvancedOrAbove;
    public formIsDirty = false;
    public invoice: Invoice;
    private occurrences: Array<JobOccurrence> = [];
    private selectedOccurrences: Array<JobOccurrence> = [];
    protected occurrence: JobOccurrence | undefined;
    protected transaction?: Transaction;

    protected get hideTax() {
        return (
            this.invoice.taxEnabled === false ||
            this.customer.hideTax === true ||
            (this.invoice.taxEnabled === undefined && Invoice.legacyTaxable(this.invoice) === false)
        );
    }

    protected readonly hasJobs: boolean;
    public static createDialog(
        customer: Customer,
        occurrence?: JobOccurrence,
        showUserInvoiceSummary = true,
        addAllUninvoicedJobs?: 'all' | 'none'
    ) {
        if (!occurrence && !Object.keys(customer.jobs).length && !ApplicationState.hasAdvancedOrAbove) {
            new NotifyUserMessage('notification.no-jobs-available-to-be-invoiced');
            return;
        }

        const dialog = new CreateOrEditInvoiceTransactionDialog(
            customer,
            'invoices.create-invoice-button',
            false,
            showUserInvoiceSummary,
            addAllUninvoicedJobs
        );
        dialog.title = 'invoices.create-invoice-title';
        dialog.occurrence = occurrence;
        dialog.invoice = InvoiceService.createInvoiceForCustomer(customer);
        return dialog;
    }
    public static editDialog(customer: Customer, transaction: Transaction, showUserInvoiceSummary = true) {
        const dialog = new CreateOrEditInvoiceTransactionDialog(customer, 'invoices.save-invoice-button', true, showUserInvoiceSummary);
        dialog.transaction = transaction;
        dialog.title = ApplicationState.localise('invoices.edit-invoice-title', {
            invoiceNumber: (transaction.invoice?.invoiceNumber || '').toString(),
        });
        dialog.invoice = transaction.invoice as Invoice;
        return dialog;
    }
    private constructor(
        protected customer: Customer,
        protected saveButtonText: TranslationKey,
        protected isEdit: boolean,
        protected showUserInvoiceSummary: boolean = true,
        protected addAllUninvoicedJobs?: 'all' | 'none'
    ) {
        super('createInvoiceDialog', '../Invoices/CreateOrEditInvoiceTransactionDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'create-invoice-dialog',
            isSecondaryView: true,
            destructive: true,
        });
        this.hasJobs = !!Object.keys(customer.jobs).length;
    }

    public async init() {
        this.getAvailableOccurrences();

        if (!this.isEdit) await this.setInvoicedItems();

        Invoice.updateTotals(this.transaction || this.invoice);
    }

    private async setInvoicedItems() {
        if (this.occurrence) {
            const occurrence = this.occurrences.filter(o => this.occurrence && o._id === this.occurrence._id)[0];
            if (occurrence) {
                this.selectedOccurrences.push(occurrence);
                const items = InvoiceService.occurrenceToInvoiceItems(
                    occurrence,
                    !!occurrence.location && this.customer.address.addressDescription !== occurrence.location.addressDescription,
                    true
                );
                this.invoice.items = items;
            }
        } else if (this.occurrences.length) {
            const preselectAll = !this.addAllUninvoicedJobs || this.addAllUninvoicedJobs === 'all';
            const occurrencesToAutoAdd = this.occurrences.filter(o => o.price);
            if (preselectAll) Array.prototype.push.apply(this.selectedOccurrences, occurrencesToAutoAdd);

            this.invoice.items = [];

            const oneNote =
                !preselectAll || (this.occurrences.length > 1 && this.occurrences.every(o => o.jobId === this.occurrences[0].jobId));
            if (preselectAll) {
                for (const occurrence of occurrencesToAutoAdd) {
                    const items = InvoiceService.occurrenceToInvoiceItems(
                        occurrence,
                        !!occurrence.location && this.customer.address.addressDescription !== occurrence.location.addressDescription,
                        !oneNote
                    );
                    if (items.length) this.invoice.items = this.invoice.items.concat(items);
                }
            }

            if (oneNote) {
                const job = JobService.getJob(this.customer._id, this.occurrences[0].jobId);
                if (job && job.invoiceNotes && job.invoiceNotes.trim()) {
                    this.invoice.notes = `${job.invoiceNotes}\n\n${
                        !this.customer.hideDefaultInvoiceNotes
                            ? (ApplicationState.account.invoiceSettings.defaultNotes &&
                                  ApplicationState.account.invoiceSettings.defaultNotes + '\n\n') ||
                              ''
                            : ''
                    }${this.customer.invoiceNotes || ''}`;
                }
            } else {
                this.invoice.notes = `${
                    !this.customer.hideDefaultInvoiceNotes
                        ? (ApplicationState.account.invoiceSettings.defaultNotes &&
                              ApplicationState.account.invoiceSettings.defaultNotes + '\n\n') ||
                          ''
                        : ''
                }${this.customer.invoiceNotes || ''}`;
            }
        } else {
            this.invoice.items = this.invoice.items || [];
        }

        const jobSummaries = await JobService.getJobSummariesForCustomer(
            this.customer,
            new DateFilterOption('', moment().format('YYYY-MM-DD'), moment().add(1, 'y').format('YYYY-MM-DD')),
            100
        );

        for (let i = 0; i < jobSummaries.length; i++) {
            const job = jobSummaries[i];
            for (let i = 0; i < job.scheduleItemsArray.length; i++) {
                const scheduleItem = job.scheduleItemsArray[i];
                if (scheduleItem.status === JobOccurrenceStatus.NotDone) {
                    this.occurrences.push(scheduleItem.occurrence);
                }
            }
        }
    }

    private getAvailableOccurrences() {
        this.occurrences = JobOccurrenceService.getUninvoicedJobOccurrences(this.customer, false);
        this.occurrences = this.occurrences.sort((o1, o2) =>
            (o1.isoCompletedDate || o1.isoPlannedDate || o1.isoDueDate) > (o2.isoCompletedDate || o2.isoPlannedDate || o2.isoDueDate)
                ? 1
                : (o1.isoCompletedDate || o1.isoPlannedDate || o1.isoDueDate) < (o2.isoCompletedDate || o2.isoPlannedDate || o2.isoDueDate)
                ? -1
                : 0
        );
    }

    public async promptCancel() {
        if (
            !this.formIsDirty ||
            (await new Prompt('prompts.confirm-title', 'prompts.lose-changes-text', {
                okLabel: 'general.yes',
                cancelLabel: 'general.no',
            }).show())
        )
            this.cancel();
    }

    public async addJob() {
        const scheduleItems = this.occurrences.map(o => new ScheduleItem(this.customer.jobs[o.jobId], this.customer, o));

        const dialog = new SelectScheduleItemsDialog(
            this.selectedOccurrences.map(o => new ScheduleItem(this.customer.jobs[o.jobId], this.customer, o)),
            scheduleItems,
            s => (s.occurrence.invoiceTransactionId ? 'INVOICED' : true)
        );

        const result = await dialog.show(DialogAnimation.SLIDE_UP);

        if (!dialog.cancelled) {
            const newlySelectedScheduleItems = result.sort(sortByDateAsc);
            // Remove all current invoice line items that match a selected occurences from invoice
            this.invoice.items = this.invoice.items?.filter(item => !this.selectedOccurrences.some(o => o._id === item.refID));

            if (newlySelectedScheduleItems.length) {
                const oneNote =
                    newlySelectedScheduleItems.length === 1 ||
                    newlySelectedScheduleItems.every(o => o.job._id === newlySelectedScheduleItems[0].job._id);

                for (const scheduleItem of newlySelectedScheduleItems) {
                    const items = InvoiceService.occurrenceToInvoiceItems(
                        scheduleItem.occurrence,
                        !!scheduleItem.occurrence.location &&
                            this.customer.address.addressDescription !== scheduleItem.occurrence.location.addressDescription,
                        !oneNote
                    );
                    if (items.length) this.invoice.items = this.invoice.items?.concat(items);
                }

                if (oneNote && !this.invoice.notes) {
                    const job = JobService.getJob(this.customer._id, newlySelectedScheduleItems[0].job._id);
                    if (job && job.invoiceNotes && job.invoiceNotes.trim()) {
                        this.invoice.notes = `${job.invoiceNotes}\n\n${
                            !this.customer.hideDefaultInvoiceNotes
                                ? (ApplicationState.account.invoiceSettings.defaultNotes &&
                                      ApplicationState.account.invoiceSettings.defaultNotes + '\n\n') ||
                                  ''
                                : ''
                        }${this.customer.invoiceNotes || ''}`;
                    }
                } else if (!this.invoice.notes) {
                    const defaultGlobalNotes =
                        this.customer.hideDefaultInvoiceNotes !== true && ApplicationState.account.invoiceSettings.defaultNotes
                            ? (ApplicationState.account.invoiceSettings.defaultNotes &&
                                  ApplicationState.account.invoiceSettings.defaultNotes + '\n\n') ||
                              ''
                            : '';
                    this.invoice.notes = `${defaultGlobalNotes}${this.customer.invoiceNotes || ''}`;
                }
            }

            this.selectedOccurrences = newlySelectedScheduleItems.map(x => x.occurrence);
        }

        Invoice.updateTotals(this.transaction || this.invoice);
    }

    public async addLineItem() {
        const dialog = new InvoiceLineItemDialog();
        const result = await dialog.show(DialogAnimation.SLIDE);

        if (result) {
            if (!this.invoice.items) this.invoice.items = [];
            this.invoice.items.push(result);
        }
        Invoice.updateTotals(this.transaction || this.invoice);
    }

    public removeInvoiceItem(item: InvoiceItem) {
        this.invoice.items?.splice(this.invoice.items.indexOf(item), 1);
        this.selectedOccurrences.splice(
            this.selectedOccurrences.findIndex(o => o._id === item.refID),
            1
        );
        Invoice.updateTotals(this.transaction || this.invoice);
    }

    public createOrUpdate = async () => {
        if (!this.invoice.items?.length) return new NotifyUserMessage('notifications.invoice-items-required');
        await CustomerService.checkSaveNewEmailEmail(this.invoice.emailTo, this.customer);
        new LoaderEvent(true);
        let transaction = this.transaction;
        if (transaction) {
            Invoice.updateTotals(transaction);
            if (this.transaction?.invoice?.date) this.transaction.date = this.invoice.date;
            await Data.put(transaction);

            const occurrences = Data.all<JobOccurrence>('joboccurrences', { invoiceTransactionId: transaction._id });
            Logger.info('occurrences to check', occurrences);
            for (const occurrence of occurrences) {
                if (transaction.invoice?.items?.some(x => x.refID === occurrence._id)) continue;
                occurrence.invoiceTransactionId = undefined;
                occurrence.invoicedDate = undefined;
                occurrence.paymentStatus = undefined;
                await Data.put(occurrence);
            }
        } else {
            transaction = await InvoiceService.submitInvoice(
                this.invoice,
                this.customer,
                this.selectedOccurrences,
                undefined,
                undefined,
                undefined,
                this.showUserInvoiceSummary
            );
        }

        if (transaction) {
            this.ok(transaction);
        } else {
            new NotifyUserMessage('notifications.invoice-creation-error');
        }
        new LoaderEvent(false);
    };

    public async editInvoiceItem(invoiceItem: InvoiceItem) {
        //The way for us to tell if a job is linked to invoice item is it won't have a itemType
        const isLinkedToJob = !invoiceItem.itemType;

        const dialog = new InvoiceLineItemDialog(invoiceItem, isLinkedToJob);
        const updatedInvoiceItem = await dialog.show(DialogAnimation.SLIDE);
        //Infer that ok with no lineItem passed means remove it from invoice
        if (!dialog.cancelled && !updatedInvoiceItem) {
            if (isLinkedToJob) {
                //Only keep invoice items that don't match the job ref we are removing
                const count = this.invoice.items?.filter(item => item.refID === invoiceItem.refID).length || 0;

                if (
                    count > 1 &&
                    !(await new Prompt('general.confirm', 'prompts.invoice-confirm-remove-all-services-for-job', {
                        localisationParams: { count: count.toString() },
                        okLabel: 'general.remove',
                    }).show())
                ) {
                    return;
                }

                this.invoice.items = this.invoice.items?.filter(item => item.refID !== invoiceItem.refID);
                this.selectedOccurrences = this.selectedOccurrences.filter(item => item._id !== invoiceItem.refID);
            } else {
                this.removeInvoiceItem(invoiceItem);
            }
        } else if (!dialog.cancelled && updatedInvoiceItem && updatedInvoiceItem.refID) {
            const itemToUpdate = this.invoice.items?.find(item => item === invoiceItem);
            if (itemToUpdate) {
                Object.assign(itemToUpdate, updatedInvoiceItem);
            } else {
                Logger.error('Unexpected error occurred trying to edit invoice line item');
            }
        }
        Invoice.updateTotals(this.transaction || this.invoice);
    }
}
