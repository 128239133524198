import type { Location } from '@nexdynamic/squeegee-common';
import { bindable, bindingMode } from 'aurelia-framework';
import { NavigationDialog } from '../Dialogs/Navigation/NavigationDialog';

export class AddressLinkCustomElement {
    @bindable({ defaultBindingMode: bindingMode.oneWay })
    public location: Location;

    public navigate() {
        if (this.location) {
            const dialog = new NavigationDialog(this.location);
            dialog.showIfNeeded();
        }

        return false;
    }
}
