declare const isDevServer: boolean | undefined;
declare const isHttp: boolean | undefined;
declare const isTouchEnabled: boolean | undefined;
declare const isMobile: boolean | undefined;
declare const isMobileApp: boolean | undefined;
declare const isAppleMobileDevice: boolean | undefined;
declare const isAppleMobileApp: boolean | undefined;
declare const isAndroidMobileDevice: boolean | undefined;
declare const isSafari: boolean | undefined;
let forceWideScreen: boolean | undefined;

export class GlobalFlags {

    static get isDevServer(): boolean {
        return typeof isDevServer !== 'undefined' && isDevServer;
    }

    static get isHttp(): boolean {
        return typeof isHttp !== 'undefined' && isHttp;
    }

    static get isTouchEnabled(): boolean {
        return typeof isTouchEnabled !== 'undefined' && isTouchEnabled;
    }

    static get isMobile(): boolean {
        return typeof isMobile !== 'undefined' && isMobile;
    }

    static get isMobileApp(): boolean {
        return typeof isMobileApp !== 'undefined' && isMobileApp;
    }

    static get isAppleMobileDevice(): boolean {
        return typeof isAppleMobileDevice !== 'undefined' && isAppleMobileDevice;
    }

    static get isAppleMobileApp(): boolean {
        return typeof isAppleMobileApp !== 'undefined' && isAppleMobileApp;
    }

    static get isAndroidMobileDevice(): boolean {
        return typeof isAndroidMobileDevice !== 'undefined' && isAndroidMobileDevice;
    }

    static get forceWideScreen(): boolean {
        if (forceWideScreen === undefined) forceWideScreen = GlobalFlags.isMobile && window.innerWidth >= 840;
        return forceWideScreen
    }

    static get isSafari(): boolean {
        return typeof isSafari !== 'undefined' && isSafari;
    }
}

(window as any).GlobalFlags = GlobalFlags;
