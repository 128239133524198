import type { TranslationKey } from '@nexdynamic/squeegee-common';

export interface IOption<TEnumType = any, TPropertyKey = any, TSecondValueType = any> {
    [key: string]: any;
    name: TranslationKey;

    value: TEnumType;

    /**
     * Used when sorting to select the property to sort on
     * @type {TPropertyKey}
     */
    propertyKey?: TPropertyKey;

    /**
     * Is a reverse of another sort option
     * @type {boolean}
     */
    reverse?: boolean;

    secondValue?: TSecondValueType;

    quantity?: number;
}

export interface IFilterItem<TOptionType = any> {
    title?: TranslationKey;
    name?: TranslationKey;
    optionNameKey: string;
    optionValueKey: string;
    type: 'sortBy' | 'filter' | 'buttonGroup' | 'dateRange';
    options: Array<TOptionType>;
    selectedOption?: TOptionType;
    selectedOptions?: Array<TOptionType>;
    handler?: (option: TOptionType) => void;
}

export interface FilterItemDictionary {
    [key: string]: IFilterItem<any>;
}

export class Filter<TFilterItemDictionary extends FilterItemDictionary> {
    public filterCount = 0;
    constructor(public filterOptions: TFilterItemDictionary, public filterMethod?: () => void) {
        this.update();
    }

    public update() {
        this.filterMethod && this.filterMethod();
        this.filterCount = this.updateFilterCount();
    }

    private updateFilterCount() {
        let count = 0;
        for (const key in this.filterOptions) {
            const option = this.filterOptions[key];
            if (
                ((option.type === 'filter' || option.type === 'dateRange') && option.selectedOption) ||
                (option.selectedOptions && option.selectedOptions.length)
            )
                count++;
        }
        return count;
    }
}
