import type { ScheduleItem } from '../Schedule/Components/ScheduleItem';

export class ScheduleItemFilterValueConverter {
    toView(array: Array<ScheduleItem>, searchKeys?: Array<string>, searchValue?: string) {
        return array.filter(item => {
            let include = true;
            if (searchKeys && searchValue) include = this.matchesSearch(item, searchKeys, searchValue);
            return include;
        });
    }

    matchesSearch(item: ScheduleItem, searchKeys: Array<string>, searchValue: string): boolean {
        for (let i = 0; i < searchKeys.length; i++) {
            const property = searchKeys[i];
            const itemValue = (<any>item)[property];
            if (itemValue && typeof itemValue === 'string' && itemValue.toLowerCase().indexOf(searchValue.toLowerCase()) > -1) return true;
        }
        return false;
    }
}
