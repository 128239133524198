import type {
    Invoice,
    SkipCharge,
    SkipReason
} from '@nexdynamic/squeegee-common';
import { to2dp } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { t } from "../t";



export const updateInvoicePriceForSkippedReason = (invoice: Invoice, skipReason?: string) => {
    const specificSkipReasons = ApplicationState.getSetting<Array<SkipReason> | undefined>('global.skip-reasons-and-charges');
    const defaultSkipCharge = ApplicationState.getSetting<SkipCharge | undefined>('global.skip-charge-default');

    const skipCharge = skipReason && (specificSkipReasons?.find(x => x.name === skipReason)?.skipCharge) || defaultSkipCharge;
    if (!skipCharge)
        return;

    const items = invoice.items || (invoice.items = []);

    for (const item of items) {
        item.unitPrice = 0;
    }

    const price = invoice?.priceIncludesTax ? invoice.total : (invoice.subtotal || invoice.total);
    const fixed = skipCharge.type === 'fixed';
    const info = fixed ? `${ApplicationState.currencySymbol()}${price.toFixed(2)}` : `${skipCharge.amount.toFixed(0)}%`;
    items.push({
        quantity: 1,
        refID: 'skip-charge',
        description: t('settings.skip-job-charge-info-invoice', { info }),
        unitPrice: to2dp(fixed ? skipCharge.amount : price * (skipCharge.amount / 100)),
        resourceType: 'joboccurrences'
    });
};
