import { bindingMode, customAttribute, inject } from 'aurelia-framework';

/**
  * A custom attribute that tracks whether users have interacted with two-way bound elements.
    as found on auerlia issue tracker
  */
@customAttribute('dirty', bindingMode.twoWay)
@inject(Element)
export class DirtyCustomAttribute {
    view: any = null;
    bindings: any = null;
    element: any = null;

    constructor(element: HTMLElement) {
        this.element = element;
    }

    created(view: any) {
        this.view = view;
    }

    bind() {
        // find all two-way bindings to elements within the element that has the 'dirty' attribute.
        this.bindings = this.view.bindings.filter((b: any) => b.mode === bindingMode.twoWay && this.element.contains(b.target));
        // intercept each binding's updateSource method.
        let i = this.bindings.length;
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self: any = this;
        while (i--) {
            const binding = this.bindings[i];
            binding.dirtyTrackedUpdateSource = binding.updateSource;
            binding.updateSource = function (newValue: any) {
                this.dirtyTrackedUpdateSource(newValue);
                if (!self.value) {
                    self.value = true;
                }
            };
        }
    }

    unbind() {
        // disconnect the dirty tracking from each binding's updateSource method.
        let i = this.bindings.length;
        while (i--) {
            const binding = this.bindings[i];
            binding.updateSource = binding.dirtyTrackedUpdateSource;
            binding.dirtyTrackedUpdateSource = null;
        }
    }
}
