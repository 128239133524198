import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { CustomDialog } from './CustomDialog';

export class TextAreaDialog extends CustomDialog<string> {
    protected textarea: HTMLTextAreaElement | undefined;
    protected textValue: string;

    constructor(
        public dialogTitle: TranslationKey,
        public value: string,
        public subTitle?: string,
        public label?: string,
        private _validate?: (value: string) => true | TranslationKey
    ) {
        super('textAreaDialog', './TextAreaDialog.html', dialogTitle, { okLabel: '', cancelLabel: '', isSecondaryView: true });
    }

    public async init() {
        this.textValue = this.value;
        setTimeout(() => {
            if (this.textarea) this.textarea.focus();
        }, 220);
    }

    public async onValidate(): Promise<true | TranslationKey> {
        if (!this._validate) return true;
        const validationResult = this._validate(this.textValue);
        return validationResult;
    }
    public delegateOk = () => this.ok();
    public async getResult(): Promise<string> {
        return this.textValue;
    }
}
