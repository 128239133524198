import type { Tag, TranslationKey } from '@nexdynamic/squeegee-common';
import { CustomDialog } from './CustomDialog';
import type { DialogSettingsSecondaryView, DialogSettingsViewport } from './DialogSettings';

export class Select<
    TModel extends { [key: string]: any; description?: TranslationKey; text?: TranslationKey | string } | Tag
> extends CustomDialog<TModel> {
    public disableLocalisation = false;

    constructor(
        protected selectTitle: TranslationKey,
        protected options: Array<TModel>,
        protected textKey: keyof TModel | keyof Tag,
        protected uniqueValueKey: keyof TModel,
        //The value of the currently selected item used for a reference in the template to mark the currently selected option
        public selectedValue?: any,
        settings?: DialogSettingsSecondaryView | DialogSettingsViewport
    ) {
        super(
            'selectDialog',
            './Select.html',
            selectTitle || '',
            settings || { cssClass: 'select-dialog', okLabel: '', cancelLabel: '', isSecondaryView: true }
        );
    }

    private _timeout: any;
    protected select(value: any, event: Event) {
        if (event && event.srcElement && event.srcElement) {
            let element = <HTMLElement>event.srcElement;
            while (element.nodeName !== 'LI' && element.parentElement) element = element.parentElement;

            element && element.classList.add('fade-select');
        }

        this.selectedValue = value;
        this._timeout = setTimeout(() => {
            this.ok();
        }, 150);
    }

    public async getResult() {
        clearTimeout(this._timeout);
        if (this.selectedValue) {
            const results = this.options.filter(item => item[this.uniqueValueKey] === this.selectedValue);
            if (results.length) return results[0];
        } else {
            // TODO If no value don't just select first one, handle properly
            if (this.options.length) return this.options[0];
        }
    }
}
