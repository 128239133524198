export const sites = [
    {
        name: 'Cotswold Pure Water',
        address: 'Unit 7 Murcot Turn Business Park Broadway WR12 7LT',
        postcode: '',
        lat: 52.063,
        lon: -1.8934,
        furtherInfo: '',
    },
];
