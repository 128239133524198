import { JobOccurrenceStatus } from '@nexdynamic/squeegee-common';
import type * as Chartist from 'chartist';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import { LoaderEvent } from '../../Events/LoaderEvent';
import type { Subscription } from '../../Events/SqueegeeEventAggregator';
import { ViewResizeEvent } from '../../Events/ViewResizeEvent';
import { DateFilterOption } from '../../Jobs/Filters/DateFilterOption';
import type { ScheduleByGroup } from '../../Schedule/Components/ScheduleByGroup';
import type { ScheduleItem } from '../../Schedule/Components/ScheduleItem';
import { ScheduleService } from '../../Schedule/ScheduleService';

class MonthStats {
    name: string;
    due = { price: 0, count: 0 };
    done = { price: 0, count: 0 };
    overdue = { price: 0, count: 0 };
    skipped = { price: 0, count: 0 };
    totalSum = { price: 0, count: 0 };
    ratios: Array<number[]> = [];
}

export class Jobs {
    public loading = true;
    public monthsSchedule: ScheduleByGroup;
    public prevoiusMonthSchedule: ScheduleByGroup;
    public jobSeries: Array<Chartist.IChartistSeriesData>;
    public averageJobsComplete: { series: Array<number[]>; labels: Array<string> };
    public currentMonthStats: MonthStats;
    public prevoiusMonthStats: MonthStats;
    protected isOwnerorAdmin = ApplicationState.isInAnyRole(['Owner', 'Admin']);
    private _dataRefreshedSub: Subscription;

    private _now: moment.Moment;
    private _nowAsIso: string;

    public created() {
        new LoaderEvent(true);
        this._now = moment();
        this._nowAsIso = this._now.format('YYYY-MM-DD');
    }

    public async attached() {
        await this.load();
        setTimeout(() => new ViewResizeEvent(), 150);
        this._dataRefreshedSub = DataRefreshedEvent.subscribe(
            async (event: DataRefreshedEvent) => event.hasAnyType('customers', 'transactions', 'joboccurrences') && this.load()
        );

        new LoaderEvent(false);
        this.loading = false;
        setTimeout(async () => {
            new ViewResizeEvent();
        }, 150);
    }

    public detached() {
        this._dataRefreshedSub && this._dataRefreshedSub.dispose();
    }

    public async getSchedule(start: moment.Moment, end: moment.Moment) {
        return ScheduleService.getScheduledJobsByDay(
            undefined,
            new DateFilterOption('', start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')),
            [JobOccurrenceStatus.Done, JobOccurrenceStatus.NotDone, JobOccurrenceStatus.Skipped]
        );
    }

    private async load() {
        this.monthsSchedule = await this.getSchedule(moment().startOf('month'), moment().endOf('month'));
        this.prevoiusMonthSchedule = await this.getSchedule(
            moment().subtract(1, 'month').startOf('month'),
            moment().subtract(1, 'month').endOf('month')
        );
        this.bindTotals();
        this.bindChart();
    }

    private bindChart() {
        this.jobSeries = [
            {
                value: this.currentMonthStats.due.count,
                className: 'balanced',
            },
            {
                value: this.currentMonthStats.done.count,
                className: 'done',
            },
            {
                value: this.currentMonthStats.overdue.count,
                className: 'overdue',
            },
            {
                value: this.currentMonthStats.skipped.count,
                className: 'skipped',
            },
        ];
    }

    private calculateFutureJobs(stats: MonthStats, jobKeys: Array<string>, day: { [id: string]: ScheduleItem }) {
        for (let i = 0; i < jobKeys.length; i++) {
            const scheduleItem = day[jobKeys[i]];
            stats.totalSum.count++;
            //If todays jobs add them to the due stats
            switch (scheduleItem.occurrence.status) {
                case JobOccurrenceStatus.Done:
                    stats.done.count++;
                    stats.done.price += scheduleItem.occurrence.price || 0;
                    break;
                case JobOccurrenceStatus.Skipped:
                    stats.skipped.count++;
                    stats.skipped.price += scheduleItem.occurrence.price || 0;
                    break;
                case JobOccurrenceStatus.NotDone:
                    stats.due.count++;
                    stats.due.price += scheduleItem.occurrence.price || 0;
                    break;
            }
        }
    }

    private calculatePastJobs(stats: MonthStats, jobKeys: Array<string>, day: { [id: string]: ScheduleItem }, _totalOverdueForDay: number) {
        for (let i = 0; i < jobKeys.length; i++) {
            const scheduleItem = day[jobKeys[i]];
            stats.totalSum.count++;
            switch (scheduleItem.occurrence.status) {
                case JobOccurrenceStatus.Done:
                    stats.done.count++;
                    stats.done.price += scheduleItem.occurrence.price || 0;
                    break;
                case JobOccurrenceStatus.Skipped:
                    stats.skipped.count++;
                    stats.skipped.price += scheduleItem.occurrence.price || 0;
                    break;
                case JobOccurrenceStatus.NotDone:
                    _totalOverdueForDay++;
                    stats.overdue.count++;
                    stats.overdue.price += scheduleItem.occurrence.price || 0;
                    break;
            }
        }
    }

    private createRatios(dateString: string, stats: MonthStats, jobKeys: Array<string>, totalOverdueForDay: number) {
        const date = moment(dateString);
        const weekOfMonth = date.week() - date.startOf('month').week();
        if (stats.ratios[weekOfMonth] === undefined) stats.ratios[weekOfMonth] = [];
        if (totalOverdueForDay && jobKeys.length) {
            stats.ratios[weekOfMonth].push(((jobKeys.length - totalOverdueForDay) / jobKeys.length) * 100);
        } else stats.ratios[weekOfMonth].push(100);
    }

    private calculateTotal(monthGroup: ScheduleByGroup) {
        const stats = new MonthStats();
        const dayKeys = Object.keys(monthGroup);
        stats.name = moment(dayKeys[0], 'YYYY-MM-DD').format('MMMM');
        for (let i = 0; i < dayKeys.length; i++) {
            const dateString = dayKeys[i];
            const day = monthGroup[dayKeys[i]];
            const jobKeys = Object.keys(day);
            const totalOverdueForDay = 0;
            if (this._nowAsIso === dateString || moment(dateString, 'YYYY-MM-DD').isAfter(this._now))
                this.calculateFutureJobs(stats, jobKeys, day);
            else {
                this.calculatePastJobs(stats, jobKeys, day, totalOverdueForDay);
                this.createRatios(dateString, stats, jobKeys, totalOverdueForDay);
            }
        }
        return stats;
    }

    private averageRatioByWeek(ratios: Array<number[]>) {
        return ratios.map(week => {
            const sum = week.reduce((previous, current) => (current += previous));
            return sum / week.length;
        });
    }

    private bindTotals() {
        this.currentMonthStats = this.calculateTotal(this.monthsSchedule);
        this.prevoiusMonthStats = this.calculateTotal(this.prevoiusMonthSchedule);
        this.averageJobsComplete = { series: [], labels: [] };
        this.averageJobsComplete.series = [
            this.averageRatioByWeek(this.currentMonthStats.ratios),
            this.averageRatioByWeek(this.prevoiusMonthStats.ratios),
        ];
        this.averageJobsComplete.labels = this.averageJobsComplete.series[0].map((_value, index) =>
            ApplicationState.localise('insights.week-label', { week: (index + 1).toString() })
        );
    }
}
