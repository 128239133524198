import type { Transaction } from '@nexdynamic/squeegee-common';
import { JobOccurrenceStatus, to2dp } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import { Gauge } from '../../Components/Charts/Gauge';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import { LoaderEvent } from '../../Events/LoaderEvent';
import type { Subscription } from '../../Events/SqueegeeEventAggregator';
import { ViewResizeEvent } from '../../Events/ViewResizeEvent';
import { DateFilterOption } from '../../Jobs/Filters/DateFilterOption';
import type { ScheduleByGroup } from '../../Schedule/Components/ScheduleByGroup';
import { ScheduleService } from '../../Schedule/ScheduleService';
import { TransactionService } from '../../Transactions/TransactionService';
import type { ITransactionMonthSummary } from './ITransactionMonthSummary';

interface IMonth {
    month: string;
    paymentTotal?: number;
    invoiceTotal?: number;
    paymentDifference?: string;
    invoiceDifference?: string;
    projectedTotal?: number;
}

interface ICurrentMonthStats {
    monthName: string;
    revenueGauge: Gauge;
    revenuePrice: number;
    totalDonePrice: number;
    projected: number;
}

export class Financials {
    protected loading = true;
    protected financialsBarData: Chartist.IChartistData;
    protected isOwnerorAdmin = ApplicationState.isInAnyRole(['Owner', 'Admin']);
    protected monthlyStats: Array<IMonth>;
    protected monthlyPaymentsTotal = 0;
    protected monthlyInvoicesTotal = 0;

    protected monthsSchedule: ScheduleByGroup;
    protected currencySymbol = ApplicationState.currencySymbol();

    protected currentMonthStats: ICurrentMonthStats;

    private _dataRefreshedSub: Subscription;

    private _startDate: moment.Moment;

    private _payments: Array<Transaction> = [];
    private _invoices: Array<Transaction> = [];
    historicStats: IMonth[];

    public created() {
        new LoaderEvent(true);
    }

    public async attached() {
        await this.load();
        this._dataRefreshedSub = DataRefreshedEvent.subscribe(
            async (event: DataRefreshedEvent) => event.hasAnyType('customers', 'transactions') && this.load()
        );

        new LoaderEvent(false);
        this.loading = false;
        setTimeout(() => new ViewResizeEvent(), 150);
    }

    public detached() {
        this._dataRefreshedSub.dispose();
    }

    private async load() {
        this._payments = await this.getPayments();
        this._invoices = await this.getInvoices();
        await this.createFinancialData();

        this.bindChart();
    }

    private async getPayments(numberOfMonths?: number) {
        if (numberOfMonths === undefined) numberOfMonths = ApplicationState.getSetting('global.financial-months-to-show', 12);

        this._startDate = moment().subtract(numberOfMonths, 'months').startOf('month');
        const endDate = moment().subtract(1, 'month').endOf('month');
        return TransactionService.getPayments(this._startDate, endDate);
    }

    private async getInvoices(numberOfMonths?: number) {
        if (numberOfMonths === undefined) numberOfMonths = ApplicationState.getSetting('global.financial-months-to-show', 12);

        this._startDate = moment().subtract(numberOfMonths, 'months').startOf('month');
        const endDate = moment().subtract(1, 'month').endOf('month');
        return TransactionService.getInvoices(this._startDate, endDate);
    }

    private async createFinancialData() {
        const transactionsByMonth: { [monthKey: string]: ITransactionMonthSummary | undefined } = {};
        for (const payment of this._payments) {
            const date = moment((payment.invoice && payment.invoice.date) || payment.date || payment.createdDate);

            // Add to the month dictionary

            const monthStart = date.format('YYYY-MM-') + '01';

            let transactionsByMonthSummary = transactionsByMonth[monthStart];
            if (!transactionsByMonthSummary) {
                transactionsByMonthSummary = { payments: 0, invoices: 0, projected: 0, done: 0 };
                transactionsByMonth[monthStart] = transactionsByMonthSummary;
            }

            transactionsByMonthSummary.payments = to2dp(transactionsByMonthSummary.payments - payment.amount);
        }

        for (const invoice of this._invoices) {
            const date = moment((invoice.invoice && invoice.invoice.date) || invoice.date || invoice.createdDate);

            // Add to the month dictionary
            const monthStart = date.format('YYYY-MM-') + '01';
            let transactionsByMonthSummary = transactionsByMonth[monthStart];
            if (!transactionsByMonthSummary) {
                transactionsByMonthSummary = { payments: 0, invoices: 0, projected: 0, done: 0 };
                transactionsByMonth[monthStart] = transactionsByMonthSummary;
            }

            transactionsByMonthSummary.invoices += invoice.amount;
        }
        const groupedDates = Object.keys(transactionsByMonth).sort();

        const startDate = groupedDates[0];
        const endDate = moment().add(6, 'months').endOf('month').format('YYYY-MM-DD');

        this.monthsSchedule = await ScheduleService.getScheduledJobsByDay(moment(), new DateFilterOption('week', startDate, endDate), []);

        const dates = Object.keys(this.monthsSchedule);
        for (let i = 0; i < dates.length; i++) {
            const monthStart = dates[i].substring(0, 7) + '-01';
            const group = this.monthsSchedule[dates[i]];
            const keys = Object.keys(group);
            for (let i = 0; i < keys.length; i++) {
                let transactionsByMonthSummary = transactionsByMonth[monthStart];
                if (!transactionsByMonthSummary) {
                    transactionsByMonthSummary = { payments: 0, invoices: 0, projected: 0, done: 0 };
                    transactionsByMonth[monthStart] = transactionsByMonthSummary;
                }

                const scheduleItem = group[keys[i]];
                transactionsByMonthSummary.projected += scheduleItem.occurrence.price || 0;
                if (scheduleItem.status === JobOccurrenceStatus.Done) transactionsByMonthSummary.done += scheduleItem.occurrence.price || 0;
            }
        }

        const totalOwing = this.monthlyInvoicesTotal - this.monthlyPaymentsTotal;
        const currentMonthStart = moment().format('YYYY-MM') + '-01';
        const currentMonthData = transactionsByMonth[currentMonthStart];
        this.currentMonthStats = {
            monthName: moment().format('MMM YY'),
            revenueGauge: new Gauge(
                (currentMonthData && currentMonthData.done) || 0,
                (currentMonthData && currentMonthData.projected) || 1
            ),
            uncollectedGauge: new Gauge(this.monthlyInvoicesTotal - totalOwing, this.monthlyInvoicesTotal || 1),
            revenuePrice: (currentMonthData && currentMonthData.done) || 0,
            totalDonePrice: (currentMonthData && currentMonthData.done) || 0,
            projected: (currentMonthData && currentMonthData.projected) || 0,
        } as ICurrentMonthStats;

        let previousMonthPayments: number | undefined;
        let previousMonthInvoices: number | undefined;
        this.monthlyStats = [];
        this.monthlyPaymentsTotal = 0;
        this.monthlyInvoicesTotal = 0;

        const months = Object.keys(transactionsByMonth).sort();
        for (const monthString of months) {
            const transactionsByMonthSummary = transactionsByMonth[monthString] as ITransactionMonthSummary;
            this.monthlyPaymentsTotal += transactionsByMonthSummary.payments;
            this.monthlyInvoicesTotal += transactionsByMonthSummary.invoices;

            const month: IMonth = { month: moment(monthString).format('MMM YY') };

            if (monthString < currentMonthStart) {
                month.paymentTotal = transactionsByMonthSummary.payments;
                month.paymentDifference = this.differenceOverLastMonth(previousMonthPayments, transactionsByMonthSummary.payments);
                month.invoiceTotal = transactionsByMonthSummary.invoices;
                month.invoiceDifference = this.differenceOverLastMonth(previousMonthInvoices, transactionsByMonthSummary.invoices);
                month.projectedTotal = transactionsByMonthSummary.payments;
            } else {
                month.projectedTotal = transactionsByMonthSummary.projected;
            }

            previousMonthPayments = month.paymentTotal;
            previousMonthInvoices = month.invoiceTotal;

            this.monthlyStats.push(month);
        }
        this.historicStats = this.monthlyStats.filter(x => x.invoiceTotal !== undefined);
    }

    private differenceOverLastMonth(previousMonth?: number, current = 0) {
        if (previousMonth === undefined) return '';
        if (!previousMonth && !current) return '';
        if (!previousMonth && current > 0) return '<i class="material-icons good">trending_up</i>';

        const difference = ((current - previousMonth) / previousMonth) * 100;
        return difference > 0
            ? `<i class="material-icons good">trending_up</i> <small class="good" style="font-size:10px">${Math.abs(difference).toFixed(
                  0
              )}%</small>`
            : `<i class="material-icons bad">trending_down</i> <small class="bad" style="font-size:10px">${Math.abs(difference).toFixed(
                  0
              )}%</small>`;
    }

    private bindChart() {
        this.financialsBarData = {
            series: [
                this.monthlyStats.filter(x => x.projectedTotal !== undefined).map(x => x.projectedTotal as number),
                this.monthlyStats.filter(x => x.invoiceTotal !== undefined).map(x => x.invoiceTotal as number),
                this.monthlyStats.filter(x => x.paymentTotal !== undefined).map(x => x.paymentTotal as number),
            ],

            labels: this.monthlyStats.map(x => x.month),
        };
    }
}
