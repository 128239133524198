import type { PaymentAccount } from '@nexdynamic/squeegee-common';
import { Expense, ExpenseCategory } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import { Logger } from '../../Logger';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { Utilities } from '../../Utilities';
import { ExpenseService } from '../ExpenseService';

export class ExpenseFormDialog extends CustomDialog<void> {
    protected menuTitle = ApplicationState.localise('expenses.new-expense');
    protected expense: Expense;
    constructor(expense?: Expense, protected paymentAccount?: PaymentAccount, protected account?: PaymentAccount) {
        super('ExpenseFormDialog', '../Expenses/Components/ExpenseFormDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            isSecondaryView: true,
            destructive: true,
        });

        if (expense) {
            if (expense.amount >= 0) {
                this.menuTitle = ApplicationState.localise('expenses.edit-expense');
            } else {
                this.menuTitle = ApplicationState.localise('expenses.expense-refund');
            }
            //Create new object
            this.expense = Utilities.copyObject(expense);
        } else {
            this.expense = new Expense('expense.standard', 0, '', new ExpenseCategory(''));
        }
        if (this.paymentAccount) this.expense.paymentAccountId = this.paymentAccount._id;
        if (this.paymentAccount && !expense?.paymentDate) this.expense.paymentDate = moment().format('YYYY-MM-DD');
        if (this.account) this.expense.accountId = this.account._id;
    }

    protected save = async () => {
        try {
            if (this.expense) {
                await ExpenseService.addExpense(this.expense);
                await this.ok();
            }
        } catch (error) {
            new NotifyUserMessage('notifications.error-during-save');
            return Logger.error(`Error during save in new expense dialog`, { expense: this.expense, error });
        }
    };

    protected hasUltimateOrAbove = ApplicationState.hasUltimateOrAbove;
}
