import type { Customer, ICustomerBalance, TMatcher, TMatchType, Transaction, TransactionProvider } from '@nexdynamic/squeegee-common';
import { MatchingUtils } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { MatcherService } from '../../ConnectedServices/MatcherService';
import { Data } from '../../Data/Data';
import { TransactionService } from '../../Transactions/TransactionService';
import { Utilities } from '../../Utilities';

export class MatchPayment {
    constructor(public providerPayment: Transaction, public provider: TransactionProvider, public dontAutoMatch = false) {
        this.availableTypes = this._getAvailableTypes();
    }

    possiblePaymentMatches: Array<IPossiblePaymentOrExpenseMatch> = [];
    possibleCustomerMatches: Array<IPossibleCustomerMatch> = [];
    selectedCustomerSuggestion?: IPossibleCustomerMatch;
    possibleInvoiceMatches: Array<IPossibleInvoiceMatch> = [];
    status: MatchStatus = 'unmatched';
    match?: Transaction;
    customer?: Customer;
    invoice?: Transaction;
    loaded = false;
    type?: TMatchType;
    availableTypes: Array<TMatchType>;
    matchPercent = 0;
    paymentAccountId?: string;
    expanded = false;
    createNew: boolean;
    allocateInvoice: 'manual' | 'auto' | 'tip' = 'auto';
    private _getAvailableTypes(): Array<TMatchType> {
        const types: Array<TMatchType> = [];
        if (this.providerPayment.amount < 0) {
            types.push('Customer Payment');
            types.push('Transfer In From');
            types.push('Expense Refund');
        } else {
            types.push('Expense');
            types.push('Transfer Out To');
            types.push('Customer Refund');
        }
        types.push('Ignore');
        return types;
    }

    private findPossibleCustomerMatches(customers: Readonly<Array<Customer>>): Array<IPossibleCustomerMatch> {
        const possibleCustomerMatches: Array<IPossibleCustomerMatch> = [];
        if (!this.providerPayment.description) return [];

        if (this.providerPayment.customerId) {
            const customer = customers.find(x => x._id === this.providerPayment.customerId);
            if (customer) {
                possibleCustomerMatches.push({
                    customer,
                    matchPercent: 1,
                    matchesOn: ['customerId'],
                    balance: TransactionService.getCustomerBalance(customer._id, moment().format('YYYY-MM-DD')),
                });
                return possibleCustomerMatches;
            }
        }

        const termsForCommonalitiesCheck = [];

        termsForCommonalitiesCheck.push(...customers.filter(x => x.name).map(x => x.name || ''));
        termsForCommonalitiesCheck.push(
            ...customers
                .filter(x => x.address)
                .map(x => {
                    if (!x.address?.addressDescription) return '';
                    return x.address?.addressDescription.replace(/ /g, '');
                })
        );
        termsForCommonalitiesCheck.push(...customers.filter(x => x.email).map(x => x.email || ''));

        MatchingUtils.setCommonalities(termsForCommonalitiesCheck);
        const today = moment().format('YYYY-MM-DD');
        for (let i = 0; i < customers.length; i++) {
            const cust = customers[i];
            // Can we match on customer ref from tran description?
            const name = Utilities.stripTitlesAndTrimName(cust.name);
            const refSimiliarty = cust._externalId ? MatchingUtils.termCompare(cust._externalId, this.providerPayment.description) : 0;
            const nameSimilarity = name
                ? MatchingUtils.termCompare(name, Utilities.stripTitlesAndTrimName(this.providerPayment.description))
                : 0;
            const addressSimilarity = cust.address.addressDescription
                ? MatchingUtils.termCompare(cust.address.addressDescription, this.providerPayment.description)
                : 0;
            const emailSimilarity = cust.email ? MatchingUtils.termCompare(cust.email, this.providerPayment.description) : 0;

            const matchesOn: Array<'name' | 'ref' | 'address' | 'email'> = [];
            if (refSimiliarty > 0.4) matchesOn.push('ref');
            if (nameSimilarity > 0.4) matchesOn.push('name');
            if (addressSimilarity > 0.4) matchesOn.push('address');
            if (emailSimilarity > 0.4) matchesOn.push('email');

            let matchPercent = [nameSimilarity, refSimiliarty, addressSimilarity, emailSimilarity].sort()[3];

            if (cust.state === 'inactive') matchPercent = matchPercent - 0.01;

            if (matchPercent > 0.5) {
                possibleCustomerMatches.push({
                    customer: cust,
                    matchPercent,
                    matchesOn,
                    balance: TransactionService.getCustomerBalance(cust._id, today),
                });
            }
        }

        return possibleCustomerMatches;
    }

    private findPossibleInvoiceMatches(
        invoices: Readonly<Array<Transaction>>,
        possibleCustomerMatches: Array<IPossibleCustomerMatch>
    ): Array<IPossibleInvoiceMatch> {
        const possibleInvoiceMatches: Array<IPossibleInvoiceMatch> = [];
        const today = moment().format('YYYY-MM-DD');
        for (let i = 0; i < invoices.length; i++) {
            const inv = invoices[i];

            if (Math.abs(inv.amount) !== Math.abs(this.providerPayment.amount)) continue;

            let matchPercent = 0;

            const matchesOn: Array<'name' | 'email' | 'address' | 'reference' | 'date' | 'amount'> = [];

            if (inv.customerId && inv.customerId === this.customer?._id) {
                matchPercent = 1;
            } else {
                const dateDiffInDays = Math.abs(moment(inv.date).diff(this.providerPayment.date, 'd')) + 1;

                const dateSimilarity = 1 / dateDiffInDays;
                if (dateSimilarity > 0.8) matchesOn.push('date');
                let descSimilarity = 1;

                if (this.providerPayment.description) {
                    // Also compare to the possible customer details

                    const refSimiliarty = inv.invoice?.invoiceNumber
                        ? MatchingUtils.termCompare(this.providerPayment.description, inv.invoice?.invoiceNumber.toString())
                        : 0;
                    if (refSimiliarty > 0.4) matchesOn.push('reference');
                    const addressSimilarity = inv.invoice?.billTo
                        ? MatchingUtils.termCompare(inv.invoice?.billTo, this.providerPayment.description)
                        : 0;
                    if (addressSimilarity > 0.4) matchesOn.push('address');
                    const emailSimilarity = inv.invoice?.emailTo
                        ? MatchingUtils.termCompare(inv.invoice?.emailTo, this.providerPayment.description)
                        : 0;
                    if (emailSimilarity > 0.4) matchesOn.push('email');
                    descSimilarity = [refSimiliarty, addressSimilarity, emailSimilarity].sort().reverse()[0];
                }
                matchPercent = (1 + dateSimilarity + descSimilarity) / 3;
            }
            if (matchPercent > 0.4) {
                if (!inv.customerId) continue;
                possibleInvoiceMatches.push({
                    invoice: inv,
                    matchPercent: matchPercent,
                    matchesOn: matchesOn,
                });
                const existingCustMatch = possibleCustomerMatches.find(c => c.customer?._id === inv.customerId);

                if (!existingCustMatch) {
                    const customer = Data.get<Customer>(inv.customerId);
                    if (!customer) continue;
                    possibleCustomerMatches.push({
                        customer,
                        matchPercent,
                        matchesOn: ['invoice'],
                        balance: customer && TransactionService.getCustomerBalance(customer._id, today),
                    });
                } else {
                    const existingMatchesOnInvoice = existingCustMatch.matchesOn.find(x => x === 'invoice');
                    if (!existingMatchesOnInvoice) {
                        existingCustMatch.matchesOn.push('invoice');
                    }
                }
            }
        }

        return possibleInvoiceMatches;
    }

    private findPaymentSuggestions(
        transactions: Readonly<Array<Transaction>>,
        possibleCustomerMatches: Array<IPossibleCustomerMatch>
    ): Array<IPossiblePaymentOrExpenseMatch> {
        const possiblePaymentMatch: Array<IPossiblePaymentOrExpenseMatch> = [];
        const today = moment().format('YYYY-MM-DD');
        for (let i = 0; i < transactions.length; i++) {
            const tran = transactions[i];
            if (!tran.amount) continue;
            if (!tran) continue;
            if (this.providerPayment.amount < 0 && tran.amount > 0) continue;
            if (this.providerPayment.amount > 0 && tran.amount < 0) continue;
            if (this.providerPayment.amount && tran.amount && Math.abs(this.providerPayment.amount) !== Math.abs(tran.amount)) continue;

            const possibleMatch: IPossiblePaymentOrExpenseMatch = {
                matchesOn: ['amount'],
                transaction: tran,
                matchPercent: 0,
            };

            const dateDiffInDays = Math.abs(moment(tran.date).diff(this.providerPayment.date, 'd')) + 1;

            let dateSimilarity = 1 / dateDiffInDays;

            if (dateDiffInDays <= 1) dateSimilarity = 1;
            else if (dateDiffInDays < 3) dateSimilarity = 0.8;
            else if (dateDiffInDays < 7) dateSimilarity = 0.5;
            else if (dateDiffInDays < 14) dateSimilarity = 0.2;
            else continue; // Dates are more than 14 these cant be a match

            if (dateSimilarity > 0.8) possibleMatch.matchesOn.push('date');

            let descSimilarity = 1;

            if (this.providerPayment.description && tran.description) {
                descSimilarity = MatchingUtils.termCompare(tran.description, this.providerPayment.description);
            }

            const percent = (1 + dateSimilarity + descSimilarity) / 3;
            if (percent < 0.5) continue;

            possibleMatch.matchPercent = percent > 1 ? 1 : percent;

            possiblePaymentMatch.push(possibleMatch);

            if (!possibleMatch.transaction?.customerId) continue;
            const existingCustMatch = this.possibleCustomerMatches.find(c => c.customer?._id === possibleMatch.transaction?.customerId);

            if (!existingCustMatch) {
                const customer = Data.get<Customer>(possibleMatch.transaction.customerId);
                if (!customer) continue;
                possibleCustomerMatches.push({
                    customer,
                    matchPercent: possibleMatch.matchPercent,
                    matchesOn: ['payment'],
                    balance: customer && TransactionService.getCustomerBalance(customer._id, today),
                });
            } else {
                existingCustMatch.matchesOn.push('payment');
            }
        }

        // if we got a 100 then only show 100 percent matches (usually 1);
        // if (maxPercentFound === 1) this.possiblePaymentMatches = this.possiblePaymentMatches.filter(x => x.matchPercent === 1);
        this.createNew = !possiblePaymentMatch.length;

        possiblePaymentMatch.sort((a, b) => b.matchPercent - a.matchPercent);

        if (possiblePaymentMatch.length > 5) possiblePaymentMatch.splice(0, 5);

        return possiblePaymentMatch;
    }

    public refreshMatchSuggestions(
        customers: Readonly<Array<Customer>>,
        invoices: Readonly<Array<Transaction>>,
        payments: Readonly<Array<Transaction>>
    ) {
        const possibleCustomerMatches = [];

        try {
            if (this.match) return;

            const customerMatches = this.findPossibleCustomerMatches(customers);
            possibleCustomerMatches.push(...customerMatches);
            const fPayments = this.customer ? payments.filter(x => x.customerId === this.customer?._id) : payments;
            const possiblePayments = this.findPaymentSuggestions(fPayments, possibleCustomerMatches);

            this.possiblePaymentMatches = possiblePayments;
            if (this.providerPayment.amount < 0) {
                if (!this.invoice) {
                    const fInvoices = this.customer ? invoices.filter(x => x.customerId === this.customer?._id) : invoices;
                    this.findPossibleInvoiceMatches(fInvoices, possibleCustomerMatches);
                }
            }
            possibleCustomerMatches.sort((a, b) => b.matchPercent - a.matchPercent);

            this.possibleCustomerMatches = possibleCustomerMatches.slice(0, 8);
            this.customerSelected = possibleCustomerMatches.find(x => x.customer?._id === this.customer?._id);
        } finally {
            this.loaded = true;
        }
    }

    matchers: Array<TMatcher> = [];

    public refreshMatchers() {
        this.matchers = MatcherService.findMatchers(this);
    }

    protected customerSelected?: IPossibleCustomerMatch;
    filter() {
        this.possiblePaymentMatches.filter(x => x.transaction?.customerId === this.customer?._id);
        this.possibleInvoiceMatches.filter(x => x.invoice?.customerId === this.customer?._id);
    }

    public isSimiliar(match: MatchPayment) {
        if (match === this) return false;
        if (
            (match.providerPayment.amount < 0 && this.providerPayment.amount > 0) ||
            (match.providerPayment.amount > 0 && this.providerPayment.amount < 0)
        )
            return false;

        if (!match.providerPayment.description) return false;
        if (!this.providerPayment.description) return false;
        return this.similarityTo(match.providerPayment.description) > 0.6;
    }

    public similarityTo(description: string) {
        if (!this.providerPayment.description) return 0;
        const similarity = MatchingUtils.termCompare(this.providerPayment.description, description);
        return similarity;
    }

    public reset() {
        this.possiblePaymentMatches = [];
        this.possibleCustomerMatches = [];
        delete this.selectedCustomerSuggestion;
        this.possibleInvoiceMatches = [];
        this.status = 'unmatched';
        this.match = undefined;

        delete this.customer;
        delete this.invoice;
        this.loaded = false;
        delete this.type;
        this.availableTypes = this._getAvailableTypes();

        delete this.paymentAccountId;
        this.expanded = false;
        this.createNew = false;
        this.allocateInvoice = 'auto';
    }
}

export type MatchStatus = 'matched' | 'unmatched' | 'ignored';

export interface IPossiblePaymentOrExpenseMatch {
    matchesOn: Array<'amount' | 'date' | 'other'>;
    transaction?: Transaction;
    matchPercent: number;
}

export interface IPossibleCustomerMatch {
    matchesOn: Array<string>;
    customer?: Customer;
    matchPercent: number;

    balance?: ICustomerBalance;
}

export interface IPossibleInvoiceMatch {
    matchesOn: Array<'name' | 'email' | 'address' | 'reference' | 'date' | 'amount'>;
    invoice?: Transaction;
    customer?: Customer;
    matchPercent: number;
}
