import type { BindingEngine, Disposable } from 'aurelia-framework';
import { observable } from 'aurelia-framework';

export class SearchableList<ListItemType> {
    @observable protected query: string;
    queryChanged() {
        this.filter();
    }
    private filteredItems: Array<ListItemType>;
    private subscription: Disposable;

    public isEmpty: boolean;
    public noResults: boolean;

    constructor(
        private bindingEngine: BindingEngine,
        private items: Array<ListItemType>,
        public searchableFields: Array<keyof ListItemType>
    ) {
        this.subscription = this.bindingEngine.collectionObserver(this.items).subscribe(this.listChanged.bind(this));
        this.filter();
    }

    listChanged() {
        this.filteredItems.splice(0);
        this.filter();
    }

    filter() {
        if (!this.filteredItems) this.filteredItems = [];
        this.filteredItems.splice(0, this.filteredItems.length);

        if (!this.query) this.filteredItems = this.items.slice();
        else {
            for (const item of this.items) {
                const matches = this.matches(item as Record<string, any>);
                if (matches) this.filteredItems.push(item);
            }
        }

        this.isEmpty = this.items.length === 0;
        this.noResults = this.filteredItems.length === 0;
    }

    private matches(item: Record<string, any>): boolean {
        let matches = false;
        for (const key of this.searchableFields) {
            matches = this.fieldMatches(item[key as string]);
            if (matches) break;
        }
        return matches;
    }

    private fieldMatches(value: any): boolean {
        if (typeof value === 'string') return value.toLowerCase().indexOf(this.query.toLowerCase()) > -1;
        if (typeof value === 'number') return value?.toString() === this.query;
        return false;
    }

    dispose() {
        this.subscription && this.subscription.dispose();
    }
}
