import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { GlobalFlags } from '../GlobalFlags';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { CustomDialog } from './CustomDialog';

export class OpenInCalendarDialog extends CustomDialog<string> {
    constructor(public dialogTitle: TranslationKey, public inputHeading: TranslationKey, public value: string) {
        super('openInCalendarDialog', './OpenInCalendarDialog.html', ApplicationState.localise(dialogTitle), {
            cssClass: 'open-calendar-dialog',
            isSecondaryView: true,
            okLabel: '',
            cancelLabel: '',
        });
    }

    public copyLink() {
        const input = <HTMLTextAreaElement>document.getElementById('inviteLink');
        if (input) {
            if (GlobalFlags.isAppleMobileDevice) {
                const editable = input.contentEditable;
                const readOnly = input.readOnly;

                input.contentEditable = 'true';
                input.readOnly = false;

                const range = document.createRange();
                range.selectNodeContents(input);

                const selection = window.getSelection();
                if (selection) {
                    selection.removeAllRanges();
                    selection.addRange(range);
                }

                input.setSelectionRange(0, 999999);
                input.contentEditable = editable;
                input.readOnly = readOnly;
            } else {
                input.select();
            }

            try {
                const successful = document.execCommand('copy');
                if (successful) new NotifyUserMessage('notifications.link-copied');
            } catch (error) {
                Logger.error(`Error during copy link`, error);
            }
        }
    }
}
