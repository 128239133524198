import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { PLATFORM } from 'aurelia-framework';
import type { IRoute } from '../IRoute';

export const routes: Array<IRoute> = [
    {
        route: '',
        name: '',
        title: '',
        moduleId: PLATFORM.moduleName('Views/DefaultChildView'),
        nav: false,
        redirect: 'default',
        settings: {
            roles: ['Owner', 'Admin'],
        },
    },
    {
        route: 'default',
        name: 'chart-of-accounts-default',
        moduleId: PLATFORM.moduleName('ChartOfAccounts/Views/chart-of-accounts'),
        nav: false,
        title: 'route-config.chart-of-accounts',
        settings: {
            roles: ['Owner', 'Admin'],
        },
    },
    {
        route: 'profit-loss',
        name: 'profit-loss',
        moduleId: PLATFORM.moduleName('ChartOfAccounts/Views/profit-loss'),
        nav: false,
        title: 'route-config.profit-loss' as TranslationKey,
        settings: {
            roles: ['Owner', 'Admin'],
        },
    },
    {
        route: 'balance-sheet',
        name: 'balance-sheet',
        moduleId: PLATFORM.moduleName('ChartOfAccounts/Views/balance-sheet'),
        nav: false,
        title: 'route-config.balance-sheet' as TranslationKey,
        settings: {
            roles: ['Owner', 'Admin'],
        },
    },
    {
        route: 'vat-return',
        name: 'vat-return',
        moduleId: PLATFORM.moduleName('ChartOfAccounts/Views/vat-return'),
        nav: false,
        title: 'vat return' as TranslationKey,
        settings: {
            roles: ['Owner', 'Admin'],
        },
    },
];
