import { inject } from 'aurelia-framework';

@inject(Element)
export class VisibilityCustomAttribute {
    constructor(private element: HTMLElement) {}

    valueChanged(newValue: any) {
        this.element.style.visibility = newValue ? 'visible' : 'hidden';
        this.element.style.opacity = newValue ? '1' : '0';
    }
}
