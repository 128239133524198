import type { Customer, TranslationKey } from '@nexdynamic/squeegee-common';
import { inject } from 'aurelia-framework';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { Prompt } from '../../Dialogs/Prompt';
import { CustomerService } from '../CustomerService';
import { SelectCustomerDialog } from './SelectCustomerDialog';

@inject(Element)
export class MergeCustomersDialog extends CustomDialog<Customer> {
    private static _cssClasses = 'details-dialog has-header-content';
    protected menuTitle: TranslationKey;
    protected menuTitleParams: { [key: string]: string };
    protected failedValidation = false;
    protected mergeNotifications = true;
    protected recordOld = true;
    protected mergeNotes = true;

    public contextMenuBarText: TranslationKey = 'general.merge';

    constructor(protected mainCustomer: Customer, protected mergingCustomer: Customer) {
        super('customer-merge-' + mainCustomer._id, '../Customers/Components/MergeCustomersDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: MergeCustomersDialog._cssClasses,
            isSecondaryView: true,
            destructive: true,
        });

        if (mainCustomer.name) {
            this.menuTitle = 'customers.merge-customers-title';
            this.menuTitleParams = { name: mainCustomer.name };
        } else {
            this.menuTitle = 'customers.merge-customers-title';
        }
    }

    public delegateMerge = async () => {
        const mergedCustomer = await CustomerService.mergeCustomers(
            this.mainCustomer,
            this.mergingCustomer,
            this.mergeNotes,
            this.recordOld
        );
        if (mergedCustomer) {
            this.ok(mergedCustomer);
        } else {
            await new Prompt('Customer merge failed!' as TranslationKey, 'There was an error merging customers.' as TranslationKey).show();
        }
    };

    public setMergeNotifications(value: boolean) {
        this.mergeNotifications = value;
    }
    public setMergeNotes(value: boolean) {
        this.mergeNotes = value;
    }
    public setRecordOld(value: boolean) {
        this.recordOld = value;
    }

    public async selectMainCustomer() {
        const dialog = new SelectCustomerDialog(undefined, 'dialogs.select-main-merge-customer-title');
        const customer = await dialog.show(DialogAnimation.SLIDE_UP);
        if (!dialog.cancelled && customer) this.mainCustomer = customer;
    }

    public async selectMergingCustomer() {
        const dialog = new SelectCustomerDialog(undefined, 'dialogs.select-merge-customer-title', [this.mainCustomer]);
        const customer = await dialog.show(DialogAnimation.SLIDE_UP);
        if (!dialog.cancelled && customer) this.mergingCustomer = customer;
    }
}
