import { PLATFORM } from 'aurelia-framework';
import type { IRoute } from '../IRoute';

export const routes: Array<IRoute> = [
    {
        route: '',
        name: '',
        title: '',
        moduleId: PLATFORM.moduleName('Views/DefaultChildView'),
        nav: false,
        redirect: 'not-done',
        settings: {
            roles: ['Owner', 'Worker'],
        },
    },
    {
        route: 'not-done',
        name: 'not-done',
        title: 'route-config.day-pilot-not-done-title',
        moduleId: PLATFORM.moduleName('DayPilot/Views/NotDone'),
        nav: false,
        settings: {
            roles: ['Owner', 'Worker'],
        },
    },
    {
        route: 'completed',
        name: 'completed',
        title: 'route-config.day-pilot-completed-title',
        moduleId: PLATFORM.moduleName('DayPilot/Views/Completed'),
        nav: false,
        settings: {
            roles: ['Owner', 'Worker'],
        },
    },
];
