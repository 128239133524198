import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { LoaderEvent } from '../Events/LoaderEvent';
import type { Subscription } from '../Events/SqueegeeEventAggregator';

export class LoaderCustomElement {
    private loaderEventSubscription: Subscription;
    protected loader?: LoaderEvent;
    private loaderChange: any;
    protected message?: TranslationKey;
    protected localisationParams?: Record<string, string>;

    public attached() {
        this.loaderEventSubscription = LoaderEvent.subscribe((loaderEvent: LoaderEvent) => {
            clearTimeout(this.loaderChange);
            this.loaderChange = setTimeout(
                () => {
                    this.setViewState(loaderEvent);
                },
                loaderEvent.showImmediately ? 0 : 100
            );
        });
    }

    public detached() {
        this.loaderEventSubscription && this.loaderEventSubscription.dispose();
    }

    private setViewState(loaderEvent: LoaderEvent) {
        const isBlocking = this.loader && !this.loader.nonBlocking;
        this.loader = loaderEvent.show ? loaderEvent : undefined;
        if (this.loader && isBlocking && this.loader.nonBlocking) this.loader.nonBlocking = false;
        this.localisationParams = loaderEvent.show ? this.loader?.localisationParams : undefined;
        this.message = loaderEvent.show ? this.loader?.message : undefined;
    }
}
