//import { wait } from '@nexdynamic/squeegee-common';
import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { randomInteger, wait } from '@nexdynamic/squeegee-common';
import { amusingConsoleMessages } from './amusingMessages';
import { ApplicationState } from './ApplicationState';
import { CustomUrlSchemeHandler } from './CustomUrlSchemeHandler';
import { SqueegeeLocalStorage } from './Data/SqueegeeLocalStorage';
import { AureliaReactComponentDialog } from './Dialogs/AureliaReactComponentDialog';
import { GlobalFlags } from './GlobalFlags';
import { Logger } from './Logger';
import { ActionableEvent } from './Notifications/ActionableEvent';
import { NotifyUserMessage } from './Notifications/NotifyUserMessage';
import { OneSignal } from './OneSignal';
import type { CannedResponse } from './ReactUI/canned-responses/CannedResponse';
import CannedResponsesDialog from './ReactUI/canned-responses/CannedResponsesDialog';
import { animate } from './Utilities';

export class Initialisation {
    public static _ready: Promise<any>;

    public static async run() {
        await Initialisation.ready;
        await SqueegeeLocalStorage.initialise();
        ApplicationState.preInit();
        Initialisation.initialiseErrorHandler();
        Initialisation.configureAndroidNavigation();
        CustomUrlSchemeHandler.initialise();
        Initialisation.initialiseHttpOneSignal();
        Initialisation.initialiseMobileOneSignal();
        Initialisation.listenForDevTools();
        Initialisation.listenForCannedResponse();
        await animate();
    }

    private static async listenForCannedResponse() {
        document.addEventListener('keydown', (event: KeyboardEvent) => {
            if (event.ctrlKey && event.shiftKey && event.code === 'Slash') {
                event.preventDefault();
                if (!ApplicationState.hasUltimateOrAbove) return;

                AureliaReactComponentDialog.show({
                    component: CannedResponsesDialog,
                    componentProps: {
                        cannedResponses: ApplicationState.getSetting<Array<CannedResponse>>(
                            'global.canned-responses',
                            new Array<CannedResponse>()
                        ),
                    },
                    dialogTitle: 'settings.canned-responses-title',
                    isSecondaryView: true,
                });
            }
        });
    }

    private static async listenForDevTools() {
        if (!GlobalFlags.isHttp) return;

        (window as any).stoppit = () => {
            clearTimeout((window as any).__quiet);
            !location.hostname?.includes('localhost') && console.log('%cStop!', 'color: red; font-size: 24px; font-weight: bold;');
            location.hostname?.includes('localhost') &&
                console.log(
                    `%c${amusingConsoleMessages[randomInteger(0, amusingConsoleMessages.length - 1)]}`,
                    'color: #b15500; font-size: 18px; font-weight: bold; font-family: Arial;'
                );
            !location.hostname?.includes('localhost') &&
                console.log(
                    '%cThis browser feature is intended for developers, if someone told you to copy-paste something here, it may be a scam as it gives them access to your account.',
                    'font-size: 16px; font-family: Arial; font-weight: bold; color: #b15500;'
                );
            (window as any).__quiet = setTimeout(() => (window as any).stoppit(), 60000);
        };

        (window as any).stoppit();

        ['shh', 'shhh', 'shhhh', 'shhhhh', 'shhhhhh', 'shhhhhhh'].forEach(shh => {
            Object.defineProperty(window, shh, {
                get: function () {
                    clearInterval((window as any).__quiet);
                    return false;
                },
                set: function (value: boolean) {
                    if (!value) (window as any).stoppit();
                    else clearInterval((window as any).__quiet);
                    return value;
                },
            });
        });
    }

    private static async initialiseHttpOneSignal(): Promise<void> {
        try {
            if (!GlobalFlags.isHttp || document.location.hostname !== 'app.squeeg.ee') return;

            const onesignalScript = document.createElement('script');
            onesignalScript.async = true;
            onesignalScript.src = 'https://cdn.onesignal.com/sdks/OneSignalSDK.js';
            document.body.appendChild(onesignalScript);
            while (!(window as any).OneSignal) await wait(1000);

            OneSignal.web?.init({ appId: '675e1658-713d-4046-9399-d8199888aad7' });
        } catch (error) {
            Logger.error('Failed to initialise onesignal web.', error);
        }
    }

    private static async initialiseMobileOneSignal() {
        try {
            if (GlobalFlags.isHttp || (!GlobalFlags.isAppleMobileDevice && !GlobalFlags.isAndroidMobileDevice)) return;

            const app = OneSignal.app;
            if (!app) return;

            app.setAppId('675e1658-713d-4046-9399-d8199888aad7');
            app.setNotificationOpenedHandler(data => Logger.info('Push received: ', data));
            app.setNotificationWillShowInForegroundHandler(e => {
                const notification = e.getNotification();
                if (notification.launchURL) {
                    new ActionableEvent(notification.title as TranslationKey, 'push.open-notification', notification.launchURL);
                } else {
                    new NotifyUserMessage(notification.title as TranslationKey);
                }
            });
        } catch (error) {
            Logger.error('Failed to initialise the onesignal mobile config.', error);
        }
    }

    private static configureAndroidNavigation() {
        if (!GlobalFlags.isAndroidMobileDevice || GlobalFlags.isHttp) return;

        try {
            const navigator = window.navigator && (window.navigator as any).app;
            if (navigator) document.addEventListener('backbutton', () => navigator.backHistory(), false);
        } catch (error) {
            Logger.error('Failed to initialise the android navigation handling.', error);
        }
    }

    private static get ready() {
        if (!Initialisation._ready) {
            Initialisation._ready = new Promise<void>(async resolve => {
                if ((<any>window).cordova) return document.addEventListener('deviceready', () => resolve());
                return resolve();
            });
        }

        return Initialisation._ready;
    }

    private static initialiseErrorHandler() {
        window.addEventListener('onerror', (event: ErrorEvent) => {
            try {
                Logger.error('Error from main window error handler', { error: event });
            } catch (error) {
                /*  */ console.log('Unable to log error from global error handler', event.error); /*  */
            }
        });
    }
}
