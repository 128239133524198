import type { Customer, IFabAction, Job, TranslationKey } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../ApplicationState';
import { AttachmentsDialog } from '../../Attachments/Dialogs/AttachmentsDialog';
import { Data } from '../../Data/Data';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { Prompt } from '../../Dialogs/Prompt';
import { TextDialog } from '../../Dialogs/TextDialog';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { EditJobDialog } from '../../Jobs/Components/EditJobDialog';
import { JobService } from '../../Jobs/JobService';
import type { JobSummary } from '../../Jobs/JobSummary';
import { Logger } from '../../Logger';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { SendNotificationService } from '../../Notifications/SendNotificationService';
import { Api } from '../../Server/Api';
import { Utilities } from '../../Utilities';
import { CustomerService } from '../CustomerService';
import { CustomerDialog } from './CustomerDialog';

export class JobSummaryDialog extends CustomDialog<void> {
    protected currencySymbol = ApplicationState.currencySymbol();
    public dateFormat = 'ddd, MMM Do';
    public account = ApplicationState.account;
    public invoiceEmail = ApplicationState.account.invoiceSettings.email;
    protected host = Api.currentHostAndScheme;
    protected fromName: string;
    protected services: string;
    protected address: string;
    protected frequency: string;
    protected startDate: string;
    protected ongoingDate: string;
    protected price: string;

    protected jobSummary: JobSummary | undefined;

    protected allowedToViewJobAttachments = ApplicationState.allowedRolesToViewJobAttachments;

    customerDescription: string | undefined;

    constructor(public customer: Customer, public job: Job) {
        super('jobSummaryDialog', '../Customers/Components/JobSummaryDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'job-summary-dialog no-nav-shadow',
            isSecondaryView: true,
        });
        this.initialise();

        this.customerDescription = this.customer?.name || ApplicationState.localise('general.unknown');
    }

    private initialise() {
        this.updateFab();
        this.fromName = SendNotificationService.notificationFrom;
        this.services = `${Utilities.localiseList(this.job.services.map(s => s.description as TranslationKey))}`;
        this.jobSummary = JobService.getJobSummaryForJobAndCustomer(this.job, this.customer);
        this.address = this.job.location?.addressDescription || '';

        if (this.jobSummary.frequency) {
            this.frequency = ApplicationState.localise(
                this.jobSummary.frequency.localisationKeyAndParams.key,
                this.jobSummary.frequency.localisationKeyAndParams.params
            );
        }

        if (!isNaN(Number(this.job.price))) this.price = `${ApplicationState.currencySymbol()}${Number(this.job.price).toFixed(2)}`;
    }

    protected get canViewCustomer() {
        return ApplicationState.isInAnyRole(['Owner', 'Admin', 'Creator']);
    }

    protected async viewCustomer() {
        if (this.customer) {
            const dialog = new CustomerDialog(this.customer);
            await dialog.show(DialogAnimation.SLIDE_UP);
        }
    }

    public cancelQuote = async () => {
        try {
            if (
                await new Prompt('prompts.cancel-title', 'prompts.cancel-quote', {
                    okLabel: 'general.yes',
                    cancelLabel: 'general.no',
                }).show()
            ) {
                await JobService.removeJob(this.job.customerId, this.job);
                new NotifyUserMessage('notifications.quote-cancelled');
                this.cancel();
            }
        } catch (error) {
            new NotifyUserMessage('notifications.delete-quote-failed');
        }
    };

    private emailChecker: (value: string) => true | TranslationKey = (value: string) => {
        if (value.trim()) return true;
        else return 'validation.valid-email-required';
    };

    public emailQuote = async () => {
        try {
            if (!ApplicationState.isVerifiedForMessaging) {
                new NotifyUserMessage('notification.account-email-not-verified-message-sending');
                return;
            }

            const dialog = new TextDialog(
                'dialogs.email-quote-title',
                'dialogs.email-quote-label',
                this.customer.email || '',
                'dialogs.email-quote-label',
                this.emailChecker,
                false,
                'email',
                'general.send'
            );
            const email = await dialog.show();
            if (!dialog.cancelled) {
                await CustomerService.checkSaveNewEmailEmail(email, this.customer);
                new LoaderEvent(true);
                if (
                    await SendNotificationService.send(
                        this.services + ' for ' + this.address,
                        email,
                        this.customer._id,
                        'Email',
                        this.fromName,
                        {
                            fromName: this.fromName,
                            isHtml: true,
                            toName: this.customer.name,
                            description: this.services + ' for ' + this.address,
                            email,
                            quote: this.job,
                        },
                        ApplicationState.account.bccEmailNotifications,
                        undefined,
                        undefined,
                        undefined,
                        'emailQuote1'
                    )
                ) {
                    new NotifyUserMessage('notifications.quote-sent');
                } else new NotifyUserMessage('notifications.send-quote-failed');
            }
        } catch (error) {
            Logger.error(
                `Error during email quote from quote summary dialog for customer ${this.customer._id} and quote ${this.job._id}`,
                error
            );
            new NotifyUserMessage('notifications.send-quote-failed');
        } finally {
            new LoaderEvent(false);
        }
    };

    private editJob = async () => {
        const dialog = new EditJobDialog(this.customer, this.job);
        await dialog.show(DialogAnimation.SLIDE);
        this.customer = Data.get<Customer>(this.customer._id) as Customer;
        this.job = JobService.getJob(this.customer._id, this.job._id) as Job;
        this.initialise();
    };

    private updateFab() {
        const actions = [] as Array<IFabAction>;

        actions.push({
            tooltip: 'actions.edit-job',
            actionType: 'action-edit',
            handler: this.editJob,
            roles: ['Owner', 'Admin', 'Creator'],
        });
        this.createFab(actions);
    }

    protected async viewJobAttachment() {
        const dialog = new AttachmentsDialog(this.job, this.job.location?.addressDescription || '', 'event', 'Job Attachments');
        await dialog.show();
    }
}
