import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { Signature } from '@nexdynamic/squeegee-common';
import SignaturePad from 'signature_pad';
import { Logger } from '../../Logger';
import { CustomDialog } from '../CustomDialog';
import { Prompt } from '../Prompt';

export class SignatureDialog extends CustomDialog<Signature | undefined> {
    protected signatureCanvas: HTMLCanvasElement;

    private _signaturePad: SignaturePad;

    constructor(
        public dialogTitle: TranslationKey,
        protected okLabel: TranslationKey = 'global.sign-title',
        private allowBlank = true,
        private existingSignature?: string,
        protected requireFullname = true,
        protected fullname = '',
        protected reason?: string
    ) {
        super('signaturePad', './Signatures/SignatureDialog.html', dialogTitle, {
            cssClass: 'signature-pad',
            okLabel: '',
            cancelLabel: '',
        });
    }

    public async init() {
        this.signatureCanvas = <HTMLCanvasElement>document.getElementById('signature-pad');
        this._signaturePad = new SignaturePad(this.signatureCanvas);
        this._signaturePad.on();
        window.addEventListener('resize', () => this.resizeCanvas());
        requestAnimationFrame(() => {
            this.resizeCanvas();
            requestAnimationFrame(() => {
                if (this.existingSignature) {
                    try {
                        this._signaturePad.fromDataURL(this.existingSignature);
                    } catch (error) {
                        Logger.error('Invalid original signature passed to signature pad', error);
                        this._signaturePad.clear();
                    }
                } else {
                    this._signaturePad.clear();
                }
            });
        });
    }

    protected clear() {
        this._signaturePad.clear();
    }

    public dispose() {
        this._signaturePad && this._signaturePad.on();
        super.dispose();
    }

    private _signature?: Signature;
    public async getResult(): Promise<Signature | undefined> {
        return this._signature;
    }

    public delegateOk = () => {
        const empty = this._signaturePad.isEmpty();
        if (!this.allowBlank && empty) {
            new Prompt('general.no-signature', 'empty.signature-panel-required', {
                okLabel: 'general.ok',
                cancelLabel: '',
            }).show();
        } else if (!this.allowBlank && this.requireFullname && (this.fullname || '').length < 3) {
            new Prompt('invalid.full-name-title', 'invalid.full-name-text', {
                okLabel: 'general.ok',
                cancelLabel: '',
            }).show();
        } else {
            this._signature = empty ? undefined : new Signature(this.signatureCanvas.toDataURL(), this.fullname);
            this.ok();
        }
    };

    private resizeCanvas() {
        const data = this.signatureCanvas.toDataURL();
        const ratio = Math.max(window.devicePixelRatio || 1, 1);
        this.signatureCanvas.width = this.signatureCanvas.offsetWidth * ratio;
        this.signatureCanvas.height = this.signatureCanvas.offsetHeight * ratio;
        const context = this.signatureCanvas.getContext('2d');
        if (context) {
            context.scale(ratio, ratio);
        }
        requestAnimationFrame(() => this._signaturePad.fromDataURL(data));
    }
}
