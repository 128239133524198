import type { LngLat, Location } from '@nexdynamic/squeegee-common';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { LocationApi } from '../../Server/LocationApi';
import { CustomDialog } from '../CustomDialog';
import { DialogAnimation } from '../DialogAnimation';

export class PinAddressDialog extends CustomDialog<Location> {
    public showPinPopup = false;
    public currentLngLat: LngLat | undefined;
    public failedValidation = false;
    public isW3WAddress = false;

    constructor(public location: Location, public currentPosition: LngLat, public pinOnly = false) {
        super('pinAddressDialog', './PinAddress/PinAddressDialog.html', '', {
            cssClass: 'pin-address-dialog',
            okLabel: '',
            cancelLabel: '',
            isSecondaryView: true,
        });
        this.animationType = DialogAnimation.SLIDE;
        this.currentLngLat = location.lngLat || currentPosition;
        if (location.addressDescription && LocationApi.getThreeWordsFromText(location.addressDescription)) this.isW3WAddress = true;
    }
    public onPinned = async (lat: number, lng: number) => {
        this.currentLngLat = [lng, lat];
        if (this.isW3WAddress) {
            const result = (await LocationApi.getW3WAddresses(this.currentLngLat, true))?.[0];
            if (result) this.location = result;
        }
        this.showPinPopup = true;
    };

    public onUnPinned = () => {
        this.currentLngLat = undefined;
        this.showPinPopup = false;
    };

    public pinAddress(pin = true) {
        if (this.location.addressDescription && this.location.addressDescription.trim()) {
            this.failedValidation = !pin;
            this.location.lngLat = pin && this.currentLngLat ? [this.currentLngLat[0], this.currentLngLat[1]] : (null as any as LngLat);
            this.location.isVerified = pin;
            this.ok(this.location);
        } else {
            this.failedValidation = true;
            new NotifyUserMessage('notifications.address-enter-text');
        }
    }
}
