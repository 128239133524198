import { inject, bindable, bindingMode } from 'aurelia-framework';

@inject(Element)
export class OnEnterKeyCustomAttribute {
    @bindable({ defaultBindingMode: bindingMode.oneWay }) nextFieldId?: string;

    @bindable({ defaultBindingMode: bindingMode.oneWay }) nextAction?: () => Promise<void> | void;

    constructor(private _element: HTMLInputElement) {}

    attached() {
        this._element.addEventListener('keypress', this._keyHandler);
    }

    detached() {
        this._element.addEventListener('keypress', this._keyHandler);
    }

    private _keyHandler = async (keyEvent: KeyboardEvent) => {
        if (keyEvent.keyCode === 13 && (this.nextFieldId || this.nextAction)) {
            keyEvent.preventDefault();
            if (this.nextAction) await this.nextAction();
            if (this.nextFieldId) {
                const nextField = document.getElementById(this.nextFieldId);
                if (nextField) nextField.focus();
            }
        }
    };
}
