import type { Quote } from '@nexdynamic/squeegee-common';
import { bindable, BindingEngine, inject } from 'aurelia-framework';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import { SearchableList } from '../../Components/Lists/SearchableList';
import { CustomerService } from '../../Customers/CustomerService';
import type { Subscription } from '../../Events/DataRefreshedEvent';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';

@inject(BindingEngine)
export class CustomerQuotesCustomElement {
    @bindable() customerId: string;
    quotes: Array<Quote> = [];
    protected list: SearchableList<Quote>;
    private _dataChangedSub?: Subscription;

    constructor(private bindingEngine: BindingEngine) { }
    attached() {
        this.loadQuotes();
        this.list = new SearchableList<Quote>(this.bindingEngine, this.quotes, []);
        this._dataChangedSub = DataRefreshedEvent.subscribe((e: DataRefreshedEvent) => {
            if (e.filterByType<Quote>('quotes').find(quote => quote.customerId === this.customerId)) this.loadQuotes();
        });
    }

    loadQuotes() {
        let quotes = CustomerService.getQuotes(this.customerId);
        if (ApplicationState.getSetting('global.hide-not-active-quotes')) {
            const today = moment().endOf('day').valueOf();
            quotes = quotes.filter(quote => quote.state === 'accepted' || (quote.state === 'pending' && quote.expiry > today));
        }
        this.quotes.splice(0, this.quotes.length, ...quotes);
    }

    detached() {
        this._dataChangedSub?.dispose();
        this.list?.dispose();
    }
}
