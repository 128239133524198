import { bindable } from 'aurelia-framework';
import * as Chartist from 'chartist';
import type { Subscription } from '../../Events/SqueegeeEventAggregator';
import { ViewResizeEvent } from '../../Events/ViewResizeEvent';

export class LineChartCustomElement {
    @bindable() data: { series: Array<number[]>; labels: Array<string> };
    @bindable() yAxisPrefix: string;
    @bindable() yAxisSuffix: string;
    @bindable() timeSeries = false;
    @bindable() centreLables: boolean;
    @bindable() showLabels = true;
    @bindable() showPoints = true;
    @bindable() showGrid = true;
    public chartRef: HTMLDivElement;
    public chart?: Chartist.IChartistLineChart;

    private _viewResizeSub: Subscription;
    public attached() {
        this._viewResizeSub = ViewResizeEvent.subscribe(() => {
            setTimeout(() => {
                requestAnimationFrame(() => {
                    if (this.chart) (<any>this.chart).update();
                });
            }, 250);
        });
    }

    public dataChanged(newValue: { series: Array<number[]>; labels: Array<string> } | undefined) {
        if (this.chart === undefined) this.loadChart();
        else if (newValue !== undefined) {
            this.chart.update(newValue);
        }
    }

    private loadChart() {
        this.chart = new Chartist.Line(this.chartRef, this.data, {
            fullWidth: true,
            showPoint: this.showPoints,
            chartPadding: { right: 16, left: 16 },
            axisX: this.timeSeries
                ? {
                      type: Chartist.FixedScaleAxis,
                      divisor: 5,
                      showGrid: this.showGrid,
                      showLabel: this.showLabels,
                      labelInterpolationFnc: (value: number) => value,
                  }
                : {
                      divisor: 5,
                      showGrid: this.showGrid,
                      showLabel: this.showLabels,
                  },
            axisY: {
                labelInterpolationFnc: (value: number) => {
                    return `${this.yAxisPrefix || ''}${value}${this.yAxisSuffix || ''}`;
                },
                scaleMinSpace: 30,
            },
        });
    }

    protected detached() {
        this.chart && this.chart.detach();
        this._viewResizeSub && this._viewResizeSub.dispose();
    }
}
