import { ApplicationState } from './ApplicationState';
import type { IFabAction } from './Components/Fabs/IFabAction';

export class FabWithActions {
    public static actions: Array<IFabAction> = [];

    public static register(actions: Array<IFabAction>) {
        if (!actions) return;
        FabWithActions.unregister();
        actions = actions.filter(x => ApplicationState.isInAnyRole(x.roles));

        FabWithActions.actions.splice(0);
        FabWithActions.actions.push(...actions);
    }

    public static unregister() {
        return FabWithActions.actions.splice(0);
    }
}
