import type { Customer, CustomerProvider, TranslationKey } from '@nexdynamic/squeegee-common';
import { wait } from '@nexdynamic/squeegee-common';
import { ConnectedServicesService } from '../../ConnectedServices/ConnectedServicesService';
import { CustomerFormDialog } from '../../Customers/Components/CustomerFormDialog';
import { SelectCustomerDialog } from '../../Customers/Components/SelectCustomerDialog';
import { Data } from '../../Data/Data';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { Prompt } from '../../Dialogs/Prompt';
import { LoaderEvent } from '../../Events/LoaderEvent';

export class CustomerManualSyncService {
    static async syncCustomers(provider: CustomerProvider) {
        const unsyncedCustomers = await this.getUnsyncedCustomers(provider);
        if (!unsyncedCustomers.length) {
            await new Prompt(
                'No unsynced customers' as TranslationKey,
                `You have no customers that are not synced to Xero` as TranslationKey,
                { cancelLabel: '' }
            ).show();
            return;
        }
        const responses = [];
        const promptToContinue = await new Prompt(
            'prompts.confirm-title',
            `You have ${unsyncedCustomers.length} customers that are not connected to a xero customer. Confirm you would like to create these customers in xero?` as TranslationKey
        ).show();
        if (!promptToContinue) return;

        new LoaderEvent(true, true, 'loader.syncing-unsynced-customers', true, { customer: unsyncedCustomers.length.toString() });
        let counter = 1;
        for (const customer of unsyncedCustomers) {
            new LoaderEvent(
                true,
                true,
                ('Syncing ' + counter + ' of ' + unsyncedCustomers.length + ' customer data to xero') as TranslationKey,
                true
            );
            responses.push(await ConnectedServicesService.createConnectedEntity(provider, 'customers', customer._id, true));
            await wait(1000);
            counter++;
        }
        new LoaderEvent(false);
        return responses;
    }

    private static async getUnsyncedCustomers(provider: CustomerProvider) {
        return Data.all<Customer>('customers', x => !x.externalIds || !x.externalIds[provider]);
    }

    static async findOrCreateCustomerFromProvider(thirdPartyCustomer: Customer): Promise<Customer | undefined> {
        const selectCustomerDialog = new SelectCustomerDialog(undefined, undefined, undefined, {
            ref: thirdPartyCustomer._id,
            name: thirdPartyCustomer.name,
            address: thirdPartyCustomer.address.addressDescription || '',
            email: thirdPartyCustomer.email,
            telephoneNumber: thirdPartyCustomer.telephoneNumber,
        });

        let selectedCustomer = await selectCustomerDialog.show(DialogAnimation.SLIDE_UP);

        if (!selectedCustomer) return;

        if (selectCustomerDialog.cancelled) return;

        if (selectCustomerDialog.customerIsNew) {
            Object.assign(selectedCustomer, thirdPartyCustomer);
            const customerDialog = new CustomerFormDialog(selectedCustomer);
            selectedCustomer = await customerDialog.show(DialogAnimation.SLIDE_UP);
            if (customerDialog.cancelled) return;
        } else {
            const thirdPartyDetails = [
                (thirdPartyCustomer.name || '').trim(),
                (thirdPartyCustomer.address.addressDescription || '').trim(),
                (thirdPartyCustomer.email ? `<span style="color: #0288d1">${thirdPartyCustomer.email}</span>` : '').trim(),
                (thirdPartyCustomer.telephoneNumber || '').trim(),
            ]
                .filter(Boolean)
                .join('<br>');

            const customerDetails = [
                (selectedCustomer.name || '').trim(),
                (selectedCustomer.address?.addressDescription || '').trim(),
                (selectedCustomer.email ? `<span style="color: #0288d1">${selectedCustomer.email}</span>` : '').trim(),
                (selectedCustomer.telephoneNumber || '').trim(),
                (selectedCustomer.telephoneNumberOther || '').trim(),
            ]
                .filter(Boolean)
                .join('<br>');
            if (
                !(await new Prompt(
                    'Confirm Customer Match' as TranslationKey,
                    `<strong>Provider Customer<br></strong>${thirdPartyDetails}<br><br><strong>Squeegee Customer</strong><br>${customerDetails}` as TranslationKey,
                    { okLabel: 'general.confirm', cancelLabel: 'general.cancel' }
                ).show())
            ) {
                return;
            }

            await Data.put(selectedCustomer);
        }

        return selectedCustomer;
    }

    static async importCustomer(provider: CustomerProvider, id: string): Promise<Customer | undefined> {
        const thirdPartyCustomer = await ConnectedServicesService.getCustomer(provider, id);
        if (!thirdPartyCustomer) throw 'Customer not found in provider';
        const customer = await CustomerManualSyncService.findOrCreateCustomerFromProvider(thirdPartyCustomer);
        if (!customer) return;
        if (!customer.externalIds) customer.externalIds = {};
        customer.externalIds[provider] = id;
        await Data.put(customer);
        return customer;
    }
}
