import { bindable, bindingMode } from 'aurelia-framework';
import type * as leaflet from 'leaflet';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { JobOccurrenceService } from '../../Jobs/JobOccurrenceService';
import { LocationUtilities } from '../../Location/LocationUtilities';
import { ScheduleDetailsDialog } from '../../Schedule/Components/ScheduleDetailsDialog';
import type { ScheduleItem } from '../../Schedule/Components/ScheduleItem';
import { ScheduleService } from '../../Schedule/ScheduleService';
import { Utilities } from '../../Utilities';
import type { OrderType } from '../DayPilot';
import type { RouteScheduleItem } from './RouteScheduleItem';

export class DayPilotItemsCustomElement {
    @bindable({ defaultBindingMode: bindingMode.oneWay }) scheduleItems: Array<RouteScheduleItem>;
    @bindable() driveDistanceMetres: number;
    @bindable() driveDurationSeconds: number;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) map: leaflet.Map;
    @bindable() listRef: HTMLDivElement;
    // @bindable({ defaultBindingMode: bindingMode.twoWay }) locationsLookup: Array<ILocationLookup>;
    @bindable() markers: leaflet.FeatureGroup & {
        zoomToShowLayer: (marker: leaflet.Marker, callback?: () => any) => any;
    };
    @bindable({ defaultBindingMode: bindingMode.twoWay }) selectedItem: RouteScheduleItem | null | undefined;
    @bindable() listLoading: boolean;
    @bindable() unverifiedTotal: number;
    @bindable() sortOrder: OrderType;
    public totalPrice: string;
    public totalDistance: string;
    public totalDuration: string;
    //  private _previousLatLngZoomedTo: leaflet.LatLng | undefined;
    protected dayPilotItemsList: HTMLUListElement;

    listLoadingChanged() {
        if (this.listLoading === false) this.refresh();
    }
    driveDistanceMetresChanged() {
        if (ApplicationState.distanceUnits === 'miles') {
            this.totalDistance =
                LocationUtilities.convertMetresIntoMiles(this.driveDistanceMetres).toFixed(2) + ApplicationState.distanceUnitsAbbreviated;
        } else {
            this.totalDistance = (this.driveDistanceMetres / 1000).toFixed(1) + ApplicationState.distanceUnitsAbbreviated;
        }
    }
    driveDurationSecondsChanged() {
        this.totalDuration = moment.duration(this.driveDurationSeconds).humanize();
    }

    private refresh() {
        this.iterateOverItems(this.scheduleItems);
    }

    public async viewScheduleItem(scheduleItem: RouteScheduleItem) {
        if (!scheduleItem.scheduleItem) return;
        const dialog = new ScheduleDetailsDialog(scheduleItem.scheduleItem);
        await dialog.show(DialogAnimation.SLIDE);
    }

    public iterateOverItems(items: Array<RouteScheduleItem>) {
        let totalPrice = 0;

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item && item.scheduleItem) totalPrice += item.scheduleItem.job.price || 0;
            if (this.selectedItem && item.id === this.selectedItem.id) {
                this.selectedItemChanged(item);
            }
        }

        if (ApplicationState.distanceUnits === 'miles') {
            this.totalDistance =
                LocationUtilities.convertMetresIntoMiles(this.driveDistanceMetres).toFixed(2) + ApplicationState.distanceUnitsAbbreviated;
        } else {
            this.totalDistance = (this.driveDistanceMetres / 1000).toFixed(1) + ApplicationState.distanceUnitsAbbreviated;
        }
        this.totalDuration = moment.duration(this.driveDurationSeconds, 'second').humanize();
        this.totalPrice = (totalPrice && totalPrice.toFixed && totalPrice.toFixed(2)) || totalPrice.toString();
    }

    private _previousExpandedItem: HTMLElement | undefined;

    protected selectedItemChanged(selectedItem: RouteScheduleItem) {
        if (selectedItem) {
            const element = <HTMLElement>this.listRef.querySelector('#' + selectedItem.id);
            if (element) this.expandItem(element, selectedItem, true);
        }
    }

    public expandItem(element: HTMLElement, scheduleItem?: RouteScheduleItem, fromMapClick = false) {
        if (element) {
            if (this._previousExpandedItem && this._previousExpandedItem !== element) {
                this._previousExpandedItem.classList.remove('expanded');
            }
            requestAnimationFrame(() => {
                if (fromMapClick && this._previousExpandedItem === element) {
                    Utilities.scrollTo(this.listRef, element.offsetTop, 250);
                    element.classList.add('expanded');
                } else {
                    Utilities.scrollTo(this.listRef, element.offsetTop, 250);
                    element.classList.toggle('expanded');
                    this.selectedItem = element.classList.contains('expanded') ? scheduleItem : undefined;
                }
                if (this._previousExpandedItem && this._previousExpandedItem !== element) {
                    this._previousExpandedItem.classList.remove('expanded');
                }
                this._previousExpandedItem = element;
            });
        }
    }

    public async markAsDone(event: Event, scheduleItem: RouteScheduleItem) {
        event.stopPropagation();

        if (scheduleItem && scheduleItem.scheduleItem) {
            await ScheduleService.completeScheduleItems([scheduleItem.scheduleItem]);
            this._previousExpandedItem = undefined;
        }
    }

    public async openScheduleItem(event: Event, scheduleItem: RouteScheduleItem) {
        event.stopPropagation();
        if (scheduleItem && scheduleItem.scheduleItem) {
            const dialog = new ScheduleDetailsDialog(scheduleItem.scheduleItem);
            await dialog.show(DialogAnimation.SCALE);
        }
    }

    public async markAsSkipped(event: Event, scheduleItem: RouteScheduleItem) {
        event.stopPropagation();

        if (scheduleItem.scheduleItem) {
            await JobOccurrenceService.skipJobOccurrence(scheduleItem.scheduleItem.occurrence);

            this._previousExpandedItem = undefined;
        }
    }

    protected getRoundColour(scheduleItem: ScheduleItem) {
        return scheduleItem.job.rounds && scheduleItem.job.rounds.length > 0 && scheduleItem.job.rounds[0].color ? scheduleItem.job.rounds[0].color : Utilities.textToColour(scheduleItem.roundName);
    }
}
