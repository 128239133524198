import type { Transaction } from '@nexdynamic/squeegee-common';
import { TransactionType } from '@nexdynamic/squeegee-common';
import type { IFabAction } from '../Components/Fabs/IFabAction';
import { CustomerFormDialog } from '../Customers/Components/CustomerFormDialog';
import { SelectCustomerDialog } from '../Customers/Components/SelectCustomerDialog';
import { Data } from '../Data/Data';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { ViewResizeEvent } from '../Events/ViewResizeEvent';
import { FabWithActions } from '../FabWithActions';
import { CreateOrEditInvoiceTransactionDialog } from './CreateOrEditInvoiceTransactionDialog';
import { InvoiceTransactionSummaryDialog } from './InvoiceTransactionSummaryDialog';

export class Invoices {
    protected transactionType = TransactionType.Invoice;
    protected fabWithActions: FabWithActions;
    params: { transactionIdOrInvoiceNumber?: string };

    public async activate(params: { transactionIdOrInvoiceNumber?: string }) {
        this.params = params;
    }

    public async attached() {
        new ViewResizeEvent();
        FabWithActions.register(this.getActions());

        if (!this.params?.transactionIdOrInvoiceNumber) return;

        let invoiceTransaction: Transaction | undefined;
        const invoiceNumber = Number(this.params.transactionIdOrInvoiceNumber);
        if (isNaN(invoiceNumber)) {
            invoiceTransaction = Data.get<Transaction>(this.params.transactionIdOrInvoiceNumber);
        } else {
            invoiceTransaction = Data.firstOrDefault<Transaction>('transactions', t => t.invoice?.invoiceNumber === invoiceNumber);
        }
        if (!invoiceTransaction) return;

        new InvoiceTransactionSummaryDialog(invoiceTransaction).show();
    }

    public detached() {
        FabWithActions.unregister();
    }

    public delegateCreateInvoice = () => this.createInvoice();

    private getActions(): Array<IFabAction> {
        const fabActions: Array<IFabAction> = [
            {
                tooltip: 'actions.create-invoice',
                actionType: 'action-invoice',
                handler: this.delegateCreateInvoice,
                roles: ['Owner', 'Admin'],
            },
        ];
        return fabActions;
    }

    private async createInvoice() {
        const selectCustomerDialog = new SelectCustomerDialog();
        let selectedCustomer = await selectCustomerDialog.show(DialogAnimation.SLIDE_UP);
        if (!selectCustomerDialog.cancelled && selectedCustomer) {
            if (selectCustomerDialog.customerIsNew) {
                const customerDialog = new CustomerFormDialog(selectedCustomer);
                selectedCustomer = await customerDialog.show(DialogAnimation.SLIDE_UP);
                if (customerDialog.cancelled) return;
            }

            const dialog = CreateOrEditInvoiceTransactionDialog.createDialog(selectedCustomer);
            if (!dialog) return;

            await dialog.show(DialogAnimation.SLIDE_UP);
        }
    }

    //TODO: Create CREDIT Note should also go here
}
