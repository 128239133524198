import { CustomDialog } from '../../Dialogs/CustomDialog';
import type { IViewOptions } from '../../IViewOptions';
import { isDevMode } from '../../isDevMode';

export class ViewOptionsDialog extends CustomDialog<IViewOptions> {
    protected isDevMode = isDevMode();

    constructor(protected viewOptions: IViewOptions) {
        super('customerViewOptionsDialog', '../Customers/Views/ViewOptionsDialog.html', 'view-options.customers-title', {
            cssClass: 'customer-view-options-dialog',
            allowClickOff: true,
            allowCancel: false,
            cancelLabel: '',
        });
    }

    protected selectViewOptions() {
        this.ok(this.viewOptions);
    }

    public async getResult() {
        return this.viewOptions;
    }
}
