import { bindable } from 'aurelia-framework';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import { DaynoteActions } from '../../Daynotes/Daynote.actions';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import type { TActionSlotMoreMenuAction } from '../../Menus/Components/action-slot-more-menu';
import './daynote.scss';
export class Daynote {
    @bindable date: string;
    dateChanged() {
        this.title = ApplicationState.localise('daynotes.card-title', { date: moment(this.date).format('dddd, Do MMM YYYY') });
        this.content = ApplicationState.getSetting('global.day-note', undefined, this.date);
    }
    content: string | undefined;
    title: string | undefined;

    actions: Array<TActionSlotMoreMenuAction> = !ApplicationState.isInAnyRole(['Owner', 'Admin', 'Creator', 'Planner']) ? [] : [
        {
            icon: 'edit',
            label: 'general.edit',
            handler: () => DaynoteActions.update(this.date, this.content),
        },
        {
            icon: 'delete',
            label: 'general.delete',
            handler: () => DaynoteActions.delete(this.date),
        },
    ];

    attached() {
        //subscribe to the setting being changed
        DataRefreshedEvent.subscribe((event: DataRefreshedEvent) => {
            const setting = event.getUpdatedSetting<string>('global.day-note', this.date);
            if (!setting) return;

            if (setting._deleted === true) this.content = undefined;
            else {
                this.content = setting.value;
            }
        });
    }
}
