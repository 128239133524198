import type { AuthorisedUser, UserRole } from '@nexdynamic/squeegee-common';
import { bindable, inject, observable } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { Logger } from '../../Logger';

@inject(Element)
export class IfisInAnyRoleCustomAttribute {
    @observable authorisedUser: AuthorisedUser = ApplicationState.userAuthorisation;
    @bindable({ primaryProperty: true }) rolesAllowed?: Array<UserRole>;

    constructor(private element: Element) {}

    authorisedUserChanged() {
        this.setElementDisplay(this.rolesAllowed);
    }

    propertyChanged(name: string, newValue: Array<UserRole> | undefined, _oldValue: Array<UserRole> | undefined) {
        if (name === 'rolesAllowed') this.setElementDisplay(newValue);
    }

    setElementDisplay(allowedRoles: Array<UserRole> | undefined) {
        try {
            const allowedToView = allowedRoles ? ApplicationState.isInAnyRole(allowedRoles) : false;
            if (allowedToView) return;
            (this.element as HTMLElement).style.display = 'none';
        } catch (error) {
            Logger.error('IfisInAnyRoleCustomAttribute: Unable to change element display value');
        }
    }
}
