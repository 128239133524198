import { close, coloris, init } from '@melloware/coloris';
import '@melloware/coloris/dist/coloris.css';

init();

export function pickColour(elementId: string) {
    coloris({
        el: `${elementId.startsWith('#') ? '' : '#'}${elementId}`,
        themeMode: 'dark',
        alpha: false,
    });
    return () => close();
}
