// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumb {
  display: flex;
  font-weight: 700;
  font-size: 18px;
  align-items: center;
  padding: 16px 16px;
  flex-wrap: wrap;
}
.breadcrumb a {
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/Breadcrumb/breadcrumb.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,gBAAA;EACA,eAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;AACJ;AAAI;EACI,qBAAA;AAER","sourcesContent":[".breadcrumb {\n    display: flex;\n    font-weight: 700;\n    font-size: 18px;\n    align-items: center;\n    padding: 16px 16px;\n    flex-wrap: wrap;\n    a {\n        text-decoration: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
