import type { TableData } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { ShortCutKeys } from '../ShortCutKeys';
import { CustomDialog } from './CustomDialog';
import { DialogAnimation } from './DialogAnimation';
import './LauncherCommandListDialog.scss';

export class LauncherKeybinds extends CustomDialog<string> {
    tableData: TableData;
    shortCutKeys = Object.values(ShortCutKeys).filter(shortCutKey => {
        if (shortCutKey.hidden) return false;
        if (shortCutKey.roles && !ApplicationState.isInAnyRole(shortCutKey.roles)) return false;
        if (shortCutKey.minimumSubscriptionLevel && !ApplicationState.hasMinimumSubscription(shortCutKey.minimumSubscriptionLevel))
            return false;

        return true;
    });

    constructor(private DialogTitle: string, private value: any) {
        super('LauncherKeybinds', './LauncherKeybinds.html', 'launcher.keybinds-title', {
            coverViewport: true,
            cssClass: 'launcher-keybinds-dialog',
        });
    }

    protected async viewKeybinds() {
        const dialog = new LauncherKeybinds(this.DialogTitle, this.value);
        await dialog.show(DialogAnimation.SLIDE);
    }
}
