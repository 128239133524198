import { bindable, computedFrom } from 'aurelia-framework';
import moment from 'moment';
import { ApplicationState } from '../../../ApplicationState';
import { ScheduleDetailsDialog } from '../../../Schedule/Components/ScheduleDetailsDialog';
import type { ScheduleItem } from '../../../Schedule/Components/ScheduleItem';

export class Appointment {
    @bindable appointment: ScheduleItem;

    @computedFrom('appointment.formattedDate')
    get title() {
        return moment(this.appointment.date).format('ddd Do MMM YYYY');
    }

    private _price?: number;
    private _priceSet: boolean;
    @computedFrom('appointment.occurrence.price')
    public get price() {
        if (!this._priceSet) {
            this._priceSet = true;
            if (
                ApplicationState.isInAnyRole(['Owner', 'Admin', 'Creator']) ||
                this.appointment.customer.hideJobPricesFromWorker === false ||
                ((this.appointment.customer.hideJobPricesFromWorker === undefined ||
                    this.appointment.customer.hideJobPricesFromWorker === null) &&
                    ApplicationState.account.hidePricesFromWorkerRole !== true)
            ) {
                this._price = this.appointment.occurrence.price || 0;
            }
        }
        return this._price;
    }

    @computedFrom('appointment.statusLabel')
    get status() {
        return { text: this.appointment.statusLabel, color: this.appointment.statusColour };
    }

    protected viewItem() {
        new ScheduleDetailsDialog(this.appointment).show();
    }

    @computedFrom('appointment.occurrence._archived')
    get archivedDetails() {
        if (!this.appointment.occurrence._archived) return;
        return { archivedColor: '#000000', archivedText: ApplicationState.localise('general.archived') };
    }
}
