import type { Location } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../ApplicationState';
import { GlobalFlags } from '../../GlobalFlags';
import { openSystemBrowser } from '../../Utilities';
import { CustomDialog } from '../CustomDialog';

export class NavigationDialog extends CustomDialog<void> {
    private readonly url = `${GlobalFlags.isAppleMobileDevice ? 'maps://' : 'https://maps.google.com'}?daddr='`;
    public readonly addressDescription: string;
    public readonly latLng: string;

    constructor(private location: Location) {
        super('NavigationDialog', './Navigation/NavigationDialog.html', 'dialogs.navigate-using-title', {
            cssClass: 'navigation-dialog',
            okLabel: '',
        });
        this.addressDescription = location.addressDescription || '';
        this.latLng = location.lngLat ? `${location.lngLat[1]},${location.lngLat[0]}` : '';
    }

    public showIfNeeded() {
        const automaticallyChoose = ApplicationState.getSetting<boolean>('global.scheduling.auto-choose-nav-method', false);
        if (automaticallyChoose) {
            this.navigateWith(this.latLng ? 'coordinates' : 'address');
            this.cancel(true);
            return;
        }

        return this.show();
    }

    protected get address() {
        return `${this.url}${encodeURIComponent(this.addressDescription)}&center=${this.latLng}`;
    }
    protected get coordinates() {
        return `${this.url}@${this.latLng}`;
    }


    protected hasLaunchNavigator = !!(window as any).launchnavigator && !!(window as any).launchnavigator;
    protected navigateAddress() {
        (window as any).launchnavigator && (window as any).launchnavigator.navigate(this.addressDescription);
        this.ok();
    }
    protected navigateCoordinates() {
        (window as any).launchnavigator &&
            (window as any).launchnavigator.navigate(this.location.lngLat && this.location.lngLat.slice().reverse());
        this.ok();
    }

    protected navigateWith(method: 'address' | 'coordinates') {
        switch (method) {
            case 'address':
                if (this.hasLaunchNavigator) this.navigateAddress();
                else {
                    openSystemBrowser(this.address);
                    this.ok();
                }
                break;
            case 'coordinates':
                if (this.hasLaunchNavigator) this.navigateCoordinates();
                else {
                    openSystemBrowser(this.coordinates);
                    this.ok();
                }
                break;
        }
    }
}
