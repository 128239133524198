import { computedFrom } from 'aurelia-binding';
import moment from 'moment';
import { DateTimePicker } from '../../Components/DateTimePicker/DateTimePicker';
import { CustomDialog } from '../CustomDialog';
import type { DialogSettingsSecondaryView, DialogSettingsViewport } from '../DialogSettings';

export interface IDateRange {
    rangeText: string;
    start: string;
    end: string;
}

export class DateRangeDialog extends CustomDialog<IDateRange> {
    protected dateRanges: Array<IDateRange>;

    private static DATE_FORMAT = 'YYYY-MM-DD';
    protected selectedRange: IDateRange;

    constructor(public currentRange?: IDateRange, settings?: DialogSettingsSecondaryView | DialogSettingsViewport) {
        super(
            'DateRangeDialog',
            './DateRange/DateRangeDialog.html',
            '',
            settings || {
                okLabel: '',
                cancelLabel: '',
                cssClass: 'date-range-dialog',
                isSecondaryView: true,
            }
        );
        if (currentRange) {
            this.selectedRange = { rangeText: currentRange.rangeText, start: currentRange.start, end: currentRange.end };
        }
        this.createPredefinedRanges();
    }

    protected delegateOk = () => this.ok();

    protected selectRange(range: IDateRange) {
        this.selectedRange = { start: range.start, end: range.end, rangeText: range.rangeText };
    }

    @computedFrom('selectedRange.start')
    protected get startDate() {
        if (this.selectedRange && this.selectedRange.start) return moment(this.selectedRange.start).format('ddd MMM Do YYYY');
        else return 'Select a Date';
    }

    @computedFrom('selectedRange.end')
    protected get endDate() {
        if (this.selectedRange && this.selectedRange.end) return moment(this.selectedRange.end).format('ddd MMM Do YYYY');
        else return 'Select a Date';
    }

    protected async setCustomDate(type: 'start' | 'end') {
        let initialDate: string;
        if (type === 'start' && this.selectedRange && this.selectedRange.start) initialDate = this.selectedRange.start;
        else if (this.selectedRange && this.selectedRange.end) initialDate = this.selectedRange.end;
        else initialDate = moment().format('YYYY-MM-DD');

        const picker = new DateTimePicker(false, initialDate, type === 'start' ? 'dates.select-start-date' : 'dates.select-end-date');
        picker.init();
        await picker.open();
        if (picker.selectedDate) {
            if (!this.selectedRange) this.selectedRange = <any>{};
            this.selectedRange.rangeText = 'Custom';
            if (type === 'start') this.selectedRange.start = picker.selectedDate;
            else this.selectedRange.end = picker.selectedDate;
        }
    }

    public async getResult() {
        return this.selectedRange;
    }

    private createPredefinedRanges() {
        this.dateRanges = [
            {
                rangeText: 'Today',
                start: moment().format(DateRangeDialog.DATE_FORMAT),
                end: moment().format(DateRangeDialog.DATE_FORMAT),
            },
            {
                rangeText: 'This Week',
                start: moment().startOf('isoWeek').format(DateRangeDialog.DATE_FORMAT),
                end: moment().endOf('isoWeek').format(DateRangeDialog.DATE_FORMAT),
            },
            {
                rangeText: 'This Month',
                start: moment().startOf('month').format(DateRangeDialog.DATE_FORMAT),
                end: moment().endOf('month').format(DateRangeDialog.DATE_FORMAT),
            },
            {
                rangeText: 'This Quarter',
                start: moment().startOf('quarter').format(DateRangeDialog.DATE_FORMAT),
                end: moment().endOf('quarter').format(DateRangeDialog.DATE_FORMAT),
            },
        ];
    }
}
