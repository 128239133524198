export class SecondsToTimeValueConverter {
    toView(seconds: number, asSummary = false, breakTwoLine = false) {
        if (!seconds) seconds = 0;
        return SecondsToTimeValueConverter.getFormattedDriveDuration(seconds, asSummary, breakTwoLine);
    }

    private static pad(number: number): string {
        return ('0' + number.toString()).slice(-2);
    }

    // returns in format HH:MM:SS or asSummary returns in format x hours y mins, listFormatHtml add some breaks and span tags
    public static getFormattedDriveDuration(seconds: number, asSummary = false, breakTwoLine = false): string {
        let minutes = Math.floor(seconds / 60);
        seconds = seconds % 60;
        const hours = Math.floor(minutes / 60);
        minutes = minutes % 60;
        if (asSummary) {
            const hoursSummary: string | undefined = hours > 0 ? hours.toString() + ' h' : undefined;
            const minuteSummary: string = minutes >= 0 ? minutes.toString() + ' min' : '';
            return hoursSummary
                ? breakTwoLine
                    ? '<span class="duration">' + hoursSummary + '<br>' + minuteSummary + '</span>'
                    : hoursSummary + ' ' + minuteSummary
                : breakTwoLine
                ? '<span class="duration">' + minuteSummary + '</span>'
                : minuteSummary;
        } else {
            return `${SecondsToTimeValueConverter.pad(hours)}:${SecondsToTimeValueConverter.pad(minutes)}:${SecondsToTimeValueConverter.pad(
                seconds
            )}`;
        }
    }
}
