import { searchScheduleItems, sortByDateAsc } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import type { ScheduleItem } from '../../Schedule/Components/ScheduleItem';
import { getAdditionalInfoForScheduleItems } from '../../Schedule/getAdditionalInfoForScheduleItems';

export interface ISelectableScheduleItem extends ScheduleItem {
    isSelected?: boolean;
    notSelectable?: string;
}

export class SelectScheduleItemsDialog extends CustomDialog<Array<ScheduleItem>> {
    public dateFormat = 'ddd, MMM Do YYYY';
    public searchTerm = '';

    public selectableScheduleItems: Array<ISelectableScheduleItem> = [];
    public filteredScheduleItems: Array<ISelectableScheduleItem> = [];

    private _includeFutureJobs = false;
    public get includeFutureJobs() {
        return this._includeFutureJobs;
    }

    public set includeFutureJobs(value: boolean) {
        this._includeFutureJobs = value;
        this.filterAndSort();
    }

    constructor(
        public currentScheduleItems: Array<ScheduleItem> = [],
        public scheduleItems: Array<ScheduleItem> = [],
        public isSelectableFilter?: (scheduleItem: ScheduleItem) => string | true
    ) {
        super('selectScheduleItemsDialog', '../Jobs/Components/SelectScheduleItemsDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'job-select-dialog select-list-dialog no-nav-shadow',
            coverViewport: true,
            noObfuscator: true,
            smallerOnDesktop: true,
        });
        this.selectableScheduleItems = this.scheduleItems.map(scheduleItem => {
            const selectableScheduleItem = <ISelectableScheduleItem>scheduleItem;
            selectableScheduleItem.isSelected = this.currentScheduleItems.some(
                current => current.occurrence._id === scheduleItem.occurrence._id
            );

            if (this.isSelectableFilter) {
                const isSelectableResult = this.isSelectableFilter(selectableScheduleItem);
                if (typeof isSelectableResult === 'string') selectableScheduleItem.notSelectable = isSelectableResult;
            }
            return selectableScheduleItem;
        });

        if (this.currentScheduleItems.some(item => moment(item.date).isAfter(moment()))) {
            this.includeFutureJobs = true;
        }

        this.filterAndSort();
    }

    public filterAndSort() {
        const tomorrow = moment().add(1, 'day').format('YYYY-MM-DD');
        const searchTerm = this.searchTerm.toLowerCase();
        const mobilePrefixes = ApplicationState.account.smsMobilePhonePrefixes?.split(',').map(x => x.trim());
        const additional = getAdditionalInfoForScheduleItems(this.selectableScheduleItems);
        const filteredScheduleItems = searchScheduleItems<ScheduleItem>(this.selectableScheduleItems, searchTerm, undefined, mobilePrefixes, additional);

        this.filteredScheduleItems = filteredScheduleItems
            .filter(s => this.includeFutureJobs !== false || s.date < tomorrow)
            .sort(sortByDateAsc);
    }

    protected delegateOk = () => {
        this.ok(this.getSelectedOccurrences());
    };

    private getSelectedOccurrences(): Array<ScheduleItem> {
        const selectedScheduleItems = <Array<ScheduleItem>>(
            this.selectableScheduleItems.filter(selectableOccurrence => selectableOccurrence.isSelected === true)
        );
        //Also include any prev selected invoices that do not appear in the current occurrences
        for (let i = 0; i < this.currentScheduleItems.length; i++) {
            if (!this.scheduleItems.find(scheduleItem => scheduleItem.occurrence._id === this.currentScheduleItems[i].occurrence._id)) {
                selectedScheduleItems.push(this.currentScheduleItems[i]);
            }
        }
        return selectedScheduleItems;
    }

    protected selectItem(item: ISelectableScheduleItem, event: Event) {
        if (item.notSelectable) return;

        item.isSelected = !item.isSelected;

        if (event) event.stopPropagation();
    }

    public async getResult() {
        return this.getSelectedOccurrences();
    }
}
