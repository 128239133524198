import moment from 'moment';
export class MillisecondsToDateAndTimeValueConverter {
    toView(milliseconds: number, format = 'YYYY-MM-DD HH:mm') {
        return MillisecondsToDateAndTimeValueConverter.getFormattedDateAndTime(milliseconds, format);
    }

    public static getFormattedDateAndTime(milliseconds: number, format = 'YYYY-MM-DD HH:mm'): string {
        if (milliseconds > 0) return moment.unix(milliseconds / 1000).format(format);
        else return moment(0).format(format);
    }
}
