import type { Subscription } from './SqueegeeEventAggregator';
import { SqueegeeEventAggregator } from './SqueegeeEventAggregator';

export class SqueegeeEvent {
    constructor() {
        setTimeout(() => SqueegeeEventAggregator.doPublish(this));
    }
    public static subscribe<TEventType extends SqueegeeEvent>(callback: (event: TEventType) => void): Subscription {
        return SqueegeeEventAggregator.doSubscribe(this, callback);
    }
    public static subscribeOnce<TEventType extends SqueegeeEvent>(callback: (event: TEventType) => void): Subscription {
        return SqueegeeEventAggregator.doSubscribeOnce(this, callback);
    }
}
