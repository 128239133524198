import { CustomDialog } from '../CustomDialog';
import type { DialogSettingsSecondaryView } from '../DialogSettings';

export const enum SelectGridDialogType {
    IMAGE,
    HTML,
}

export class SelectGridDialog<TReturnType> extends CustomDialog<TReturnType> {
    protected selectedItem: TReturnType | undefined;

    constructor(
        public selectTitle: string,
        public items: Array<TReturnType>,
        public type: SelectGridDialogType,
        public currentSelected?: TReturnType,
        settings: DialogSettingsSecondaryView = { isSecondaryView: true }
    ) {
        super(
            'selectGridDialog',
            './SelectGrid/SelectGridDialog.html',
            '',
            Object.assign({ cssClass: 'select-grid-dialog', okLabel: '', cancelLabel: '', isSecondaryView: true }, settings)
        );

        this.selectedItem = currentSelected;
    }

    public select(item: TReturnType) {
        this.selectedItem = item;
        this.ok(item);
    }

    public async getResult() {
        return this.selectedItem;
    }
}
