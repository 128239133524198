import type { IJob, Service } from '@nexdynamic/squeegee-common';
import { TagType } from '@nexdynamic/squeegee-common';
import { bindable, customElement, inject } from 'aurelia-framework';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { SelectTagsDialog } from '../../Tags/Components/SelectTagsDialog';

@customElement('service-list')
@inject(Element)
export class ServiceList {
    @bindable job: IJob;
    @bindable updated?: () => void;

    protected async addServices() {
        const servicesDialog = new SelectTagsDialog((this.job.services && this.job.services.slice()) || [], TagType.SERVICE, 1);
        const services = (await servicesDialog.show(DialogAnimation.SLIDE_UP)) as Array<Service>;

        if (!servicesDialog.cancelled) {
            if (!this.job.services) this.job.services = [];
            for (const service of services) {
                if (this.job.services.some(s => s._id === service._id)) continue;
                this.job.services.push(service);
            }

            this.job.services = this.job.services.filter(s => services.some(ss => ss._id === s._id));

            this.updated?.();
        }
    }
}
