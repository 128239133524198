import type { AccountUser, JobGroup, Team, TranslationKey } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { Data } from '../../Data/Data';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { Prompt } from '../../Dialogs/Prompt';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { Logger } from '../../Logger';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { SelectUserOrTeamDialog } from '../../Users/SelectUserOrTeamDialog';
import { shiftJobSchedules } from '../shiftJobSchedules';
import { ShiftOrResetScheduleEvent } from './ShiftOrResetScheduleEvent';

export class ShiftJobScheduleDialog extends CustomDialog<void> {
    protected from = moment().format();
    protected amount = 1;
    protected direction = 1;
    protected unit: 'week' | 'day' = 'week';
    protected selectedWorkerOrTeamText = 'Any worker or team';
    protected selectedWorkerOrTeam?: AccountUser | Team;
    protected keepOldScheduleChoice = true;
    protected hasScheduledRounds = Data.exists<JobGroup>('tags', r => !!r.frequencyData || (!!r.schedules && !!r.schedules.length));
    protected workDays = {
        Mon: true,
        Tue: true,
        Wed: true,
        Thu: true,
        Fri: true,
        Sat: false,
        Sun: false,
    };

    constructor() {
        super('shift-job-shedule', '../Jobs/Components/ShiftJobScheduleDialog.html', 'shift-job-schedule.title', {
            okLabel: 'shift-job-schedule.title',
            cancelLabel: 'general.cancel',
            isSecondaryView: true,
            destructive: true,
            subtitle: 'shift-job-schedule.description',
        });
    }

    private getWorkDays() {
        const days: Array<number> = [];
        if (this.workDays.Mon) days.push(1);
        if (this.workDays.Tue) days.push(2);
        if (this.workDays.Wed) days.push(3);
        if (this.workDays.Thu) days.push(4);
        if (this.workDays.Fri) days.push(5);
        if (this.workDays.Sat) days.push(6);
        if (this.workDays.Sun) days.push(7);
        return days;
    }

    protected async selectWorkerOrTeam() {
        if (this.hasScheduledRounds) return;
        const dialog = new SelectUserOrTeamDialog(
            undefined,
            'All Workers' as TranslationKey,
            'Shift schedule of all jobs for all workers' as TranslationKey
        );
        const result = await dialog.show(DialogAnimation.SLIDE_UP);
        const userOrTeam = result as AccountUser | Team;

        if (!dialog.cancelled) {
            this.selectedWorkerOrTeam = (userOrTeam && userOrTeam.resourceType && userOrTeam) || undefined;
            this.selectedWorkerOrTeamText = userOrTeam && userOrTeam.resourceType ? userOrTeam.name : 'Any worker or team';
        }
    }

    public async ok() {
        new LoaderEvent(true);
        try {
            if (this.direction === -1 && moment(this.from).subtract(this.amount, this.unit).isBefore()) {
                new LoaderEvent(false);
                if (
                    !(await new Prompt(
                        'Allow Overdue Jobs?' as TranslationKey,
                        ('The selected from date and the amount of days/weeks chosen could mean that some jobs' +
                            ' are scheduled to be done before today, are you sure you wish to continue?') as TranslationKey,
                        {
                            cancelLabel: 'general.cancel',
                            okLabel: 'continue' as TranslationKey,
                        }
                    ).show())
                ) {
                    return;
                }
            }
            const success = await shiftJobSchedules(
                this.amount * this.direction,
                this.unit,
                this.keepOldScheduleChoice,
                moment(this.from),
                this.getWorkDays(),
                this.selectedWorkerOrTeam
            );
            if (success) {
                new ShiftOrResetScheduleEvent();
                super.ok();
            } else {
                throw 'shift schedule on JobService returned false';
            }
        } catch (error) {
            new LoaderEvent(false);
            new NotifyUserMessage('notification.failed-to-shift-schedule');
            Logger.error(`Error during ok in shift job schedule dialog`, error);
        }
    }

    protected selectUnit(unit: 'week' | 'day') {
        this.unit = unit;
    }

    protected selectWorkDay(day: 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat' | 'Sun') {
        this.workDays[day] = !this.workDays[day];
    }

    protected selectOldChoice(choice: boolean) {
        this.keepOldScheduleChoice = choice;
    }

    protected selectDirection(direction: number) {
        this.direction = direction;
    }
}
