import type { PaymentAccount } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';

export class PaymentAccountTagsCustomElement {
    @bindable() paymentAccount: PaymentAccount;

    protected isDevModeEnabled = ApplicationState.stateFlags.devMode;

    @computedFrom('paymentAccount')
    protected get providers() {
        if (!this.paymentAccount?.externalIds) return [];
        if (!Object.keys(this.paymentAccount?.externalIds).length) return [];
        return Object.keys(this.paymentAccount?.externalIds).map((x: string) => {
            const parts = x.split('.');
            return parts[0];
        })
    }
}
