import { CleanerPlannerCommonEntity } from './CleanerPlannerCommonEntity';
export class CleanerPlannerTransaction extends CleanerPlannerCommonEntity {
    /** 0:debit 1:payment 2:expense 3:writeoff(credit) */
    TypeId: string;
    JobId: string;
    /** Entered for payments */
    PaymentReference?: string;
    ExpenseCategoryId: string;
    /** Entered for debits */
    Description?: string;
    /** DD/MM/YYYY HH:mm:ss */
    Date: string;
    InvoiceId: string;
    /** Pre vat amount */
    Amount: string;
    Vat: string;
    /** Total value of transaction including VAT */
    Total: string;
}
