import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { CustomDialog } from './CustomDialog';

export class HtmlDialog extends CustomDialog<string> {
    constructor(
        public dialogTitle: TranslationKey,
        public html: string,
        public raw = false,
        protected closeIcon?: string,
        public done?: () => void,
        public doneText?: TranslationKey
    ) {
        super('htmlDialog', './HtmlDialog.html', ApplicationState.localise(dialogTitle), {
            cssClass: 'text-dialog',
            isSecondaryView: true,
            okLabel: '',
            cancelLabel: '',
        });
    }

    public async init() {
        setTimeout(
            () =>
                <HTMLInputElement>document.getElementById('text-field') &&
                (<HTMLInputElement>document.getElementById('text-field')).focus(),
            200
        );
    }

    public delegateOk = () => this.ok();
}
