import type { PaymentAccount, TranslationKey } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import type { AccountLedgerTypeGroup } from '../../ChartOfAccounts/AccountsLedgerService';
import { AccountsLedgerService } from '../../ChartOfAccounts/AccountsLedgerService';
import { DateTimePicker } from '../../Components/DateTimePicker/DateTimePicker';
import { Data } from '../../Data/Data';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import type { Subscription } from '../../Events/SqueegeeEventAggregator';
export class BalanceSheet {

    protected balances: Partial<Record<string, number>> = {};
    protected balances2: Partial<Record<string, number>> = {};

    protected groupedByType: AccountLedgerTypeGroup;

    private _dataChangedEvent: Subscription;

    protected balanceDate = moment().format();
    protected balanceDate2: string | undefined;

    protected businessName = ApplicationState.account.businessName;

    async loadData() {
        this.groupedByType = AccountsLedgerService.getAccountLedgersGroupedByType(false);
        this.loadBalances();
    }

    async setBalanceDate() {
        const dtp = new DateTimePicker(undefined, this.balanceDate, 'balance-date' as TranslationKey, false);
        dtp.init();
        await dtp.open();
        if (dtp.canceled) return;
        this.balanceDate = dtp.selectedDate;
        this.loadBalances();
    }

    async set2ndBalanceDate() {
        const dtp = new DateTimePicker(undefined, this.balanceDate2, 'balance-date2' as TranslationKey, false);
        dtp.init();
        await dtp.open();
        if (dtp.canceled) return;
        this.balanceDate2 = dtp.selectedDate;
        this.loadBalances();
    }

    async loadBalances() {
        const accounts = Data.all<PaymentAccount>('paymentaccounts').filter(a => !!a.type);
        if (!accounts) return;
        const balancesResp = await AccountsLedgerService.getAccountLedgerBalances(accounts, this.balanceDate, this.balanceDate2);
        this.balances = balancesResp.balances;
        this.balances2 = balancesResp.balances2;
    }
    attached() {
        this._dataChangedEvent = DataRefreshedEvent.subscribe<DataRefreshedEvent>(event => {
            if (event.hasAnyType('paymentaccounts')) return this.loadData();
            if (event.hasAnyType('transactions')) return this.loadBalances();
        });
        this.loadData();
    }
    detached() {
        this._dataChangedEvent && this._dataChangedEvent.dispose();
    }
}
