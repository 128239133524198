import type { StoredObject } from '@nexdynamic/squeegee-common';
import {
    Customer,
    FrequencyType,
    Job,
    JobGroup,
    JobOccurrence,
    JobOccurrenceStatus,
    Location,
    Service,
    Tag,
    TagType,
} from '@nexdynamic/squeegee-common';
import { Data } from '../Data';
import type { SoapyRow } from './SoapyRow';

export const soapyRowToSqueegeeCustomer = (soapyRow: SoapyRow, insertsAndUpdates: Array<StoredObject>): void => {
    const name = soapyRow.customerName;
    const address = new Location(undefined, undefined, '');
    if (soapyRow.customerAddress) {
        const parts = new Array<string>();
        if (soapyRow.customerAddress.flatNumber) parts.push(soapyRow.customerAddress.flatNumber);
        if (soapyRow.customerAddress.streetNumber) parts.push(soapyRow.customerAddress.streetNumber);
        if (soapyRow.customerAddress.streetName) parts.push(soapyRow.customerAddress.streetName);
        if (soapyRow.customerAddress.town) parts.push(soapyRow.customerAddress.town);
        if (soapyRow.customerAddress.postcode) parts.push(soapyRow.customerAddress.postcode);
        address.addressDescription = parts.join(',');
    }
    const phone = soapyRow.customerPhoneNumber;
    const email = soapyRow.customerEmail;
    const customer = new Customer(name, address, phone, email);
    insertsAndUpdates.push(customer);

    customer.notes = soapyRow.customerNotes;

    const date = soapyRow.jobScheduledDate?.format('YYYY-MM-DD') || soapyRow.jobLastDone?.format('YYYY-MM-DD');
    const job = new Job(customer._id, 'jobs', undefined, date, address);
    customer.jobs[job._id] = job;
    for (const description of soapyRow.jobServices?.split(',') || []) {
        let service = Data.firstOrDefault<Service>('tags', s => s.type === TagType.SERVICE && s.description === description);
        if (!service) service = insertsAndUpdates.find(s => s instanceof Service && s.description === description) as Service;
        if (!service) {
            service = new Tag(description, TagType.SERVICE);
            insertsAndUpdates.push(service);
        }
        job.services.push(service);
    }

    for (const description of soapyRow.jobRoundId?.split(',') || []) {
        let round = Data.firstOrDefault<JobGroup>('tags', s => s.type === TagType.ROUND && s.description === description);
        if (!round) round = insertsAndUpdates.find(s => s instanceof JobGroup && s.description === description) as JobGroup;
        if (!round) {
            round = new JobGroup(description);
            insertsAndUpdates.push(round);
        }
        job.rounds.push(round);
    }

    switch (soapyRow.jobFrequency?.unit) {
        case undefined:
            job.frequencyType = FrequencyType.NoneRecurring;
            break;
        case 'Weeks':
            job.frequencyType = FrequencyType.Weeks;
            job.frequencyInterval = soapyRow.jobFrequency?.interval;
            break;
        case 'Months':
            job.frequencyType = FrequencyType.DayOfMonth;
            job.frequencyInterval = soapyRow.jobFrequency?.interval;
            break;
        case 'Years':
            job.frequencyType = FrequencyType.MonthlyDayOfWeek;
            job.frequencyInterval = soapyRow.jobFrequency?.interval;
            break;
    }

    job.price = soapyRow.jobCost;
    job.createdDate = soapyRow.jobCreatedAt?.format('YYYY-MM-DD') || job.createdDate;

    if (soapyRow.jobLastDone) {
        const occurrence = new JobOccurrence(
            job,
            soapyRow.jobLastDone.format('YYYY-MM-DD'),
            JobOccurrenceStatus.Done,
            soapyRow.jobLastDone.format('YYYY-MM-DD')
        );
        occurrence.services = job.services;
        occurrence.rounds = job.rounds;
        occurrence.price = job.price;
        occurrence.paymentStatus = 'unbillable';
        insertsAndUpdates.push(occurrence);
    }
};
