import type {
    Customer,
    CustomerProvider,
    GlobalSettingIds,
    ICustomerPaymentProvidersData,
    TranslationKey
} from '@nexdynamic/squeegee-common';
import { MatchingUtils } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../ApplicationState';
import { CustomerService } from '../../Customers/CustomerService';
import { Data } from '../../Data/Data';
import { CustomDialogBase } from '../../Dialogs/CustomDialogBase';
import { Prompt } from '../../Dialogs/Prompt';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { Logger } from '../../Logger';
import { CustomerManualSyncService } from './CustomerManualSyncService';
import type { ICustomerMatch } from './ICustomerMatch';

type PaymentProvider = keyof ICustomerPaymentProvidersData;
export class CustomerMatchDialog extends CustomDialogBase<Array<ICustomerMatch>> {
    protected matchedItems: Array<ICustomerMatch> = [];
    protected customers: Readonly<Array<Customer>> = [];
    protected totalUnmatched = 0;
    protected totalConfirmed = 0;
    protected totalIgnored = 0;
    protected showUnmatched = true;
    protected showConfirmed = false;
    protected showIgnored = false;
    protected filteredMatchedItems: Array<ICustomerMatch> = [];
    protected ignored: Array<string>;
    constructor(
        public menuTitle: TranslationKey | string,
        protected thirdPartyCustomers: Array<Customer>,
        public provider: CustomerProvider,

        public moreLoader: (starting_after?: string, load?: number) => Promise<Array<Customer> | undefined>
    ) {
        super('customerMatchDialog', '../ConnectedServices/Customers/CustomerMatchDialog.html', '', {
            isSecondaryView: true,
            okLabel: '',
            cancelLabel: '',
            cssClass: 'customer-match-dialog details-dialog no-nav-shadow',
        });
    }

    private ignoreSetting: GlobalSettingIds | undefined;
    // eslint-disable-next-line require-await
    async init() {
        try {
            switch (this.provider) {
                case 'gocardless':
                    this.ignoreSetting = 'global.gocardless.customers.ignore-ids';
                    break;
                case 'stripe':
                    this.ignoreSetting = 'global.stripe.customers.ignore-ids';
                    break;
            }

            this.ignored = (this.ignoreSetting && ApplicationState.getSetting<Array<string>>(this.ignoreSetting)) || [];

            this.customers = CustomerService.getCustomers();
            this.autoMatchCustomers(this.thirdPartyCustomers);
            this.updateTotals();
            this.filter();
        } catch (error) {
            Logger.error(`Error during initialise the customer match dialog`, error);
        }
    }

    async loadMore() {
        new LoaderEvent(true, true, 'loader.loading-customers-from-provider', undefined, { provider: this.provider });
        if (!this.moreLoader) return;
        const lastItem = this.thirdPartyCustomers[this.thirdPartyCustomers.length - 1];

        if (!lastItem) return;
        const lastItemId = lastItem.externalIds?.[this.provider];
        const moreCustomers = await this.moreLoader(lastItemId, 3000);
        if (!moreCustomers) return;
        this.thirdPartyCustomers.push(...moreCustomers);
        this.autoMatchCustomers(moreCustomers);
        this.updateTotals();
        this.filter();
        new LoaderEvent(false);
    }

    protected toggleTab(to: 'unmatched' | 'confirmed' | 'ignored') {
        this.showUnmatched = false;
        this.showConfirmed = false;
        this.showIgnored = false;
        switch (to) {
            case 'unmatched':
                this.showUnmatched = true;
                break;
            case 'confirmed':
                this.showConfirmed = true;
                break;
            case 'ignored':
                this.showIgnored = true;
                break;
        }
        this.filter();
    }

    private filter() {
        this.filteredMatchedItems = this.matchedItems.filter(
            x =>
                x.thirdPartyCustomer &&
                (this.showUnmatched || x.status !== 'unmatched') &&
                (this.showConfirmed || x.status !== 'confirmed') &&
                (this.showIgnored || x.status !== 'ignored')
        );
    }

    private autoMatchCustomers(activeThirdParty: Array<Customer>) {
        this.matchedItems = [];

        const itemsToProcess = activeThirdParty.slice();
        let unmatchedCustomers = this.customers.filter(x => !x.externalIds?.[this.provider]);
        if (!itemsToProcess || !itemsToProcess.length) return;

        // Process ignored and already matched
        for (let i = itemsToProcess.length - 1; i > -1; i--) {
            const thirdPartyCustomer = itemsToProcess[i];

            const thirdPartyCustomerId = thirdPartyCustomer.externalIds?.[this.provider];
            if (!thirdPartyCustomerId) continue;

            const matched = this.findExactMatch(thirdPartyCustomer, this.customers);

            if (matched) {
                this.matchedItems.push(matched);
                continue;
            }

            unmatchedCustomers = this.customers.filter(x => !x.externalIds?.[this.provider]);

            const isIgnored = this.ignored.indexOf(thirdPartyCustomerId) !== -1;

            const fuzzyMatch = this.findFuzzyMatch(thirdPartyCustomer, unmatchedCustomers);
            if (isIgnored) fuzzyMatch.status = 'ignored';
            this.matchedItems.push(fuzzyMatch);
        }
    }

    private findExactMatch(thirdPartyCustomer: Customer, customers: Readonly<Array<Customer>>): ICustomerMatch | undefined {
        try {
            for (const customer of customers) {
                if (
                    customer.externalIds?.[this.provider] &&
                    thirdPartyCustomer.externalIds?.[this.provider] &&
                    customer.externalIds?.[this.provider] === thirdPartyCustomer.externalIds?.[this.provider]
                ) {
                    return { thirdPartyCustomer, squeegeeCustomer: customer, status: 'confirmed', possibleMatches: [] };
                } else if (thirdPartyCustomer._id === customer._id) {
                    return { thirdPartyCustomer, squeegeeCustomer: customer, status: 'confirmed', possibleMatches: [] };
                } else if (
                    customer.paymentProviderMetaData?.[this.provider as PaymentProvider]?.sourceOrMandateId &&
                    thirdPartyCustomer.paymentProviderMetaData?.[this.provider as PaymentProvider]?.sourceOrMandateId &&
                    customer.paymentProviderMetaData?.[this.provider as PaymentProvider]?.sourceOrMandateId ===
                    thirdPartyCustomer.paymentProviderMetaData?.[this.provider as PaymentProvider]?.sourceOrMandateId
                ) {
                    return { thirdPartyCustomer, squeegeeCustomer: customer, status: 'confirmed', possibleMatches: [] };
                }
            }
        } catch (error) {
            Logger.error(`Error during find match in the customer match dialog`, error);
            return { thirdPartyCustomer, squeegeeCustomer: undefined, status: 'unmatched', possibleMatches: [] };
        }
    }
    private findFuzzyMatch(thirdPartyCustomer: Customer, customers: Array<Customer>): ICustomerMatch {
        try {
            const possibleMatches: Array<Customer> = [];

            for (const customer of customers) {
                if (
                    thirdPartyCustomer.email &&
                    typeof thirdPartyCustomer.email === 'string' &&
                    thirdPartyCustomer.email.trim() &&
                    customer.email &&
                    thirdPartyCustomer.email.toLowerCase() === customer.email.toLowerCase()
                ) {
                    possibleMatches.push(customer);

                    //Try matching based on phone
                } else if (
                    thirdPartyCustomer.telephoneNumber &&
                    typeof thirdPartyCustomer.telephoneNumber === 'string' &&
                    thirdPartyCustomer.telephoneNumber.trim() &&
                    customer.telephoneNumber &&
                    thirdPartyCustomer.telephoneNumber === customer.telephoneNumber.trim()
                ) {
                    possibleMatches.push(customer);

                    //Try matching based on other phone
                } else if (
                    thirdPartyCustomer.telephoneNumber &&
                    typeof thirdPartyCustomer.telephoneNumber === 'string' &&
                    thirdPartyCustomer.telephoneNumber.trim() &&
                    customer.telephoneNumberOther &&
                    thirdPartyCustomer.telephoneNumber === customer.telephoneNumberOther.trim()
                ) {
                    possibleMatches.push(customer);
                    //Try matching based on address
                } else if (
                    thirdPartyCustomer.address &&
                    typeof thirdPartyCustomer.address.addressDescription === 'string' &&
                    thirdPartyCustomer.address.addressDescription.trim() &&
                    customer.address &&
                    customer.address.addressDescription &&
                    customer.address.addressDescription
                        .trim()
                        .toLowerCase()
                        .startsWith(thirdPartyCustomer.address?.addressDescription.trim().toLowerCase())
                ) {
                    possibleMatches.push(customer);
                } else if (MatchingUtils.termCompare(customer.name, thirdPartyCustomer.name) > 0.9) {
                    possibleMatches.push(customer);
                }
            }

            return { thirdPartyCustomer, squeegeeCustomer: undefined, status: 'unmatched', possibleMatches };
        } catch (error) {
            Logger.error(`Error during find match in the customer match dialog`, error);
            return { thirdPartyCustomer, squeegeeCustomer: undefined, status: 'unmatched', possibleMatches: [] };
        }
    }

    public async createCustomer(matchedItem: ICustomerMatch) {
        if (!matchedItem.thirdPartyCustomer) return undefined;
        await Data.put(matchedItem.thirdPartyCustomer);
        this.matchCustomer(matchedItem, true);
    }

    public async assignCustomer(matchedItem: ICustomerMatch, selectedCustomer?: Customer) {
        if (!matchedItem.thirdPartyCustomer) return;

        if (!selectedCustomer) {
            selectedCustomer = await CustomerManualSyncService.findOrCreateCustomerFromProvider(matchedItem.thirdPartyCustomer);
        }
        if (!selectedCustomer) return;
        matchedItem.squeegeeCustomer = selectedCustomer;

        this.matchCustomer(matchedItem, this.provider === 'gocardless');
        this.restoreIgnoredCustomer(matchedItem);

    }

    public removeAlreadyAssigned(newAssignment: Customer) {
        for (let i = 0; i < this.matchedItems.length; i++) {
            const item = this.matchedItems[i];
            if (item.squeegeeCustomer && item.squeegeeCustomer._id === newAssignment._id) {
                item.squeegeeCustomer = undefined;
                item.status = 'unmatched';
            }
        }
    }

    public async removeCustomer(matchedItem: ICustomerMatch) {
        this.restoreIgnoredCustomer(matchedItem);

        matchedItem.squeegeeCustomer = undefined;
        matchedItem.status = 'unmatched';
        if (!this.customers) return;
        await CustomerService.applyMatchedMandatesToCustomers([matchedItem], this.customers, false, this.provider);
        this.updateTotals();
        this.filter();
    }

    public async matchCustomer(matchedItem: ICustomerMatch, copyContactDetails: boolean) {
        matchedItem.status = 'confirmed';

        if (!this.customers) return;

        await CustomerService.applyMatchedMandatesToCustomers([matchedItem], this.customers, copyContactDetails, this.provider);
        this.updateTotals();
        this.filter();
    }

    public ignoreCustomer(matchedItem: ICustomerMatch) {
        matchedItem.status = 'ignored';
        this.updateTotals();
        this.filter();

        if (this.provider !== 'stripe' && this.provider !== 'gocardless') return;

        const id = matchedItem.thirdPartyCustomer?.externalIds?.[this.provider];
        if (!id) return;

        const settingId: GlobalSettingIds =
            this.provider === 'gocardless' ? 'global.gocardless.customers.ignore-ids' : 'global.stripe.customers.ignore-ids';

        const ignoredIds = ApplicationState.getSetting<Array<string>>(settingId, []);

        if (ignoredIds.indexOf(id) > -1) return;

        ignoredIds.push(id);
        ApplicationState.setSetting(settingId, ignoredIds);
    }

    public restoreIgnoredCustomer(matchedItem: ICustomerMatch) {
        const id = matchedItem.thirdPartyCustomer?.externalIds?.[this.provider];
        if (!id) return;
        const settingId: GlobalSettingIds =
            this.provider === 'gocardless' ? 'global.gocardless.customers.ignore-ids' : 'global.stripe.customers.ignore-ids';
        const ignoredIds = ApplicationState.getSetting<Array<string>>(settingId, []);

        if (!ignoredIds.includes(id)) return;

        ApplicationState.setSetting(settingId, ignoredIds.filter(x => x !== id));
    }

    // eslint-disable-next-line require-await
    public async getResult() {
        return this.matchedItems;
    }

    public delegateImport = () => this.import();
    private async import() {
        this.ignored = this.matchedItems
            .filter(x => x.status === 'ignored')
            .map(x => (x.thirdPartyCustomer && x.thirdPartyCustomer.externalIds?.[this.provider]) as string)
            .filter(Boolean);
        if (this.totalUnmatched > 0) {
            const dialog = new Prompt('general.confirm', 'prompts.confirm-customer-matches', {
                okLabel: 'general.confirm',
                cancelLabel: 'general.cancel',
            });
            await dialog.show();
            if (!dialog.cancelled) {
                if (this.ignoreSetting) ApplicationState.setSetting(this.ignoreSetting, this.ignored);
                this.ok();
            }
        } else {
            if (this.ignoreSetting) ApplicationState.setSetting(this.ignoreSetting, this.ignored);
            this.ok();
        }
    }

    private updateTotals() {
        this.totalUnmatched = 0;
        this.totalConfirmed = 0;
        this.totalIgnored = 0;

        for (const match of this.matchedItems) {
            if (!match.thirdPartyCustomer) continue;

            if (match.status === 'confirmed') this.totalConfirmed++;
            else if (match.status === 'ignored') this.totalIgnored++;
            else this.totalUnmatched++;
        }
        this.matchedItems.sort((x, y) => {
            const xName = x.thirdPartyCustomer?.name || x.squeegeeCustomer?.name || '';
            const yName = y.thirdPartyCustomer?.name || y.squeegeeCustomer?.name || '';
            return xName > yName ? 1 : xName < yName ? -1 : 0;
        });
    }
}
