// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#custom-dialog .time-dialog {
  padding: 0;
  max-height: 264px;
}
#custom-dialog .time-dialog .squeegee-dialog-content {
  padding: 0;
  min-height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Dialogs/TimeOrDuration/TimeDialog.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,iBAAA;AACJ;AAAI;EACI,UAAA;EACA,gBAAA;AAER","sourcesContent":["#custom-dialog .time-dialog {\n    padding: 0;\n    max-height: 264px;\n    .squeegee-dialog-content {\n        padding: 0;\n        min-height: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
