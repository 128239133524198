import type {
    AutomaticPaymentTransactionSubType,
    Customer,
    InvoiceTransactionSubType,
    PaymentTransactionSubType,
    RefundPaymentTransactionSubType,
} from '@nexdynamic/squeegee-common';
import { PaymentMethod, TransactionType } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { isDevMode } from '../isDevMode';
import type { IPaymentMethodDefinition } from './IPaymentMethodDefinition';
import { enableFakeSquarePOS } from './Square/enableFakeSquarePOS';

export type AllowedPaymentTypes = Array<
    PaymentTransactionSubType | AutomaticPaymentTransactionSubType | InvoiceTransactionSubType | RefundPaymentTransactionSubType
>;

export const DefaultWorkerPaymentMethods: AllowedPaymentTypes = [
    'payment.card',
    'payment.cash',
    'payment.cheque',
    'payment.other',
    'payment.tip.cash',
    'payment.tip.card',
    'payment.tip.cheque',
    'payment.tip.other',
    'auto.go-cardless',
    'auto.stripe',
    'payment.stripe',
];

export function getPaymentMethods(
    isNotCreatorAdminOrOwner: boolean,
    customer?: Customer,
    ignoreMobile = false
): Array<IPaymentMethodDefinition> {
    const methods: Array<IPaymentMethodDefinition> = [
        {
            id: 'auto.go-cardless',
            method: PaymentMethod.directDebit,
            provider: 'gocardless',
            tranType: TransactionType.AutomaticPayment,
            icon: `<img
            style="width: auto; height: 32px;"
            src="./images/provider-gocardless.png"
            alt="gocardless"/>`,
            name: `GoCardless Payment`,
            class: 'bg-red',
            type: 'Charge',
            unavailable: getGoCardlessErrorReason(customer),
        },
        {
            id: 'payment.stripe',
            method: PaymentMethod.card,
            provider: 'stripe',
            tranType: TransactionType.Payment,
            icon: `<img
            style="width: auto; height: 32px;"
            src="./images/provider-stripe.png"
            alt="stripe"/>`,
            name: 'Stripe Card Payment',
            class: 'bg-gray',
            type: 'Charge',
            unavailable: getStripeErrorReason(customer),
        },
        {
            id: 'payment.cash',
            method: PaymentMethod.cash,
            tranType: TransactionType.Payment,
            icon: `<span style="font-weight: 800; font-size: 18px; line-height: 32px;">${ApplicationState.currencySymbol()}</span>`,
            name: 'Cash Received',
            class: 'bg-blue',
            type: 'Record',
            unavailable: false,
        },
        {
            id: 'payment.cheque',
            method: PaymentMethod.cheque,
            tranType: TransactionType.Payment,
            icon: `<i class="material-icons">aspect_ratio</i>`,
            name: 'Cheque',
            class: 'bg-blue',
            type: 'Record',
            unavailable: false,
        },
        {
            id: 'payment.bank-transfer',
            method: PaymentMethod.bankTransfer,
            tranType: TransactionType.Payment,
            icon: `<i class="material-icons">account_balance</i>`,
            name: 'Bank Transfer',
            class: 'bg-blue',
            type: 'Record',
            unavailable: false,
        },
        {
            id: 'payment.card',
            method: PaymentMethod.card,
            tranType: TransactionType.Payment,
            icon: `<i class="material-icons">credit_card</i>`,
            name: 'Manual Card Payment',
            class: 'bg-blue',
            type: 'Record',
            unavailable: false,
        },
    ];

    // Add in the custom payment methods.
    const customPaymentMethods = ApplicationState.getSetting('global.custom-payment-methods', {} as Record<string, string>);
    for (const key in customPaymentMethods) {
        methods.push({
            id: key as PaymentTransactionSubType,
            method: PaymentMethod.custom,
            tranType: TransactionType.Payment,
            icon: `<i class="material-icons">grade</i>`,
            name: customPaymentMethods[key],
            class: 'bg-green',
            type: 'Record',
            unavailable: false,
        });
    }

    methods.push(
        {
            id: 'payment.other',
            method: PaymentMethod.other,
            tranType: TransactionType.Payment,
            icon: `<i class="material-icons">radio_button_checked</i>`,
            name: `Other Payment`,
            class: 'bg-blue',
            type: 'Record',
            unavailable: false,
        },
        {
            id: 'invoice.credit-note',
            method: PaymentMethod.credit,
            tranType: TransactionType.Invoice,
            icon: '<i class="material-icons">card_giftcard</i>',
            name: `Credit Note`,
            class: 'bg-blue',
            type: 'Credit',
            unavailable: false,
        },
        {
            id: 'invoice.write-off',
            method: PaymentMethod.writeOff,
            tranType: TransactionType.Invoice,
            icon: '<i class="material-icons">cancel</i>',
            name: `Write Off`,
            class: 'bg-blue',
            type: 'Credit',
            unavailable: false,
        },
        {
            id: 'payment.tip.cash',
            method: PaymentMethod.tip,
            tranType: TransactionType.Payment,
            icon: `<span style="font-weight: 800; font-size: 18px; line-height: 32px;">${ApplicationState.currencySymbol()}</span>`,
            name: 'Cash Tip',
            class: 'bg-blue',
            type: 'Tips',
            unavailable: false,
        },
        {
            id: 'payment.tip.cheque',
            method: PaymentMethod.tip,
            tranType: TransactionType.Payment,
            icon: '<i class="material-icons">aspect_ratio</i>',
            name: 'Tip by Cheque',
            class: 'bg-blue',
            type: 'Tips',
            unavailable: false,
        },
        {
            id: 'payment.tip.bank-transfer',
            method: PaymentMethod.tip,
            tranType: TransactionType.Payment,
            icon: '<i class="material-icons">account_balance</i>',
            name: 'Tip by Bank Transfer',
            class: 'bg-blue',
            type: 'Tips',
            unavailable: false,
        },
        {
            id: 'payment.tip.card',
            method: PaymentMethod.tip,
            tranType: TransactionType.Payment,
            icon: `<i class="material-icons">credit_card</i>`,
            name: 'Tip by Manual Card',
            class: 'bg-blue',
            type: 'Tips',
            unavailable: false,
        },
        {
            id: 'payment.tip.other',
            method: PaymentMethod.tip,
            tranType: TransactionType.Payment,
            icon: `<i class="material-icons">radio_button_checked</i>`,
            name: `Other Tip Payment`,
            class: 'bg-blue',
            type: 'Tips',
            unavailable: false,
        }
    );

    if (isDevMode()) enableFakeSquarePOS();

    if ((window.squarePOS || ignoreMobile) && ApplicationState.features.SquarePayments) {
        methods.push({
            id: 'payment.square',
            method: PaymentMethod.card,
            provider: 'square',
            tranType: TransactionType.Payment,
            icon: `<img
            style="width: auto; height: 32px"
            src="./images/provider-square.png"
            alt="square"/>`,
            name: window.squarePOS?.isFake ? 'Square POS Payment (Fake)' : 'Square POS Payment',
            class: 'bg-gray',
            type: 'Charge',
            unavailable: false,
        });
    }

    const allowedMethods: AllowedPaymentTypes = ApplicationState.getSetting(
        'global.allowed-worker-planner-payment-methods',
        DefaultWorkerPaymentMethods
    );

    if (!isNotCreatorAdminOrOwner || !allowedMethods) return methods;

    return methods.filter(m => allowedMethods.some(a => a === m.id));
}

export function getRefundMethods(customer: Customer): Array<IPaymentMethodDefinition> {
    return [
        {
            id: 'refund.cash',
            method: PaymentMethod.cash,
            tranType: TransactionType.Payment,
            icon: `<i class="material-icons">account_balance</i>`,
            name: `Refunded in Cash`,
            class: 'bg-blue',
            type: 'Refund',
            unavailable: false,
        },
        {
            id: 'refund.cheque',
            method: PaymentMethod.cheque,
            tranType: TransactionType.Payment,
            icon: `<i class="material-icons">account_balance</i>`,
            name: `Refunded by Cheque`,
            class: 'bg-blue',
            type: 'Refund',
            unavailable: false,
        },
        {
            id: 'refund.bank-transfer',
            method: PaymentMethod.bankTransfer,
            tranType: TransactionType.Payment,
            icon: `<i class="material-icons">account_balance</i>`,
            name: `Refunded by Bank Transfer`,
            class: 'bg-blue',
            type: 'Refund',
            unavailable: false,
        },
        {
            id: 'refund.card',
            method: PaymentMethod.card,
            tranType: TransactionType.Payment,
            icon: `<i class="material-icons">credit_card</i>`,
            name: `Refunded by Card`,
            class: 'bg-blue',
            type: 'Refund',
            unavailable: false,
        },
        {
            id: 'refund.direct-debit',
            method: PaymentMethod.directDebit,
            tranType: TransactionType.Payment,
            icon: `<i class="material-icons">account_balance</i>`,
            name: `Refunded by Direct Debit`,
            class: 'bg-blue',
            type: 'Refund',
            unavailable: customer.paymentProviderMetaData?.gocardless?.sourceOrMandateId ? false : 'unavailable',
        },
        {
            id: 'refund.other',
            method: PaymentMethod.other,
            tranType: TransactionType.Payment,
            icon: `<i class="material-icons">account_balance</i>`,
            name: `Other Refund`,
            class: 'bg-blue',
            type: 'Refund',
            unavailable: false,
        },
    ];
}

const getGoCardlessErrorReason = (customer?: Customer) => {
    if (!ApplicationState.account.goCardlessPublishableKey) return ApplicationState.localise('general.unavailable');
    if (!customer?.paymentProviderMetaData?.gocardless?.sourceOrMandateId) {
        if (customer?.paymentProviderMetaData?.gocardless?.inviteNotificationId)
            return ApplicationState.localise('go-cardless.invite-pending');
        return ApplicationState.localise('go-cardless.no-mandate');
    }
    if (!['active', 'pending'].includes(customer?.paymentProviderMetaData?.gocardless?.status || ''))
        return ApplicationState.localise('general.cancelled');

    return false;
};

const getStripeErrorReason = (customer?: Customer) => {
    if (!ApplicationState.account.stripePublishableKey) return ApplicationState.localise('general.unavailable');
    if (!customer?.paymentProviderMetaData?.stripe?.sourceOrMandateId) {
        if (customer?.paymentProviderMetaData?.stripe?.inviteNotificationId)
            return ApplicationState.localise('stripe.payment-form-invited');
        return ApplicationState.localise('stripe.payment-form-no-card-on-file');
    }
    if (!['active'].includes(customer?.paymentProviderMetaData?.stripe?.status || ''))
        return ApplicationState.localise('stripe.payment-form-no-card-on-file');

    return false;
};
