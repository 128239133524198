import { bindable, bindingMode } from 'aurelia-framework';
import { DeselectEvent } from '../../Events/DeselectEvent';
import type { Subscription } from '../../Events/SqueegeeEventAggregator';

export class SearchBar {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) searchValue: string;
    @bindable() searching: (isSearching: boolean) => void;
    @bindable() executeSearch?: (term: string) => void;
    protected showSearchInput: boolean;

    protected searchInput: HTMLInputElement;

    private _deselectEventSubscription: Subscription;

    searchValueChanged(value: string) {
        if (!this.showSearchInput) this.toggleSearch();
        if (this.executeSearch) this.executeSearch(value);
    }

    attached() {
        this._deselectEventSubscription = DeselectEvent.subscribe(() => {
            if ((!this.searchValue || !this.searchValue.length) && this.showSearchInput === true) this.toggleSearch();
        });
    }

    bind() {
        if (this.searchInput && this.searchInput.addEventListener) {
            this.searchInput.addEventListener('blur', this.delegateToggleSearch);
        }
    }

    detached() {
        this._deselectEventSubscription && this._deselectEventSubscription.dispose();
        if (this.searchInput && this.searchInput.removeEventListener) {
            this.searchInput.removeEventListener('blur', this.delegateToggleSearch);
        }
    }

    private delegateToggleSearch = () => {
        if ((!this.searchValue || !this.searchValue.length) && this.showSearchInput === true) this.toggleSearch();
    };

    protected toggleSearch(event?: Event) {
        this.showSearchInput = !this.showSearchInput;
        this.searching && this.searching(this.showSearchInput);

        if (this.showSearchInput === true && this.searchInput) {
            setTimeout(() => this.searchInput.focus(), 350);
        }
        if (event) event.stopPropagation();
    }

    protected blurInput() {
        if (this.searchInput) {
            this.searchInput.blur();
        }
    }

    protected close() {
        this.searchValue = '';
        this.showSearchInput = false;
    }
}
