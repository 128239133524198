import type { Customer } from '@nexdynamic/squeegee-common';
import { FrequencyType, Job, JobGroup, Location, Tag, TagType, WeekOfMonth } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { parse } from 'papaparse';
import { CustomerService } from '../Customers/CustomerService';
import { Logger } from '../Logger';
import type { AworkaRecord } from './AworkaRecord';
export class AworkaParser {
    private static DEFAULT_NAME = 'Unknown';
    private static INPUT_DATE_FORMAT = 'YYYY-MM-DD';
    private static INPUT_DATE_FORMAT_ALT = 'DD/MM/YYYY';
    private static OUTPUT_DATE_FORMAT = 'YYYY-MM-DD';

    public static readonly warnings: Array<string> = [];

    public static parseCSV(csv: string) {
        return parse(csv, { header: true, skipEmptyLines: true });
    }

    public static convertRecord(
        record: AworkaRecord,
        rounds: { [id: string]: JobGroup },
        services: { [id: string]: Tag },
        customers: Array<Customer>
    ) {
        const aworkaCustomerId = AworkaParser.getValue(record, ['Aworka Customer ID']);

        let customer = customers.find(x => x._externalId === aworkaCustomerId);

        if (customer) {
            const job = AworkaParser.getJob(record, customer, rounds, services);
            if (job) customer.jobs[job._id] = job;
        } else {
            customer = CustomerService.create();
            customer._externalId = aworkaCustomerId;

            customer.name = AworkaParser.getName(record);
            customer.address.addressDescription = AworkaParser.getAddressDescription(record);
            customer.notes = (customer.notes || '') + (AworkaParser.getValue(record, ['Customer Notes']) || '');
            const lat = Number(AworkaParser.getValue(record, ['Customer Latitude']) || 0);
            const lng = Number(AworkaParser.getValue(record, ['Customer Longitude']) || 0);
            if (lat && lng) {
                customer.address.lngLat = [lng, lat];
                customer.address.isVerified = true;
            }

            const number1 = AworkaParser.getValue(record, ['Customer Phone 1']);

            const number2 = AworkaParser.getValue(record, ['Customer Phone 2']);

            const number3 = AworkaParser.getValue(record, ['Customer Phone 3']);

            customer.telephoneNumber = number1;
            if (number2 && number3) {
                customer.telephoneNumberOther = `${number2}/${number3}`;
            } else {
                customer.telephoneNumberOther = number2 || number3;
            }

            customer.email = AworkaParser.getValue(record, ['Customer Email']);
            const job = AworkaParser.getJob(record, customer, rounds, services);

            if (job) customer.jobs[job._id] = job;

            customers.push(customer);

            return customer;
        }
    }

    public static getValue(record: AworkaRecord, keys: Array<keyof AworkaRecord>) {
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            (<any>key) = key.trim();
            if (record[key] && record[key].trim()) return record[key].trim();
        }

        return '';
    }

    private static getJob(record: AworkaRecord, customer: Customer, rounds: { [id: string]: JobGroup }, services: { [id: string]: Tag }) {
        const jobAddressDescription = AworkaParser.getAddressDescription(record);
        let address: Location;
        if (customer.address.addressDescription === jobAddressDescription) {
            address = customer.address;
        } else {
            address = new Location(undefined, undefined, jobAddressDescription);
            const lat = Number(AworkaParser.getValue(record, ['Job Latitude']) || 0);
            const lng = Number(AworkaParser.getValue(record, ['Job Longitude']) || 0);
            if (lat && lng) {
                address.lngLat = [lng, lat];
                address.isVerified = true;
            }
        }

        const job = new Job(customer._id, undefined, undefined, undefined, address);

        let price: number | string = AworkaParser.getValue(record, ['Price']).replace(',', '');

        if (isNaN(Number(price))) {
            Logger.warn(`Price '${price}' for ${customer.name} is not valid, has been changed to 0 instead`);
            price = 0;
        }
        const date = AworkaParser.getDate(record);
        if (!date) {
            AworkaParser.warnings.push(`Job for '${customer.name}' did not have a valid next due date`);
            return;
        }

        job.date = date;
        job.description = '';
        job.description += AworkaParser.getValue(record, ['Job Contact Name']) || '';
        job.description += ' ';
        job.description += AworkaParser.getValue(record, ['Allowed Days']) || '';
        job.description += ' ';
        job.description += AworkaParser.getValue(record, ['Job Email']) || '';
        job.description += ' ';
        job.description += AworkaParser.getValue(record, ['Job Company']) || '';
        job.description += ' ';
        job.description += AworkaParser.getValue(record, ['Description']) || '';
        job.description += ' ';
        job.description += AworkaParser.getValue(record, ['Notes']) || '';
        job.description = job.description.trim();
        job.price = Number(price);
        if (record.Round) job.rounds = AworkaParser.getRounds(record, rounds);
        job.services = AworkaParser.getService(record, services);
        AworkaParser.setFrequencyType(AworkaParser.getValue(record, ['Frequency']), job);

        const order = Number(AworkaParser.getValue(record, ['Round Index']));
        if (!isNaN(order)) job.plannedOrder = order;

        const aworkaJobId = AworkaParser.getValue(record, ['Aworka Job ID']);
        if (aworkaJobId) job._externalId = aworkaJobId;

        return job;
    }

    private static setFrequencyType(frequency: string, job: Job) {
        const monthlyOrWeekly = frequency.match(/^(\d*?)?\s+([a-z]+)/i);
        const monthlyDayOfWeek = frequency.match(/(\d)\s+(\w+)\s+(\d)(?:[a-z]{2})\s+(\w+)/i);
        const onceMonthly = frequency.match(/^(\d)(?:[a-z]{2})\s+(\w+)$/i);

        const isYearly = frequency.toLowerCase().trim() === 'yearly';

        if (monthlyOrWeekly) {
            job.frequencyInterval = Number(monthlyOrWeekly[1].trim());
            job.frequencyType = monthlyOrWeekly[2].toLowerCase().trim() === 'weekly' ? FrequencyType.Weeks : FrequencyType.DayOfMonth;
        } else if (onceMonthly) {
            job.frequencyInterval = 1;
            job.frequencyType = FrequencyType.MonthlyDayOfWeek;
            job.frequencyWeekOfMonth = AworkaParser.getWeekOfMonth(onceMonthly[1].trim());
        } else if (monthlyDayOfWeek) {
            job.frequencyInterval = Number(monthlyDayOfWeek[1].trim());
            job.frequencyType = FrequencyType.MonthlyDayOfWeek;
            job.frequencyWeekOfMonth = AworkaParser.getWeekOfMonth(monthlyDayOfWeek[3].trim());
        } else if (isYearly) {
            job.frequencyInterval = 12;
            job.frequencyType = FrequencyType.DayOfMonth;
        } else {
            job.frequencyInterval = 0;
            job.frequencyType = FrequencyType.NoneRecurring;
        }
    }

    private static getWeekOfMonth(weekIndex: string) {
        switch (weekIndex) {
            case '1':
                return WeekOfMonth.First;
            case '2':
                return WeekOfMonth.Second;
            case '3':
                return WeekOfMonth.Third;
            case '4':
                return WeekOfMonth.Fourth;
        }
    }

    private static getDate(record: AworkaRecord) {
        const dateString = AworkaParser.getValue(record, ['Next Due']);
        let date = moment(dateString, AworkaParser.INPUT_DATE_FORMAT);

        if (!date.isValid() || date.format(AworkaParser.INPUT_DATE_FORMAT) !== dateString) {
            date = moment(dateString, AworkaParser.INPUT_DATE_FORMAT_ALT);
            if (!date.isValid() || date.format(AworkaParser.INPUT_DATE_FORMAT_ALT) !== dateString) {
                return void Logger.error(
                    `${dateString} is in not in the format '${AworkaParser.INPUT_DATE_FORMAT}' or ${AworkaParser.INPUT_DATE_FORMAT_ALT}`
                );
            }
        }

        return date.format(AworkaParser.OUTPUT_DATE_FORMAT);
    }

    private static getRounds(record: AworkaRecord, rounds: { [id: string]: JobGroup }): Array<JobGroup> {
        const newRounds: Array<JobGroup> = [];
        const roundName = AworkaParser.getValue(record, ['Round']);
        if (rounds[roundName]) {
            newRounds.push(rounds[roundName]);
        } else {
            const tag = new JobGroup(roundName);
            newRounds.push(tag);
            rounds[roundName] = tag;
        }

        return newRounds;
    }

    private static getService(record: AworkaRecord, services: { [id: string]: Tag }) {
        const newTagDescription = AworkaParser.getValue(record, ['Task']);

        if (!newTagDescription) return [services['Default Service']];

        if (services[newTagDescription] === undefined) {
            const newService = new Tag(newTagDescription, TagType.SERVICE);
            services[newService.description] = newService;
            return [newService];
        } else {
            return [services[newTagDescription]];
        }
    }

    private static getAddressDescription(record: AworkaRecord) {
        const description = AworkaParser.getValue(record, ['Job Address', 'Customer Address']);
        const postcode = AworkaParser.getValue(record, ['Customer Postcode']);
        return `${description ? description + ' ' : ''}${postcode ? postcode : ''}`.trim() || AworkaParser.DEFAULT_NAME;
    }

    private static getName(record: AworkaRecord) {
        const name = AworkaParser.getValue(record, ['Job Contact Name', 'Customer Name']);
        const company = AworkaParser.getValue(record, ['Customer Company']);
        return `${name ? name + ' ' : ''}${company ? (name ? '| ' + company : company) : ''}`.trim() || AworkaParser.DEFAULT_NAME;
    }
}
