import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { CustomDialog } from '../Dialogs/CustomDialog';
import { Api } from '../Server/Api';
import type { IConnectedServiceInfo } from './IConnectedServiceInfo';

export class ConnectedServiceConnect extends CustomDialog<void> {
    protected isConnected: boolean = Api.isConnected;
    // protected syncOutData: ConnectedServiceSyncTypes | undefined = {};
    constructor(protected providerData: IConnectedServiceInfo, protected reauthMessage?: TranslationKey) {
        super('connectedServiceConnectDialog', '../ConnectedServices/ConnectedServiceConnect.html', '', {
            okLabel: '',
            cssClass: 'payment-providers-dialog',
            coverViewport: true,
        });
    }
}
