import type { JobOccurrenceStatus, LngLat, TranslationKey } from '@nexdynamic/squeegee-common';
import { bindable, bindingMode, computedFrom } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { ActionBarEvent } from '../../Events/ActionBarEvent';
import { LocationUtilities } from '../../Location/LocationUtilities';
import { Logger } from '../../Logger';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { ScheduleDetailsDialog } from '../../Schedule/Components/ScheduleDetailsDialog';
import type { ScheduleItem } from '../../Schedule/Components/ScheduleItem';
import { ScheduleService } from '../../Schedule/ScheduleService';

export class DoneDayPilotItemsCustomElement {
    @bindable() scheduleItems: Array<ScheduleItem>;
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    status: JobOccurrenceStatus;
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    groupText: string;
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    disableMultiSelect = false;
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    suppressDistance = false;
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    suppressTime = false;
    @bindable() searchText: string;
    @bindable() showGroupPrice = true;

    public selectableMode: boolean;
    public totalPrice: string;
    public totalDistance: string;
    public totalTime: string;
    public searchOptions: Array<keyof ScheduleItem> = ['customerName', 'addressDescription'];

    @computedFrom('scheduleItems.length')
    public get filteredItems(): Array<ScheduleItem> {
        const items = this.scheduleItems.filter(item => {
            let include = true;
            if (this.status !== undefined) include = item.status === this.status;
            return include;
        });
        this.iterateOverItems(items);
        return items;
    }

    public async viewScheduleItem(scheduleItem: ScheduleItem, event: Event) {
        if (scheduleItem.disabled) return event && event.stopPropagation();

        if (this.selectableMode) return this.selectItem(scheduleItem, event);
        try {
            const dialog = new ScheduleDetailsDialog(scheduleItem);
            await dialog.show(DialogAnimation.SLIDE);
        } catch (error) {
            Logger.error(`Error during viewScheduleItem in the day pilot completed list`, error);
        }

        if (event) event.stopPropagation();
    }

    protected async executePrimary(target: ScheduleItem): Promise<ScheduleItem | null> {
        if (!target.scheduleItemActions.primaryAction) return Promise.reject(null);
        return await target.scheduleItemActions.primaryAction.handler();
    }

    protected async executeSecondary(target: ScheduleItem): Promise<ScheduleItem | null> {
        if (!target.scheduleItemActions.secondaryAction) return Promise.reject(null);
        return await target.scheduleItemActions.secondaryAction.handler();
    }

    private setSelectableMode() {
        const selectedItems = this.scheduleItems.filter(x => x.selected);
        this.selectableMode = !!selectedItems.length;

        if (this.selectableMode === true) {
            ActionBarEvent.fromSelectedScheduleItems(
                this.unselectAllItems,
                ScheduleService.getBulkActionsForScheduleItem(selectedItems, this.scheduleItems, this.bulkActionWrapper),
                selectedItems
            );
        } else {
            new ActionBarEvent(false);
        }
    }

    protected selectItem(scheduleItem: ScheduleItem, event: Event) {
        if (this.disableMultiSelect === true || scheduleItem.disabled) return;
        if (this.selectableMode) {
            scheduleItem.selected = !scheduleItem.selected;
            this.setSelectableMode();
        } else if (!this.selectableMode) {
            scheduleItem.selected = true;
            this.setSelectableMode();
        }

        if (event) event.stopPropagation();
    }

    protected selectAllItems = (scheduleItems: Array<ScheduleItem>) => {
        scheduleItems.forEach(x => (x.selected = !x.selected));
        this.setSelectableMode();
    };

    protected unselectAllItems = () => {
        this.scheduleItems.forEach(x => (x.selected = false));
        this.setSelectableMode();
    };

    private bulkActionWrapper = async (
        action: (scheduleItems: Array<ScheduleItem>, unselectMethod: () => void) => Promise<any>,
        message: TranslationKey
    ) => {
        const selectedItems = this.filteredItems.filter((scheduleItem: ScheduleItem) => scheduleItem.selected === true);

        if (selectedItems.length) {
            try {
                if (await action(selectedItems, this.unselectAllItems)) {
                    new NotifyUserMessage(message, { count: selectedItems.length.toString() });
                }
            } catch (error) {
                new NotifyUserMessage('notifications.oh-no');
                Logger.error(`Error during execute bulk action wrapper in completed day pilot list`, error);
            }
        }
    };

    public iterateOverItems(items: Array<ScheduleItem>) {
        let totalDistance = 0;
        let totalPrice = 0;
        let totalTime = 0;

        const currentPosition = ApplicationState.position;

        let start: LngLat | undefined;
        let from: LngLat | undefined;

        if (currentPosition) {
            start = [currentPosition.coords.longitude, currentPosition.coords.latitude];
            from = start;
        }

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            totalPrice += items[i].job.price || 0;
            totalTime += this.durationToMinutes(items[i].occurrence.duration);
            if (item.occurrence && item.occurrence.location && item.occurrence.location.lngLat) {
                if (from) {
                    totalDistance += LocationUtilities.getDistanceBetweenPoints(
                        from,
                        item.occurrence.location.lngLat,
                        ApplicationState.distanceUnits
                    );
                }
                from = item.occurrence.location.lngLat;
            }
        }

        if (from && start && from !== start) {
            totalDistance += LocationUtilities.getDistanceBetweenPoints(from, start, ApplicationState.distanceUnits);
        }

        this.totalDistance = totalDistance ? '| ' + totalDistance.toFixed(2) + ' ' + ApplicationState.distanceUnits : '';
        this.totalPrice = totalPrice.toFixed(2).toString();
        if (!totalTime) this.totalTime = '';
        else {
            const hours = Math.floor(totalTime / 60);
            const minutes = totalTime % 60;
            this.totalTime = '| ' + hours.toString() + 'h ' + (minutes ? minutes.toString() + 'm' : '') + ' ';
        }
    }

    private durationToMinutes(duration?: string) {
        if (!duration) return 0;
        try {
            const durationParts = duration.split(':');
            const hours = Number(durationParts[0]);
            const minutes = Number(durationParts[1]);
            if (!isNaN(hours) && !isNaN(minutes)) return minutes + hours * 60;
        } catch (error) {
            return 0;
        }
        return 0;
    }
}
