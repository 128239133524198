import type { StoredObject } from '@nexdynamic/squeegee-common';
import { SqueegeeEvent } from './SqueegeeEvent';
import type { Subscription } from './SqueegeeEventAggregator';

export class NewDataEvent extends SqueegeeEvent {
    constructor(public storedObject: StoredObject) {
        super();
    }
}

export type { Subscription };
