import type { Customer } from '@nexdynamic/squeegee-common';
import { JobOccurrence } from '@nexdynamic/squeegee-common';
import { Data } from '../../Data/Data';
import { TimerService } from '../../Tracking/TimerService';
import { UserService } from '../../Users/UserService';

export const requestStartTimer = async (options: { customerId?: string; jobId?: string; occurrenceId?: string; isoDueDate: string }) => {
    const { customerId, jobId, occurrenceId, isoDueDate } = options;
    if (!customerId || !jobId || !occurrenceId || !isoDueDate) return;

    const activeTimer = TimerService.getTimerRunningForCurrentUser();
    if (activeTimer && activeTimer?.occurrenceId !== occurrenceId) return; //view to start timer won't be available if a timer is already running

    let occurrence = Data.get<JobOccurrence>(occurrenceId);
    if (!occurrence) {
        const customer = Data.get<Customer>(customerId);
        if (!customer) return;

        const job = customer.jobs?.[jobId];
        if (!job) return;

        occurrence = new JobOccurrence(job, isoDueDate);
        if (!occurrence) return;
    }

    const assignee = UserService.getUser();
    if (!assignee) return;

    TimerService.startTimer(occurrence, assignee);
};
