import { customAttribute, bindingMode, DOM, inject } from 'aurelia-framework';

@customAttribute('number-value', bindingMode.twoWay)
@inject(DOM.Element)
export class NumberValue {
    protected value: number;

    public static getNumber(value: string) {
        const number = parseFloat(value);
        return !isNaN(number) && isFinite(number) ? number : NaN;
    }

    constructor(public input: HTMLInputElement) {}

    setValue = () => {
        // blank input maps to null value
        if (this.input.value === '') {
            this.value = 0;
            return;
        }
        const number = NumberValue.getNumber(this.input.value);
        if (!isNaN(number)) {
            this.value = Number(number);
        }
    };

    bind() {
        this.input.value = (Number(this.value) || 0).toFixed(2);
        this.input.addEventListener('change', this.setValue);
        this.input.addEventListener('input', this.setValue);
    }

    unbind() {
        this.input.removeEventListener('change', this.setValue);
        this.input.removeEventListener('input', this.setValue);
    }
}
