import type { StoredObject, Transaction } from '@nexdynamic/squeegee-common';
import { Api } from '../Server/Api';
import { Data } from './Data';

/**
 *
 * @param objectToUpdate the object being saved
     * @returns void or errors if the object is not allowed to be updated
     * @description Don't allow existing transactions (have timestamp) to be updated if they are awaitingApiProcessing
 */

export const hasTransactionUpdatesAwaitingApiProcessing = (objectToUpdate: StoredObject): boolean => {
    if (Api.getQueueItem(objectToUpdate._id))
        return false;

    if (objectToUpdate.resourceType !== 'transactions')
        return false;

    if (Data.isNew(objectToUpdate))
        return false;

    if (!Data.get(objectToUpdate._id))
        return false;

    const transactionStatus = (objectToUpdate as StoredObject as Transaction).status;
    if (transactionStatus !== 'awaitingApiHandling' && transactionStatus !== 'apiHandlingInProgress')
        return false;

    return true;
};
