import type { Customer } from '@nexdynamic/squeegee-common';
import { computedFrom } from 'aurelia-framework';
import type { Router } from 'aurelia-router';
import type * as Chartist from 'chartist';
import { ApplicationState } from '../../ApplicationState';
import { CustomerDialog } from '../../Customers/Components/CustomerDialog';
import { CustomerService } from '../../Customers/CustomerService';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import { LoaderEvent } from '../../Events/LoaderEvent';
import type { Subscription } from '../../Events/SqueegeeEventAggregator';
import { ViewResizeEvent } from '../../Events/ViewResizeEvent';
import { TransactionService } from '../../Transactions/TransactionService';

export class Customers {
    protected router: Router;
    public activate(_params: any, routeConfig: any) {
        this.router = routeConfig.navModel.router;
    }
    protected isOwnerorAdmin = ApplicationState.isInAnyRole(['Owner', 'Admin']);
    protected loading = true;
    protected count: number;
    protected customersNoActiveJobsCount: number;
    protected totalOwed: number;
    protected owingCount: number;
    protected creditCount: number;
    protected creditAmount: number;
    protected balancedCount: number;

    protected customerSeries: Array<Chartist.IChartistSeriesData>;

    private _dataRefreshedSub: Subscription;

    public created() {
        new LoaderEvent(true);
    }

    public attached() {
        this.loadData();
        this._dataRefreshedSub = DataRefreshedEvent.subscribe(
            async (event: DataRefreshedEvent) => event.hasAnyType('customers', 'transactions') && this.loadData()
        );
        new LoaderEvent(false);
        this.loading = false;
        setTimeout(() => new ViewResizeEvent(), 150);
    }

    public detached() {
        this._dataRefreshedSub.dispose();
    }

    private bindChartData() {
        this.customerSeries = [
            {
                value: this.owingCount,
                className: 'owing',
            },
            {
                value: this.balancedCount,
                className: 'balanced',
            },
            {
                value: this.creditCount,
                className: 'in-credit',
            },
        ];
    }

    private _owing: Array<{ customer: Customer; balance: number }> = [];
    @computedFrom('_owing.length')
    protected get customersOwing() {
        return this._owing
            .sort((a, b) => {
                return b.balance - a.balance;
            })
            .slice(0, 3);
    }

    private _inCredit: Array<{ customer: Customer; balance: number }> = [];
    @computedFrom('_inCredit.length')
    protected get customersInCredit() {
        return this._inCredit
            .sort((a, b) => {
                return b.balance - a.balance;
            })
            .slice(0, 3);
    }

    private _noActiveJobs: Array<{ customer: Customer }> = [];
    @computedFrom('_noActiveJobs.length')
    protected get customersNoActiveJobs() {
        return this._noActiveJobs;
    }

    private loadData() {
        const customers = CustomerService.getCustomers() || [];
        this.count = customers.length;
        this.customersNoActiveJobsCount = 0;
        const owing: Array<{ customer: Customer; balance: number }> = [];
        const inCredit: Array<{ customer: Customer; balance: number }> = [];
        const noActiveJobs: Array<{ customer: Customer }> = [];
        this.owingCount = 0;
        this.creditCount = 0;
        this.totalOwed = 0;
        this.balancedCount = 0;
        let totalInCredit = 0;
        const today = new Date().toISOString();

        for (let i = 0; i < customers.length; i++) {
            const customer = customers[i];

            const balance = TransactionService.getCustomerBalance(customer._id, today);
            if (customer.state !== 'inactive' && Object.keys(customer.jobs).length === 0) {
                this.customersNoActiveJobsCount++;
                noActiveJobs.push({ customer: customer });
            }

            if (balance.amount > 0) {
                owing.push({ customer: customer, balance: balance.amount });
                this.totalOwed += balance.amount;
                this.owingCount++;
            } else if (balance.amount < 0) {
                inCredit.push({ customer: customer, balance: Math.abs(balance.amount) });
                totalInCredit += balance.amount;
                this.creditCount++;
            } else {
                this.balancedCount++;
            }
        }

        this._owing = owing;
        this._inCredit = inCredit;
        this._noActiveJobs = noActiveJobs;
        this.creditAmount = Math.abs(totalInCredit);
        this.bindChartData();
    }

    public showCustomer(customer: Customer) {
        if (customer) {
            const dialog = new CustomerDialog(customer);
            dialog.show(DialogAnimation.SLIDE_UP);
        }
    }

    public goToOwing() {
        this.router.navigate('/owing');
    }
}
