import type { IAccountingPeriod, IAccountingPeriodSettings } from '@nexdynamic/squeegee-common';
import { AccountPeriodSettings } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';

export class AccountingPeriodService {
    static addPeriod(period: IAccountingPeriod) {
        const accountPeriodSettingsData = ApplicationState.getSetting<IAccountingPeriodSettings>('global.accounting-periods', {
            accountingPeriods: {},
        });
        AccountPeriodSettings.addPeriod(accountPeriodSettingsData, period);
        ApplicationState.setSetting('global.accounting-periods', accountPeriodSettingsData);
    }

    static updatePeriod(period: IAccountingPeriod) {
        const accountPeriodSettingsData = ApplicationState.getSetting<IAccountingPeriodSettings>('global.accounting-periods', {
            accountingPeriods: {},
        });
        if (!accountPeriodSettingsData.accountingPeriods[period.periodEndDate]) return;
        accountPeriodSettingsData.accountingPeriods[period.periodEndDate] = period;
        ApplicationState.setSetting('global.accounting-periods', accountPeriodSettingsData);
    }

    static getPeriods(): Array<IAccountingPeriod> {
        const accountPeriodSettingsData = ApplicationState.getSetting<IAccountingPeriodSettings>('global.accounting-periods', {
            accountingPeriods: {},
        });
        return Object.keys(accountPeriodSettingsData.accountingPeriods).map(key => accountPeriodSettingsData.accountingPeriods[key]);
    }

    static createNew() {
        const newPeriod: IAccountingPeriod = {
            periodEndDate: new Date().toISOString().substring(0, 10),
            description: ApplicationState.localise('period.default-description', { date: new Date().toLocaleDateString() }),
        };
        return newPeriod;
    }
}
