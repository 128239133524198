import type { JobSummary } from '../Jobs/JobSummary';

export class JobSummaryFilterValueConverter {
    toView(array: Array<JobSummary>, searchTextOriginal?: string) {
        if (!searchTextOriginal) return array;

        const searchText = searchTextOriginal.toLowerCase();
        return array.filter(jobSummary => {
            if (this.isMatch(jobSummary.job._externalId || '', searchText)) return true;
            if (this.isMatch(jobSummary.job.location?.addressDescription || '', searchText)) return true;
            if (this.isMatch(jobSummary.job.description, searchText)) return true;
            if (this.isMatch(jobSummary.roundsDescription, searchText)) return true;
            if (this.isMatch(jobSummary.servicesDescription, searchText)) return true;
            return false;
        });
    }

    private isMatch(propertyValue: any, searchText: string) {
        const value = (propertyValue || '').toString().toLowerCase();
        return value.indexOf(searchText) > -1;
    }
}
