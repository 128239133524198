import { inject } from 'aurelia-framework';

@inject(Element)
export class TheDestroyerCustomAttribute {
    constructor(private _element: HTMLElement) {}

    public detached() {
        if (this._element && this._element.querySelectorAll) {
            const elements = this._element.querySelectorAll('[data-upgraded]');
            elements.length && componentHandler.downgradeElements(elements);
        }
    }
}
