export const enum DialogAnimation {
    NONE,
    GROW,
    SCALE,
    SLIDE,
    SLIDE_UP,
    GROW_IN,
    GROW_OUT,
    SCALE_IN,
    SCALE_OUT,
    SLIDE_IN,
    SLIDE_OUT,
    SLIDE_UP_IN,
    SLIDE_UP_OUT,
}
