import type { Contact, TranslationKey } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../ApplicationState';
import { Data } from '../../Data/Data';
import { CustomDialog } from '../../Dialogs/CustomDialog';

export class ContactDialog extends CustomDialog<Contact> {
    constructor(public contact: Contact) {
        super('contactDialog', '../Components/Contacts/ContactDialog.html', ApplicationState.localise('general.name'), {
            cssClass: 'contact-dialog',
            isSecondaryView: true,
            okLabel: '',
            cancelLabel: '',
        });
    }

    public async onValidate(): Promise<true | TranslationKey> {
        if (!this.contact.name || !this.contact.name.trim()) return 'validation.name-required';
        return true;
    }

    public async getResult(): Promise<Contact> {
        return this.contact;
    }

    public delegateOk = async () => {
        await Data.put(this.contact);
        this.ok();
    };
}
