import { bindable } from 'aurelia-framework';
import type { IIndicator } from '../Jobs/IIndicator';

export class Indicators {
    @bindable indicators: Array<IIndicator> = [];
    // For testing: [
    //     { description: "default"},
    //     {description: "done", state: "done"},
    //  {description: "current", state: "current"},
    //  {description: "overdue", state: "overdue"},
    //  {description: "warning", state: "warning"}];
}
