import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { bindable, inject } from 'aurelia-framework';
import moment from 'moment';
import { DateTimePicker } from './DateTimePicker/DateTimePicker';

@inject(Element)
export class DatePickerCustomAttribute {
    @bindable title?: TranslationKey = 'dates.select-date';
    @bindable pickedMethod?: () => void;
    @bindable allowFutureDates = true;
    private datePicker: DateTimePicker;

    constructor(private element: HTMLInputElement) {}

    attached() {
        this.element.readOnly = true;
        this.datePicker = new DateTimePicker(undefined, undefined, undefined, this.allowFutureDates);

        this.datePicker.onDateSelected = selectedDate => {
            if (this.element.parentElement) this.element.parentElement.classList.add('is-dirty');
            this.element.value = selectedDate;
            this.element.dispatchEvent(new Event('change'));
            if (this.pickedMethod) this.pickedMethod();
        };

        this.element.addEventListener('click', () => {
            //This code could be a problem if the element value is not 'ddd MMM DD YYYY' format
            if (this.element.value) {
                this.datePicker.initialDate = moment(this.element.value, 'ddd MMM DD YYYY').format('YYYY-MM-DD');
            } else {
                this.datePicker.initialDate = moment().format('YYYY-MM-DD');
            }
            this.datePicker.title = this.title || 'dates.select-date';
            this.datePicker.init();
            this.datePicker.toggle();
        });
    }

    detached() {
        this.datePicker.close();
    }
}
