import { PLATFORM } from 'aurelia-framework';
import type { IRoute } from '../IRoute';

export const routes: Array<IRoute> = [
    {
        route: '',
        name: '',
        title: 'route-config.customers-overview-title',
        moduleId: PLATFORM.moduleName('Customers/Views/CustomerList'),
        nav: false,
        settings: {
            roles: ['Owner', 'Admin', 'Creator'],
        },
    },
];
