import type { Transaction, TranslationKey } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { Prompt } from '../Dialogs/Prompt';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { TransactionService } from '../Transactions/TransactionService';
import { InvoiceService } from './InvoiceService';

interface InvoiceActionResult {
    error?: TranslationKey;
    completed: boolean;
}
export class InvoiceActions {
    public static async cancelInvoice(transaction: Transaction): Promise<InvoiceActionResult> {
        const { invoice } = transaction;
        try {
            const isTip = transaction.transactionSubType === 'invoice.tip';
            const title = isTip ? 'actions.delete' : 'prompts.cancel-title';
            const description = isTip ? 'prompts.delete-tip' : 'prompts.invoice-cancel-text';

            const prompt = new Prompt(title, description, {
                okLabel: isTip ? 'actions.delete' : 'general.yes',
                cancelLabel: 'general.cancel',
            });

            if (await prompt.show()) {
                if (isTip) {
                    await TransactionService.cancelTransaction(transaction, true, undefined, 'always');
                } else {
                    await InvoiceService.cancelOrVoidInvoice(transaction._id, false);
                    new NotifyUserMessage('notifications.invoice-cancel-success', {
                        ref: invoice && invoice.referenceNumber ? '#' + invoice.referenceNumber : '',
                    });
                }
                return { completed: true };
            }
            return { completed: false };
        } catch (err) {
            Logger.error('Unable To Cancel Invoice', err);
            const error = ApplicationState.localise('notifications.invoice-cancel-error', {
                ref: invoice && invoice.referenceNumber ? '#' + invoice.referenceNumber : '',
            });
            new NotifyUserMessage(error);
            return { completed: false, error };
        }
    }
}
