export class SortByPropertyValueConverter {
    toView(array: Array<any>, config: { propertyName: string; descending: boolean; sortType: string }) {
        if (!array) return array;
        const factor = config.descending ? -1 : 1;
        return [...array].sort((a, b) => {
            if (config.sortType && config.sortType === 'alphabetical') {
                return a[config.propertyName].localeCompare(b[config.propertyName]) * factor;
            }
            return (a[config.propertyName] - b[config.propertyName]) * factor;
        });
    }
}
