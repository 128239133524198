// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.daynote {
  padding: 8px;
}
.daynote__title {
  display: flex;
}
.daynote__title h2 {
  margin-top: 0px;
  margin-bottom: 8px;
  white-space: pre-wrap;
}
.daynote__body {
  white-space: pre-wrap;
}`, "",{"version":3,"sources":["webpack://./src/Daynotes/components/daynote.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;AAAI;EACI,aAAA;AAER;AADQ;EACI,eAAA;EACA,kBAAA;EACA,qBAAA;AAGZ;AAAI;EACI,qBAAA;AAER","sourcesContent":[".daynote {\n    padding: 8px;\n    &__title {\n        display: flex;\n        h2 {\n            margin-top: 0px;\n            margin-bottom: 8px;\n            white-space: pre-wrap;\n        }\n    }\n    &__body {\n        white-space: pre-wrap;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
