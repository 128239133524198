import type { Expense, Transaction, TransferTransaction } from '@nexdynamic/squeegee-common';
import { TransactionType } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import { Utilities } from '../Utilities';
interface ITranQuery {
    from: string;
    to: string;
}

export interface IVATReturnV1 {
    box1VATDueOnOutputs: number;
    box2VATDueOnEUInputs: number;
    box3VATDueTotal: number;
    box4VATReclaimed: number;
    box5VATToPay: number;
    box6TotalOutputsExVAT: number;
    box7TotalInputsExVAT: number;
    box8TotalOfSuppliesToEU: number;
    box9TotalOfAqqFromEU: number;

}
export class VATServiceV1 {
    static getVATReturnDataForTrans(trans: Readonly<Array<Transaction>>) {
        const vr: IVATReturnV1 = {
            box1VATDueOnOutputs: 0,
            box2VATDueOnEUInputs: 0,
            box3VATDueTotal: 0,
            box4VATReclaimed: 0,
            box5VATToPay: 0,
            box6TotalOutputsExVAT: 0,
            box7TotalInputsExVAT: 0,
            box8TotalOfSuppliesToEU: 0,
            box9TotalOfAqqFromEU: 0,
        }
        for (const tran of trans) {
            if (tran.transactionType === TransactionType.Invoice) {
                vr.box1VATDueOnOutputs += tran.invoice?.totalTax || 0;
                vr.box6TotalOutputsExVAT += (tran.invoice?.priceIncludesTax ?
                    tran.invoice.total - tran.invoice.totalTax : tran.invoice?.total) || 0;
                continue;
            }

            // TODO: Eu tax?

            if (tran.transactionType === TransactionType.Expense) {
                const exp = tran as Expense;
                vr.box4VATReclaimed += exp.totalTax || 0;
                vr.box7TotalInputsExVAT += exp.subtotal || 0;
            }
        }

        vr.box3VATDueTotal = vr.box1VATDueOnOutputs + vr.box2VATDueOnEUInputs;
        vr.box5VATToPay = vr.box3VATDueTotal - vr.box4VATReclaimed;
        return vr;
    }

    static getVATReturn(q: ITranQuery) {
        this.validateTranQuery(q);
        const trans = Data.all<Transaction>('transactions', t => t.date > q.from && (t.date < q.to || t.date.substring(0, 10) === q.to))
        return this.getVATReturnDataForTrans(trans);
    }

    static validateTranQuery(q: ITranQuery) {
        if (q.from?.length !== 10 && moment.isDate(q.from)) throw 'from should be ISO Date YYYY-MM-DD';
        if (q.to?.length !== 10 && moment.isDate(q.to)) throw 'to should be ISO Date YYYY-MM-DD';
    }

    static getVATLedger(): Array<Transaction> {
        const trans = Data.all<Transaction>('transactions');
        const vatTrans: Array<Transaction> = [];
        for (const tran of trans) {
            if (tran.amount === 0) continue;
            if (tran.transactionType === TransactionType.Invoice) {
                const vatTran = Utilities.copyObject(tran) as Transaction;
                vatTran._id = vatTran._id + '-tax';
                vatTran.amount = (tran.invoice?.totalTax || 0);
                vatTrans.push(vatTran);
                continue;
            }

            // TODO: Eu tax?

            if (tran.transactionType === TransactionType.Expense) {
                const vatTran = Utilities.copyObject(tran) as Expense;
                vatTran._id = vatTran._id + '-tax';
                vatTran.amount = -(vatTran.totalTax || 0);
                vatTrans.push(vatTran);
                continue;
            }

            if (tran.transactionType === TransactionType.Transfer && (tran as TransferTransaction).paymentAccountId === ApplicationState.dataEmail + '-tax') {
                const vatTran = Utilities.copyObject(tran) as TransferTransaction;
                vatTrans.push(vatTran);
                continue;
            }
        }

        return vatTrans;
    }
}
