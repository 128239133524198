import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { TextKeys } from '@nexdynamic/squeegee-portal-common';
import { observable } from 'aurelia-framework';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import { Prompt } from '../../Dialogs/Prompt';
import { TextDialog } from '../../Dialogs/TextDialog';
import type { IMenuBarAction } from '../../Menus/IMenuBarAction';

type TextSettings = { textDebug: boolean; text: Record<keyof typeof TextKeys, string | undefined | null> };
export type PortalTextDialogProps = { searchValue?: string; textSettings: TextSettings };

type TextKeyInstance = { key: keyof typeof TextKeys; value: string | null | undefined; keyToken?: Array<string>; isCustom?: boolean };

export class PortalTextDialog extends CustomDialog<TextSettings> {
    private items = new Array<TextKeyInstance>();
    protected filteredItems: Array<{ key: keyof typeof TextKeys; value: string | null | undefined }>;

    @observable protected searchText: string;
    protected searchTextChanged() {
        this.filter();
    }

    constructor(private settings: PortalTextDialogProps) {
        super('portalTextDialog', '../DirectoryAndPortal/Dialogs/PortalTextDialog.html', 'portal-view.text-dialog', {
            cssClass: 'portal-text-dialog',
            isSecondaryView: true,
            okLabel: '',
            cancelLabel: '',
        });

        const textKeys = Object.keys(TextKeys) as Array<keyof typeof TextKeys>;
        for (const key of textKeys) {
            const value = settings.textSettings.text[key];
            let keyToken: Array<string>;
            if (typeof value === 'string') keyToken = /{{.+}}/.exec(value) || [];
            else keyToken = [];
            this.items.push({ key, value, keyToken, isCustom: TextKeys[key] === undefined });
        }
        this.filter(settings.searchValue);
        this.refreshActions();
    }

    protected updateKey = async (key: keyof typeof TextKeys, isCustom = false) => {
        let item = this.items.find(item => item.key === key);
        if (item?.isCustom) {
            if (
                !(await new Prompt('Edit or Delete' as TranslationKey, 'Do you want to edit or delete this custom key?' as TranslationKey, {
                    okLabel: 'general.edit',
                    cancelLabel: 'general.delete',
                }).show())
            )
                return await this.deleteCustomKey({ key });
        }
        if (!item) {
            item = { key, value: '', isCustom };
            this.items.push(item);
        }
        const value = item.value || '';
        const tokens = item.keyToken || [];
        console.log('tokens', tokens);
        const textDialog = new TextDialog(
            `${key}` as TranslationKey,
            (tokens && (`Tokens include: ${tokens}` as TranslationKey)) || (`Set the text value for ${key}` as TranslationKey),
            value,
            '',
            undefined,
            false,
            'textarea'
        );
        const result = await textDialog.show();
        if (textDialog.cancelled) return;

        item.value = result;
        this.filter();
    };

    protected addCustomKey = async () => {
        const textDialog = new TextDialog('portal-view.text-dialog-add-custom-key', '', '', '');
        const result = await textDialog.show();
        if (textDialog.cancelled) return;

        const key = result as keyof typeof TextKeys;

        return await this.updateKey(key, true);
    };

    public filter = (searchValue?: string) => {
        this.searchText = searchValue || this.searchText || '';
        this.filteredItems = !this.searchText?.trim()
            ? this.items
            : this.items.filter(
                  item =>
                      item.key?.toLowerCase().includes(this.searchText.toLowerCase()) ||
                      item.value?.toLowerCase().includes(this.searchText.toLowerCase())
              );
    };

    protected moreActions: Array<IMenuBarAction>;

    protected refreshActions = () => {
        this.moreActions = [
            {
                tooltip: 'portal-view.text-dialog-add-custom-key',
                handler: () => this.addCustomKey(),
                title: 'portal-view.text-dialog-add-custom-key',
            },
        ];

        if (this.settings.textSettings.textDebug) {
            this.moreActions.push({
                tooltip: 'portal-view.text-dialog-debug-off',
                handler: async () => {
                    this.settings.textSettings.textDebug = false;
                    this.refreshActions();
                },
                icon: 'bug_report',
                title: 'portal-view.text-dialog-debug-off',
            });
        } else {
            this.moreActions.push({
                tooltip: 'portal-view.text-dialog-debug-on',
                handler: async () => {
                    this.settings.textSettings.textDebug = true;
                    this.refreshActions();
                },
                icon: 'pest_control',

                title: 'portal-view.text-dialog-debug-on',
            });
        }
    };

    protected deleteCustomKey = async ({ key }: { key: string }) => {
        if (!(await Prompt.continue('Are you sure you want to remove this custom key?' as TranslationKey))) return;

        const index = this.items.findIndex(item => item.key === key);
        if (index >= 0) this.items.splice(index, 1);
        this.filter();
    };

    public delegateOk = () => {
        this.settings.textSettings.text = {} as Record<keyof typeof TextKeys, string | null | undefined>;
        this.items.forEach(item => (this.settings.textSettings.text[item.key] = item.value));
        this.ok(this.settings.textSettings);
    };
}
