import type { Customer, InvoiceTransaction, PaymentTransactionSubType } from '@nexdynamic/squeegee-common';
import { PaymentDetails, PaymentTransaction, TransactionType } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../ApplicationState';
import { Data } from '../../Data/Data';
import { TransactionDialog } from '../../Transactions/Components/TransactionDialog';
import type { IChargeState } from '../IChargeState';
import type { SquareTransactionInfo } from './SquareTransactionInfo';

export const handleSquareReaderChargeComplete = async (
    transactionInfo: SquareTransactionInfo,
    state: IChargeState,
    onSuccess: (paymentTransaction: PaymentTransaction) => void
) => {
    const customer = Data.get<Customer>(state.customerId);
    if (!customer) throw 'Customer not found';

    const customerPayment = new PaymentTransaction(
        TransactionType.Payment,
        'payment.square' as PaymentTransactionSubType,
        state.amount,
        `Square in person payment`,
        new PaymentDetails(
            'square',
            undefined,
            state.invoiceIds,
            transactionInfo.serverTransactionId || transactionInfo.clientTransactionId,
            undefined,
            ApplicationState.currencyCode()
        ),
        state.customerId,
        'complete'
    );
    customerPayment.externalIds = { square: transactionInfo.serverTransactionId, squareclient: transactionInfo.clientTransactionId };
    await Data.put(customerPayment);

    for (const invoiceId of state.invoiceIds || []) {
        const invoiceTransaction = Data.get<InvoiceTransaction>(invoiceId);
        if (!invoiceTransaction?.invoice) continue;

        invoiceTransaction.invoice.paymentReference = customerPayment._id;
        invoiceTransaction.invoice.referenceNumber = transactionInfo.serverTransactionId || transactionInfo.clientTransactionId;
        await Data.put(invoiceTransaction);
    }

    onSuccess(customerPayment);
    await new TransactionDialog(customerPayment).show();
};
