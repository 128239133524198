import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { Contact } from '@nexdynamic/squeegee-common';
import { bindable } from 'aurelia-framework';
import { Data } from '../../Data/Data';
import { Prompt } from '../../Dialogs/Prompt';
import { Utilities } from '../../Utilities';
import { ContactDialog } from './ContactDialog';

export class Contacts {
    @bindable contacts: Array<Contact>;
    @bindable save: () => Promise<void>;

    protected contactAsText(contact: Contact) {
        return `${contact.name} ${contact.methods && Utilities.localiseList(contact.methods.map(x => `${x.type} ${x.value}` as TranslationKey))
            }`;
    }

    protected async addOrUpdateContact(contactToUpdate?: Contact) {
        const name = (contactToUpdate && contactToUpdate.name) || '';
        const methods = (contactToUpdate && contactToUpdate.methods) || [];

        let contact = new Contact({
            name,
            methods
        });

        const contactDialog = new ContactDialog(contact);
        contact = await contactDialog.show();
        if (!contactDialog.cancelled) {
            if (contactToUpdate) {
                await Data.put(contactToUpdate);
                Object.assign(contactToUpdate, contact);
            } else {
                await Data.put(contact);
                this.contacts.push(contact);
            }
            this.save && (await this.save());
        }
    }

    protected async removeContact(contact: Contact) {
        if (
            await new Prompt('general.confirm', 'delete.confirmation-question', {
                okLabel: 'general.delete',
                cancelLabel: 'general.cancel',
            }).show()
        ) {
            this.contacts.splice(this.contacts.indexOf(contact), 1);
            await Data.delete(contact);
            this.save && (await this.save());
        }
    }
}
