import type { TranslationKey } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { CustomDialog } from '../CustomDialog';
import './TimeDialog.scss';
export class TimeDialog extends CustomDialog<string> {
    constructor(
        protected time: string = moment().format('HH:mm'),
        protected any: boolean,
        protected anyTimeText: TranslationKey = 'jobs.any-time',
        title: TranslationKey = 'enter.time-title',

        protected allowAny: boolean = true
    ) {
        super('timeOrDurationDialog', './TimeOrDuration/TimeDialog.html', title, {
            okLabel: 'general.save',
            cssClass: 'time-dialog',
        });
        if (allowAny) this.any = false;
    }

    public async getResult() {
        return this.any ? '' : this.time;
    }
}
