import { bindable } from 'aurelia-framework';
import { fetchNotificationsUnreadCountByCustomerId } from '../../Notifications/functions/fetchNotificationUnreadCountByCustomerId';

export class CustomerUnreadMessagesBadge {
    @bindable() customerId: string;

    protected count?: number;

    attached() {
        fetchNotificationsUnreadCountByCustomerId(this.customerId)
            .then(count => {
                this.count = count;
            })
            .catch(() => {
                this.count = 0;
            });
    }
}
