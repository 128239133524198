import type { Contact, Franchisee } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import { CustomDialog } from '../Dialogs/CustomDialog';
import { Utilities } from '../Utilities';

export class FranchiseeDialog extends CustomDialog<Franchisee> {
    protected contacts: Array<Contact>;

    constructor(protected franchisee: Franchisee) {
        super('franchiseeDialog', '../Franchise/FranchiseeDialog.html', ApplicationState.localise('franchise.franchisee-details'), {
            cssClass: 'franchisee-dialog',
            isSecondaryView: true,
            okLabel: '',
            cancelLabel: '',
        });
        if (!this.franchisee.settings) this.franchisee.settings = {};
        this.franchisee = Utilities.copyObject(franchisee);
    }

    public async init() {
        const contacts: Array<Contact> = [];
        for (const contactId of this.franchisee.contactIds || []) {
            const contact = await Data.get<Contact>(contactId);
            if (contact) contacts.push(contact);
        }
        this.contacts = contacts;
    }

    private refreshFranchisee() {
        this.franchisee.contactIds = this.contacts.map(x => x._id);
    }

    public async getResult(): Promise<Franchisee> {
        return this.franchisee;
    }

    public delegateOk = async () => {
        await this.save();
        this.ok();
    };

    public save = async () => {
        this.refreshFranchisee();
        await Data.put(this.franchisee);
    };
}
