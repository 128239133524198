import type { SoapyRow } from './SoapyRow';

export const parseSoapyDueWeek = (dueWeek: string): SoapyRow['jobDueWeek'] | undefined => {
    // parse the week from the format '2021-W01'
    const year = parseInt(dueWeek.slice(0, 4));
    if (!year) return;

    const week = parseInt(dueWeek.slice(6));
    if (!week) return;

    return { year, week };
};
