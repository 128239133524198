import { bindable, bindingMode, computedFrom } from 'aurelia-framework';
import { Utilities } from '../Utilities';
import './AvatarCustomElement.scss';

export class AvatarCustomElement {
    @bindable({ defaultBindingMode: bindingMode.toView }) text?: string;
    @bindable({ defaultBindingMode: bindingMode.toView }) colour?: string;
    @bindable({ defaultBindingMode: bindingMode.toView }) isIcon?: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView }) image?: string;

    protected css: string;

    @computedFrom('text', 'colour', 'isIcon', 'image')
    protected get avatar(): { text?: string; color?: string; imageUrl?: string } | void {
        let text: string | undefined;
        let color: string | undefined;

        if (!this.text) return;

        if (this.image) {
            this.css = `background-image:url(${this.image})`;
        } else {
            text = this.isIcon ? this.text : Utilities.getAvatarTextFromName(this.text);
            color = this.colour || Utilities.textToColour(this.text);

            this.css = `background-color: ${color}`;
        }

        return { text, color, imageUrl: this.image };
    }
}
