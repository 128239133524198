import { SqueegeeEvent } from './SqueegeeEvent';
import type { IMenuBarAction } from '../Menus/IMenuBarAction';
import { ApplicationState } from '../ApplicationState';
import type { ScheduleItem } from '../Schedule/Components/ScheduleItem';

export class ActionBarEvent extends SqueegeeEvent {
    public static fromSelectedScheduleItems(
        unselectAllItems: () => void,
        actions: IMenuBarAction[] | undefined,
        selectedItems: Array<ScheduleItem>
    ) {
        let price: number | undefined;
        for (const item of selectedItems) {
            if (item.price !== undefined) {
                price = (price || 0) + item.price;
            }
        }
        const count =
            selectedItems.length.toString() + (price === undefined ? '' : ` (${ApplicationState.currencySymbol()}${price.toFixed(2)})`);
        new ActionBarEvent(true, unselectAllItems, actions, count);
    }
    constructor(public enable: boolean, public closeHandler?: () => void, public actions?: Array<IMenuBarAction>, public count?: string) {
        super();
    }
}
