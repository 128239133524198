import { createTheme, type ThemeOptions } from '@mui/material';
import type { CustomTheme } from '@nexdynamic/nex-ui-react';

export type PortalThemeOptions = Omit<ThemeOptions, keyof CustomTheme>;

export type PortalTheme = {
    id: string;
    name: string;
    theme: PortalThemeOptions;
};

export const createPortalTheme = (options: PortalThemeOptions) => createTheme(options as ThemeOptions); // move to portal common
