import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { computedFrom } from 'aurelia-framework';

export class InvoiceLineItemForm {
    constructor(public quantity: number = 1, public description: string = '', public unitPrice: number = 0) {}

    validationError: TranslationKey | null;

    @computedFrom('quantity', 'description', 'unitPrice')
    get isValid(): boolean {
        this.validationError = null;

        if (!this.quantityIsValid) this.validationError = 'validation.invoice-line-item-quantity';
        if (!this.unitPriceIsValid) this.validationError = 'validation.invoice-line-item-unit-price';
        if (!this.descriptionIsValid) this.validationError = 'validation.invoice-line-item-description';

        return this.validationError === null;
    }

    @computedFrom('quantity')
    get quantityIsValid(): boolean {
        return !!(this.quantity && !isNaN(this.quantity) && this.quantity > 0);
    }

    @computedFrom('unitPrice')
    get unitPriceIsValid(): boolean {
        return !isNaN(this.unitPrice);
    }

    @computedFrom('description')
    get descriptionIsValid(): boolean {
        return !!(this.description && this.description.length);
    }
}
