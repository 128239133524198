import { Autocomplete, Box, Button, List, ListItem, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import useTranslation from '../ReactUI/hooks/useTranslation';

type HashtagAutoCompleteProps = {
    setInProgress: (inProgress: string) => void;
    add: (value: string | null) => void;
    options: Array<string>;
    remove: (hashtag: string) => void;
    value: string;
    selected: Array<string>;
    inputClassName?: string;
};

export const HashtagAutoComplete = ({ setInProgress, add, options, remove, selected, inputClassName, value }: HashtagAutoCompleteProps) => {
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState(value);

    return (
        <Box sx={{ p: 2 }}>
            <Autocomplete
                onChange={(_, v) => {
                    add(v);
                    setInProgress('');
                    setTimeout(() => setInputValue(() => ''), 0);
                }}
                onInputChange={(_, v) => {
                    setInputValue(() => v);
                    setInProgress(v);
                }}
                inputValue={inputValue}
                options={options}
                renderInput={params => (
                    <TextField {...params} label={t('hashtags.bulk-tag-edit-placeholder-important')} className={inputClassName} />
                )}
                includeInputInList
                freeSolo
                autoComplete
                autoHighlight
            />
            <Typography variant="h6" sx={{ mt: 3 }}>
                Existing Tags
            </Typography>
            <List>
                {selected.map(hashtag => (
                    <ListItem key={hashtag} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Box sx={{ pl: 1 }}>{hashtag}</Box>
                        <Button onClick={() => remove(hashtag)} sx={{ width: '30px' }}>
                            Remove
                        </Button>{' '}
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};
