import { Api } from './Server/Api';

const inspect = require('browser-util-inspect');

export type ILogType = 'error' | 'warn' | 'debug' | 'info';

/*  */
export class Logger {
    private static _logEntriesQueue: Array<string> = [];

    /**
     * @param message The message to log
     * @param details Any additional details to log as a non-recursing object
     * @param severity The severity of the error, defaults to 5
     * @example Logger.error('Something horrendous went wrong', { details: 'Some details' }, 1);
     * 1: Critical
     * 2: Severe
     * 3: Serious
     * 4: Minor
     * 5: Cosmetic
     */
    public static error(message: string, details?: any, severity: 1 | 2 | 3 | 4 | 5 = 5) {
        try {
            if (typeof message !== 'string') {
                details = { details, error: message };
                message = 'Non string error passed';
            } else if (!message.trim()) {
                message = 'No message text supplied for error';
            }

            message = `SQG::0${severity} ${message}`;

            const error = { message, details };

            let detailsString = '';

            try {
                detailsString = inspect(details);
            } catch (ex) {
                detailsString = details
                    ? Object.keys(details)
                          .map(x => `"${x}":"${details[x]}"`)
                          .join('\n')
                    : 'Unable to stringify client error.';
            }

            const finalMessage = (message + '\n' + detailsString).slice(0, 10000);

            this.proccessQueue();
            this.addLogEntryToQueue(finalMessage);

            console.error.apply(console, [new Error(message), error]);
        } catch (error) {
            console.log(message, details);
        }
    }

    private static _queueProccessor: any;
    private static proccessQueue() {
        if (this._queueProccessor) return;

        const runQueue = async () => {
            try {
                // if the api is not connected or there are no log entries in the queue, return
                if (!Api.isConnected || this._logEntriesQueue.length === 0) return;
                const entry = this._logEntriesQueue.pop();
                if (entry) await Api.writeLogEntry(entry);
            } catch (error) {
                console.info('Error processing log queue', error);
            } finally {
                this._queueProccessor = setTimeout(runQueue, 800);
            }
        };

        runQueue();
    }

    private static addLogEntryToQueue(logEntry: string) {
        // if a log entry with the exact same message is already in the queue, don't add it again
        if (this._logEntriesQueue.includes(logEntry)) return;

        this._logEntriesQueue.unshift(logEntry);
        // if the queue is too long, remove the oldest entry
        this._logEntriesQueue.splice(100, this._logEntriesQueue.length - 100);
    }

    public static warn(message: string, details?: any) {
        try {
            console.warn.apply(console, details ? [message, details] : [message]);
        } catch (error) {
            console.log(message, details);
        }
    }

    public static info(message: string, details?: any) {
        try {
            console.info.apply(console, details ? [message, details] : [message]);
        } catch (error) {
            console.log(message, details);
        }
    }

    public static log(message: string, details: any, color: string, backgroundColor: string) {
        try {
            console.info.apply(console, [`%c${message}`, Logger.getCss(color, backgroundColor), details || '']);
        } catch (error) {
            console.log(message, details);
        }
    }

    private static getCss(color: string, backgroundColor: string) {
        return `background: ${backgroundColor}; color: ${color}`;
    }

    public static debug(message: string, details?: any) {
        try {
            console.debug.apply(console, details ? [message, details] : [message]);
        } catch (error) {
            console.log(message, details);
        }
    }
}

/*  */
