import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { CustomDialog } from '../CustomDialog';
import type { CapacityMeasurement } from './CapacityMeasurement';

export interface CapacityMeasurementDialogSettings {
    titleLocale?: TranslationKey;
    typeLocale?: TranslationKey;
    benchmarkLocale?: TranslationKey;
    amountLocale?: TranslationKey;
}

export class CapacityMeasurementDialog extends CustomDialog<CapacityMeasurement> {
    constructor(protected capacityMeasurement: CapacityMeasurement, protected dialogSettings: CapacityMeasurementDialogSettings) {
        super('capacityMeasurementDialog', './CapacityMeasurement/CapacityMeasurementDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'capacity-measurement-dialog',
            isSecondaryView: true,
        });
    }

    protected selectCapacityMeasurements = () => {
        this.ok(this.capacityMeasurement);
    };
}
