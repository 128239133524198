import { Data } from '../../Data/Data';
import StoredObjectUtils from '../../utils/StoredObjectUtils';

export const viewStoredObject = async (options: { id?: string }) => {
    const { id } = options;
    if (!id) return;

    const storedObject = await Data.get(id);
    if (!storedObject) return;

    const dialog = StoredObjectUtils.getDialogForStoredObject(storedObject);
    if (!dialog?.showDialog) return;

    await dialog.showDialog();
};
