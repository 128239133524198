import { CleanerPlannerCommonEntity } from './CleanerPlannerCommonEntity';
export class CleanerPlannerContact extends CleanerPlannerCommonEntity {
    Title: string;
    FirstName: string;
    LastName: string;
    Company: string;
    Address1: string;
    Address2: string;
    Town: string;
    County: string;
    Postcode: string;
    Phone: string;
    Mobile: string;
    Email: string;
}
