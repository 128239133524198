// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simple-select-list-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 8px;
    cursor: pointer;
    margin-bottom: 6px;
}

.simple-select-list-item:hover {
    background-color: #eee;
}

.simple-select-list-item.selected {
    background-color: #ddd;
}`, "",{"version":3,"sources":["webpack://./src/Dialogs/SimpleSelect.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".simple-select-list-item {\n    background-color: #fff;\n    border: 1px solid #ddd;\n    border-radius: 5px;\n    padding: 8px;\n    cursor: pointer;\n    margin-bottom: 6px;\n}\n\n.simple-select-list-item:hover {\n    background-color: #eee;\n}\n\n.simple-select-list-item.selected {\n    background-color: #ddd;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
