import { ViewResizeEvent } from '../Events/ViewResizeEvent';
import { GlobalFlags } from '../GlobalFlags';
import { Logger } from '../Logger';
import type { CustomDialog } from './CustomDialog';
import { DialogAnimation } from './DialogAnimation';
import type { DialogSettingsSecondaryView, DialogSettingsViewport } from './DialogSettings';

export class CustomDialogElement {
    private static ANIMATION_TIME_CSS = '275ms';
    private static get ANIMATION_TIME() {
        const result = /\d\d\d/.exec(this.ANIMATION_TIME_CSS);
        return (result && Number(result[0])) || 250;
    }

    protected dialogList: HTMLElement;
    protected view: HTMLElement;
    protected viewExpanded = false;
    protected dialogs: Array<CustomDialog<any>> = [];
    protected attached() {
        this.view = <HTMLElement>document.getElementById('main-view');

        window.sq.showDialog = (dialog: CustomDialog<any>) => this.showDialog(dialog);
        window.sq.closeDialog = (dialog: CustomDialog<any>, onClose?: () => any, autoClosed?: boolean) =>
            this.closeDialog(dialog, onClose, autoClosed);

        window.addEventListener('popstate', this.popDialog);
    }
    protected detached() {
        window.removeEventListener('popstate', this.popDialog);
    }

    private showDialog = async (dialog: CustomDialog<any>) => {
        if (!dialog) return Logger.error('No dialog found to show'); // Same dialog for the same model, just replace it.

        if (this.dialogs.length && this.dialogs[this.dialogs.length - 1].description === dialog.description) {
            dialog.isActive = true;
            dialog.visible = true;
            const existing = this.dialogs[this.dialogs.length - 1];
            dialog.element = existing.element;
            delete existing.element;
            this.dialogs.splice(this.dialogs.length - 1, 1, dialog);
            if (!GlobalFlags.isMobile && (dialog._settings as DialogSettingsSecondaryView).isSecondaryView === true) this.toggleMainViewSize(true);
            //this.toggleOverflow(true);
            dialog.init && (await dialog.init());
            this.closeDialog(existing);
            return;
        }
        await new Promise<void>(async resolve => {
            try {
                this.dialogs.push(dialog);
                requestAnimationFrame(() => {
                    const dialogElement = dialog.element;
                    if (!dialogElement) return Logger.error('dialogElement not found for ' + dialog.description, dialog);
                    const animation = this.getAnimation(dialog, false);
                    dialogElement.classList.add(animation.class);
                    dialog.visible = true;
                    //this.toggleOverflow(true);

                    if ((GlobalFlags.forceWideScreen || !GlobalFlags.isMobile) && (dialog._settings as DialogSettingsSecondaryView).isSecondaryView === true) {
                        this.toggleMainViewSize(true);
                    }
                    dialog.init && dialog.init();
                    setTimeout(() => {
                        for (const existingDialog of this.dialogs.filter(x => x !== dialog)) existingDialog.isActive = false;
                        dialog.isActive = true;
                        return resolve();
                    }, animation.ms);
                });
            } catch (error) {
                Logger.error(`Error during display the dialog "${dialog.description}"`, error);
                return resolve();
            }
        });

        if (dialog.noReplaceSameType) return;

        const closeOthersOfType = await this.closeExistingDialogOfType(dialog);
        if (!closeOthersOfType.success) {
            const dialog = <CustomDialog<any>>closeOthersOfType.dialog;
            dialog.isActive = true;
            this.dialogs.push(dialog);
        }
    };

    private async closeExistingDialogOfType(newDialog: CustomDialog<any>) {
        for (const dialog of this.dialogs.filter(x => x !== newDialog && x.template === newDialog.template)) {
            if (!(await dialog.cancel(true)))
                return {
                    success: false,
                    dialog,
                };
        }
        return {
            success: true,
            dialog: newDialog,
        };
    }

    private closeDialog = (dialog: CustomDialog<any>, onClose?: () => any, autoClosed?: boolean) => {
        if (!dialog) return Logger.error('No dialog found to close');

        const dialogElement = dialog.element;

        try {
            dialog.dispose();
        } catch {
            /* swallowed. */
        }

        const index = this.dialogs.indexOf(dialog);
        if (index > -1) {
            if (autoClosed) {
                this.toggleMainViewSize(false);
                this.dialogs = this.dialogs.filter(x => x !== dialog);
                return;
            }
            if (index > 0) {
                this.dialogs[index - 1].isActive = true;
                this.dialogs[index - 1].attachCloseKey();
            }
            const animation = this.getAnimation(dialog, true);
            if (dialogElement) dialogElement.classList.add(autoClosed ? 'out-no-animate-dialog' : animation.class);
            //this.toggleOverflow(false);

            if ((GlobalFlags.forceWideScreen || !GlobalFlags.isMobile) && (dialog._settings as DialogSettingsSecondaryView).isSecondaryView === true) {
                this.toggleMainViewSize(false);
            }

            setTimeout(
                async () => {
                    dialog.isActive = false;
                    this.dialogs = this.dialogs.filter(x => x !== dialog);
                    onClose && (await onClose());
                },
                autoClosed ? 0 : animation.ms
            );
        } else {
            setTimeout(async () => {
                dialog.isActive = false;
                onClose && (await onClose());
            });
        }
    };

    // private toggleOverflow(on: boolean) {
    //     if (!GlobalFlags.isMobile) return;
    //     on ? (document.body.style.overflowY = 'hidden') : (document.body.style.overflowY = 'auto');
    // }
    private toggleMainViewSize(doShrink: boolean, force = false) {
        // Don't fire for mobile
        if (!GlobalFlags.forceWideScreen && GlobalFlags.isMobile && doShrink === true) return;
        if (!this.view) return;

        const shrink = ['shrink-view'];
        const expand = ['expand-view'];
        if (doShrink) {
            this.viewExpanded = false;
            this.view.classList.add(...shrink);
            this.view.classList.remove(...expand);
        } else if (
            force ||
            this.dialogs.filter(dialog => (dialog._settings as DialogSettingsSecondaryView).isSecondaryView).length - 1 === 0 ||
            this.dialogs.length === 0
        ) {
            this.viewExpanded = true;
            this.view.classList.remove(...shrink);
            this.view.classList.add(...expand);
        }
        new ViewResizeEvent();
    }
    private popDialog = () => {
        const dialog = this.dialogs.length && this.dialogs[this.dialogs.length - 1];
        if (dialog && dialog._settings && dialog._settings.allowCancel) dialog.cancel();
    };

    private getAnimation(
        dialog: CustomDialog<any>,
        reverse: boolean
    ): {
        class: string;
        ms: number;
    } {
        const animationType = dialog.animationType;
        const animation =
            (GlobalFlags.forceWideScreen || !GlobalFlags.isMobile) && (dialog._settings as DialogSettingsSecondaryView).isSecondaryView === true
                ? this.getFullScreenDesktopAnimation(animationType, reverse)
                : this.getStandardAnimation(animationType, reverse);
        return animation;
    }
    private getFullScreenDesktopAnimation(animationType: DialogAnimation, reverse: boolean) {
        switch (animationType) {
            case DialogAnimation.GROW_IN:
            case DialogAnimation.SCALE_IN:
            case DialogAnimation.SLIDE_IN:
            case DialogAnimation.SLIDE_UP_IN:
                return {
                    class: reverse ? 'out-no-animate-dialog' : 'slide-dialog',
                    ms: reverse ? 0 : CustomDialogElement.ANIMATION_TIME,
                };
            case DialogAnimation.GROW_OUT:
            case DialogAnimation.SCALE_OUT:
            case DialogAnimation.SLIDE_OUT:
            case DialogAnimation.SLIDE_UP_OUT:
                return {
                    class: reverse ? 'slide-out-dialog' : 'in-no-animate-dialog',
                    ms: reverse ? CustomDialogElement.ANIMATION_TIME : 0,
                };
            case DialogAnimation.NONE:
                return {
                    class: reverse ? 'out-no-animate-dialog' : 'in-no-animate-dialog',
                    ms: 0,
                };
            default:
                return {
                    class: reverse ? 'slide-out-dialog' : 'slide-dialog',
                    ms: CustomDialogElement.ANIMATION_TIME,
                };
        }
    }
    private getStandardAnimation(animationType: DialogAnimation, reverse: boolean) {
        switch (animationType) {
            case DialogAnimation.NONE:
                return {
                    class: reverse ? 'out-no-animate-dialog' : 'in-no-animate-dialog',
                    ms: 0,
                };
            case DialogAnimation.GROW:
                return {
                    class: reverse ? 'grow-out-dialog' : 'grow-dialog',
                    ms: CustomDialogElement.ANIMATION_TIME,
                };
            case DialogAnimation.SCALE:
                return {
                    class: reverse ? 'scale-out-dialog' : 'scale-dialog',
                    ms: CustomDialogElement.ANIMATION_TIME,
                };
            case DialogAnimation.SLIDE:
                return {
                    class: reverse ? 'slide-out-dialog' : 'slide-dialog',
                    ms: CustomDialogElement.ANIMATION_TIME,
                };
            case DialogAnimation.SLIDE_UP:
                return {
                    class: reverse ? 'slide-down-out-dialog' : 'slide-up-dialog',
                    ms: CustomDialogElement.ANIMATION_TIME,
                };
            case DialogAnimation.GROW_IN:
                return {
                    class: reverse ? 'out-no-animate-dialog' : 'grow-dialog',
                    ms: reverse ? 0 : CustomDialogElement.ANIMATION_TIME,
                };
            case DialogAnimation.SCALE_IN:
                return {
                    class: reverse ? 'out-no-animate-dialog' : 'scale-dialog',
                    ms: reverse ? 0 : CustomDialogElement.ANIMATION_TIME,
                };
            case DialogAnimation.SLIDE_IN:
                return {
                    class: reverse ? 'out-no-animate-dialog' : 'slide-dialog',
                    ms: reverse ? 0 : CustomDialogElement.ANIMATION_TIME,
                };
            case DialogAnimation.SLIDE_UP_IN:
                return {
                    class: reverse ? 'out-no-animate-dialog' : 'slide-up-dialog',
                    ms: reverse ? 0 : CustomDialogElement.ANIMATION_TIME,
                };
            case DialogAnimation.GROW_OUT:
                return {
                    class: reverse ? 'grow-out-dialog' : 'in-no-animate-dialog',
                    ms: reverse ? CustomDialogElement.ANIMATION_TIME : 0,
                };
            case DialogAnimation.SCALE_OUT:
                return {
                    class: reverse ? 'scale-out-dialog' : 'in-no-animate-dialog',
                    ms: reverse ? CustomDialogElement.ANIMATION_TIME : 0,
                };
            case DialogAnimation.SLIDE_OUT:
                return {
                    class: reverse ? 'slide-out-dialog' : 'in-no-animate-dialog',
                    ms: reverse ? CustomDialogElement.ANIMATION_TIME : 0,
                };
            case DialogAnimation.SLIDE_UP_OUT:
                return {
                    class: reverse ? 'slide-down-out-dialog' : 'in-no-animate-dialog',
                    ms: reverse ? CustomDialogElement.ANIMATION_TIME : 0,
                };
            default:
                return {
                    class: reverse ? 'slide-out-dialog' : 'slide-dialog',
                    ms: CustomDialogElement.ANIMATION_TIME,
                };
        }
    }

    public async onClickedObfuscator(dialog: CustomDialog<void>) {
        if ((dialog._settings as DialogSettingsViewport).allowClickOff) {
            await dialog.cancel();
        }
    }
}
