import type { JobGroup, TranslationKey } from '@nexdynamic/squeegee-common';
import { FrequencyType, TagType } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import { DateTimePicker } from '../Components/DateTimePicker/DateTimePicker';
import { Data } from '../Data/Data';
import { LoaderEvent } from '../Events/LoaderEvent';
import { JobOccurrenceService } from '../Jobs/JobOccurrenceService';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { ScheduleItem } from '../Schedule/Components/ScheduleItem';
import { ScheduleService } from '../Schedule/ScheduleService';
import { CustomDialog } from './CustomDialog';

export class ScheduleActionDialog extends CustomDialog<boolean | string> {
    //Used for template
    protected dateFormat = 'YYYY-MM-DD';
    protected actions: Array<{ icon: string; label: string; subLabel: string; dateISO: string }> = [];

    // Store the value to return to the caller.
    protected adjustSchedule = false;

    // Control based on whether there are any scheduled items that are recurreling
    protected itemsWithRoundSchedulesCount: number;
    protected itemsWithOwnRecurringScheduleCount: number;

    constructor(private scheduleItems: Array<ScheduleItem>) {
        super(
            'scheduleActionDialog',
            './ScheduleActionDialog.html',
            scheduleItems.length > 1
                ? (`Replan ${scheduleItems.length} jobs` as TranslationKey)
                : <TranslationKey>scheduleItems[0].customer.name,
            {
                cssClass: 'schedule-action-dialog',
                okLabel: '',
                cancelLabel: '',
                showCloseX: true,
                subtitle:
                    scheduleItems.length > 1
                        ? ('' as TranslationKey)
                        : <TranslationKey>scheduleItems[0].job.location?.addressDescription || '',
            }
        );

        this.setActions();
        if (ApplicationState.isInAnyRole(['Owner', 'Admin', 'Planner'])) {
            const rounds = Data.all<JobGroup>('tags', x => x.type === TagType.ROUND).reduce<{ [id: string]: JobGroup }>((d, next) => {
                d[next._id] = next;
                return d;
            }, {});
            this.itemsWithRoundSchedulesCount = scheduleItems.filter(
                item => item.job.rounds && item.job.rounds.find(round => rounds[round._id] && rounds[round._id].frequencyData)
            ).length;

            this.itemsWithOwnRecurringScheduleCount = scheduleItems.filter(item => {
                if (item.job.frequencyType === FrequencyType.NoneRecurring) return false;
                if (
                    item.job.frequencyType === FrequencyType.Weeks &&
                    Array.isArray(item.job.frequencyDayOfWeek) &&
                    item.job.frequencyDayOfWeek.length > 1
                )
                    return false;
                if (!item.job.rounds || !item.job.rounds.length) return true;

                for (const round of item.job.rounds) {
                    if (rounds[round._id] && rounds[round._id].frequencyData) {
                        return false;
                    }
                }

                return true;
            }).length;
            this.adjustSchedule = ApplicationState.account.schedulingBehavior === 'update-schedule';
        }
    }

    protected async skip() {
        new LoaderEvent(true);
        const multiple = this.scheduleItems.length > 1;

        this.ok(true);
        const skipReasonCheck = await JobOccurrenceService.getSkipReasonIfRequired();
        if (skipReasonCheck.cancelled) {
            new NotifyUserMessage('notification.skip-reason-required');
            return;
        }

        for (const scheduleItem of this.scheduleItems) {
            await JobOccurrenceService.skipJobOccurrence(scheduleItem.occurrence, multiple, skipReasonCheck);
        }

        for (const scheduleItem of this.scheduleItems) ScheduleItem.refresh(scheduleItem);

        new LoaderEvent(false);
    }

    protected async replan(isoDate: string | undefined) {
        await ScheduleService.replan(this.scheduleItems, isoDate, this.adjustSchedule);

        return this.ok(true);
    }

    protected async replanCustom() {
        const currentPlannedDate = moment().format('YYYY-MM-DD');
        const datePickerDialog = new DateTimePicker(false, currentPlannedDate, 'dates.select-new-planned-date');
        datePickerDialog.init();
        await datePickerDialog.open();
        if (!datePickerDialog.canceled) return this.replan(datePickerDialog.selectedDate);
    }

    private setActions() {
        const plannedDate = moment(this.scheduleItems[0].date);
        const today = moment().format(this.dateFormat);
        const tomorrow = moment().add(1, 'day').format(this.dateFormat);
        const nextWeek = moment().add(1, 'week').format(this.dateFormat);

        if (plannedDate.isSame(today) === false) {
            this.addAction('today', 'Today', today, today);
        }
        if (plannedDate.isSame(tomorrow) === false) {
            this.addAction('update', 'Tomorrow', tomorrow, tomorrow);
        } else {
            const followingDay = moment(tomorrow).add(1, 'day').format(this.dateFormat);
            this.addAction('update', 'Following Day', followingDay, followingDay);
        }
        if (plannedDate.isSame(nextWeek) === false) {
            this.addAction('next_week', 'Next Week', nextWeek, nextWeek);
        } else {
            const followingWeek = moment(nextWeek).add(1, 'week').format(this.dateFormat);
            this.addAction('next_week', 'Following Week', followingWeek, followingWeek);
        }

        new LoaderEvent(false);
    }

    private addAction(icon: string, label: string, subLabel: string, dateISO: string) {
        this.actions.push({ icon, label, subLabel, dateISO });
    }
}
