export class InvoiceMessageModel {
    [name: string]: string;
    total = '';
    reference = '';
    invoiceNumber = '';
    url = '';
    services = '';
    assignees = '';
    formattedDate = '';
    formattedDueDate = '';
}
