import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { CustomDialog } from './CustomDialog';
import type { DialogSettingsSecondaryView, DialogSettingsViewport } from './DialogSettings';
import './SimpleSelect.css';
export class SimpleSelect<TModel extends { [key: string]: any }> extends CustomDialog<TModel> {
    constructor(
        protected simpleSelectTitle: TranslationKey,
        protected options: Array<TModel>,
        protected textKey: keyof TModel,
        protected uniqueValueKey: keyof TModel,
        protected extraTextHtml: string = '',
        protected textDescription: TranslationKey = 'empty.string',
        settings: DialogSettingsSecondaryView | DialogSettingsViewport = {}
    ) {
        super(
            'simpleSelectDialog',
            './SimpleSelect.html',
            simpleSelectTitle || '',
            Object.assign(
                {
                    cssClass: 'simple-select-dialog',
                    okLabel: '',
                    cancelLabel: '',
                    allowClickOff: true,
                },
                settings
            )
        );
    }

    protected selectValue(value: number) {
        const results = this.options.filter(item => item[this.uniqueValueKey] === value);
        if (results.length) this.ok(results[0]);
    }
}
