import { ApplicationState } from '../ApplicationState';
import { Api } from '../Server/Api';
export interface IMessageTemplates {
    simple: string;
}

export class EmailTemplateService {
    public static getLogoUrl() {
        return (
            (ApplicationState.messagingTemplates &&
                ApplicationState.account.businessLogo &&
                `${Api.apiEndpoint}/api/payments/logo/${ApplicationState.account.uuid || ''}?uncache=${Math.floor(
                    Math.random() * 1000000
                )}`) ||
            ''
        );
    }
    public static getLogoHtml() {
        const logoUrl = EmailTemplateService.getLogoUrl();
        return logoUrl ? `<img style="max-height: 150px; max-width: 200px; padding-bottom: 24px;" src="${logoUrl}"/>` : '';
    }

    public static getSignatureHtml() {
        return (ApplicationState.messagingTemplates && ApplicationState.account.signature) || '';
    }
    public static getSimpleHtml(contentHtml: string) {
        return /<html.*?>/.test(contentHtml) ? contentHtml : `<div style="margin: auto; max-width: 800px;">${contentHtml}</div>`;
    }
    // add any html cleaning functions here
    public static cleanHtml(html?: string) {
        return !html ? '' : html.replace(/<!--\[if !mso\]>(.|\n)*?<!\[endif\]-->/gm, ''); // this one is for removing a meta tag that if present older outlook versions will not render the body eg // <!--[if !mso]><!--><meta http-equiv="X-UA-Compatible" content="IE=Edge" /><!--<![endif]-->
    }
}
