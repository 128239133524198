import type { ExpenseCategory, TranslationKey } from '@nexdynamic/squeegee-common';
import { TransactionType } from '@nexdynamic/squeegee-common';
import type { FilterItemDictionary, IFilterItem, IOption } from './Filter';
import { Filter } from './Filter';

export interface IScheduleViewFilterArgs {
    rounds: Array<IOption<string>>;
    services: Array<IOption<string>>;
    status: Array<IOption<string>>;
    assignee: Array<IOption<string>>;
}

export interface IScheduleFilterItemDictionary extends FilterItemDictionary {
    rounds: IFilterItem<IOption<string>>;
    sortBy: IFilterItem<IOption<number>>;
    services: IFilterItem<IOption<string>>;
    status: IFilterItem<IOption<string>>;
    direction: IFilterItem<IOption<string>>;
    assignee: IFilterItem<IOption<string>>;
    dateRange: IFilterItem<IOption<any>>;
}
export interface IScheduleSortItemDictionary extends FilterItemDictionary {
    sortBy: IFilterItem<IOption<number>>;
    direction: IFilterItem<IOption<string>>;
}
export interface ITransactionsFilterArgs {
    transactionType: TransactionType | Array<TransactionType>;
}
export interface ITransactionsFilterItemDictionary extends FilterItemDictionary {
    sortBy: IFilterItem<IOption<ITransactionsFilterSortOption>>;
    status: IFilterItem<IOption<string>>;
    dateRange: IFilterItem<IOption<any>>;
}

export interface IExpensesFilterItemDictionary extends FilterItemDictionary {
    sortBy: IFilterItem<IOption<ITransactionsFilterSortOption>>;
    category: IFilterItem<IOption<string>>;
    dateRange: IFilterItem<IOption<any>>;
}

export const enum ITransactionsFilterSortOption {
    DATE_OLDEST_NEWEST = 1,
    DATE_NEWEST_OLDEST = 2,
}

export class Filters {
    public static scheduleViewFilter(
        argsDictionary?: IScheduleViewFilterArgs,
        existingFilter?: Filter<IScheduleFilterItemDictionary | IScheduleSortItemDictionary>
    ): Filter<IScheduleFilterItemDictionary | IScheduleSortItemDictionary> {
        let sortByOptions: Array<IOption<number>> = [];

        sortByOptions = [
            { name: 'sorting.by-planned-order', value: 1 },
            { name: 'general.due-date', value: 5 },
            { name: 'sorting.by-distance', value: 2 },
            { name: 'sorting.by-start-time', value: 3 },
            { name: 'sorting.by-price', value: 4 },
        ];

        let directionOptions: Array<IOption<string>> = [];
        directionOptions = [
            { name: 'sorting.order-ascending', value: 'ascending' },
            { name: 'sorting.order-descending', value: 'descending' },
        ];

        const items: IScheduleSortItemDictionary = {
            sortBy: {
                type: 'sortBy',
                name: 'filters.sort-by',
                optionNameKey: 'name',
                optionValueKey: 'value',
                options: sortByOptions,
                selectedOption: sortByOptions[0],
            },
            direction: {
                type: 'sortBy',
                name: 'general.direction',
                optionNameKey: 'name',
                optionValueKey: 'value',
                options: directionOptions,
                selectedOption: directionOptions[0],
            },
        };

        if (argsDictionary) {

            const rounds: IFilterItem<IOption<string>> = {
                type: 'filter',
                name: 'general.round',
                optionNameKey: 'name',
                optionValueKey: 'value',
                options: argsDictionary.rounds,
                title: 'filters.filter-by',
            };

            const services: IFilterItem<IOption<string>> = {
                type: 'filter',
                name: 'general.service',
                optionNameKey: 'name',
                optionValueKey: '_id',
                options: argsDictionary.services,
            };


            const status: IFilterItem<IOption<string>> = {
                type: 'filter',
                name: 'general.status',
                optionNameKey: 'name',
                optionValueKey: 'value',
                options: argsDictionary.status,
            };

            const assignee: IFilterItem<IOption<string>> = {
                type: 'filter',
                name: 'general.assigned-to',
                optionNameKey: 'name',
                optionValueKey: 'value',
                options: argsDictionary.assignee,
            };

            if (existingFilter) {
                rounds.selectedOptions = existingFilter.filterOptions.rounds
                    .selectedOptions?.filter(r => argsDictionary.rounds.some(a => a.value === r.value))
                    .map(r => argsDictionary.rounds.find(o => o.value === r.value) as IOption<string>)


                services.selectedOptions = existingFilter.filterOptions.services
                    .selectedOptions?.filter(r => argsDictionary.services.some(a => a.value === r.value))
                    .map(r => argsDictionary.services.find(o => o.value === r.value) as IOption<string>);

                status.selectedOptions = existingFilter.filterOptions.status
                    .selectedOptions?.filter(r => argsDictionary.status.some(a => a.value === r.value))
                    .map(r => argsDictionary.status.find(o => o.value === r.value) as IOption<string>);

                assignee.selectedOptions = existingFilter.filterOptions.assignee
                    .selectedOptions?.filter(r => argsDictionary.assignee.some(a => a.value === r.value))
                    .map(r => argsDictionary.assignee.find(o => o.value === r.value) as IOption<string>);
            }

            Object.assign(items, {
                rounds,
                services,
                status,
                assignee,
            });
        }

        return new Filter<IScheduleFilterItemDictionary | IScheduleSortItemDictionary>(items);
    }

    public static transactionsFilter(filterArguments: ITransactionsFilterArgs): Filter<ITransactionsFilterItemDictionary> {
        const sortByOptions: Array<IOption<ITransactionsFilterSortOption>> = [
            { name: 'sorting.by-newest-oldest', value: ITransactionsFilterSortOption.DATE_NEWEST_OLDEST },
            { name: 'sorting.by-oldest-newest', value: ITransactionsFilterSortOption.DATE_OLDEST_NEWEST },
        ];

        let statusOptions: Array<IOption<any>> = [];
        if (
            !Array.isArray(
                filterArguments.transactionType !== undefined &&
                !Array.isArray(filterArguments.transactionType) &&
                filterArguments.transactionType === TransactionType.Invoice
            )
        ) {
            statusOptions = [
                { name: 'filters.by-paid', value: 'paid' },
                { name: 'filters.by-pending-payment', value: 'pending' },
            ];
        }

        const items: ITransactionsFilterItemDictionary = {
            sortBy: {
                type: 'sortBy',
                name: 'filters.sort-by',
                optionNameKey: 'name',
                optionValueKey: 'value',
                options: sortByOptions,
                selectedOption: sortByOptions[0],
            },
            status: {
                type: 'filter',
                name: 'general.status',
                optionNameKey: 'name',
                optionValueKey: 'value',
                options: statusOptions,
            },
            dateRange: {
                type: 'dateRange',
                name: 'general.date-range',
                optionNameKey: 'rangeText',
                optionValueKey: 'value',
                options: [],
            },
        };
        return new Filter<ITransactionsFilterItemDictionary>(items);
    }

    public static expensesFilter(categories: Array<ExpenseCategory>): Filter<IExpensesFilterItemDictionary> {
        const sortByOptions: Array<IOption<ITransactionsFilterSortOption>> = [
            { name: 'sorting.by-newest-oldest', value: ITransactionsFilterSortOption.DATE_NEWEST_OLDEST },
            { name: 'sorting.by-oldest-newest', value: ITransactionsFilterSortOption.DATE_OLDEST_NEWEST },
        ];

        const categoriesOptions: Array<IOption<string>> = categories.map(c => {
            return { name: <TranslationKey>c.description, value: c._id };
        });

        const items: IExpensesFilterItemDictionary = {
            sortBy: {
                type: 'sortBy',
                name: 'filters.sort-by',
                optionNameKey: 'name',
                optionValueKey: 'value',
                options: sortByOptions,
                selectedOption: sortByOptions[0],
            },
            category: {
                type: 'filter',
                name: 'general.categories',
                optionNameKey: 'name',
                optionValueKey: 'value',
                options: categoriesOptions,
            },
            dateRange: {
                type: 'dateRange',
                name: 'general.date-range',
                optionNameKey: 'rangeText',
                optionValueKey: 'value',
                options: <Array<IOption<any>>>[],
            },
        };
        return new Filter<IExpensesFilterItemDictionary>(items);
    }
}
