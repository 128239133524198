import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { uuid } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { Logger } from '../Logger';
import { pickColour } from './Colourpicker';
import { CustomDialog } from './CustomDialog';
import type { ITinyMceTool } from './TinyMcePlugin';
import { TinyMcePlugin } from './TinyMcePlugin';

export class TextDialog extends CustomDialog<string> {
    public inputHeading: TranslationKey | '';

    constructor(
        public dialogTitle: TranslationKey,
        inputHeading: '' | TranslationKey,
        public value: string,
        public inputLabel: '' | TranslationKey,
        public _validate?: (v: string) => true | TranslationKey,
        protected isPassword = false,
        protected inputType: 'text' | 'email' | 'password' | 'number' | 'number-2dp' | 'url' | 'textarea' | 'colour' | 'tinymce' = 'text',
        protected okLabel: TranslationKey = 'general.save',
        protected editorTools?: Array<ITinyMceTool>,
        protected liveInfoMethod?: (value: string) => string,
        protected maxLength?: number,
        protected htmlIsFullpage?: boolean,
        protected localisationParams?: Record<string, string>,
        protected rows: number = 15,
        protected prefix?: string,
        protected suffix?: string,
        protected autocomplete?: string
    ) {
        super(
            'textDialog',
            './TextDialog.html',
            ApplicationState.localise(dialogTitle),
            inputType !== 'tinymce'
                ? {
                      isSecondaryView: true,
                      cssClass: 'text-dialog',
                      okLabel: '',
                      cancelLabel: '',
                  }
                : {
                      cssClass: 'text-dialog',
                      okLabel: '',
                      cancelLabel: '',
                      coverViewport: true,
                      smallerOnDesktop: false,
                  }
        );

        if (this.inputType === 'number-2dp') {
            this.inputType = 'number';
            this.step = '0.01';
        }

        this.inputHeading = inputHeading ? ApplicationState.localise(inputHeading, localisationParams) : '';
    }

    protected step: string | undefined;

    protected textFieldId = `txt_${uuid()}`;

    protected keypress = (e: KeyboardEvent) => {
        if (e.ctrlKey && e.key === 'Enter') this.ok();
    };
    static async show(
        title: string | TranslationKey,
        value: string,
        description: string | TranslationKey = title,
        label: string | TranslationKey = title,
        multiline = false
    ): Promise<string | false> {
        const textDialog = new TextDialog(
            title as TranslationKey,
            description as TranslationKey,
            value,
            label as TranslationKey,
            undefined,
            undefined,
            multiline ? 'textarea' : 'text'
        );
        const resp = await textDialog.show();
        if (textDialog.cancelled) return false;
        return resp;
    }
    protected tinyMcePluginInstance: TinyMcePlugin;
    private _closeColorPicker: undefined | (() => void);
    public async init() {
        if (this.inputType === 'tinymce') {
            this.tinyMcePluginInstance = new TinyMcePlugin(
                this.textFieldId,
                this.editorTools,
                this.htmlIsFullpage === false ? false : true
            );
        } else if (this.inputType === 'colour') {
            try {
                this._closeColorPicker = pickColour(this.textFieldId);
            } catch (error) {
                Logger.error('Failed to set colour picker', error);
            }
        }

        setTimeout(() => {
            const textInput = document.getElementById(this.textFieldId) as HTMLInputElement | HTMLTextAreaElement;
            if (textInput) {
                if (this.maxLength && this.maxLength > 0) {
                    textInput.maxLength = this.maxLength;
                }
                textInput.focus();
            }
        }, 150);
    }

    public async keydown(event: KeyboardEvent) {
        if (event.keyCode === 13) return this.ok();
    }

    protected get liveInfo() {
        return this.liveInfoMethod && this.liveInfoMethod(this.value);
    }
    public async onValidate(): Promise<true | TranslationKey> {
        if (!this._validate) return true;
        const validationResult = this._validate(this.value);
        return validationResult;
    }

    public async getResult(): Promise<string> {
        const textField = document.getElementById(this.textFieldId) as HTMLTextAreaElement | HTMLInputElement;
        this.value = (textField && textField.value) || '';
        return this.value;
    }

    public hasContent() {
        const div = document.createElement('div');
        div.innerHTML = this.value;

        return Boolean(div.innerText.trim());
    }
    onCancel = () => {
        this._closeColorPicker?.();
        return Promise.resolve(true);
    };

    public delegateOk = () => {
        const textField = document.getElementById(this.textFieldId) as HTMLTextAreaElement | HTMLInputElement;
        this.value = (textField && textField.value) || '';
        this._closeColorPicker?.();
        this.ok();
    };
}
