// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.job-form__content {
  padding: 0px 16px;
  max-width: 890px;
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/Jobs/Components/JobForm.scss"],"names":[],"mappings":"AACI;EACI,iBAAA;EACA,gBAAA;EACA,YAAA;AAAR","sourcesContent":[".job-form {\n    &__content {\n        padding: 0px 16px;\n        max-width: 890px;\n        margin: auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
