import type {
    AutomaticPaymentTransactionSubType,
    Currency,
    Expense,
    LedgerAccountSubType,
    LedgerAccountType,
    PaymentAccountProviders,
    Transaction,
    TransactionProvider,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import {
    Customer,
    LedgerAccountSubTypes,
    LedgerAccountTypes,
    PaymentAccount,
    TransactionType,
    TransferTransaction,
} from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import { DateTimePicker } from '../Components/DateTimePicker/DateTimePicker';
import { PaymentsMatchDialog } from '../ConnectedServices/Payments/PaymentsMatchDialog';
import { CustomerCreditDialog } from '../Customers/Components/CustomerCreditDialog';
import { CustomerFormDialog } from '../Customers/Components/CustomerFormDialog';
import { CustomerRefundDialog } from '../Customers/Components/CustomerRefundDialog';
import { SelectCustomerDialog } from '../Customers/Components/SelectCustomerDialog';
import { Data } from '../Data/Data';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { Prompt } from '../Dialogs/Prompt';
import { Select } from '../Dialogs/Select';
import { TextDialog } from '../Dialogs/TextDialog';
import { ExpenseFormDialog } from '../Expenses/Components/ExpenseFormDialog';
import { ExpenseService } from '../Expenses/ExpenseService';
import { TransactionService } from '../Transactions/TransactionService';
import { Utilities } from '../Utilities';

export type AccountLedgerTypeGroup = Array<{
    type: {
        label: string;
        balance?: number;
    };
    subTypes: Array<{
        label: string;
        balance?: number;
        accounts: PaymentAccount[];
    }>;
}>;

export class AccountsLedgerService {
    static async selectAccountForTransfer(provider?: PaymentAccountProviders, notThisOneId?: string) {
        let paymentAccount: PaymentAccount | undefined;
        const paymentAccounts = this.getAccountsForTransfer(provider, notThisOneId);
        const selectAccount = new Select<PaymentAccount>('payments.select-payment-account', paymentAccounts, 'name', '_id');
        paymentAccount = await selectAccount.show();
        return paymentAccount;
    }

    static async selectAccount(provider?: PaymentAccountProviders) {
        const paymentAccounts = this.getRealAccounts(provider);
        const selectAccount = new Select<PaymentAccount>('payments.select-payment-account', paymentAccounts, 'name', '_id');
        const paymentAccount = await selectAccount.show();
        return paymentAccount;
    }

    public static getAvatar(_paymentAccount: PaymentAccount): { colour: string; text: string } {
        let image: string | undefined;

        image = _paymentAccount.providerlogoUrl;

        const avatar = { colour: '', text: 'account_balance', image };
        return avatar;
    }

    static async createAccount() {
        let accountName = 'New Account';
        const accountNameDialog = new TextDialog(
            'New Account Name' as TranslationKey,
            '',
            accountName,
            '',
            undefined,
            false,
            'text',
            'Create Account' as TranslationKey
        );

        accountName = await accountNameDialog.show();
        if (accountNameDialog.cancelled) return;

        const newAccount = new PaymentAccount();
        newAccount.name = accountName;
        await Data.put(newAccount);
    }

    static async editAccountName(accountToEditName: PaymentAccount) {
        const accountNameDialog = new TextDialog(
            'Account Name' as TranslationKey,
            '',
            accountToEditName.name,
            '',
            undefined,
            false,
            'text',
            'general.save'
        );

        const editName = await accountNameDialog.show();
        if (accountNameDialog.cancelled) return;
        accountToEditName.name = editName;
        await Data.put(accountToEditName);
    }
    static async editOpeningBalance(accountToEditName: PaymentAccount) {
        const editBalanceDlg = new TextDialog(
            'Opening Balance' as TranslationKey,
            '',
            (accountToEditName.openingBalance || 0).toFixed(2),
            '',
            undefined,
            false,
            'number-2dp',
            'general.save'
        );

        const value = await editBalanceDlg.show();
        if (editBalanceDlg.cancelled) return;

        if (isNaN(Number(value))) return;

        accountToEditName.openingBalance = Number(Number(value).toFixed(2));
        await Data.put(accountToEditName);
    }

    static async deleteAccount(accountToDelete: PaymentAccount) {
        const transactions = TransactionService.getAccountTransactions(undefined, accountToDelete._id);
        const builtIns = [ApplicationState.creditorAccountId, ApplicationState.debtorAccountId, ApplicationState.taxAccountId];
        const isBuiltIn = builtIns.includes(accountToDelete._id);
        if (!isBuiltIn && transactions.length > 0) {
            const alert = new Prompt(
                'Unable to Delete' as TranslationKey,
                'This account has transactions, you can only delete accounts without transactions' as TranslationKey,
                { cancelLabel: '' }
            );
            await alert.show();
            return;
        }

        const prompt = new Prompt('prompts.confirm-title', 'Are you sure you wish to delete this payment account?' as TranslationKey, {
            okLabel: 'general.delete',
        });
        await prompt.show();
        if (prompt.cancelled) return;

        const toDelete = [accountToDelete];
        Data.delete(toDelete);
        return true;
    }

    static async moveTransactions(fromAccount: PaymentAccount) {
        // Don't move for built in accounts and notify the user
        const builtIns = [ApplicationState.creditorAccountId, ApplicationState.debtorAccountId, ApplicationState.taxAccountId];
        const isBuiltIn = builtIns.includes(fromAccount._id);
        if (isBuiltIn) {
            const alert = new Prompt('general.error', 'prompts.unable-to-move-transactions-from-built-in-account', { cancelLabel: '' });
            await alert.show();
            return;
        }

        // Select the account to which the transactions will be moved
        const toAccount = await AccountsLedgerService.selectAccountForTransfer(undefined, fromAccount._id);
        // If no account is selected, return
        if (!toAccount) return;

        // Get all transactions associated with the fromAccount
        const transactions = TransactionService.getAccountTransactions(undefined, fromAccount._id);

        // Create a prompt to confirm the move of the transactions to the selected account
        const prompt = new Prompt(
            'prompts.confirm-title',
            `Are you sure you wish to move these ${transactions.length} transactions to "${toAccount.name}" account?` as unknown as TranslationKey,
            {
                okLabel: 'general.confirm',
            }
        );
        await prompt.show();
        // If the prompt is cancelled, return
        if (prompt.cancelled) return;

        // Iterate through all transactions and update their paymentAccountId and paymentDetails.paymentAccountId properties
        for (const tran of transactions) {
            const tx = tran as Expense | TransferTransaction;
            if (tx.paymentAccountId) tx.paymentAccountId = toAccount._id;
            if (tran.paymentDetails?.paymentAccountId) tran.paymentDetails.paymentAccountId = toAccount._id;

            // If it has a void move that too
            if (!tran.voidedId) continue;

            const voidedTran = Data.get<Expense | TransferTransaction>(tran.voidedId);
            if (!voidedTran) continue;

            if (voidedTran.paymentAccountId) voidedTran.paymentAccountId = toAccount._id;
            if (voidedTran.paymentDetails?.paymentAccountId) voidedTran.paymentDetails.paymentAccountId = toAccount._id;
        }

        // Save the updated transactions
        await Data.put(transactions);

        // Return true if the transactions were successfully moved, otherwise return undefined
        return true;
    }

    static async setType(account: PaymentAccount) {
        const type = await this.selectLedgerAccountType(account.type);
        if (!type) return;
        if (type.id === 'accounts.not-on-balance-sheet') {
            account.type = undefined;
            account.subType = undefined;
        } else {
            const subType = await this.selectLedgerAccountSubType(type.id, account.subType);
            if (!subType) return;
            account.type = type.id;
            account.subType = subType.id;
        }

        Data.put(account);
        return true;
    }

    static async selectLedgerAccountType(currentType?: LedgerAccountType) {
        const types = this.getLedgerAccountTypes();
        const selType = new Select<{ id: LedgerAccountType; desc: string }>(
            'payment-accounts-select-type' as TranslationKey,
            types,
            'desc',
            'id',
            currentType
        );
        return await selType.show();
    }

    static async selectLedgerAccountSubType(type: LedgerAccountType, currentSubType?: LedgerAccountSubType) {
        const types = this.getLedgerAccountSubTypes(type);
        const selType = new Select<{ id: LedgerAccountSubType; desc: string }>(
            'payment-accounts-select-sub-type' as TranslationKey,
            types,
            'desc',
            'id',
            currentSubType
        );
        return await selType.show();
    }

    static getLedgerAccountTypes() {
        return LedgerAccountTypes.map(x => {
            return { id: x as LedgerAccountType, desc: ApplicationState.localise(x as TranslationKey) };
        });
    }

    static getLedgerAccountSubTypes(type?: LedgerAccountType) {
        return LedgerAccountSubTypes.filter(x => !type || x.startsWith(type)).map(x => {
            return { id: x as LedgerAccountSubType, desc: ApplicationState.localise(x as TranslationKey) };
        });
    }

    static async getAccountLedgerBalances(accounts: Array<PaymentAccount>, balanceDate?: string, balanceDate2?: string) {
        const balances: Partial<Record<string, number>> = {};
        const balances2: Partial<Record<string, number>> = {};

        for (const account of accounts) {
            const accBalances = this.getAccountLedgerBalance(account, balanceDate, balanceDate2);

            balances[account._id] = accBalances.balance;

            const key = account.type || 'accounts.not-on-balance-sheet';
            balances[key] = (balances[key] || 0) + (accBalances.balance || 0);

            const subKey = account.subType || 'accounts.sub-not-on-balance-sheet';
            balances[subKey] = (balances[subKey] || 0) + (accBalances.balance || 0);

            if (balanceDate2) {
                balances2[account._id] = accBalances.balance2;
                balances2[key] = (balances2[key] || 0) + (accBalances.balance2 || 0);
                balances2[subKey] = (balances2[subKey] || 0) + (accBalances.balance2 || 0);
            }
        }

        return { balances, balances2 };
    }

    static getAccountLedgerBalance(account: PaymentAccount, balanceDate?: string, balanceDate2?: string) {
        let balance = account.openingBalance || 0;
        let balance2 = account.openingBalance || 0;
        if (!balanceDate) balanceDate = moment().format('YYYY-MM-DD');
        const transactions = TransactionService.getAccountTransactions(undefined, account._id);
        for (const tran of transactions) {
            if (balanceDate2 && tran.date.substring(0, 10) <= balanceDate2.substring(0, 10)) {
                balance2 -= tran.amount || 0;
            }
            if (balanceDate && tran.date.substring(0, 10) <= balanceDate.substring(0, 10)) {
                balance -= tran.amount || 0;
            }
        }
        return { balance, balance2 };
    }

    static async getExpenditureBalance() {
        let balance = 0;
        const transactions = await ExpenseService.getExpenses();
        for (const tran of transactions) {
            balance -= tran.amount || 0;
        }
        return balance;
    }
    static getIncomeBalance(): number {
        let balance = 0;
        const transactions = TransactionService.getInvoices();
        for (const tran of transactions) {
            balance += tran.amount || 0;
        }
        return balance;
    }
    public static getAccountsForTransfer(provider?: PaymentAccountProviders, notThisOneId?: string) {
        const realAccounts = this.getRealAccounts(provider, notThisOneId);
        return realAccounts;
    }

    public static getRealAccounts(provider?: PaymentAccountProviders, notThisOneId?: string) {
        return (
            (Data.all<PaymentAccount>(
                'paymentaccounts',
                pa => (!notThisOneId || pa._id != notThisOneId) && (!provider || !!pa.externalIds?.[provider])
            ) as Array<PaymentAccount>) || []
        );
    }

    public static getAccountLedgersGroupedByType(includeAccountsNotOnBalanceSheet: boolean): AccountLedgerTypeGroup {
        const accountsByType: {
            [text: string]: {
                id: string;
                subtypes: {
                    [text: string]: {
                        id: string;
                        label: string;

                        accounts: PaymentAccount[];
                    };
                };
                label: string;
            };
        } = {};

        const items = Data.all<PaymentAccount>('paymentaccounts').filter(a => includeAccountsNotOnBalanceSheet || !!a.type);

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            const group = item.type || 'accounts.not-on-balance-sheet';
            const subGroup = item.subType || 'accounts.sub-not-on-balance-sheet';
            if (!accountsByType[group])
                accountsByType[group] = { id: group, subtypes: {}, label: ApplicationState.localise(group as TranslationKey) };

            if (!accountsByType[group].subtypes[subGroup])
                accountsByType[group].subtypes[subGroup] = {
                    id: subGroup,
                    accounts: [],
                    label: ApplicationState.localise(subGroup as TranslationKey),
                };

            accountsByType[group].subtypes[subGroup].accounts.push(item);
        }

        return Object.keys(accountsByType).map(groupKey => {
            const type = accountsByType[groupKey];

            const subTypes = Object.keys(type.subtypes).map(subGroupKey => {
                return type.subtypes[subGroupKey];
            });
            return { type, subTypes };
        });
    }

    public static async createTransferIn(toAccount: PaymentAccount, date?: string, amount?: number, fromAccountId?: string) {
        const fromAccount = !fromAccountId
            ? await AccountsLedgerService.selectAccountForTransfer(undefined, toAccount._id)
            : await Data.get<PaymentAccount>(fromAccountId);
        if (!fromAccount) return;
        return await this.transfer(fromAccount, toAccount, date, amount);
    }
    public static async transferOut(fromAccount: PaymentAccount, date?: string, amount?: number) {
        const transfer = await AccountsLedgerService.createTransferOut(fromAccount, date, amount);
        if (!transfer) return;

        await Data.put([transfer.from, transfer.to]);
    }
    public static async transferIn(toAccount: PaymentAccount, date?: string, amount?: number, fromAccountId?: string) {
        const transfer = await AccountsLedgerService.createTransferIn(toAccount, date, amount, fromAccountId);
        if (!transfer) return;

        await Data.put([transfer.from, transfer.to]);
    }
    public static async createTransferOut(fromAccount: PaymentAccount, date?: string, amount?: number, toAccountId?: string) {
        const toAccount = !toAccountId
            ? await AccountsLedgerService.selectAccountForTransfer(undefined, fromAccount._id)
            : await Data.get<PaymentAccount>(toAccountId);
        if (!toAccount) return;
        return await this.transfer(fromAccount, toAccount, date, amount);
    }
    private static async transfer(fromAccount: PaymentAccount, toAccount: PaymentAccount, date?: string, amount?: number) {
        if (!date) {
            const datePicker = new DateTimePicker(false, undefined, 'transfer.date' as TranslationKey);
            await datePicker.init();
            await datePicker.open();
            date = datePicker.selectedDate;
        }
        if (!date) return;
        if (!amount) {
            const transferAmount = new TextDialog(
                'Transfer amount' as TranslationKey,
                'Transfer amount' as TranslationKey,
                '0.00',
                '',
                undefined,
                undefined,
                'number-2dp'
            );
            const response = await transferAmount.show();
            if (!response) return;
            if (Number(response)) {
                amount = Number(response);
            }
        }
        if (!amount) return;
        const fromAccountTran = new TransferTransaction(
            'transfer.out',
            amount,
            'Transfer to ' + toAccount.name,
            date,
            fromAccount._id,
            toAccount._id,
            ''
        );

        const toAccountTran = new TransferTransaction(
            'transfer.in',
            amount,
            'Transfer from ' + fromAccount.name,
            date,
            toAccount._id,
            fromAccount._id,
            fromAccountTran._id
        );

        fromAccountTran.targetTransactionid = toAccountTran._id;

        return { from: fromAccountTran, to: toAccountTran };
    }

    public static async enableVATLedger() {
        const ledger = new PaymentAccount();
        ledger.name = 'VAT';
        ledger._id = ApplicationState.dataEmail + '-tax';
        ledger.type = 'liability.current';
        ledger.subType = 'liability.current-vat';
        ledger.providerlogoUrl = `./images/provider-hmrc.svg`;
        ledger.provider = 'HMRC';
        ledger.dontAllowExpenses = true;
        ledger.dontAllowPayments = true;

        await Data.put(ledger);
    }

    public static async enableDebtorsLedger() {
        const ledger = new PaymentAccount();
        ledger.name = 'Debtors';
        ledger._id = ApplicationState.dataEmail + '-debtors';
        ledger.type = 'asset.current';
        ledger.subType = 'asset.current-account-receivable';
        ledger.dontAllowExpenses = true;
        ledger.dontAllowPayments = false;
        await Data.put(ledger);
    }

    public static async enableCreditorsLedger() {
        const ledger = new PaymentAccount();
        ledger.name = 'Creditors';
        ledger._id = ApplicationState.dataEmail + '-creditors';
        ledger.type = 'liability.current';
        ledger.subType = 'liability.current-accounts-payable';
        ledger.dontAllowExpenses = false;
        ledger.dontAllowPayments = true;

        await Data.put(ledger);
    }

    public static async enableStripePaymentAccount() {
        // Update all stripe payments to use this paymentAccountId;
        const dataToSave = [];

        const trans = Data.all<Transaction>(
            'transactions',
            x =>
                x.transactionSubType === 'payment.stripe' ||
                x.transactionSubType === 'auto.stripe' ||
                x.transactionSubType === 'refund.stripe'
        );

        const stripePaymentAccounts: ReadonlyArray<PaymentAccount> = Data.all<PaymentAccount>(
            'paymentaccounts',
            x => x.provider === 'stripe'
        );

        const stripePaymentAccountsDict = stripePaymentAccounts.reduce((acc: Record<string, PaymentAccount>, curr) => {
            acc[curr._id] = curr;
            return acc;
        }, {});

        for (const t of trans) {
            if (!t.paymentDetails) t.paymentDetails = {};
            const currency: keyof Currency = (t.currency?.toUpperCase() ||
                t.paymentDetails?.currency?.toUpperCase() ||
                ApplicationState.account.currency ||
                'GBP') as keyof Currency;

            const paymentAccountId = `${ApplicationState.dataEmail}-stripepaymentaccount-${currency}`;
            if (!stripePaymentAccountsDict[paymentAccountId]) {
                // Create a new stripe payment account for this currency
                const newStripeAccount = new PaymentAccount();
                newStripeAccount.name = `Stripe ${currency}`;
                newStripeAccount._id = paymentAccountId;
                newStripeAccount.externalIds = { stripe: 'stripe' };
                newStripeAccount.providerlogoUrl = `./images/provider-stripe.png`;
                newStripeAccount.provider = 'Stripe connected service';
                newStripeAccount.currency = currency;
                dataToSave.push(newStripeAccount);
                stripePaymentAccountsDict[paymentAccountId] = newStripeAccount;
            }

            if (t.paymentDetails.paymentAccountId === paymentAccountId) continue;

            t.paymentDetails.paymentAccountId = paymentAccountId;
            t.currency = t.currency?.toUpperCase() as keyof Currency;

            dataToSave.push(t);
        }

        await Data.put(dataToSave);
    }

    static getCreditorLedger(): Array<Transaction> {
        const trans = Data.all<Expense>('transactions', t => t.transactionType === TransactionType.Expense).slice();

        const credTrans: Array<Transaction> = [];
        for (const tran of trans) {
            if (tran.amount === 0) continue;

            credTrans.push(Utilities.copyObject(tran));

            if (tran.paymentDate) {
                const expPayment = Utilities.copyObject(tran);
                expPayment._id = tran._id + '-payment';
                expPayment.description = expPayment.description + ' (Payment)';
                expPayment.transactionType = TransactionType.Unreconciled;
                expPayment.date = tran.paymentDate;
                expPayment.amount = -tran.amount;
                credTrans.push(expPayment);
            }
        }

        return credTrans;
    }

    public static async enableGoCardlessPaymentAccount() {
        const gcAccount = new PaymentAccount();
        gcAccount._id = ApplicationState.dataEmail + '-gcpaymentaccount';
        gcAccount.name = 'GoCardless';
        gcAccount.externalIds = { gocardless: 'gocardless' };
        gcAccount.providerlogoUrl = `./images/provider-gocardless.png`;
        gcAccount.provider = 'GoCardless connected service';
        const dataToSave = [];
        dataToSave.push(gcAccount);
        const trans = Data.all<Transaction>('transactions', x => x.transactionSubType === 'auto.go-cardless');

        for (const t of trans) {
            if (!t.paymentDetails) t.paymentDetails = {};
            t.paymentDetails.paymentAccountId = gcAccount._id;
            dataToSave.push(t);
        }

        await Data.put(dataToSave);
        await ApplicationState.setSetting<string, AutomaticPaymentTransactionSubType>(
            'global.payment-account',
            gcAccount._id,
            'auto.go-cardless'
        );
        //Update all gocar
    }

    public static getGoCardlessPaymentAccount() {
        return Data.get(ApplicationState.dataEmail + '-gcpaymentaccount');
    }

    public static getStripePaymentAccount() {
        return Data.get(`${ApplicationState.dataEmail}-stripepaymentaccount-${ApplicationState.account.currency || 'GBP'}`);
    }

    public static async selectOrCreateCustomer() {
        const selectCustomerDialog = new SelectCustomerDialog(new Customer(), undefined, undefined, undefined, true);
        let selectedCustomer = await selectCustomerDialog.show(DialogAnimation.SLIDE_UP);
        if (selectCustomerDialog.cancelled || !selectedCustomer) return;

        if (selectCustomerDialog.customerIsNew) {
            const customerDialog = new CustomerFormDialog(selectedCustomer);
            selectedCustomer = await customerDialog.show(DialogAnimation.SLIDE_UP);
            if (customerDialog.cancelled) return;
        }
        return selectedCustomer;
    }

    public static async createRefund(paymentAccount: PaymentAccount) {
        const customer = await this.selectOrCreateCustomer();

        if (!customer) return;
        const paymentDialog = CustomerRefundDialog.createForCustomer(customer);

        paymentDialog.paymentAccount = paymentAccount;
        paymentDialog.paymentAccountId = paymentAccount._id;
        const payment = await paymentDialog.show();
        if (paymentDialog.cancelled || !payment) return;
    }

    public static async createPayment(paymentAccount: PaymentAccount) {
        const customer = await this.selectOrCreateCustomer();

        if (!customer) return;
        const paymentDialog = CustomerCreditDialog.createForCustomer(customer);
        paymentDialog.paymentAccount = paymentAccount;
        paymentDialog.paymentAccountId = paymentAccount._id;
        const payment = await paymentDialog.show();
        if (paymentDialog.cancelled || !payment) return;
    }

    public static async createExpense(paymentAccount: PaymentAccount) {
        if (paymentAccount.dontAllowExpenses) {
            return;
        }
        if (paymentAccount.type === 'expenditure' || paymentAccount.type === 'asset.current' || paymentAccount.type === 'asset.fixed') {
            const dialog = new ExpenseFormDialog(undefined, undefined, paymentAccount);
            await dialog.show(DialogAnimation.SLIDE_UP);
            return;
        }
        if (!paymentAccount.type || paymentAccount.subType === 'asset.current-bank-account') {
            const dialog = new ExpenseFormDialog(undefined, paymentAccount, undefined);
            await dialog.show(DialogAnimation.SLIDE_UP);
            return;
        }
    }

    public static getUnreconciledTrans(paymentAccount: PaymentAccount) {
        const unreconciledTrans = Data.all<Transaction>(
            'transactions',
            x => x.accountId === paymentAccount._id && x.status === 'unreconciled'
        );
        return unreconciledTrans;
    }
    public static async reconcile(paymentAccount: PaymentAccount) {
        const unreconciledTrans = this.getUnreconciledTrans(paymentAccount);
        if (!paymentAccount.provider) return;
        const providerLabel = ApplicationState.localise(`providers.${paymentAccount.provider.split('.')[0]}-name` as TranslationKey);
        const dialog = new PaymentsMatchDialog(
            ApplicationState.localise('matcher.import-provider-payments', { provider: providerLabel || '' }),
            unreconciledTrans.slice(),
            paymentAccount.provider as TransactionProvider,
            paymentAccount
        );
        dialog.show();
    }
}
