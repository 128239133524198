import { Linker } from '@nexdynamic/squeegee-common';
import { Data } from '../Data/Data';


export const migrateLinkers = async (oldId: string, newId: string) => {
    // WTF Check if there are any linkers attached to this occurrence as we need to update them
    if (oldId === newId) return;

    const linkers = Data.all<Linker>('linkers', l => Linker.getParentId(l) === oldId).slice();
    const newLinkers = linkers.map(l => Linker.clone(l, newId));

    if (linkers) {
        await Data.delete(linkers);
        await Data.put(newLinkers);
    }
};
