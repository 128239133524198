import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { computedFrom } from 'aurelia-framework';
import { ApplicationEnvironment } from '../ApplicationEnvironment';
import { ApplicationState } from '../ApplicationState';
import { CustomDialog } from '../Dialogs/CustomDialog';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { NumpadDialog } from '../Dialogs/Numpad/NumpadDialog';
import { Select } from '../Dialogs/Select';
import { TextDialog } from '../Dialogs/TextDialog';
import { GlobalFlags } from '../GlobalFlags';
import { Logger } from '../Logger';
import { Api } from '../Server/Api';

export class DeveloperLaunchOptionsDialog extends CustomDialog<void> {
    protected stateFlags = ApplicationState.stateFlags;
    protected isHttp = GlobalFlags.isHttp;
    protected apiEndpoint = Api.apiEndpoint;
    public static developerEndPointValues: Array<{ text: TranslationKey; value: string }> = [
        { text: 'developer.auto', value: '' },
        { text: 'sqgee.com' as TranslationKey, value: 'https://sqgee.com' },
        { text: 'api01.sqgee.com' as TranslationKey, value: 'https://api01.sqgee.com' },
        { text: 'api02.sqgee.com' as TranslationKey, value: 'https://api02.sqgee.com' },
        { text: 'api03.sqgee.com' as TranslationKey, value: 'https://api03.sqgee.com' },
        { text: 'api04.sqgee.com' as TranslationKey, value: 'https://api04.sqgee.com' },
        { text: 'api05.sqgee.com' as TranslationKey, value: 'https://api05.sqgee.com' },
        { text: 'api06.sqgee.com' as TranslationKey, value: 'https://api06.sqgee.com' },
        { text: 'processor.sqgee.com' as TranslationKey, value: 'https://api05.sqgee.com' },
        { text: 'webhook.sqgee.com' as TranslationKey, value: 'https://api05.sqgee.com' },
        { text: 'backup.sqgee.com' as TranslationKey, value: 'https://api05.sqgee.com' },
        { text: 'staging.squeeg.ee' as TranslationKey, value: 'https://staging.squeeg.ee' },
        { text: 'dev.squeeg.ee' as TranslationKey, value: 'https://dev.squeeg.ee' },
        { text: 'localhost:3000' as TranslationKey, value: 'http://localhost:3000' },
        { text: 'global.manual-specify', value: 'manual' },
    ];

    constructor() {
        super('developerLaunchOptionsDialog', '../Launch/DeveloperLaunchOptionsDialog.html', undefined, {
            okLabel: '',
            cancelLabel: '',
            isSecondaryView: true,
        });
        this.dataBatchSize = ApplicationEnvironment.dataBatchSize;
    }

    protected async setSqueegeeApiEndpointOverride() {
        const select = new Select(
            'prompts.developer-endpoint-set-title',
            DeveloperLaunchOptionsDialog.developerEndPointValues,
            'text',
            'value',
            this.apiEndpoint
        );
        const result = await select.show(DialogAnimation.SLIDE);
        if (!select.cancelled) {
            if (result.value === 'manual') {
                const endpointDialog = new TextDialog(
                    'prompts.developer-endpoint-set-title',
                    '',
                    this.apiEndpoint,
                    '',
                    undefined,
                    false,
                    'url'
                );
                const endpoint = await endpointDialog.show();
                if (!endpointDialog.cancelled) {
                    await Api.overrideApiEndpoint(endpoint);
                    this.apiEndpoint = endpoint;
                }
                return;
            }

            if (result.value === this.apiEndpoint) {
                Logger.info(`Same endpoint (${result.value}) selected`);
                return;
            }

            ApplicationState.apiEndpointOverride = '';
            if (result.value === '') {
                await Api.overrideApiEndpoint();
                this.apiEndpoint = Api.apiEndpoint;
            } else {
                await Api.overrideApiEndpoint(result.value);
                this.apiEndpoint = result.value;
            }

            Api.refreshConnectionMetadata(true);
        }
    }

    protected dataBatchSize: number;

    protected async setDataBatchSize() {
        const dialog = new NumpadDialog(this.dataBatchSize, undefined, undefined, true);
        const result = await dialog.show();
        if (!dialog.cancelled) {
            ApplicationEnvironment.dataBatchSize = result;
            this.dataBatchSize = result;
        }
    }

    protected localisationDebuggingEnabledInfo = `${ApplicationState.localise('developer.localisation-debugging')} 
           ${ApplicationEnvironment.i18nDebugEnabled
            ? ApplicationState.localise('general.enabled')
            : ApplicationState.localise('general.disabled')
        }`;
    protected toggleLocalisationDebugging() {
        ApplicationEnvironment.i18nDebugEnabled = !ApplicationEnvironment.i18nDebugEnabled;
        this.localisationDebuggingEnabledInfo = `${ApplicationState.localise('developer.localisation-debugging')} 
           ${ApplicationEnvironment.i18nDebugEnabled
                ? ApplicationState.localise('general.enabled')
                : ApplicationState.localise('general.disabled')
            }`;
        ApplicationState.refreshLocale();
    }

    @computedFrom('apiEndpoint')
    protected get apiEndpointText() {
        return `${this.apiEndpoint} (${ApplicationState.apiEndpointOverride ? 'overridden' : 'auto'})`;
    }

    protected apiEndpointOverridden() {
        return !!ApplicationState.apiEndpointOverride;
    }
}
