import type { JobOccurrence, ServiceTagInstances } from '@nexdynamic/squeegee-common';
import { JobOccurrenceTagInstances } from '@nexdynamic/squeegee-common';
import { Data } from '../../Data/Data';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';

type TagServiceInstance = { id: string; name: string, tags: Array<{ label: string; tag: string; }> }

export class JobOccurrenceTags extends CustomDialog<string> {
    private jobOccurrenceTagInstances: JobOccurrenceTagInstances;
    protected highlightEmpty = false;

    constructor(private jobOccurrence: JobOccurrence) {
        super('htmlDialog', '../Jobs/Components/JobOccurrenceTags.html', 'services.set-service-tags', {
            cssClass: 'text-dialog',
            isSecondaryView: true,
            okLabel: '',
            cancelLabel: '',
        });

        const id = JobOccurrenceTagInstances.getId(jobOccurrence._id);
        this.jobOccurrenceTagInstances = Data.get<JobOccurrenceTagInstances>(id) || new JobOccurrenceTagInstances(jobOccurrence._id);
    }

    protected tagServiceInstances: Array<TagServiceInstance>;

    public async init() {
        const tagServiceInstances = new Array<TagServiceInstance>();

        const existingTags = JobOccurrenceTagInstances.getTags(this.jobOccurrenceTagInstances);

        for (const s of this.jobOccurrence.services) {
            if (!s.requiresTagging) continue;

            const existingService = existingTags.find(t => t.serviceId === s._id);
            const tags = new Array<{ label: string, tag: string }>();

            if (s.requiresTagging === 'instance') {
                for (let i = 0; i <= ((s.quantity || 1) - 1); i++) {
                    tags.push({ label: `Tag ${i + 1}`, tag: existingService?.tagIds?.[i] || '' });
                }
            } else {
                tags.push({ label: 'Tag Text', tag: existingService?.tagIds?.[0] || '' });
            }

            tagServiceInstances.push({ id: s._id, name: s.description, tags });
        }

        this.tagServiceInstances = tagServiceInstances;
    }

    public delegateOk = () => {
        this.highlightEmpty = true;
        const serviceTagInstancesCollection = new Array<ServiceTagInstances>();
        for (const t of this.tagServiceInstances) {
            const serviceTagInstances: ServiceTagInstances = {
                serviceId: t.id,
                tagIds: []
            }
            for (const tag of t.tags) {
                if (!tag.tag) return new NotifyUserMessage('services.tags-are-required');
                serviceTagInstances.tagIds.push(tag.tag);
            }
            serviceTagInstancesCollection.push(serviceTagInstances);
        }
        JobOccurrenceTagInstances.setTags(this.jobOccurrenceTagInstances, serviceTagInstancesCollection);
        Data.put(this.jobOccurrenceTagInstances);
        this.ok();
    };
}
