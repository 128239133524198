import type { StoredObjectResourceTypes } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom } from 'aurelia-framework';
import type { Subscription } from '../../Events/DataRefreshedEvent';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import './badge.scss';

export class Badge {
    @bindable() count: number;
    @bindable() showBadge = true;
    @bindable() getter?: () => number;
    @bindable() listenFor?: Array<StoredObjectResourceTypes>;
    @bindable() subscription?: (badge: Badge) => () => void;
    private _subscriptionDisposer?: () => void;

    //modifiers
    @bindable() offset: boolean;
    @bindable() pulse: boolean;
    @bindable() color?: 'success' | 'error' | 'default' = 'default';

    protected countFormatted: string;
    protected fontSize: string;

    private _dataRefreshedEventSubscription?: Subscription;

    attached() {
        const eventTypes = this.listenFor;
        if (eventTypes) {
            this._dataRefreshedEventSubscription = DataRefreshedEvent.subscribe(
                (event: DataRefreshedEvent) => event.hasAnyType(...eventTypes) && this.updateValueFromGetter()
            );
        }
        if (this.getter) this.updateValueFromGetter();
        if (this.subscription) this._subscriptionDisposer = this.subscription(this);
    }

    updateValueFromGetter = () => {
        if (this.getter) {
            this.count = this.getter();
            if (this.count > 0) this.showBadge = true;
            else this.showBadge = false;
        }
    };

    countChanged() {
        if (!this.count) {
            this.countFormatted = '';
        } else if (this.count > 99) {
            this.countFormatted = '99+';
        } else {
            this.countFormatted = this.count.toString();
        }
    }

    @computedFrom('offset', 'pulse', 'color', 'count')
    get modifiers() {
        const classes: Array<string> = [];
        if (this.offset) classes.push('badge--offset');
        if (this.pulse) classes.push('badge--pulse');
        if (this.color) classes.push(`badge--${this.color}`);
        if (this.count > 99) classes.push('badge--high-count');
        if (!this.count) classes.push('badge--dot');

        return classes.join(' ');
    }

    detached() {
        this._dataRefreshedEventSubscription?.dispose();
        this._subscriptionDisposer?.();
    }
}
