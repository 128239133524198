import type { Disposable, ICollectionObserverSplice } from 'aurelia-framework';
import { BindingEngine, TaskQueue, bindable, inject } from 'aurelia-framework';
import { GlobalFlags } from '../../GlobalFlags';
import { Logger } from '../../Logger';
import type { IFabAction } from './IFabAction';

@inject(TaskQueue, BindingEngine)
export class ExpandableFab {
    @bindable() protected actions: Array<IFabAction>;

    public fabExpanded = false;
    public obfuscator: HTMLElement;
    public showFab: boolean;
    public showChildFabs = false;
    private running = false;

    protected get isApple() {
        return GlobalFlags.isAppleMobileApp;
    }

    private actionsObserver: Disposable;

    constructor(private taskQueue: TaskQueue, private bindingEngine: BindingEngine) {}

    public attached() {
        this.obfuscator.style.display = 'none';
        this.obfuscator.addEventListener('transitionend', () => {
            this.fabExpanded ? (this.obfuscator.style.display = 'block') : (this.obfuscator.style.display = 'none');
        });
        this.obfuscator.addEventListener('webkitTransitionEnd', () => {
            this.fabExpanded ? (this.obfuscator.style.display = 'block') : (this.obfuscator.style.display = 'none');
        });

        this.taskQueue.queueMicroTask({
            call: () => setTimeout(() => (this.showFab = true), 250),
        });

        this.actionsObserver = this.bindingEngine.collectionObserver(this.actions).subscribe(this.actionsChanged.bind(this));
    }

    public detached() {
        if (this.actionsObserver) this.actionsObserver.dispose();
    }

    private actionsChanged(splices?: Array<ICollectionObserverSplice<IFabAction>>) {
        if (splices && splices.length) {
            this.closeFab();
        }
    }

    public toggleFab() {
        this.fabExpanded ? this.closeFab() : this.openFab();
    }

    public async openFab(force = false) {
        if (!force && (this.fabExpanded === true || this.running)) return;
        if (!this.actions.length) return;
        this.running = true;
        this.fabExpanded = true;
        this.showChildFabs = true;
        this.toggleObfuscator(true);
        await this.toggleChildFabs(true);
        this.running = false;
    }

    public async closeFab() {
        if (this.fabExpanded === false || this.running) return;
        this.running = true;
        this.fabExpanded = false;
        await this.toggleChildFabs(false);
        this.toggleObfuscator(false);
        this.running = false;
    }

    public async fireAction(action: IFabAction) {
        this.closeFab();
        try {
            action.handler && (await action.handler());
        } catch (error) {
            Logger.error('Error during fire the iFabAction', { action, error });
        }
    }

    private async toggleChildFabs(expand: boolean) {
        if (this.actions.length) {
            const fabs = expand ? this.actions.slice().reverse() : this.actions;
            for (let i = 0; i < fabs.length; i++) {
                await this.toggleChildFab(fabs[i], expand);
            }
        }

        if (expand === false) this.hideChildFabs();
    }

    private toggleChildFab(action: IFabAction, expand: boolean) {
        return new Promise<void>(resolve => {
            setTimeout(() => {
                action.expanded = expand;
                resolve();
            }, 50);
        });
    }

    private toggleObfuscator(show: boolean) {
        if (!this.obfuscator) return;
        if (show) {
            this.obfuscator.style.opacity = '0.6';
            this.obfuscator.style.display = 'block';
        } else {
            this.obfuscator.style.opacity = '0';
        }
    }

    private hideChildFabs() {
        setTimeout(() => {
            this.showChildFabs = false;
        }, 100);
    }
}
