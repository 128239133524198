import { Location } from '@nexdynamic/squeegee-common';
import { bindable, bindingMode, customElement, inject } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { AddressLookupDialog } from '../../Dialogs/AddressLookupDialog';
import { LocationUtilities } from '../../Location/LocationUtilities';
import { Api } from '../../Server/Api';
import { Utilities } from '../../Utilities';

@customElement('location-picker')
@inject(Element)
export class LocationPicker {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) location: Location;
    @bindable({ defaultBindingMode: bindingMode.oneWay }) failedValidation: boolean;

    public stateFlags = ApplicationState.stateFlags;
    public multiLineAddressDescription: string;
    public addressInput: HTMLTextAreaElement;

    attached() {
        this.updateMultiLineAddressDescription();
        this.addressInput.addEventListener('focus', this.onFocus);
    }

    detached() {
        if (this.addressInput) this.addressInput.removeEventListener('focus', this.onFocus);
    }

    private onFocus = (event: FocusEvent) => {
        event.preventDefault();
        event.stopPropagation();
        this.showAddressLookupDialog();
    };

    public updateMultiLineAddressDescription() {
        if (this.location && this.location.addressDescription) {
            this.multiLineAddressDescription = Location.getMultiLineAddressDescription(this.location && this.location.addressDescription);
        } else {
            this.multiLineAddressDescription = '';
        }
        if (this.addressInput) this.addressInput.rows = this.getNumberOfAddressInputRows();
    }

    public getNumberOfAddressInputRows(): number {
        if (this.multiLineAddressDescription) {
            return (this.multiLineAddressDescription.match(/\n/g) || []).length + 1;
        }
        return 1;
    }

    public async showAddressLookupDialog() {
        const dialog = new AddressLookupDialog(this.location);
        const location = await dialog.show();
        if (!dialog.cancelled) {
            this.location = Utilities.copyObject(location);
            this.updateMultiLineAddressDescription();
        }
    }

    public async pinAddress() {
        if (Api.isConnected) {
            await LocationUtilities.pinAddress(this.location);
            this.updateMultiLineAddressDescription();
        }
    }
}
