import type { Customer, JobOccurrence, Notification, Quote, Transaction } from '@nexdynamic/squeegee-common';
import { TransactionType, sortByCreatedDateDesc, sortByOccurrenceDateDesc } from '@nexdynamic/squeegee-common';
import { Data } from '../../Data/Data';

export const setCustomerContext = (c?: Customer): void => {
    if (!c) return;

    const customerId = c._id;
    window.sq.context.customer = {
        get customer() {
            return Data.get<Customer>(customerId);
        },
        get invoices() {
            return Data.all<Transaction>('transactions', {
                transactionType: TransactionType.Invoice,
                customerId,
            })
                .slice()
                .sort(sortByCreatedDateDesc);
        },
        get payments() {
            return Data.all<Transaction>('transactions', {
                transactionType: TransactionType.Payment,
                customerId,
            })
                .slice()
                .sort(sortByCreatedDateDesc);
        },
        get automaticPayments() {
            return Data.all<Transaction>('transactions', {
                transactionType: TransactionType.AutomaticPayment,
                customerId,
            })
                .slice()
                .sort(sortByCreatedDateDesc);
        },
        get quotes() {
            return Data.all<Quote>('quotes', { customerId }).slice().sort(sortByCreatedDateDesc);
        },
        get jobsAndOccurrences() {
            const customer = Data.get<Customer>(customerId);
            if (!customer) return;

            return Object.values(customer.jobs).map(job => ({
                job,
                occurrences: Data.all<JobOccurrence>('joboccurrences', { jobId: job._id }).slice().sort(sortByOccurrenceDateDesc),
            }));
        },
        get deletedJobsAndOccurrences() {
            const customer = Data.get<Customer>(customerId);
            if (!customer) return;

            return Object.values(customer.deletedJobs || {}).map(job => ({
                job,
                occurrences: Data.all<JobOccurrence>('joboccurrences', { jobId: job._id }).slice().sort(sortByOccurrenceDateDesc),
            }));
        },
        get notifications() {
            return Data.all<Notification>('notifications', { customerId })
                .concat(Data.all<Notification>('notifications', { addressee: customerId }))
                .filter((notification, index, self) => self.findIndex(n => n._id === notification._id) === index)
                .sort(sortByCreatedDateDesc);
        },
    };
    console.log('Customer context updated', window.sq.context.customer);
};
