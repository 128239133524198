import type { Mobile2FAResult, SmsApiType, SqueegeeCreditPricing, TranslationKey } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../ApplicationState';
import { Select } from '../../Dialogs/Select';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { purchaseSqueegeeCredits } from '../../Notifications/Utils/purchaseSqueegeeCredits';
import { Api } from '../../Server/Api';
import { CustomDialog } from '../CustomDialog';
import { Prompt } from '../Prompt';
import { TextDialog } from '../TextDialog';
import type { ISmsApiSettings } from './ISmsApiSettings';

export class SmsSettingsDialog extends CustomDialog<ISmsApiSettings> {
    protected squeegeeCreditPricingBase?: SqueegeeCreditPricing;
    protected squeegeeCreditPricing10k?: SqueegeeCreditPricing;
    protected squeegeeCreditPricing25k?: SqueegeeCreditPricing;
    protected squeegeeCreditPricingBaseDescription: string;
    protected squeegeeCreditPricing10kDescription: string;
    protected squeegeeCreditPricing25kDescription: string;
    protected squeegeeCreditPricingLoading = 'Loading pricing...';
    private rateBase: number;
    private rate10k: number;
    private rate25k: number;
    _squeegeeCreditsNextRefresh: any;
    currentSmsFromNumber: string;
    fromChanged: boolean;
    fromIsName: boolean;
    constructor(
        protected smsAPIKey: string,
        protected smsAPIToken: string,
        protected smsFromNumber: string = 'SqueegeeApp',
        protected smsFromPrivateNumber: string,
        protected smsNumberCallRedirect: string,
        protected smsAPI: SmsApiType,
        protected smsAutoTopup = false,
        protected smsAutoTopupTrigger = 100,
        protected smsAutoTopupAmount = 250,
        protected smsMobilePhonePrefixes?: string
    ) {
        super('smsSettingsDialog', './SmsSettings/SmsSettingsDialog.html', 'dialogs.notification-settings-title', {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'notifications-settings-dialog',
            isSecondaryView: true,
        });
        if (!smsAPI) smsAPI = '';

        this.currentSmsFromNumber = smsFromNumber;
    }

    private formatter = Intl.NumberFormat(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 4 });
    public async init() {
        if (this.smsAutoTopupTrigger === 1000 && this.smsAutoTopupAmount < 1000) this.smsAutoTopupAmount = 1000;

        await this.loadSmsRate();

        Api.getSqueegeeCredits().then(creditsResponse => {
            this._squeegeeCredits = creditsResponse?.squeegeeCredits;
            this._squeegeeCreditsNextRefresh = creditsResponse?.nextRefresh;
        });
    }

    private async loadSmsRate() {
        await Promise.all([this.loadBaseSmsRate(), this.load10kSmsRate(), this.load25kSmsRate()]);
        this.autoTopupTotal = this.getPriceForCredits(this.smsAutoTopupAmount);
    }

    private async loadBaseSmsRate() {
        const smsCreditPricingResponseBase = await Api.get<SqueegeeCreditPricing>(
            null,
            `/api/customer/squeegee-credit-pricing?quantity=${100}`
        );
        if (smsCreditPricingResponseBase?.data) {
            this.squeegeeCreditPricingBase = smsCreditPricingResponseBase.data;
            this.rateBase = this.squeegeeCreditPricingBase.amount * (1 + this.squeegeeCreditPricingBase.taxPercentage);
            this.squeegeeCreditPricingBaseDescription =
                `£${this.formatter.format(this.rateBase)}` + (ApplicationState.account.language.endsWith('GB') ? ' inc. VAT' : ' inc. TAX');
        } else {
            new NotifyUserMessage('notification.unable-to-get-pricing-for-location');
            this.squeegeeCreditPricingLoading = `Error loading pricing information for the country you have selected in your profile. Squeegee SMS may not be available in this country, please contact us if you continue to see this message.`;
        }
    }

    private async load10kSmsRate() {
        const smsCreditPricingResponse10k = await Api.get<SqueegeeCreditPricing>(
            null,
            `/api/customer/squeegee-credit-pricing?quantity=${10000}`
        );
        if (smsCreditPricingResponse10k?.data) {
            this.squeegeeCreditPricing10k = smsCreditPricingResponse10k.data;
            this.rate10k = this.squeegeeCreditPricing10k.amount * (1 + this.squeegeeCreditPricing10k.taxPercentage);
            this.squeegeeCreditPricing10kDescription =
                `£${this.formatter.format(this.rate10k)}` + (ApplicationState.account.language.endsWith('GB') ? ' inc. VAT' : ' inc. TAX');
        } else {
            new NotifyUserMessage('notification.unable-to-get-pricing-for-location');
            this.squeegeeCreditPricingLoading = `Error loading pricing information for the country you have selected in your profile. Squeegee SMS may not be available in this country, please contact us if you continue to see this message.`;
        }
    }

    private async load25kSmsRate() {
        const smsCreditPricingResponse25k = await Api.get<SqueegeeCreditPricing>(
            null,
            `/api/customer/squeegee-credit-pricing?quantity=${25000}`
        );
        if (smsCreditPricingResponse25k?.data) {
            this.squeegeeCreditPricing25k = smsCreditPricingResponse25k.data;
            this.rate25k = this.squeegeeCreditPricing25k.amount * (1 + this.squeegeeCreditPricing25k.taxPercentage);
            this.squeegeeCreditPricing25kDescription =
                `£${this.formatter.format(this.rate25k)}` + (ApplicationState.account.language.endsWith('GB') ? ' inc. VAT' : ' inc. TAX');
        } else {
            new NotifyUserMessage('notification.unable-to-get-pricing-for-location');
            this.squeegeeCreditPricingLoading = `Error loading pricing information for the country you have selected in your profile. Squeegee SMS may not be available in this country, please contact us if you continue to see this message.`;
        }
    }

    protected getPriceForCredits(numberOfCredits: number) {
        const rate = numberOfCredits >= 25000 ? this.rate25k : numberOfCredits >= 10000 ? this.rate10k : this.rateBase;
        return `£${(rate * numberOfCredits).toFixed(2)}` + (ApplicationState.account.language.endsWith('GB') ? ' inc. VAT' : ' inc. TAX');
    }
    protected hasAdvancedOrAbove = ApplicationState.hasAdvancedOrAbove;
    protected toggleAutoTopUp(enable: boolean) {
        this.smsAutoTopup = enable;
    }

    protected changeAutoTopupTrigger(triggerAmount: number) {
        this.smsAutoTopupTrigger = triggerAmount;
        if (this.smsAutoTopupTrigger === 1000 && this.smsAutoTopupAmount < 1000) this.smsAutoTopupAmount = 1000;
    }

    protected changeAutoTopupAmount(topupAmount: number) {
        this.smsAutoTopupAmount = topupAmount;
        this.loadSmsRate();
    }

    protected autoTopupTotal: string;

    protected stateFlags = ApplicationState.stateFlags;

    protected _squeegeeCredits?: number;
    protected get squeegeeCredits() {
        if (this._squeegeeCredits === undefined) return '';

        const credits = this._squeegeeCredits || 0;
        return `${credits} Squeegee Credit${credits === 1 ? '' : 's'} remaining. ${this._squeegeeCreditsNextRefresh || ''}`;
    }

    protected get squeegeeCreditCount() {
        return this._squeegeeCredits || 0;
    }

    protected async changingSmsMethod() {
        if (this.smsAPI === 'squeegee-sms-two-way-private' && ApplicationState.account.smsAPI !== 'squeegee-sms-two-way-private') {
            const allowed = await this.enablePrivateSms();
            if (allowed) return true;

            this.smsAPI = ApplicationState.account.smsAPI;
            return false;
        }

        if (this.smsAPI !== 'squeegee-sms-two-way-private' && ApplicationState.account.smsAPI === 'squeegee-sms-two-way-private') {
            const deactivated = await this.disablePrivateSms();
            if (deactivated) return true;

            this.smsAPI = 'squeegee-sms-two-way-private';
            return false;
        }
    }

    protected async purchaseSqueegeeCreditsDialog(amount?: number) {
        await purchaseSqueegeeCredits({ amount });
        const creditsUpdate = await Api.getSqueegeeCredits();
        this._squeegeeCredits = creditsUpdate?.squeegeeCredits;
        this._squeegeeCreditsNextRefresh = creditsUpdate?.nextRefresh;
    }

    protected async mobile2FA(phoneNumber: string) {
        try {
            new LoaderEvent(true, true, 'user-dialog.generating-and-sending-2fa-code');
            const startResult = await Api.post<{
                id: string;
            }>(null, `/api/notifications/2fa/start`, { phoneNumber });

            if (!startResult) return { success: false, error: 'dialog.failed-to-begin-mobile-2fa' };

            if (!startResult.data.id) return { success: false, error: 'dialog.id-incorrect' };

            new LoaderEvent(false);
            let code: string;
            let count = 0;
            while (count++ < 3) {
                const dialog = new TextDialog('general.confirm', 'dialogs.enter-verification-code', '', '');

                code = await dialog.show();

                new LoaderEvent(true, true, 'user-dialog.verifying-2fa-code');

                if (dialog.cancelled) {
                    return { success: false, error: 'dialog.mobile-2fa-cancelled' };
                }

                const result = await Api.post<Mobile2FAResult>(
                    null,
                    `/api/notifications/2fa/complete`,
                    { id: startResult.data.id, code },
                    undefined,
                    true
                );

                if (!result) return { success: false, error: 'dialog.failed-to-recieve-verification' };

                if (!result.data.success) {
                    if (result.data.error === 'Code expired') {
                        new LoaderEvent(false);
                        await new Prompt('general.error', 'dialogs.verification-code-expired', { cancelLabel: '' }).show();
                    } else if (result.data.error === 'Invalid code') {
                        new LoaderEvent(false);
                        await new Prompt('general.error', 'dialogs.verification-code-invalid', { cancelLabel: '' }).show();
                        continue;
                    } else {
                        new LoaderEvent(false);
                        await new Prompt('general.error', 'general.error', { cancelLabel: '' }).show();
                    }
                }
                return result.data;
            }
        } finally {
            new LoaderEvent(false);
        }
    }

    public delegateOk = async () => {
        let from = this.smsFromNumber;
        if (!from) {
            from = 'SqueegeeApp';
        } else if (this.smsAPI === 'squeegee-sms') {
            from = from.substring(0, 11);
        }

        this.fromChanged = this.smsFromNumber !== this.currentSmsFromNumber;
        this.fromIsName = !from.match(/^\+?[0-9]*$/);
        if (this.fromChanged) {
            if (!this.fromIsName) {
                const twoFA = await this.mobile2FA(from);

                if (!twoFA?.success) {
                    new NotifyUserMessage('user-dialog.unable-to-verify-2fa-code');
                    this.smsFromNumber = this.currentSmsFromNumber;
                }

                if (twoFA?.success) {
                    this.smsFromNumber = from;
                    this.ok();
                    return new NotifyUserMessage('dialogs.sms-settings-updated');
                }
            } else {
                if (!this.smsFromNumber?.trim()) {
                    this.smsFromNumber = '';
                } else {
                    new Prompt('notifications.sms-name-request-title', 'notifications.sms-name-request-text', {
                        cancelLabel: '',
                        localisationParams: { name: from },
                    }).show();
                }
            }
        }
        this.ok();
    };

    protected async enablePrivateSms(): Promise<boolean> {
        if (ApplicationState.getSetting('global.sms-from-private-number')) return true;
        try {
            new LoaderEvent(true, true, 'loader.searching-for-sms-number');
            const numbersResponse = await Api.get<{ numbers?: Array<string>; success: boolean; error?: string }>(
                null,
                '/api/twilio/suggest-numbers'
            );
            if (!numbersResponse?.data?.success || !numbersResponse?.data?.numbers?.length) {
                new Prompt(
                    'Private SMS Unavailable' as TranslationKey,
                    (numbersResponse?.data?.error ||
                        'No private SMS numbers currently available, please try again later.') as TranslationKey,
                    { cancelLabel: '' }
                ).show();
                return false;
            }
            new LoaderEvent(false);

            if (
                !(await new Prompt('general.confirm', 'dialogs.activate-private-sms', {
                    okLabel: 'general.ok',
                    cancelLabel: 'general.cancel',
                    localisationParams: { cost: '£10.00' + (ApplicationState.account.language.indexOf('GB') > -1 ? ' including VAT' : '') },
                }).show())
            )
                return false;

            const numbers = numbersResponse?.data?.numbers;

            const options = [] as Array<{ [name: string]: string }>;

            for (const number of numbers) {
                options.push({ number });
            }

            const selectNumber = new Select(
                'Select from the available private SMS numbers' as TranslationKey,
                options,
                'number',
                'number',
                undefined,
                { coverViewport: true }
            );
            new LoaderEvent(false);
            const selectedNumber = await selectNumber.show();
            if (selectNumber.cancelled) return false;

            if (
                !(await new Prompt('general.confirm', 'dialogs.activate-private-sms-confirm', {
                    okLabel: 'general.ok',
                    cancelLabel: 'general.cancel',
                    localisationParams: {
                        cost: '£10.00' + (ApplicationState.account.language.indexOf('GB') > -1 ? ' including VAT' : ''),
                        phoneNumber: selectedNumber.number,
                    },
                }).show())
            )
                return false;

            new LoaderEvent(true, true, 'loader.charging-your-card-activating-private-sms');
            const purchaseRequest = await Api.post<{ success: boolean; error?: string }>(
                null,
                '/api/twilio/charge-and-activate-private-number',
                { number: selectedNumber.number }
            );
            new LoaderEvent(false);

            if (!purchaseRequest?.data.success) {
                new Prompt(
                    'Unable to Activate Private Number' as TranslationKey,
                    (purchaseRequest?.data?.error ||
                        'An unknown error occurred while charging your card and activating the number.') as TranslationKey,
                    { cancelLabel: '' }
                ).show();
                return false;
            }

            ApplicationState.setSetting('global.sms-from-private-number', selectedNumber.number);
            this.smsFromPrivateNumber = selectedNumber.number;

            ApplicationState.account.smsAPI = this.smsAPI = 'squeegee-sms-two-way-private';
            await ApplicationState.save();

            return true;
        } catch (error) {
            new Prompt(
                'Unable to Activate Private Number' as TranslationKey,
                typeof error?.error === 'string'
                    ? error.error
                    : ('An unknown error occurred while activating private SMS.' as TranslationKey),
                { cancelLabel: '' }
            ).show();
            return false;
        } finally {
            new LoaderEvent(false);
        }
    }

    private async disablePrivateSms(): Promise<boolean> {
        const phoneNumber = ApplicationState.getSetting('global.sms-from-private-number', '');
        if (!phoneNumber) return true;

        try {
            if (
                !(await new TextDialog(
                    'general.confirm',
                    ApplicationState.localise('dialogs.deactivate-private-sms-confirm', {
                        phoneNumber,
                    }),
                    '',
                    '',
                    value => (value === phoneNumber ? true : 'dialogs.deactivate-private-sms-confirm-error')
                ).show())
            )
                return false;

            new LoaderEvent(true, true, 'loader.deactivating-number-for-private-sms', undefined, { number: phoneNumber });
            const deactivated = await Api.delete<{ success: boolean; error?: string }>(
                null,
                '/api/twilio/cancel-number?number=' + encodeURIComponent(phoneNumber)
            );

            if (!deactivated) {
                new Prompt('prompts.error-title', 'prompts.failed-to-deactivate-private-sms', { cancelLabel: '' }).show();
                return false;
            }

            await ApplicationState.setSetting('global.sms-from-private-number', '');
            this.smsFromPrivateNumber = '';

            ApplicationState.account.smsAPI = this.smsAPI;
            await ApplicationState.save();

            new Prompt('general.success', 'prompts.deactivated-private-sms', { cancelLabel: '' }).show();
            return true;
        } catch (error) {
            new NotifyUserMessage('notification.unknown-error-while-deactivating-private-sms');
            return false;
        } finally {
            new LoaderEvent(false);
        }
    }

    async getResult(): Promise<ISmsApiSettings> {
        return {
            provisionalFromName: this.fromChanged && this.fromIsName && this.smsFromNumber?.trim() ? this.smsFromNumber.trim() : null,
            smsAPI: this.smsAPI,
            smsAPIKey: this.smsAPIKey,
            smsAPIToken: this.smsAPIToken,
            smsFromNumber: !this.fromChanged || !this.fromIsName ? this.smsFromNumber : this.currentSmsFromNumber,
            smsFromPrivateNumber: this.smsFromPrivateNumber,
            smsNumberCallRedirect: this.smsNumberCallRedirect,
            smsAutoTopup: this.smsAutoTopup,
            smsAutoTopupTrigger: this.smsAutoTopupTrigger,
            smsAutoTopupAmount: this.smsAutoTopupAmount,
            smsMobilePhonePrefixes: this.smsMobilePhonePrefixes,
        };
    }

    protected get maxLengthSqueegeeSmsFrom() {
        const from = (this.smsFromNumber || '').replace(/[ +]/, '');

        return /^[\d ]*?$/.test(from) ? 15 : 11;
    }
}
