import type { Customer, Job, JobInitialFields, SettingIds } from '@nexdynamic/squeegee-common';
import { splitName } from '@nexdynamic/squeegee-common';
import type { BookedItemProps, BookingConfirmationTemplateProps } from '@nexdynamic/squeegee-templates';
import { TemplateService } from '@nexdynamic/squeegee-templates';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import { JobSummaryDialog } from '../../Customers/Components/JobSummaryDialog';
import { Data } from '../../Data/Data';
import { SendMessageToCustomer } from '../../Dialogs/SendMessageToCustomer';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { AppointmentsDialog } from '../../Jobs/Dialogs/AppointmentsDialog';
import { JobService } from '../../Jobs/JobService';
import { Logger } from '../../Logger';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { QuoteService } from '../../Quotes/QuoteService';
import { Api } from '../../Server/Api';
import { Utilities } from '../../Utilities';

export class JobActions {
    public static viewAppointments(customerId: string, jobId: string) {
        new AppointmentsDialog({ customerId, jobId }).show();
    }

    public static view(customer: Customer, job: Job) {
        new JobSummaryDialog(customer, job).show();
    }

    public static async save(
        customer: Customer,
        jobToSave: Job,
        assignees: Array<string>,
        fields?: JobInitialFields,
        dateRequired = false
    ): Promise<{ error?: string; success: boolean }> {
        const job = Utilities.copyObject(jobToSave);

        if (JobService.validateJobAndNotifyUI(job, dateRequired).length) {
            return { error: 'Validation Failed', success: false };
        } else {
            try {
                new LoaderEvent(true);
                await JobService.addOrUpdateJob(
                    customer,
                    job,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    fields,
                    assignees
                );
                return { success: true };
            } catch (error) {
                new NotifyUserMessage('notifications.job-save-error');
                Logger.error(`Error during save in new job dialog`, { job, error });

                return { error: ApplicationState.localise('notifications.job-save-error'), success: false };
            } finally {
                new LoaderEvent(false);
            }
        }
    }

    public static async sendBookingConfirmationForJobs(customerId: string, jobs: Array<Job>) {
        try {
            if (!ApplicationState.isVerifiedForMessaging) throw new Error('Please verify your email to send messages');
            const customer = Data.get<Customer>(customerId);
            if (!customer) throw new Error('Unable send booking confirmation customer not found');

            const quoteSettings = QuoteService.getQuoteSettings();

            const items: Array<BookedItemProps> = [];

            for (const job of jobs) {
                items.push({ description: job.services.map(s => s.description).join(', '), date: moment(job.date).format('LL') });
            }

            let automaticPaymentSignup: string | undefined;
            if (ApplicationState.account.stripePublishableKey || ApplicationState.account.goCardlessPublishableKey) {
                const showLinkKey: SettingIds = 'global.quote-booking-confirmation-show-automatic-payment-signup';
                const showLink = await ApplicationState.getSetting(showLinkKey, false);
                if (showLink) {
                    automaticPaymentSignup = `${Api.currentHostAndScheme}/go/a/${customer._id}/`;
                }
            }

            const names = splitName(customer.name);

            const model: BookingConfirmationTemplateProps = {
                model: {
                    businessEmail: quoteSettings.email || ApplicationState.account.email,
                    businessName: ApplicationState.account.businessName || '',
                    customerName: customer.name,
                    firstName: names.firstname,
                    customerRef: customer._externalId || customer._id,
                    date: Date.now(),
                    items,
                    automaticPaymentSignup,
                },
            };

            const email = TemplateService.renderComponent<BookingConfirmationTemplateProps>(
                'email.booking-confirmation',
                model,
                false,
                customer.ownerEmail
            );

            const options = {
                businessName: ApplicationState.account.businessName || '',
                items: items.map(item => `${item.description} - ${item.date}`).join('\n'),
            };

            let sms = ApplicationState.getSetting<string | undefined>('global.sms-booking-template');
            if (sms) {
                sms = sms.replace(/{{businessName}}/g, options.businessName);
                sms = sms.replace(/{{items}}/g, options.items);
                sms = sms.replace('[items]', options.items);
            }
            else {
                sms = ApplicationState.localise('booking.default-sms-template', options);
            }

            const newMessage = new SendMessageToCustomer(customer, { sms, email, emailIsHtml: true }, undefined, undefined, false);
            await newMessage.show();

            if (!newMessage.cancelled) {
                new NotifyUserMessage('notifications.booking-confirmation-sent');
            }
        } catch (error) {
            new NotifyUserMessage('booking.confirmation.sent-error');
            Logger.error('Unable to send booking confirmation', error);
        }
    }
}
