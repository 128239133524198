import type { SoapyFrequency } from './SoapyFrequency';

export const parseSoapyFrequency = (frequency: string): SoapyFrequency | undefined => {
    const [intervalText, unit] = frequency.split(' ');
    if (!intervalText || !unit) return;

    const interval = parseInt(intervalText);
    if (isNaN(interval)) return;

    if (unit !== 'Weeks' && unit !== 'Months' && unit !== 'Years') return;

    return { interval, unit };
};
