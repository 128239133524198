import type { AccountUser, Customer, FrequencyData, Job, JobGroup, Tag, Team, TranslationKey } from '@nexdynamic/squeegee-common';
import {
    FrequencyBuilder,
    FrequencyType,
    JobOccurrence,
    JobOccurrenceStatus,
    TagType,
    copyObject,
    generateJobOccurrenceId,
    wait,
} from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import { createBackup } from '../Data/createBackup';
import { Prompt } from '../Dialogs/Prompt';
import { LoaderEvent } from '../Events/LoaderEvent';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { ScheduleActionEvent } from '../Schedule/Actions/ScheduleActionEvent';
import { AssignmentService } from '../Users/Assignees/AssignmentService';
import { Utilities } from '../Utilities';
import { updateNextDateByDays } from './updateNextDateByDays';

export const shiftJobSchedules = async (
    amount: number,
    unit: moment.unitOfTime.Base,
    keepOldScheduleChoice: boolean,
    from = moment(),
    days: Array<number> = [1, 2, 3, 4, 5, 6, 7],
    assignedTo?: AccountUser | Team
) => {
    if (amount === 0) {
        new NotifyUserMessage('notification.shift-amount-must-be-greater-than-zero');
        return;
    }

    if (
        assignedTo &&
        Data.count<JobGroup>('tags', r => !!r.frequencyData || (!!r.schedules && !!r.schedules.length), { type: TagType.ROUND })
    ) {
        new NotifyUserMessage('notification.cannot-shift-when-you-have-scheduled-rounds');
        return;
    }

    from = from.clone().startOf('day');

    let localisableConfirmKey: TranslationKey;
    if (amount > 0 && keepOldScheduleChoice) localisableConfirmKey = 'shift-job-schedule.overdue-keep-confirm-text-forwards';
    else if (amount > 0 && !keepOldScheduleChoice) localisableConfirmKey = 'shift-job-schedule.overdue-replace-confirm-text-forwards';
    else localisableConfirmKey = 'shift-job-schedule.confirm-text-backwards';

    let unitText = '';
    const absAmount = Math.abs(amount);
    switch (unit) {
        case 'd':
        case 'day':
        case 'days':
            unitText = absAmount === 1 ? 'day' : 'days';
            break;
        case 'm':
        case 'month':
        case 'months':
            unitText = absAmount === 1 ? 'month' : 'months';
            break;
        case 'w':
        case 'week':
        case 'weeks':
            unitText = absAmount === 1 ? 'week' : 'weeks';
            break;
        case 'y':
        case 'year':
        case 'years':
            unitText = absAmount === 1 ? 'year' : 'years';
            break;
    }
    const confirmText = ApplicationState.localise(localisableConfirmKey, {
        count: 'selected',
        amount: absAmount.toString(),
        unit: unitText,
        date: from.format('LL'),
    });
    new LoaderEvent(false);

    if (
        !(await new Prompt('shift-job-schedule.title', confirmText, {
            okLabel: 'general.confirm',
            cancelLabel: 'general.cancel',
        }).show())
    )
        return false;

    new LoaderEvent(false);
    const localisationParams = {
        confirmText: confirmText,
        user: ApplicationState.dataEmail,
        dateFrom: from.format('LL'),
    };
    // Create a backup before shifting schedules.
    // and create a message regarding the details of this action for the audit log.
    const actionDetailsDescription = ApplicationState.localise('backup.reason.shift-schedule.description', localisationParams);
    if (!(await createBackup({ initiatorTask: 'shift-schedule', auditInfo: { items: [], actionDetailsDescription } })))
        throw new Error('Failed to create backup prior to shifting schedules.');
    new LoaderEvent(true);

    for (const customer of Data.all<Customer>('customers')) {
        let updated = false;
        for (const jobId in customer.jobs || {}) {
            if (Utilities.fixJobSchedule(customer.jobs[jobId], false)) updated = true;
        }
        if (updated) Data.put(customer);
    }

    const isDays = unit === 'd' || unit === 'day' || unit === 'days';
    const endDate = isDays
        ? updateNextDateByDays(from.clone().startOf('day'), days, amount)
        : from.clone().add(amount, unit).startOf('day');
    const endDateIso = endDate.format('YYYY-MM-DD');

    const fromIso = from.format('YYYY-MM-DD');

    const jobs = {} as { [id: string]: Job };
    const updates = [] as Array<JobOccurrence | Customer | Tag>;

    // 1. FORWARDS ONLY: Move each job start date that is on or before the
    //    end date forward based on it's schedule until it is after the end date,
    //    concreting all transient occurrences as you go.
    // 2. Move each job start date based on the shift size.
    for (const originalCustomer of Data.all<Customer>('customers', c => c.state !== 'inactive')) {
        const customer = Utilities.copyObject<Customer>(originalCustomer);
        let updated = false;
        for (const job of Object.values(customer.jobs || {})) {
            // Don't process inactive jobs or jobs with no date or external calendar.
            if (job.state === 'inactive' || !job.date || job.frequencyType === FrequencyType.ExternalCalendar) continue;

            if (isDays) {
                const dateCheck = moment(job.date);
                if (!dateCheck.isValid()) continue;
                if (!days.includes(dateCheck.isoWeekday())) continue;
            }

            jobs[job._id] = job;

            // Check this after adding it to the jobs list so we can still move occurrences that are assigned.
            if (assignedTo && !AssignmentService.isAssigned(job, assignedTo._id, false)) continue;

            // 1. Just in case it's a non recurring and there is a job on the
            //    date done or skipped already.
            if (job.frequencyType === FrequencyType.NoneRecurring) {
                if (job.date < fromIso) continue;

                const jobDate = job.date.slice(0, 10);
                const doneOrSkippedOccurrenceOnDate = Data.firstOrDefault<JobOccurrence>(
                    'joboccurrences',
                    o =>
                        o.status !== JobOccurrenceStatus.NotDone &&
                        (o.isoDueDate?.slice(0, 10) === jobDate ||
                            o.isoPlannedDate?.slice(0, 10) === jobDate ||
                            o.isoCompletedDate?.slice(0, 10) === jobDate),
                    { jobId: job._id }
                );
                if (doneOrSkippedOccurrenceOnDate) continue;
            } else {
                if (amount > 0 && job.date <= endDateIso) {
                    const frequency = FrequencyBuilder.getFrequencyFromJob(job);
                    if (frequency) {
                        let count = 0;
                        for (;;) {
                            if (count === 1000)
                                throw new Error('Failed to shift the schedule, too many recursions within the job date calculation.');
                            count++;

                            // New date available so concrete the occurrence then move to the new one.
                            if (!Data.get(generateJobOccurrenceId(job, job.date))) {
                                const occurrence = new JobOccurrence(job, job.date);
                                updates.push(occurrence);
                            }

                            const date: string | undefined = frequency.getNextDate(moment(job.date).add(1, 'day').format('YYYY-MM-DD'));
                            if (!date) break;
                            job.date = date;
                            if (date > endDateIso) break;
                        }
                        await wait(1);
                    }
                }
            }

            // 2.

            const originalJobDate = job.date;
            job.date = isDays
                ? updateNextDateByDays(moment(job.date), days, amount).format('YYYY-MM-DD')
                : moment(job.date).add(amount, unit).format('YYYY-MM-DD');

            if (amount < 0) {
                const doneOrSkippedOccurrenceOnDate = Data.firstOrDefault<JobOccurrence>(
                    'joboccurrences',
                    o =>
                        (o.status === JobOccurrenceStatus.Done || o.status === JobOccurrenceStatus.Skipped) &&
                        o.isoDueDate.slice(0, 10) === originalJobDate.slice(0, 10),
                    { jobId: job._id }
                );

                if (doneOrSkippedOccurrenceOnDate) {
                    const updated = copyObject(doneOrSkippedOccurrenceOnDate);
                    updated._id = job._id + job.date;
                    updated.isoDueDate = job.date;
                    updates.push(updated);

                    doneOrSkippedOccurrenceOnDate._deleted = true;
                    updates.push(doneOrSkippedOccurrenceOnDate);
                }
            }

            Utilities.fixJobSchedule(job, false);
            updated = true;
        }
        if (updated) updates.push(customer);
    }

    // 1. FORWARDS ONLY: Move each scheduled round start date that is on or before
    //    the end date forward based on it's schedule until it is after the end
    //    date, ignore occurrences.
    // 2. Move each scheduled round start date based on the shift size.
    for (const round of Utilities.copyObject(
        Data.all<JobGroup>('tags', {
            type: TagType.ROUND,
        })
    )) {
        let updated = false;
        const frequencyDatas = [] as Array<FrequencyData>;
        if (round.frequencyData) frequencyDatas.push(round.frequencyData);
        if (round.schedules) frequencyDatas.push(...round.schedules);
        if (!frequencyDatas.length) continue;
        for (const frequencyData of frequencyDatas) {
            if (frequencyData.firstScheduledDate) {
                frequencyData.firstScheduledDate = isDays
                    ? updateNextDateByDays(moment(frequencyData.firstScheduledDate), days, amount).format('YYYY-MM-DD')
                    : moment(frequencyData.firstScheduledDate).add(amount, unit).format('YYYY-MM-DD');
                Utilities.fixFrequencyData(frequencyData);

                updated = true;
                if (frequencyData.firstScheduledDate > endDateIso) continue;

                const frequency = FrequencyBuilder.getFrequencyFromDateAndFrequencyData(frequencyData);
                if (frequency) {
                    let count = 0;
                    for (;;) {
                        if (count === 1000)
                            throw new Error(
                                'Failed to shift the schedule, too many recursions within the round schedules array date calculation.'
                            );
                        count++;

                        const date: string = frequency.getNextDate(
                            moment(frequencyData.firstScheduledDate).add(1, 'day').format('YYYY-MM-DD')
                        ) as string;
                        if (!date) break;
                        frequencyData.firstScheduledDate = date;
                        if (frequencyData.firstScheduledDate > endDateIso) break;
                    }
                    await wait(1);
                }
            }
        }
        if (updated) updates.push(round);
    }

    // Move all not done occurrences due after the start of the shift (isoDueDate, isoPlannedDate, and ID dates) forward based on the shift size.
    // If forwards, only move occurrences planned on or after the start date.
    // Updated the IDs to match the isoDue Date (deleting the wrong ID'd one.)
    const newJobOccurrences = updates.filter(u => u.resourceType === 'joboccurrences') as Array<JobOccurrence>;
    const existingOccurrences = Data.all<JobOccurrence>('joboccurrences', o => (o.isoPlannedDate || o.isoDueDate) >= fromIso, {
        status: JobOccurrenceStatus.NotDone,
    });
    const allOccurrences = newJobOccurrences.concat(existingOccurrences);
    for (const occurrence of allOccurrences) {
        // Job didn't get processed.
        if (!jobs[occurrence.jobId]) continue;

        // Leave appointments on non-work days where they are if we're moving by days.
        const plannedDate = occurrence.isoPlannedDate || occurrence.isoDueDate;
        if (isDays && !days.includes(moment(plannedDate).isoWeekday())) continue;

        // This occurrence is not relevant
        if (plannedDate < fromIso) continue;

        // If this occurrence is assigned and not to this assignee, skip it.
        if (assignedTo && !AssignmentService.isAssigned(occurrence, assignedTo._id, false)) continue;

        // Create a copy in case we need to update the ID.
        const clone = Utilities.copyObject(occurrence);

        // Update the due date and ID if the job is being moved.
        if (!assignedTo || AssignmentService.isAssigned(jobs[clone.jobId], assignedTo._id, false)) {
            // Remove the existing one with the busted ID.
            const original = Utilities.copyObject(occurrence);
            original._deleted = true;
            updates.push(original);

            // Update the clone to have the new ID and due date.
            clone.isoDueDate = isDays
                ? updateNextDateByDays(moment(clone.isoDueDate), days, amount).format('YYYY-MM-DD')
                : moment(clone.isoDueDate).add(amount, unit).format('YYYY-MM-DD');

            clone.lastId = clone._id;
            clone._id = clone.jobId + clone.isoDueDate;
        }

        // Updated the planned date if it exists.
        if (clone.isoPlannedDate) {
            clone.isoPlannedDate = isDays
                ? updateNextDateByDays(moment(clone.isoPlannedDate), days, amount).format('YYYY-MM-DD')
                : moment(clone.isoPlannedDate).add(amount, unit).format('YYYY-MM-DD');
        }

        updates.push(clone);
    }

    // Only when moving forward.
    if (amount > 0) {
        // Delete all concrete not done occurrences in the gap.
        // If keepOldScheduleChoice is set, delete all concrete not done occurrences occurring before either today or the from date whichever is older.
        const todayIso = moment().format('YYYY-MM-DD');
        const deleteFromBefore = fromIso < todayIso ? fromIso : todayIso;
        for (const update of updates) {
            if (update.resourceType !== 'joboccurrences') continue;

            const occurrence = update as JobOccurrence;

            if (assignedTo && !AssignmentService.isAssigned(occurrence, assignedTo._id, false)) continue;

            const plannedDate = occurrence.isoPlannedDate || occurrence.isoDueDate;

            // This is an occurrence already moved to after the gap, leave it alone.
            if (plannedDate >= endDateIso) continue;

            // This is an occurrence in the gap, always remove.
            if (plannedDate >= fromIso) {
                occurrence._deleted = true;
                continue;
            }

            // This is an occurrence before the from date or today (whichever is older) remove if not keepOldScheduleChoice.
            if (!keepOldScheduleChoice && plannedDate < deleteFromBefore) {
                occurrence._deleted = true;
                continue;
            }
        }

        const overdueOccurrences = Data.all<JobOccurrence>('joboccurrences', o => (o.isoPlannedDate || o.isoDueDate) <= fromIso, {
            status: JobOccurrenceStatus.NotDone,
        });

        // Check through all the existing occurrences
        for (const occurrence of overdueOccurrences) {
            const plannedDate = occurrence.isoPlannedDate || occurrence.isoDueDate;

            if (assignedTo && !AssignmentService.isAssigned(occurrence, assignedTo._id, false)) continue;

            // This is an occurrence already moved to after the gap, leave it alone.
            if (plannedDate >= endDateIso) continue;

            // This is an occurrence in the gap, always remove.
            if (plannedDate >= fromIso) {
                occurrence._deleted = true;
                updates.push(occurrence);
                continue;
            }

            // This is an occurrence before the from date or today (whichever is older) remove if not keepOldScheduleChoice.
            if (!keepOldScheduleChoice && plannedDate < deleteFromBefore) occurrence._deleted = true;
            updates.push(occurrence);
        }
    }

    // Filter out duplicates leaving the last added one as it's the latest change.
    const found = {} as { [ide: string]: true };
    let count = updates.length;
    while (count--) {
        const id = updates[count]._id;
        if (found[id]) updates.splice(count, 1);
        else found[id] = true;
    }

    // Save everything (we could put an are you sure here if we want);
    await Data.put(updates);

    new ScheduleActionEvent('action-replan');
    new LoaderEvent(false);
    const localisableSuccessKey = amount > 0 ? 'shift-job-schedule.success-forward' : 'shift-job-schedule.success-backward';
    new NotifyUserMessage(localisableSuccessKey, {
        count: updates.filter(u => u.resourceType === 'customers').length + ' customers selected',
        amount: absAmount.toString(),
        unit: unit,
        date: from.format('LL'),
    });
    return true;
};
