import { requestCompleteOccurrence } from './IntegrationFunctions/requestCompleteOccurrence';
import { requestStartTimer } from './IntegrationFunctions/requestStartTimer';
import { requestStopTimer } from './IntegrationFunctions/requestStopTimer';
import { requestTimedOccurrence } from './IntegrationFunctions/requestTimedOccurrence';
import { userScheduleUpdate } from './IntegrationFunctions/userScheduleUpdate';
import { viewStoredObject } from './IntegrationFunctions/viewStoredObject';

const timers = {
    requestStopTimer,
    requestStartTimer,
};
export const parentFrameApi: { [method: string]: (options: Record<string, unknown>) => void | Promise<void> } = {
    ...timers,
    viewStoredObject,
    requestUserSchedule: userScheduleUpdate,
    requestTimedOccurrence,
    requestCompleteOccurrence,
} as const;
