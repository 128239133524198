import { computedFrom } from 'aurelia-framework';
import * as Hammer from 'hammerjs';
import { CustomDialog } from '../CustomDialog';
import type { ISlide } from './ISlide';
import type { ISlideShowOptions } from './ISlideShowOptions';

export class SlideShowDialog extends CustomDialog<void> {
    private sliderManager?: HammerManager;
    private sliderElement: HTMLElement | null;
    protected activeSlideIndex = 0;
    @computedFrom('activeSlideIndex')
    protected get lastSlide() {
        return this.activeSlideIndex + 1 === this.slides.length;
    }

    protected currentSlideClass: string;
    protected updateCurrentSlideClass() {
        this.currentSlideClass = (this.slides && this.slides[this.activeSlideIndex] && this.slides[this.activeSlideIndex].class) || '';
    }

    constructor(protected slides: Array<ISlide>, protected options: ISlideShowOptions) {
        super('slideShowDialog', './SlideShow/SlideShowDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            allowCancel: options.allowSkip,
            cssClass: 'slide-show-dialog',
            coverViewport: true,
            noObfuscator: true,
            smallerOnDesktop: true,
        });

        this.updateCurrentSlideClass();
    }

    protected additionalStyles = '';
    public async init() {
        this.sliderElement = <HTMLElement>document.querySelector('#slideContainer .slider');
        const slideCount = this.slides.length;
        if (this.sliderElement) {
            this.sliderManager = new Hammer.Manager(this.sliderElement);
            this.sliderManager.add(new Hammer.Pan({ threshold: 0, pointers: 0 }));
            this.sliderManager.on('pan', e => {
                const percentage = ((100 / slideCount) * e.deltaX) / window.innerWidth;
                const transformPercentage = percentage - (100 / slideCount) * this.activeSlideIndex;
                if (this.sliderElement) {
                    this.sliderElement.classList.remove('animate');
                    this.sliderElement.style.transform = 'translateX( ' + transformPercentage + '% )';
                }
                if (e.isFinal) {
                    requestAnimationFrame(() => {
                        this.sliderElement && this.sliderElement.classList.add('animate');
                        if (percentage < -(15 / this.slides.length)) {
                            this.goToSlide(this.activeSlideIndex + 1);
                        } else if (percentage > 15 / this.slides.length) {
                            this.goToSlide(this.activeSlideIndex - 1);
                        } else {
                            this.goToSlide(this.activeSlideIndex);
                        }
                    });
                }
            });
        }
    }

    protected getSlideBackground(slide: ISlide) {
        return (slide.bg && `url("images/launch-slides/${slide.bg}`) || '';
    }

    public goToSlide(number: number) {
        const slideCount = this.slides.length;
        if (number < 0) {
            this.activeSlideIndex = 0;
        } else if (number > slideCount - 1) {
            this.activeSlideIndex = slideCount - 1;
        } else {
            this.activeSlideIndex = number;
        }

        const percentage = -(100 / slideCount) * this.activeSlideIndex;
        if (this.sliderElement) {
            this.sliderElement.style.transform = 'translateX( ' + percentage + '% )';
        }

        this.updateCurrentSlideClass();
    }

    public async ok() {
        const activeSlide = this.slides[this.activeSlideIndex];
        activeSlide && activeSlide.action && (await activeSlide.action.execute());
        super.ok();
    }
    public dispose() {
        if (!this.sliderManager) return;

        this.sliderManager.off('pan');
        this.sliderManager.stop(true);
        this.sliderManager.destroy();

        super.dispose();
    }
}
