import type { Customer, JobGroup} from '@nexdynamic/squeegee-common';
import { Tag, TagType, Transaction, TransactionType } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { Logger } from '../Logger';
import { TagService } from '../Tags/TagService';
import { Data } from './Data';
import { GeorgeParser } from './GeorgeParser';
import type { GeorgeRecord } from './GeorgeRecord';
import { ImportValidator } from './ImportValidator';

export class GeorgeImporter {
    private static requiredColoumns: Array<keyof GeorgeRecord> = [
        'Debt',
        'Frequency',
        'Invoice Address1',
        'Invoice Forename',
        'Invoice HouseNo/Name',
        'Invoice Phone1',
        'Invoice PostCode',
        'Invoice Surname',
        'Invoice Title',
        'Invoice eMail',
        'NextDue',
        'Notes',
        'Prepaid',
        'Price',
    ];

    public static async import(
        csv: string,
        deleteExistingData = false
    ): Promise<{ warnings: Array<string>; error: string; success: boolean }> {
        const output = { warnings: <Array<string>>[], error: '', success: false };
        try {
            const parsedCSV = GeorgeParser.parseCSV(csv);
            if (parsedCSV && parsedCSV.data && parsedCSV.data.length) {
                const validator = ImportValidator.validateCSV(Object.keys(parsedCSV.data[0]), GeorgeImporter.requiredColoumns);
                if (validator.isValid) {
                    await GeorgeImporter.addRecords(parsedCSV.data, deleteExistingData);
                    output.warnings = GeorgeParser.warnings.splice(0);
                    output.success = true;
                } else {
                    output.error = 'The Selected CSV is missing the following columns: ' + validator.errors.join(', \n');
                }
            } else {
                output.error = 'Unable to parse the selected CSV.';
            }
        } catch (error) {
            Logger.error(`Error during import George CSV`, error);
            throw new Error(error);
        }
        return output;
    }

    private static async addRecords(records: Array<GeorgeRecord>, deleteExistingData: boolean) {
        const rounds: { [id: string]: JobGroup } = {};
        if (!deleteExistingData) GeorgeImporter.mergeWithExistingRounds(rounds);
        const serviceTag = GeorgeImporter.getDefaultService(deleteExistingData);

        const customers: Array<Customer> = [];
        let transactions: Array<Transaction> = [];

        let format: string;
        const recordsWithDates = records.filter(record => !!record.NextDue);
        if (recordsWithDates.every(record => moment(record.NextDue, 'DD-MM-YYYY').isValid())) format = 'DD-MM-YYYY';
        else if (recordsWithDates.every(record => moment(record.NextDue, 'MM-DD-YYYY').isValid())) format = 'MM-DD-YYYY';
        else throw new Error('Unable to determine the date format for this import, please check the import file.');

        for (let i = 0; i < records.length; i++) {
            const record = records[i];
            try {
                const customer = GeorgeParser.convertRecord(record, rounds, serviceTag, format);
                const newTransactions = GeorgeImporter.getCustomerBalance(record, customer);

                if (!customer) throw new Error('Could not convert customer record');
                customers.push(customer);
                if (newTransactions.length) transactions = transactions.concat(newTransactions);
            } catch (error) {
                throw new Error(error);
            }
        }
        if (customers.length) {
            try {
                const validator = ImportValidator.validateOutput(customers, transactions, rounds);
                if (validator.isValid) {
                    if (deleteExistingData) await Data.deleteAllData(false);
                    await GeorgeImporter.addToDatabase(customers, transactions, rounds, serviceTag);
                    Data.initSync();
                } else {
                    throw new Error('George Data failed validation ' + '\n' + validator.errors.join('\n'));
                }
            } catch (error) {
                throw new Error('Could not import george CSV ' + error);
            }
        } else {
            throw new Error('Could not import george CSV');
        }
    }

    private static async addToDatabase(
        customers: Array<Customer>,
        transactions: Array<Transaction>,
        rounds: { [id: string]: Tag },
        serviceTag: Tag
    ) {
        const roundsArray = Object.keys(rounds).map(key => rounds[key]);
        let all: Array<any> = [];
        all = all.concat(customers, transactions, roundsArray, [serviceTag]);
        await Data.put(all);
    }

    private static mergeWithExistingRounds(rounds: { [id: string]: Tag }) {
        const existingRounds = TagService.getTagsByType(TagType.ROUND);

        if (existingRounds && existingRounds.length) {
            for (let i = 0; i < existingRounds.length; i++) {
                const existingRound = existingRounds[i];
                rounds[existingRound._id] = existingRound;
            }
        }

        return rounds;
    }

    private static getDefaultService(deleteExistingData: boolean): Tag {
        const existingServices = deleteExistingData ? [] : TagService.getTagsByType(TagType.SERVICE);

        let service = existingServices?.find(service => service.description === 'clean windows');

        if (!service) {
            service = new Tag('clean windows', TagType.SERVICE);
            TagService.updateTag(service);
        }

        return service;
    }

    private static getCustomerBalance(record: GeorgeRecord, customer: Customer): Array<Transaction> {
        const debt = Number(record.Debt.trim());
        const prepaid = Number(record.Prepaid.trim());

        const transactions = [];

        if (debt > 0)
            transactions.push(
                new Transaction(
                    TransactionType.Adjustment,
                    'adjustment.initial-balance-imported',
                    debt,
                    customer._id,
                    undefined,
                    'Debt import'
                )
            );
        if (prepaid > 0)
            transactions.push(
                new Transaction(
                    TransactionType.Adjustment,
                    'adjustment.initial-balance-imported',
                    -prepaid,
                    customer._id,
                    undefined,
                    'Prepaid import '
                )
            );

        return transactions;
    }
}
