import type { PaymentTransaction } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../ApplicationState';
import { Logger } from '../../Logger';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { isDevMode } from '../../isDevMode';
import type { IChargeState } from '../IChargeState';
import type { SquareTransactionInfo } from './SquareTransactionInfo';
import { enableFakeSquarePOS } from './enableFakeSquarePOS';
import { handleSquareReaderChargeComplete } from './handleSquareReaderChargeComplete';

export const chargeUsingSquareReader = (state: IChargeState, onSuccess: (paymentTransaction: PaymentTransaction) => void) => {
    try {
        if (!window.squarePOS) {
            if (isDevMode()) enableFakeSquarePOS();
            else throw 'Square api is not available';
        }
        window.squarePOS.initTransaction(
            {
                squareApplicationId: 'sq0idp-0G9R-cAmtBw_L-uMHi7VzA',
                squareCallbackURL: `web+squeegee://payments/square/callback`,
                currencyCode: ApplicationState.currencyCode(),
                amount: state.amount,
                state: JSON.stringify(state),
                customerId: state.providerCustomerId,
            },
            async (transactionInfo: SquareTransactionInfo) => {
                if (!transactionInfo.clientTransactionId) throw 'No client transaction id';
                if (transactionInfo.errorCode) throw transactionInfo.errorDescription;
                await handleSquareReaderChargeComplete(transactionInfo, state, onSuccess);
            }
        );
    } catch (ex) {
        Logger.error('There was an error calling the Square init transaction API', ex);
        new NotifyUserMessage('error.problem-calling-square-app');
    }
};
