import type { StoredObject } from '@nexdynamic/squeegee-common';
import { parse } from 'papaparse';
import { FileUploadDialog } from '../../Dialogs/FileUploadDialog';
import { Prompt } from '../../Dialogs/Prompt';
import { Data } from '../Data';
import type { SoapyRow } from './SoapyRow';
import type { SoapyRowRaw } from './SoapyRowRaw';
import { parseSoapyRowFramRaw } from './parseSoapyRowFramRaw';
import { soapyRowToSqueegeeCustomer } from './soapyRowToSqueegeeCustomer';

export const importFromGetSoapyCsv = async () => {
    const dialog = new FileUploadDialog(['.csv'], undefined);
    const files = await dialog.show();
    if (files && files.length) {
        const file = files[0];
        const reader = new FileReader();
        try {
            const loader = new Promise<void>(resolve => (reader.onload = () => resolve()));
            reader.readAsText(file);
            await loader;

            const csv = reader.result as string;

            const results = parse(csv, { header: true, skipEmptyLines: true });
            if (results.errors?.length) throw new Error(results.errors.map(e => e.message).join('\n'));

            const rows = results.data as SoapyRowRaw[];
            if (!rows.length) throw new Error('No rows found in the import CSV.');

            const soapyRows = new Array<SoapyRow>();
            for (const row of rows) {
                const soapyRow = parseSoapyRowFramRaw(row);
                soapyRows.push(soapyRow);
            }

            const importPrompt = new Prompt('import.get-soapy-import-title', 'import.get-soapy-import-title-description', {
                okLabel: 'import.get-soapy-import-button',
                localisationParams: { count: soapyRows.length.toString() },
            });
            await importPrompt.show();
            if (importPrompt.cancelled) return;

            const insertsAndUpdates = new Array<StoredObject>();
            for (const soapyRow of soapyRows) {
                soapyRowToSqueegeeCustomer(soapyRow, insertsAndUpdates);
            }

            console.log(insertsAndUpdates);
            Data.put(insertsAndUpdates);
        } catch (error) {
            console.error(error);
        }
    }
};

(window as any).importFromGetSoapyCsv = importFromGetSoapyCsv;
