import type { TemplateName } from '@nexdynamic/squeegee-templates';
import { TemplateService } from '@nexdynamic/squeegee-templates';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import { Logger } from '../../Logger';
import './ExternalTemplateDialog.scss';

export class ExternalTemplateDialog extends CustomDialog<void> {
    protected html: string;
    protected loading = false;
    protected renderError?: string;

    constructor(private templateName: TemplateName, private props: any, protected contentTitle: string) {
        super('ExternalTemplateDialog', '../Dialogs/ExternalContent/ExternalTemplateDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            isSecondaryView: true,
            cssClass: 'external-template-dialog',
        });

        this.loading = true;
    }

    public async init() {
        try {
            this.html = TemplateService.renderComponent(this.templateName, this.props, false);
        } catch (error) {
            this.renderError = 'Unable to render content';
            Logger.error(`${ExternalTemplateDialog.name}: ${this.renderError}`, error);
        } finally {
            this.loading = false;
        }
    }
}

export default ExternalTemplateDialog;
