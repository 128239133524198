import type { JobOccurrence } from '@nexdynamic/squeegee-common';
import { Data } from '../../Data/Data';
import { TimerService } from '../../Tracking/TimerService';
import { UserService } from '../../Users/UserService';
import { sendFrameApiData } from '../sendFrameApiData';

export const requestTimedOccurrence = () => {
    const assigneeId = UserService.getUser()?._id;
    if (!assigneeId) return;
    const timeEvent = TimerService.getTimerRunningForWorker(assigneeId);
    if (!timeEvent) return;

    const occurrenceId = timeEvent?.occurrenceId;
    if (!occurrenceId) return;

    const occurrence = Data.get<JobOccurrence>(occurrenceId);
    if (!occurrence) return;

    const timeData = TimerService.getTimeEntriesForJobOccurrence(occurrence);

    sendFrameApiData('timedOccurrence', { occurrence, timeData });
};
