import type { LngLat} from '@nexdynamic/squeegee-common';
import { StoredObject } from '@nexdynamic/squeegee-common';
import type { OrderType } from '../DayPilot';
import type { IOccurrenceOrderData } from './IOccurrenceOrderData';

export class DayOrderData extends StoredObject {
    constructor(
        public day: string,
        public user: string | undefined,
        public lastKnownLocation?: LngLat,
        public occurrenceOrderData: IOccurrenceOrderData = {},
        public sortMethod: OrderType = 'optimal',
        public jobsRescheduledSinceOptimisation: boolean = false,
        public travelDataLastUpdated?: string,
        public totalDistanceMetres?: number,
        public totalDurationSeconds?: number
    ) {
        super('occurrencesbyday');
    }
}
