import { CustomDialog } from '../../Dialogs/CustomDialog';
import type { ScheduleItem } from '../../Schedule/Components/ScheduleItem';

export interface ISelectableScheduleItem {
    isSelected: boolean;
    scheduleItem: ScheduleItem;
}

export class SelectAppointmentsForNotification extends CustomDialog<Array<ScheduleItem>> {
    public dateFormat = 'ddd, MMM Do';

    protected selectableScheduleItems: Array<ISelectableScheduleItem> = [];

    constructor(
        protected currentScheduleItems: Array<ScheduleItem> = [],
        private scheduleItems: Array<ScheduleItem> = [],
        protected isManualNotification: boolean = false
    ) {
        super('selectAppointmentsForNotification', '../Jobs/Components/SelectAppointmentsForNotification.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'appointment-select-dialog select-list-dialog no-nav-shadow',
            coverViewport: true,
            noObfuscator: true,
            smallerOnDesktop: true,
        });
        this.selectableScheduleItems = this.scheduleItems.map(scheduleItem => {
            return <ISelectableScheduleItem>{
                isSelected: this.currentScheduleItems.some(
                    currentScheduleItem => currentScheduleItem.occurrence._id === scheduleItem.occurrence._id
                ),
                scheduleItem,
            };
        });
        this.hasSelectedItems = !!this.getSelectedScheduleItems().length;
    }

    protected delegateOk = () => {
        this.ok(this.getSelectedScheduleItems());
    };

    protected delegateSelectAll() {
        this.selectableScheduleItems.forEach(item => (item.isSelected = true));
        this.hasSelectedItems = !!this.getSelectedScheduleItems().length;
    }

    protected delegateSelectNone() {
        this.selectableScheduleItems.forEach(item => (item.isSelected = false));
        this.hasSelectedItems = false;
    }

    private getSelectedScheduleItems(): Array<ScheduleItem> {
        return <Array<ScheduleItem>>(
            this.selectableScheduleItems
                .filter(selectableScheduleItem => selectableScheduleItem.isSelected === true)
                .map(selectableScheduleItem => selectableScheduleItem.scheduleItem)
        );
    }

    protected hasSelectedItems = false;
    protected selectItem(item: ISelectableScheduleItem, event: Event) {
        item.isSelected = !item.isSelected;
        if (event) event.stopPropagation();
        this.hasSelectedItems = !!this.getSelectedScheduleItems().length;
    }

    public async getResult() {
        return this.getSelectedScheduleItems();
    }
}
