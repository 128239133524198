import { CleanerPlannerCommonEntity } from './CleanerPlannerCommonEntity';
export class CleanerPlannerInvoice extends CleanerPlannerCommonEntity {
    CustomerId: string;
    Reference: string;
    /** Address note of some kind? */
    Scheme: string;
    /** DD/MM/YYYY HH:mm:ss */
    Date: string;
    /** DD/MM/YYYY HH:mm:ss */
    Sent: string;
    TransactionNotes: string;
    /** "True" or "False" */
    Paid: string;
    /** "True" or "False" */
    IsQuote: string;
}
