import type { ScheduleItem } from '../Schedule/Components/ScheduleItem';
import type { ISortOption } from '../Schedule/Views/ISortData';

const sortOptions: Array<ISortOption<keyof ScheduleItem>> = [
    {
        text: 'sorting.by-round-distance',
        value: [
            { sortPropertyName: 'roundName', sortDirection: 1 },
            { sortPropertyName: 'distance', sortDirection: 1 },
        ],
    },
    { text: 'sorting.by-distance', value: [{ sortPropertyName: 'distance', sortDirection: 1 }] },
    { text: 'sorting.by-optimised-drive', value: [{ sortPropertyName: 'driveOptimisationRank', sortDirection: 1 }] },
    { text: 'sorting.by-optimised-drive-completed', value: [{ sortPropertyName: 'driveOptimisationRank', sortDirection: -1 }] },
    { text: 'sorting.by-planned-order', value: [{ sortPropertyName: 'plannedOrder', sortDirection: 1 }] },
];

export class ScheduleItemSortValueConverter {
    toView(array: Array<ScheduleItem>, sortOptionIndex?: number) {
        if (sortOptionIndex === -1) return array;
        return array.sort((a, b) => {
            return ScheduleItemSortValueConverter.sortOnProperty(a, b, 0, sortOptionIndex ? sortOptions[sortOptionIndex] : sortOptions[0]);
        });
    }

    private static sortOnProperty(
        a: ScheduleItem,
        b: ScheduleItem,
        propertyIndex: number,
        sortGroupItems: ISortOption<keyof ScheduleItem>
    ): number {
        if (propertyIndex === sortGroupItems.value.length) return 0;

        const propertyName = <keyof ScheduleItem>sortGroupItems.value[propertyIndex].sortPropertyName;
        const sortDirection = <number>sortGroupItems.value[propertyIndex].sortDirection;
        const occurrenceA = a[propertyName] ? (typeof a[propertyName] === 'string' ? <string>a[propertyName] : a[propertyName]) : '0';
        const occurrenceB = b[propertyName] ? (typeof b[propertyName] === 'string' ? <string>b[propertyName] : b[propertyName]) : '0';
        if (occurrenceA !== occurrenceB) {
            return <any>occurrenceA < <any>occurrenceB ? -1 * sortDirection : 1 * sortDirection;
        } else {
            propertyIndex++;
            return this.sortOnProperty(a, b, propertyIndex, sortGroupItems);
        }
    }
}
