import type {
    GlobalSettingIds,
    JobOccurrence,
    Notification,
    StoredObject,
    StoredObjectResourceTypes,
    Transaction,
} from '@nexdynamic/squeegee-common';
import { Setting } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { SqueegeeEvent } from './SqueegeeEvent';
import type { Subscription } from './SqueegeeEventAggregator';

export class DataRefreshedEvent extends SqueegeeEvent {
    private constructor(public updatedObjects: Readonly<{ [id: string]: StoredObject | null }>) {
        super();

        const updatedObjectsArray = [] as Array<StoredObject>;
        for (const updatedObjectId in updatedObjects) {
            const storedObject = updatedObjects[updatedObjectId];
            ApplicationState.signaler && ApplicationState.signaler.signal('item-update:' + updatedObjectId);
            if (storedObject) updatedObjectsArray.push(storedObject);
        }

        this.updatedObjectsArray = updatedObjectsArray;
    }
    public updatedObjectsArray: Readonly<Array<StoredObject>>;
    private static _updatedObjects?: { [id: string]: StoredObject | null };
    private static _urgentTimer?: NodeJS.Timeout;
    private static _nonUrgentTimer?: NodeJS.Timeout;

    public static emit(updatedObjects: { [id: string]: StoredObject | null }, lazy: boolean) {
        if (DataRefreshedEvent._nonUrgentTimer) clearTimeout(DataRefreshedEvent._nonUrgentTimer);

        if (!DataRefreshedEvent._updatedObjects) {
            DataRefreshedEvent._updatedObjects = updatedObjects;
        } else {
            Object.assign(DataRefreshedEvent._updatedObjects, updatedObjects);
        }

        if (lazy) {
            DataRefreshedEvent._nonUrgentTimer = setTimeout(() => {
                DataRefreshedEvent.doEmit();
                delete DataRefreshedEvent._nonUrgentTimer;
            }, 500) as any as NodeJS.Timeout;
        } else if (!DataRefreshedEvent._urgentTimer) {
            DataRefreshedEvent._urgentTimer = setTimeout(() => {
                DataRefreshedEvent.doEmit();
                delete DataRefreshedEvent._urgentTimer;
            }, 50) as any as NodeJS.Timeout;
        }
    }

    private static doEmit() {
        new DataRefreshedEvent(DataRefreshedEvent._updatedObjects || {});
        delete DataRefreshedEvent._updatedObjects;
    }

    private _customerTypeUpdates: { [customerId: string]: { [type: string]: Array<StoredObject> } };
    private get customerTypeUpdates() {
        this.ensureLookupData();
        return this._customerTypeUpdates;
    }

    private _types: { [type: string]: true };
    private get types() {
        this.ensureLookupData();
        return this._types;
    }
    private _lookupDataSet = false;
    private ensureLookupData() {
        if (!this._lookupDataSet) {
            this._types = {};
            this._customerTypeUpdates = {};
            for (const storedObject of this.updatedObjectsArray) {
                if (storedObject.resourceType && !this._types[storedObject.resourceType]) this._types[storedObject.resourceType] = true;
                if (storedObject.resourceType === 'notifications') {
                    const notification = storedObject as Notification;
                    if (!this._customerTypeUpdates[notification.addressee]) {
                        this._customerTypeUpdates[notification.addressee] = {};
                    }
                    if (!this._customerTypeUpdates[notification.addressee][storedObject.resourceType]) {
                        this._customerTypeUpdates[notification.addressee][storedObject.resourceType] = [];
                    }
                    this._customerTypeUpdates[notification.addressee][storedObject.resourceType].push(storedObject);
                } else if (storedObject.resourceType === 'joboccurrences' || storedObject.resourceType === 'transactions') {
                    const jobOccurrenceOrTransaction = storedObject as JobOccurrence | Transaction;
                    if (!jobOccurrenceOrTransaction.customerId) continue;
                    if (!this._customerTypeUpdates[jobOccurrenceOrTransaction.customerId]) {
                        this._customerTypeUpdates[jobOccurrenceOrTransaction.customerId] = {};
                    }
                    if (!this._customerTypeUpdates[jobOccurrenceOrTransaction.customerId][storedObject.resourceType]) {
                        this._customerTypeUpdates[jobOccurrenceOrTransaction.customerId][storedObject.resourceType] = [];
                    }
                    this._customerTypeUpdates[jobOccurrenceOrTransaction.customerId][storedObject.resourceType].push(storedObject);
                }
            }
        }
    }

    public hasAnyType(...types: Array<StoredObjectResourceTypes>) {
        for (const t of types) {
            if (this.types[t]) return true;
        }
        return false;
    }

    public hasCustomerTypesUpdated(customerId: string, ...types: Array<StoredObjectResourceTypes>) {
        if (!this.customerTypeUpdates[customerId]) return false;
        for (const t of types) {
            if (this.customerTypeUpdates[customerId][t]) return true;
        }
        return false;
    }

    public getCustomerTypesUpdated(customerId: string) {
        if (!this.customerTypeUpdates[customerId]) return;
        return this.customerTypeUpdates[customerId];
    }

    public filterByType<TStoredObjectType>(type: StoredObjectResourceTypes): Array<TStoredObjectType> {
        const items: Array<unknown> = [];
        const ids = Object.keys(this.updatedObjects);

        for (const id of ids) {
            const item = this.updatedObjects[id];
            if (item && item.resourceType === type) items.push(item);
        }

        return items as Array<TStoredObjectType>;
    }

    public getUpdatedSetting<TSettingType>(globalSettingId: GlobalSettingIds, dynamicId?: string) {
        const settingId = Setting.getId(ApplicationState.account.uuid, globalSettingId, dynamicId);
        return this.updatedObjects[settingId] as Setting<TSettingType> | undefined;
    }
}

export type { Subscription };
