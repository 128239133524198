import { bindable, computedFrom } from 'aurelia-framework';
import * as Chartist from 'chartist';

export class GaugeChartCustomElement {
    @bindable() data: { max: number; series: Array<number> | Array<Chartist.IChartistSeriesData> };

    public chartRef: HTMLDivElement;
    public chart?: Chartist.IChartistPieChart;

    @computedFrom('data.series')
    public get total() {
        if (this.data && this.data.series) {
            const value = <number>this.data.series[0];
            const max = this.data.max / 2;
            const percentage = (value / max) * 100;
            return `${isNaN(percentage) ? 0 : percentage.toFixed(0)}%`;
        }
    }

    public dataChanged(newValue: { max: number; series: Array<number> | Array<Chartist.IChartistSeriesData> } | undefined) {
        if (this.chart === undefined) this.loadChart();
        else if (newValue !== undefined) {
            this.chart.update(
                { series: newValue.series },
                {
                    total: newValue.max,
                    donut: true,
                    donutWidth: 15,
                    startAngle: 270,
                    showLabel: false,
                }
            );
        }
    }

    private loadChart() {
        this.chart = new Chartist.Pie(
            this.chartRef,
            { series: this.data.series },
            {
                donut: true,
                donutWidth: 15,
                startAngle: 270,
                total: this.data.max,
                showLabel: false,
            }
        );
        this.animateChart();
    }

    private animateChart() {
        if (!this.chart) return;
        this.chart.on('draw', (data: any) => {
            if (data.type === 'slice') {
                const pathLength = data.element._node.getTotalLength();
                data.element.attr({
                    'stroke-dasharray': pathLength + 'px ' + pathLength + 'px',
                });
                const animationDefinition: Chartist.IChartistAnimations = {
                    'stroke-dashoffset': {
                        id: 'anim' + data.index,
                        dur: 400,
                        from: -pathLength + 'px',
                        to: '0px',
                        easing: Chartist.Svg.Easing.easeOutQuint,
                        fill: 'freeze',
                    },
                };
                if (data.index !== 0) animationDefinition['stroke-dashoffset'].begin = 'anim' + (data.index - 1) + '.end';
                data.element.attr({ 'stroke-dashoffset': -pathLength + 'px' });
                data.element.animate(animationDefinition, false);
            }
        });
    }

    protected detached() {
        this.chart && this.chart.detach();
    }
}
