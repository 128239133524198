import { ProductLevel } from '@nexdynamic/squeegee-common';
import { PLATFORM } from 'aurelia-framework';
import { ApplicationState } from '../ApplicationState';
import type { IRoute } from '../IRoute';

export const routes: Array<IRoute> = [
    {
        route: '',
        name: '',
        title: '',
        moduleId: PLATFORM.moduleName('Views/DefaultChildView'),
        nav: false,
        redirect: 'customers',
        settings: {
            roles: ['Owner', 'Admin'],
        },
    },
    {
        route: 'customers',
        name: 'customers',
        moduleId: PLATFORM.moduleName('Franchise/Customers'),
        nav: false,
        title: 'route-config.franchisee-customers-title',
        settings: {
            minimumSubscription: ProductLevel.Core,
            roles: ['Owner', 'Admin'],
            enabled: () => ApplicationState.isFranchise(),
        },
    },
    {
        route: 'franchisees',
        name: 'franchisees',
        moduleId: PLATFORM.moduleName('Franchise/Franchisees'),
        nav: false,
        title: 'route-config.franchisees-title',
        settings: {
            minimumSubscription: ProductLevel.Core,
            roles: ['Owner', 'Admin'],
            enabled: () => ApplicationState.isFranchise(),
        },
    },
    {
        route: 'options',
        name: 'options',
        moduleId: PLATFORM.moduleName('Franchise/Franchise'),
        nav: false,
        title: 'route-config.franchise-title',
        settings: {
            minimumSubscription: ProductLevel.Core,
            roles: ['Owner', 'Admin'],
            enabled: () => ApplicationState.isFranchise(),
        },
    },
    {
        route: 'download-reports',
        name: 'download-reports',
        moduleId: PLATFORM.moduleName('Franchise/DownloadReports'),
        nav: false,
        title: 'route-config.franchisees-download-reports',
        settings: {
            minimumSubscription: ProductLevel.Advanced,
            roles: ['Owner', 'Admin'],
            enabled: () => ApplicationState.isFranchise(),
        },
    },
];
