import type { Customer } from '@nexdynamic/squeegee-common';
import type { Subscription } from 'aurelia-event-aggregator';
import { ApplicationState } from '../../ApplicationState';
import { CustomerService } from '../../Customers/CustomerService';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import { MandateApi } from '../../Server/MandateApi';
import { CustomDialog } from '../CustomDialog';
import { Prompt } from '../Prompt';

export class MandateHistoryDialog extends CustomDialog<void> {
    protected model = {
        customer: <Customer>{},
    };
    protected automaticPaymentName = ApplicationState.dd;
    protected mandateId: string;
    protected gcDashboardLink: string;
    private _dataChangedEvent: Subscription;



    constructor(customer: Customer) {
        super('mandateHistoryDialog', './AutomaticPayments/MandateHistoryDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'mandate-history-dialog',
            isSecondaryView: true,
        });
        this.model.customer = customer;
        this.mandateId = this.model.customer.paymentProviderMetaData?.gocardless?.sourceOrMandateId || '';
        this.gcDashboardLink = (this.mandateId && `https://manage.gocardless.com/mandates/${this.mandateId}`) || '';
    }

    public async init() {
        this._dataChangedEvent = DataRefreshedEvent.subscribe((e: DataRefreshedEvent) => {
            if (e.updatedObjects[this.model.customer._id] === undefined) return;

            if (e.updatedObjects[this.model.customer._id] === null) return super.cancel();

            this.model = {
                customer: this.model.customer,
            };
        });
    }

    public dispose() {
        this._dataChangedEvent && this._dataChangedEvent.dispose && this._dataChangedEvent.dispose();
        super.dispose();
    }

    protected async checkMandateStatus() {
        try {
            if (!this.model.customer.paymentProviderMetaData?.gocardless) return;
            const originalMandateStatus = this.model.customer.paymentProviderMetaData?.gocardless?.status;
            const checkedAutomaticPaymentStatus = await MandateApi.checkCustomerMandate(this.model.customer._id);

            if (originalMandateStatus === checkedAutomaticPaymentStatus) {
                await new Prompt('prompts.payment-status-title', 'prompts.payment-status-same', {
                    allowCancel: false,
                    localisationParams: { status: checkedAutomaticPaymentStatus || '' },
                }).show();
            } else if (checkedAutomaticPaymentStatus) {
                this.model.customer.paymentProviderMetaData.gocardless.status = checkedAutomaticPaymentStatus;
                CustomerService.addOrUpdateCustomer(this.model.customer);
                await new Prompt('prompts.payment-status-title', 'prompts.payment-status-changed', {
                    allowCancel: false,
                    localisationParams: { status: checkedAutomaticPaymentStatus || '' },
                }).show();
            }
        } catch (error) {
            await new Prompt('prompts.payment-status-title', 'prompts.payment-status-error', {
                allowCancel: false,
                localisationParams: { error },
            }).show();
        }
    }
}
