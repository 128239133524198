import type {
    IReportBuilderSerialisable,
    ReportTypes,
    StoredObject,
    TableData,
    TranslationKey,
    TReportType,
} from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import { DateTimePicker } from '../Components/DateTimePicker/DateTimePicker';
import { Data } from '../Data/Data';
import { SqueegeeLocalStorage } from '../Data/SqueegeeLocalStorage';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { HtmlDialog } from '../Dialogs/HtmlDialog';
import { NumpadDialog } from '../Dialogs/Numpad/NumpadDialog';
import { Prompt } from '../Dialogs/Prompt';
import { ReportDialog } from '../Dialogs/ReportDialog';
import { SimpleSelect } from '../Dialogs/SimpleSelect';
import { TextDialog } from '../Dialogs/TextDialog';
import { LoaderEvent } from '../Events/LoaderEvent';
import { GlobalFlags } from '../GlobalFlags';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { Api } from '../Server/Api';
import { UserService } from '../Users/UserService';
import { animate, Utilities } from '../Utilities';

export class ReportsService {
    private static get DEFAULT_LOGO_IMG() {
        return `<img style="max-height: 28px;" src="${Api.apiEndpoint}/images/icon.png" /> `;
    }
    static async getExecutableReports() {
        const availableReports = await ReportsService.getUsersAvailableReports();

        const executableReports = new Array<{
            name: TranslationKey;
            type: TReportType;
            typeKey: ReportTypes;
            logoImg: string;
            show: () => void;
        }>();

        if (availableReports && availableReports.length) {
            for (const reportBuilderSerialised of availableReports) {
                try {
                    const generator = Utilities.parseFunction<
                        (data: Array<StoredObject>, ...args: Array<any>) => Array<TableData> | string
                    >(reportBuilderSerialised.generator);

                    const name = reportBuilderSerialised.name;

                    const type = reportBuilderSerialised.type;

                    const typeKey: ReportTypes = `reports-type.${type}`;

                    const logoImg = reportBuilderSerialised.logoUrl
                        ? `<img style="max-height: 28px;" src="${reportBuilderSerialised.logoUrl}" /> `
                        : ReportsService.DEFAULT_LOGO_IMG;

                    const show = async () => {
                        await animate();
                        try {
                            const params = [Data.all(''), window.sq] as Array<any>;
                            if (reportBuilderSerialised.params) {
                                new LoaderEvent(false);
                                for (const param of reportBuilderSerialised.params) {
                                    const paramKey = `report-${reportBuilderSerialised.name.replace(/ /g, '-')}-${param.name.replace(
                                        / /g,
                                        '-'
                                    )}`;
                                    let value = !param.dontSave ? SqueegeeLocalStorage.getItem(paramKey) || '' : '';

                                    switch (param.type) {
                                        case 'boolean':
                                            value = (
                                                (await new Prompt(param.name, param.description, {
                                                    okLabel: 'general.yes',
                                                    cancelLabel: 'general.no',
                                                }).show()) || false
                                            ).toString();
                                            break;
                                        case 'date': {
                                            const pOptions = Object.entries(reportBuilderSerialised.paramOptions || {});
                                            const paramOptions: Record<string, Record<string, string | undefined> | undefined> = {};
                                            for (const [name, options] of pOptions) {
                                                const paramOption = (paramOptions[name] = {} as Record<string, string | undefined>);
                                                for (const p of options.split(',')) {
                                                    const [option, value] = p.split(':');
                                                    paramOption[option] = value;
                                                }
                                            }

                                            const min = paramOptions[param.name]?.min;

                                            const minAsNumber = Number(min);
                                            let disableDatesBefore = '';
                                            if (
                                                min &&
                                                !ApplicationState.stateFlags.devMode &&
                                                ApplicationState.getSetting('global.archive-occurrences-before-days', true)
                                            ) {
                                                disableDatesBefore = moment().add(minAsNumber, 'days').format('YYYY-MM-DD');
                                            }
                                            const datePicker = new DateTimePicker(
                                                false,
                                                value || moment().format(),
                                                param.name,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                disableDatesBefore
                                            );
                                            datePicker.init();
                                            await datePicker.open();
                                            if (datePicker.canceled) return;
                                            value = datePicker.selectedDate;
                                            break;
                                        }
                                        case 'multi': {
                                            const selectDialog = new SimpleSelect(
                                                param.name,
                                                param.options || [],
                                                'text',
                                                'value',
                                                undefined,
                                                param.description
                                            );
                                            const result = await selectDialog.show();
                                            if (selectDialog.cancelled) return;
                                            value = result.value;
                                            break;
                                        }
                                        default: {
                                            if (param.type === 'decimal' || param.type === 'integer') {
                                                if (GlobalFlags.isAppleMobileDevice || GlobalFlags.isAndroidMobileDevice) {
                                                    const numpadDialog = new NumpadDialog(
                                                        Number(value) || undefined,
                                                        '',
                                                        '',
                                                        param.type === 'integer'
                                                    );
                                                    numpadDialog.title = param.name as TranslationKey;
                                                    const result = await numpadDialog.show();
                                                    if (numpadDialog.cancelled) return;
                                                    value = result.toFixed(param.type === 'integer' ? 0 : 2);
                                                    break;
                                                }
                                            }
                                            let validator: undefined | ((value: string) => true | TranslationKey);
                                            if (param.type === 'decimal') {
                                                validator = value => (isNaN(Number(value)) ? 'reports.params-error-decimal' : true);
                                            } else if (param.type === 'integer') {
                                                validator = value =>
                                                    isNaN(Number(value)) || Number(value).toString() !== Number(value).toFixed(0)
                                                        ? 'reports.params-error-integer'
                                                        : true;
                                            } else if (param.validation) {
                                                const regex = new RegExp(param.validation.regex);
                                                const message = param.validation.message as TranslationKey;
                                                validator = value => ((value || '').match(regex) ? true : message);
                                            }
                                            const paramDialog = new TextDialog(
                                                param.name as TranslationKey,
                                                param.description as TranslationKey,
                                                value,
                                                '',
                                                validator
                                            );
                                            value = await paramDialog.show();
                                            if (paramDialog.cancelled) return;
                                        }
                                    }

                                    if (!param.dontSave) SqueegeeLocalStorage.setItem(paramKey, value);

                                    params.push(value);
                                }
                            }

                            new LoaderEvent(true);
                            await animate();

                            const reportData = await generator.apply(null, params);
                            if (typeof reportData === 'string') {
                                new HtmlDialog(reportBuilderSerialised.name, reportData, true).show();
                                new NotifyUserMessage('reports.archived-data-warning');
                            } else {
                                new ReportDialog(
                                    reportBuilderSerialised.name,
                                    reportData,
                                    reportBuilderSerialised.defaultSortBy,
                                    !reportBuilderSerialised.defaultSortDescending
                                ).show(DialogAnimation.SLIDE);
                                new NotifyUserMessage('reports.archived-data-warning');
                            }
                        } catch (error) {
                            new Prompt('reports.report-render-error', 'reports.report-render-error-message', { cancelLabel: '' }).show();
                        }
                        new LoaderEvent(false);
                    };

                    executableReports.push({ name, type, typeKey, logoImg, show });
                } catch (error) {
                    Logger.error('Error during load report builder', { error, reportBuilderSerialised });
                }
            }
        }

        return executableReports;
    }
    public static async getUsersAvailableReports() {
        if (ApplicationState.isInAnyRole(['Admin', 'Owner'])) return ReportsService.getAllAvailableReports();

        if (!ApplicationState.isInAnyRole(['Reporting'])) return [];

        const user = UserService.getUser();
        if (!user || !user.allowedReports || !user.allowedReports.length) return [];

        const allReports = await ReportsService.getAllAvailableReports();
        const allowedReports = (allReports || []).filter(r => user.allowedReports && user.allowedReports.find(x => x === r.name));
        return allowedReports;
    }

    private static _allAvailableReports: Array<IReportBuilderSerialisable> | undefined;
    public static async getAllAvailableReports() {
        if (document.location.host === 'localhost:3000') ReportsService._allAvailableReports = undefined;

        if (!ReportsService._allAvailableReports) {
            const allAvailableReportBuilders =
                ReportsService._allAvailableReports ||
                (await Api.get<Array<IReportBuilderSerialisable>>(
                    document.location.hostname === 'localhost' && document.location.port === '3000'
                        ? `http://${document.location.host}`
                        : Api.apiEndpoint,
                    '/api/reports/additional'
                ));

            if (allAvailableReportBuilders && allAvailableReportBuilders.data && Array.isArray(allAvailableReportBuilders.data)) {
                SqueegeeLocalStorage.setItem('additionalReports', JSON.stringify(allAvailableReportBuilders.data));
                ReportsService._allAvailableReports = allAvailableReportBuilders.data;
            }
            if (!ReportsService._allAvailableReports) {
                try {
                    const additionalReportsSerialised = SqueegeeLocalStorage.getItem('additionalReports');
                    if (additionalReportsSerialised) ReportsService._allAvailableReports = JSON.parse(additionalReportsSerialised);
                } catch (error) {
                    Logger.error('Could not deserialise additional reports', error);
                    SqueegeeLocalStorage.removeItem('additionalReports');
                }
            }
        }
        return ReportsService._allAvailableReports || [];
    }
}
