import { Location } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { ApplicationStateUpdatedEvent } from '../Events/ApplicationStateUpdatedEvent';
import type { Subscription } from '../Events/SqueegeeEventAggregator';
import { LocationUtilities } from '../Location/LocationUtilities';
import { Logger } from '../Logger';
import { Api } from '../Server/Api';
import { LocationApi } from '../Server/LocationApi';
import { Utilities } from '../Utilities';
import { CustomDialog } from './CustomDialog';

export class AddressLookupDialog extends CustomDialog<Location> {
    protected searchTerm = '';
    protected searching: boolean;
    protected searchResults: Array<Location>;
    protected updating = false;
    protected stateFlags = ApplicationState.stateFlags;
    protected account = ApplicationState.account;
    protected Api = Api;
    protected manageAccountOnlineLink = Utilities.manageAccountOnlineLink;
    protected menuTitle = 'addresses.title';
    private _applicationStateUpdatedSub: Subscription;

    private location: Location;

    constructor(location: Location = new Location()) {
        super('addressLookupDialog', './AddressLookupDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'address-select-dialog select-list-dialog select-dialog-with-search no-nav-shadow',
            isSecondaryView: true,
        });
        this._applicationStateUpdatedSub = ApplicationStateUpdatedEvent.subscribe(() => {
            this.account = ApplicationState.account;
        });
        const locationString = JSON.stringify(location);
        this.location = JSON.parse(locationString);
        if (location && location.addressDescription) {
            this.searchTerm = location.addressDescription;
            this.search();
        }
    }

    protected manageAccount(event: Event) {
        event.stopPropagation();
        try {
            ApplicationState.router.navigateToRoute('account');
        } catch (error) {
            Logger.error(`Error during navigate to manageAccount route from address lookup dialog.`, error);
        }
    }

    public clearSearchTerm() {
        this.searchTerm = '';
    }

    public async search() {
        this.searching = true;
        this.searchResults = [];
        if (this.searchTerm && this.searchTerm.length > 3 && Api.isConnected) {
            // REPLACE THIS WITH THE POSTCODEANYWHERE SERVICE RESULTS
            const searchResults = await LocationApi.getAddresses(this.searchTerm);
            if (searchResults && searchResults.length) {
                this.replaceSearchResultsWithNewItems(searchResults);
            }
        }
        this.searching = false;
    }

    private replaceSearchResultsWithNewItems(newLocations: Array<Location>) {
        this.searchResults = [];
        if (newLocations && newLocations.length) {
            for (const location of newLocations) this.searchResults.push(location);
        }
    }

    protected async setLocation(location?: Location) {
        if (location) {
            if (
                location.addressId &&
                location.addressId === this.location?.addressId &&
                location.addressSource === 'addressService' &&
                this.location.isVerified &&
                this.location.lngLat?.length === 2
            ) {
                location.isVerified = true;
                location.lngLat = this.location.lngLat;
            }
            this.location = location;
        } else {
            this.location.addressDescription = this.searchTerm ? this.searchTerm.trim() : undefined;
            this.location.addressId = undefined;
            this.location.lngLat = undefined;
            this.location.lngLatSource = undefined;
            this.location.addressSource = 'locationPickerManual';
            this.location.isVerified = false;
        }

        // WTF: if this is w3w, then we need to hydrate the suggestion that was selected with lnglat
        if (this.location.addressSource === 'what3words' && !this.location.lngLat) {
            const threeWords =
                this.location.addressId ||
                (this.location.addressDescription && LocationApi.getThreeWordsFromText(this.location.addressDescription));
            if (threeWords) {
                const exactLocation = await LocationApi.getW3WAddresses(threeWords, true);
                if (exactLocation?.[0]) this.location = exactLocation[0];
            }
        }

        if (this.location.partialMatch) this.location.addressDescription = this.searchTerm;
        if (this.Api.isConnected && !(await LocationUtilities.pinAddress(this.location))) return;

        this.ok(this.location);
    }

    public async onCancel() {
        if (this._applicationStateUpdatedSub) this._applicationStateUpdatedSub.dispose();
        return true;
    }
}
