import { bindable, bindingMode, inject } from 'aurelia-framework';
import hammer from 'hammerjs';
import { ApplicationState } from '../../ApplicationState';

@inject(Element)
export class SwipeCustomAttribute {
    /**
     * Fired upon the completion of a swipe left event
     * @memberOf SwipeCustomeAttribute
     */
    @bindable({ defaultBindingMode: bindingMode.oneTime }) onLeft: () => any;
    /**
     * Fired upon the completion of a swipe right event
     * @memberOf SwipeCustomeAttribute
     */
    @bindable({ defaultBindingMode: bindingMode.oneTime }) onRight: () => any;

    private _hammerTime: HammerManager;
    public constructor(element: HTMLElement) {
        this._hammerTime = new hammer(element, <any>{
            dragLockToAxis: true,
            dragBlockHorizontal: true,
            preventDefault: true,
        });
    }

    public async attached() {
        if (ApplicationState.getSetting('global.disable-swiping', false)) return;
        if (this.onLeft) this._hammerTime.on('swipeleft', () => this.onLeft());
        if (this.onRight) this._hammerTime.on('swiperight', () => this.onRight());
    }

    public async detached() {
        this._hammerTime.destroy();
    }
}
