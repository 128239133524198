import type { LngLat } from '@nexdynamic/squeegee-common';
import { bindable, bindingMode } from 'aurelia-framework';
import { ApplicationState } from '../ApplicationState';
import { LocationUtilities } from '../Location/LocationUtilities';

export class DistanceCustomElement {
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public coords: LngLat;

    protected distance: number;
    protected distanceWholeNumber: string;
    protected distanceDecimalPart: string;
    protected distanceUnits: string;
    protected locationError: string;

    private _updateInterval: number;
    private async updateDistance() {
        if (this.coords) {
            let distance: number | void;
            try {
                distance = await LocationUtilities.getDistance(this.coords);
            } catch (error) {
                this.locationError = error.message;
                return;
            }

            if (distance !== undefined) {
                this.distance = distance;
                this.distanceUnits = ApplicationState.distanceUnits;
                const parts = this.distance.toFixed(2).split('.');
                this.distanceWholeNumber = parts[0];
                this.distanceDecimalPart = parts[1];
            }
        }
        this._updateInterval = <any>setTimeout(() => this.updateDistance(), 6000);
    }

    public async attached() {
        this.updateDistance();
    }

    public detached() {
        clearTimeout(this._updateInterval);
    }
}
