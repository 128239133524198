import type { ProductLevel } from '@nexdynamic/squeegee-common';
import { bindable, inject } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { SubscriptionUpdatedEvent } from '../../Events/SubscriptionUpdatedEvent';
import { Logger } from '../../Logger';

@inject(Element)
export class IfSubscriptionLevelCustomAttribute {
    @bindable({ primaryProperty: true }) productLevelAllowed: ProductLevel;
    initialDisplay: string;

    constructor(private element: Element) {
        SubscriptionUpdatedEvent.subscribe<SubscriptionUpdatedEvent>(() =>
            this.setElementDisplay(this.productLevelAllowed, ApplicationState.subscription.product.productLevel)
        );
    }

    propertyChanged(name: string, newValue: ProductLevel | undefined, _oldValue: string) {
        if (name === 'productLevelAllowed') this.setElementDisplay(newValue, ApplicationState.subscription.product.productLevel);
    }

    setElementDisplay(value: ProductLevel | undefined, accountProductLevel: ProductLevel) {
        try {
            if (!value) return;

            const element = this.element as HTMLElement | null;
            if (!element) return;

            if (Number(value) > accountProductLevel) {
                if (element.style.display === 'none') return;

                this.initialDisplay = (this.element as HTMLElement).style.display;
                element.style.display = 'none';
            } else {
                if (element.style.display !== 'none') return;

                element.style.display = this.initialDisplay || '';
            }
        } catch (error) {
            Logger.error('Unable to change element display value');
        }
    }
}
