import type { Customer, TranslationKey } from '@nexdynamic/squeegee-common';
import { searchCustomers } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../ApplicationState';
import { NumberToCurrencyValueConverter } from '../../Converters/NumberToCurrencyValueConverter';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import type { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { Logger } from '../../Logger';
import { TransactionService } from '../../Transactions/TransactionService';
import { CustomerService } from '../CustomerService';

export class SelectCustomerDialog extends CustomDialog<Customer | undefined> {
    public filteredList: Array<Customer>;
    public searchTerm = '';
    public customerIsNew = false;

    constructor(
        public selectedCustomer?: Customer,
        public dialogTitle?: TranslationKey,
        public excludeCustomers?: Customer[],
        public referenceCustomer?: { ref: string; name: string; address?: string; email?: string; telephoneNumber?: string },
        public includeInactive = false
    ) {
        super('selectCustomerDialog', '../Customers/Components/SelectCustomerDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'select-list-dialog select-dialog-with-search no-nav-shadow',
            coverViewport: true,
            noObfuscator: true,
            smallerOnDesktop: true,
        });

        if (!dialogTitle) this.dialogTitle = 'dialogs.select-customer-title';
    }

    private _customerList: Readonly<Array<Customer>> = [];

    protected _delegateOk() {
        if (this.selectedCustomer) {
            this.ok(this.selectedCustomer);
        } else {
            this.add();
        }
    }

    public async show(animation: DialogAnimation) {
        const promise = super.show(animation);
        new LoaderEvent(true);
        try {
            this._customerList = CustomerService.getCustomers();
            this.filter();
        } catch (error) {
            Logger.error(`Error during get and filter the customers in the select customer dialog.`, error);
        }
        new LoaderEvent(false);
        return promise;
    }

    public filter() {
        const results = [];

        if (this.selectedCustomer) results.push(this.selectedCustomer);

        const mobilePrefixes = ApplicationState.account.smsMobilePhonePrefixes?.split(',').map(x => x.trim());
        const searchResults = searchCustomers({
            customers: this._customerList,
            searchText: this.searchTerm,
            searchJobs: true,
            mobilePrefixes,
        });

        searchResults
            .sort((a, b) => {
                if (a.state !== 'inactive' && b.state === 'inactive') return 1;
                if (a.state === 'inactive' && b.state !== 'inactive') return -1;
                return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
            })
            .filter(c => this.includeInactive || c.state !== 'inactive');

        if (this.excludeCustomers) {
            for (const excludeCustomer of this.excludeCustomers) {
                let index = -1;
                const customerId = excludeCustomer._id;
                if (customerId) index = searchResults.findIndex(customer => customer._id === customerId);
                if (index > -1) {
                    searchResults.splice(index, 1);
                }
            }
        }
        results.push(...searchResults);
        this.filteredList = results;
    }

    public add = async () => {
        try {
            const customer = CustomerService.create(this.searchTerm);

            this.selectedCustomer = customer;
            this.customerIsNew = true;
            await this.ok(this.selectedCustomer);
        } catch (error) {
            Logger.error(`Error during add a customer in the select customer dialog.`, error);
        }
    };

    public customerMatcher = (a: Customer, b: Customer) => {
        if (!a || !b) return false;
        return a._id === b._id;
    };

    protected selectCustomer(customer: Customer) {
        this.selectedCustomer = customer;
        this.ok();
    }

    public async getResult() {
        return this.selectedCustomer;
    }

    protected getCustomerBalance(customerId: string) {
        const balance = TransactionService.getCustomerBalance(customerId).amount * -1;
        return NumberToCurrencyValueConverter.numberToCurrency(balance, 'general.balance');
    }
}
