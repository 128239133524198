import type { Customer, Expense, ICustomerBalance, TMatcher, TMatchType, Transaction, TranslationKey } from '@nexdynamic/squeegee-common';
import { TransactionType } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { findBestMatch } from 'string-similarity';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import { TextDialog } from '../Dialogs/TextDialog';
import { TransactionService } from '../Transactions/TransactionService';
import { ConnectedServicesService } from './ConnectedServicesService';
import type { MatchPayment } from './Payments/MatchPayment';

export class MatcherService {
    static findMatchers(matchedItem: MatchPayment): Array<TMatcher> {
        const connectedServiceData = ConnectedServicesService.getProviderSettings(matchedItem.provider);
        if (!connectedServiceData.matchers) return [];

        if (!matchedItem.providerPayment.description) return [];

        const key = matchedItem.providerPayment.description.toLowerCase();

        // Direct match
        const directMatch = connectedServiceData.matchers[key];
        if (directMatch) {
            const matcher = this.transformLegacyMatcher(directMatch, key, matchedItem);
            if (matcher) return [matcher];
        }

        const allMatchers = Object.keys(connectedServiceData.matchers);
        if (!allMatchers.length) return [];

        const bestMatcher = findBestMatch(key, allMatchers);

        const ratings = bestMatcher.ratings
            .filter(r => r.rating > 0.6)
            .sort((a, b) => b.rating - a.rating)
            .slice(0, 2);
        if (!ratings.length) return [];

        return ratings
            .map(x => this.transformLegacyMatcher(connectedServiceData.matchers[x.target] as string, x.target, matchedItem))
            .filter(x => x) as Array<TMatcher>;
    }

    static transformLegacyMatcher(matcher: string, key: string, matchedItem: MatchPayment): TMatcher | undefined {
        const entity = Data.get<Transaction>(matcher);
        let type: TMatchType | undefined;
        let description = '';
        let customerBalance: ICustomerBalance | undefined;

        const isMoneyIn = matchedItem.providerPayment.amount < 0;

        if (isMoneyIn) {
            if (entity?.transactionSubType === 'transfer.in') {
                type = 'Transfer In From';
                description = entity.description || '';
            } else if (entity?.transactionType === TransactionType.Payment) {
                if (entity?.customerId && entity?.amount < 0) {
                    type = 'Customer Payment';
                    const customer = entity.customerId && Data.get<Customer>(entity.customerId);
                    const today = moment().format('YYYY-MM-DD');
                    customerBalance = customer ? TransactionService.getCustomerBalance(customer._id, today) : undefined;
                    description = `${entity.transactionSubType ? ApplicationState.localise(entity.transactionSubType as TranslationKey) : ''
                        } ${customer ? ` from ${customer.name} ()` : ''}`;
                }
            }
        } else {
            if (entity?.transactionSubType === 'transfer.out') {
                type = 'Transfer Out To';
                description = entity.description || '';
            } else if (entity?.transactionType === TransactionType.Expense) {
                type = 'Expense';
                const ent = entity as Expense;
                description = `in category ${ent.category?.description} with description ${ent.description}`;
            } else if (entity?.transactionType === TransactionType.Payment) {
                if (entity?.customerId && entity?.amount > 0) {
                    type = 'Customer Refund';
                    const customer = entity.customerId && Data.get<Customer>(entity.customerId);
                    description = `${entity.transactionSubType ? ApplicationState.localise(entity.transactionSubType as TranslationKey) : ''
                        } ${customer ? ` from ${customer.name}` : ''}`;
                }
            }
        }
        if (!type) return undefined;
        const matcherResult: TMatcher = {
            entityId: matcher,
            type,
            description,
            customerBalance,
            matcher: key,
        };
        return matcherResult;
    }

    static async promptForMatcher(matcher: string) {
        const prompt = new TextDialog(
            'matcher.create-matcher',
            `Use this as a template for future transactions that have a description similar to '${matcher}'?` as TranslationKey,
            matcher,
            'Match On' as TranslationKey
        );
        const value = await prompt.show();
        if (prompt.cancelled) return false;
        return value;
    }

    static async removeMatcher(provider: string, matcher: string) {
        const settings = ConnectedServicesService.getProviderSettings(provider);
        delete settings.matchers[matcher];
        await ConnectedServicesService.saveProviderConfig(provider, settings);
    }

    static extractIdenticalBits(string1: string, string2: string) {
        return (string1.match(new RegExp('[' + string2 + ']', 'g')) || []).join('').trim();
    }

    static extractMatcherFromProviderPayment(providerPayment: Transaction) {
        return providerPayment.description?.toLowerCase();
    }

    static async extractAndSaveMatcher(paymentMatch: MatchPayment) {
        const { providerPayment } = paymentMatch;
        if (paymentMatch.provider === 'gocardless') return;
        const matcher = this.extractMatcherFromProviderPayment(providerPayment);
        if (!matcher) return;

        const providerData = await ConnectedServicesService.getProviderSettings(paymentMatch.provider);

        if (!providerData.matchers) providerData.matchers = {};

        if (!providerData.matchers[matcher]) {
            const prompt = await MatcherService.promptForMatcher(matcher);
            if (!prompt) return;
            providerData.matchers[prompt.toLowerCase()] = providerPayment._id;
        }

        await ConnectedServicesService.saveProviderConfig(paymentMatch.provider, providerData);
    }
}
