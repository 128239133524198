import { FeedbackData, FeedbackPromptResult } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../ApplicationState';
import { GlobalFlags } from '../../GlobalFlags';
import { Logger } from '../../Logger';

const data = require('../../../package.json');

export class FeedbackService {
    public static feedbackUrl = () => ApplicationState.getSupport('Squeegee App Feedback', false);
    public static async saveFeedbackOrRatingResult(feedbackPromptResult: FeedbackPromptResult): Promise<void> {
        const feedbackData = ApplicationState.account.feedbackData ? ApplicationState.account.feedbackData : new FeedbackData();
        feedbackData.feedbackPromptHistory.push(feedbackPromptResult);
        if (feedbackPromptResult.promptResult === 'gave-rating') {
            feedbackData.hasRated = true;
            feedbackData.lastRatedTimestamp = feedbackPromptResult.timestamp;
        }
        ApplicationState.account.feedbackData = feedbackData;
        ApplicationState.save();
    }

    public static async sendFeedback() {
        return ApplicationState.getSupport('Squeegee App Feedback', false, '', 'about.feedback-link-text');
    }

    public static async rate() {
        try {
            const promptResult = new FeedbackPromptResult(new Date().getTime().toString(), 'gave-rating', `${data.version}`);
            const launchReview = (<any>window).LaunchReview;
            if (launchReview) {
                launchReview.launch(GlobalFlags.isAppleMobileDevice ? '1223407652' : 'com.squeegee');
            }
            FeedbackService.saveFeedbackOrRatingResult(promptResult);
        } catch (error) {
            Logger.error('Error during complete rate()', error);
        }
    }
}
