import type { InvoiceTransaction, PaymentTransaction, TransactionProvider, TranslationKey } from '@nexdynamic/squeegee-common';
import { TransactionType, wait } from '@nexdynamic/squeegee-common';
import { ConnectedServicesService } from '../../ConnectedServices/ConnectedServicesService';
import { Data } from '../../Data/Data';
import { Prompt } from '../../Dialogs/Prompt';
import { LoaderEvent } from '../../Events/LoaderEvent';

export class TransactionsManualSyncService {
    static async syncUnsyncedInvoices(provider: TransactionProvider, cutOff?: string) {
        const unsyncedInvoices = await this.getUnsyncedInvoices(provider, cutOff);
        if (!unsyncedInvoices.length) {
            await new Prompt(
                'No unsynced invoices' as TranslationKey,
                `You have no invoices that are not synced to Xero` as TranslationKey,
                { cancelLabel: '' }
            ).show();
            return;
        }

        const promptToContinue = await new Prompt(
            'prompts.confirm-title',
            `You have ${unsyncedInvoices.length} invoices that are not connected to a Xero invoice. Confirm you would like to create these invoices in Xero?` as TranslationKey
        ).show();

        if (!promptToContinue) return;
        new LoaderEvent(true, true, 'loader.syncing-unsynced-invoices', true, { invoices: unsyncedInvoices.length.toString() });

        await this.syncInvoices(provider, unsyncedInvoices);

        // for (const unsyncedInvoice of unsyncedInvoices) {
        //     new LoaderEvent(
        //         true,
        //         true,
        //         ('Syncing ' + counter + ' of ' + unsyncedInvoices.length + ' invoice data to xero') as TranslationKey,
        //         true
        //     );
        //     await this.syncInvoice(provider, unsyncedInvoice);
        //     await wait(1000);
        //     counter++;
        // }
    }

    static async syncUnsyncedPayments(provider: TransactionProvider, cutOff?: string) {
        const unsyncedPayments = await this.getUnsyncedPayments(provider, cutOff);
        if (!unsyncedPayments.length) {
            await new Prompt(
                'No unsynced payments' as TranslationKey,
                `You have no payments that are not synced to Xero` as TranslationKey,
                { cancelLabel: '' }
            ).show();
            return;
        }
        const promptToContinue = await new Prompt(
            'prompts.confirm-title',
            `You have ${unsyncedPayments.length} payments that are not connected to a xero payment. Confirm you would like to create these payments in xero?` as TranslationKey
        ).show();

        if (!promptToContinue) return;
        new LoaderEvent(true, true, 'loader.syncing-unsynced-payments', true, { payments: unsyncedPayments.length.toString() });
        let counter = 1;
        for (const unsyncedPayment of unsyncedPayments) {
            new LoaderEvent(
                true,
                true,
                ('Syncing ' + counter + ' of ' + unsyncedPayments.length + ' payment data to xero') as TranslationKey,
                true
            );
            await this.syncPayment(provider, unsyncedPayment);
            await wait(1000);
            counter++;
        }
    }
    private static async getUnsyncedInvoices(provider: TransactionProvider, cutOff?: string) {
        return Data.all<InvoiceTransaction>(
            'transactions',
            x =>
                !x.voidedId &&
                x.transactionType === TransactionType.Invoice &&
                (!cutOff || x.date >= cutOff) &&
                (!x.externalIds || !x.externalIds[provider])
        );
    }

    private static async getUnsyncedPayments(provider: TransactionProvider, cutOff?: string) {
        return Data.all<PaymentTransaction>(
            'transactions',
            x =>
                !x.voidedId &&
                (x.transactionType === TransactionType.Payment || x.transactionType === TransactionType.AutomaticPayment) &&
                (!cutOff || x.date >= cutOff) &&
                (!x.externalIds || !x.externalIds[provider])
        );
    }

    private static async syncPayment(provider: TransactionProvider, payment: PaymentTransaction) {
        await ConnectedServicesService.createConnectedEntity(provider, 'payments', payment._id);
    }

    private static async syncInvoices(provider: TransactionProvider, invoices: readonly InvoiceTransaction[]) {
        await ConnectedServicesService.syncResourceEntities(
            provider,
            'invoices',
            invoices.map(x => x._id)
        );
    }
}
