import type { JobOccurrenceStatus } from '@nexdynamic/squeegee-common';
import type { ScheduleItem } from '../Schedule/Components/ScheduleItem';

export class ScheduleItemRepeatValueConverter {
    toView(array: Array<ScheduleItem>, status?: JobOccurrenceStatus) {
        return array.filter((item: ScheduleItem) => {
            let include = true;
            if (status !== undefined) include = item.status === status;
            return include;
        });
    }
}
