import type { TableData, TranslationKey } from '@nexdynamic/squeegee-common';
import { bindable } from 'aurelia-framework';
import { ApplicationState } from '../ApplicationState';
import type { IFabAction } from '../Components/Fabs/IFabAction';
import { CustomDialog } from './CustomDialog';

export class ReportDialog extends CustomDialog<string> {
    @bindable() updateFab = (fabActions: Array<IFabAction>) => {
        this.fabActions = fabActions;
    };

    constructor(
        protected readonly reportTitle: TranslationKey,
        protected readonly report: Array<TableData>,
        protected readonly sortBy: string,
        protected readonly sortAscending: boolean
    ) {
        super('reportDialog', './ReportDialog.html', ApplicationState.localise(reportTitle), {
            okLabel: '',
            cancelLabel: '',
            coverViewport: true,
            disableScroll: true,
        });
    }

    public delegateOk = () => this.ok();
}
