import { bindable, computedFrom } from 'aurelia-framework';
import * as Chartist from 'chartist';

export class DonutCustomElement {
    @bindable() series: Array<number> | Array<Chartist.IChartistSeriesData>;
    @bindable() labels: Array<number>;
    @bindable() title: string;
    @bindable() showLabels = false;
    @bindable() donutWidth = 18;
    @bindable() showTotal = true;

    public chartRef: HTMLDivElement;
    public chart?: Chartist.IChartistPieChart;

    @computedFrom('series')
    public get total() {
        if (this.series) {
            let total = 0;
            for (let i = 0; i < this.series.length; i++) {
                const data = this.series[i];
                if (typeof data === 'object' && data.value !== undefined) {
                    total += data.value;
                } else {
                    total += <number>data;
                }
            }
            return total;
        } else return 0;
    }

    public attached() {
        this.loadChart();
    }

    public seriesChanged(newValue: Array<number> | Array<Chartist.IChartistSeriesData> | undefined) {
        if (this.chart === undefined) return;
        else if (newValue !== undefined) {
            this.chart.update({ series: newValue });
        }
    }

    private loadChart() {
        this.chart = new Chartist.Pie(
            this.chartRef,
            { series: this.series },
            {
                donut: true,
                showLabel: this.showLabels,
                donutWidth: this.donutWidth,
            }
        );

        this.animateChart();
    }

    protected detached() {
        this.chart && this.chart.detach();
    }

    private animateChart() {
        if (!this.chart) return;
        this.chart.on('draw', (data: any) => {
            if (data.type === 'slice') {
                const pathLength = data.element._node.getTotalLength();
                data.element.attr({
                    'stroke-dasharray': pathLength + 'px ' + pathLength + 'px',
                });
                let animationDefinition: Chartist.IChartistAnimations;

                animationDefinition = {
                    'stroke-dashoffset': {
                        id: 'anim' + data.index,
                        dur: 150,
                        from: -pathLength + 'px',
                        to: '0px',
                        easing: Chartist.Svg.Easing.easeOutQuint,
                        fill: 'freeze',
                    },
                };

                if (data.index !== 0) {
                    animationDefinition['stroke-dashoffset'].begin = 'anim' + (data.index - 1) + '.end';
                }
                data.element.attr({ 'stroke-dashoffset': -pathLength + 'px' });
                data.element.animate(animationDefinition, false);
            }
        });
    }
}
