// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  width: 100%;
  height: 100%;
  margin: auto;
  max-width: 1200px;
  padding: 16px;
}

@media (max-width: 780px) {
  .container {
    padding: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Layout/container.scss"],"names":[],"mappings":"AACA;EACI,WAAA;EACA,YAAA;EACA,YAAA;EACA,iBALgB;EAMhB,aAAA;AAAJ;;AAGA;EACI;IACI,UAAA;EAAN;AACF","sourcesContent":["$desktop-max-width: 1200px;\n.container {\n    width: 100%;\n    height: 100%;\n    margin: auto;\n    max-width: $desktop-max-width;\n    padding: 16px;\n}\n\n@media (max-width: 780px) {\n    .container {\n        padding: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
