import type { PaymentAccount } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom } from 'aurelia-framework';
import { AccountsLedgerService } from '../../ChartOfAccounts/AccountsLedgerService';

export class PaymentAccountAvatarCustomElement {
    @bindable() paymentAccount?: PaymentAccount;

    @computedFrom('paymentAccount')
    get avatar(): { colour: string; text: string; image?: string } | void {
        if (!this.paymentAccount) return;
        return AccountsLedgerService.getAvatar(this.paymentAccount);
    }
}
