// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.multi-input-dialog .input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background-color: #f9f9f9;
  transition: box-shadow 0.3s ease;
}
.multi-input-dialog .label {
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 0.5rem;
}
.multi-input-dialog .input-field {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
}`, "",{"version":3,"sources":["webpack://./src/Dialogs/MultipleInputDialog.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,yBAAA;EACA,kBAAA;EACA,yBAAA;EACA,gCAAA;AAAR;AAEI;EACI,iBAAA;EACA,WAAA;EACA,qBAAA;AAAR;AAEI;EACI,eAAA;EACA,eAAA;EACA,sBAAA;EACA,kBAAA;EACA,aAAA;EACA,kCAAA;AAAR","sourcesContent":[".multi-input-dialog {\n    .input-container {\n        display: flex;\n        flex-direction: column;\n        margin-bottom: 1rem;\n        padding: 1rem;\n        border: 1px solid #e0e0e0;\n        border-radius: 6px;\n        background-color: #f9f9f9;\n        transition: box-shadow 0.3s ease;\n    }\n    .label {\n        font-size: 0.9rem;\n        color: #333;\n        margin-bottom: 0.5rem;\n    }\n    .input-field {\n        padding: 0.5rem;\n        font-size: 1rem;\n        border: 1px solid #ccc;\n        border-radius: 4px;\n        outline: none;\n        transition: border-color 0.3s ease;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
