import { bindable, bindingMode, computedFrom } from 'aurelia-framework';
import { Logger } from '../../Logger';

export class ListItem {
    @bindable({ defaultBindingMode: bindingMode.oneTime }) bordered?: boolean;
    @bindable({ defaultBindingMode: bindingMode.oneTime }) color?: 'primary' | '';
    @bindable({ defaultBindingMode: bindingMode.oneTime }) multiline?: boolean;
    @bindable({ defaultBindingMode: bindingMode.oneTime }) size?: 'large' | '';
    @bindable({ defaultBindingMode: bindingMode.oneTime }) avatar?: boolean;
    @bindable({ defaultBindingMode: bindingMode.oneTime }) masked?: boolean;
    @bindable() href?: string;
    @bindable() hrefTarget?: string;

    @bindable() showAvatar?: boolean;
    @bindable() showAction?: boolean;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) selected?: boolean;

    selectedChanged(selected: boolean) {
        if (selected === true && this.onSelect) this.onSelect();
        if (selected === false && this.onDeselect) this.onDeselect();
    }

    @bindable({ defaultBindingMode: bindingMode.oneTime }) onClick?: () => void;
    @bindable({ defaultBindingMode: bindingMode.oneTime }) onSelect?: () => void;
    @bindable({ defaultBindingMode: bindingMode.oneTime }) onDeselect?: () => void;

    attached() {
        if (this.onSelect && this.onClick) {
            Logger.error('ListItem Invalid props passed: You cant defind a on-click and on-select property');
        }
    }

    clicked() {
        if (this.onClick && this.href) {
            Logger.warn('You have set a on-click event and a href this will not work');
        }
        if (this.onClick) this.onClick();
        if (this.onSelect) this.selected = !this.selected;
    }

    @computedFrom('onSelect', 'onDeselect')
    get selectable() {
        return !!(this.onSelect || this.onDeselect);
    }

    @computedFrom('onClick', 'onSelect')
    get clickable() {
        return !!(this.onClick || this.onSelect);
    }
}
