import { ApplicationState } from '../ApplicationState';
import { CustomDialog } from '../Dialogs/CustomDialog';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import type { Subscription } from '../Events/DataRefreshedEvent';
import { DataRefreshedEvent } from '../Events/DataRefreshedEvent';
import { Api } from '../Server/Api';
import { ConnectedServiceConfig } from './ConnectedServiceConfig';
import type { IConnectedServiceInfo } from './IConnectedServiceInfo';

export class ConnectedServicesDialog extends CustomDialog<void> {
    protected hasAdvancedOrAbove = ApplicationState.hasAdvancedOrAbove;
    protected isConnected: boolean = Api.isConnected;
    protected providers: Array<IConnectedServiceInfo>;
    protected _dataChangedSub: Subscription;

    async activate(params: any) {
        await this.init();

        if (params.providerId) {
            const provider = this.providers.find(x => x.id === params.providerId);
            if (!provider) return;
            const providerDialog = new ConnectedServiceConfig(provider);
            providerDialog.show(DialogAnimation.SLIDE_IN);
        }
    }

    constructor() {
        super('connectedServicesDialog', '../ConnectedServices/ConnectedServicesDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'payment-providers-dialog',
            isSecondaryView: true,
        });
    }

    public async init() {
        this._dataChangedSub = DataRefreshedEvent.subscribe(async (x: DataRefreshedEvent) => {
            if (x.hasAnyType('settings')) {
                const response = await Api.get<Array<IConnectedServiceInfo>>(Api.apiEndpoint, '/api/oauth');
                this.updateProviders(response?.data);
            }
        });
        const response = await Api.get<Array<IConnectedServiceInfo>>(Api.apiEndpoint, '/api/oauth');
        this.updateProviders(response?.data);
    }

    private updateProviders(providerData?: Array<IConnectedServiceInfo>) {
        const devOnly = { square: true } as Record<string, boolean | undefined>;
        this.providers = (providerData || []).filter(x => this.devMode || !devOnly[x.id]);
    }

    public dispose() {
        this._dataChangedSub && this._dataChangedSub.dispose();
        super.dispose();
    }

    async openProvider(provider: IConnectedServiceInfo) {
        const providerDialog = new ConnectedServiceConfig(provider);
        await providerDialog.show(DialogAnimation.SLIDE_IN);
    }
}
