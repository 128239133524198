import { CleanerPlannerCommonEntity } from './CleanerPlannerCommonEntity';
export class CleanerPlannerJob extends CleanerPlannerCommonEntity {
    Reference: string;
    RoundId: string;
    CustomerId: string;
    ContactId: string;
    ServiceId: string;
    ScheduleInterval: string;
    /** 1:days 2:weeks 3:months */
    ScheduleFrequencyId: string;
    /** 127:ad hoc */
    ScheduleDayId: string;
    /** 15:ad hoc */
    ScheduleWeekId: string;
    /** DD/MM/YYYY HH:mm:ss */
    Due: string;
    /** DD/MM/YYYY HH:mm:ss */
    WorksheetDefaultNextDue: string;
    WorksheetIndex: string;
    /** DD/MM/YYYY HH:mm:ss */
    NextDue: string;
    /** DD/MM/YYYY HH:mm:ss */
    LastDone: string;
    /** minutes */
    Duration: string;
    /** "True" or "False" */
    ScheduleEnabled: string;
    Price: string;
    /** If there is one of these, this is supposed to be two jobs. */
    AltPrice: string;
    Balance: string;
    TotalPrice: string;
    /** 0:quote 1:active 2:suspended 3:cancelled 4:completed 5:transferred */
    StatusId: string;
    /** 1: call first 0:No need "blank": no need*/
    ReminderModeId: string;
}
