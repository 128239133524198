import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';

export class NumberToCurrencyValueConverter {
    toView(
        number: number,
        label: TranslationKey,
        currencySymbol?: string,
        showDecimal?: boolean,
        coloriseLabel?: boolean,
        invertNegative?: boolean
    ) {
        return NumberToCurrencyValueConverter.numberToCurrency(number, label, currencySymbol, showDecimal, coloriseLabel, invertNegative);
    }

    public static numberToCurrency(
        number: number | undefined | null,
        label: TranslationKey,
        currencySymbol?: string,
        showDecimal?: boolean,
        coloriseLabel?: boolean,
        invertNegative?: boolean
    ) {
        if (!currencySymbol) currencySymbol = ApplicationState.currencySymbol();
        if (!showDecimal) showDecimal = true;

        if (number === undefined || number === null)
            return ApplicationState.stateFlags.devMode ? '<span style="color: #da384d">NO PRICE</span>' : '';

        const parts = parseFloat(number.toString()).toFixed(2).split('.');
        let negative = number.toString().startsWith('-');
        const wholeNumber = negative ? parts[0].substring(1, parts[0].length) : parts[0];
        if (invertNegative) negative = !negative;
        const decimalPart = showDecimal ? '.<span class="decimal">' + parts[1] + '</span>' : '';

        const tag = coloriseLabel && number < 0 ? 'credit' : coloriseLabel && number > 0 ? 'owing' : null;
        const numberFormatted = negative
            ? `<span class="symbol">(</span>${wholeNumber}${decimalPart}<span class="symbol">)</span>`
            : `${wholeNumber}${decimalPart}`;
        return `<div class="financial">
            <div>
            <span class="symbol">${currencySymbol}</span>${numberFormatted}
            </div>
            <div if.bind="label && label.length > 0" class="label ${coloriseLabel && tag ? `financial-tag financial-tag--${tag}` : ''
            }">${ApplicationState.localise(label)}</div>
        </div>`;
    }
}
