import type { PaymentAccount } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom } from 'aurelia-framework';
import { PaymentAccountDialog } from './PaymentAccountDialog';

export class PaymentAccountCustomElement {
    @bindable() paymentAccount: PaymentAccount;
    @bindable() balance?: number;
    view() {
        new PaymentAccountDialog(this.paymentAccount, this.balance).show();
    }

    @computedFrom('paymentAccount.name')
    get title() {
        if (!this.paymentAccount) return;
        return this.paymentAccount.name;
    }

    @computedFrom('paymentAccount.provider', 'paymentAccount.sortcode', 'paymentAccount.accountNumber')
    get description() {
        if (!this.paymentAccount) return;
        return `${this.paymentAccount.provider || ''} ${this.paymentAccount.sortcode || ''} ${
            this.paymentAccount.accountNumber || ''
        }`.trim();
    }
}
