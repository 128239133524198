import type { Job, JobOccurrence, Service } from '@nexdynamic/squeegee-common';
import { TagType } from '@nexdynamic/squeegee-common';
import { bindable } from 'aurelia-framework';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { Logger } from '../../Logger';
import { SelectTagsDialog } from '../../Tags/Components/SelectTagsDialog';
import { Utilities } from '../../Utilities';

export class JobPricingDialog extends CustomDialog<void> {
    @bindable protected failedValidation: boolean;
    @bindable protected formIsDirty: boolean;
    @bindable protected priceServicesEnabled: boolean;

    private originallyPricedByService: boolean;
    private originalJob: JobOccurrence | Job;

    protected taggedServices: boolean;

    constructor(protected job: JobOccurrence | Job) {
        super('job-pricing-' + job._id, '../Jobs/Components/JobPricing.html', '', {
            okLabel: '',
            cancelLabel: '',
            isSecondaryView: true,
            destructive: true,
        });

        this.taggedServices = job.resourceType === 'joboccurrences' && job.services.some(s => s.requiresTagging);

        this.priceServicesEnabled =
            this.taggedServices || (
                job.price !== 0 &&
                job.services
                    .map(x => (Number((x as Service).price) || 0) * (Number((x as Service).quantity) || 1))
                    .reduce((x: number, y) => x + (y || 0), 0) === job.price);

        this.originalJob = Utilities.copyObject<Job | JobOccurrence>(job);
        this.originallyPricedByService = this.priceServicesEnabled;
    }
    protected enableServicePricing() {
        if (this.job.services.length === 1 && !this.job.services[0].price) {
            this.job.services[0].quantity = 1;
            this.job.services[0].price = this.job.price;
        }
        this.priceServicesEnabled = true;
    }

    protected disableServicePricing() {
        this.priceServicesEnabled = false;
        //If this job was not originally Priced By Service then if we switch back apply the job price one more
        if (!this.originallyPricedByService) {
            this.job.price = this.originalJob.price;
        }
    }

    protected async addServices(event: Event) {
        const dialog = new SelectTagsDialog(this.job.services.slice(), TagType.SERVICE, 0);
        const services = await dialog.show(DialogAnimation.SLIDE_UP);
        if (!dialog.cancelled) {
            for (const service of services) {
                if (this.job.services.some(s => s._id === service._id)) continue;
                this.job.services.push(service);
            }

            this.job.services = this.job.services.filter(s => services.some(ss => ss._id === s._id));
        }
        if (event) event.stopPropagation();
    }

    protected validate() {
        const errors = Utilities.validateAndNotifyUI(!!this.job.services && this.job.services.length !== 0, 'validation.service-required');
        return errors;
    }

    protected save() {
        if (this.validate().length) return;
        if (!this.priceServicesEnabled) {
            for (const service of this.job.services) {
                service.quantity = 1;
                service.price = undefined;
            }
        }
        this.ok();
    }

    protected updated = async () => {
        Logger.info('JobPricingDialog: updated');
        this.taggedServices = this.job.resourceType === 'joboccurrences' && this.job.services.some(s => s.requiresTagging);
    }
}
