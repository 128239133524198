import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { AlphanumericCharColourDictionary } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom } from 'aurelia-framework';
import './ui-tag.scss';
const colours = new AlphanumericCharColourDictionary();

export class UiTag {
    private static defaultColor = '#FFF';
    @bindable() color?: string;
    @bindable() status?: string;
    @bindable() text?: string;
    @bindable() description?: TranslationKey;

    @computedFrom('color', 'text', 'status')
    protected get css(): Record<string, string | undefined> | void {
        if (this.status) return;
        const css = {
            'background-color': this.color,
        };
        if (this.text && !this.color) {
            css['background-color'] = colours[this.text.substring(0, 1).toUpperCase()];
        } else {
            css['background-color'] = this.color || UiTag.defaultColor;
        }

        return css;
    }
}
