import type { Service } from '@nexdynamic/squeegee-common';
import { bindable, customElement, inject } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { GlobalFlags } from '../../GlobalFlags';
import { getNumber } from '../../Settings/getNumber';
import { animate } from '../../Utilities';
import { ServiceList } from './ServiceList';
@customElement('service-item')
@inject(Element, ServiceList)
export class ServiceItem {
    @bindable service: Service;
    @bindable updated?: () => void;

    private _inputElement: HTMLInputElement;
    constructor(private _element: Element, protected serviceList: ServiceList) { }

    attached() {
        this._inputElement = <HTMLInputElement>this._element.querySelector('input');

        this.service.quantity = Number(this.service.quantity || 1);
        if (isNaN(this.service.quantity)) this.service.quantity = 0;

        this.service.price = Number(this.service.price || 0);
        if (isNaN(this.service.price)) this.service.price = 0;
        const price = this.service.price;

        if (!GlobalFlags.isMobile) {
            this._inputElement.addEventListener('keypress', this._keyHandler);
            this._inputElement.addEventListener('blur', this._blurHandler);
        }

        if (GlobalFlags.isTouchEnabled) this._inputElement.addEventListener('touchstart', this._touchStartHandler);

        if (GlobalFlags.isMobile) this._inputElement.readOnly = true;

        animate().then(() => {
            this._inputElement.value = price.toFixed(2);
            this._inputElement.dispatchEvent(new Event('change'));

            this.updateTotal();
            this._inputElement?.parentElement?.classList.add('is-dirty');
        });
    }

    servicePriceChanged() {
        this.updateTotal();
    }

    protected async addServiceQuantity(event: MouseEvent, service: Service) {
        event.stopPropagation();
        service.quantity = (service.quantity || 0) + 1;
        this.updateTotal();
        this.updated?.();
    }

    protected async subtractServiceQuantity(event: MouseEvent, service: Service) {
        event.stopPropagation();
        if (service.quantity !== undefined) {
            service.quantity -= 1;

            if (service.quantity === 0) this.removeServiceFromJob(service);

            this.updateTotal();
            this.updated?.();
        }
    }

    protected async editServiceQuantity(event: MouseEvent, service: Service) {
        event.stopPropagation();

        const quantity = await getNumber({ title: 'general.quantity', value: service.quantity, integer: true });
        if (quantity === undefined) return;

        service.quantity = quantity;
        this.updateTotal();
        this.updated?.();
    }

    protected removeServiceFromJob(service: Service) {
        if (!this.serviceList.job.services) return;
        this.serviceList.job.services.splice(this.serviceList.job.services.indexOf(service), 1);
        this.updated?.();
    }

    protected updateTotal() {
        let total = 0;
        for (const service of this.serviceList.job.services || []) {
            total += (service.price || 0) * (service.quantity || 1);
        }
        this.serviceList.job.price = total;
    }

    detached() {
        if (!GlobalFlags.isMobile) {
            this._inputElement.removeEventListener('keypress', this._keyHandler);
            this._inputElement.removeEventListener('blur', this._blurHandler);
        }

        if (GlobalFlags.isTouchEnabled) this._inputElement.removeEventListener('touchstart', this._touchStartHandler);
    }

    private _touchStartHandler = (event: TouchEvent) => {
        event.preventDefault();
        event.stopPropagation();
        let currentPrice = Number(this._inputElement.value);
        if (isNaN(currentPrice)) currentPrice = 0;
        this.getPrice(currentPrice);
    };

    private _blurHandler = () => {
        const element = this._inputElement;
        element.value = Number(element.value).toFixed(2);
        element.dispatchEvent(new Event('change'));
    };

    private _keyHandler = (keyEvent: KeyboardEvent) => {
        let key = keyEvent.key;
        if (keyEvent.key && keyEvent.key.toLowerCase().indexOf('numpad ') === 0) key = keyEvent.key.substring(7);
        const start = this._inputElement.selectionStart || 0;
        if (
            key &&
            key.length === 1 &&
            !/^\d*?\.?\d?\d?$/.test(
                this._inputElement.value.substring(0, start) +
                key +
                this._inputElement.value.substring(start, this._inputElement.value.length)
            )
        ) {
            keyEvent.preventDefault();
        }
    };

    public async getPrice(currentPrice: number) {
        const price = await getNumber({ title: 'general.price', value: currentPrice, prefix: ApplicationState.currencySymbol() });
        if (price === undefined) return;

        this._inputElement.value = price.toFixed(2);
        this._inputElement.dispatchEvent(new Event('change'));
        this.updateTotal();
    }
}
