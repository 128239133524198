import type { Customer, Job, JobOccurrence } from '@nexdynamic/squeegee-common';
import { getImportantHashtags, getStandardHashtags } from '@nexdynamic/squeegee-common';
import type { HashTagMap } from './HashTagMap';

export const getHashtags = (itemOrItems: Array<Customer> | Array<Job> | Array<JobOccurrence> | Array<string> | string): HashTagMap => {
    const hashtagMap = {
        important: new Map<string, number>(),
        standard: new Map<string, number>(),
    };

    for (const item of typeof itemOrItems === 'string' ? [itemOrItems] : itemOrItems) {
        const text =
            typeof item === 'string'
                ? item
                : item.resourceType === 'customers'
                ? (item as Customer).notes
                : (item as Job | JobOccurrence).description;

        if (!text) continue;

        const importantHashtags = getImportantHashtags(text);
        for (const hashtag of importantHashtags) {
            const count = hashtagMap.important.get(hashtag) || 0;
            hashtagMap.important.set(hashtag, count + 1);
        }

        const standardHashtags = getStandardHashtags(text);
        for (const hashtag of standardHashtags) {
            const count = hashtagMap.standard.get(hashtag) || 0;
            hashtagMap.standard.set(hashtag, count + 1);
        }
    }

    return hashtagMap;
};
