import { CapacityMeasurementType, JobOccurrenceStatus } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import type { ScheduleByGroup } from '../../Schedule/Components/ScheduleByGroup';
import type { IChartClickData } from './IChartClickData';

type GroupType = 'overdue' | 'due' | 'not due' | 'done' | 'skipped';

export class ChartData implements c3.Data {
    public readonly isChartData = true;
    public x = 'x';
    columns: Array<Array<string | number>> = [['x']];
    colors: { [columnName: string]: string } = {};
    type: 'bar' | 'pie' | 'line' = 'bar';
    groups: Array<Array<string>> = [];
    onclick?: (data: IChartClickData) => void;
    onselected?: (data: IChartClickData) => void;
    selection?: {
        enabled?: boolean;
        grouped?: boolean;
        multiple?: boolean;
        draggable?: boolean;
        isselectable?: (d?: any) => boolean;
    };
    constructor(
        public chartLabels: Array<string>,
        public columnIcons?: Array<string>,
        public columnSummaries?: Array<string>,
        public highlightColumn?: number,
        public columnHighs?: Array<number | undefined>,
        public columnLows?: Array<number | undefined>
    ) {
        for (let i = 0; i < chartLabels.length; i++) {
            const label = chartLabels[i];
            this.columns[0].push(label);
        }
    }

    public addGroup(name: string, values: Array<number>, colour?: string) {
        if (!this.groups.length) this.groups.push([]);
        this.groups[0].push(name);
        const column: Array<string | number> = [];
        column.push(name);
        for (let i = 0; i < values.length; i++) {
            const value = values[i];
            column.push(value);
        }
        this.columns.push(column);
        if (colour) this.colors[name] = colour;
    }

    private static fromScheduleByGroupTotals(
        scheduleByGroup: ScheduleByGroup,
        groupLabelFormatter: { (text: string): string } = (text: string) => {
            return text.replace(' ', '<br>');
        }
    ) {
        const groupKeys = Object.keys(scheduleByGroup);
        const chartData = new ChartData(groupKeys.map(key => groupLabelFormatter(key)));
        chartData.addGroup(
            'total',
            groupKeys.map(key => Object.keys(scheduleByGroup[key]).length)
        );
        return chartData;
    }

    public static fromScheduleByDayTotals(scheduleByDay: ScheduleByGroup) {
        return ChartData.fromScheduleByGroupTotals(scheduleByDay, key => moment(key).format('D ddd'));
    }

    protected static getWeatherIconUrl(dayIso: string) {
        if (ApplicationState.weather) {
            const weather = ApplicationState.weather[dayIso];
            return weather ? 'images/weather/' + weather.icon + '.svg#weather' : '';
        }
        return '';
    }

    protected static getWeatherSummary(dayIso: string) {
        if (ApplicationState.weather) {
            const weather = ApplicationState.weather[dayIso];
            return weather?.summary || '';
        }
        return '';
    }

    protected static getWeatherHigh(dayIso: string) {
        if (ApplicationState.weather) {
            const weather = ApplicationState.weather[dayIso];
            if (weather) return weather.high;
        }
    }

    protected static getWeatherLow(dayIso: string) {
        if (ApplicationState.weather) {
            const weather = ApplicationState.weather[dayIso];
            if (weather) return weather.low;
        }
    }

    private static getPricesForGroup(scheduleByGroup: ScheduleByGroup, todayIso: string, groupType: GroupType): Array<number> {
        const dayKeys = Object.keys(scheduleByGroup);
        const priceTotalsForDays: Array<number> = [];
        for (let dayIndex = 0; dayIndex < dayKeys.length; dayIndex++) {
            let totalPriceForDay = 0;
            const day = scheduleByGroup[dayKeys[dayIndex]];
            const scheduleItemKeys = Object.keys(day);
            for (let i = 0; i < scheduleItemKeys.length; i++) {
                const scheduleItem = day[scheduleItemKeys[i]];
                const plannedDate = scheduleItem.date;
                if (groupType === 'overdue' && scheduleItem.status === JobOccurrenceStatus.NotDone && plannedDate < todayIso) {
                    totalPriceForDay += scheduleItem.occurrence.price || 0;
                } else if (
                    groupType === 'due' &&
                    scheduleItem.status === JobOccurrenceStatus.NotDone &&
                    scheduleItem.date.substring(0, 10) === todayIso
                ) {
                    totalPriceForDay += scheduleItem.occurrence.price || 0;
                } else if (groupType === 'not due' && scheduleItem.status === JobOccurrenceStatus.NotDone && scheduleItem.date > todayIso) {
                    totalPriceForDay += scheduleItem.occurrence.price || 0;
                } else if (groupType === 'done' && scheduleItem.status === JobOccurrenceStatus.Done) {
                    totalPriceForDay += scheduleItem.occurrence.price || 0;
                } else if (groupType === 'skipped' && scheduleItem.status === JobOccurrenceStatus.Skipped) {
                    totalPriceForDay += scheduleItem.occurrence.price || 0;
                }
            }
            priceTotalsForDays.push(totalPriceForDay);
        }
        return priceTotalsForDays;
    }

    private static getJobNumbersForGroup(scheduleByGroup: ScheduleByGroup, todayIso: string, groupType: GroupType): Array<number> {
        const groupKeys = Object.keys(scheduleByGroup);
        const jobNumbers = groupKeys.map(
            key =>
                Object.keys(scheduleByGroup[key]).filter(id => {
                    const item = scheduleByGroup[key][id];
                    const plannedDate = item.date;
                    if (groupType === 'overdue') return item.status === JobOccurrenceStatus.NotDone && plannedDate < todayIso;
                    else if (groupType === 'due')
                        return item.status === JobOccurrenceStatus.NotDone && item.date.substring(0, 10) === todayIso;
                    else if (groupType === 'not due') return item.status === JobOccurrenceStatus.NotDone && item.date > todayIso;
                    else if (groupType === 'done') return item.status === JobOccurrenceStatus.Done;
                    else if (groupType === 'skipped') return item.status === JobOccurrenceStatus.Skipped;
                    // else if (groupType === 'invoiced') return item.status === JobOccurrenceStatus.Invoiced;
                }).length
        );
        return jobNumbers;
    }

    public static fromScheduleByDayAndStatus(scheduleByGroup: ScheduleByGroup) {
        const todayIso = moment().format('YYYY-MM-DD');
        const groupKeys = Object.keys(scheduleByGroup);

        const labels = groupKeys.map(key => moment(key).format('D<br>ddd'));
        const icons = groupKeys.map(key => ChartData.getWeatherIconUrl(key));
        const summaries = groupKeys.map(key => ChartData.getWeatherSummary(key));
        const highs = groupKeys.map(key => ChartData.getWeatherHigh(key));
        const lows = groupKeys.map(key => ChartData.getWeatherLow(key));

        const chartData = new ChartData(labels, icons, summaries, groupKeys.indexOf(todayIso), highs, lows);

        const overdue =
            ApplicationState.capacityMeasurementType === CapacityMeasurementType.NUMBER_OF_JOBS
                ? ChartData.getJobNumbersForGroup(scheduleByGroup, todayIso, 'overdue')
                : ChartData.getPricesForGroup(scheduleByGroup, todayIso, 'overdue');
        if (overdue.length) chartData.addGroup('Overdue', overdue, '#F66578');

        const notDone =
            ApplicationState.capacityMeasurementType === CapacityMeasurementType.NUMBER_OF_JOBS
                ? ChartData.getJobNumbersForGroup(scheduleByGroup, todayIso, 'due')
                : ChartData.getPricesForGroup(scheduleByGroup, todayIso, 'due');
        if (notDone.length) chartData.addGroup('Due', notDone, '#486493');

        const notDoneNotDue =
            ApplicationState.capacityMeasurementType === CapacityMeasurementType.NUMBER_OF_JOBS
                ? ChartData.getJobNumbersForGroup(scheduleByGroup, todayIso, 'not due')
                : ChartData.getPricesForGroup(scheduleByGroup, todayIso, 'not due');
        if (notDoneNotDue.length) chartData.addGroup('Not due', notDoneNotDue, '#486493');

        const done =
            ApplicationState.capacityMeasurementType === CapacityMeasurementType.NUMBER_OF_JOBS
                ? ChartData.getJobNumbersForGroup(scheduleByGroup, todayIso, 'done')
                : ChartData.getPricesForGroup(scheduleByGroup, todayIso, 'done');
        if (done.length) chartData.addGroup('Done', done, '#66B988');

        const skipped =
            ApplicationState.capacityMeasurementType === CapacityMeasurementType.NUMBER_OF_JOBS
                ? ChartData.getJobNumbersForGroup(scheduleByGroup, todayIso, 'skipped')
                : ChartData.getPricesForGroup(scheduleByGroup, todayIso, 'skipped');
        if (skipped.length) chartData.addGroup('Skipped', skipped, '#F8C007');

        // const invoiced = ApplicationState.capacityMeasurementType === CapacityMeasurementType.NUMBER_OF_JOBS
        //     ? ChartData.getJobNumbersForGroup(scheduleByGroup, todayIso, 'invoiced')
        //     : ChartData.getPricesForGroup(scheduleByGroup, todayIso, 'invoiced');
        // if (invoiced.length) chartData.addGroup('Invoiced', invoiced, '#AB47BC');

        return chartData;
    }
}
