// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.distance-marker {
  font-size: 11px;
  color: rgb(0, 0, 0);
  text-align: center;
  line-height: 11px;
  margin-bottom: 2px;
  opacity: 0.7;
}
.distance-marker__value {
  font-weight: 500;
  font-size: smaller;
}

.distance-marker--light {
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/Components/Distance/distance-marker.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;EAKA,YAAA;AAHJ;AADI;EACI,gBAAA;EACA,kBAAA;AAGR;;AAEA;EACI,WAAA;AACJ","sourcesContent":[".distance-marker {\n    font-size: 11px;\n    color: rgba(0, 0, 0);\n    text-align: center;\n    line-height: 11px;\n    margin-bottom: 2px;\n    &__value {\n        font-weight: 500;\n        font-size: smaller;\n    }\n    opacity: 0.7;\n}\n\n.distance-marker--light {\n    color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
