import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Backdrop, Paper, SpeedDial, SpeedDialAction, keyframes, useTheme } from '@mui/material';
import { useState } from 'react';

export type ReactFabAction = {
    icon: JSX.Element;
    name: string;
    color: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | string;
    onClick: () => void;
};

type ExpandableFabProps = {
    actions: Array<ReactFabAction>;
};

const ExpandableFab = ({ actions }: ExpandableFabProps) => {
    const [fabOpen, setFabOpen] = useState(false);
    const handleOpenFab = () => setFabOpen(!fabOpen);

    const showIcon = keyframes`
        from {
            scale: 0;
            opacity: 0;
            transform: rotate(0deg);
        }
        to {
            scale: 1;
            opacity: 1;
            transform: rotate(180deg);
        }
    `;

    const hideIcon = keyframes`
        from {
            scale: 1;
            opacity: 1;
            transform: rotate(0deg);
        }
        to {
            scale: 0;
            opacity: 0;
            transform: rotate(180deg);
        }
    `;
    const theme = useTheme();
    return (
        <>
            <Backdrop component={Paper} open={fabOpen} onClick={handleOpenFab} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.6)' }} />
            <SpeedDial
                ariaLabel='Speed Dial'
                sx={{
                    position: 'absolute',
                    bottom: 16,
                    right: 16,
                    '& .MuiSpeedDial-fab > .MuiSvgIcon-root': {
                        position: 'absolute',
                    },
                }}
                FabProps={{
                    color: 'secondary',
                }}
                icon={
                    <>
                        <MoreVertIcon
                            sx={{
                                animation: fabOpen ? `${hideIcon} 0.4s forwards` : `${showIcon} 0.4s forwards`,
                                opacity: fabOpen ? 0 : 1,
                            }}
                        />
                        <CloseIcon
                            sx={{
                                animation: fabOpen ? `${showIcon} 0.4s forwards` : `${hideIcon} 0.4s forwards`,
                                opacity: fabOpen ? 1 : 0,
                            }}
                        />
                    </>
                }
                color='primary'
                onClick={handleOpenFab}
                open={fabOpen}
            >
                {actions.map(action => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={action.onClick}
                        tooltipOpen
                        sx={{
                            '& .MuiSpeedDialAction-staticTooltipLabel': {
                                width: 'fit-content',
                                backgroundColor: theme.palette.grey[700],
                                color: 'white',
                                borderRadius: 1,
                                fontSize: '12px',
                                maxWidth: 'fit-content',
                                px: '6px',
                                whiteSpace: 'nowrap',
                            },
                            '& .MuiButtonBase-root': {
                                backgroundColor: action.color,
                                color: 'white',
                            },
                        }}
                    />
                ))}
            </SpeedDial>
        </>
    );
};

export default ExpandableFab;
