import type { Expense, PaymentAccount } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../ApplicationState';
import { Data } from '../../Data/Data';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { Prompt } from '../../Dialogs/Prompt';
import type { Subscription } from '../../Events/DataRefreshedEvent';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import type { IMenuBarAction } from '../../Menus/IMenuBarAction';
import { ExpenseService } from '../ExpenseService';
import { ExpenseFormDialog } from './ExpenseFormDialog';

export class ExpenseSummaryDialog extends CustomDialog<void> {
    protected moreActions: Array<IMenuBarAction>;
    private _dataRefreshedSub: Subscription;
    protected expense?: Expense;

    protected paymentAccount?: PaymentAccount;
    constructor(protected expenseId: string) {
        super('edit-' + expenseId, '../Expenses/Components/ExpenseSummaryDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'details-dialog no-nav-shadow',
            isSecondaryView: true,
            destructive: true,
        });
        this.moreActions = [
            {
                tooltip: 'menubar.edit',
                actionType: 'action-edit',
                handler: this._editExpense,
                roles: ['Owner', 'Admin'],
            },
            {
                tooltip: 'menubar.delete',
                actionType: 'action-delete',
                handler: this._deleteExpense,
                roles: ['Owner', 'Admin'],
            },
        ];

        this.load();

        this._dataRefreshedSub = DataRefreshedEvent.subscribe(
            (event: DataRefreshedEvent) => Boolean(event.updatedObjects[this.expenseId]) && this.load()
        );
    }

    protected get menuTitle() {
        if (!this.expense) return;

        return this.expense.description || ApplicationState.localise('general.expense');
    }

    private load() {
        const expense = Data.get<Expense>(this.expenseId);
        if (expense?.paymentAccountId) {
            const paymentAccount = Data.get<PaymentAccount>(expense?.paymentAccountId);
            this.paymentAccount = Object.assign({}, paymentAccount);
        }
        if (!expense) {
            this.cancel();
        } else {
            this.expense = Object.assign({}, expense);
        }
    }

    private _deleteExpense = async () => {
        if (!this.expense) return;
        const dialog = new Prompt('expenses.confirm-delete', 'expenses.confirm-delete-msg', { okLabel: 'general.delete' });

        await dialog.show(DialogAnimation.SLIDE_IN);

        if (!dialog.cancelled) {
            await ExpenseService.removeExpense(this.expense);
            this.ok();
        }
    };

    private _editExpense = async () => {
        if (!this.expense) return;

        const dialog = new ExpenseFormDialog(this.expense);
        await dialog.show();
    };

    async getResult() {
        if (this._dataRefreshedSub) this._dataRefreshedSub.dispose();
    }
}
