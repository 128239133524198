import type { Customer, Job, JobOccurrence, StoredObject } from '@nexdynamic/squeegee-common';
import { updateCustomerTags } from './UpdateResourceTypeTags/updateCustomerTags';
import { updateJobTags } from './UpdateResourceTypeTags/updateJobTags';
import { updateOccurrenceTags } from './UpdateResourceTypeTags/updateOccurrenceTags';

export const updateTags = async (
    initialItem: Customer | Job | JobOccurrence | StoredObject,

    importantSelected: Array<string>,
    standardSelected: Array<string>,
    ignoreImportant: Array<string>,
    ignoreStandard: Array<string>
) => {
    switch (initialItem.resourceType) {
        case 'customers':
            await updateCustomerTags(initialItem as Customer, standardSelected, importantSelected, ignoreImportant, ignoreStandard);
            break;
        case 'jobs':
        case '':
             //WTF Older jobs can have an empty resourceType
            await updateJobTags(initialItem as Job, standardSelected, importantSelected, ignoreImportant, ignoreStandard);
            break;
        case 'joboccurrences':
            await updateOccurrenceTags(initialItem as JobOccurrence, standardSelected, importantSelected, ignoreImportant, ignoreStandard);
            break;
        default:
            break;
    }
};
