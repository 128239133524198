import type { Account, ICustomerSummary } from '@nexdynamic/squeegee-common';
import { ProductLevel } from '@nexdynamic/squeegee-common';
import type { Subscription } from 'aurelia-event-aggregator';
import { computedFrom, inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { AccountActions } from '../Account/AccountActions';
import { AccountBillingService } from '../Account/AccountBillingService';
import { SubscriptionFormFields } from '../Account/Components/subscription-form';
import { ApplicationState } from '../ApplicationState';
import { AddressLookupDialog } from '../Dialogs/AddressLookupDialog';
import { GlobalFlags } from '../GlobalFlags';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { Api } from '../Server/Api';
import { Squeegee } from '../Squeegee';
import { Utilities } from '../Utilities';
import type { DeveloperLaunchOptionsDialog } from './DeveloperLaunchOptionsDialog';

@inject(Router)
export class GettingStarted {
    public stateFlags = ApplicationState.stateFlags;
    protected isMobile = GlobalFlags.isMobile;
    protected isHttp = GlobalFlags.isHttp;
    protected step = 1;
    protected notifySub: Subscription;
    protected error = '';
    protected fields?: SubscriptionFormFields;
    subAccount?: ICustomerSummary;
    account: Account;
    protected betaBanner = window.sq.betaVersion;
    protected nameInput: HTMLFormElement;
    protected automaticallyReferred: boolean;
    protected supportEmail = ApplicationState.localise('general.support-email');
    protected canRegister = ApplicationState.canShowAccountManagement;

    @computedFrom('_api.isConnected')
    protected get connected() {
        return window.navigator.onLine;
    }

    @computedFrom('ApplicationState.version', 'Api.connectionData')
    protected get connectionData() {
        return `Squeegee v${ApplicationState.version} ${Api.connectionData}`;
    }
    constructor(public router: Router) {
        this.account = ApplicationState.account;
    }
    protected getSupport() {
        ApplicationState.getSupport('Getting Started Help', false);
    }
    public async attached() {
        if (ApplicationState.hasMinimumSubscription(ProductLevel.Core)) this.router.navigateToRoute('customers');

        document.body.style.backgroundColor = 'var(--themed-main-dark)';
        this.subAccount = await AccountBillingService.getAccount();

        this.hideOverflow(false);

        this.notifySub = NotifyUserMessage.subscribe((evt: NotifyUserMessage) => {
            this.error = evt.text;
        });

        this.fields = new SubscriptionFormFields();

        this.fields.requirePayment = false;
        this.fields.users = 1;

        if (!this.canRegister) {
            const plan = (await AccountBillingService.getSelectablePlans())?.find(p => p.name.toLowerCase() === 'advanced');
            if (plan) {
                const planId = plan?.periods.find(p => p.interval === 'month')?.planId;
                if (planId) {
                    this.fields.planId = planId;
                    return await this.initialSubscribe(this.fields);
                }
            }
        }
    }
    initialSubscribe = async (fields: SubscriptionFormFields) => {
        if (fields.planId) {
            const { success } = await AccountActions.subscribe(
                fields.planId,
                fields.users,
                Boolean(fields.previousPlanId),
                fields.requirePayment,
                false
            );

            await Squeegee.postCheckAndConfirmDetails();

            if (success) {
                await this.router.navigate('/customers');
            }
        }
    };
    public detached() {
        this.hideOverflow();

        this.notifySub && this.notifySub.dispose();
    }
    private hideOverflow(hide = true) {
        const body = document.querySelector('body');
        if (body) {
            hide ? (body.style.overflow = 'hidden') : (body.style.overflow = 'auto');
        }
    }

    public async saveInfo() {
        this.error = '';
        ApplicationState.account.name = this.account.name;
        ApplicationState.account.businessName = this.account.businessName;
        ApplicationState.account.contactNumber = this.account.contactNumber;
        await ApplicationState.save().catch(err => {
            this.error = err;
        });
        if (this.error.length === 0) {
            this.step = 2;
        }
    }

    public async decrementDevModeCounter() {
        await ApplicationState.decrementShowDevModeCounter();
    }

    private devMode?: DeveloperLaunchOptionsDialog;
    protected toggleDevMode() {
        if (this.devMode) {
            this.devMode.cancel();
            delete this.devMode;
        } else {
            if (ApplicationState.stateFlags.devMode) {
                this.devMode = ApplicationState.openDevModeDialog();
            }
        }
    }
    protected ensureTitleCase() {
        this.account.name = Utilities.toTitleCase(this.account.name);
        return true;
    }
    public async setAddress(event: Event) {
        const dialog = new AddressLookupDialog(this.account.address);
        const location = await dialog.show();
        if (!dialog.cancelled) {
            this.account.address = location;
            this.account.businessAddress = location;
        }
        if (event) event.stopPropagation();
    }
}
