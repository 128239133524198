import { TransactionType, type Customer, type Transaction } from '@nexdynamic/squeegee-common';
import { Data } from '../../Data/Data';
import { Logger } from '../../Logger';
import { Api } from '../../Server/Api';

export const checkCustomerPaymentStatuses = async (customerId: string, limit = 25) => {
    try {
        const customer = Data.get<Customer>(customerId);
        if (!customer) throw new Error('Customer not found');

        // Get all pending transactions for the customer limit to 10
        const pendingPayments = Data.all<Transaction>('transactions', {
            transactionType: TransactionType.AutomaticPayment,
            customerId,
            status: 'pending',
        });

        if (pendingPayments.length === 0) return;

        const goCardlessTransactions = pendingPayments.filter(
            t => t.paymentDetails?.paymentProvider === 'gocardless' && t.paymentDetails.paymentId
        );
        const stripeTransactions = pendingPayments.filter(
            t => t.paymentDetails?.paymentProvider === 'stripe' && t.paymentDetails.paymentId
        );

        if (goCardlessTransactions.length === 0 && stripeTransactions.length === 0) return;

        // Limit transactions
        const goCardlessTransactionsIds = goCardlessTransactions.map(t => t._id).splice(0, limit);
        const stripeTransactionsIds = stripeTransactions.map(t => t._id).splice(0, limit);

        Logger.info('Checking customer payment statuses', {
            customerId,
            goCardlessTransactionsIds,
            stripeTransactionsIds,
        });

        await Api.post(null, '/api/refresh-payment-statuses', {
            customerId,
            goCardlessTransactionsIds,
            stripeTransactionsIds,
        });
    } catch (error) {
        Logger.error('Error checking customer payment statuses', error);
    }
};
