import type { Customer, Job } from '@nexdynamic/squeegee-common';
import { Data } from '../../Data/Data';
import { updateTagText } from './updateTagText';

export const updateJobTags = async (
    initialJob: Job,
    standardSelected: string[],
    importantSelected: string[],
    ignoreImportant: string[],
    ignoreStandard: string[]
) => {
    const customer = Data.get<Customer>(initialJob.customerId);
    if (!customer) return;

    const job = customer.jobs[initialJob._id];
    if (!job) return;

    job.description = await updateTagText(job.description || '', standardSelected, importantSelected, ignoreImportant, ignoreStandard);

    Data.put(customer);
};
