import { ApplicationState } from '../ApplicationState';
import { LoaderEvent } from '../Events/LoaderEvent';
import { ReauthenticateEvent } from '../Events/ReauthenticateEvent';
import type { Subscription } from '../Events/SqueegeeEventAggregator';
import { ForgotPasswordDialog } from '../Launch/ForgotPasswordDialog';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { RethinkDbAuthClient } from '../Server/RethinkDbAuthClient';

export class ReauthenticateCustomElement {
    private _reauthenticateEventSub: Subscription;
    private _resolver?: (authenticated: boolean) => void;

    protected email: string | undefined;
    protected password: string;
    protected signInForm: HTMLFormElement;
    protected visible = false;

    public attached() {
        this._reauthenticateEventSub = ReauthenticateEvent.subscribe(async (authenticatedEvent: ReauthenticateEvent) => {
            this.email = RethinkDbAuthClient.session && RethinkDbAuthClient.session.email;
            if (!this.email) document.location && document.location.reload();
            Logger.info('Here comes the reauthentication train, choo choo...');
            this._resolver = authenticatedEvent.resolver;
            if (!this.password || !(await this.signIn())) this.visible = true;
        });
    }

    public detached() {
        this._reauthenticateEventSub && this._reauthenticateEventSub.dispose();
    }

    protected async signOut() {
        this.visible = false;
        ApplicationState.signOut();
    }
    protected async signIn() {
        if (this.signInForm.checkValidity()) {
            try {
                new LoaderEvent(true);
                await ApplicationState.signIn(this.password);
                this.visible = false;
                this._resolver && (await this._resolver(true));
                return true;
            } catch (error) {
                Logger.error('Error during complete signIn() in the reauthenticate customer element', error);
                new NotifyUserMessage('notifications.signin-failed');
            } finally {
                new LoaderEvent(false);
            }
        } else {
            this.setValidity('password', !!this.password);
            this.signInForm.classList.toggle('shake');
            setTimeout(() => this.signInForm.classList.toggle('shake'), 500);
        }
    }

    private setValidity(fieldId: string, valid: boolean) {
        const container = <HTMLDivElement>(<HTMLInputElement>document.getElementById(fieldId)).parentElement;
        if (valid) container.classList.remove('is-invalid');
        else container.classList.add('is-invalid');
    }

    protected async forgotPassword() {
        this.visible = false;
        await new ForgotPasswordDialog(<string>this.email).show();
        this.visible = true;
    }
}
