import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { SqueegeeEvent } from './SqueegeeEvent';

export class LoaderEvent extends SqueegeeEvent {
    constructor(
        public show: boolean,
        public showImmediately = false,
        public message?: TranslationKey,
        public nonBlocking = false,
        public localisationParams?: Record<string, string>
    ) {
        super();
    }
}
