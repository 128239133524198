import { ScheduleService } from '../../Schedule/ScheduleService';
import { TimerService } from '../../Tracking/TimerService';
import { AssignmentService } from '../../Users/Assignees/AssignmentService';
import { UserService } from '../../Users/UserService';
import { sendFrameApiData } from '../sendFrameApiData';

export const userScheduleUpdate = async () => {
    const todayIsoString = new Date().toISOString().slice(0, 10);
    const userSchedule = ScheduleService.getSchedule(todayIsoString, todayIsoString);

    const user = UserService.getUser()?._id;
    if (!user) return;

    const assignedSchedule = userSchedule.filter(s => AssignmentService.isAssigned(s.occurrence, user, true));

    const schedule = assignedSchedule.map(s => {
        let timer = TimerService.getWorkerTimersForJobOccurrence(s.occurrence);
        if (!timer.length) timer = [];

        const timeData = TimerService.getTimeEntriesForJobOccurrence(s.occurrence);
        return {
            time: timer.map(t => t.totalTime),
            customer: s.customer,
            occurrence: s.occurrence,
            timeData,
        };
    });

    sendFrameApiData('userScheduleUpdate', schedule);
};
