import { bindable } from 'aurelia-framework';
import './selectable.scss';
export class SelectableCustomElement {
    @bindable onChange: () => void;
    @bindable() selected: boolean;

    toggle() {
        this.onChange();
    }
}
