import type {
    IFranchiseeCharge,
    IFranchiseeFee,
    IFranchiseeFeeOrChargeBase,
    IFranchiseeSettings,
    PaymentTransactionSubType,
    TranslationKey
} from '@nexdynamic/squeegee-common';
import { AutomaticPaymentTransactionSubTypes, PaymentTransactionSubTypes } from '@nexdynamic/squeegee-common';
import { bindable, bindingMode } from 'aurelia-framework';
import { ApplicationState } from '../ApplicationState';
import type { CustomDialog } from '../Dialogs/CustomDialog';
import { NumpadDialog } from '../Dialogs/Numpad/NumpadDialog';
import { Prompt } from '../Dialogs/Prompt';
import { Select } from '../Dialogs/Select';
import { SelectMultiple } from '../Dialogs/SelectMultiple';
import { TextDialog } from '../Dialogs/TextDialog';
import { Logger } from '../Logger';
import { t } from "../t";
import type { IFeeOrChargeInfo } from './IFeeOrChargeInfo';

export class FranchiseeFeesAndCharges {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) franchiseeSettings: IFranchiseeSettings;
    @bindable showResetFees = true;
    @bindable showResetCharges = true;
    @bindable save: () => Promise<void>;

    protected currency = ApplicationState.currencySymbol();
    protected showFees = !ApplicationState.getSetting<boolean>('global.franchise-billing-is-accrual', false);

    public async attached() {
        this.refresh();
    }

    private refresh = () => {
        delete this._feeInfos;
        delete this._chargeInfos;
    }

    protected accrualToggled = (on: boolean) => {
        this.showFees = !on;
    }

    private _feeInfos?: Array<IFeeOrChargeInfo>;
    protected get feeInfos() {
        if (this._feeInfos === undefined) {
            this._feeInfos = [];
            const fees = this.franchiseeSettings.fees || {};
            this._feeInfos = <Array<IFeeOrChargeInfo>>Object.keys(fees)
                .map(f => {
                    return fees[f] && { name: f, details: fees[f] };
                })
                .filter(f => !!f);
        }
        return this._feeInfos;
    }

    private _chargeInfos?: Array<IFeeOrChargeInfo>;
    protected get chargeInfos() {
        if (this._chargeInfos === undefined) {
            this._chargeInfos = [];
            const charges: { [key: string]: IFranchiseeCharge | null } = this.franchiseeSettings.charges || {};
            this._chargeInfos = Object.keys(charges)
                .map(c => ({ name: c, details: charges[c] }))
                .filter((c): c is { name: string; details: IFranchiseeCharge } => !!c.details);
        }
        return this._chargeInfos;
    }

    protected async resetFeesToDefaults() {
        if (
            await new Prompt('general.confirm', 'reset.franchise-custom-fees', {
                okLabel: 'filters.reset',
                cancelLabel: 'general.cancel',
            }).show()
        ) {
            this.franchiseeSettings.fees =
                (ApplicationState.account.defaultFranchiseeSettings && ApplicationState.account.defaultFranchiseeSettings.fees) || {};
            this.refresh();
            this.save && (await this.save());
        }
    }

    protected async resetPaymentTypeFilterToDefaults() {
        if (
            await new Prompt('general.confirm', 'reset.franchise-to-default-payment', {
                okLabel: 'filters.reset',
                cancelLabel: 'general.cancel',
            }).show()
        ) {
            this.franchiseeSettings.includeTransactionSubTypes =
                (ApplicationState.account.defaultFranchiseeSettings &&
                    ApplicationState.account.defaultFranchiseeSettings.includeTransactionSubTypes) ||
                undefined;
            this.refresh();
            this.save && (await this.save());
        }
    }

    protected get transactionTypes() {
        const customPaymentMethods = ApplicationState.getSetting<Record<string, string>>('global.custom-payment-methods', {});
        return (this.franchiseeSettings.includeTransactionSubTypes || []).map(x => customPaymentMethods[x] || t(x as TranslationKey));
    }
    protected async resetChargesToDefaults() {
        if (
            await new Prompt('general.confirm', 'reset.franchise-to-default-charges', {
                okLabel: 'filters.reset',
                cancelLabel: 'general.cancel',
            }).show()
        ) {
            this.franchiseeSettings.charges =
                (ApplicationState.account.defaultFranchiseeSettings && ApplicationState.account.defaultFranchiseeSettings.charges) || {};
            this.refresh();
            this.save && (await this.save());
        }
    }

    protected async removeFee(feeInfo: IFeeOrChargeInfo) {
        if (!this.franchiseeSettings.fees || !this.franchiseeSettings.fees?.[feeInfo.name]) return;
        this.franchiseeSettings.fees[feeInfo.name] = null;
        this.refresh();
        this.save && (await this.save());
    }

    protected async removeCharge(chargeInfo: IFeeOrChargeInfo) {
        this.franchiseeSettings.charges && delete this.franchiseeSettings.charges[chargeInfo.name];
        this.refresh();
        this.save && (await this.save());
    }

    protected async addOrUpdateFee(feeInfoWithDetails?: IFeeOrChargeInfo) {
        const feeSettings = feeInfoWithDetails && (feeInfoWithDetails.details as IFranchiseeFee);
        const referenceFilterDialog = this.getReferenceFilterDialog(feeSettings);

        const additionalDialog = new NumpadDialog(
            (feeSettings && feeSettings.additionalFixedAmount) || 0,
            ApplicationState.currencySymbol(),
            ' additional'
        );
        additionalDialog.noReplaceSameType = true;
        additionalDialog.nextDialog = referenceFilterDialog;

        const maxDialog = this.getMaxDialog(feeSettings, additionalDialog);

        const minDialog = this.getMinDialog(feeSettings, maxDialog);

        const amountDialog = this.getAmountDialog(feeSettings, minDialog);

        const typeSelector = this.getTypeSelector(feeSettings, amountDialog, amountDialog);

        const paidDirectlyToFranchiseeDialog = new Prompt(
            'Paid Directly' as TranslationKey,
            'Is this payment method paid directly to the Franchisee and therefore deducted from the net income?' as TranslationKey,
            {
                okLabel: 'Paid Directly' as TranslationKey,
                altLabel: 'Not Paid Directly' as TranslationKey,
                cancelLabel: 'Cancel' as TranslationKey,
            }
        );
        paidDirectlyToFranchiseeDialog.nextDialog = typeSelector;
        paidDirectlyToFranchiseeDialog.noReplaceSameType = true;

        const types = this.getFeeTypes();
        const paymentTypeSelector = new Select(
            'select.payment-type-to-apply-fee',
            types,
            'text',
            'value',
            feeSettings && feeSettings.paymentType
        );
        paymentTypeSelector.noReplaceSameType = true;
        paymentTypeSelector.nextDialog = paidDirectlyToFranchiseeDialog;

        const currentName = (feeInfoWithDetails && feeInfoWithDetails.name) || '';
        const nameDialog = new TextDialog('enter.name-for-charge', '', currentName || '', '', value => {
            return !value || (value !== currentName && this.franchiseeSettings.charges && this.franchiseeSettings.charges[value])
                ? 'enter.name-unique-for-charge'
                : true;
        });

        nameDialog.noReplaceSameType = true;
        nameDialog.nextDialog = paymentTypeSelector;

        const result = await nameDialog.show();
        if (nameDialog.cancelled) return Logger.info('cancelled');

        const name = result;
        const paymentTypeResult = await paymentTypeSelector.getResult();
        const paidDirectlyToFranchisee = await paidDirectlyToFranchiseeDialog.result;
        const paymentType = paymentTypeResult && paymentTypeResult.value;
        if (!paymentType) return Logger.info('cancelled');
        const type = await typeSelector.getResult();
        const amount = await amountDialog.getResult();
        const min = await minDialog.getResult();
        const max = await maxDialog.getResult();
        const additionalFixedAmount = await additionalDialog.getResult();
        const referenceFilter = referenceFilterDialog.value;

        if (type !== undefined) {
            const fee: IFranchiseeFee = {
                paymentType,
                paidDirectlyToFranchisee,
                type: type.value,
                amount,
                min,
                max,
                additionalFixedAmount,
                referenceFilter,
            };
            this.franchiseeSettings.fees = this.franchiseeSettings.fees || {};
            delete this.franchiseeSettings.fees[currentName];
            this.franchiseeSettings.fees[name] = fee;

            this.refresh();
            this.save && (await this.save());
        }
    }

    private getFeeTypes() {
        const customPaymentMethods = ApplicationState.getSetting('global.custom-payment-methods', {} as Record<string, string>);
        const customPaymentMethodSubTypes = Object.keys(customPaymentMethods);
        const types = PaymentTransactionSubTypes.concat(AutomaticPaymentTransactionSubTypes)
            .concat(customPaymentMethodSubTypes)
            .map(subType => ({
                text: (() => {
                    if (customPaymentMethods[subType]) return customPaymentMethods[subType];
                    if (subType.includes('tip.')) return subType.replace('tip.', '').replace('payment.', '') + ' (tip)';
                    if (subType.includes('payment.')) return subType.replace('payment.', '');
                    if (subType.includes('auto.')) return subType.replace('auto.', '') + ' (automatic)';
                    return subType;
                })().toUpperCase() as TranslationKey,
                value: subType,
            }));

        return types;
    }

    private getTypeSelector(feeInfo: IFranchiseeFeeOrChargeBase | undefined, nextDialog: NumpadDialog, amountDialog: NumpadDialog) {
        const typeSelector = new Select<{ text: TranslationKey; value: 'percent' | 'fixed' }>(
            'select.fee-type',
            [
                { value: 'fixed', text: 'fixed.value' },
                { value: 'percent', text: 'global.percentage' },
            ],
            'text',
            'value',
            feeInfo && feeInfo.type
        );
        typeSelector.noReplaceSameType = true;
        typeSelector.nextDialog = nextDialog;

        amountDialog.showNextDialog = async () => {
            const type = await typeSelector.getResult();
            return (type && type.value === 'percent') || false;
        };

        typeSelector.beforeNextDialog = async () => {
            const type = await typeSelector.getResult();
            if (!type) return;
            switch (type.value) {
                case 'percent':
                    amountDialog.suffix = '% charge';
                    break;
                case 'fixed':
                    amountDialog.prefix = ApplicationState.currencySymbol();
                    amountDialog.suffix = ' charge';
                    break;
            }
        };

        return typeSelector;
    }

    private getAmountDialog(feeInfo: IFranchiseeFeeOrChargeBase | undefined, nextDialog: CustomDialog<any>) {
        const amountDialog = new NumpadDialog((feeInfo && feeInfo.amount) || 0);
        amountDialog.noReplaceSameType = true;
        amountDialog.nextDialog = nextDialog;
        return amountDialog;
    }

    private getMinDialog(feeInfo: IFranchiseeFeeOrChargeBase | undefined, nextDialog: CustomDialog<any>) {
        const minDialog = new NumpadDialog((feeInfo && feeInfo.min) || 0, ApplicationState.currencySymbol(), ' minimum');
        minDialog.noReplaceSameType = true;
        minDialog.nextDialog = nextDialog;
        return minDialog;
    }

    private getMaxDialog(feeInfo: IFranchiseeFeeOrChargeBase | undefined, nextDialog: CustomDialog<any>) {
        const maxDialog = new NumpadDialog((feeInfo && feeInfo.max) || 0, ApplicationState.currencySymbol(), ' maximum');
        maxDialog.noReplaceSameType = true;
        maxDialog.nextDialog = nextDialog;
        return maxDialog;
    }

    private getReferenceFilterDialog(feeInfo: IFranchiseeFee | undefined) {
        const referenceFilterDialog = new TextDialog(
            'Reference Filter' as TranslationKey,
            `Optionally only match this text in the payment reference for these transactions:` as TranslationKey,
            (feeInfo && feeInfo.referenceFilter) || '',
            ''
        );
        referenceFilterDialog.noReplaceSameType = true;
        return referenceFilterDialog;
    }

    protected async addOrUpdateCharge(chargeInfoDetails?: IFeeOrChargeInfo) {
        const chargeSettings = chargeInfoDetails && (chargeInfoDetails.details as IFranchiseeCharge);

        const additionalDialog = new NumpadDialog(
            (chargeSettings && chargeSettings.additionalFixedAmount) || 0,
            ApplicationState.currencySymbol(),
            ' additional'
        );
        additionalDialog.noReplaceSameType = true;

        const maxDialog = this.getMaxDialog(chargeSettings, additionalDialog);

        const minDialog = this.getMinDialog(chargeSettings, maxDialog);

        const appliedToDialog = this.getAppliedToSelector(chargeSettings, minDialog);

        const amountDialog = this.getAmountDialog(chargeSettings, appliedToDialog);

        const typeSelector = this.getTypeSelector(chargeSettings, amountDialog, amountDialog);

        let name = (chargeInfoDetails && chargeInfoDetails.name) || '';

        const currentName = name;

        const nameDialog = new TextDialog('enter.name-for-charge', '', name, '', value => {
            return !value || (value !== currentName && this.franchiseeSettings.charges && this.franchiseeSettings.charges[value])
                ? 'enter.name-unique-for-charge'
                : true;
        });

        nameDialog.noReplaceSameType = true;
        nameDialog.nextDialog = typeSelector;

        const result = await nameDialog.show();
        if (nameDialog.cancelled) return Logger.info('cancelled');

        name = result;

        const type = await typeSelector.getResult();
        const amount = await amountDialog.getResult();
        const appliedTo = await appliedToDialog.getResult();
        const min = await minDialog.getResult();
        const max = await maxDialog.getResult();
        const additionalFixedAmount = await additionalDialog.getResult();

        if (type !== undefined && appliedTo !== undefined) {
            const charge: IFranchiseeCharge = { type: type.value, amount, appliedTo: appliedTo.value, min, max, additionalFixedAmount };
            this.franchiseeSettings.charges = this.franchiseeSettings.charges || {};
            this.franchiseeSettings.charges[name] = charge;

            this.refresh();
            this.save && (await this.save());
        }
    }

    private getAppliedToSelector(info: IFranchiseeCharge | undefined, nextDialog: CustomDialog<any>) {
        const appliedToSelector = new Select<{ text: TranslationKey; value: 'net' | 'gross' }>(
            'how.to-apply-charge-select',
            [
                {
                    value: 'gross',
                    text: 'gross.before-charges-fees',
                },
                {
                    value: 'net',
                    text: 'net.before-charges-fees',
                },
            ],
            'text',
            'value',
            info && info.appliedTo
        );
        appliedToSelector.noReplaceSameType = true;
        appliedToSelector.nextDialog = nextDialog;

        return appliedToSelector;
    }

    protected updatePaymentTypeFilter = async () => {
        const types = this.getFeeTypes();
        const paymentTypeSelector = new SelectMultiple<{ text: TranslationKey; value: PaymentTransactionSubType }>(
            'Select Types' as TranslationKey,
            types,
            'text',
            'value',
            types.filter(x => this.franchiseeSettings?.includeTransactionSubTypes?.find(y => y === x.value))
        );

        paymentTypeSelector.disableLocalisation = true;
        await paymentTypeSelector.show();
        if (paymentTypeSelector.cancelled) return;

        this.franchiseeSettings.includeTransactionSubTypes = paymentTypeSelector.selectedOptions.map(x => x.value);
        console.log(this.franchiseeSettings.includeTransactionSubTypes);
        this.save && (await this.save());
        this.refresh();
    };
}
