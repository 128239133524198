import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { computedFrom } from 'aurelia-framework';
import { CustomDialog } from '../CustomDialog';

export class DurationDialog extends CustomDialog<string> {
    protected durationHours = 0;
    protected durationMinutes = 0;
    protected durationSeconds = 0;

    constructor(protected duration: string, protected dialogOptions?: { seconds?: boolean }) {
        super('timeOrDurationDialog', './TimeOrDuration/DurationDialog.html', 'Enter Duration' as TranslationKey, {
            okLabel: 'general.save',
            cssClass: 'duration-dialog',
        });
        this._initDurationParts();
    }

    private _initDurationParts() {
        if (this.duration) {
            const durationParts = this.duration.split(':');
            if (durationParts.length > 3) return;
            if (durationParts && durationParts.length > 1) {
                this.durationHours = parseInt(durationParts[0], 10);
                this.durationMinutes = parseInt(durationParts[1], 10);
                if (durationParts.length === 3) {
                    this.durationSeconds = parseInt(durationParts[2], 10);
                }
            }
        }
    }

    protected updateDuration() {
        this.duration = `${this.padNumberToString(this.durationHours)}:${this.padNumberToString(this.durationMinutes)}${
            this.useSeconds ? `:${this.padNumberToString(this.durationSeconds)}` : ''
        }`;
    }

    private padNumberToString(numberToPad: number, widthToPadTo = 2): string {
        let numberToPadAsString = numberToPad.toString(10);
        while (numberToPadAsString.length < widthToPadTo) {
            numberToPadAsString = `0${numberToPadAsString}`;
        }
        return numberToPadAsString;
    }

    public async getResult() {
        return this.duration;
    }

    @computedFrom('dialogOptions')
    protected get useSeconds() {
        return this.dialogOptions?.seconds || false;
    }
}
