import type {
    Customer,
    InvoiceTransaction,
    PaymentAccount,
    RefundPaymentTransactionSubType,
    RefundTransaction,
    Transaction,
    TransactionProvider,
    TranslationKey
} from '@nexdynamic/squeegee-common';
import {
    PaymentMethod,
    TransactionType
} from '@nexdynamic/squeegee-common';
import { bindable } from 'aurelia-framework';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import { ChargeCustomerService } from '../../Charge/ChargeCustomerService';
import type { IPaymentMethodDefinition, TPaymentMethodType } from '../../Charge/IPaymentMethodDefinition';
import { getRefundMethods } from '../../Charge/PaymentMethods';
import { Data } from '../../Data/Data';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { Prompt } from '../../Dialogs/Prompt';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { GlobalFlags } from '../../GlobalFlags';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { Api } from '../../Server/Api';
import { TransactionService } from '../../Transactions/TransactionService';
import { CustomerDialog } from './CustomerDialog';

export class CustomerRefundDialog extends CustomDialog<RefundTransaction> {
    @bindable() public paymentAmount = 0;

    public reference: string;
    public paymentDate: string = moment().format('YYYY-MM-DD');
    protected whatToPayOption: 'fullBalance' | undefined;
    protected selectedPaymentMethod: IPaymentMethodDefinition | undefined;
    protected hidePaymentOptions: boolean;
    protected hidePaymentMethods: boolean;
    protected readonly = false;
    protected provider?: TransactionProvider;
    protected providerId: string | undefined;

    public paymentAccountId?: string;
    protected paymentMethodType: TPaymentMethodType;
    protected hasAnyChargeMethod = false;
    protected currentHostAndScheme = Api.currentHostAndScheme;
    protected label: string;
    protected isAdminOrAbove = ApplicationState.isInAnyRole(['Owner', 'Admin']);
    protected paymentMethods = getRefundMethods(this.customer);
    protected payment?: Transaction;
    public static createForCustomer(customer: Customer) {
        const balance = TransactionService.getCustomerBalance(customer._id, moment().format('YYYY-MM-DD'));
        const dialog = new CustomerRefundDialog(balance.amount, customer);
        new LoaderEvent(false);
        return dialog;
    }

    public static createForProviderPayment(
        customer: Customer,
        provider: TransactionProvider,
        providerPayment: Transaction,
        paymentAccount?: PaymentAccount
    ) {
        if (!providerPayment.amount) return;

        const dialog = this.createForCustomer(customer);
        if (!dialog) return;
        dialog.hidePaymentMethods = true;
        dialog.readonly = true;

        dialog.paymentMethodType = 'Refund';
        const method = dialog.paymentMethods.find(x => x.method === PaymentMethod.bankTransfer);
        if (!method) throw 'Failed to find bank transfer refund method';
        dialog.selectedPaymentMethod = method;
        dialog.paymentAmount = Math.abs(providerPayment.amount);
        dialog.provider = provider;
        dialog.providerId = providerPayment.externalIds?.[provider];
        dialog.paymentDate = providerPayment.date;
        dialog.paymentAccount = paymentAccount;
        return dialog;
    }

    protected otherAmountInput?: HTMLInputElement;
    protected otherAmountInputClicked = () => {
        if (this.otherAmountInput && GlobalFlags.isHttp && !Number(this.paymentAmount)) this.otherAmountInput.value = '';
    };
    public static createForPayment(customer: Customer, payment: Transaction) {
        if (!payment.amount) return;

        const dialog = this.createForCustomer(customer);
        if (!dialog) return;
        dialog.readonly = true;

        dialog.paymentMethodType = 'Refund';
        dialog.payment = payment;
        const method = dialog.paymentMethods.find(x => x.tranType === payment.transactionType);
        if (method) dialog.selectedPaymentMethod = method;
        dialog.paymentAmount = Math.abs(payment.amount);
        if (payment.paymentDetails?.paymentAccountId) {
            dialog.paymentAccount = Data.get(payment.paymentDetails?.paymentAccountId);
            dialog.paymentAccountId = dialog.paymentAccount?._id;
        }

        return dialog;
    }

    private constructor(protected balance: number, protected customer: Customer, public paymentAccount?: PaymentAccount) {
        super('customerCreditDialog', '../Customers/Components/CustomerRefundDialog.html', ``, {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'customer-credit-dialog form-dialog credit-dialog',
            isSecondaryView: true,
            destructive: true,
        });

        const hasGoCardlessSetup =
            !!ApplicationState.account.goCardlessPublishableKey && !!customer.paymentProviderMetaData?.gocardless?.sourceOrMandateId;
        const hasStripeSetup = ApplicationState.account.stripePublishableKey;

        this.hasAnyChargeMethod = hasGoCardlessSetup || hasStripeSetup;
    }

    protected switchPaymentAmountSource() {
        this.updateLabel();
    }

    protected async setType(type: TPaymentMethodType) {
        if (this.readonly || this.paymentMethodType === type) return;

        this.paymentMethodType = type;
        this.selectedPaymentMethod = undefined;

        this.updateLabel();

        if (type === 'Charge' && !this.hasAnyChargeMethod) {
            if (ApplicationState.isInAnyRole(['Owner', 'Admin'])) {
                await new Prompt('charge.methods-configure', 'does.not-have-automatic-payment-enable-here', {
                    cancelLabel: '',
                }).show();
            }
            return;
        }
    }

    protected get canViewCustomer() {
        return this.customer && ApplicationState.isInAnyRole(['Owner', 'Admin', 'Creator']);
    }

    private updateLabel() {
        if (!this.selectedPaymentMethod) return;

        const selectedPaymentMethod = this.selectedPaymentMethod;

        setTimeout(() => {
            if (!this.readonly) {
                switch (this.whatToPayOption) {
                    case 'fullBalance':
                        this.paymentAmount = Math.abs(this.balance);
                        break;
                }
            }

            this.label = `Record ${ApplicationState.currencySymbol()}${this.paymentAmount.toFixed(2)} ${selectedPaymentMethod.name}`;
        }, 50);
    }

    protected async viewCustomer() {
        if (this.canViewCustomer) {
            if (this.customer) {
                const dialog = new CustomerDialog(this.customer);
                await dialog.show(DialogAnimation.SLIDE_UP);
            }
        }
    }

    public async init() {
        this.updateLabel();
    }
    private _recording: boolean;
    public recordDelegate() {
        // WTF: running record() directly from click will allow double clicks to double trigger GC payment
        if (this._recording) return;
        this._recording = true;
        this.record();
    }

    public async record(providerId?: string) {
        try {
            const isValid = this.validateForm();
            if (isValid !== true) {
                new NotifyUserMessage(isValid);
                return;
            }
            if (!this.selectedPaymentMethod) {
                new NotifyUserMessage('no.valid-payment-method-selected-user-message');
                return;
            }
            new LoaderEvent(true);

            const additionalReference = this.reference ? ` (${this.reference})` : '';
            const subType = this.selectedPaymentMethod.id;
            const provider = this.provider || this.selectedPaymentMethod.provider;
            const method = this.selectedPaymentMethod.method;
            providerId = providerId || this.providerId;
            const refund = TransactionService.createRefund(
                subType as RefundPaymentTransactionSubType,
                method,
                this.customer._id,
                Math.abs(this.paymentAmount),
                this.paymentDate,
                additionalReference,
                provider,
                providerId,
                this.payment,
                this.paymentAccount?._id
            );

            await ChargeCustomerService.completeLocalPayment(refund);

            if (this.payment) {
                if (!this.payment.paymentDetails) this.payment.paymentDetails = {};
                if (this.payment.paymentDetails) {
                    this.payment.paymentDetails.invoiceIds = [];
                    this.payment.paymentDetails.refundId = refund._id;
                }

                await Data.put(this.payment);

                if (this.payment.customerId) {
                    const id = this.payment._id;
                    const allocatedInvoiceTransactions = Data.all<InvoiceTransaction>(
                        'transactions',
                        t => t.invoice?.paymentReference === id,
                        {
                            customerId: this.payment.customerId,
                            transactionType: TransactionType.Invoice,
                        }
                    );
                    for (const t of allocatedInvoiceTransactions) {
                        if (!t.invoice) continue;
                        t.invoice.paymentReference = '';
                        t.invoice.paid = false;
                        Data.put(t);
                    }
                }
            }

            new LoaderEvent(false);
            this.ok(refund);
        } finally {
            this._recording = false;
        }
    }

    public validateForm(): true | TranslationKey {
        if (!this.selectedPaymentMethod) return 'validation.payment-method-required';

        if ((Number(this.paymentAmount) || 0) === 0) return 'validation.payment-amount-required';

        return true;
    }
}
