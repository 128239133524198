import type { CapacityBenchmarkType, CapacityMeasurementType } from '@nexdynamic/squeegee-common';

const TypeDescriptionDictionary: { [key: number]: string } = {
    0: 'Number of Jobs',
    1: 'Value of Jobs',
};

const BenchmarkDescriptionDictionary: { [key: number]: string } = {
    0: 'This Week',
    1: 'This Month',
};

export class CapacityMeasurement {
    constructor(public type: CapacityMeasurementType, public benchmark: CapacityBenchmarkType, public amount?: number) {}
    public get typeDescription(): string {
        return TypeDescriptionDictionary[this.type];
    }
    public get benchmarkDescription() {
        return BenchmarkDescriptionDictionary[this.benchmark];
    }
    public get benchmarkAmount() {
        return this.amount;
    }
}
