import type { IListItem, IListItemIndicator } from '@nexdynamic/squeegee-common';
import { Logger } from '../../Logger';
import { Utilities } from '../../Utilities';

export interface ListItem<TOriginalItemType> {
    avatarText?: string;
    avatarColour?: string;
    getSecondaryContentHtml?: string | (() => string | Promise<string>);
    getAdditionalSearchableText?: string | (() => string | Promise<string>);
    getIndicators?: Array<IListItemIndicator> | (() => Array<IListItemIndicator> | Promise<Array<IListItemIndicator>>);
}

export abstract class ListItem<TOriginalItemType> implements IListItem<TOriginalItemType> {
    public constructor(readonly original: TOriginalItemType) {}

    private _cache: { [key: string]: any } = {};
    public get cache() {
        return this._cache;
    }
    public refresh() {
        this._cache = {};
    }

    protected abstract getTitle: string | (() => string | Promise<string>);
    public get title() {
        return this.getCachedValue('title', this.getTitle);
    }

    protected abstract getDescription: string | (() => string | Promise<string>);

    protected abstract getAvatarImage: string | (() => string | Promise<string>);
    public get description() {
        return this.getCachedValue('description', this.getDescription);
    }

    public get secondaryContentHtml() {
        return this.getCachedValue('secondaryContentHtml', this.getSecondaryContentHtml);
    }

    public get additionalSearchableText() {
        return this.getCachedValue('additionalSearchableText', this.getAdditionalSearchableText);
    }

    public get indicators(): Array<IListItemIndicator> {
        return this.getCachedValue('indicators', this.getIndicators);
    }

    public get avatarImage() {
        return this.getCachedValue('avatarImage', this.getAvatarImage);
    }

    private getCachedValue(
        property: string,
        getter?:
            | string
            | Array<IListItemIndicator>
            | (() => string | Array<IListItemIndicator> | Promise<string | Array<IListItemIndicator>>)
    ) {
        if (this.cache[property] === undefined) {
            this.cache[property] = null;
            if (getter) {
                const cacheResult = typeof getter === 'function' ? getter() : getter;
                if (typeof cacheResult === 'string' || Array.isArray(cacheResult)) {
                    this.cache[property] = cacheResult;
                } else {
                    setTimeout(
                        () =>
                            cacheResult
                                .then(t => (this.cache[property] = t))
                                .catch(error => Logger.info(`Failed to get the ${property} for list item.`, { error })),
                        Utilities.randomInteger(50, 250)
                    );
                }
            }
        }
        return this.cache[property];
    }
}
