import type { TranslationKey } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';

export class DateTimePicker {
    private static _defaultId = 'date-time-picker';
    public okOnSelect: boolean;

    public constructor(
        protected hideCancel = false,
        public initialDate?: string | null,
        public title: TranslationKey = 'dates.select-date',
        protected pickFutureDates: boolean = true,
        protected highlightDates?: { [isoDate: string]: { allowPick: boolean } },
        protected highlightIcon?: string,
        protected showTodayButton = true,
        protected disableDaysBefore?: string
    ) {
        this.title = ApplicationState.localise(this.title);
    }

    private static daysOfWeek: Array<string> = [
        '<th>M</th>',
        '<th>T</th>',
        '<th>W</th>',
        '<th>T</th>',
        '<th>F</th>',
        '<th>S</th>',
        '<th>S</th>',
    ];

    private _currentDate: moment.Moment;
    private _dateTimePickerEl: (HTMLElement & { DateTimePicker?: DateTimePicker }) | null;
    private _obfuscator: HTMLElement | null;
    private _isOpen = false;
    private _selectedDate = '';
    private _isInitialized = false;
    private _elementId: string = DateTimePicker._defaultId;

    public onDateSelected: (selectedDate: string) => void;
    public canceled = false;

    public init(elementId?: string) {
        if (this.initialDate) this._currentDate = moment(this.initialDate, 'YYYY-MM-DD');

        if (!this._currentDate || !this._currentDate.isValid()) this._currentDate = moment();

        this._selectedDate = this.currentDate.format('YYYY-MM-DD');
        if (this._isInitialized === false) {
            if (elementId) this._elementId = elementId;

            this._dateTimePickerEl = <HTMLElement & { DateTimePicker?: DateTimePicker }>document.getElementById(this._elementId);

            if (!this._dateTimePickerEl) {
                throw new Error('No date time picker element found for #' + this._elementId);
            }

            this._dateTimePickerEl.DateTimePicker = this;
            this._obfuscator = document.getElementById('date-time-picker-obfuscator');

            if (!this._obfuscator) {
                this._dateTimePickerEl.insertAdjacentHTML(
                    'beforebegin',
                    `<div id="date-time-picker-obfuscator" class="date-time-picker-obfuscator"></div>`
                );
                this._obfuscator = document.getElementById('date-time-picker-obfuscator');
            }

            this._isInitialized = true;
        }
    }

    public open() {
        const closePromise = new Promise<void>(resolve => (this._closePromiseResolver = resolve));
        this.buildDateTimePicker();
        this._obfuscator && this._obfuscator.classList.add('date-time-picker-obfuscator-active');
        this._dateTimePickerEl && this._dateTimePickerEl.classList.add('date-time-picker-active');
        this._isOpen = true;
        return closePromise;
    }

    public toggle() {
        if (this._isOpen === false) {
            this.open();
        } else {
            this.close();
        }
    }

    public cancel() {
        this.canceled = true;
        this.close();
    }

    public today() {
        this.selectDate(moment().format('YYYY-MM-DD'));
        this.ok();
    }

    private _closePromiseResolver: (value: void) => void;
    public close() {
        this._obfuscator && this._obfuscator.classList.remove('date-time-picker-obfuscator-active');
        this._dateTimePickerEl && this._dateTimePickerEl.classList.remove('date-time-picker-active');
        this._isOpen = false;
        if (this._closePromiseResolver) this._closePromiseResolver();
    }

    public ok() {
        if (this.isDisabled(this._currentDate.format('YYYY-MM-DD'))) return;
        const currentDateString = this._currentDate.format('YYYY-MM-DD');
        this._selectedDate = currentDateString;
        this.onDateSelected && this.onDateSelected(this._selectedDate);
        this.close();
    }

    private isDisabled(date: string) {
        let disabled = false;
        Object.keys(this.highlightDates || {}).forEach(e => {
            if (this.highlightDates && !this.highlightDates[e].allowPick && date === e) disabled = true;
        });
        return disabled;
    }

    public get currentDate(): moment.Moment {
        return this._currentDate;
    }

    public get selectedDate(): string {
        return this._selectedDate;
    }

    public static getCurrentMonthStartOffset(date: moment.Moment): number {
        const firstDateOfMonth = date.clone().date(1);

        let offset = firstDateOfMonth.isoWeekday() - 1;
        offset = offset < 0 ? 6 : offset;

        return offset;
    }

    private buildDateTimePicker() {
        this._dateTimePickerEl &&
            (this._dateTimePickerEl.innerHTML =
                this.getHeaderElement() + this.getBodyHeaderElement() + this.getBodyElement() + this.getFooterElement());
    }

    private getHeaderElement(): string {
        const title = this.title ? `<div class="date-time-picker-title">${this.title}</div>` : '';
        const headerHTML = `
            ${title}
            <div class="date-time-picker-header">
                <div class="dtph-selected-year">
                    <div class="selected-year-container">
                        <div style="cursor: pointer;">${this._currentDate.year()}</div>
                    </div>
                </div>

                <div class="dtph-selected-date">
                    <div class="selected-date-container">
                        <div>
                            ${this._currentDate.format('ddd MMM Do')}
                        </div>
                    </div>
                </div>
            </div>
        `;

        return headerHTML;
    }

    private getBodyHeaderElement() {
        const bodyHeader = `
            <div class="date-time-picker-body-header">

                <button
                onclick="document.getElementById('${this._elementId}').DateTimePicker.changeMonth(-1)"
                tabindex="0" type="button" class="dtp-header-button">
                    <div>
                        <svg viewBox="0 0 24 24">
                            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                        </svg>
                    </div>
               </button>

               <div class="dtp-body-header-text">
                    ${this._currentDate.format('MMMM YYYY')}
               </div>

               <button onclick="document.getElementById('${this._elementId}').DateTimePicker.changeMonth(1)"
                tabindex="0" type="button" class="dtp-header-button">
                    <div>
                        <svg viewBox="0 0 24 24">
                            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                        </svg>
                    </div>
               </button>

            </div>
        `;

        return bodyHeader;
    }

    private getBodyElement() {
        const bodyElement = `
            <div class="date-time-picker-body">

                <table>
                    <thead class="dtpb-days-of-week">
                        ${DateTimePicker.daysOfWeek.join('\n')}
                    </thead>
                     <tbody class="dtpb-days">
                        ${this.getDayRows(this._currentDate).join('\n')}
                    </tbody>
                </table>

            </div>
        `;

        return bodyElement;
    }

    public okLabel: TranslationKey = 'general.select';
    public cancelLabel: TranslationKey = 'general.cancel';
    private getFooterElement() {
        const todayButton = !this.showTodayButton
            ? ''
            : `
                <button onclick="document.getElementById('${this._elementId}').DateTimePicker.today()"
                tabindex="0" type="button" class="dtp-footer-button">
                    <div>
                        <span>${ApplicationState.localise('dates.today')}</span>
                    </div>
                </button>`;

        const cancelButton = this.hideCancel
            ? ''
            : `
                <button onclick="document.getElementById('${this._elementId}').DateTimePicker.cancel()"
                tabindex="0" type="button" class="dtp-footer-button">
                    <div>
                        <span>${ApplicationState.localise(this.cancelLabel)}</span>
                    </div>
                </button>`;

        const okButton = this.okOnSelect
            ? ''
            : `                <button onclick="document.getElementById('${this._elementId}').DateTimePicker.ok()"
        tabindex="0" type="button" class="dtp-footer-button">
            <div>
                <span>${ApplicationState.localise(this.okLabel)}</span>
            </div>
        </button>`;

        const footerElement = `
            <div class="date-time-picker-footer">
                ${todayButton}
                ${cancelButton}
                ${okButton}
            </div>
        `;
        return footerElement;
    }

    private getDayRows(date: moment.Moment): Array<string> {
        const offset = DateTimePicker.getCurrentMonthStartOffset(date);
        const rows: Array<string> = [];
        let currentRow: Array<string> = [];

        if (offset > 0) {
            const lastMonth = date.clone().add(-1, 'M');

            for (let day = 1; day <= offset; day++) {
                currentRow.push(
                    this.createDayElement(
                        lastMonth.year(),
                        lastMonth.month(),
                        lastMonth.daysInMonth() - offset + day,
                        true,
                        undefined,
                        this.disableDaysBefore
                    )
                );
            }
        }

        for (let day = 1; day <= date.daysInMonth(); day++) {
            if (currentRow.length === 7) {
                const rowString = `<tr>${currentRow.join('\n')}</tr>`;
                rows.push(rowString);
                currentRow = [];
            }

            currentRow.push(this.createDayElement(date.year(), date.month(), day, false, day === date.date(), this.disableDaysBefore));
        }

        if (currentRow.length) {
            let day = 1;
            if (currentRow.length < 7) {
                const nextMonth = date.clone().add(1, 'M');
                while (currentRow.length < 7) {
                    currentRow.push(
                        this.createDayElement(nextMonth.year(), nextMonth.month(), day++, true, undefined, this.disableDaysBefore)
                    );
                }
            }

            const rowString = `<tr>${currentRow.join('\n')}</tr>`;
            rows.push(rowString);
        }

        return rows;
    }

    private todayIso = moment().format('YYYY-MM-DD');
    private createDayElement(
        year: number,
        monthIndex: number,
        dayOfMonth: number,
        differentMonth = false,
        isCurrent = false,
        dateInPastIso?: string
    ) {
        const dateIso = `${year}-${('0' + (monthIndex + 1)).slice(-2)}-${('0' + dayOfMonth).slice(-2)}`;

        const futureDisabled = !this.pickFutureDates && dateIso > this.todayIso;
        const pastFromDateDisabled = dateInPastIso ? dateIso < dateInPastIso : false;

        let classes = 'dtpg-day';
        classes += this.getDisabledClass(differentMonth);
        classes += this.getIsActiveClass(isCurrent);
        classes += this.getIsTodayClass(dateIso);
        classes += this.getDisallowedClass(dateIso);
        classes += this.getHighlightIconClass();
        classes += this.getFutureDisabledClass(futureDisabled);
        classes += this.disallowPastDatesClass(pastFromDateDisabled);

        const highlightClass = this.getHighlightClass(dateIso);
        classes += highlightClass;

        const clickHandler = `document.getElementById('${this._elementId}').DateTimePicker.selectDate('${dateIso}')`;

        const iconElement = this.highlightIcon && highlightClass ? `<i class="material-icons">${this.highlightIcon}</i>` : '';

        return `<td title="${dateIso}" class="${classes}" onclick="${clickHandler}"><span>${dayOfMonth}</span> ${iconElement}</td>`;
    }

    private getFutureDisabledClass(futureDisabled: boolean) {
        return futureDisabled ? ' dtpg-future-disabled' : '';
    }

    private getHighlightIconClass() {
        return this.highlightIcon ? ' icon' : '';
    }

    private getIsTodayClass(dateIso: string) {
        return dateIso === this.todayIso ? ' dtpg-today' : '';
    }

    private getIsActiveClass(isCurrent: boolean) {
        return isCurrent ? ' dtpg-active' : '';
    }

    private getDisabledClass(differentMonth: boolean) {
        return differentMonth ? ' dtpg-different-month' : '';
    }

    public changeMonth(direction: number) {
        if (direction > 0 && !this.pickFutureDates && moment(this._currentDate).endOf('month') >= moment().endOf('month')) return;
        if (
            direction < 0 &&
            this.disableDaysBefore &&
            moment(this._currentDate).startOf('month') <= moment(this.disableDaysBefore).startOf('month')
        ) {
            return;
        }
        this._currentDate.add(direction, 'M');
        if (
            direction > 0 &&
            !this.pickFutureDates &&
            moment(this._currentDate).isSame(moment(), 'month') &&
            moment(this._currentDate).isAfter(moment(), 'day')
        ) {
            this._currentDate = moment();
        }
        this.buildDateTimePicker();
    }

    private getHighlightClass(dateIso: string) {
        return this.highlightDates?.[dateIso] ? ' highlight' : '';
    }

    private getDisallowedClass(dateIso: string) {
        return this.highlightDates?.[dateIso]?.allowPick === false ? ' disallowed' : '';
    }

    private disallowPastDatesClass(fromDateIso?: boolean) {
        if (!fromDateIso) return '';

        return ' disable-past-dates';
    }

    public selectDate(dateISO: string) {
        let allowSelectDate = true;
        const highlightedDate = this.highlightDates && this.highlightDates[dateISO];
        if (highlightedDate && !highlightedDate.allowPick) allowSelectDate = false;
        else if (!this.pickFutureDates && dateISO > moment().format('YYYY-MM-DD')) allowSelectDate = false;
        else if (this.disableDaysBefore && dateISO < this.disableDaysBefore) allowSelectDate = false;
        if (allowSelectDate) {
            this._currentDate = moment(dateISO);
            this.buildDateTimePicker();
            if (!this.okOnSelect) return;

            this.ok();
        }
    }
}
