import type { Customer, JobOccurrence } from '@nexdynamic/squeegee-common';
import { StoredObject } from '@nexdynamic/squeegee-common';
import { ApplicationState } from './ApplicationState';
import { Data } from './Data/Data';
import { Prompt } from './Dialogs/Prompt';
import { TextDialog } from './Dialogs/TextDialog';
import { LoaderEvent } from './Events/LoaderEvent';
import { Utilities } from './Utilities';

export class CustomUpdateFunctions {
    [name: string]:
        | ((x: Customer, Data: Data, ApplicationState: ApplicationState) => Promise<Array<StoredObject> | StoredObject | undefined>)
        | string
        | boolean
        | number;

    private 'fallbackToEmail': boolean;
    public readonly 'Notifications - Set default to SMS if available' = async (customer: Customer) => {
        if (this.fallbackToEmail === undefined) {
            new LoaderEvent(false);
            this.fallbackToEmail = await new Prompt('fallback.to-email', 'fallback.to-email-question', {
                okLabel: 'general.yes',
                cancelLabel: 'general.no',
            }).show();
            new LoaderEvent(true);
        }

        if (customer.telephoneNumber && /^07|^447|^\+447|^\+44 7|^44 7/.test(customer.telephoneNumber)) {
            if (customer.defaultNotificationMethod !== 'sms') {
                customer.defaultNotificationMethod = 'sms';
                return customer;
            }
            return;
        }

        if (customer.telephoneNumberOther && /^07|^447|^\+447|^\+44 7|^44 7/.test(customer.telephoneNumberOther)) {
            if (customer.defaultNotificationMethod !== 'sms2') {
                customer.defaultNotificationMethod = 'sms2';
                return customer;
            }
            return;
        }

        if (!this.fallbackToEmail) return;

        if (customer.email) {
            if (customer.defaultNotificationMethod !== 'email') {
                customer.defaultNotificationMethod = 'email';
                return customer;
            }
            return;
        }
    };

    private 'fallbackToSms': boolean;
    public readonly 'Notifications - Set default to Email if available' = async (customer: Customer) => {
        if (this.fallbackToSms === undefined) {
            new LoaderEvent(false);
            this.fallbackToSms = await new Prompt('fallback.to-sms', 'fallback-to-sms-question', {
                okLabel: 'general.yes',
                cancelLabel: 'general.no',
            }).show();
            new LoaderEvent(true);
        }

        if (customer.email) {
            if (customer.defaultNotificationMethod !== 'email') {
                customer.defaultNotificationMethod = 'email';
                return customer;
            }
            return;
        }

        if (!this.fallbackToSms) return;

        if (customer.telephoneNumber && /^07|^447|^\+447|^\+44 7|^44 7/.test(customer.telephoneNumber)) {
            if (customer.defaultNotificationMethod !== 'sms') {
                customer.defaultNotificationMethod = 'sms';
                return customer;
            }
            return;
        }

        if (customer.telephoneNumberOther && /^07|^447|^\+447|^\+44 7|^44 7/.test(customer.telephoneNumberOther)) {
            if (customer.defaultNotificationMethod !== 'sms2') {
                customer.defaultNotificationMethod = 'sms2';
                return customer;
            }
            return;
        }
    };

    private 'duration': string;
    public readonly 'Jobs - Set duration on all jobs and not done occurrences' = async (customer: Customer) => {
        let customerUpdated = false;
        const updates: Array<StoredObject> = [];

        if (this.duration === undefined) {
            const durationDialog = new TextDialog(
                'enter.duration-text',
                'enter.duration-format-zerozero',
                ApplicationState.account.defaultJobDuration,
                '',
                value => (/\d\d:\d\d/.test(value) ? true : 'enter.duration-in-format-hour-minute')
            );

            new LoaderEvent(false);
            this.duration = await durationDialog.show();
            new LoaderEvent(true);

            if (durationDialog.cancelled) throw 'User cancelled';
        }

        for (const job of Object.keys(customer.jobs || {}).map(id => customer.jobs[id])) {
            if (job.duration !== this.duration) {
                job.duration = this.duration;
                customerUpdated = true;
            }

            const jobOccurrences = Data.all<JobOccurrence>('joboccurrences', x => x.duration !== this.duration && !x.status, {
                jobId: job._id,
            });
            if (jobOccurrences && jobOccurrences.length) {
                for (const occurrence of jobOccurrences) {
                    occurrence.duration = this.duration;
                    const copy = Utilities.copyObject(occurrence);
                    updates.push(copy);
                }
            }
        }

        if (customerUpdated) updates.push(customer);

        return updates.length ? updates : undefined;
    };
}
