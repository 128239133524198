import type { JobOccurrence, StandardApiResponseWithData } from '@nexdynamic/squeegee-common';
import { type Customer, type Job } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom } from 'aurelia-framework';
import { CustomerService } from '../../Customers/CustomerService';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import type { Subscription } from '../../Events/NewDataEvent';
import { JobService } from '../../Jobs/JobService';
import { Logger } from '../../Logger';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import type { ScheduleItem } from '../../Schedule/Components/ScheduleItem';
import { ScheduleService } from '../../Schedule/ScheduleService';
import { Api } from '../../Server/Api';

export class AppointmentsView {
    @bindable jobId: string;
    @bindable customerId: string;

    @bindable appointments: Array<ScheduleItem>;
    protected job?: Job;
    protected customer?: Customer;

    private _dataRefreshedEvent: Subscription;
    protected attached() {
        this.load();

        this._dataRefreshedEvent = DataRefreshedEvent.subscribe<DataRefreshedEvent>(e => this.handleDataChange(e));
    }

    protected detached() {
        if (this._dataRefreshedEvent) this._dataRefreshedEvent.dispose();
    }

    private handleDataChange(event: DataRefreshedEvent) {
        if (event.hasAnyType('customers', 'joboccurrences', 'jobs')) {
            this.load();
        }
    }

    private load() {
        this.job = JobService.getJob(this.customerId, this.jobId);
        this.customer = CustomerService.getCustomer(this.customerId);
        this.appointments = JobService.getJobAppointments(this.customerId, this.jobId);
        this.archivedAppointments();
    }

    @computedFrom('customer.name')
    protected get title() {
        return this.customer?.name;
    }

    @computedFrom('customer.name')
    protected get description() {
        return this.job?.location?.addressDescription;
    }

    protected async archivedAppointments() {
        try {
            const response = await Api.get<StandardApiResponseWithData<Array<JobOccurrence>>>(
                null,
                `/api/jobs/customers/${this.customerId}/jobs/${this.jobId}/occurrences?archived=true`,
                undefined,
                2000
            );

            if (!response?.data.success) throw new Error('Failed to load archived appointments');

            if (!response.data.data.length) return;

            const archivedOccurrences = response.data.data.sort((a, b) => {
                if (a.isoCompletedDate && b.isoCompletedDate) {
                    return a.isoCompletedDate > b.isoCompletedDate ? -1 : 1;
                } else if (a.isoCompletedDate) {
                    return -1;
                } else if (b.isoCompletedDate) {
                    return 1;
                }
                return 0;
            });

            const archivedScheduleItems = new Array<ScheduleItem>();
            for (const archivedOccurrence of archivedOccurrences) {
                const scheduleItemFromArchivedOccurrence = ScheduleService.getScheduleItemForJobOccurrence(archivedOccurrence);
                if (!scheduleItemFromArchivedOccurrence) continue;
                archivedScheduleItems.push(scheduleItemFromArchivedOccurrence);
            }
            if (!archivedScheduleItems.length) return;

            for (const item of archivedScheduleItems) {
                const index = this.appointments.findIndex(appointment => appointment.occurrence._id === item.occurrence._id);
                if (index > -1) this.appointments.splice(index, 1, item);
                else this.appointments.push(item);
            }
        } catch (error) {
            Logger.error('Failed to load archived appointments', error);
            return new NotifyUserMessage('appointments-view.error-loading-archived-appointments');
        }
    }
}
