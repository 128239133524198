import type { JobOccurrence } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../ApplicationState';
import { CustomDialog } from '../../Dialogs/CustomDialog';

type Params = {
    customerId: string;
    jobId: string;
};

export class AppointmentsDialog extends CustomDialog<void> {
    protected menuTitle = ApplicationState.localise('jobs.appointments');
    protected appointments: Array<JobOccurrence>;

    constructor(protected params: Params) {
        super('AppointmentsDialog', '../Jobs/Dialogs/AppointmentsDialog.html', '', {
            cssClass: 'no-nav-shadow',
            isSecondaryView: true,
            okLabel: '',
            cancelLabel: '',
        });
    }
}
