import type { Service } from '@nexdynamic/squeegee-common';
import { InvoiceItem, TagType, to2dp, uuid } from '@nexdynamic/squeegee-common';
import { computedFrom } from 'aurelia-framework';
import { ApplicationState } from '../ApplicationState';
import { CustomDialog } from '../Dialogs/CustomDialog';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { SelectTagsDialog } from '../Tags/Components/SelectTagsDialog';
import { InvoiceLineItemForm } from './InvoiceLineItemForm';

export class InvoiceLineItemDialog extends CustomDialog<InvoiceItem> {
    protected currencySymbol = ApplicationState.currencySymbol();
    protected failedValidation = false;
    protected formIsDirty = false;
    protected lineItemForm = new InvoiceLineItemForm();

    constructor(public invoiceItem?: InvoiceItem, public isLinkedToJob = false) {
        super('invoiceLineItemDialog', '../Invoices/InvoiceLineItemDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'invoice-line-item-dialog',
            isSecondaryView: true,
            destructive: true,
        });

        if (invoiceItem) {
            this.lineItemForm = new InvoiceLineItemForm(
                this.invoiceItem?.quantity,
                this.invoiceItem?.description,
                this.invoiceItem?.unitPrice
            );
        }
    }

    protected async selectService() {
        try {
            const dialog = new SelectTagsDialog([], TagType.SERVICE, 0, 1);
            const result = await dialog.show(DialogAnimation.SLIDE_UP);

            if (!dialog.cancelled) {
                if (result.length) {
                    this.serviceToInvoiceItem(result[0]);
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    private serviceToInvoiceItem(service: Service) {
        if (service) {
            this.lineItemForm = new InvoiceLineItemForm(service.quantity || 1, service.description, service.price || 0);
        }
    }

    private formIsValid() {
        let isValid = false;
        this.formIsDirty = true;

        if (this.lineItemForm.isValid) isValid = true;
        this.failedValidation = !isValid;
        return isValid;
    }

    public save = () => {
        if (this.formIsValid()) {
            // If invoice item was passed in only update some fields and don't create new invoice item
            let invoiceItem: InvoiceItem;
            if (this.invoiceItem) {
                invoiceItem = this.invoiceItem;
                Object.assign(this.invoiceItem, {
                    quantity: Number(this.lineItemForm.quantity),
                    description: this.lineItemForm.description,
                    unitPrice: Number(this.lineItemForm.unitPrice),
                });
            } else {
                invoiceItem = new InvoiceItem(
                    Number(this.lineItemForm.quantity),
                    this.lineItemForm.description,
                    Number(this.lineItemForm.unitPrice),
                    'transactions',
                    uuid(),
                    undefined,
                    'custom'
                );
            }
            this.ok(invoiceItem);
        } else if (this.lineItemForm.validationError) {
            new NotifyUserMessage(this.lineItemForm.validationError);
        }
    };

    public removeItem() {
        if (this.invoiceItem) {
            this.ok();
        }
    }

    @computedFrom('lineItemForm.unitPrice', 'lineItemForm.quantity')
    public get totalPrice() {
        return to2dp(this.lineItemForm.unitPrice * this.lineItemForm.quantity);
    }
}
