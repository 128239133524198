import type { Customer } from '@nexdynamic/squeegee-common';
import { Data } from '../Data/Data';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { CustomerDialog } from './Components/CustomerDialog';

export class CustomerActions {
    static view(customerId: string) {
        try {
            const customer = Data.get<Customer>(customerId);

            if (!customer) throw new Error('customer not found!');
            else {
                new CustomerDialog(customer).show();
            }
        } catch (error) {
            Logger.error('Unable to view customer', error);
            new NotifyUserMessage('customer.unable-to-view');
        }
    }
}
