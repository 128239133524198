import type {
    AccountUser,
    Customer,
    DayOfWeek,
    Job,
    JobGroup,
    JobInitialFields,
    JobState,
    Team,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import {
    Frequency,
    FrequencyBuilder,
    FrequencyType,
    Service,
    TagType,
    getUkPostcode,
    notNullUndefinedEmptyOrZero,
} from '@nexdynamic/squeegee-common';
import { ObserverLocator, bindable, computedFrom, inject } from 'aurelia-framework';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import { DateTimePicker } from '../../Components/DateTimePicker/DateTimePicker';
import { CustomerDialog } from '../../Customers/Components/CustomerDialog';
import { Data } from '../../Data/Data';
import { AddressLookupDialog } from '../../Dialogs/AddressLookupDialog';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { Prompt } from '../../Dialogs/Prompt';
import { SelectMultiple } from '../../Dialogs/SelectMultiple';
import { TextAreaDialog } from '../../Dialogs/TextAreaDialog';
import { TextDialog } from '../../Dialogs/TextDialog';
import { DurationDialog } from '../../Dialogs/TimeOrDuration/DurationDialog';
import { TimeDialog } from '../../Dialogs/TimeOrDuration/TimeDialog';
import { Logger } from '../../Logger';
import { SelectTagsDialog } from '../../Tags/Components/SelectTagsDialog';
import { Utilities } from '../../Utilities';
import { isDevMode } from '../../isDevMode';
import { JobService } from '../JobService';
import './JobForm.scss';
import { JobPricingDialog } from './JobPricing';

@inject(ObserverLocator)
export class JobForm {
    @bindable protected customer: Customer;
    @bindable protected job: Job;
    @bindable protected assignees: Array<Team | AccountUser> = [];
    @bindable protected failedValidation: boolean;
    @bindable protected formIsDirty: boolean;
    @bindable protected priceServicesEnabled: boolean;
    @bindable protected quoteAppointment: boolean;
    @bindable protected isNewJob = false;
    @bindable protected initialFields?: JobInitialFields;
    @bindable protected onSubmit?: (job: Job, fields: JobInitialFields) => void;
    @bindable protected quoteConversion: boolean;
    @bindable protected applyRange: boolean;

    protected jobAnyTime = true;
    protected durationHours = 0;
    protected durationMinutes = 0;
    protected isAssignedToTeam: boolean;
    private firstDate = moment().format('YYYY-MM-DD');
    protected firstDateChosen = false;
    protected initialPrice: number;
    protected initialPriceChosen = false;

    protected submit() {
        if (this.onSubmit) {
            const fields: JobInitialFields = {
                firstDate: this.firstDateChosen ? this.firstDate : undefined,
                initialPrice: this.initialPriceChosen ? this.initialPrice : undefined,
            };
            this.onSubmit(this.job, fields);
        } else {
            Logger.warn('Job form was submitted but no onSubmit handler was passed');
        }
    }

    protected toggleFirstDateChosen() {
        this.firstDateChosen = !this.firstDateChosen;
    }

    private _appointmentName: string = ApplicationState.localise('quotes.appointment-service-name');
    protected get appointmentName() {
        return this._appointmentName;
    }

    protected set appointmentName(name: string) {
        this._appointmentName = name;
        if (this.quoteAppointment && this.job.services && this.job.services.length === 1 && this.job.services[0].description !== name)
            this.job.services = [new Service(name)];
    }

    private _originalJobTime: string | undefined;

    protected get multiUserEnabled() {
        return ApplicationState.multiUserEnabled;
    }

    private _switchedServices: Array<Service> = [];
    protected setServicesBasedOnQuoteAppointmentStatus() {
        if (this.quoteAppointment) {
            this._switchedServices = this.job.services;
            this.job.services = [new Service(this.appointmentName)];
        } else {
            this.job.services = this._switchedServices;
        }
    }

    public hasAdvancedOrAbove = ApplicationState.hasAdvancedOrAbove;

    bind() {
        this._switchedServices = this.job.services;

        if (this.job.time !== undefined && this.job.time.length > 0) {
            //clean old job times that were the 'h:mma' format
            if (this.job.time.indexOf('m') > -1) {
                this.job.time = moment(this.job.time, 'h:mma').format('HH:mm');
            }
            this.jobAnyTime = false;
            this._originalJobTime = this.job.time;
        }
        if (this.job.duration === undefined) this.job.duration = ApplicationState.account.defaultJobDuration;

        this.priceServicesEnabled =
            this.job.price !== 0 &&
            this.job.services
                .map(x => (Number((x as Service).price) || 0) * (Number((x as Service).quantity) || 1))
                .reduce((x: number, y) => x + (y || 0), 0) === this.job.price;

        if (this.job.rounds.length) {
            const roundData = Data.get<JobGroup>(this.job.rounds[0]._id);
            if (roundData) this.setJobFrequencyToRoundFrequency(roundData);
        }
    }

    attached() {
        if (this.job && !this.job.time) this.job.time = undefined;
        if (this.initialFields) {
            if (Number(this.initialFields.initialPrice)) {
                this.initialPrice = Number(this.initialFields.initialPrice);
                this.initialPriceChosen = true;
            }

            if (this.initialFields.firstDate) {
                this.firstDate = this.initialFields.firstDate;
                this.firstDateChosen = true;
            }
        }
        if (this.job.frequencyRange) this.selectFrequencyWithinRange();

        this._initDurationParts();
    }

    protected async editDuration(event: Event) {
        if (!this.isPlanner) return;

        const durationDialog = new DurationDialog(this.job.duration || ApplicationState.account.defaultJobDuration || '00:30');
        const duration = await durationDialog.show();
        if (durationDialog.cancelled || !duration) return;

        this.job.duration = duration;

        if (event) event.stopPropagation();
    }

    protected async editTime(event: Event) {
        if (!this.isPlanner) return;

        const timeDialog = new TimeDialog(this.job.time, !this.job.time);
        const time = await timeDialog.show();
        if (timeDialog.cancelled) return;

        this.job.time = time;

        if (event) event.stopPropagation();
    }

    protected get defaultRequireSignature() {
        return this.customer && this.customer.requireSignature === true
            ? 'customer - yes'
            : this.customer && this.customer.requireSignature === false
            ? 'customer - no'
            : ApplicationState.account.requireSignature
            ? 'global - yes'
            : 'global - no';
    }

    protected globalTrackTime = ApplicationState.getSetting('global.jobs.track-time', false);
    @computedFrom('globalTrackTime')
    protected get defaultTrackTime() {
        return ApplicationState.getSetting('customer.jobs.track-time', this.globalTrackTime, this.customer._id);
    }
    protected selectRequireSignature(required?: boolean) {
        this.job.requireSignature = required;
    }

    protected selectTrackTime(trackTime?: boolean) {
        this.job.trackTime = trackTime;
    }

    protected get requireSignatureDescription() {
        return this.job.requireSignature === true
            ? 'Yes'
            : this.job.requireSignature === false
            ? 'No'
            : `Default (${this.defaultRequireSignature})`;
    }

    protected get requireSignature() {
        return this.job.requireSignature === true || this.customer.requireSignature === true || ApplicationState.account.requireSignature;
    }

    private _initDurationParts() {
        if (this.job && this.job.duration) {
            const durationParts = this.job.duration.split(':');
            if (durationParts && durationParts.length === 2) {
                this.durationHours = parseInt(durationParts[0], 10);
                this.durationMinutes = parseInt(durationParts[1], 10);
            }
        }
    }

    protected updateDuration() {
        this.job.duration = `${this.padNumberToString(this.durationHours)}:${this.padNumberToString(this.durationMinutes)}`;
    }

    private padNumberToString(numberToPad: number, widthToPadTo = 2): string {
        let numberToPadAsString = numberToPad.toString(10);
        while (numberToPadAsString.length < widthToPadTo) {
            numberToPadAsString = `0${numberToPadAsString}`;
        }
        return numberToPadAsString;
    }

    protected jobAnyTimeToggled() {
        if (this.jobAnyTime) {
            this.job.time = undefined;
        } else {
            if (!this.job.time) this.job.time = this._originalJobTime ? this._originalJobTime : moment().format('HH:mm');
        }
    }

    protected priceServicesEnabledChanged() {
        for (let i = 0; i < this.job.services.length; i++) {
            const service = <Service>this.job.services[i];
            const serviceDefault = Data.get<Service>(service._id);

            service.quantity = service.quantity || (this.priceServicesEnabled && serviceDefault ? Number(serviceDefault.quantity) || 1 : 1);
            service.price = service.price || (this.priceServicesEnabled && serviceDefault ? Number(serviceDefault.price) || 0 : 0);
        }
    }

    protected enableServicePricing() {
        this.priceServicesEnabled = true;
    }

    protected disableServicePricing() {
        this.priceServicesEnabled = false;
    }

    protected async addToRound(event: Event) {
        if (event) event.stopPropagation();
        const dialog = new SelectTagsDialog(
            this.job.rounds.slice(),
            TagType.ROUND,
            0,
            1,
            undefined,
            this.job.location && this.job.location.lngLat
        );
        const rounds = <Array<JobGroup>>await dialog.show(DialogAnimation.SLIDE_UP);
        if (dialog.cancelled) return;
        const roundData = rounds.find(round => round.frequencyData !== undefined);
        if (roundData) {
            if (this.customer && this.job && this.customer.jobs[this.job._id]) {
                const prompt = new Prompt('jobgroup.set-round-schedule', 'jobgroup.set-round-schedule-confirm-single', {
                    okLabel: 'general.ok',
                    localisationParams: { jobs: '' },
                });
                await prompt.show();

                if (prompt.cancelled) return;
            }

            this.setJobFrequencyToRoundFrequency(roundData);
        }

        this.job.rounds = rounds.slice();
    }

    protected setJobFrequencyToRoundFrequency(roundData: JobGroup) {
        const roundFrequencyData = roundData.frequencyData;
        if (roundFrequencyData?.firstScheduledDate && roundFrequencyData.type !== FrequencyType.NoneRecurring) {
            const freq = FrequencyBuilder.getFrequency(
                roundFrequencyData.interval,
                roundFrequencyData.type,
                roundFrequencyData.firstScheduledDate,
                roundFrequencyData.dayOfMonth,
                roundFrequencyData.weekOfMonth,
                roundFrequencyData.dayOfWeek
            );

            const nextDate = freq.getNextDate();

            if (!nextDate) throw 'There is no future.';

            this.job.date = nextDate;
            this.job.frequencyType = roundFrequencyData.type;
            this.job.frequencyInterval = roundFrequencyData.interval;
            this.job.frequencyDayOfMonth = roundFrequencyData.dayOfMonth;
            this.job.frequencyDayOfWeek = roundFrequencyData.dayOfWeek;
            this.job.frequencyWeekOfMonth = roundFrequencyData.weekOfMonth;
        }
    }

    protected skillsEnabled = ApplicationState.multiUserEnabled;

    protected async addSkills(event: Event) {
        if (!this.isEditor) return;
        const availableSkills = ApplicationState.skills.map(skill => ({ text: skill, value: skill }));
        const dialog = new SelectMultiple('general.select-skills', availableSkills, 'text', 'value');
        const skills = await dialog.show(DialogAnimation.SLIDE_UP);
        if (dialog.cancelled) return;
        this.job.skills = skills.map(s => s.value);
        if (event) event.stopPropagation();
    }

    protected async addServices(event: Event) {
        if (this.quoteAppointment) return;
        const dialog = new SelectTagsDialog(this.job.services.slice(), TagType.SERVICE, 0);
        const services = await dialog.show(DialogAnimation.SLIDE_UP);
        if (!dialog.cancelled) {
            for (const service of services) {
                if (this.job.services.some(s => s._id === service._id)) continue;
                this.job.services.push(service);
            }

            this.job.services = this.job.services.filter(s => services.some(ss => ss._id === s._id));
        }
        if (event) event.stopPropagation();
    }

    public async selectFrequency() {
        const mode: 'one-off' | 'frequency' = this.job.frequencyType === FrequencyType.NoneRecurring ? 'one-off' : 'frequency';

        const matchingTexts = [
            getUkPostcode(this.job.location?.addressDescription),
            getUkPostcode(this.job.location?.addressDescription, false),
        ].filter(notNullUndefinedEmptyOrZero);

        if (this.quoteAppointment) {
            await JobService.pickFrequency(
                this.job,
                false,
                true,
                false,
                mode,
                undefined,
                undefined,
                matchingTexts,
                this.quoteConversion,
                this.applyRange
            );
        } else {
            await JobService.pickFrequency(
                this.job,
                true,
                true,
                true,
                mode,
                this.job.location && this.job.location.lngLat,
                this.job.rounds && this.job.rounds[0],
                matchingTexts,
                this.quoteConversion,
                this.applyRange
            );
        }

        if (isDevMode()) Logger.info('Job frequency set', this.job);
    }

    protected async pickLocation() {
        const dialog = new AddressLookupDialog(this.job.location);
        const location = await dialog.show();
        if (!dialog.cancelled) {
            this.job.location = Utilities.copyObject(location);
        }
    }

    protected get formattedDate() {
        if (this.job && this.job.date) {
            return moment(this.job.date).format('ddd, MMM Do');
        } else {
            return '';
        }
    }

    protected get frequencyText(): TranslationKey | '' {
        if (this.job && this.job.frequencyInterval !== undefined && this.job.frequencyType !== undefined) {
            const frequency = FrequencyBuilder.getFrequency(
                this.job.frequencyInterval,
                this.job.frequencyType,
                this.job.date || moment(),
                this.job.frequencyDayOfMonth,
                this.job.frequencyWeekOfMonth,
                Array.isArray(this.job.frequencyDayOfWeek) ? this.job.frequencyDayOfWeek[0] : this.job.frequencyDayOfWeek
            );
            return ApplicationState.localise(frequency.localisationKeyAndParams.key, frequency.localisationKeyAndParams.params);
        } else return '';
    }
    protected selectJobState(state: JobState) {
        this.job.state = state;
    }

    @computedFrom(
        'job.date',
        'job.frequencyInterval',
        'job.frequencyType',
        'job.frequencyDayOfMonth',
        'job.frequencyWeekOfMonth',
        'job.frequencyDayOfWeek',
        'job.externalCalendarUrl'
    )
    protected get fullScheduleDescription() {
        let description = '';

        const firstScheduledDateText = (this.job.date && moment(this.job.date, 'YYYY-MM-DD').format('ddd MMM D YYYY')) || '';

        if (this.job.frequencyRange) {
            const summary = new Frequency(
                moment(firstScheduledDateText),
                this.job.frequencyInterval,
                this.job.frequencyType,
                this.job.frequencyDayOfMonth,
                this.job.frequencyWeekOfMonth,
                this.job.frequencyDayOfWeek as DayOfWeek | undefined,
                this.job.frequencyRange
            ).summary;

            if (summary) return summary;
        }
        const frequencyText = this.frequencyText;
        if (this.quoteAppointment) {
            description = firstScheduledDateText;
        } else if (this.job.frequencyType === FrequencyType.ExternalCalendar) {
            description = ApplicationState.localise('frequency.external-calendar');
        } else if (this.job.frequencyType !== FrequencyType.NoneRecurring) {
            description = this.job.date
                ? ApplicationState.localise('frequency.description', { frequency: frequencyText, date: firstScheduledDateText })
                : frequencyText;
        } else {
            description = this.job.date
                ? ApplicationState.localise('frequency.appointment-description', {
                      frequency: frequencyText,
                      date: firstScheduledDateText,
                  })
                : frequencyText;
        }
        return description;
    }

    protected get isAdmin() {
        return ApplicationState.isInAnyRole(['Owner', 'Admin']);
    }

    protected get isEditor() {
        return ApplicationState.isInAnyRole(['Owner', 'Admin', 'Creator', 'JobEditor']);
    }

    protected get isPlanner() {
        return ApplicationState.isInAnyRole(['Owner', 'Admin', 'Creator', 'Planner', 'JobEditor']);
    }

    public async viewCustomer() {
        if (!ApplicationState.isInAnyRole(['Admin', 'Owner', 'Creator'])) return false;
        const dialog = new CustomerDialog(this.customer);
        await dialog.show(DialogAnimation.SLIDE_IN);
    }

    @computedFrom('scheduleItem.job.date')
    protected get startingDateText() {
        return `${this.job.frequencyType !== FrequencyType.NoneRecurring ? 'Starting ' : ''}${moment(this.job.date, 'YYYY-MM-DD').format(
            'ddd, MMM Do YYYY'
        )}`;
    }

    protected async editAppointmentDescription() {
        const descriptionDialog = new TextDialog(
            'general.description',
            '',
            this.job.services[0]?.description || '',
            '',
            v => !!v || 'validation.name-required'
        );
        const result = await descriptionDialog.show();
        if (descriptionDialog.cancelled) return;
        this.job.services[0].description = result;
    }
    protected async selectServices(event: Event) {
        if (!this.isEditor) return;
        const dialog = new SelectTagsDialog(this.job.services.slice(), TagType.SERVICE, 0);
        const services = await dialog.show(DialogAnimation.SLIDE_UP);
        if (dialog.cancelled) return;
        this.job.services = services.slice();
        if (event) event.stopPropagation();
    }

    protected async editNotes(event: Event) {
        const dialog = new TextAreaDialog('customers.notes-label', this.job.description ? this.job.description : '', undefined, undefined);
        const notes = await dialog.show();
        if (dialog.cancelled) return;
        this.job.description = notes;
        if (event) event.stopPropagation();
    }

    protected async editInvoiceNotes(event: Event) {
        const dialog = new TextAreaDialog(
            'customers.notes-label',
            this.job.invoiceNotes ? this.job.invoiceNotes : '',
            undefined,
            undefined
        );
        const notes = await dialog.show();
        if (dialog.cancelled) return;
        this.job.invoiceNotes = notes;
        if (event) event.stopPropagation();
    }

    protected async editPrice(event: Event) {
        if (!this.isEditor) return;
        const job = Utilities.copyObject<Job>(this.job);

        //TODO: add first job price code
        const dialog = new JobPricingDialog(job);
        await dialog.show();
        if (dialog.cancelled) return;
        this.job.price = job.price;
        this.job.services = job.services.slice();
        if (event) event.stopPropagation();
    }

    protected async editSchedule(event: Event) {
        if (!this.isEditor && !this.isPlanner) return;
        const mode: 'one-off' | 'frequency' = this.job.frequencyType === FrequencyType.NoneRecurring ? 'one-off' : 'frequency';

        const matchingTexts = [
            getUkPostcode(this.job.location?.addressDescription),
            getUkPostcode(this.job.location?.addressDescription, false),
        ].filter(notNullUndefinedEmptyOrZero);

        if (this.quoteAppointment) {
            await JobService.pickFrequency(
                this.job,
                false,
                this.quoteAppointment,
                false,
                'one-off',
                undefined,
                undefined,
                matchingTexts,
                this.quoteConversion,
                this.applyRange
            );
        } else {
            await JobService.pickFrequency(
                this.job,
                true,
                true,
                true,
                mode,
                this.job.location && this.job.location.lngLat,
                this.job.rounds && this.job.rounds[0],
                matchingTexts,
                this.quoteConversion,
                this.applyRange
            );
        }
        if (event) event.stopPropagation();

        if (isDevMode()) Logger.info('Job frequency set', this.job);
    }

    protected async chooseFirstDate() {
        const datePicker = new DateTimePicker(false, this.firstDate);
        datePicker.init();
        await datePicker.open();
        if (!datePicker.canceled) {
            this.firstDate = datePicker.selectedDate;
        }
    }

    @computedFrom('isNewJob', 'job.frequencyType')
    protected get showInitialFields() {
        if (
            this.job.frequencyType === FrequencyType.NoneRecurring ||
            this.job.frequencyType === FrequencyType.ExternalCalendar ||
            (!this.isNewJob && !this.job.isQuote)
        )
            return false;
        else return true;
    }

    @computedFrom('defaultTrackTime')
    protected get timeTrackDefaultLabel() {
        return this.defaultTrackTime
            ? ApplicationState.localise('default.global-label-yes')
            : ApplicationState.localise('default.label-no');
    }

    protected async selectFrequencyWithinRange() {
        if (this.job.frequencyType === FrequencyType.NoneRecurring || this.job.frequencyType === FrequencyType.ExternalCalendar) return;
        if (!this.job.frequencyRange) return;

        const matchingTexts = [
            getUkPostcode(this.job.location?.addressDescription),
            getUkPostcode(this.job.location?.addressDescription, false),
        ].filter(notNullUndefinedEmptyOrZero);
        await JobService.pickFrequency(
            this.job,
            true,
            true,
            true,
            'frequency',
            this.job.location && this.job.location.lngLat,
            this.job.rounds && this.job.rounds[0],
            matchingTexts,
            true,
            false,
            true
        );
    }
}
