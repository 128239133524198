import { GlobalFlags } from './GlobalFlags';
import { RethinkDbAuthClient } from './Server/RethinkDbAuthClient';
import type { TUrlSearchParams } from './TUrlSearchParams';
import { Utilities } from './Utilities';

export class CustomUrlSchemeHandler {
    public static initialise() {
        if (!GlobalFlags.isHttp && (GlobalFlags.isAppleMobileDevice || GlobalFlags.isAndroidMobileDevice)) {
            window.handleOpenURL = async (rawUrl: string) => CustomUrlSchemeHandler.handle(rawUrl);
        } else if (GlobalFlags.isHttp && window.document.location.href?.indexOf('?goto=') > -1) {
            const queryRegex = window.document.location.href && window.document.location.href.match(/\?(.*?)$/);

            if (queryRegex && queryRegex.length === 2) {
                const query = (<any>new URLSearchParams(queryRegex[1])) as TUrlSearchParams;
                if (query.has('goto')) CustomUrlSchemeHandler.handle(query.get('goto'));
            }
        }
    }
    private static handleCustomUrlSchemeDirectly(rawUrl: string): boolean {
        if (rawUrl.includes('payments/square/callback')) {

            return true;
        }
        return false;
    }
    private static async handle(rawUrl: string) {
        if (CustomUrlSchemeHandler.handleCustomUrlSchemeDirectly(rawUrl)) return;
        RethinkDbAuthClient.checkQuerySession(rawUrl);
        console.log('*** Handle raw url coming in: ' + rawUrl);
        const path = rawUrl.replace('web+squeegee://', '');
        const applicationRouteWithLeadingSlash = path.trim().length && path.startsWith('/') ? path : '/' + path;
        Utilities.goToRootUrl({ applicationRouteWithLeadingSlash });
    }
}
