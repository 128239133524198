import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import type { IVATReturnV1 } from '../../ChartOfAccounts/VATService';
import { VATServiceV1 } from '../../ChartOfAccounts/VATService';
import { DateTimePicker } from '../../Components/DateTimePicker/DateTimePicker';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import type { Subscription } from '../../Events/SqueegeeEventAggregator';
export class VATREturn {
    private _dataChangedEvent: Subscription;

    protected startDate = moment().subtract(1, 'month').format('YYYY-MM-DD');
    protected endDate = moment().format('YYYY-MM-DD');

    protected businessName = ApplicationState.account.businessName;

    protected vatReturn: IVATReturnV1;

    async loadData() {
        this.vatReturn = VATServiceV1.getVATReturn({ from: this.startDate, to: this.endDate });
    }

    async setStartDate() {
        const dtp = new DateTimePicker(undefined, this.startDate, 'balance.date-1', false);
        dtp.init();
        await dtp.open();
        if (dtp.canceled) return;
        this.startDate = dtp.selectedDate.substring(0, 10);
        this.loadData();
    }

    async setEndDate() {
        const dtp = new DateTimePicker(undefined, this.endDate, 'balance.date-2', false);
        dtp.init();
        await dtp.open();
        if (dtp.canceled) return;
        this.endDate = dtp.selectedDate.substring(0, 10);
        this.loadData();
    }

    attached() {
        this._dataChangedEvent = DataRefreshedEvent.subscribe<DataRefreshedEvent>(event => {
            if (event.hasAnyType('paymentaccounts')) return this.loadData();
            if (event.hasAnyType('transactions')) return this.loadData();
        });
        this.loadData();
    }
    detached() {
        this._dataChangedEvent && this._dataChangedEvent.dispose();
    }
}
