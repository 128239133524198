import { animate } from '../Utilities';
import { SqueegeeEvent } from './SqueegeeEvent';
export class BGLoaderEvent extends SqueegeeEvent {
    private constructor(public show: boolean, public message: string, public progressPercent?: number | 'unknown') {
        super();
    }

    public static async emit(show: boolean, message: string, progressPercent?: number | 'unknown') {
        new BGLoaderEvent(show, message, progressPercent);
        await animate();
    }
}
