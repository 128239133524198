import type { LngLat } from '@nexdynamic/squeegee-common';
import { copyObject, Location, notNullUndefinedEmptyOrZero } from '@nexdynamic/squeegee-common';
import type { Subscription } from 'aurelia-event-aggregator';
import { bindable, bindingMode, inject } from 'aurelia-framework';
import * as leaflet from 'leaflet';
import 'leaflet-draw';
import 'leaflet.markercluster';
import { bbox, multiPoint } from 'turf';
import { ApplicationState } from '../ApplicationState';
import { CustomerSavedMessage } from '../Customers/CustomerMessage';
import { SqueegeeLocalStorage } from '../Data/SqueegeeLocalStorage';
import type { RouteScheduleItem } from '../DayPilot/Components/RouteScheduleItem';
import { NavigationDialog } from '../Dialogs/Navigation/NavigationDialog';
import { DataRefreshedEvent } from '../Events/DataRefreshedEvent';
import { InvalidateMapSizesEvent } from '../Events/InvalidateMapSizesEvent';
import { isDevMode } from '../isDevMode';
import { JobSavedMessage } from '../Jobs/JobMessage';
import { PositionUpdatedEvent } from '../Location/PositionUpdatedEvent';
import { Logger } from '../Logger';
import type { ScheduleItem } from '../Schedule/Components/ScheduleItem';
import { TrackingService } from '../Tracking/TrackingService';
import { UserService } from '../Users/UserService';
import type { Workload } from '../Users/Workload';
import { animate } from '../Utilities';
import { sites } from './sw';

const pointInPolygon = require('point-in-polygon');

export interface LocationWithScheduleItem extends Location {
    isLocationWithScheduleItem: true;
    scheduleItem: ScheduleItem;
    statusColour?: string;
    avatarColour?: string;
    rank?: number;
    icon?: string;
    avatar?: string;
}

@inject(Element)
export class MapCustomElement {
    @bindable() name = 'general';
    @bindable() style = 'mapbox://styles/mapbox/light-v9';
    @bindable() zoom = 11;
    @bindable() minZoom = 1;
    @bindable() maxZoom = 18;
    @bindable() dragging = false;
    @bindable() scrollWheelZoom = false;
    @bindable() touchZoom = false;
    @bindable() doubleClickZoom = false;
    @bindable() boxZoom = false;
    @bindable() trackResize = true;

    // required for dragging not to be intercepted on IOS
    @bindable() tap = false;
    @bindable() driveStyle = false;
    @bindable() workloads: Array<Workload>;
    @bindable() showCurrentPosition = false;
    @bindable() trackCurrentPositionRealtime = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) mapRef: leaflet.Map;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) markers: leaflet.MarkerClusterGroup | undefined;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) fullscreen = false;
    @bindable() locationLoading: boolean;
    @bindable() showHome = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) homeMarkerClickHandler: () => void | undefined;
    @bindable() alwaysShow = false;
    @bindable() hideFullScreen = false;
    @bindable() dontZoomToSelected = false;
    @bindable() showUserMarkers = true;
    @bindable() enableDrawing: boolean;
    @bindable() selectItems?: (items: Array<ScheduleItem | RouteScheduleItem | Location>) => void;
    @bindable() selectItem?: (item: ScheduleItem | RouteScheduleItem | Location) => void;
    @bindable() location: Array<RouteScheduleItem | ScheduleItem | Location> | Location;
    @bindable() useLocationStatusColour: boolean;
    @bindable() useRoundColour: boolean;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) clearSelected: () => void;
    @bindable() locationChangedTrigger?: (map: MapCustomElement) => void;
    private _toggleResizeUpdateTimeout: NodeJS.Timeout;
    private _fitMapToGeoJSONTimeout: NodeJS.Timeout;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) refreshSelected?: () => void;
    public async locationChanged() {
        this._locations = undefined;
        if (!this.map) {
            await this._reloadMap();
        } else {
            this.refreshUserMarkers();
            this.refreshLocationMarkers();
            if (this.trackCurrentPositionRealtime) this.fitMapToLocationsBounds();
        }

        this.locationChangedTrigger?.(this);
    }

    @bindable() overrideUserForCurrentPosition: string | undefined = undefined;
    public overrideUserForCurrentPositionChanged() {
        this.updateCurrentPosition();
    }

    @bindable({ defaultBindingMode: bindingMode.twoWay }) selectedItem: RouteScheduleItem | LocationWithScheduleItem | undefined;
    protected selectedItemChanged(selectedItem: RouteScheduleItem | undefined) {
        if (!selectedItem?.id || !selectedItem.scheduleItem) return;

        const selectedMarker = this._markersById[selectedItem.scheduleItem.occurrence._id];
        if (!selectedMarker || !this.markers) return;

        const latLng = selectedMarker.getLatLng();

        this.trimLatLng(latLng);

        if (this.dontZoomToSelected) {
            this.map.panTo(latLng);
        } else {
            if (this.map.getZoom() >= 15) {
                this.map.panTo(latLng);
            } else {
                this.map.setView(latLng, 15);
            }
        }
    }

    // Change tracked properties should bind last.
    @bindable({ defaultBindingMode: bindingMode.twoWay }) selectedWorkload: Workload;
    protected selectedWorkloadChanged(selectedItem: Workload) {
        if (!selectedItem || !selectedItem.userOrTeam?._id) return;

        const selectedMarker = this._usersById[selectedItem.userOrTeam._id];
        if (!selectedMarker || !this.markers) return;

        const latLng = selectedMarker.getLatLng();

        this.trimLatLng(latLng);

        if (this.dontZoomToSelected) {
            this.map.panTo(latLng);
        } else {
            if (this.map.getZoom() >= 15) {
                this.map.panTo(latLng);
            } else {
                this.map.setView(latLng, 15);
            }
        }
    }

    @bindable() fitMapToLocationToggle = true;
    protected fitMapToLocationToggleChanged() {
        this.fitMapToLocationsBounds();
    }

    protected hasValidLocations = false;

    _positionSub: Subscription;
    _dataRefreshedSub: Subscription;

    constructor(private element: Element, private map: leaflet.Map) {}

    private _customerChangedSub: Subscription;
    private _jobChangedSub: Subscription;
    private _mapReady = false;
    private _currentPositionMarker: leaflet.Marker | undefined;
    private _applicationState = ApplicationState;
    private _homeMarker: leaflet.Marker;

    private _peopleMarkers: leaflet.MarkerClusterGroup | undefined;

    private _locations?: Array<Location>;
    private get locations(): Array<Location | LocationWithScheduleItem | RouteScheduleItem> {
        if (this._locations === undefined) {
            if (!this.location) {
                this._locations = [];
            } else if (Array.isArray(this.location)) {
                if (this.location.length === 0) {
                    this._locations = [];
                } else {
                    if ((this.location[0] as ScheduleItem).occurrence?.location) {
                        this._locations = this.location
                            .map(x => {
                                const s = x as ScheduleItem;
                                if (!s.occurrence?.location) return undefined;

                                const location = copyObject(s.occurrence.location) as LocationWithScheduleItem;
                                location.scheduleItem = s;
                                return location;
                            })
                            .filter(notNullUndefinedEmptyOrZero);
                    } else {
                        this._locations = this.location as Array<RouteScheduleItem>;
                    }
                }
            } else {
                this._locations = [this.location];
            }
        }
        return this._locations;
    }

    private checkHasValidLocations() {
        this.hasValidLocations = !!this.updateGeoJSONFeatures().length;
    }

    private _invalidateMapSizesSubscription: Subscription;
    public async attached() {
        window.sq.currentMap = this;

        this.checkHasValidLocations();
        if (this.showCurrentPosition) await this.initCurrentPosition();

        await this._reloadMap(true);

        this._invalidateMapSizesSubscription = InvalidateMapSizesEvent.subscribe(this.resizeMapOnInvalidate);
        this._customerChangedSub = CustomerSavedMessage.subscribe(() => this.refreshMarkers());
        this._jobChangedSub = JobSavedMessage.subscribe(() => this.refreshMarkers());
    }

    public detached() {
        this._invalidateMapSizesSubscription && this._invalidateMapSizesSubscription.dispose();
        this._customerChangedSub && this._customerChangedSub.dispose();
        this._jobChangedSub && this._jobChangedSub.dispose();
        this._positionSub && this._positionSub.dispose();
        this._dataRefreshedSub && this._dataRefreshedSub.dispose();

        clearTimeout(this._reloadMapDebounce);
        clearTimeout(this._toggleResizeUpdateTimeout);
        clearTimeout(this._fitMapToGeoJSONTimeout);

        this.map && this.map.remove();
        window.sq.currentMap && delete window.sq.currentMap;
    }

    private async initCurrentPosition() {
        await this.updateCurrentPosition();

        if (this.trackCurrentPositionRealtime) {
            this._positionSub = PositionUpdatedEvent.subscribe<PositionUpdatedEvent>(async () => this.updateCurrentPosition());
            this._dataRefreshedSub = DataRefreshedEvent.subscribe(async (event: DataRefreshedEvent) => {
                if (event.hasAnyType('tracker')) {
                    await this.updateCurrentPosition();
                }
            });
        }
    }

    private async updateCurrentPosition() {
        let position: LngLat | undefined;
        if (this.overrideUserForCurrentPosition) {
            const user = await UserService.getUser(this.overrideUserForCurrentPosition);
            if (user) position = TrackingService.getLatestTracking(user)?.lngLat;
        } else {
            await ApplicationState.positionInitialised;
            position = this.getLngLatFromGeoPosition(ApplicationState.position);
        }

        this.setCurrentPosition(position);
    }

    private setCurrentPosition(position: LngLat | undefined) {
        if (!position) return;

        this._currentPosition = position;
        this._currentPositionMarker && this._currentPositionMarker.setLatLng([position[1], position[0]]);
        if (this.trackCurrentPositionRealtime) this.fitMapToCurrentPositionAndNextWayPoint();
    }

    private getLngLatFromGeoPosition(position?: globalThis.GeolocationPosition): LngLat | undefined {
        return position ? [position.coords.longitude, position.coords.latitude] : undefined;
    }

    private trimLatLng(latLng: leaflet.LatLng) {
        if (latLng && latLng.lat && latLng.lng) {
            latLng.lat = Number(latLng.lat.toFixed(6));
            latLng.lng = Number(latLng.lng.toFixed(6));
        }
        return latLng;
    }

    private refreshMarkers() {
        this.refreshLocationMarkers();
        this.refreshUserMarkers();
        this.refreshSelected?.();
    }

    private _reloadMapDebounce: any;
    private async _reloadMap(immediately = false) {
        const reloadCallback = async () => {
            try {
                this._locationsAsGeoJSONFeatureCollection = [];
                this.markers = undefined;
                this._peopleMarkers = undefined;
                this.checkHasValidLocations();
                if (this.hasValidLocations || this.alwaysShow) {
                    this._mapReady = false;
                    this.map && this.map.remove();
                    this.attachMap();
                }
            } catch {
                //swallow error
            }
        };

        clearTimeout(this._reloadMapDebounce);

        if (immediately) reloadCallback();
        else this._reloadMapDebounce = setTimeout(reloadCallback, 250);
    }

    private resizeMapOnInvalidate = () => this.map && this.map.invalidateSize();

    private _currentPosition: LngLat | undefined;

    private addCurrentPositionMarker() {
        if (this._currentPosition) {
            const currentPositionMarkerHtml = `<i class="material-icons md-24">gps_fixed</i>`;
            const markerIcon = leaflet.divIcon({
                html: currentPositionMarkerHtml,
                className: 'current-position-marker',
                iconSize: [24, 24],
                popupAnchor: [0, -12],
                iconAnchor: [12, 12],
            });
            this._currentPositionMarker = leaflet.marker(new leaflet.LatLng(this._currentPosition[1], this._currentPosition[0]), {
                title: 'Current Position',
                icon: markerIcon,
            });
            this._currentPositionMarker.bindPopup('Current Location');
            this.map.addLayer(this._currentPositionMarker);
        }
    }

    private addInfoMarkers() {
        if (!ApplicationState.spotlessWaterMarkersDisabled) {
            (<any>window).navigateToSiteHook = (siteIndex: number) => this.navigate(siteIndex);
            for (const site of sites) {
                const markerIcon = leaflet.icon({
                    iconUrl: 'images/sites/SW.svg',
                    iconSize: [28, 28],
                    popupAnchor: [0, -19],
                    iconAnchor: [19, 19],
                });
                const sitesLayer = leaflet.marker(new leaflet.LatLng(site.lat, site.lon), {
                    title: site.name,
                    icon: markerIcon,
                });
                const navigate = `<a href="#" onclick="return !!window.navigateToSiteHook(${sites.indexOf(
                    site
                )}) && false;">Navigate Here</a>`;
                sitesLayer.bindPopup(
                    `${site.name}<br>${site.address !== site.name ? site.address : ''}<br>${site.postcode}<br>${
                        site.furtherInfo
                    }<br><br>${navigate}`
                );
                this.map.addLayer(sitesLayer);
            }
        }
    }

    public navigate(siteIndex: number) {
        const site = sites[siteIndex];
        if (site) {
            const location = new Location([site.lon, site.lat], undefined, site.address);
            const dialog = new NavigationDialog(location);
            dialog.showIfNeeded();
        }

        return false;
    }

    private addHomeMarker() {
        const businessAddress = this._applicationState.account.businessAddress;
        if (businessAddress && businessAddress.lngLat && businessAddress.lngLat.length === 2) {
            const markerIconHTML = `<i style="color: #555" class="material-icons md-36">location_on</i>
            <i class="material-icons location-marker-shadow">location_on</i>
            <span style="background-color: #555" class="marker-fill"></span>
            <span class="drive-marker-number">
              <i class="material-icons home-leg-marker">home</i>
            </span>`;
            const markerIcon = leaflet.divIcon({
                html: markerIconHTML,
                className: 'marker',
                iconSize: [38, 38],
                popupAnchor: [0, -36],
                iconAnchor: [18, 36],
            });
            this._homeMarker = leaflet.marker(new leaflet.LatLng(businessAddress.lngLat[1], businessAddress.lngLat[0]), {
                title: businessAddress.addressDescription,
                icon: markerIcon,
            });
            this._homeMarker.bindPopup(businessAddress.addressDescription ? businessAddress.addressDescription : 'Home');
            if (this.homeMarkerClickHandler) this._homeMarker.on('click', this.homeMarkerClickHandler);
            this.map.addLayer(this._homeMarker);
        }
    }

    private _markersById: { [key: string]: leaflet.Marker } = {};
    private _usersById: { [key: string]: leaflet.Marker } = {};

    private createMarkers() {
        this._markersById = {};
        this._usersById = {};
        this.markers = leaflet.markerClusterGroup({
            showCoverageOnHover: true,
            spiderfyDistanceMultiplier: 1.8,
            pane: 'markers',
            disableClusteringAtZoom: 5,
            iconCreateFunction: function (cluster) {
                const colourCounts: { [key: string]: number } = {};
                cluster.getAllChildMarkers().forEach(cm => {
                    if (cm && cm.getAttribution) {
                        const attribution = cm.getAttribution();
                        const colourCount = colourCounts[attribution ? attribution : ''];
                        if (!colourCount) {
                            colourCounts[attribution ? attribution : ''] = 0;
                        }
                        colourCounts[attribution ? attribution : '']++;
                    }
                });
                const icons = [];
                let counter = 0;
                for (const colour in colourCounts) {
                    counter++;
                    const markerIconHTML = `<div style="position: absolute; top: ${counter * 5}px; left: ${counter * 5}px; 
                    width:32px; height: 32px;font-weight: 500;
                    text-align: center;
                    opacity: 0.9;
                    line-height: 30px;
                    font-size: 18px;">
                    <i style="color: ${colour}" class="material-icons md-36">location_on</i>
                       <span style="background-color: ${colour}" class="marker-fill"></span>
                    </div>`;

                    icons.push(markerIconHTML);
                }
                const markerIcon = leaflet.divIcon({
                    html: icons.join(),
                    className: 'marker',
                    iconSize: [38, 38],
                    popupAnchor: [0, 0],
                    iconAnchor: [18, 26],
                });
                return markerIcon;
            },
        });
        this._peopleMarkers = leaflet.markerClusterGroup({
            showCoverageOnHover: false,
            spiderfyDistanceMultiplier: 1.2,
            disableClusteringAtZoom: 10,
            pane: 'people',
        });
    }

    private refreshLocationMarkers() {
        const keep = [];
        this.markers && this.markers.clearLayers();
        this._markersById = {};
        for (let i = 0; i < this.locations.length; i++) {
            const locationItem = this.locations[i];

            const hasScheduleItem = (
                x: LocationWithScheduleItem | RouteScheduleItem | Location
            ): x is LocationWithScheduleItem | RouteScheduleItem => {
                return !!(x as LocationWithScheduleItem | RouteScheduleItem).scheduleItem;
            };

            if (!locationItem.lngLat) continue;

            let colour: string;
            if (!hasScheduleItem(locationItem)) colour = '';
            else if (this.useRoundColour) colour = locationItem.scheduleItem?.roundColour || '';
            else if (this.useLocationStatusColour) colour = locationItem.statusColour || locationItem.avatarColour || '';
            else colour = locationItem.avatarColour || '';

            let markerIconHTML = `<i style="color: ${colour}" class="material-icons md-36">location_on</i>`;

            if (hasScheduleItem(locationItem) && (locationItem.rank || locationItem.icon)) {
                markerIconHTML += `<span style="background-color: ${colour}" class="marker-fill"></span>`;

                markerIconHTML += '<span class="drive-marker-number">';
                if (locationItem.icon) markerIconHTML += `<i class="material-icons">${locationItem.icon}</i>`;
                else if (locationItem.avatar) markerIconHTML += locationItem.avatar;
                markerIconHTML += '</span>';
            }

            const markerIcon = leaflet.divIcon({
                html: markerIconHTML,
                className: !hasScheduleItem(locationItem)
                    ? 'marker'
                    : this.useLocationStatusColour
                    ? locationItem.statusColour || locationItem.avatarColour
                    : locationItem.avatarColour
                    ? 'drive-marker'
                    : 'marker',
                iconSize: [38, 38],
                popupAnchor: [0, -36],
                iconAnchor: [18, 36],
            });

            if (hasScheduleItem(locationItem) && locationItem.scheduleItem && this._markersById[locationItem.scheduleItem.occurrence._id]) {
                const marker = this._markersById[locationItem.scheduleItem.occurrence._id];
                marker.setIcon(markerIcon);
            } else {
                const scheduleItem = hasScheduleItem(locationItem) ? locationItem.scheduleItem : undefined;
                const marker = leaflet.marker(new leaflet.LatLng(locationItem.lngLat[1], locationItem.lngLat[0]), {
                    title: `${scheduleItem?.customer.name || ''}\n${locationItem.addressDescription}\n${scheduleItem?.roundName || ''}\n${
                        scheduleItem?.serviceList
                    }\n${scheduleItem?.frequencyText}`,
                    icon: markerIcon,
                    attribution: !hasScheduleItem(locationItem)
                        ? ''
                        : this.useLocationStatusColour
                        ? locationItem.statusColour || locationItem.avatarColour
                        : locationItem.avatarColour,
                });

                if (this.markers && marker) {
                    this.markers.addLayer(marker);
                    if (hasScheduleItem(locationItem) && locationItem.scheduleItem)
                        this._markersById[locationItem.scheduleItem.occurrence._id] = marker;
                    marker.on('click', () => {
                        if (!hasScheduleItem(locationItem)) return;

                        this.selectedItem = locationItem;

                        if (this.selectItem) this.selectItem(locationItem);
                    });
                }
            }
            if (hasScheduleItem(locationItem) && locationItem.scheduleItem) keep.push(locationItem.scheduleItem.occurrence._id);
        }
    }

    private refreshUserMarkers() {
        if (this.workloads && this.workloads.length) {
            for (let i = 0; i < this.workloads.length; i++) {
                const workload = this.workloads[i];
                const lngLat = workload.getLngLat();
                if (workload && workload.userOrTeam && lngLat) {
                    const markerIconHTML = `<div class="person-marker" style="background-color:${workload.avatarColor}">
                        <span style='color:white'>${workload && workload.userOrTeam ? workload.userOrTeam.name.substr(0, 1) : '?'} </span>
                    </div>`;

                    const markerIcon = leaflet.divIcon({
                        html: markerIconHTML,
                        className: 'marker',
                        iconSize: [46, 46],
                        iconAnchor: [23, 23],
                    });
                    let marker = this._usersById[workload.userOrTeam._id];
                    if (marker) {
                        if (this.showUserMarkers) {
                            marker.setLatLng(new leaflet.LatLng(lngLat[1], lngLat[0]));
                        } else {
                            marker.remove();
                            delete this._usersById[workload.userOrTeam._id];
                        }
                    } else if (this.showUserMarkers) {
                        marker = leaflet.marker(new leaflet.LatLng(lngLat[1], lngLat[0]), {
                            title: ApplicationState.localise('general.last-known-location'),
                            icon: markerIcon,
                        });

                        if (this._peopleMarkers) this._peopleMarkers.addLayer(marker);
                        marker.on('click', () => {
                            this.selectedWorkload = workload;
                        });
                        if (workload.userOrTeam._id) this._usersById[workload.userOrTeam._id] = marker;
                    }
                }
            }
        }
    }

    private async attachMap() {
        await ApplicationState.positionInitialised;

        const mapElement = this.element.querySelector('.map') as HTMLElement;
        if (!mapElement || (mapElement as any)._leaflet_id) return;

        this.map = new leaflet.Map(mapElement, {
            minZoom: this.minZoom,
            maxZoom: this.maxZoom,
            dragging: this.dragging,
            scrollWheelZoom: this.scrollWheelZoom,
            touchZoom: this.touchZoom,
            doubleClickZoom: this.doubleClickZoom,
            boxZoom: this.boxZoom,
            trackResize: this.trackResize,
            tap: this.tap,
            attributionControl: false,
            zoomControl: false,
        });

        const mapLoaded = new Promise<leaflet.Map>(resolve => this.map.on('load', () => resolve(this.map)));

        this.map.createPane('markers');
        const pane = this.map.getPane('markers');
        if (pane) pane.style.zIndex = '550';

        this.map.createPane('people');
        const pane2 = this.map.getPane('people');
        if (pane2) pane2.style.zIndex = '600';

        this.createMarkers();
        this.refreshLocationMarkers();
        this.refreshUserMarkers();
        this.addInfoMarkers();

        let currentLocation = this.getStartingLocation();
        const zoom = currentLocation ? 12 : 3;
        if (!currentLocation) currentLocation = { lat: 51, lng: -2 };

        const ready = new Promise<void>(resolve => this.map.once('moveend zoomend', () => resolve()));

        this.map.on('baselayerchange', e => SqueegeeLocalStorage.setItem(`map-${this.name}-default-layer`, (e && (e as any).name) || null));

        const normalDay = new leaflet.TileLayer(
            'https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/512/png8?apiKey=U7VG3YF0RPY6AJHRwvdWbRN4vfw3N-4mRTnRq8fHj-k&ppi=250',
            { maxZoom: 18 }
        );
        const satelliteDay = new leaflet.TileLayer(
            'https://1.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/512/png8?apiKey=U7VG3YF0RPY6AJHRwvdWbRN4vfw3N-4mRTnRq8fHj-k',
            { maxZoom: 18 }
        );
        const hybridDay = new leaflet.TileLayer(
            'https://1.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/512/png8?apiKey=U7VG3YF0RPY6AJHRwvdWbRN4vfw3N-4mRTnRq8fHj-k&ppi=250',
            { maxZoom: 18 }
        );
        const terrainDay = new leaflet.TileLayer(
            'https://1.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/terrain.day/{z}/{x}/{y}/512/png8?apiKey=U7VG3YF0RPY6AJHRwvdWbRN4vfw3N-4mRTnRq8fHj-k&ppi=250',
            { maxZoom: 18 }
        );
        const normalDayGrey = new leaflet.TileLayer(
            'https://1.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day.grey/{z}/{x}/{y}/512/png8?apiKey=U7VG3YF0RPY6AJHRwvdWbRN4vfw3N-4mRTnRq8fHj-k&ppi=250',
            { maxZoom: 18 }
        );
        const normalDayTransit = new leaflet.TileLayer(
            'https://1.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day.transit/{z}/{x}/{y}/512/png8?apiKey=U7VG3YF0RPY6AJHRwvdWbRN4vfw3N-4mRTnRq8fHj-k&ppi=250',
            { maxZoom: 18 }
        );
        const reducedDay = new leaflet.TileLayer(
            'https://1.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/reduced.day/{z}/{x}/{y}/512/png8?apiKey=U7VG3YF0RPY6AJHRwvdWbRN4vfw3N-4mRTnRq8fHj-k&ppi=250',
            { maxZoom: 18 }
        );
        const normalNight = new leaflet.TileLayer(
            'https://1.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.night/{z}/{x}/{y}/512/png8?apiKey=U7VG3YF0RPY6AJHRwvdWbRN4vfw3N-4mRTnRq8fHj-k&ppi=250',
            { maxZoom: 18 }
        );
        const reducedNight = new leaflet.TileLayer(
            'https://1.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/reduced.night/{z}/{x}/{y}/512/png8?apiKey=U7VG3YF0RPY6AJHRwvdWbRN4vfw3N-4mRTnRq8fHj-k&ppi=250',
            { maxZoom: 18 }
        );
        const pedestrianDay = new leaflet.TileLayer(
            'https://1.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/pedestrian.day/{z}/{x}/{y}/512/png8?apiKey=U7VG3YF0RPY6AJHRwvdWbRN4vfw3N-4mRTnRq8fHj-k&ppi=250',
            { maxZoom: 18 }
        );

        const layers = {
            'Normal Day': normalDay,
            'Satellite Day': satelliteDay,
            'Hybrid Day': hybridDay,
            'Terrain Day': terrainDay,
            'Normal Day Grey': normalDayGrey,
            'Normal Day Transit': normalDayTransit,
            'Reduced Day': reducedDay,
            'Normal Night': normalNight,
            'Reduced Night': reducedNight,
            'Pedestrian Day': pedestrianDay,
        } as leaflet.Control.LayersObject;

        const currentLayerName = SqueegeeLocalStorage.getItem(`map-${this.name}-default-layer`);
        const currentLayer = (currentLayerName && layers[currentLayerName]) || pedestrianDay;

        this.map.addLayer(currentLayer);

        leaflet.control.layers(layers).addTo(this.map);

        if (this.markers) this.map.addLayer(this.markers);
        if (this._peopleMarkers) this.map.addLayer(this._peopleMarkers);

        if (this.showCurrentPosition && this._currentPosition) this.addCurrentPositionMarker();
        if (this.showHome) this.addHomeMarker();

        this.map.setView(currentLocation, zoom);

        this.addDrawingTools();

        (await mapLoaded) && (await ready);

        this._mapReady = true;
        this.mapRef = this.map;

        this.fitMapToLocationsBounds();
    }

    private getStartingLocation(): leaflet.LatLngExpression | undefined {
        if (this._currentPosition) {
            return { lat: this._currentPosition[1], lng: this._currentPosition[0] };
        } else if (
            ApplicationState.account.businessAddress &&
            ApplicationState.account.businessAddress.isVerified &&
            ApplicationState.account.businessAddress.lngLat
        ) {
            return { lat: ApplicationState.account.businessAddress.lngLat[1], lng: ApplicationState.account.businessAddress.lngLat[0] };
        }
    }

    protected toggleFullscreen() {
        this.fullscreen = !this.fullscreen;
        if (this.fullscreen) {
            this.map.scrollWheelZoom.enable();
            this.map.touchZoom.enable();
            this.map.dragging.enable();
            this.map.boxZoom.enable();
            this.map.doubleClickZoom.enable();
            this.map.tap && this.map.tap.enable();
        } else {
            this.map.scrollWheelZoom.disable();
            this.map.touchZoom.disable();
            this.map.dragging.disable();
            this.map.boxZoom.disable();
            this.map.doubleClickZoom.disable();
            this.map.tap && this.map.tap.disable();
        }

        clearTimeout(this._toggleResizeUpdateTimeout);
        this._toggleResizeUpdateTimeout = setTimeout(() => {
            try {
                this.map && this.map.invalidateSize();
                this.showCurrentPosition ? this.fitMapToCurrentPositionAndNextWayPoint() : this.fitMapToLocationsBounds();
            } catch {
                //swallow error
            }
        }, 0);
    }

    private _locationsAsGeoJSONFeatureCollection: Array<LngLat>;
    private updateGeoJSONFeatures() {
        const locationsAsGeoJSON = [];
        if (this.locations && this.locations.length) {
            for (let i = 0; i < this.locations.length; i++) {
                const location = this.locations[i];
                if (location && location.lngLat && location.lngLat.length === 2) {
                    locationsAsGeoJSON.push(location.lngLat);
                }
            }
        }
        this._locationsAsGeoJSONFeatureCollection = locationsAsGeoJSON;
        return this._locationsAsGeoJSONFeatureCollection;
    }

    protected fitMapToCurrentPositionAndNextWayPoint() {
        if (this._currentPosition && this.markers && this.selectedItem) {
            const currenPositionAsGeoJSONPoint = this._currentPosition;
            const nextWayPointLngLat = this.selectedItem.lngLat;
            const points: Array<LngLat> = [];

            if (currenPositionAsGeoJSONPoint) points.push(currenPositionAsGeoJSONPoint);
            if (nextWayPointLngLat) {
                points.push(nextWayPointLngLat);
            }

            if (points.length) {
                this.fitMapToGeoJSONPointFeatureCollectionBBox(points);
            }
        }
    }

    public async fitMapToLocationsBounds() {
        if (this.locations && this.locations.length && this._mapReady) {
            await animate();
            const locationsAsGeoJSONCollection = this.updateGeoJSONFeatures();
            this.fitMapToGeoJSONPointFeatureCollectionBBox(locationsAsGeoJSONCollection);
        }
    }

    private fitMapToGeoJSONPointFeatureCollectionBBox(featureCollection: Array<LngLat>) {
        if (featureCollection && featureCollection.length) {
            const bobox = bbox(multiPoint(featureCollection));
            if (
                bobox &&
                bobox.length &&
                bobox.length === 4 &&
                !isNaN(bobox[0]) &&
                !isNaN(bobox[1]) &&
                !isNaN(bobox[2]) &&
                !isNaN(bobox[3])
            ) {
                const latLngBounds = new leaflet.LatLngBounds(
                    new leaflet.LatLng(bobox[1], bobox[0]),
                    new leaflet.LatLng(bobox[3], bobox[2])
                );

                clearTimeout(this._fitMapToGeoJSONTimeout);
                this._fitMapToGeoJSONTimeout = setTimeout(() => {
                    try {
                        this.map.fitBounds(latLngBounds, { maxZoom: 14, padding: [50, 50] });
                    } catch {
                        // swallow error
                    }
                }, 250);
            }
        }
    }

    protected zoomCurrentPosition() {
        if (this._currentPosition) {
            this.map.setView({ lat: this._currentPosition[1], lng: this._currentPosition[0] }, 16);
        }
    }

    public panToHome() {
        if (this.showHome && this._homeMarker) this.map.panTo(this._homeMarker.getLatLng());
    }

    private _drawings = new Array<any>();
    protected addDrawingTools() {
        if (!this.enableDrawing) return;

        const drawingGroup = leaflet.featureGroup();

        const options: leaflet.Control.DrawConstructorOptions = {
            position: 'topleft',
            draw: {
                polygon: {
                    allowIntersection: false,
                    drawError: {
                        color: '#ff4081',
                        message: "<strong>Oh snap!<strong> you can't draw that, you've crossed the streams!",
                    },
                    shapeOptions: {
                        color: '#bada55',
                    },
                },
                rectangle: {},
                circlemarker: false,
                marker: false,
                polyline: false,
                circle: false,
            },
            edit: {
                featureGroup: drawingGroup,
            },
        };

        const drawControl = new leaflet.Control.Draw(options);

        const map = this.map;

        map.addControl(drawControl);

        map.addLayer(drawingGroup);

        map.on(leaflet.Draw.Event.DELETED, (e: leaflet.DrawEvents.Deleted) => {
            const deleted = e.layers.getLayers();
            this._drawings = this._drawings.filter(d => !deleted.includes(d));

            for (const drawing of deleted) drawing.remove();

            const markers = [] as Array<any>;

            if (Array.isArray(this.location)) {
                for (const location of this.location) {
                    const lngLat = (location as ScheduleItem).occurrence?.location?.lngLat;
                    if (lngLat) {
                        for (const polygon of drawingGroup.getLayers()) {
                            const coordinates = (polygon as any)?.toGeoJSON?.()?.geometry?.coordinates?.[0];
                            if (!coordinates) continue;

                            if (this.isMarkerInsidePolygon(new leaflet.LatLng(lngLat[0], lngLat[1]), coordinates)) {
                                if (!markers.includes(location)) markers.push(location);
                            }
                        }
                    }
                }
            }

            this.selectItems?.(markers);
        });

        this.refreshSelected = () => {
            const markers = [] as Array<any>;

            if (Array.isArray(this.location)) {
                for (const location of this.location) {
                    const lngLat = (location as ScheduleItem).occurrence?.location?.lngLat;
                    if (lngLat) {
                        for (const polygon of drawingGroup.getLayers()) {
                            const coordinates = (polygon as any)?.toGeoJSON?.()?.geometry?.coordinates?.[0];
                            if (!coordinates) continue;

                            if (this.isMarkerInsidePolygon(new leaflet.LatLng(lngLat[0], lngLat[1]), coordinates)) {
                                if (!markers.includes(location)) markers.push(location);
                            }
                        }
                    }
                }
            }

            this.selectItems?.(markers);
        };

        const createDrawnArea = (leafletEvent: leaflet.DrawEvents.Created) => {
            const layer = leafletEvent.layer;
            if (!layer) return;
            this._drawings.push(layer);
            drawingGroup.addLayer(layer);
            this.fitMapToLocationsBounds();
        };

        if (isDevMode()) {
            map.on(leaflet.Draw.Event.CREATED, () => Logger.info('Draw event: CREATED'));
            map.on(leaflet.Draw.Event.DELETED, () => Logger.info('Draw event: DELETED'));
            map.on(leaflet.Draw.Event.DELETESTART, () => Logger.info('Draw event: DELETESTART'));
            map.on(leaflet.Draw.Event.DELETESTOP, () => Logger.info('Draw event: DELETESTOP'));
            map.on(leaflet.Draw.Event.DRAWSTART, () => Logger.info('Draw event: DRAWSTART'));
            map.on(leaflet.Draw.Event.DRAWSTOP, () => Logger.info('Draw event: DRAWSTOP'));
            map.on(leaflet.Draw.Event.DRAWVERTEX, () => Logger.info('Draw event: DRAWVERTEX'));
            map.on(leaflet.Draw.Event.EDITED, () => Logger.info('Draw event: EDITED'));
            map.on(leaflet.Draw.Event.EDITMOVE, () => Logger.info('Draw event: EDITMOVE'));
            map.on(leaflet.Draw.Event.EDITRESIZE, () => Logger.info('Draw event: EDITRESIZE'));
            map.on(leaflet.Draw.Event.EDITSTART, () => Logger.info('Draw event: EDITSTART'));
            map.on(leaflet.Draw.Event.EDITSTOP, () => Logger.info('Draw event: EDITSTOP'));
            map.on(leaflet.Draw.Event.EDITVERTEX, () => Logger.info('Draw event: EDITVERTEX'));
            map.on(leaflet.Draw.Event.MARKERCONTEXT, () => Logger.info('Draw event: MARKERCONTEXT'));
            map.on(leaflet.Draw.Event.TOOLBARCLOSED, () => Logger.info('Draw event: TOOLBARCLOSED'));
            map.on(leaflet.Draw.Event.TOOLBAROPENED, () => Logger.info('Draw event: TOOLBAROPENED'));
            map.on(leaflet.Draw.Event.EDITED, () => Logger.info('Draw event: EDITED'));
            map.on(leaflet.Draw.Event.CREATED, () => Logger.info('Draw event: CREATED'));
        }

        map.on(leaflet.Draw.Event.TOOLBARCLOSED, this.refreshSelected);
        map.on(leaflet.Draw.Event.CREATED, createDrawnArea);

        this.clearSelected = () => {
            for (const drawing of this._drawings) drawing.remove();
            this._drawings = [];
            drawingGroup.clearLayers();
            this.selectItems?.([]);
        };
    }

    private isMarkerInsidePolygon(latLng: leaflet.LatLng, coordinates: Array<[number, number]>) {
        const point = [latLng.lat, latLng.lng];
        return pointInPolygon(point, coordinates);
    }
}
