import type { Customer, Tag } from '@nexdynamic/squeegee-common';
import { FrequencyType, Job, JobGroup, Location } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { parse } from 'papaparse';
import { CustomerService } from '../Customers/CustomerService';
import type { GeorgeRecord } from './GeorgeRecord';

export class GeorgeParser {
    private static DEFAULT_NAME = 'Unknown';
    private static OUTPUT_DATE_FORMAT = 'YYYY-MM-DD';

    public static readonly warnings: Array<string> = [];

    public static parseCSV(csv: string) {
        return parse(csv, { header: true, skipEmptyLines: true });
    }

    public static convertRecord(record: GeorgeRecord, rounds: { [id: string]: JobGroup }, service: Tag, dateFormat: string) {
        const customer = CustomerService.create();

        customer.name = GeorgeParser.getName(record);
        customer.address.addressDescription = GeorgeParser.getAddressDescription(record);
        customer.telephoneNumber = GeorgeParser.getTelephoneNumber(record);
        customer.email = GeorgeParser.getEmail(record);
        customer.jobs = GeorgeParser.getJobs(record, customer, rounds, service, dateFormat);

        return customer;
    }

    private static getValue(record: GeorgeRecord, keys: Array<keyof GeorgeRecord>) {
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            (<any>key) = key.trim();
            if (record[key] && record[key].trim()) return record[key].trim();
        }

        return '';
    }

    private static getName(record: GeorgeRecord): string {
        const title = GeorgeParser.getValue(record, ['Job Title', 'Invoice Title']);
        const firstName = GeorgeParser.getValue(record, ['Job Forename', 'Invoice Forename']);
        const lastName = GeorgeParser.getValue(record, ['Job Surname', 'Invoice Surname']);

        if (!firstName && !lastName) return GeorgeParser.DEFAULT_NAME;

        return `${title}${firstName ? ' ' + firstName : ''}${lastName ? ' ' + lastName : ''}`.trim();
    }

    public static getAddressDescription(record: GeorgeRecord): string {
        const houseName = GeorgeParser.getValue(record, ['Invoice HouseNo/Name', 'Job HouseNo/Name']);
        const postCode = GeorgeParser.getValue(record, ['Invoice PostCode'] || record['Job PostCode'] || '').trim();
        const address1 = GeorgeParser.getValue(record, ['Invoice Address1', 'Job Address1']);
        const address2 = GeorgeParser.getValue(record, ['Invoice Address2', 'Job Address2']);
        const address3 = GeorgeParser.getValue(record, ['Invoice Address3', 'Job Address3']);
        const address4 = GeorgeParser.getValue(record, ['Invoice Address4', 'Job Address4']);
        const address5 = GeorgeParser.getValue(record, ['Invoice Address5', 'Job Address5']);
        const address6 = GeorgeParser.getValue(record, ['Invoice Address6', 'Job Address6']);
        const addressLine = `${address1}${address2 ? ' ' + address2 : ''}${address3 ? ' ' + address3 : ''}${address4 ? ' ' + address4 : ''
            }${address5 ? ' ' + address5 : ''}${address6 ? ' ' + address6 : ''}`.trim();

        const finalAddress = `${houseName}${addressLine ? ' ' + addressLine : ''}${postCode ? ' ' + postCode : ''}`;
        return finalAddress && finalAddress.length ? finalAddress : 'Unknown address';
    }

    public static getJobAddressDescription(record: GeorgeRecord) {
        const houseName = GeorgeParser.getValue(record, ['Job HouseNo/Name', 'Invoice HouseNo/Name']);
        const postCode = GeorgeParser.getValue(record, ['Job PostCode', 'Invoice PostCode']);

        const address1 = GeorgeParser.getValue(record, ['Job Address1', 'Invoice Address1']);
        const address2 = GeorgeParser.getValue(record, ['Job Address2', 'Invoice Address2']);
        const address3 = GeorgeParser.getValue(record, ['Job Address3', 'Invoice Address3']);
        const address4 = GeorgeParser.getValue(record, ['Job Address4', 'Invoice Address4']);
        const address5 = GeorgeParser.getValue(record, ['Job Address5', 'Invoice Address5']);
        const address6 = GeorgeParser.getValue(record, ['Job Address6', 'Invoice Address6']);
        const addressLine = `${address1}${address2 ? ' ' + address2 : ''}${address3 ? ' ' + address3 : ''}${address4 ? ' ' + address4 : ''
            }${address5 ? ' ' + address5 : ''}${address6 ? ' ' + address6 : ''}`.trim();

        const finalAddress = `${houseName}${addressLine ? ' ' + addressLine : ''}${postCode ? ' ' + postCode : ''}`;
        return finalAddress && finalAddress.length ? finalAddress : 'Unknown address';
    }

    public static getTelephoneNumber(record: GeorgeRecord): string {
        return GeorgeParser.getValue(record, ['Invoice Phone1', 'Job Phone1', 'Invoice Phone2', 'Job Phone2']);
    }

    private static getJobs(record: GeorgeRecord, customer: Customer, rounds: { [id: string]: JobGroup }, service: Tag, dateFormat: string) {
        const date = GeorgeParser.getDate(record, dateFormat);
        if (!date) {
            GeorgeParser.warnings.push(`Job for '${customer.name}' did not have a valid next due date`);
            return {};
        }

        const jobAddressDescription = GeorgeParser.getJobAddressDescription(record);
        const job = new Job(
            customer._id,
            undefined,
            undefined,
            undefined,
            customer.address.addressDescription === jobAddressDescription
                ? customer.address
                : new Location(undefined, undefined, jobAddressDescription)
        );

        // TODO: Make sure this sets the day of week on the job to the same as the job date.
        job.date = date;
        job.description = GeorgeParser.getValue(record, ['Notes']);
        const price = record['Price'].trim().replace(',', '');
        if (isNaN(Number(price))) {
            throw new Error(price + ' is not a valid price');
        }
        job.price = Number(price);
        if (record.Round) job.rounds = GeorgeParser.getRounds(record, rounds);
        job.services.push(service);

        const frequency = record.Frequency.match(/(\d+)\s*?(\w+)\(s\)(?:.*?on\s*?(\w+))?/);

        if (frequency && frequency.length >= 3) {
            job.frequencyInterval = Number(frequency[1]);
            job.frequencyType = this.getFrequencyType(frequency[2], job.frequencyInterval, frequency[3]);
        } else {
            job.frequencyInterval = 0;
            job.frequencyType = FrequencyType.NoneRecurring;
        }
        return { [job._id]: job };
    }

    private static getDate(record: GeorgeRecord, dateFormat: string) {
        const dateString = GeorgeParser.getValue(record, ['NextDue']);
        if (!dateString || !dateString.length) return '';
        const date = moment(dateString, dateFormat);
        if (date.isValid()) {
            return date.format(GeorgeParser.OUTPUT_DATE_FORMAT);
        } else {
            throw new Error(dateString + ' is not a valid date format');
        }
    }

    public static getFrequencyType(type: string, interval: number, dayOfWeek?: string): FrequencyType {
        const frequency = type.toLowerCase();
        switch (frequency) {
            case 'day':
                return FrequencyType.Days;
            case 'week':
                return FrequencyType.Weeks;
            case 'month':
                if (dayOfWeek) {
                    return FrequencyType.MonthlyDayOfWeek;
                } else {
                    return FrequencyType.DayOfMonth;
                }
            case 'year':
                interval = interval * 12;
                return FrequencyType.DayOfMonth;
            default:
                return FrequencyType.NoneRecurring;
        }
    }

    private static getRounds(record: GeorgeRecord, rounds: { [id: string]: JobGroup }): Array<JobGroup> {
        const newRounds: Array<JobGroup> = [];

        if (rounds[record.Round]) {
            newRounds.push(rounds[record.Round]);
        } else {
            const tag = new JobGroup(record.Round);
            newRounds.push(tag);
            rounds[record.Round] = tag;
        }

        return newRounds;
    }

    private static getEmail(record: GeorgeRecord) {
        return GeorgeParser.getValue(record, ['Invoice eMail', 'Job eMail']);
    }
}
