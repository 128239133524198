import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { Logger } from '../Logger';
import { CustomDialog } from './CustomDialog';
import './MultipleInputDialog.scss';

export class MultipleInputDialog extends CustomDialog<Array<{ key: string; value: string }>> {
    protected options: Array<{ key: string; value: string }>;

    constructor(title: TranslationKey, options: Array<{ key: string; value: string }>) {
        super('superduperfirst', './MultipleInputDialog.html', '', {
            cancelLabel: '',
            okLabel: '',
            isSecondaryView: true,
        });

        this.title = title;
        this.options = options;
    }

    public async init() {
        Logger.info('MultipleInputDialog', this.options);
        Logger.info('MultipleInputDialog', 'init');
    }

    public async save() {
        Logger.info('MultipleInputDialog', 'save');
        return this.ok(this.options);
    }

    public async close() {
        Logger.info('MultipleInputDialog', 'close');
        return this.cancel();
    }

    public async getResult(): Promise<{ key: string; value: string }[] | undefined> {
        return this.options;
    }
}
