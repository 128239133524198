import type { TranslationKey } from '@nexdynamic/squeegee-common';
import type { ItemActionType } from '../Actions/IListItemAction';
import type { IFabAction } from '../Components/Fabs/IFabAction';

export class DialogSettingsBase {
    public constructor() {
        (window as any).dialog = this;
    }
    idOverride?: string;
    okLabel?: '' | TranslationKey = 'general.ok';
    cancelLabel?: '' | TranslationKey = 'general.cancel';
    cssClass?: string;
    disableScroll?: boolean;
    fab?: IFabOptions;
    allowCancel?: boolean = true;
    localisationParams?: Record<string, string>;
    subtitle?: TranslationKey;
    altLabel?: '' | TranslationKey = '';
    destructive?: boolean = false;
    showCloseX?: boolean = false;
    zIndex?: number;
}

export class DialogSettingsSecondaryView extends DialogSettingsBase {
    isSecondaryView: true;
    noObfuscator?: never;
    coverViewport?: never;
    coverViewportNoAnimation?: never;
    smallerOnDesktop?: never;
    allowClickOff?: never;
}

export class DialogSettingsViewport extends DialogSettingsBase {
    noObfuscator?: boolean;
    coverViewport?: boolean;
    coverViewportNoAnimation?: boolean;
    smallerOnDesktop?: boolean;
    allowClickOff?: boolean;
    isSecondaryView?: never;
}

export interface IFabOptions {
    actionType?: ItemActionType;
    icon?: string;
    action?: () => void;
    actions?: Array<IFabAction>;
}
