import type { IFabAction, TranslationKey } from '@nexdynamic/squeegee-common';
import { uuid } from '@nexdynamic/squeegee-common';
import type { Subscription } from 'aurelia-event-aggregator';
import { computedFrom } from 'aurelia-framework';
import { ApplicationState } from '../ApplicationState';
import { CloseDialogKeyboardEvent } from '../Events/CloseDialogKeyboardEvent';
import { InvalidateMapSizesEvent } from '../Events/InvalidateMapSizesEvent';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { DialogAnimation } from './DialogAnimation';
import type { DialogSettingsSecondaryView, DialogSettingsViewport } from './DialogSettings';
import { DialogSettingsBase } from './DialogSettings';

export interface CustomDialogBase<TPromiseReturnType> {
    getResult?(): Promise<TPromiseReturnType | undefined>;
    onCancel?(): Promise<boolean>;
    onValidate?(ok?: TPromiseReturnType): Promise<boolean | TranslationKey>;
    init?(): Promise<void>;
    abortCancel(): Promise<boolean>;
    visible?: boolean;
    noReplaceSameType?: boolean;
    fabActions?: Array<IFabAction>;
}

export abstract class CustomDialogBase<TPromiseReturnType> {
    @computedFrom('ApplicationState.stateFlags.devMode')
    public get devMode() {
        return ApplicationState.stateFlags.devMode;
    }
    public viewAsJson = (data: any) => ApplicationState.viewAsJson(data);
    public animationType: DialogAnimation;
    public nextDialog: CustomDialogBase<any>;
    public showNextDialog?: (result: TPromiseReturnType | undefined) => Promise<boolean>;
    public beforeNextDialog?: () => Promise<void>;
    public get cancelled() {
        return this._cancelled;
    }

    public error: TranslationKey | boolean;
    public isActive = true;
    public _settings: DialogSettingsSecondaryView | DialogSettingsViewport = new DialogSettingsBase();
    private _id: string;
    protected _result: Promise<TPromiseReturnType>;
    protected _cancelled = false;
    protected _resolver: (result?: TPromiseReturnType | any) => void;
    public element: HTMLDivElement | undefined;

    private _closeKeyboardSub: Subscription;
    public constructor(
        public description: string,
        public template: string | undefined,
        public title: TranslationKey | '' | undefined,
        settings?: DialogSettingsViewport | DialogSettingsSecondaryView
    ) {
        this._id = (settings && settings.idOverride) || uuid();
        settings && Object.assign(this._settings, settings);

        this._result = new Promise<TPromiseReturnType | undefined | any>(resolve => (this._resolver = resolve));

        history.pushState(null, '', document.URL);
        if (!settings || settings.allowCancel !== false) {
            this.attachCloseKey();
        }
    }

    public attachCloseKey() {
        this._closeKeyboardSub && this._closeKeyboardSub.dispose();
        this._closeKeyboardSub = CloseDialogKeyboardEvent.subscribe(async (event: CloseDialogKeyboardEvent) => {
            if (!event.handled) {
                event.handled = true;
                if (!(this._settings as DialogSettingsSecondaryView).isSecondaryView || this.isActive) {
                    this.cancel();
                }
            }
        });
    }

    public get id() {
        return this._id;
    }

    public async show(animation = DialogAnimation.SLIDE) {
        this.animationType = animation;
        window.sq.showDialog && window.sq.showDialog(this);
        return this._result;
    }

    public async forceShow(animation = DialogAnimation.SLIDE) {
        this.animationType = animation;

        window.sq.showDialog && window.sq.showDialog(this);

        return this._result;
    }

    public async ok(result?: TPromiseReturnType): Promise<void> {
        // wtf: reset maps whenever dialog is closed, in case we need to load missing tiles
        setTimeout(() => new InvalidateMapSizesEvent(), 250);

        if (this.onValidate) {
            this.error = await this.onValidate(result);
            if (this.error !== true) {
                if (typeof this.error === 'string') {
                    new NotifyUserMessage(this.error);
                }
                return;
            }
        }

        this.beforeNextDialog && (await this.beforeNextDialog());

        if (this.nextDialog && (!this.showNextDialog || (await this.showNextDialog(this.getResult && (await this.getResult()))))) {
            this.animationType = DialogAnimation.NONE;

            setTimeout(() => this.element && (this.element.style.display = 'none'), 250);

            await this.nextDialog.show(DialogAnimation.SLIDE);

            if (this.nextDialog.cancelled) this._cancelled = true;
        }

        window.sq.closeDialog && window.sq.closeDialog(this, async () => this._resolver(this.getResult ? await this.getResult() : result));
    }

    /**
     * Returns a promise of type boolean if true cancel was completed otherwise cancel was not completed
     * @returns {Promise<boolean>}
     * @memberof CustomDialogBase
     */
    public async cancel(autoClosed?: boolean): Promise<boolean> {
        // wtf: reset maps whenever dialog is closed, in case we need to load missing tiles
        setTimeout(() => new InvalidateMapSizesEvent(), 250);

        return new Promise<boolean>(async resolve => {
            if (this.abortCancel && (await this.abortCancel())) {
                this._cancelled = false;
                return resolve(false);
            }

            this._cancelled = true;

            if (this.onCancel) {
                const canCancel = await this.onCancel();
                if (canCancel) {
                    window.sq.closeDialog &&
                        window.sq.closeDialog(
                            this,
                            () => {
                                resolve(true);
                                this._resolver(false);
                            },
                            autoClosed
                        );
                } else {
                    this._cancelled = false;
                    resolve(false);
                }
            } else {
                window.sq.closeDialog &&
                    window.sq.closeDialog(
                        this,
                        () => {
                            resolve(true), this._resolver(false);
                        },
                        autoClosed
                    );
            }
        });
    }

    public dispose() {
        delete this.element;
        this._closeKeyboardSub && this._closeKeyboardSub.dispose();
    }

    fabActions?: Array<IFabAction> = [];
    public createFab(actions: Array<IFabAction>) {
        this._settings.fab = { actions: actions.slice() };
        this.fabActions = actions.slice();
    }
}
