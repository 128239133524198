import { PLATFORM } from 'aurelia-framework';
import type { IRoute } from '../IRoute';

export const routes: Array<IRoute> = [
    {
        route: '',
        name: '',
        title: '',
        moduleId: PLATFORM.moduleName('Views/DefaultChildView'),
        nav: false,
        redirect: 'reports',
        settings: {
            roles: ['Owner', 'Admin'],
        },
    },
    {
        route: 'customers',
        name: 'customers',
        moduleId: PLATFORM.moduleName('Insights/Views/Customers'),
        nav: false,
        title: 'route-config.insights-customers',
        settings: {
            roles: ['Owner', 'Admin'],
        },
    },
    {
        route: 'jobs',
        name: 'jobs',
        moduleId: PLATFORM.moduleName('Insights/Views/Jobs'),
        nav: false,
        title: 'route-config.insights-jobs',
        settings: {
            roles: ['Owner', 'Admin'],
        },
    },
    {
        route: 'financials',
        name: 'financials',
        moduleId: PLATFORM.moduleName('Insights/Views/Financials'),
        nav: false,
        title: 'route-config.insights-financials',
        settings: {
            roles: ['Owner', 'Admin'],
        },
    },
    {
        route: 'reports',
        name: 'reports',
        moduleId: PLATFORM.moduleName('Insights/Views/Reports'),
        nav: false,
        title: 'route-config.insights-reports',
        settings: {
            roles: ['Owner', 'Admin', 'Reporting'],
        },
    },
];
