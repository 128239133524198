import { LoaderEvent } from '../Events/LoaderEvent';
import { Logger } from '../Logger';
import type { ScheduleMessageDialogOptions } from '../ReactUI/scheduleMessages/ScheduleMessagesDialog';
import { ScheduleMessageDialogComponent } from '../ReactUI/scheduleMessages/ScheduleMessagesDialog';
import { AureliaReactComponentDialog } from './AureliaReactComponentDialog';



export const getSecondsTimestampForSchedule = async (options?: ScheduleMessageDialogOptions) => {
    try {
        new LoaderEvent(false);
        const aureliaReactComponentDialog = new AureliaReactComponentDialog<number, ScheduleMessageDialogOptions>({
            dialogTitle: 'general.datetime',
            isSecondaryView: true,
            component: ScheduleMessageDialogComponent,
            componentProps: options,
        });
        const sendAtMillisecondsTimestamp = await aureliaReactComponentDialog.show();
        if (aureliaReactComponentDialog.cancelled) return;
        return Math.floor(sendAtMillisecondsTimestamp / 1000);
    } catch {
        Logger.error('Error in scheduleReminders() on SelectAppointmentForNotification');
    }

};
