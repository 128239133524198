import { CustomDialog } from './CustomDialog';
import './LauncherCommandListDialog.scss';

export class LauncherCommandListDialog extends CustomDialog<string> {
    constructor() {
        super('LauncherCommandListDialog', './LauncherCommandListDialog.html', 'launcher.list-title', {
            coverViewport: true,
            cssClass: 'launcher-list-dialog',
        });
    }
}
