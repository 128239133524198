import { CustomDialog } from '../Dialogs/CustomDialog';
import { DateRangeDialog } from '../Dialogs/DateRange/DateRangeDialog';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { Select } from '../Dialogs/Select';
import { SelectMultiple } from '../Dialogs/SelectMultiple';
import { Logger } from '../Logger';
import type { Filter, FilterItemDictionary, IFilterItem, IOption } from './Filter';

export class FiltersDialog<TFilterDictionary extends FilterItemDictionary> extends CustomDialog<Filter<TFilterDictionary>> {
    constructor(
        public filter: Filter<TFilterDictionary>,
        public getFilterFunction: (args?: { [key: string]: any }) => Filter<TFilterDictionary>,
        public filterArgs?: { [key: string]: any }
    ) {
        super('filtersDialog', '../Filters/FiltersDialog.html', '', {
            cancelLabel: '',
            okLabel: '',
            cssClass: 'filters-dialog',
            allowClickOff: true,
        });
        this.updateItems();
    }

    private updateItems() {
        const filterItems: Array<IFilterItem> = [];
        for (const key in this.filter.filterOptions) {
            const item = this.filter.filterOptions[key];
            filterItems.push(item);
        }
        this.filterItems = filterItems;
    }

    public filterItems: Array<IFilterItem> = [];

    protected selectBtn(item: IFilterItem, option: IOption) {
        item.selectedOption = option;
    }

    protected async openDateRange(item: IFilterItem) {
        try {
            const dialog = new DateRangeDialog(
                item.selectedOption ? item.selectedOption[item.optionValueKey] : undefined,

                {
                    cssClass: 'date-range-dialog filter-select-dialog',
                    okLabel: '',
                    cancelLabel: '',
                    allowClickOff: true,
                }
            );
            const value = await dialog.show(DialogAnimation.SLIDE);
            if (value && !dialog.cancelled) {
                item.selectedOption = {
                    [item.optionNameKey]: value.rangeText,
                    [item.optionValueKey]: value,
                };
            }
            this.filter.update();
        } catch (error) {
            Logger.error('Error in openDateRange() on FiltersDialog', error);
        }
    }

    protected async openSortBy(item: IFilterItem) {
        const dialog = new Select<IOption>(
            item.name || 'general.select',
            item.options,
            item.optionNameKey,
            item.optionValueKey,
            item.selectedOption ? item.selectedOption[item.optionValueKey] : null,
            { okLabel: '', cancelLabel: '', cssClass: 'select-dialog filter-select-dialog', allowClickOff: true }
        );

        const newSelectedOption = await dialog.show(DialogAnimation.SLIDE);
        item.selectedOption = newSelectedOption;
    }

    protected async openFilter(item: IFilterItem) {
        const dialog = new SelectMultiple<IOption>(
            item.name || 'general.select',
            item.options,
            item.optionNameKey,
            item.optionValueKey,
            item.selectedOptions,

            { okLabel: '', cancelLabel: '', cssClass: 'select-dialog filter-select-dialog', allowClickOff: true }
        );

        await dialog.show(DialogAnimation.SLIDE);
        item.selectedOptions = await dialog.getResult();

        this.filter.update();
    }

    protected clear() {
        this.filter = this.getFilterFunction(this.filterArgs);
        this.filter.update();
        this.updateItems();
    }

    public async getResult() {
        return this.filter;
    }
}
