// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.html-content {
  position: relative;
  height: 100%;
  width: 100%;
  display: block;
  box-sizing: border-box;
}
.html-content iframe {
  border-radius: 6px;
  display: block;
  background: #fff;
  border: none;
  height: 100%;
  width: 100%;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 1px -1px, rgba(180, 180, 180, 0.1) 0px 1px 1px 0px, rgba(180, 180, 180, 0.1) 0px 1px 3px 0px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Html/html-content.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,YAAA;EACA,WAAA;EACA,cAAA;EACA,sBAAA;AACJ;AAAI;EACI,kBAAA;EACA,cAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,mIAAA;AAER","sourcesContent":[".html-content {\n    position: relative;\n    height: 100%;\n    width: 100%;\n    display: block;\n    box-sizing: border-box;\n    iframe {\n        border-radius: 6px;\n        display: block;\n        background: #fff;\n        border: none;\n        height: 100%;\n        width: 100%;\n        margin: auto;\n        box-shadow: rgb(0, 0, 0, 10%) 0px 2px 1px -1px, rgba(180, 180, 180, 0.1) 0px 1px 1px 0px, rgba(180, 180, 180, 0.1) 0px 1px 3px 0px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
