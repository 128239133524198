import type { JobOccurrence } from '@nexdynamic/squeegee-common';
import { Data } from '../../Data/Data';
import { updateTagText } from './updateTagText';

export const updateOccurrenceTags = async (
    initialOccurrence: JobOccurrence,
    standardSelected: string[],
    importantSelected: string[],
    ignoreImportant: string[],
    ignoreStandard: string[]
) => {
    const occurrence = Data.get<JobOccurrence>(initialOccurrence._id);
    if (!occurrence) return;

    occurrence.description = await updateTagText(
        occurrence.description || '',
        standardSelected,
        importantSelected,
        ignoreImportant,
        ignoreStandard
    );

    Data.put(occurrence);
};
