import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import { Prompt } from '../Dialogs/Prompt';
import { TextAreaDialog } from '../Dialogs/TextAreaDialog';

export class DaynoteActions {
    public static async delete(date: string) {
        if (typeof date !== 'string') throw new Error('Unable to delete Daynote, date invalid');
        const dialog = new Prompt(
            'general.delete',
            ApplicationState.localise('daynotes.delete-confirm', { date: moment(date).format('ll') }),
            { okLabel: 'general.delete' }
        );
        await dialog.show();
        if (dialog.cancelled) return;
        await ApplicationState.clearSetting<string>('global.day-note', date);
    }

    public static async update(date: string, content?: string) {
        if (typeof date !== 'string') throw new Error('Unable to update Daynote, date invalid');

        const dialog = new TextAreaDialog(
            ApplicationState.localise('daynotes.dialog-title'),
            content || '',
            ApplicationState.localise('daynotes.dialog-subtitle', { date: moment(date).format('ll') })
        );
        const result = await dialog.show();
        if (dialog.cancelled) return;

        if (result) {
            await ApplicationState.setSetting('global.day-note', result, date);
        } else {
            await ApplicationState.clearSetting('global.day-note', date);
        }
    }
}
