import { bindable } from 'aurelia-framework';
import * as Chartist from 'chartist';
import type { Subscription } from '../../Events/SqueegeeEventAggregator';
import { ViewResizeEvent } from '../../Events/ViewResizeEvent';

export class BarChartCustomElement {
    @bindable() data: Chartist.IChartistData;
    @bindable() yAxisPrefix: string;

    public chartRef: HTMLDivElement;
    public chart?: Chartist.IChartistLineChart;

    private _viewResizeSub: Subscription;
    public attached() {
        this._viewResizeSub = ViewResizeEvent.subscribe(() => {
            setTimeout(() => {
                requestAnimationFrame(() => {
                    if (this.chart) (<any>this.chart).update();
                });
            }, 250);
        });
    }

    public dataChanged(newValue: Chartist.IChartistData) {
        if (this.chart === undefined) this.loadChart();
        else if (newValue !== undefined) {
            this.chart.update(newValue);
        }
    }

    private loadChart() {
        this.chart = new Chartist.Bar(this.chartRef, this.data, {
            seriesBarDistance: 10,
            axisY: {
                labelInterpolationFnc: (value: number) => {
                    return (this.yAxisPrefix ? this.yAxisPrefix : '') + value;
                },
                scaleMinSpace: 30,
            },
        });
    }

    protected detached() {
        this.chart && this.chart.detach();
        this._viewResizeSub && this._viewResizeSub.dispose();
    }
}
