import { bindable, bindingMode } from 'aurelia-framework';

export class CheckBox {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) checked: boolean;

    @bindable() onChange?: (value: boolean) => void;

    toggle(event: Event) {
        this.checked = !this.checked;
        if (this.onChange) this.onChange(this.checked);
        if (event) event.stopPropagation();
    }
}
