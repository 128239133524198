import { inject } from 'aurelia-framework';

@inject(Element)
export class FileInputCustomAttribute {
    constructor(private _element: HTMLInputElement) {}

    attached() {
        const label = <HTMLElement>this._element.nextSibling;
        if (label && label.nextElementSibling) {
            const labelInnerSpan = label.nextElementSibling.querySelector('span');

            this._element.addEventListener('change', () => {
                if (labelInnerSpan && this._element.files) labelInnerSpan.innerText = `${this._element.files.length} files selected`;
            });
        }
    }
}
