import type { IFranchiseeSettings } from '@nexdynamic/squeegee-common';
import { copyObject } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { LoaderEvent } from '../Events/LoaderEvent';

export class Franchise {
    protected franchiseeSettings: IFranchiseeSettings;

    public constructor() {
        this.franchiseeSettings = copyObject(ApplicationState.account.defaultFranchiseeSettings || {});
        ApplicationState.account.defaultFranchiseeSettings = this.franchiseeSettings;
    }

    protected save = async () => {
        new LoaderEvent(true);
        ApplicationState.account.defaultFranchiseeSettings = copyObject(this.franchiseeSettings);
        await ApplicationState.save();
        new LoaderEvent(false);
    };
}
