import { inject } from 'aurelia-framework';
import type { RouterConfiguration } from 'aurelia-router';
import { Router } from 'aurelia-router';
import { ViewResizeEvent } from '../Events/ViewResizeEvent';
import { GlobalFlags } from '../GlobalFlags';
import { Logger } from '../Logger';
import { routes } from './Routes';

@inject(Router)
export class Franchising {
    private constructor(private router: Router) { }

    public attached() {
        new ViewResizeEvent();
    }

    public async configureRouter(config: RouterConfiguration, router: Router) {
        if (GlobalFlags.isHttp) {
            config.options.pushState = true;
            config.options.root = '/';
        }
        await router.configure(config.map(routes));
        this.router = router;
        this.router.refreshNavigation();
    }

    public goto(route: string, id: string) {
        return new Promise<void>((resolve, reject) => {
            try {
                this.router.navigateToRoute(route, id && { id: id });
                resolve();
            } catch (error) {
                Logger.error('Error in goto route on franchising', { route, id, error });
                reject();
            }
        });
    }
}
