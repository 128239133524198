import type { IIntegrationTab, Integration, IRouteSettings, TranslationKey } from '@nexdynamic/squeegee-common';
import { PLATFORM } from 'aurelia-framework';
import type { IRoute } from './IRoute';

export class IntegrationClientRoute implements IRoute {
    public readonly route: string;
    public readonly moduleId = PLATFORM.moduleName('Integrations/Integrations');
    public readonly name: 'integrations';
    public readonly settings: IRouteSettings & { dynamicTabs: Array<IIntegrationTab> };
    public readonly title: TranslationKey;
    public readonly tabs: Array<IIntegrationTab>;
    public constructor(integration: Integration) {
        this.route = `integrations/${integration.path}`;
        this.title = integration.title;
        this.settings = {
            navIcon: integration.navIcon,
            dynamicTabs: integration.tabs,
        };
    }
}
