import type { IAccountingPeriod, IFabAction, TranslationKey } from '@nexdynamic/squeegee-common';
import type { Subscription } from 'aurelia-event-aggregator';
import { AccountingPeriodService } from '../../ChartOfAccounts/AccountingPeriodService';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import type { IMenuBarAction } from '../../Menus/IMenuBarAction';

export class AccountingPeriodSettingsDialog extends CustomDialog<boolean> {
    private _dataChangedEvent: Subscription;
    protected model: { accountingPeriod?: IAccountingPeriod; items: Array<IAccountingPeriod> } = {
        items: <Array<IAccountingPeriod>>[],
    };

    constructor() {
        super('AccountingPeriodSettingsDialog', '../ChartOfAccounts/Components/AccountingPeriodSettingsDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'payment-account-dialog',
            isSecondaryView: true,
        });
    }

    public async init() {
        this.updateFab();
        this.model.items = await AccountingPeriodService.getPeriods();
        this._dataChangedEvent = DataRefreshedEvent.subscribe(async (event: DataRefreshedEvent) => {
            if (event.hasAnyType('settings')) this.model.items = AccountingPeriodService.getPeriods();
        });
    }
    public dispose() {
        super.dispose();
        this._dataChangedEvent && this._dataChangedEvent.dispose();
    }

    protected contextMenuActions: Array<IMenuBarAction>;

    private updateFab() {
        const actions: Array<IFabAction> = [];

        actions.push({
            tooltip: 'general.add-new' as TranslationKey,
            actionType: 'action-add',
            handler: this.addNew,
            roles: ['Owner', 'Admin'],
        });

        this.createFab(actions);
    }

    onSelect = (period: IAccountingPeriod) => {
        this.model.accountingPeriod = period;
    };

    addNew = async () => {
        const newPeriod = await AccountingPeriodService.createNew();
        this.model.accountingPeriod = newPeriod;
    };
}
