import { TransactionType } from '@nexdynamic/squeegee-common';
import { bindable } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import type { Filter } from '../../Filters/Filter';
import type { ITransactionsFilterItemDictionary } from '../../Filters/Filters';
import { Filters } from '../../Filters/Filters';
import { FiltersDialog } from '../../Filters/FiltersDialog';
import { Logger } from '../../Logger';
import { TransactionsBase } from '../../Transactions/Components/TransactionsBase';
import { TransactionService } from '../../Transactions/TransactionService';

export class CustomerTransactionsCustomElement extends TransactionsBase {
    @bindable customerId: string;
    public currentFilter: Filter<ITransactionsFilterItemDictionary>;
    protected hideCancelledTransactions = ApplicationState.getSetting('global.hide-cancelled-transactions', false);

    @bindable public searchText = '';
    public searchTextChanged(): void {
        this.filter();
    }
    async loadData() {
        this.currentFilter = this.currentFilter || Filters.transactionsFilter({ transactionType: [] });
        this._transactionsByDay = TransactionService.getCustomerTransIncVoidsByDay(this.customerId, []);

        if (ApplicationState.stateFlags.devMode) {
            this.generateBalances();
        }

        await this.updateTransactionByDayArray(this.currentFilter.filterOptions, this.searchText);
    }
    private filter() {
        this.updateTransactionByDayArray(this.currentFilter.filterOptions, this.searchText);
    }

    protected async openFilterMenu() {
        try {
            const dialog = new FiltersDialog<ITransactionsFilterItemDictionary>(this.currentFilter, Filters.transactionsFilter, {
                transactionType: TransactionType.Invoice,
            });
            await dialog.show(DialogAnimation.SLIDE_UP);
            if (!dialog.cancelled) {
                this.currentFilter = await dialog.getResult();
                this.filter();
            }
        } catch (error) {
            Logger.error('Error in openFilterMenu() on Transactions', error);
        }
    }
}
