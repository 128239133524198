import { inject } from 'aurelia-framework';
import { ApplicationState } from '../ApplicationState';
import { NumpadDialog } from '../Dialogs/Numpad/NumpadDialog';
import { GlobalFlags } from '../GlobalFlags';

@inject(Element)
export class PriceCustomAttribute {
    constructor(private _element: HTMLInputElement) { }

    attached() {
        this._blurHandler();
        if (!GlobalFlags.isMobile) {
            this._element.addEventListener('keypress', this._keyHandler);
            this._element.addEventListener('blur', this._blurHandler);
        }

        if (GlobalFlags.isTouchEnabled) this._element.addEventListener('touchstart', this._touchStartHandler);

        if (GlobalFlags.isMobile) this._element.readOnly = true;
        this._element?.parentElement?.classList.add('is-dirty');
    }

    detached() {
        if (!GlobalFlags.isMobile) {
            this._element.removeEventListener('keypress', this._keyHandler);
            this._element.removeEventListener('blur', this._blurHandler);
        }

        if (GlobalFlags.isTouchEnabled) this._element.removeEventListener('touchstart', this._touchStartHandler);
    }

    private _touchStartHandler = async (event: TouchEvent) => {
        if (!this._element.disabled) {
            if (event.cancelable) {
                event.preventDefault();
                event.stopPropagation();
            }
            let currentPrice = Number(this._element.value);
            if (isNaN(currentPrice)) currentPrice = 0;
            await this.getPrice(currentPrice);
            return true;
        } else {
            return false;
        }
    };

    private _blurHandler = () => {
        this._element.value = Number(this._element.value).toFixed(2);
        this._element.dispatchEvent(new Event('change'));
    };

    private _keyHandler = (keyEvent: KeyboardEvent) => {
        let key = keyEvent.key;
        if (keyEvent.key && keyEvent.key.toLowerCase().indexOf('numpad ') === 0) key = keyEvent.key.substring(7);
        if (
            key &&
            key.length === 1 &&
            this._element.selectionStart !== null &&
            !/^\d*?\.?\d?\d?$/.test(
                this._element.value.substring(0, this._element.selectionStart) +
                key +
                this._element.value.substring(this._element.selectionStart, this._element.value.length)
            )
        ) {
            keyEvent.preventDefault();
        }
    };

    private async getPrice(currentPrice: number) {
        const numpad = new NumpadDialog(currentPrice, ApplicationState.currencySymbol());
        const result = await numpad.show();
        if (!numpad.cancelled && result !== undefined) {
            this._element.value = result.toFixed(2);
            this._element.dispatchEvent(new Event('change'));
        }
    }
}
