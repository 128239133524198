import type {
    Customer,
    IFabAction,
    Invoice,
    InvoiceItem,
    JobOccurrence,
    Notification,
    StandardApiResponseWithData,
    Transaction,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import { TransactionType } from '@nexdynamic/squeegee-common';
import { computedFrom } from 'aurelia-binding';
import { ApplicationState } from '../ApplicationState';
import { ConnectedServicesService } from '../ConnectedServices/ConnectedServicesService';
import { CustomerCreditDialog } from '../Customers/Components/CustomerCreditDialog';
import { CustomerDialog } from '../Customers/Components/CustomerDialog';
import { CustomerService } from '../Customers/CustomerService';
import { Data } from '../Data/Data';
import { CustomDialog } from '../Dialogs/CustomDialog';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { Prompt } from '../Dialogs/Prompt';
import { TextDialog } from '../Dialogs/TextDialog';
import { DataRefreshedEvent } from '../Events/DataRefreshedEvent';
import { LoaderEvent } from '../Events/LoaderEvent';
import { Logger } from '../Logger';
import type { IMenuBarAction } from '../Menus/IMenuBarAction';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { ScheduleDetailsDialog } from '../Schedule/Components/ScheduleDetailsDialog';
import { ScheduleItem } from '../Schedule/Components/ScheduleItem';
import { Api } from '../Server/Api';
import { RethinkDbAuthClient } from '../Server/RethinkDbAuthClient';
import { TransactionService } from '../Transactions/TransactionService';
import { CreateOrEditInvoiceTransactionDialog } from './CreateOrEditInvoiceTransactionDialog';
import { InvoiceActions } from './InvoiceActions';
import { InvoiceService } from './InvoiceService';
import { getInvoiceNotifications } from './functions/getInvoiceNotifications';

export class InvoiceTransactionSummaryDialog extends CustomDialog<void> {
    isTip: boolean;
    customerDescription: string | undefined;

    notifications: Array<Notification> = [];

    async init() {
        this.loadNotifications();
    }

    loadNotifications = async () => {
        if (this.transaction) {
            const result = await getInvoiceNotifications(this.transaction._id);
            if (result.success) {
                this.notifications = result.notifications;
            }
        }
    };

    protected async resendInvoice() {
        if (this.notifications?.length) {
            const lastNotification = this.notifications[0];
            if (
                lastNotification &&
                (await new Prompt('general.confirm', 'invoices.resend-edited-invoice', {
                    okLabel: 'general.send',
                    cancelLabel: 'general.no',
                }).show())
            ) {
                if (lastNotification.type === 'Email') {
                    await this.emailInvoice();
                } else if (lastNotification.type === 'SMS') {
                    await this.smsInvoice();
                }
            }
        }
    }

    protected currencySymbol = ApplicationState.currencySymbol();

    public dateFormat = 'ddd, MMM Do';
    public account = ApplicationState.account;
    public invoiceElement: HTMLElement;
    protected userId = RethinkDbAuthClient.session && RethinkDbAuthClient.session.id;
    protected get voidedTransaction() {
        return this.transaction.voidedId && Data.get<Transaction>(this.transaction.voidedId);
    }

    protected canPayOnline =
        !!this.userId && !!this.userId.trim() && !!this.transaction && !!this.invoice && !!this.account.stripePublishableKey;
    protected host = Api.apiEndpoint;
    protected invoiceUrl = '';
    protected previewVisible: boolean;
    private customer: Customer | undefined;
    protected api = Api;
    protected takeAPaymentLink: string | undefined;

    protected menuTitle: string;

    protected isInvoice: boolean;
    protected _changedSub = DataRefreshedEvent.subscribe((event: DataRefreshedEvent) => {
        if (event.updatedObjects[this.transaction._id]) {
            this.transaction = event.updatedObjects[this.transaction._id] as Transaction;
            if (this.invoice) this.invoiceUrl = InvoiceService.generateInvoiceUrl(this.invoice.referenceNumber);
            this.getCustomerAndUpdateInvoiceEmail();
            this.updateFab();
        }

        if (this.transaction.customerId && event.hasCustomerTypesUpdated(this.transaction.customerId, 'notifications')) {
            this.loadNotifications();
        }
    });

    constructor(public transaction: Transaction) {
        super('invoiceSummaryDialog', '../Invoices/InvoiceTransactionSummaryDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'invoice-summary-dialog',
            isSecondaryView: true,
        });

        this.getCustomerAndUpdateInvoiceEmail();
        this.updateFab();

        this.menuTitle = ApplicationState.localise(this.getTransTypeDescription()) + ' ' + ApplicationState.localise('general.summary');

        this.isTip = transaction.transactionSubType === 'invoice.tip';
        const isCredit = (transaction.amount || 0) < 0;
        this.isInvoice = !this.isTip && !isCredit;

        this.customerDescription = transaction.invoice?.billTo || this.customer?.name || ApplicationState.localise('general.unknown');
    }

    detached() {
        this._changedSub && this._changedSub.dispose();
    }

    @computedFrom('transaction.invoice.paymentReference')
    protected get payment() {
        if (!this.invoice) return;
        if (!this.invoice.paymentReference) return;
        return Data.get<Transaction>(this.invoice.paymentReference);
    }

    private get invoice() {
        return this.transaction.invoice as Invoice;
    }

    protected get canViewCustomer() {
        return ApplicationState.isInAnyRole(['Owner', 'Admin', 'Creator']);
    }

    protected async openInvoiceItem(invoiceItem: InvoiceItem) {
        if (!this.customer) return;
        if (invoiceItem.resourceType !== 'joboccurrences') return;
        let possibleOccurrence = Data.get<JobOccurrence>(invoiceItem.refID);

        if (possibleOccurrence === undefined) {
            const occurrenceId = invoiceItem.refID;
            const response = await Api.get<StandardApiResponseWithData<JobOccurrence>>(
                null,
                `/api/jobs/customers/${this.customer._id}/occurrences/${occurrenceId}?archived=true`,
                undefined
            );

            if (!response?.data.success || !response.data.data)
                return new NotifyUserMessage('appointments-view.error-loading-archived-appointments');

            possibleOccurrence = response.data.data;
        }
        if (!possibleOccurrence) return new NotifyUserMessage('appointments-view.error-loading-archived-appointments');
        const job = this.customer.jobs && this.customer.jobs[possibleOccurrence.jobId];

        if (!job) return new NotifyUserMessage('appointments-view.error-loading-archived-appointments');

        new ScheduleDetailsDialog(new ScheduleItem(job, this.customer, possibleOccurrence)).show(DialogAnimation.SLIDE);
    }

    protected async viewCustomer() {
        if (this.canViewCustomer && this.customer) {
            const dialog = new CustomerDialog(this.customer);
            await dialog.show(DialogAnimation.SLIDE_UP);
        }
    }

    public cancelInvoice = async () => {
        const { completed } = await InvoiceActions.cancelInvoice(this.transaction);
        if (completed) this.cancel();
    };

    private emailChecker: (value: string) => true | TranslationKey = (value: string) => {
        if (value.trim()) return true;
        else return 'validation.valid-email-required';
    };

    public emailInvoice = async () => {
        let email = '';
        try {
            const dialog = new TextDialog(
                'dialogs.email-invoice-title',
                'dialogs.email-invoice-label',
                this.invoice.emailTo,
                'dialogs.email-invoice-label',
                this.emailChecker,
                false,
                'email',
                'general.send'
            );
            email = await dialog.show();
            if (!dialog.cancelled && email && this.customer) {
                await CustomerService.checkSaveNewEmailEmail(email, this.customer, this.transaction);
                new LoaderEvent(true);
                await InvoiceService.sendInvoiceByEmail(this.transaction, email, true);
            }
        } catch (error) {
            Logger.error('Error in emailInvoice() on InvoiceTransactionSummaryDialog', error);
            new NotifyUserMessage('notifications.invoice-send-error', { email });
        } finally {
            new LoaderEvent(false);
        }
    };

    public smsInvoice = async () => {
        try {
            await InvoiceService.sendInvoiceBySms([this.transaction], undefined, true);
        } catch (error) {
            Logger.error(`Error during smsInvoice in invoice transaction summary`, { invoice: this.invoice, error });
            new NotifyUserMessage('notifications.invoice-send-error');
        } finally {
            new LoaderEvent(false);
        }
    };

    private async getCustomerAndUpdateInvoiceEmail() {
        this.customer = (this.transaction.customerId && CustomerService.getCustomer(this.transaction.customerId)) || undefined;
        if (this.customer && this.customer.email && this.customer.email !== this.invoice.emailTo)
            this.invoice.emailTo = this.customer.email;
    }

    public edit = async () => {
        if (!this.customer) return;

        const confirmText: TranslationKey = this.notifications?.length ? 'invoices.confirm-edit-already-sent' : 'invoices.confirm-edit';
        if (!(await new Prompt('general.confirm', confirmText, { okLabel: 'general.edit' }).show())) return;

        const dialog = CreateOrEditInvoiceTransactionDialog.editDialog(this.customer, this.transaction, false);
        await dialog.show();
        if (dialog.cancelled) return;

        await this.resendInvoice();
        if (this.customer) await InvoiceService.allocateBalanceToInvoices(this.customer._id);

        const transaction = Data.get<Transaction>(this.transaction._id);
        // WTF: This is required to updated the status of this dialog, leave it alone!
        if (transaction) new InvoiceTransactionSummaryDialog(transaction).show();
    };

    public unvoid = async () => {
        this.transaction;

        if (!this.transaction.voidedId) return;
        const voided = Data.get<Transaction>(this.transaction.voidedId);
        if (!voided) return;

        if (!(await new Prompt('general.confirm', 'prompts.invoice-un-cancel-text', { okLabel: 'general.yes' }).show())) return;

        delete this.transaction.voidedId;
        await Data.put(this.transaction);
        await Data.delete(voided);

        if (this.customer) await InvoiceService.allocateBalanceToInvoices(this.customer._id);

        const transaction = Data.get<Transaction>(this.transaction._id);
        if (transaction) new InvoiceTransactionSummaryDialog(transaction).show();
    };

    public getTransTypeDescription(): TranslationKey {
        return TransactionService.getTransTypeDescription(this.transaction);
    }

    public async takePayment() {
        if (!this.customer) return;

        const dialog = CustomerCreditDialog.createForInvoice(this.customer, this.transaction, true);
        if (!dialog) return;
        await dialog.show();
        if (dialog.cancelled) return;

        const transaction = Data.get<Transaction>(this.transaction._id);

        // WTF: This is required to updated the status of this dialog, leave it alone!
        if (transaction) new InvoiceTransactionSummaryDialog(transaction).show();
    }

    protected contextMenuActions: Array<IMenuBarAction>;

    private async updateFab() {
        this.contextMenuActions = [];

        if (
            this.transaction.transactionSubType !== 'invoice.tip' &&
            this.transaction.amount >= 0 &&
            this.customer &&
            (ApplicationState.stateFlags.devMode || (!this.transaction.invoice?.paid && !this.transaction.voidedId))
        ) {
            this.contextMenuActions.push({
                tooltip: `Edit Invoice` as TranslationKey,
                actionType: 'action-edit',
                handler: this.edit,
                roles: ['Owner', 'Admin'],
            });
        }

        if (this.transaction.amount >= 0 && this.transaction.voidedId) {
            this.contextMenuActions.push({
                tooltip: this.transaction.amount >= 0 ? 'actions.restore-invoice' : 'actions.restore-credit',
                actionType: 'action-not-done',
                handler: this.unvoid,
                roles: ['Owner', 'Admin'],
            });
        }

        if (ApplicationState.stateFlags.devMode) {
            if (this.transaction.externalIds?.xero) {
                this.contextMenuActions.push({
                    tooltip: 'Update with Xero' as TranslationKey,
                    actionType: 'action-edit',
                    handler: async () => {
                        await ConnectedServicesService.updateConnectedEntity('xero', this.transaction._id);
                    },
                    roles: ['Owner', 'Admin'],
                });
                this.contextMenuActions.push({
                    tooltip: 'Void in Xero' as TranslationKey,
                    actionType: 'action-edit',
                    handler: async () => {
                        await ConnectedServicesService.deleteConnectedEntity('xero', this.transaction._id);
                    },
                    roles: ['Owner', 'Admin'],
                });
            } else {
                this.contextMenuActions.push({
                    tooltip: 'Create in Xero' as TranslationKey,
                    actionType: 'action-edit',
                    handler: async () => {
                        await ConnectedServicesService.createConnectedEntity('xero', 'invoices', this.transaction._id);
                    },
                    roles: ['Owner', 'Admin'],
                });
            }
        }

        if (this.transaction.transactionSubType !== 'invoice.tip') {
            if (!this.transaction.voidedId) {
                this.contextMenuActions.push({
                    tooltip: this.transaction.amount >= 0 ? 'actions.cancel-invoice' : 'actions.cancel-credit',
                    actionType: 'action-not-done',
                    handler: this.cancelInvoice,
                    roles: ['Owner', 'Admin'],
                });
            }
        } else {
            this.contextMenuActions.push({
                tooltip: 'Delete Tip' as TranslationKey,
                actionType: 'action-delete',
                handler: this.cancelInvoice,
                roles: ['Owner', 'Admin'],
            });
        }

        if (ApplicationState.stateFlags.devMode) {
            this.contextMenuActions.push({
                tooltip: 'Delete Transaction' as TranslationKey,
                actionType: 'action-delete',
                handler: this.deleteTransaction,
                roles: ['Owner', 'Admin'],
            });
        }

        this.invoiceUrl = (this.invoice?.referenceNumber && InvoiceService.generateInvoiceUrl(this.invoice.referenceNumber)) || '';

        const actions: Array<IFabAction> = [];
        if (this.transaction.transactionSubType !== 'invoice.tip') {
            actions.push(
                {
                    tooltip: 'actions.email-invoice',
                    actionType: 'action-email',
                    handler: this.emailInvoice,
                    roles: ['Owner', 'Admin'],
                },
                {
                    tooltip: 'actions.sms-invoice',
                    actionType: 'action-request-payment',
                    handler: this.smsInvoice,
                    roles: ['Owner', 'Admin'],
                },
                {
                    tooltip: 'actions.preview-invoice',
                    actionType: 'action-preview-invoice',
                    link: this.invoiceUrl,
                    roles: ['Owner', 'Admin'],
                },
                {
                    tooltip: 'actions.download',
                    actionType: 'action-download',
                    link: this.invoiceUrl + '?format=pdf',
                    roles: ['Owner', 'Admin'],
                }
            );

            if (!this.payment && this.transaction.amount > 0 && !this.transaction?.invoice?.paid && !this.transaction.voidedId) {
                actions.push({
                    tooltip: 'actions.record-payment',
                    actionType: 'action-credit',
                    handler: () => this.takePayment(),
                    roles: ['Owner', 'Admin'],
                });
            }
        }

        // Don't allow payments on transactions with payment ref or that have a negative amount ie credit notes
        this.createFab(actions);
    }

    public deleteTransaction = async () => {
        try {
            const deleteOnCancel = this.transaction.transactionType === TransactionType.AutomaticPayment ? 'always' : 'onSuccess';
            await InvoiceService.cancelOrVoidInvoice(this.transaction._id, false, undefined, deleteOnCancel);
            this.cancel();
        } catch (ex) {
            new NotifyUserMessage(ex);
        }
    };
}
