import type {
    Customer,
    JobOccurrence,
    Notification,
    StoredEvent,
    SummaryTransaction,
    Transaction,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import {
    ArchiveService,
    SyncMode,
    TransactionType,
    isVersionGreaterOrEqual,
    sortByCreatedDateDesc,
    wait,
} from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import { DateTimePicker } from '../Components/DateTimePicker/DateTimePicker';
import { Data } from '../Data/Data';
import { Prompt } from '../Dialogs/Prompt';
import { LoaderEvent } from '../Events/LoaderEvent';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { Api } from '../Server/Api';

const KEEP_NOTIFICATION_COUNT = 2000;

export const KEEP_OCCURRENCES_AFTER_X_WEEKS = 12;

export class ClientArchiveService {
    static async autoArchive() {
        if (!Data.lastSuccessfullSyncTimeStamp) return;

        const response = await Api.ping();
        if (!response?.versionInfo) return;
        if (isVersionGreaterOrEqual(response.versionInfo.version, '1.24.2')) {
            await ClientArchiveService.archiveCampaignNotifications();
            await ClientArchiveService.archiveOlderNotifications();
            await ClientArchiveService.archiveOlderJobOccurrences();
        }
    }

    static async archiveCampaignNotifications() {
        const notifications = Data.all<Notification>('notifications', x => !!x.campaignId).slice();

        for (const notification of notifications) {
            notification._deleted = true;
            notification._archived = true;
        }

        await Data.put(notifications);
    }

    static async archiveNotificationsBeforeDate(date: string) {
        const notifications = Data.all<Notification>('notifications').slice();
        const updates = await ArchiveService.archiveNotificationsOnOrBeforeDate(notifications, date);
        await Data.put(updates);
    }

    static async archiveOlderNotifications() {
        const notifications = Data.all<Notification>('notifications').slice();
        const sorted = notifications.sort(sortByCreatedDateDesc);
        const toArchive = sorted.slice(KEEP_NOTIFICATION_COUNT);

        toArchive.forEach(n => {
            n._archived = true;
            n._deleted = true;
        });

        await Data.put(toArchive);
    }

    static async unarchiveCustomerNotifications(customerId: string) {
        try {
            const description = `Are you sure you want to unarchive notifications for this customer?`;
            const youAreSure = await new Prompt('general.confirm', description as TranslationKey, {
                okLabel: 'general.yes',
                cancelLabel: 'general.cancel',
            }).show();

            if (!youAreSure) return;
            new LoaderEvent(true);
            await Api.post(null, `/api/archive/notifications/unarchive?customerId=${customerId}`, undefined, false, false);
            await wait(1000); // Give the server a moment to process the request.
            await Data.fullSync(SyncMode.Full);
            new NotifyUserMessage('Customer notifications unarchived.' as TranslationKey);
        } catch (error) {
            new NotifyUserMessage('Failed to unarchive notifications.' as TranslationKey);
        } finally {
            new LoaderEvent(false);
        }
    }

    static async unarchiveCustomerTransactions(customerId: string) {
        try {
            const description = `Are you sure you want to unarchive all financial history for this customer?`;
            const youAreSure = await new Prompt('general.confirm', description as TranslationKey, {
                okLabel: 'general.yes',
                cancelLabel: 'general.cancel',
            }).show();

            if (!youAreSure) return;
            new LoaderEvent(true);
            await Api.post(null, `/api/archive/transactions/unarchive?customerId=${customerId}`, undefined, false, false);
            await wait(1000); // Give the server a moment to process the request.
            await Data.fullSync(SyncMode.Full);
            new NotifyUserMessage('Customer transactions unarchived.' as TranslationKey);
        } catch (error) {
            new NotifyUserMessage('Failed to unarchive transactions.' as TranslationKey);
        } finally {
            new LoaderEvent(false);
        }
    }

    static async unarchiveCustomerJobOccurrences(customerId: string) {
        try {
            const description = `Are you sure you wish unarchive all job appointment history for this customer?`;
            const youAreSure = await new Prompt('general.confirm', description as TranslationKey, {
                okLabel: 'general.yes',
                cancelLabel: 'general.cancel',
            }).show();

            if (!youAreSure) return;
            new LoaderEvent(true);
            await Api.post(null, `/api/archive/joboccurrences/unarchive?customerId=${customerId}`, undefined, false, false);
            await wait(1000); // Give the server a moment to process the request.
            await Data.fullSync(SyncMode.Full);
            new NotifyUserMessage('Customer job appointment history unarchived.' as TranslationKey);
        } catch (error) {
            new NotifyUserMessage('Failed to unarchive occurrences.' as TranslationKey);
        } finally {
            new LoaderEvent(false);
        }
    }

    static async unarchiveAllTransactions() {
        try {
            const summaries = Data.all<SummaryTransaction>('transactions', { transactionType: TransactionType.Summary });
            if (!summaries.length) {
                new NotifyUserMessage('There are currently no archived transactions/summaries.' as TranslationKey);
                return;
            }

            const count = summaries.map(s => s.count).reduce((x, y) => x + y);
            const description = `Are you sure you want to unarchive all financial transactions and remove the summaries, this will re-add ${count} transactions to the account?`;
            const youAreSure = await new Prompt('general.confirm', description as TranslationKey, {
                okLabel: 'general.yes',
                cancelLabel: 'general.cancel',
            }).show();

            if (!youAreSure) return;
            new LoaderEvent(true);
            await Api.post(null, '/api/archive/transactions/unarchive', undefined, false, false);
            await wait(1000); // Give the server a moment to process the request.
            await Data.fullSync(SyncMode.Full);
            new NotifyUserMessage('All transactions unarchived.' as TranslationKey);
        } catch (error) {
            new NotifyUserMessage('Failed to unarchive transactions.' as TranslationKey);
        } finally {
            new LoaderEvent(false);
        }
    }

    static async archiveTransactionsOnOrBeforeDate() {
        try {
            const datePicker = new DateTimePicker(false, undefined, undefined, false);
            datePicker.init();
            await datePicker.open();

            if (!datePicker.canceled) {
                const date = moment(datePicker.selectedDate);

                const youAreSure = await new Prompt(
                    'general.confirm',
                    (`Are you sure you wish archive and summarise all financial transactions including payments, ` +
                        `invoices, credit notes etc for all customers on or before ${date.format('ll')}?`) as TranslationKey,
                    {
                        okLabel: 'general.yes',
                        cancelLabel: 'general.cancel',
                    }
                ).show();

                if (youAreSure) {
                    new LoaderEvent(true);
                    const transactions = Data.all<Transaction>('transactions').slice();
                    const currencySymbol = ApplicationState.currencySymbol();

                    const updates = await ArchiveService.createArchiveTransactionsByCustomer(
                        transactions,
                        datePicker.selectedDate,
                        currencySymbol
                    );

                    await Data.put(updates);
                    new LoaderEvent(false);
                    const description = `All transactions for all customers on or before ${date.format(
                        'll'
                    )} have been archived and summarised.`;
                    await new Prompt('general.complete', description as TranslationKey, { okLabel: 'general.ok', cancelLabel: '' }).show();
                }
            }
        } catch (error) {
            new NotifyUserMessage('Failed to archive transactions.' as TranslationKey);
        } finally {
            new LoaderEvent(false);
        }
    }

    static async archiveStoredEventsOnOrBeforeDate() {
        try {
            const datePicker = new DateTimePicker(false, undefined, undefined, false);
            datePicker.init();
            await datePicker.open();

            if (!datePicker.canceled) {
                const date = moment(datePicker.selectedDate);

                const description = `Are you sure you want to archive all time events on or before ${date.format('ll')}?`;
                const youAreSure = await new Prompt('general.confirm', description as TranslationKey, {
                    okLabel: 'general.yes',
                    cancelLabel: 'general.cancel',
                }).show();

                if (youAreSure) {
                    new LoaderEvent(true);
                    await ClientArchiveService.archiveStoredEventsBeforeDate(date.format('YYYY-MM-DD'));
                    new LoaderEvent(false);
                    const description = `All time entry events for all customers on or before ${date.format('ll')} have been archived.`;
                    await new Prompt('general.complete', description as TranslationKey, { okLabel: 'general.ok', cancelLabel: '' }).show();
                }
            }
        } catch (error) {
            new NotifyUserMessage('Failed to archive stored events.' as TranslationKey);
        } finally {
            new LoaderEvent(false);
        }
    }

    static async archiveStoredEventsBeforeDate(date: string) {
        const storedEvents = Data.all<StoredEvent>('storedevents').slice();
        const updates = await ArchiveService.archiveStoredEventsOnOrBeforeDate(storedEvents, date);
        await Data.put(updates);
    }

    static async unarchiveAllStoredEvents() {
        try {
            const description = `Are you sure you wish unarchive all storedEvents?`;
            const youAreSure = await new Prompt('general.confirm', description as TranslationKey, {
                okLabel: 'general.yes',
                cancelLabel: 'general.cancel',
            }).show();

            if (!youAreSure) return;
            new LoaderEvent(true);
            await Api.post(null, '/api/archive/storedevents/unarchive', undefined, false, false);
            await wait(1000); // Give the server a moment to process the request.
            await Data.fullSync(SyncMode.Full);
            new NotifyUserMessage('All stored events unarchived.' as TranslationKey);
        } catch (error) {
            new NotifyUserMessage('Failed to unarchive stored events.' as TranslationKey);
        } finally {
            new LoaderEvent(false);
        }
    }

    static async archiveNotificationsOnOrBeforeDate() {
        try {
            const datePicker = new DateTimePicker(false, undefined, undefined, false);
            datePicker.init();
            await datePicker.open();

            if (!datePicker.canceled) {
                const date = moment(datePicker.selectedDate);

                const description = `Are you sure you want to archive all notifications for all customers on or before ${date.format(
                    'll'
                )}?`;
                const youAreSure = await new Prompt('general.confirm', description as TranslationKey, {
                    okLabel: 'general.yes',
                    cancelLabel: 'general.cancel',
                }).show();

                if (youAreSure) {
                    new LoaderEvent(true);

                    ClientArchiveService.archiveNotificationsBeforeDate(date.format('YYYY-MM-DD'));

                    new LoaderEvent(false);
                    const description = `All notifications for all customers on or before ${date.format('ll')} have been archived.`;
                    await new Prompt('general.complete', description as TranslationKey, { okLabel: 'general.ok', cancelLabel: '' }).show();
                }
            }
        } catch (error) {
            new NotifyUserMessage('Failed to archive transactions.' as TranslationKey);
        } finally {
            new LoaderEvent(false);
        }
    }

    static async unarchiveAllJobOccurrences() {
        try {
            const youAreSure = await new Prompt(
                'general.confirm',
                `Are you sure you want to unarchive any archived job occurrences, this may re-add a large amount of data to the account?` as TranslationKey,
                {
                    okLabel: 'general.yes',
                    cancelLabel: 'general.cancel',
                }
            ).show();

            if (!youAreSure) return false;
            new LoaderEvent(true);
            Api.post(null, '/api/archive/joboccurrences/unarchive', undefined, false, false).then(
                () => new NotifyUserMessage('Unarchive all job occurrences complete.' as TranslationKey)
            );
            await wait(1000); // Give the server a moment to process the request.
            new NotifyUserMessage('Processing request to unarchive all job occurrences unarchived.' as TranslationKey);
            return true;
        } catch (error) {
            new NotifyUserMessage('Failed to unarchive job occurrences.' as TranslationKey);
        } finally {
            new LoaderEvent(false);
        }
        return false;
    }

    static async archiveJobOccurrencesOnOrBeforeDate() {
        try {
            const datePicker = new DateTimePicker(false, undefined, undefined, false);
            datePicker.init();
            await datePicker.open();

            if (!datePicker.canceled) {
                const date = moment(datePicker.selectedDate);

                const occurrences = Data.all<JobOccurrence>('joboccurrences').slice();
                const customers = Data.all<Customer>('customers').slice();

                const updates = ArchiveService.createArchivedJobOccurrences(occurrences, customers, datePicker.selectedDate);
                const updatedAppointmentsCount = updates.filter(update => update.resourceType === 'joboccurrences').length;

                if (updatedAppointmentsCount === 0) {
                    new NotifyUserMessage('There are no job occurrences on or before that date that can be archived.' as TranslationKey);
                    return;
                }

                const description = `Are you sure you wish archive ${updatedAppointmentsCount} skipped or completed job occurrences on or before ${date.format(
                    'll'
                )}?`;

                const youAreSure = await new Prompt('general.confirm', description as TranslationKey, {
                    okLabel: 'general.yes',
                    cancelLabel: 'general.cancel',
                }).show();

                if (youAreSure) {
                    new LoaderEvent(true);

                    if (updates.length) {
                        await Data.put(updates);

                        new LoaderEvent(false);
                        const description = `All job occurrences on or before ${date.format('ll')} have been archived.`;
                        await new Prompt('general.complete', description as TranslationKey, {
                            okLabel: 'general.ok',
                            cancelLabel: '',
                        }).show();
                    } else {
                        new LoaderEvent(false);
                        new NotifyUserMessage('Unable to archive job occurrences on or before that date.' as TranslationKey);
                    }
                }
            }
        } catch (error) {
            new NotifyUserMessage('Failed to archive job occurrences.' as TranslationKey);
        } finally {
            new LoaderEvent(false);
        }
    }

    static async archiveOlderJobOccurrences() {
        const autoArchiveEnabled = ApplicationState.getSetting<boolean>('global.archive-occurrences-before-days', true);
        if (!autoArchiveEnabled) return;

        const occurrencesCopy = Data.all<JobOccurrence>('joboccurrences').slice();
        const customers = Data.all<Customer>('customers').slice();
        const before12weeks = moment().subtract(KEEP_OCCURRENCES_AFTER_X_WEEKS, 'weeks').startOf('week').format('YYYY-MM-DD');

        const archiveOccurrences = ArchiveService.createArchivedJobOccurrences(occurrencesCopy, customers, before12weeks);

        await Data.put(archiveOccurrences);
    }
}
