import type { Franchisee, StoredObjectResourceTypes, TranslationKey } from '@nexdynamic/squeegee-common';
import { wait } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import type { IFabAction } from '../Components/Fabs/IFabAction';
import type { ListItem } from '../Components/StandardList/ListItem';
import { StandardList } from '../Components/StandardList/StandardList';
import { Data } from '../Data/Data';
import { Prompt } from '../Dialogs/Prompt';
import { LoaderEvent } from '../Events/LoaderEvent';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { Api } from '../Server/Api';
import { RethinkDbAuthClient } from '../Server/RethinkDbAuthClient';
import { animate } from '../Utilities';
import type { FranchiseeReportItem, FranchiseeReportItemEntry, FranchiseeReportItemGroup } from './FranchiseeReportItemStandardListItem';
import { FranchiseeReportItemStandardListItem } from './FranchiseeReportItemStandardListItem';
import { Franchisees } from './Franchisees';
export class DownloadReports extends StandardList<FranchiseeReportItem> {
    protected listTitle: TranslationKey = 'route-config.franchisees-download-reports';

    listenToResourceTypes: Array<StoredObjectResourceTypes> = ['franchisee'];

    protected loadItems = async () => {
        new LoaderEvent(true, true, 'loader.loading-reports');
        try {
            const franchisees = Data.all<Franchisee>('franchisee');
            const list: Array<ListItem<FranchiseeReportItem>> = [];
            const franchiseeReportItems: Array<FranchiseeReportItem> = [];

            const reportPromises = [] as Array<Promise<void>>;
            for (const franchisee of franchisees) {
                // eslint-disable-next-line no-async-promise-executor
                reportPromises.push(
                    new Promise<void>(async (resolve, reject) => {
                        try {
                            const franchiseeReports = await Franchisees.getReports(
                                franchisee,
                                moment().subtract(3, 'month').format('YYYY-MM-DD')
                            );
                            for (const report of franchiseeReports) {
                                franchiseeReportItems.push({
                                    _id: report.isoReportDate + franchisee._id,
                                    report,
                                    franchisee,
                                    isGroup: false,
                                });
                            }
                            resolve();
                        } catch (error) {
                            reject(error);
                        }
                    })
                );
            }
            await Promise.all(reportPromises);

            franchiseeReportItems
                .sort((x, y) =>
                    x.isGroup || y.isGroup
                        ? 0
                        : x.report.isoReportDate.localeCompare(y.report.isoReportDate) || x.franchisee.name.localeCompare(y.franchisee.name)
                )
                .reverse();

            let previousGroup: FranchiseeReportItemGroup | undefined;
            for (const franchiseeReportItem of franchiseeReportItems) {
                if (franchiseeReportItem.isGroup) continue;

                const locale = ApplicationState.account.language?.slice(0, 5) || 'en-GB';
                const isWeekly = franchiseeReportItem.franchisee?.settings?.reportInterval === 'week';
                const from = moment(franchiseeReportItem.report.isoReportDate)
                    .locale(locale)
                    .startOf(isWeekly ? 'week' : 'month');
                const to = moment(franchiseeReportItem.report.isoReportDate).locale(locale);
                const groupName = isWeekly ? `week ${from.week()}` : `${from.format('MMMM YYYY')}`;
                const groupDescription = `All reports for the period ${from.format('L')} to ${to.format('L')} inclusive.`;
                if (previousGroup?.groupName !== groupName) {
                    previousGroup = { isGroup: true, groupName, _id: groupName, groupDescription, entries: [] };
                    list.push(new FranchiseeReportItemStandardListItem(previousGroup));
                }
                list.push(new FranchiseeReportItemStandardListItem(franchiseeReportItem));
                previousGroup.entries.push(franchiseeReportItem);
            }
            return list;
        } finally {
            new LoaderEvent(false);
        }
    };

    protected selectItem = async (franchiseeReportItem: FranchiseeReportItem) => {
        try {
            let entries: Array<FranchiseeReportItemEntry>;

            if (franchiseeReportItem?.isGroup) {
                const totalToDownload = franchiseeReportItem.entries.length;
                const downloadAllQuestion = `Download ${totalToDownload} reports for ${franchiseeReportItem.groupName}?` as TranslationKey;
                const downloadAll = await new Prompt('general.confirm', downloadAllQuestion, { okLabel: 'general.download' }).show();
                if (!downloadAll) return;

                entries = [...franchiseeReportItem.entries];
            } else {
                entries = [franchiseeReportItem];
            }

            const message = franchiseeReportItem?.isGroup
                ? (`Starting all reports download for ${franchiseeReportItem.groupName}...` as TranslationKey)
                : (`Starting report download for ${entries[0].franchisee.name}...` as TranslationKey);

            new LoaderEvent(true, true, message);

            const linkPromises = [] as Array<Promise<HTMLAnchorElement>>;
            for (const entry of entries) {
                const final = entry.report.state === 'finalised';
                const auth = `?session-key=${RethinkDbAuthClient.session?.key}&session-value=${RethinkDbAuthClient.session?.value}&pdf=true`;
                const state = final && (entry.report.state === 'archived' || entry.report.state === 'finalised') ? 'locked' : 'transient';
                const idOrDate = (final && entry.report.id) || entry.report.isoReportDate;
                const baseReportViewUrl = `${Api.currentHostAndScheme}/api/reports/franchisee-owner-${state}/${ApplicationState.account.uuid}/${entry.franchisee._id}/${idOrDate}/`;
                const url = `${baseReportViewUrl}${auth}`;

                const a = document.createElement('A') as HTMLAnchorElement;
                a.download = `${entry.franchisee.name}-${entry.report.isoReportDate}.pdf`;

                linkPromises.push(
                    fetch(url).then(response =>
                        response.blob().then(blob => {
                            const blobUrl = window.URL.createObjectURL(blob);

                            a.href = blobUrl;

                            new LoaderEvent(true, true, 'loader.downloaded-index-of-total-reports', undefined, {
                                index: (entries.indexOf(entry) + 1).toString(),
                                total: entries.length.toString(),
                            });

                            return a;
                        })
                    )
                );
                await animate(10);
            }

            const links = await Promise.all(linkPromises);

            for (const a of links) {
                document.body.appendChild(a);
                a.click();
                await wait(250);
                document.body.removeChild(a);
            }

            new LoaderEvent(false);
        } catch (error) {
            Logger.error('Error in selectItem() on download reports', { FranchiseeReportItem: franchiseeReportItem, error });
            new NotifyUserMessage('franchise.download-report-failed');
        }
    };

    protected fabActions: Array<IFabAction> = [];
}
