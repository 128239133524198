import type { Expense } from '@nexdynamic/squeegee-common';
import { bindable } from 'aurelia-framework';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { ExpenseSummaryDialog } from '../../Expenses/Components/ExpenseSummaryDialog';
import { Logger } from '../../Logger';

export class ExpenseCustomElement {
    @bindable expense: Expense;

    protected async view() {
        try {
            const dialog = new ExpenseSummaryDialog(this.expense._id);
            await dialog.show(DialogAnimation.SCALE);
        } catch (error) {
            Logger.error('Error in viewExpense()', error);
        }
    }
}
