// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ui-tag {
  text-transform: capitalize;
  display: inline;
  font-size: smaller;
  color: #fff;
  padding: 3px 6px 3px 6px;
  border-radius: 3px;
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/Components/Tags/ui-tag.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;EACA,wBAAA;EACA,kBAAA;EACA,mBAAA;AACJ","sourcesContent":[".ui-tag {\n    text-transform: capitalize;\n    display: inline;\n    font-size: smaller;\n    color: #fff;\n    padding: 3px 6px 3px 6px;\n    border-radius: 3px;\n    white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
