import { uuid } from '@nexdynamic/squeegee-common';
import { CustomDialog } from '../CustomDialog';
import { DialogAnimation } from '../DialogAnimation';
import type { DialogSettingsViewport } from '../DialogSettings';

export class NumpadDialog extends CustomDialog<number> {
    public value: string;

    private openedWithValue = false;
    constructor(value?: number, public prefix = '', public suffix = '', private integer = false, settings?: DialogSettingsViewport) {
        super('numpadDialog-' + uuid(), '../Dialogs/Numpad/NumpadDialog.html', '', Object.assign(NumpadDialog._defaultSettings, settings));
        this.value = (Number(value) || 0).toFixed(integer ? 0 : 2);
        if (this.value) this.openedWithValue = true;
    }

    private static get _defaultSettings(): DialogSettingsViewport {
        return { okLabel: '', cancelLabel: '', cssClass: 'numpad-dialog' };
    }

    public async getResult() {
        return Number(this.value) || 0;
    }

    public show() {
        return super.show(DialogAnimation.SLIDE_UP)
    }

    public select = (input?: string) => {
        if (this.openedWithValue && input !== undefined) {
            this.value = Number(0).toFixed(this.integer ? 0 : 2);
            this.openedWithValue = false;
        }

        if (input === undefined) {
            this.value = this.value.substring(0, this.value.length - 1);
            return;
        }
        if ((this.value === '' || this.value === '0.00') && input === '.') {
            this.value = '0.';
            return;
        }

        if (this.value !== '0.00') {
            if (this.integer && !/^\d*?$/.test(this.value + input)) return;
            else if (!this.integer && !/^\d*?\.?\d?\d?$/.test(this.value + input)) return;
        }

        this.value = this.value === '0.00' || (!Number(this.value) && this.integer) ? input : this.value + input;
    };
}
