import type { Customer, TranslationKey } from '@nexdynamic/squeegee-common';
import { uuid } from '@nexdynamic/squeegee-common';
import type { ConfirmCardPaymentData, Source, Stripe, StripeCardElement } from '@stripe/stripe-js';
import { bindable, inject } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { Data } from '../../Data/Data';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { Logger } from '../../Logger';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { Api } from '../../Server/Api';
import { StripeUtils } from '../../Stripe/StripeUtils';
@inject(Element)
export class StripeChargeCustomerCustomElement {
    _cardElement: StripeCardElement;
    @bindable label: string;
    @bindable customerId?: string;
    @bindable invoiceId?: string;
    @bindable stripeCustomerId?: string;
    @bindable getChargeDescription?: () => string;
    @bindable franchiseeId?: string;
    @bindable name?: string;
    @bindable address?: string;
    @bindable email?: string;
    @bindable done?: (ok: boolean, paymentMethodId?: string, paymentTransactionId?: string) => void;
    @bindable chargeAmount?: number;
    protected selectedPaymentMethodId = 'on';
    protected paymentTransactionId = uuid();
    paymentMethod?: stripe.paymentMethod.PaymentMethod;
    errorMessage: string;
    displaySuccess: boolean;
    stripeUnavailable = true;
    stripeInProcess = false;
    paymentMethods: Array<{ id: string; card: Source.Card }>;

    constructor(private _element: Element) { }
    hasAdvancedOrAbove: boolean;
    async attached() {
        await this.setupCardPayment();
        await this.setupCardPaymentForm();
    }

    async setupCardPayment() {
        new LoaderEvent(true, true, 'loader.checking-stripe-payment-details');
        this.stripeInProcess = true;
        try {
            this.hasAdvancedOrAbove = ApplicationState.hasAdvancedOrAbove;

            const customer = (this.customerId && Data.get<Customer>(this.customerId)) || undefined;
            if (!customer) return;
            const stripeCustomerId = customer.externalIds?.stripe;
            if (!stripeCustomerId) return;

            const cardsOnFile =
                (
                    await Api.get<Array<{ id: string; card: Source.Card }>>(
                        null,
                        `/api/payments/stripe/cards-on-file/${customer.externalIds?.stripe}`,
                        undefined,
                        undefined,
                        true
                    )
                )?.data || [];

            if (!cardsOnFile.length) return;

            this.paymentMethods = cardsOnFile;
            const sourceOrMandateId = customer?.paymentProviderMetaData?.stripe?.sourceOrMandateId;
            if (!sourceOrMandateId) return;
            if (!this.paymentMethods.some(x => x.id === sourceOrMandateId)) return;
            this.selectedPaymentMethodId = sourceOrMandateId;
        } catch (error) {
            const message = ((typeof error?.description?.raw?.message === 'string' && error?.description?.raw?.message) ||
                'Unable to set up a charge for this customer.') as TranslationKey;
            new NotifyUserMessage(message);
            Logger.error('Failed to initialise the stripe charge.', error);
        } finally {
            this.stripeInProcess = false;
            new LoaderEvent(false);
        }
    }

    private _stripe?: Stripe;
    async setupCardPaymentForm() {
        this._stripe = await StripeUtils.getUserStripeInstance();

        if (!this._stripe) return;

        const elements = this._stripe.elements();
        const cardElement = elements.create('card', { hidePostalCode: true, style: { empty: {}, base: {} } });

        const element = this._element.querySelector<HTMLElement>('#stripe-container');

        if (!element) {
            return;
        }

        cardElement.mount(element);
        cardElement.on('change', event => {
            if (event && event.error) {
                this.errorMessage = event.error.message || '';
            } else {
                this.errorMessage = '';
            }
        });

        this._cardElement = cardElement;

        this.stripeUnavailable = false;
    }

    async confirmCardPayment() {
        if (!this._stripe) return;
        this.stripeInProcess = true;
        new LoaderEvent(true, true, 'loader.confirming-payment-details');

        let succeeded = false;
        let returnedPaymentMethodId: string | undefined;
        let paymentTransactionId: string | undefined;

        try {
            const paymentMethodId = this.selectedPaymentMethodId !== 'on' ? this.selectedPaymentMethodId : undefined;

            const currency = ApplicationState.currencyCode().toLowerCase();

            const processPayment = await Api.post<{
                client_secret: string;
                stripeCustomerId: string;
            }>(
                null,
                '/api/confirm-customer-charge',
                {
                    currency,
                    amount: Number(((this.chargeAmount || 0) * 100).toFixed(2)),
                    customerId: this.customerId,
                    email: this.email,
                    name: this.name,
                    address: this.address,
                    invoiceId: this.invoiceId,
                    paymentId: this.paymentTransactionId,
                    franchiseeId: this.franchiseeId,
                    stripeCustomerId: this.stripeCustomerId,
                    chargeDescription: this.getChargeDescription ? this.getChargeDescription() : '',
                },
                undefined,
                false
            );
            if (!processPayment) {
                throw 'No response returned from the Stripe card service.';
            }

            const receipt_email = this.email || undefined;
            const confirmCardPaymentOptions: ConfirmCardPaymentData = {
                payment_method: paymentMethodId || { card: this._cardElement },
                receipt_email,
            };

            const cardSetup = await this._stripe.confirmCardPayment(processPayment.data.client_secret, confirmCardPaymentOptions);

            if (!cardSetup.error) {
                returnedPaymentMethodId =
                    typeof cardSetup.paymentIntent?.payment_method === 'string'
                        ? cardSetup.paymentIntent?.payment_method
                        : cardSetup.paymentIntent?.payment_method?.id;
                succeeded = true;
                paymentTransactionId = this.paymentTransactionId;
                this.displaySuccess = true;
            } else {
                this.errorMessage = cardSetup.error.message || '';
                this.stripeInProcess = false;
            }
        } finally {
            this.done && this.done(succeeded, returnedPaymentMethodId, paymentTransactionId);
            new LoaderEvent(false);
        }
    }

    selectedPaymentMethodIdChanged(newValue: string) {
        if (newValue === 'on') {
            this.setupCardPaymentForm();
        }
    }
}
