import type { Franchisee, IFranchiseeReportInfo, TranslationKey } from '@nexdynamic/squeegee-common';
import { wait } from '@nexdynamic/squeegee-common';
import { bindable } from 'aurelia-framework';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import { Prompt } from '../Dialogs/Prompt';
import { Api } from '../Server/Api';
import { RethinkDbAuthClient } from '../Server/RethinkDbAuthClient';
import { Franchisees } from './Franchisees';
import type { IReportInfo } from './IReportInfo';

export class FranchiseeReports {
    @bindable franchiseeId: string;

    protected currentHostAndScheme = Api.currentHostAndScheme;
    protected sessionKey = RethinkDbAuthClient.session && RethinkDbAuthClient.session.key;
    protected sessionValue = RethinkDbAuthClient.session && RethinkDbAuthClient.session.value;
    protected reports: Array<IReportInfo> = [];
    protected loaded = false;
    protected get connected() {
        return Api.isConnected;
    }

    public async attached() {
        await this.loadAvailableFranchiseeReports();
    }

    private async loadAvailableFranchiseeReports() {
        const franchisee = Data.get<Franchisee>(this.franchiseeId);
        if (franchisee) this.reports = await Franchisees.getReports(franchisee);
        this.loaded = true;
    }

    protected getViewReportUrl(report: IReportInfo, final = true) {
        const auth = `?session-key=${this.sessionKey}&session-value=${this.sessionValue}`;
        const state = final && (report.state === 'archived' || report.state === 'finalised') ? 'locked' : 'transient';
        const idOrDate = (final && report.id) || report.isoReportDate;
        const baseReportViewUrl = `${this.currentHostAndScheme}/api/reports/franchisee-owner-${state}/${ApplicationState.account.uuid}/${this.franchiseeId}/${idOrDate}/`;
        const url = `${baseReportViewUrl}${auth}`;
        return url;
    }

    protected async lockReport(report: IReportInfo) {
        this.loaded = false;
        if (
            !(await new Prompt(
                (report.state === 'draft' ? 'Finalise Month' : 'Re-finalise Month') as TranslationKey,
                (report.state === 'draft'
                    ? 'Are you sure you wish to finalise this franchisee monthly statement?'
                    : 'Are you sure you wish to re-finalise this franchisee monthly statement and archive the previously finalised statement?') as TranslationKey
            ).show())
        )
            return (this.loaded = true);

        await wait(500);
        await Api.post<IFranchiseeReportInfo>(null, `/api/reports/franchisee-lock-report/${this.franchiseeId}/${report.isoReportDate}`);
        await this.loadAvailableFranchiseeReports();
    }
}
