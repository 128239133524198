// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#custom-dialog .view-dialog {
  border-radius: none;
  max-height: 600px;
  height: 50vh;
  width: calc(100vw - 16px);
  top: initial;
  max-width: 100%;
  max-width: 640px;
  margin: 0 auto;
  z-index: 10;
}
#custom-dialog .view-dialog .mdl-list {
  margin-top: 0;
}
#custom-dialog .view-dialog context-menu-bar {
  height: 32px;
}
#custom-dialog .view-dialog .context-menu-bar {
  height: 40px !important;
  padding: 4px !important;
  margin: 0px !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
#custom-dialog .view-dialog .squeegee-dialog-content {
  padding: 0;
}
#custom-dialog .view-dialog .mdl-dialog__title {
  display: none;
}
#custom-dialog .view-dialog .squeegee-dialog-body {
  padding-top: 0;
  border-radius: 0;
  top: 40px;
}
#custom-dialog .view-dialog .dialog-content {
  border-radius: 0;
}`, "",{"version":3,"sources":["webpack://./src/Dialogs/ViewDialog.scss"],"names":[],"mappings":"AAGI;EACI,mBAAA;EACA,iBAAA;EACA,YAAA;EACA,yBAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EAiCA,WAAA;AAlCR;AAGQ;EACI,aAAA;AADZ;AAGQ;EACI,YAAA;AADZ;AAGQ;EACI,uBAAA;EACA,uBAAA;EACA,sBAAA;EACA,2BAAA;EACA,4BAAA;AADZ;AAIQ;EACI,UAAA;AAFZ;AAIQ;EACI,aAAA;AAFZ;AAKQ;EACI,cAAA;EACA,gBAAA;EACA,SAAA;AAHZ;AAMQ;EACI,gBAAA;AAJZ","sourcesContent":["@import '../Theme.scss';\n\n#custom-dialog {\n    .view-dialog {\n        border-radius: none;\n        max-height: 600px;\n        height: 50vh;\n        width: calc(100vw - 16px);\n        top: initial;\n        max-width: 100%;\n        max-width: 640px;\n        margin: 0 auto;\n\n        .mdl-list {\n            margin-top: 0;\n        }\n        context-menu-bar {\n            height: 32px;\n        }\n        .context-menu-bar {\n            height: 40px !important;\n            padding: 4px !important;\n            margin: 0px !important;\n            border-top-left-radius: 4px;\n            border-top-right-radius: 4px;\n        }\n\n        .squeegee-dialog-content {\n            padding: 0;\n        }\n        .mdl-dialog__title {\n            display: none;\n        }\n\n        .squeegee-dialog-body {\n            padding-top: 0;\n            border-radius: 0;\n            top: 40px;\n        }\n\n        .dialog-content {\n            border-radius: 0;\n        }\n\n        z-index: 10;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
