import type { Job, JobOccurrence } from '@nexdynamic/squeegee-common';
import { sortByDateDesc } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import { Data } from '../../Data/Data';
import { t } from '../../t';

export const jobOffScheduleDiffDetails = (job: Job, today: string) => {
    if (!job.date || job.date < today || job.frequencyType === 0 || job.frequencyType === 5) return;

    const jobDate = job.date.slice(0, 10);

    const latestOccurrence = Data.all<JobOccurrence>('joboccurrences', { jobId: job._id })
        .slice()
        .sort((x, y) => sortByDateDesc({ date: x.isoDueDate }, { date: y.isoDueDate }))
        .filter(x => x.isoDueDate < jobDate)[0];

    if (!latestOccurrence) return;

    const multiplier =
        job.frequencyType === 1 ? 1 : job.frequencyType === 2 ? 7 : job.frequencyType === 3 || job.frequencyType === 4 ? 31 : 1;

    const expectedDays = job.frequencyInterval * multiplier;

    const lateTolerance = (ApplicationState.viewOptions.offScheduleToleranceLate || 100) / 100;
    const earlyTolerance = (ApplicationState.viewOptions.offScheduleToleranceEarly || 25) / 100;
    const daysMax = Math.floor(job.frequencyInterval * multiplier * (1 + lateTolerance));
    const daysMin = Math.floor(job.frequencyInterval * multiplier * (1 - earlyTolerance));

    if (jobDate === latestOccurrence.isoDueDate) return;
    const diffInDays = Math.abs(moment(latestOccurrence.isoDueDate).diff(jobDate, 'days'));
    if (diffInDays < daysMax && diffInDays > daysMin) return;

    const diffInDaysSuffix = diffInDays < daysMin ? 'early' : 'late';
    const diffFromExpected = Math.abs(expectedDays - diffInDays);

    return t(`view-options.off-schedule-info-${diffInDaysSuffix}`, { days: diffFromExpected.toString() });
};
