import { CleanerPlannerCommonEntity } from './CleanerPlannerCommonEntity';
export class CleanerPlannerCustomer extends CleanerPlannerCommonEntity {
    Id: string;
    ContactId: string;
    SourceId: string;
    /** Comma separated list of services */
    ServiceNames: string;
    /** DD/MM/YYYY HH:mm:ss */
    LastInvoiceTime: string;
    GoCardlessId?: string;
    GoCardlessPreAuthId?: string;
}
