// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customer-dialog__tabs-bar {
  min-height: 52px;
  height: 52px;
}
.customer-dialog__tab {
  height: 52px;
}`, "",{"version":3,"sources":["webpack://./src/Customers/Components/CustomerDialog.scss"],"names":[],"mappings":"AACI;EAEI,gBAAA;EACA,YAAA;AADR;AAGI;EACI,YAAA;AADR","sourcesContent":[".customer-dialog {\n    &__tabs-bar {\n        // Some reason ios has issue with tab bar if min-height is not set\n        min-height: 52px;\n        height: 52px;\n    }\n    &__tab {\n        height: 52px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
