import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { DateTimePicker } from '../../Components/DateTimePicker/DateTimePicker';

export class ProfitLoss {
    protected startDate: string;
    protected endDate: string;
    async setStartDate() {
        const dtp = new DateTimePicker(undefined, this.startDate, 'start-date' as TranslationKey, false);
        dtp.init();
        await dtp.open();
        if (dtp.canceled) return;
        this.startDate = dtp.selectedDate;
    }
    async setEndDate() {
        const dtp = new DateTimePicker(undefined, this.endDate, 'end-date' as TranslationKey, false);
        dtp.init();
        await dtp.open();
        if (dtp.canceled) return;
        this.endDate = dtp.selectedDate;
    }
}
